import React from "react";
import useUI from "hooks/ui.hook";

import { useMenu } from "../../router_context";
import { EditorInterface } from "../../router_pages";
import { Banner } from "../../router_components";

import Logo from "assets/images/jabiLogo/MAJOR_DETOUR.png";

import styles from "assets/scss/pages/Menu/editorIndex.module.scss";
import { Button } from "lib/components";
import useFeature from "hooks/useFeature";
import { VideoTuto } from "lib/components/YoutubeModal";


const colors = ["#f1faf5", "#faf6ff", "#fcf1f2", "#fff7eb"];

const RenderRepas = () => {
  const [ui] = useUI();
  const [, dispatch] = useMenu();

  const _handleClick = (repas) => {
    dispatch({type: "setMeal", meal: repas});
    dispatch({type: "changePage", page: 1 });
  };

  if (!ui || !ui.establishment || !ui.establishment.template) return (
    <div className={styles.noTemplate}>
      <img src={Logo} />
      <h1>Menu introuvable</h1>
      <p>{"Votre fonctionnalité d'édition de menu n'est pas encore activée"}</p>
      <p>{"Rapprochez vous de l'équipe Jabi afin de paramétrer votre menu personnalisé"}</p>
      <Button style={{marginTop: 20}} onClick={() => window.location.href = "mailto:briac.perrin@jabi.fr"}>{"Prendre contact avec l'équipe Major"}</Button>
    </div>
  );
  
  return Object.keys(ui.establishment.template).sort((a,b) => ui.establishment.template[a].heure - ui.establishment.template[b].heure).map((_repas, _id) => (
    <div className={styles.card} key={_id} onClick={() => _handleClick(_repas)} style={{backgroundColor: colors[_id % colors.length]}}>
      <span className={styles.mealTitle}>{_repas}</span>
    </div>
  ));
};

const MenuIndex = () => {
  const [ctx] = useMenu();

  if(!ctx) return <></>;

  return (
    <>
      {ctx.page === 0 ? 
        <div style={{width: "100%"}}>
          {useFeature("reservationMenu") ? 
              <Banner/>
          : null}
          <div style={{marginTop: 10, paddingBottom:12}}>
            <VideoTuto url="https://youtu.be/Xo7PSK9K4lQ" /*preview={menuCard}*//>
          </div>

          <div className={styles.container} style={{width: "100%"}}>
            <RenderRepas/>
          </div>
          
        </div>
        : ctx.page === 1 ? 
          <EditorInterface />
          : null
      }
      
    </>);
};

export default MenuIndex;