import PropTypes from "prop-types";
import {
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import { MultiSelect } from "lib/components";
import { FormGroup } from "reactstrap";

import "../../../fonts";
import ImagePickerCrop from "lib/components/Form/ImagePickerCrop";
import useAnimationEvents from "resources/animations";
import useGazette from "pages/Major/Gazette/context";
import styles from "../styles/animation.contentType.module.scss";

const AnimationComponent = (props) => {
  const {selectedAnimations = []} = props;
  const [ctx] = useGazette();
  let categories = ctx.categoriesEvent ?? [];
  
  return(
    <div style={{display: "flex", flexDirection: "column", gap : 5}}>
      {selectedAnimations.length === 0 ? <h5>Aucune animation séléctionnée</h5> : null}
      {selectedAnimations.map((a, index) => 
        index < 10 ? (
          <div key={index} style={{display: "flex", flexDirection: "column", backgroundColor:'white', borderRadius: 5}}>
            <div style={{backgroundColor: categories.find((c) => c.uid === a.value.categoryId)?.color ?? "#ec871c", display: "flex", justifyContent:"center", gap: 10, borderTopLeftRadius: 5, borderTopRightRadius: 5}}>
              <img src={categories.find((c) => c.uid === a.value.categoryId)?.smiley} style={{width: 20}}/>
              <p style={{color: "white"}}>{moment(a.value.start).format("DD MMM")} de {moment(a.value.start).format("HH:mm")} à {moment(a.value.end).format("HH:mm")}</p>
            </div>
            <p className={styles.animation} style={{textAlign: "center"}}>{a.value.title}</p>
          </div>
        ) : index === selectedAnimations.length - 1 ? (
          <p key={index}>Et {selectedAnimations.length - 10} autres</p>
        ) : null)}

    </div>
  );
};

const AnimationEditor = (props) => {
  const {
    onChange,
    field,
  } = props;
  const [ctx] = useGazette();
  let events = ctx.events;

  // tri des evenements selon la page animations passées ou bien à venir (importantes)
  if (ctx.selectedPage.slug === "importantAnimation") {
    events = events.filter(e => moment(e.start) >= moment(ctx.editionDate ? ctx.editionDate : ctx.createdAt).startOf("month").add(1, "months")).reverse();
  }
  if(ctx.selectedPage.slug === "monthAnimation") {
    events = events.filter(e => moment(e.start) >= moment(ctx.editionDate ? ctx.editionDate : ctx.createdAt).startOf("month") && moment(e.start) <= moment().endOf("month")).reverse();
  }
  const options = events.map(e => ({value: e, label: `${moment(e.start).format("DD/MM/YYYY")} - De ${moment(e.start).format("hh:mm")} à ${moment(e.end).format("hh:mm")} - ${e.title}`}));
  
  
  return (
    <div>
      {ctx.selectedPage.slug === "importantAnimation" ?
        <h5>Animations importantes du mois de {moment(ctx.editionDate ? ctx.editionDate : ctx.createdAt).startOf("month").add(1, "months").format("MMMM YYYY")}</h5>
      : null}
      {ctx.selectedPage.slug === "monthAnimation" ?
        <h5>Animations du mois de {moment(ctx.editionDate ? ctx.editionDate : ctx.createdAt).startOf("month").format("MMMM YYYY")}</h5>
      : null}
      <div style={{marginBottom: 10}}>
      <p>À l&apos;aide du sélecteur, vous pouvez ajouter autant d&apos;animations que vous souhaitez pour le mois spécifié ci-dessus.</p>
      <p>Pour ce faire, cliquez sur le sélecteur &quot;Ajouter les animations&quot;, choisissez une animation et répétez ce processus autant de fois que désiré.</p>
      </div>
      
      <FormGroup>
        <MultiSelect onChange={e => onChange("selectedAnimations", e)} value={field.selectedAnimations ?? ""} options={options ?? []} placeholder="Selectionner les animations" label="Ajouter des animations" />
      </FormGroup>
    </div>
  );
};

const AnimationPdf = (props) => {
  const {cardBackgroundColor, selectedAnimations, categoriesEvent} = props;
  let categories = categoriesEvent ?? [];

  const pdfStyle = StyleSheet.create({
    container: {
      display: "flex",
      flexDirection : "row",
      justifyContent: "space-evenly",
      flexWrap: "wrap",
      rowGap: "10px",
      columnGap: "5px",
      width: "100%",
      height: "100%"
    },
    animationContainer: {
      marginTop: "2px",
    },

    animation: {
      fontFamily: "Brandon",
      width: "100%",
      paddingHorizontal: 3,
      textAlign: "center",
      height: "100%",
    },

    animationCard: {
      width: "48%",
      height: 75,
      display: "flex",
      flexDirection: "column",
      backgroundColor: cardBackgroundColor,
      justifyContent:"space-between",
      borderRadius: 5,
    },
    headerCard: {
      display: "flex", 
      flexDirection: "row",
      alignItems: "center",
      justifyContent:"center", 
      gap: 10, 
      borderTopLeftRadius: 5, 
      borderTopRightRadius: 5,
      paddingVertical: 5,
    },
    headerText: {
      wordWrap: "break-word",
      fontFamily: "Brandon",
      color: "white",
      fontSize: 16,
    },
  });

  return (
    <View style={pdfStyle.container}>
      {selectedAnimations && selectedAnimations.map((a, index) => (
        <View style={pdfStyle.animationCard} key={index}>
          <View style={[pdfStyle.headerCard, {backgroundColor: categories.find((c) => c.uid === a.value.categoryId)?.color ?? "#ec871c"}]}>
            {categories.find((c) => c.uid === a.value.categoryId)?.smiley ? <Image src={categories.find((c) => c.uid === a.value.categoryId)?.smiley} style={{width: 20}}/> : null}
            <Text style={pdfStyle.headerText}>{moment(a.value.start).format("DD MMM")} de {moment(a.value.start).format("HH:mm")} à {moment(a.value.end).format("HH:mm")}</Text>
          </View>
          <Text style={pdfStyle.animation}>{a.value.title}</Text>
        </View>
      ))}
    </View>
  );
};

AnimationComponent.propTypes = {
  selectedAnimations: PropTypes.any,
};
AnimationEditor.propTypes = {
  onChange: PropTypes.any,
  field: PropTypes.any,
};
AnimationPdf.propTypes = {
  selectedAnimations: PropTypes.any,
  categoriesEvent: PropTypes.any,
  cardBackgroundColor: PropTypes.any,
};

export { AnimationEditor, AnimationComponent, AnimationPdf };