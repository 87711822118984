import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";
import styles from "../../assets/scss/component/colorPicker.module.scss";

const ColorPicker = (props) => {
  const {
    value,
    onChange,
    label,
    invalid,
    disabled,
    placeholder,
    className,
    style,
  } = props;
  const [localValue, setLocalValue] = useState(null);
  const [display, setDisplay] = useState(false);

  const _onChange = (e) => {
    setLocalValue(e.hex);
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);


  useEffect(() => {
    if(display === false && localValue !== null){
      onChange(localValue);
    }
  }, [display]);

  return (
    <div className={`${styles.container} ${className}`} style={{...style, position: "relative"}}>
      {label ? <span className="block text-gray-600 mb-1">{label}:</span> : null}
      <div className={styles.input}>
        <div className={styles.colorRenderer} style={{backgroundColor: value ?? "#3e84d7"}}
          onClick={() => setDisplay(!display)}>
        </div>
      </div>
      {display && <div onClick={() => setDisplay(false)} style={{position: "fixed", zIndex: 1005, top: 0, left: 0, right: 0, bottom: 0}}></div>}
      {display ? 
        <div style={{position: "absolute", zIndex: 1010}}>
          <div style={{}} >
            <SketchPicker
              disableAlpha
              color={localValue}
              onChange={_onChange}
            />
          </div>
        </div>
        : null}
    </div>
  );
};

ColorPicker.propTypes = {
  value : PropTypes.string,
  onChange : PropTypes.func,
  label : PropTypes.string,
  invalid : PropTypes.any,
  disabled : PropTypes.any,
  placeholder : PropTypes.any,
  className : PropTypes.string,
  style : PropTypes.any,
};

export default ColorPicker;