import React, { createContext, useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import firebase from "firebase";
import { useContext } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const firestore = firebase.firestore();

const Ctx = createContext();

const Reducer = (state, action) => {
  switch (action.type) {
    case "setIsLoading":
      return { ...state, isLoading: action.isLoading };
    case "setGroupement":
      return { ...state, groupement: action.groupement };
    case "setInitialState":
      return { ...state, initialState: action.initialState };
    case "setRegions":
      return { ...state, regions: action.regions };
    case "setPresta":
      return { ...state, presta: action.presta };
    case "setEstablishments":
      return { ...state, establishments: action.establishments, };
    default:
      return { ...state };
  }
};

const Default = {
  isLoading: true,
  groupement: null,
  initialState: null,
  error: {},
  regions: {},
  presta: {},

  // fonctionnement
  establishments: [],
  page: 0, // 0: infos, 1: abonnement
};

const Provider = ({ children, id }) => {
  const [state, dispatch] = useReducer(Reducer, Default);
  const history = useHistory();

  //fetch etabs
  useEffect(() => {
    (async () => {
      try {
        const establishments = await firestore.collection("establishments").get();
        const _etabData = establishments.docs.map(doc => ({ uid: doc.id, ...doc.data() }));
        dispatch({ type: "setEstablishments", establishments: _etabData });

        const groupement = await firestore.collection("groupements").doc(id).get();
        const groupementData = groupement.data();
        dispatch({ type: "setGroupement", groupement: { ...groupementData } });
        dispatch({ type: "setInitialState", initialState: { ...groupementData } });

        let _regions = {};

        if (groupementData?.regions) {
          groupementData?.regions.forEach(regionName => {
            _regions[regionName] = [];
          });
          _etabData.forEach(_etab => {
            if (groupementData?.establishments.includes(_etab.uid)) {
              if (_regions[_etab.region]) {
                if (_etab.region) _regions[_etab.region].push(_etab.uid);
              }
            }
          });
        }

        dispatch({ type: "setRegions", regions: _regions });


        if (groupementData?.presta) {
          dispatch({ type: "setPresta", presta: groupementData.presta });
        }


        dispatch({ type: "setIsLoading", isLoading: false });
      } catch (error) {
        console.log("error", error);
      }
    })();
  }, []);

  const _public = {
    state,
    dispatch,
    save: async () => {
      // try {


      const batch = firestore.batch();

      const docGrpRef = firestore.collection("groupements").doc(id);

      const _data = {
        ...state.groupement,
        regions: Object.keys(state.regions)
      };

      if (state.groupement.specific === "ovelia")
        _data.presta = { ...state.presta };

      batch.update(docGrpRef, _data);


      const _etabsToUpdate = [...new Set([...state?.initialState?.establishments ?? [], ...state?.groupement?.establishments])];
      Object.values(state.establishments).filter(_etab => _etabsToUpdate.includes(_etab.uid)).forEach(_etab => {

        const docRef = firestore.collection("establishments").doc(_etab.uid);
        const _update = {};

        // groupement: id

        // les étabs qui ont été enlevés.
        if(state?.initialState?.establishments?.includes(_etab.uid) && !state?.groupement?.establishments?.includes(_etab.uid)){
          _update.region = firebase.firestore.FieldValue.delete();
          _update.groupement = firebase.firestore.FieldValue.delete();
        }
   

        // les étabs qui ont été rajoutés
        if(state?.groupement?.establishments?.includes(_etab.uid) && !state?.initialState?.establishments?.includes(_etab.uid)){
          _update.groupement = id;
          Object.entries(state.regions).forEach(([_region, _etabList]) => {
            if (_etabList.includes(_etab.uid))
              _update.region = _region;
          });
  
          if (!_update.region) {
            _update.region = firebase.firestore.FieldValue.delete();
          }
        }

        batch.update(docRef, _update);
      });

      await batch.commit();

      dispatch({ type: "setInitialState", initialState: { ...state.groupement } });

      toast.success("Groupement mis à jour");
      // } catch (error) {
      //   console.log("error", error);
      //   toast.error("Erreur lors de la mise à jour du groupement");
      // }
    },
    remove: async () => {
      try {
        if (window.confirm("Voulez-vous vraiment supprimer ce groupement ?")) {
          await firestore.collection("groupements").doc(id).delete();
          toast.success("Groupement supprimé");
          history.push("/dashboard/groupements");
        }
      } catch (error) {
        console.log("error", error);
        toast.error("Erreur lors de la suppression du groupement");
      }
    },
    add: async () => {
      console.log("add");
    }
  };

  return (
    <Ctx.Provider value={_public}>
      {children}
    </Ctx.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

const useCtx = () => useContext(Ctx);

export default useCtx;
export { Provider };