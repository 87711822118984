import React, {useState, useEffect} from "react";
import { Card, Spinner } from "../../../lib/components";
import firebase from "firebase";
import styles from "../../../assets/scss/pages/CutlureQuizz/home.module.scss";
import { PlusCircle } from "react-feather";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const firestore = firebase.firestore;

const CultureQuizz = () => {
  const history = useHistory();
  const [quizzs, setQuizzs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        let _quizzs = await firestore().collection("quizz").get();
        let _buffer = [];
        _quizzs.forEach(doc => _buffer.push({uid: doc.id, ...doc.data()}));
        setQuizzs(_buffer);
        setLoading(false);
      } catch (e) {
        console.error(e);
        toast.error(e.message);
      }
    })();
  }, []);

  const onCreate = () => {history.push("/dashboard/quizz/new");};
  const onEdit = (quizz) => {history.push(`/dashboard/quizz/${quizz.uid}`);};

  if (loading) return <Spinner />;

  return (
    <div className={styles.container}>
      <Card className={styles.card} onClick={onCreate}>
        <PlusCircle color="#300438" size={40} />
      </Card>
      {quizzs.map(q => (
        <Card className={styles.card} onClick={() => onEdit(q)} key={q.uid}>
          <span style={{fontSize: 28}}>{q.title ?? "Quizz sans nom"}</span>
        </Card>
      ))}
    </div>
  );
};

export default CultureQuizz;