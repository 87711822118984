import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useUI from "../../../../hooks/ui.hook";

import Table from "../detailedTable";
import ModalForm from "../ModalForm";
import { updatePlanning } from "../dataManager";
import moment from "moment";

import styles from "../../../../assets/scss/pages/reservation/planningDetails.module.scss";
import ExportTable from "../ExportTable";
import { Button } from "lib/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import XLSX from "xlsx-js-style";

import usePlanningReservation from "./contextReservationPlanning";
import { sendNotifToCentreNotif } from "../../../../services/ressources/notification";
import { getAnimationById } from "../../../../services/ressources/animations";

let noneCounter = 0;

// excel Styles
const baseBorder = {top: {style : "thin", color:{rgb: "000000"}}, bottom: {style : "thin", color:{rgb: "000000"}}, left: {style : "thin", color:{rgb: "000000"}}, right: {style : "thin", color:{rgb: "000000"}}};
const enhancedTopBorder = {top: {style : "thick", color:{rgb: "000000"}}, bottom: {style : "thin", color:{rgb: "000000"}}, left: {style : "thin", color:{rgb: "000000"}}, right: {style : "thin", color:{rgb: "000000"}}};
const enhancedLeftBorder = {top: {style : "thin", color:{rgb: "000000"}}, bottom: {style : "thin", color:{rgb: "000000"}}, left: {style : "thick", color:{rgb: "000000"}}, right: {style : "thin", color:{rgb: "000000"}}};
const baseFont = {sz: 12};
const headerFont = {sz: 15, bold: true};
const baseAlignment = { vertical: "center", horizontal: "center", wrapText: true }; 
const baseFill = {fgColor: {rgb: "B3FFBF"}};


const Details = () => {
  const [ctx] = usePlanningReservation();
  const [ui] = useUI();
  const [columns, setColumns] = useState([]);
  const [organisedData, setOrganisedData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalExportOpen, setModalExportOpen] = useState(false);

  const [modalData, setModalData] = useState({});

  const onClick = (cell) => {
    let usefulData = {
      type: "checkbox",
      uid: cell.row.original.userId,
      name: cell.row.original.name,
      date: cell.column.parent.id,
      idEvent: cell.column.id,
      title:
        cell.column.Header +
        " " +
        cell.column.parent.Header +
        " " +
        cell.column.time,
      value: cell.value == "x" ? true : false,
    };

    setModalData(usefulData);
    setModalOpen(true);
  };

  const onSave = async (_data) => {
    let action = "remove";
    console.log("dd",_data);
    const animTitle = await getAnimationById({etabId:ui.user.establishment,animId:_data.idEvent})
    console.log(animTitle);
    if (_data.newValue) {action = "add";
    sendNotifToCentreNotif({
      ui,
      data: {
        type: "animation",
        action: "add",
        data: {
          userId: _data.uid,
          name: animTitle.title,
          date: animTitle.start,
          end: animTitle.end
        }
      }
    }); 
    }else{
      sendNotifToCentreNotif({
        ui,
        data: {
          type: "animation",
          action: "delete",
          data: {
            userId: _data.uid,
            name: animTitle.title,
            date: animTitle.start,
            end: animTitle.end
          }
        }
      }); 
    }
    updatePlanning(ui.user.establishment, _data.idEvent, _data.uid, action);

    ctx.updateData(_data, action);
  };
  const onClose = () => {
    setModalOpen(false);
  };

  const onCloseExport = () => {
    setModalExportOpen(false);
  };

  const generateExportData = (period, date, identifiedBySurname, identifiedByName, identifiedByNumero, total, signature) => {
    let generatedData = null;

    let header = {};

    if(identifiedByNumero){
      header["Appartement"] = [];
    }
    if(identifiedBySurname){
      header["Prénom"] = [];
    }
    if(identifiedByName){
      header["Nom"] = [];
    }


    let _users = {};
    Object.entries(ctx.etabUsers).forEach(([key,value]) => {
      if(value.isDeleted && moment(value.deletedAt.toDate()).isBefore(moment(ctx.selectedWeek).startOf("week"))) return;
      _users[key] = {surname: value.surname, name : value.name, room: value.room, table: []};
    });

    let totalList = [];
    let localDate = date.clone();
    let numDays = - localDate.diff(localDate.clone().add(1,period), "days");
    for (let i = 1; i <= numDays; i++) {
      let formated = localDate.format("YYYY-MM-DD");
      header[localDate.format("ddd DD/MM")] = [];
      if (ctx.dataPlanning[formated] != undefined) {
        ctx.dataPlanning[formated].forEach(_event => {
          let obj = {};
          let obj_hours = {};
          obj_hours[ moment(_event.start.seconds * 1000).format("HH:mm") +"-"+moment(_event.end.seconds * 1000).format("HH:mm")] = [];
          obj[_event.title] = [obj_hours];
          header[localDate.format("ddd DD/MM")].push(obj);

          if(total){
            if(_event.reservation != undefined){
              totalList.push(_event.reservation.length.toString());
            }else totalList.push("");
            
          }
          Object.entries(_users).forEach(([key,value]) => {
            if(_event.reservation != undefined){
              if(_event.reservation.indexOf(key) > -1)value.table.push("x");
              else value.table.push("");
            }else value.table.push("");
              
          });

        });
      } else { // aucun événement ce jour
        Object.entries(_users).forEach(([key,value]) => {
          value.table.push("");
        });
        if(total) totalList.push("");
      }
      localDate = moment(localDate).add(1, "day");
    }

    if(total){
      header["Total"] = [];
    }
    if(signature){
      header["Signature"] = [];
    }

    let _data = [];
    Object.values(_users).forEach((value) => {
      let table = value.table;
      let sum = 0;
      if(total){
        table.forEach(element => {
          if(element == "x")sum++;
        });
      }
      if(identifiedByName){
        table = [value.name, ...table];
      }
      if(identifiedBySurname){
        table = [value.surname, ...table];
      }
      if(identifiedByNumero){
        table = [value.room, ...table];
      }
      if(total){
        table = [...table, sum];
      }
      if(signature){
        table = [...table, ""];
      }
      _data.push(table);
    });
    let colStart = 0 + identifiedBySurname + identifiedByName + identifiedByNumero; // true == 1, false == 0;
    let colEnd = 0 + total + signature;

    if(total){
      let total_total = 0;
      totalList.forEach(element => {
        let num = parseInt(element);
        if(!isNaN(num))total_total += num;
      });

      for (let index = 0; index < colStart-1; index++) {
        totalList = ["",...totalList];
      }
      totalList = ["Total",...totalList];

  
      totalList = [...totalList,total_total.toString()];

      _data.push(totalList);
    }
    
    generatedData = {
      header: header,
      data : _data,
      depth : 3,
      colStart: colStart,
      colEnd: colEnd
    };

    return generatedData;
  };

  const exportDay = (_data) => {
    const _eventId = _data.id;

    const _event = ctx.dataRaw.find(e => e.uid === _eventId);

    const columns = [
      "Nom", "Prénom"
    ];
    columns.push(_event.title);

    const data = [];

    if(_event.reservation){
      // console.log("EtabUsers", ctx.etabUsers);
      // console.log("Reservation", _event.reservation);
      _event.reservation.forEach(uid => {
        data.push({
          //  Le tableau de reservation ne contient pas seulement des uid mais aussi des objets utilisateurs
          //  La source de cette différence n'a pas été identifiée
          "Nom" : ctx.etabUsers[uid] ? ctx.etabUsers[uid].name : uid.name ,
          "Prénom" : ctx.etabUsers[uid] ? ctx.etabUsers[uid].surname : uid.surname,
          [_event.title] : "",
        });
      });
    }
    

    const wb = XLSX.utils.book_new();
    
    const headerStyle = {border : baseBorder, alignment: baseAlignment, font: headerFont};

    const excelData = [];

    const header = [];
    const columnsSize = [];
    const rowSize = [];

    columns.forEach(element => {
      header.push({v: element, s: headerStyle});
      columnsSize.push({width: 21});
    });
    rowSize.push({});

    excelData.push(header);

    data.forEach((element, indx) => {
      const line = [];
      let dataStyle = {border : baseBorder, alignment: baseAlignment, font: baseFont};
      if(indx === 0)dataStyle.border = enhancedTopBorder;
      Object.values(element).forEach((_value) => {
        line.push({v: _value, s: dataStyle});
      });
      excelData.push(line);
      rowSize.push({"hpt" : 20});
    });
      
    let sheet = XLSX.utils.aoa_to_sheet(excelData);
    sheet["!cols"] = columnsSize;
    sheet["!rows"] = rowSize;

    XLSX.utils.book_append_sheet(wb, sheet);
    const filename = "participants_"+moment(_event.start.toDate()).format("YYYY-MM-DD")+"_"+_event.title;
    XLSX.writeFile(wb, filename+".xlsx");
  };

  useEffect(() => {
    if (ctx.dataSelected == undefined) return;
    let _columns = [
      {
        Header: "Rechercher",
        Footer: "",
        accessor: "search",
        sticky: "left",
        disableSortBy: true,
        columns: [
          {
            Header: "Nom",
            Footer: "TOTAL",
            accessor: "name",
            filter: "fuzzyText",
            sticky: "left",
            width: 200,
          },
            {
              Header: "détails",
              Footer: " ",
              accessor: "details",
              disableSortBy: true,
              width: 60,
              Cell: CellDetails
            }
        ],
      },
    ];

    Object.keys(ctx.dataSelected).forEach((element) => {
      let list = ctx.dataSelected[element];
      list.sort((a, b) => a.start - b.start);

      let listColums = [];
      list.forEach((event) => {
        listColums.push({
          Header: event.title,
          Footer: `${
            event.reservation != undefined ? event.reservation.length : " "
          }`,
          time:
            moment(event.start.seconds * 1000).format("HH:mm") +
            "-" +
            moment(event.end.seconds * 1000).format("HH:mm"),
          export: exportDay,
          accessor: event.id,
          canUpdate: true,
        });
      });
      if (listColums.length == 0) {
        listColums.push({
          Header: "aucune activité",
          Footer: " ",
          accessor: "none" + noneCounter,
        });
        noneCounter++;
      }
      _columns.push({
        Header: moment(element).format("ddd DD/MM"),
        id: element,
        Footer: "",
        columns: listColums,
      });
    });

    let organisedDataDict = {};

    Object.entries(ctx.etabUsers).forEach(([uid, _userData]) => {
      if(_userData.isDeleted && moment(_userData.deletedAt.toDate()).isBefore(moment(ctx.selectedWeek).startOf("week"))) return;
      let name = `${_userData.name} ${_userData.surname} ${_userData.room ?? ""}`;
      if (organisedDataDict[name] == undefined) {
        organisedDataDict[name] = {};
      }
      organisedDataDict[name].userId = uid;
    });

    Object.entries(ctx.dataSelected).forEach(([key, value]) => {
      value.forEach((event) => {
        if (event.reservation != undefined) {
          event.reservation.forEach((uid) => {
            if(ctx.etabUsers[uid]){
              if(ctx.etabUsers[uid].isDeleted && moment(ctx.etabUsers[uid].deletedAt.toDate()).isBefore(moment(ctx.selectedWeek).startOf("week"))) return;
              let name = `${ctx.etabUsers[uid].name} ${ctx.etabUsers[uid].surname} ${ctx.etabUsers[uid].room ?? ""}`;
              organisedDataDict[name][event.id] = "x";
              organisedDataDict[name].userId = uid;  
            }
            // générateur de beaucoup de noms
            /*
            for(let i=0; i<20; i++){
              let nameG = "nom"+i;
              if (organisedDataDict[nameG] == undefined) {
                organisedDataDict[nameG] = {};
              }
              organisedDataDict[nameG][event.id] = "x";
            }*/
          });
        }
      });
    });

    let _organisedData = [];

    Object.entries(organisedDataDict).sort((a,b) => a[0].localeCompare(b[0])).forEach(([key, value]) => {
      let obj = value;
      obj.name = key;
      _organisedData.push(obj);
    });

    setColumns(_columns);
    setOrganisedData(_organisedData);
  }, [ctx.dataSelected]);

  if(!ctx.dataSelected) return <></>;

  return (
    <div className={styles.details}>
      <Button className={styles.exportButton} color="primary" onClick={() => setModalExportOpen(true)}>Exporter</Button>
      <Table
        _columns={columns}
        _data={organisedData}
        colWidth={140}
        hasFooter={true}
        onClick={onClick}
      />
      {modalOpen ? (
        <ModalForm onClose={onClose} onSave={onSave} data={modalData} />
      ) : null}
      {modalExportOpen ? (
        <ExportTable onClose={onCloseExport} dataGeneration={generateExportData} infos={{title:"Export des Animations", type:"event", date:ctx.selectedDate}}/>
      ) : null}
    </div>
  );
};

const CellDetails = ({ cell }) => {
  const [ctx, dispatch] = usePlanningReservation();

  const clickUser = () =>{
    dispatch({ type: "setProperty", property : "modalWeekOpen", value:true});
    dispatch({ type: "setProperty", property : "modalInfos", value:{
      uid: cell.row.original.userId,
      user: cell.row.original.name,
    }});
    //alert("coucou");
    //setUserId(uid);
  };

  return <FontAwesomeIcon
    icon={faSearch}
    style={{"cursor" : "pointer"}}
    onClick={() => clickUser(cell.row.original.userId)}

  />;
};
CellDetails.propTypes = {
  cell: PropTypes.object.isRequired,
};


export default Details;
