import React from "react";
import PropTypes from "prop-types";

import Basic_ExportWeek from "./Basic/PDF/ExportWeek";


import HappySenior_ExportWeek from "./Specific/happysenior/PDF/ExportWeek";

import Girandieres_ExportWeek from "./Specific/girandieres/PDF/ExportWeek";

import Jasmins_ExportWeek from "./Specific/jasmins/PDF/ExportWeek";

import Ovelia_ExportDay from "./Specific/ovelia/PDF/ExportDay";
import Ovelia_ExportWeek from "./Specific/ovelia/PDF/ExportWeek";

import Heurus_ExportWeek from "./Specific/heurus/PDF/ExportWeek";
import Heurus_ExportDay from "./Specific/heurus/PDF/ExportDay";
import Heurus_ExportDayRestaurant from "./Specific/heurus/PDF/ExportDayRestaurant";

import MarpaLeCanalet_ExportWeek from "./Specific/marpaLeCanalet/PDF/ExportWeek";



const ExportWeek = (props) => {
    const { ui } = props;

    if (ui.establishment.menuTemplate === "happySenior") return <HappySenior_ExportWeek {...props} />;
    if (ui.establishment.menuTemplate === "girandieres") return <Girandieres_ExportWeek {...props} />;
    if (ui.establishment.menuTemplate === "jasmins") return <Jasmins_ExportWeek {...props} />;
    if (ui.establishment.menuTemplate === "marpa_le_canalet") return <MarpaLeCanalet_ExportWeek {...props} />;
    if (ui?.groupement?.specific === "heurus") return <Heurus_ExportWeek {...props} />;
    if (ui?.groupement?.specific === "ovelia") return <Ovelia_ExportWeek {...props} />;
    return <Basic_ExportWeek {...props} />;
};

const ExportDay = (props) => {
    const { ui } = props;

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_ExportDay {...props} />;
    if (ui?.groupement?.specific === "heurus") return <Heurus_ExportDay {...props} />;
    return <></>;
};

const ExportDayRestaurant = (props) => {
    const { ui } = props;

    if (ui?.groupement?.specific === "heurus") return <Heurus_ExportDayRestaurant {...props} />;
    return <></>;
};


ExportWeek.propTypes = {
    ui: PropTypes.object,
};
ExportDay.propTypes = {
    ui: PropTypes.object,
};
ExportDayRestaurant.propTypes = {
    ui: PropTypes.object,
};




export { ExportWeek, ExportDay, ExportDayRestaurant };