import React, {useContext, useEffect, useState} from "react";
import useWindowSize from "../../hooks/useWindowSize.hook";
import {SidebarContext} from "../../context/dashboard/sidebar.context";
import PropTypes from "prop-types";

import styles from "../../assets/scss/layout/dashboard/leftSidebar.module.scss";

const LeftSidebar = ({children}) => {
  const [sidebarOpen, setSidebarOpen] = useContext(SidebarContext);
  const [prevSize, setPrevSize] = useState(3000);
  const size = useWindowSize();

  useEffect(() => {
    if (size.width < 550 && prevSize >= 550)
      setSidebarOpen(false);
    else if (size.width > 550 && prevSize <= 550)
      setSidebarOpen(true);
    setPrevSize(size.width);
  }, [size.width, size.height, setSidebarOpen, prevSize]);

  return (
    <div className={`${styles.sidebar} ${sidebarOpen ? styles.open : ""}`}>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
};

export default LeftSidebar;