import React from "react";
import PropTypes from "prop-types";
import styles from "../assets/scss/component/separator.module.scss";

const Separator = (props) => {
  const {children, className, style} = props;

  return (
    <div className={`${styles.separator} ${className ?? ""}`} style={style ?? {}}>
      <span className={styles.innerText}>{children}</span>
    </div>
  );
};

Separator.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object
};

export default Separator;