import React, { useState, useEffect, useMemo, useCallback } from "react";
import useUI from "hooks/ui.hook";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
  FormGroup,
  Select,
  Tag,
} from "lib/components";

import deepEqual from "deep-equal";
import { ModalInformation, CategorySelector } from "../../../router_components";
import { useMenuReservation } from "../../../router_context";

import styles from "../../../Basic/Assets/scss/modalReservation.module.scss";
import styles2 from "../scss/modalReservation.module.scss";

import PropTypes from "prop-types";
import deepClone from "helpers/deepClone";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faUserMinus } from "@fortawesome/free-solid-svg-icons";
import { sendNotifToCentreNotif } from "services/ressources/notification";

const fixe = ["assiette de fromage", "faisselle"];
const colors = ["#8c66dc", "#dc6681", "#00bfb2", "#DC965A", "#81171B"];
const colorUnite = "#123865";

const ModalReservation = (props) => {
  const { 
    type = "resident",
  } = props;
  const [ui] = useUI();
  const [ctx, dispatch] = useMenuReservation();

  const template = ui.establishment.template;
  const templateSubscription = ui.establishment.templateSubscription;

  

  const [reservation, setReservation] = useState([]);
  const [hasPaid, setHasPaid] = useState(false);
  const [empty, setEmpty] = useState(false);

  const [page, setPage] = useState(0);

  const getResa = (type) => {
    if(type === "resident") return deepClone(ctx.data[ctx.modalInfos.date].reservation[ctx.modalInfos.uid][ctx.modalInfos.repas]).reverse();
    if(type === "guest") return deepClone(ctx.dataGuestOrders[ctx.modalInfos.date][ctx.modalInfos.uid][ctx.modalInfos.repas]);
    return null;
  };

  //aliases
  const dataDay = ctx?.data[ctx?.modalInfos?.date];
  const currentUser = ctx?.users[ctx?.modalInfos?.uid] ?? (ctx.guests && ctx.guests.find(guest => guest.guestId === ctx.modalInfos.uid)) ?? {};
  const isRestaurantOpen = ui.establishment.isRestaurantOpen ?? true;
  const subscription = currentUser?.subscription && currentUser?.subscriptionMonths && currentUser?.subscriptionMonths.includes(moment(ctx?.selectedDate).format("YYYY-MM")) ? currentUser?.subscription : null;
  const isEmployee = currentUser.role === "employee";
  useEffect(() => {
    try {
      setPage(0);
      const _resa = getResa(type);
      if (_resa) {
        setReservation(_resa);

        if (_resa[0] && _resa[0].status !== "canceled") {
          setEmpty(false);
        } else {
          setEmpty(true);
        }

      } else {
        setReservation([defaultReservationObject()]);
        setEmpty(true);
      }

      if (type === "guest" && currentUser.type === "guest") {
        setHasPaid(currentUser.hasPaid);
      }

    } catch (error) {
      setReservation([defaultReservationObject()]);
      setEmpty(true);
      setPage(0);
    }
  }, [ctx.modalResaOpen, type]);


  const defaultReservationObject = () => {
    if(template && ctx?.modalInfos?.repas &&  template[ctx.modalInfos.repas].categories){
      const _defaultMenu = {};

      template[ctx.modalInfos.repas].categories.map((_categ) => _defaultMenu[_categ] = null);
      _defaultMenu["supplément"] = null;
  
      const _resaObj = {
        ..._defaultMenu,
        homeDelivery: reservation?.[0]?.homeDelivery ?? (ctx.modalInfos.repas === "Dîner" && isRestaurantOpen === false) ? true : false
      };
      return _resaObj;

    }else{
      return {
        homeDelivery: false
      };
    }
  };

  const _save = () => {
    ctx.updateMenu(reservation);
    const _initial = ctx.data[ctx.modalInfos.date] && ctx.data[ctx.modalInfos.date].reservation && ctx.data[ctx.modalInfos.date].reservation[ctx.modalInfos.uid] ? ctx.data[ctx.modalInfos.date].reservation[ctx.modalInfos.uid][ctx.modalInfos.repas] ?? [] : [];
    const dateFinal = new Date(ctx?.modalInfos?.date);
    if(_initial.length === 0 ){
      sendNotifToCentreNotif({
      ui,
      data: {
        type: "menu",
        action: "add",
        data: {
          homeDelivery: reservation[0]?.homeDelivery,
          quantity: reservation?.length,
          userId: currentUser.uid,
          name: ctx?.modalInfos?.repas,
          date: dateFinal,
        }
      }
    }); 
    }else{
      sendNotifToCentreNotif({
      ui,
      data: {
        type: "menu",
        action: "update",
        data: {
          homeDelivery: reservation[0]?.homeDelivery,
          quantity: reservation?.length,
          userId: currentUser.uid,
          name: ctx?.modalInfos?.repas,
          date: dateFinal,
        }
      }
    }); }
    dispatch({ type: "setProperty", property: "modalResaOpen", value: false });
  };

  const _delete = () => {
    ctx.updateMenu("delete");
    const dateFinal = new Date(ctx?.modalInfos?.date);
    sendNotifToCentreNotif({
      ui,
      data: {
        type: "menu",
        action: "delete",
        data: {
          homeDelivery: reservation[0]?.homeDelivery,
          quantity: reservation?.length,
          userId: currentUser.uid,
          name: ctx?.modalInfos?.repas,
          date: dateFinal,
        }
      }
    }); 
    dispatch({ type: "setProperty", property: "modalResaOpen", value: false });
  };


  const updateHomeDelivery = () => {
    let currentValue = reservation[0].homeDelivery;

    let _resa = deepClone(reservation);

    _resa.forEach(element => {
      element.homeDelivery = !currentValue;
    });

    setReservation(_resa);
  };

  const updatePaid = () => {
    setHasPaid(!hasPaid);
    const _currentGuest = deepClone(currentUser);

    _currentGuest.hasPaid = !hasPaid;
    ctx.updateGuestPaid(_currentGuest);
  };

  const updateSelection = (category, choice) => {
    let currentResa = deepClone(reservation);

    if(category === "garniture") {
      if(!currentResa[page][category])
        currentResa[page][category] = [];
      // Cas où on l'a déjà séléctionné => on le retire
      if(currentResa[page][category].includes(choice)) {
        currentResa[page][category] = currentResa[page][category].filter((item) => item !== choice);
      } else {
        // sinon on l'ajoute
        currentResa[page][category] = [...currentResa[page][category],choice];

      }
    } else {
      currentResa[page][category] = choice;

    }
    //cas ou on change de dessert, on doit vérifier si un dessert n'était pas sélectionné dans les suppléments.

    if (category === "dessert") {
      const dessertIdList = Object.keys(dataDay[ctx.modalInfos.repas]["dessert"]);

      // s'il y avait un dessert dans les suppléments et qu'un dessert est choisi dans la catégorie dessert. On supprime celui du supplément.
      if (dessertIdList.includes(currentResa[page]["supplément"]) && dessertIdList.includes(choice)) {
        currentResa[page]["supplément"] = null;
      }

      // on doit ajouter le fromage dans le cas où il est pas déjà pris dans la catégorie dessert
      if (choice === fixe[0] && currentResa[page]["supplément"] === fixe[0]) {
        currentResa[page]["supplément"] = null;
      }

      // on doit ajouter la faisselle dans le cas où elle n'est pas déjà prise dans la catégorie dessert.
      if (choice === fixe[1] && currentResa[page]["supplément"] === fixe[1]) {
        currentResa[page]["supplément"] = null;
      }
    }

    setReservation(currentResa);
  };

  const addResa = () => {
    let currentResa = deepClone(reservation);

    currentResa.push(defaultReservationObject());

    setReservation(currentResa);
    setPage(currentResa.length - 1);
  };

  const removeResa = (idx) => {
    let indxToRemove = idx ?? reservation.length > 1 ? reservation.length - 1 : null;

    if (indxToRemove) {
      let currentResa = deepClone(reservation);

      currentResa.splice(indxToRemove, 1);

      setReservation(currentResa);
      setPage(page - 1);
    }

  };

  const hasChanged = useMemo(() => {
    // On compare la resa actuelle à la resa initiale :
    const _initial = ctx.data[ctx.modalInfos.date] && ctx.data[ctx.modalInfos.date].reservation && ctx.data[ctx.modalInfos.date].reservation[ctx.modalInfos.uid] ? ctx.data[ctx.modalInfos.date].reservation[ctx.modalInfos.uid][ctx.modalInfos.repas] ?? [] : [];

    if (deepEqual(_initial, reservation)) {
      return false;
    } else {
      return true;
    }
  }, [reservation, ctx]);

  const getFormula = useCallback((_page) => {
    if (ctx.modalInfos.repas === "Déjeuner") {
      let count = 0;
      if (reservation[_page]) {

        if (reservation[_page]["entrée"]) count++;
        if (reservation[_page]["plat"]) count++;
        if (reservation[_page]["dessert"]) count++;
      }

      if (count === 2) return "DUO";
      if (count === 3) return "TRIO";

      return null;
    }
    if (ctx.modalInfos.repas === "Dîner") {
      let count = 0;
      if (reservation[_page]) {

        if (reservation[_page]["entrée"]) count++;
        if (reservation[_page]["plat"]) count++;
        if (reservation[_page]["dessert"]) count++;
      }

      if (count >= 2) return "DUO";
      return null;
    }
    return null;
  }, [reservation, ctx]);


  const isValid = useMemo(() => {
    let _valid = true;

    reservation.forEach((_resa, _page) => {
      if (!getFormula(_page)) _valid = false;
    });

    return _valid;
  }, [reservation, ctx]);


  return (
    <Modal isOpen={ctx.modalResaOpen} toggle={() => dispatch({ type: "setProperty", property: "modalResaOpen", value: false })} className={styles.modal}>
      <ModalHeader className={styles.modalHeader}>
        <div className={styles.modalHeaderComponent}>

          <div className={styles.modalTitle}>
            Réservation pour le {ctx?.modalInfos?.repas} du <span className={styles.headerDate}>{(ctx?.modalInfos?.date ?? "").split("-").reverse().join("/")}</span>
          </div>

          <div className={styles.headerContent}>
            {page === 0 && templateSubscription ?
              <Tag className={styles.tag} style={{ backgroundColor: subscription ? colors[Object.keys(templateSubscription).indexOf(subscription) % 5] : colorUnite }}><div>{subscription ?? "Unité"}</div></Tag>
              : null}
          </div>

        </div>
        
      </ModalHeader>
      <ModalBody style={{ padding: 0 }}>
        <div className={styles.headerResa}>
          <div className={styles.resaList}>
            <div className={page === 0 ? styles.resaSelected : null} onClick={() => setPage(0)}> {currentUser?.surname} {currentUser?.name}</div>
            {reservation.length > 1 ?
              reservation.map((_r, indx) => (
                indx > 0 ?
                  <div key={indx} onClick={() => setPage(indx)} className={page === indx ? styles.resaSelected : null}>
                    <div>
                      {`(Invité ${indx})`}
                    </div>
                  </div>
                  : null
              ))
              : null}
          </div>
          <div className={styles.resaButton}>
              {!isEmployee && type !== "guest"?
                <>
                  {page > 0 ? <div color="danger" onClick={removeResa} style={{ color: "red", textAlign: "center" }}> <FontAwesomeIcon icon={faUserMinus} color="red" /> Supprimer cette invitation</div> : null}
                  <div onClick={addResa} style={{textAlign: "center"}}> <FontAwesomeIcon icon={faUserPlus} color="#300438" /> Inviter une personne</div>
                </>
                : null}
          </div>
        </div>
        <div className={styles.modalContainer}>
          <ModalInformation reservation={reservation} page={page} />
          <div className={styles.contentHorizontal}>
            <div className={styles.contentVertical}>
              <div className={styles.content}>

                {reservation ?
                  <CategorySelector page={page} dataReservation={reservation[page]} updateSelection={(category, choice) => updateSelection(category, choice)} />
                  : null}

              </div>
              
            </div>
            
          </div>
          
        </div>
        {!isValid ?
          <div className={styles.notValid}>
            Veuillez sélectionner une formule pour pouvoir valider la réservation ! {reservation.length > 1 ? "(pour chaque invité)" : null} 
          </div> 
        : null}
      </ModalBody>
      <ModalFooter>
        <div className={styles.footer}>
        {type === "guest" && currentUser.type === "guest" &&
          <div className={styles2.checkboxContainer}>
            <Checkbox className={`${styles.checkbox} ${styles2.checkbox}`} label="Repas réglé" checked={hasPaid} onChange={updatePaid} />
          </div>}
          {type !== "guest" && <>
          <div>
            <Checkbox className={styles.checkbox} label="portage" checked={reservation ? reservation[0]?.homeDelivery : false} disabled={!isRestaurantOpen && ctx.modalInfos.repas === "Dîner"} onChange={updateHomeDelivery} />
          </div>
          <div className={styles.footerButtons}>
            {!empty ?
              <Button color="danger" onClick={_delete}>{reservation && reservation[0] && reservation[0].createdLocally ? "Annuler la réservation automatique" : "Supprimer"}</Button>
              : null}
            <Button color="secondary" onClick={() => dispatch({ type: "setProperty", property: "modalResaOpen", value: false })}>Annuler</Button>
            {dataDay ? <Button color="primary" disabled={!hasChanged || !isValid} onClick={_save}>Valider la réservation</Button> : null}
          </div>
          <div className={styles.nbresas}>
            {reservation ? reservation.length : 0} repas
          </div>
          </>}
        </div>
      </ModalFooter>
    </Modal>
  );
};

const SuspenseHOC = (props) => {
  const [ctx] = useMenuReservation();

  if (!ctx || !ctx.data || !ctx.modalInfos) return <></>;
  return <ModalReservation {...props} />;
};


ModalReservation.propTypes = {
  type: PropTypes.string,
};


export default SuspenseHOC;
