import {useState, useEffect} from "react";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import sleep from "../helpers/asyncWait";
import { getUserById } from "services/ressources/user";
import { getQuizzById,getQuestionsById } from "services/ressources/quizz";

const firestore = firebase.firestore;
const rtd = firebase.database;

const useCultureQuizzInstance = (instanceId) => {
  const history = useHistory();

  const [instance, setInstance] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [quizz, setQuizz] = useState(null);
  const [question, setQuestion] = useState(null);

  const [instanceLoading, setInstanceLoading] = useState(true);
  const [participantsLoading, setParticipantsLoading] = useState(true);
  const [quizzLoading, setQuizzLoading] = useState(true);

  // register for instance rtd updates
  useEffect(() => {
    rtd().ref(`quizz/${instanceId}`).on("value", snapshot => {
      setInstance(snapshot.val());
      setInstanceLoading(false);
    });
  }, [instanceId]);

  // fetch participants data
  useEffect(() => {
    (async () => {
      if (!instance || instance.participants === null || instance.participants === undefined) {
        setParticipants([]);
      } else {
        let _participants = await Promise.all(Object.keys(instance.participants).map(async (_p) => {
          return await getUserById({id: instance.participants[_p]});
        }));
        setParticipants(_participants);
      }
      setParticipantsLoading(false);
    })();
  }, [instance]);

  // fetch quizz data
  useEffect(() => {
    (async () => {
      let _instance = (await rtd().ref(`quizz/${instanceId}`).once("value")).val();
      let _quizz = await getQuizzById({id: _instance.quizzId});
      let _questions = await getQuestionsById({id:_instance.quizzId});
      
      _quizz.questions = [..._questions];
      setQuizz(_quizz);
      setQuizzLoading(false);
    })();
  }, [instanceId]);

  // get question data
  useEffect(() => {
    if (!instance || instance.status !== "started" || !quizz) {
      setQuestion(null);
      return;
    } else {
      let _question = quizz.questions.find(q => q.uid === `${instance.currentQuestion}`);
      if (!_question) {
        update({status: "results"});
        history.push(`/dashboard/cultureQuizz/${instanceId}/results`);
      } else {
        setQuestion(_question);
      }
    }
  }, [instance, quizz]);

  const update = async (data) => {
    await rtd().ref(`quizz/${instanceId}`).update(data);
  };

  const abort = async () => {
    update({status: "aborted"});
    sleep(3000);
    rtd().ref(`quizz/${instanceId}`).remove();
    history.push("/quizz");
  };


  return ({
    loading: instanceLoading || participantsLoading || quizzLoading,
    instance,
    quizz,
    participants,
    question,
    update,
    abort
  });
};

export default useCultureQuizzInstance;