import { useState, useEffect } from "react";
import useUi from "./ui.hook";
import mapStore from "store/mapStore";


const mapHandler = () => {
    const [ui] = useUi();
    const mapRef = mapStore((state) => state.mapRef);
    const mapUi = mapStore((state) => state.mapUi);

    const setMapRef = mapStore((state) => state.setMapRef);
    const setPlatform = mapStore((state) => state.setPlatform);
    const setService = mapStore((state) => state.setService);
    const setMapUi = mapStore((state) => state.setMapUi);
    const setLat = mapStore((state) => state.setLat);
    const setLng = mapStore((state) => state.setLng);
    const setZoom = mapStore((state) => state.setZoom);

    const setGroup = mapStore((state) => state.setGroup);
    const setGroupList = mapStore((state) => state.setGroupList);

    const addToGroupList = mapStore((state) => state.addToGroupList);


    useEffect(()=>{
        if(mapRef && mapUi){
        // if(group)mapRef.removeObject(group);
        let group = new H.map.Group();

        mapRef.addObject(group);

        // add 'tap' event listener, that opens info bubble, to the group
        group.addEventListener("tap", function (evt) {
            // event target is the marker itself, group is a parent event target
            // for all objects that it contains
            let bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
            // read custom data
            content: evt.target.getData()
            });

            mapUi.addBubble(bubble);
        }, false);

        setGroup(group);
        }
    },[mapRef,mapUi]);

    

    const init = (map, platform, service, mapUi) => {
        setMapRef(map);
        setPlatform(platform);
        setService(service);
        setMapUi(mapUi);
    };

    const changeView = (lat, lng, zoom) => {
        setLat(lat);
        setLng(lng);
        setZoom(zoom);
    };


    /**
     * Creates a new marker and adds it to the map
     * @param {Number} lat  The location of the marker
     * @param {Number} lng  The location of the marker
     * @param {String} color  The marker color
    * @param {String} groupName  The group to add the marker
     */
    function addBasicMarker(lat, lng, color, groupName) {
        const svg = "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"" + color + "\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-map-pin\"><path d=\"M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z\"></path><circle cx=\"12\" cy=\"10\" r=\"3\"></circle></svg>";

        let markerIcon = new H.map.Icon(svg);

        let marker = new H.map.Marker({ lat, lng }, { icon: markerIcon});
        // console.log(marker, mapRef);

        if (groupName) {
            const groupList = mapStore.getState().groupList;
            if(groupList[groupName])
                groupList[groupName].addObject(marker);
        } else {
            mapRef.addObject(marker);
        }

    }

    const genSVG = (color1, color2) => {
        return `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin">
        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" fill="${color1 ?? "none"}" stroke="currentColor"></path>
        <circle cx="12" cy="10" r="3.5" fill="${color2 ?? "white"}" stroke="black"  stroke-width="1"></circle>
        </svg>
        `;
    };

    /**
     * Creates a new marker and adds it to the map
     * @param {Number} lat  The location of the marker
     * @param {Number} lng  The location of the marker
     * @param {String} html  The Html code of the Bubble
     * @param {String} color  The marker color     
     * @param {String} groupName  The group to add the marker
     */
    const addBubbleMarker = (lat, lng, html, color, groupName) => {
        let _color = color ?? "none";

        const svg = genSVG(_color);

        //"<div class=\"infoDiv\"><div>"+ name +"<div> <a href=\"/dashboard/one/"+id+"\"><div>Prendre le contrôle</div></a></div>"
        let markerIcon = new H.map.Icon(svg);

        let marker = new H.map.Marker({lat, lng}, { icon: markerIcon });

        // setTimeout(() => {
        //     marker.setIcon(new H.map.Icon("<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"" +"red" + "\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"feather feather-map-pin\"><path d=\"M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z\"></path><circle cx=\"12\" cy=\"10\" r=\"3\"></circle></svg>"));
        // }, 7000);

        marker.setData(html);
        // dispatch({type: "addToList", property: "bubbleMarkerList", value: marker});

        if (groupName) {
            const groupList = mapStore.getState().groupList;
            if(groupList[groupName])
                groupList[groupName].addObject(marker);
        } else {
            const group = mapStore.getState().group;
            group.addObject(marker);
        }

        return marker;
    };


    /**
     * Adding a new group of markers
     * @param {String} names The names / id of the groups
     */
    const addGroup = (name) => {
        let group = new H.map.Group();

        const _group = mapStore.getState().group;
        if(_group){
            _group.addObject(group);
        }else{
            mapRef.addObject(group);
        }
        addToGroupList(name, group);
    };

    /**
     * Hiding group of markers by name
     * @param {String} name  The name / id of the group
     */
    const hideGroup = (name) => {
        const groupList = mapStore.getState().groupList;
        if(groupList[name])
            groupList[name].setVisibility(false);
    };


    /**
     * Show group of markers by name
     * @param {String} name  The name / id of the group
     */
    const showGroup = (name) => {
        const groupList = mapStore.getState().groupList;
        if(groupList[name])
            groupList[name].setVisibility(true);
    };

    const removeBasicMarkers = () => {
        mapRef.removeObjects(basicMarkerList);
    };


    const cleanup = () => {
        setMapRef(null);
        setMapUi(null);
        setGroup(null);
        setGroupList({});
    };

    return {init, changeView, addBasicMarker, addBubbleMarker, addGroup, hideGroup, showGroup, removeBasicMarkers, cleanup, genSVG};
};



export default mapHandler;