import React, { useState } from "react";
import {Container, Row, Col} from "reactstrap";

import YoutubePlayer from "./YoutubePlayer";
import actuCard from "../../../assets/images/TutoVideo/actuCard.svg";
import animCard from "../../../assets/images/TutoVideo/animationCard.svg";
import menuCard from "../../../assets/images/TutoVideo/menuCard.svg";
import styles from "../../../assets/scss/pages/Tutoriels/videoplayer.module.scss";
import { PlayCircle } from "react-feather";

const VideoLibrary = () => {
  const [selectedVideo, setSelectedVideo] = useState(
    "https://youtu.be/3_i0XoifFxU"
  );
  const [selectedVideoTitle, setSelectedVideoTitle] = useState(
    "Vidéo de présentation des actualités."
  );
  
  const videos = [
    {
      id: 1,
      src: "https://youtu.be/3_i0XoifFxU",
      title: "Vidéo de présentation des actualités.",
      shortTitle: "Actualités",
      duration: "1mn43"
    },
    {
      id: 2,
      src: "https://youtu.be/7MZ1mRH2BV4",
      title: "Vidéo de présentation des animations.",
      shortTitle: "Planning d'animation",
      duration: "2mn35"
    },
    {
      id: 3,
      src: "https://youtu.be/Xo7PSK9K4lQ",
      title: "Vidéo de présentation des menus.",
      shortTitle: "Menus",
      duration: "2mn29"
    },
    {
      id: 4,
      src: "https://youtu.be/1sKFXq0NE5g",
      title: "Vidéo de présentation du bandeau dynamique.",
      shortTitle: "Bandeau dynamique",
      duration: "0mn52"
    },
    {
      id: 5,
      src: "https://youtu.be/YQABjrtFQeQ",
      title: "Vidéo de présentation de culture quiz.",
      shortTitle: "Culture quiz",
      duration: "2mn11"
    },
    {
      id: 6,
      src: "https://youtu.be/W18_85zuhZ8",
      title: "Vidéo de présentation des ateliers stimulants",
      shortTitle: "Ateliers stimulants",
      duration: "2mn09"
    },
    {
      id: 7,
      src: "https://youtu.be/RvfDYtMWM3g",
      title: "Vidéo de présentation des données d'utilisation",
      shortTitle: "Données d'utilisation",
      duration: "1mn16"
    },
    {
      id: 8,
      src: "https://youtu.be/1P61Re4pRx4",
      title: "Vidéo de présentation des réservations",
      shortTitle: "Réservations",
      duration: "2mn01"
    },
    {
      id: 9,
      src: "https://youtu.be/u8OsB67dubY",
      title: "Vidéo de présentation de la gestion utilisateur",
      shortTitle: "Gestion utilisateur",
      duration: "1mn29"
    },
    {
      id: 10,
      src: "https://youtu.be/2SVFf0RpaGc",
      title: "Vidéo de présentation des notifications",
      shortTitle: "Notifications",
      duration: "1mn14"
    },
    {
      id: 11,
      src: "https://youtu.be/rgM06jOvick",
      title: "Vidéo de présentation de la Gazette",
      shortTitle: "Gazette",
      duration: "2mn52"
    }
  ];

  return (
    <Container><Row><Col lg={12}>
      <div className={styles.libContainer}>
        <div className={styles.videoPlayerContainer}>
          <YoutubePlayer src={selectedVideo.split("/")[3]} light={true} />
          <p className={styles.videoTitle}>{selectedVideoTitle} </p>
        </div>
        <div className={styles.videoLibrary}>
          <p className={styles.txtOtherVideo}>Découvrez également : </p>
          {videos.map((video) => (
            <div
              key={video.id}
              className={styles.videoPreview}
              onClick={() => {
                setSelectedVideo(video.src);
                setSelectedVideoTitle(video.title);
              }}
            >
              <div style={{backgroundColor: "#8cc5a2", borderRadius: 15, display: "flex", flexDirection: "column", gap: 10, height: 120, width: 180, alignItems: "center", padding: 5, overflowY: "hidden"}}>
                <span style={{textTransform: "uppercase", color: "#4a0956", flex: 1, textAlign: "center", fontWeight: "bold"}}>{video.shortTitle}</span>
                <div style={{height: 25}}>
                  <PlayCircle color="#4a0956" size={20} />
                </div>
                <span style={{color: "#4a0956", width: "100%", textAlign: "right", fontSize: 13}}>Durée: {video.duration}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Col></Row></Container>
  );
};

export default VideoLibrary;
