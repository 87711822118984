import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import useUI from "./ui.hook";
import { useHistory } from "react-router-dom";

const useGoogleAnalytics = (id) => {
  const [ui] = useUI();
  const history = useHistory();
  const [isEnabled] = useState(window.location.hostname !== "localhost");

  //set ga users properties from context
  useEffect(() => {
    if (!id || !isEnabled) return;
    const userProperties =  {
      userId: ui.user ? ui.user.uid : null,
      userRole: ui.user ? ui.user.role : null,
      establishmentId: ui.establishment ? ui.establishment.id : null,
      establishmentName: ui.establishment ? ui.establishment.name : null,
    };
    ReactGA.gtag("set", "user_properties", userProperties);
  }, [ui.user, ui.establishment]);

  //initialize GA only in localhost
  useEffect(() => {
    if (!id || !isEnabled) return;

    ReactGA.initialize(id);
  }, []);

  //onUrlChange
  useEffect(() => {
    if (!id || !isEnabled) return;

    const subscribe = history.listen(location => {
      ReactGA.send({hitType: "page_view", page: location.pathname});
    });
    return subscribe;
  }, []);

  const sendEvent = (eventName, params) => {
    if (!isEnabled) return;
    ReactGA.gtag("event", eventName, params);
  };

  return sendEvent;
};

export default useGoogleAnalytics;