import React, { useState } from "react";
import {Provider} from "./context";
import EditorInterface from "./editor";

const OveliaMenuEditor = () => {

  return (
    <Provider>
      <EditorInterface />
    </Provider>
  );
};

export default OveliaMenuEditor;
