import React, {useState} from "react";
import useRedirectMCWebWithAutologin from "helpers/redirectMCWebWithAutologin";
import {User} from "react-feather";

import {Card, Avatar} from "../../lib/components";

import useUI from "../../hooks/ui.hook";
import TodayActivities from "./HomeWidgets/TodayActivities";
import TomorrowActivities from "./HomeWidgets/TommorowActivities";
import LastActu from "./HomeWidgets/LastActu";
import TodayBirthday from "./HomeWidgets/TodayBirthday";
import MonthBirthday from "./HomeWidgets/MonthBirthday";
import useFeature from "hooks/useFeature";

const roleMap = {"owner" : "propriétaire", "employee" : "Collaborateur", "family" : "famille", "senior" : "sénior", "seniorTotem" : "sénior Totem", "superadmin" : "administrateur"};

const Index = () => {
  const [ui] = useUI();

  return (
    <>
      <div style={{display: "flex", alignItems: "stretch", justifyContent: "center", gap: 10, width: "100%", flexWrap: "wrap"}}>
        <Card color="grey" style={{flex: 1, paddingLeft: 10, paddingRight: 10, minWidth: 250}}>
          <div style={{height: "100%", gap: 10, display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center"}}>
            <Avatar img={ui.establishment.profileImage} size="xl" firstname={ui.user.surname} lastname={ui.user.name}/>
            <div>
              <p style={{fontSize: 18, color: "#2D3444"}}>Bonjour</p>
              <p style={{fontSize: 26, fontWeight: "600"}}>{ui.user.surname} {ui.user.name}</p>
              <p style={{fontStyle: "italic"}}>{roleMap[ui.user.role]}</p>
            </div>
          </div>
        </Card>

        <div style={{flex: 3, border: "1px solid #ECECEC", display: "flex", alignItems: "stretch", justifyContent: "center",
          borderRadius: 12, margin: 5, minWidth: 400, flexWrap: "wrap",backgroundColor: "#F6F7FB"}}>
          <div style={{flex: 3, padding: 5, margin: 1, minWidth: 400}}>
            <h3 style={{fontWeight: 600, marginBottom: 0}}><User style={{color: "#6FCF97"}} /> Vos informations</h3>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "calc(100% - 60px)", gap: 10, padding: "0 15px", marginTop: 10, flexWrap: "wrap"}}>
              <div style={{flex: 1, minWidth: 200}}>
                <p>Etablissement:</p>
                <p style={{fontWeight: "600"}}>{ui.establishment.name}</p>
              </div>
              <div style={{flex: 1, minWidth: 200}}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <p>Responsable de l'établissement:</p>
                <p style={{fontWeight: "600"}}>{ui.establishment?.owner?.surname} {ui.establishment?.owner?.name}</p>
              </div>
            </div>
          </div>
          <div style={{flex: 1, backgroundColor: "white", borderLeft: "1px solid #ECECEC", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", padding: 5}}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p style={{color: "#300438", textAlign: "center"}}>Votre code Établissement:</p>
            <p style={{fontWeight: "700", fontSize: 38, textAlign: "center", color: "#60c316"}}>{ui.establishment.id}</p>
            {ui.establishment.poster ? <a href={ui.establishment.poster} target="_blank" style={{textAlign: "center"}} rel="noreferrer">Affiche établissement</a> : <></>}
          </div>
        </div>
      </div>

      <div style={{display: "flex", alignItems: "stretch", justifyContent: "center", gap: 10, width: "100%", flexWrap: "wrap"}}>
        <TodayActivities />
        <TomorrowActivities />
      </div>

      
      <div style={{display: "flex", alignItems: "stretch", justifyContent: "center", gap: 10, width: "100%", flexWrap: "wrap"}}>
        <TodayBirthday />
        <MonthBirthday />
      </div>
      
      
      <div style={{display: "flex", alignItems: "stretch", justifyContent: "center", gap: 10, width: "100%", flexWrap: "wrap", marginBottom: 30}}>
        {/*<Card style={{flex: 1, paddingLeft: 18, paddingRight: 18, minWidth: 250}}>
          <span style={{fontWeight: 700, color: "#555" }}>La recomandation de Jabi</span>
          <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
            <Link to="/dashboard/ateliers/Mémoire/Mémoire%20des%20souvenirs/Atelier%201%20-%20Photo%20langage" style={{flex: 1}}>
              <Card color="border" style={{display: "flex", flexDirection: "column", padding: 20, alignItems: "center", justifyContent: "center"}}>
                <img src={WorkshopRecommanded} style={{maxWidth: 200}} />
                <p style={{textAlign: "center", fontSize: 28, fontWeight: "700", color: "#300438"}}>Photo-language</p>
              </Card>
            </Link>
          </div>
          </Card>*/}
        <LastActu />
      </div>
    </>
  );
};

export default Index;