import React, { useEffect, useState } from "react";
import {
  Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Table, Card, CardHeader, CardBody, Input, FormGroup
} from "lib/components";
import { Edit, Plus, List, BarChart, Eye } from "react-feather";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { createPatchnote, getAllPatchnotes_rt } from "services/ressources/patchnotes";
import Searching from "./searching";
import { Provider } from "./context";

import styles from "./scss/patchnotes.module.scss";

const PatchnotesPage = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [patchnotes, setPatchnotes] = useState([]);

  useEffect(() => {
    getAllPatchnotes_rt(null, setPatchnotes);
  }, []);

  const _create = () => {
    const _data = { date: moment(date).toDate() ?? moment().toDate(), content: {type: "block", title : `Patchnote du ${moment(date).format("DD MMMM YYYY")}`} };
    createPatchnote(_data);
    setIsOpen(false);
  };

  return (
    <div style={{ flex: 1 }}>
      <Container><Row><Col xs={12}>
        <Card>
          <CardHeader style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", justifyContent: "space-between", gap: 60, alignItems: "center" }}>
              <h5 style={{ marginBottom: 0, whiteSpace: "nowrap" }}>Patchnotes</h5>
            </div>
            <Button color="primary" onClick={() => setIsSearching(!isSearching)}>{isSearching ? "Retour à la liste" : "Effectuer une recherche spécifique"}</Button>
            {!isSearching ? <Button color="primary" onClick={() => setIsOpen(true)}><Plus color="white" /></Button> : <div></div>}
          </CardHeader>
          <CardBody>
            {isSearching ? <Searching patchnotes={patchnotes}/> :
              patchnotes.sort((a, b) => b.date - a.date).map((_patchnote, _indx) => (
                <div className={styles.patchnote} key={_indx} onClick={() => history.push(`/dashboard/patchnotes/${_patchnote.uid}`)}>
                  <h2>Patchnote du {moment(_patchnote.date).format("DD MMMM YYYY")}</h2>
                  {/* <Eye color="#4a0956" style={{cursor: "pointer"}} onClick={() => history.push(`/dashboard/patchnotes/${_patchnote.uid}`)} /> */}
                  <Edit color="#4a0956" style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); history.push(`/dashboard/patchnotes/${_patchnote.uid}/edit`); }} />
                </div>
              ))
            }
          </CardBody>
        </Card>
      </Col></Row></Container>
      <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <ModalHeader>Créer un patchnote</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input type="date" label="Date du déploiement" onChange={e => setDate(e.target.value)} value={date} />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsOpen(false)}>Annuler</Button>
          <Button color="primary" onClick={_create}>Créer</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const Patchnotes = () => {
  return(
    <Provider>
      <PatchnotesPage/>
    </Provider>
  );
};

export default Patchnotes;