import React,{useEffect} from "react";
import PropTypes from "prop-types";
import {createContext, useReducer, useContext} from "react";
import firebase from "firebase";
import moment from "moment";
import useUI from "hooks/ui.hook";
import deepClone from "helpers/deepClone";
import {toast} from "react-toastify"; 
import {isDayEmpty, getDefaultRepas, getDefaultDay, isRepasEmpty, areReservationsEqual, deepMenuEqual} from "./helpers/operations";
import {getMenusByPeriodGroupement_rt, setMenuBatchGroupement} from "services/ressources/groupementMenu";
import useMicroService from "helpers/microService";

const firestore = firebase.firestore;

const Context = createContext();

const Default = {
  page: 0,
  meal: null,
  menu: null,
  menuType: null,
  week: null,
  data: null,
  state: null,
  isSaved: null,
  save: null,
  loading: true,
  modalBrokenOpen: false,
  brokenList: [],
  users: null,
  copy: null,
};

function Reducer(state, action) {
  switch(action.type) {
  case "setMeal": return ({...state, meal: action.meal}); 
  case "setMenu": return ({...state, menu: action.menu});
  case "changePage": return ({...state, page: action.page});
  case "clear": return ({...Default});
  case "save": return ({...Default});
  case "nextWeek": return ({...state, week: state.week.map(day => moment(day).add(7, "day").toDate())});
  case "previousWeek": return ({...state, week: state.week.map(day => moment(day).subtract(7, "day").toDate())});
  case "setProperty": return ({...state, [action.property]: action.value});
  case "setState": return {...action.state};
  default: return {...state};
  }
}

const Provider = ({presta ,children}) => {
  const [ui] = useUI();
  const [ctx, dispatch] = useReducer(Reducer, Default);
  const execMicroService = useMicroService();

  const template = ui?.groupement?.template;

  useEffect(() => {
    let _monday = moment().startOf("week");
    let _week = [];
    _week.push(_monday.toDate());
    for (let i = 0; i < 6; i++) {
      _monday.add(1, "day");
      _week.push(_monday.toDate());
    }
    dispatch({type: "setProperty", property: "week", value: _week});

    if(template && Object.keys(template).length) {
      let meal = Object.keys(template).sort((a,b) => ui.groupement.template[a].heure - ui.groupement.template[b].heure)[0];
      dispatch({type: "setProperty", property: "meal", value: meal});
      let menu = Object.keys(template[meal].menus).sort((a,b) => template[meal].menus[a].weight - template[meal].menus[b].weight)[0];
      dispatch({ type: "setMenu", menu: menu });
    }

    try {
      firestore()
      .collection("users")
      .where("groupement", "==", ui.user.groupement)
      .where("role", "in", ["senior","seniorTotem"])
      .onSnapshot(res => {
        let _dataUsers = {};
        res.forEach((doc) => {
          _dataUsers[doc.id] = {...doc.data(), uid: doc.id};
        });
        dispatch({ type: "setProperty", property : "users", value: _dataUsers });
      });
    } catch (e) {
      console.error(e);
    }


  }, []);

  // auto change the menu when we change the meal.
  useEffect(()=> {
    if(template && ctx.meal) {
      let menu = Object.keys(template[ctx.meal].menus).sort((a,b) => template[ctx.meal].menus[a].weight - template[ctx.meal].menus[b].weight)[0];
      if(menu !== ctx.menu)
        dispatch({ type: "setMenu", menu: menu });
    }
  }, [ctx.meal]);

  // auto change the menu type 
  useEffect(()=> {
    if(template && ctx.menu) {
      let menuType = template[ctx.meal].menus[ctx.menu].type;
      if(menuType !== ctx.menuType)
        dispatch({type: "setProperty", property: "menuType", value: menuType});
    }
  }, [ctx.menu]);



  //auto load menu when week change
  useEffect(() => {
    if (ctx.week === null) return;
    (async () => {
      dispatch({type: "setProperty", property: "loading", value: false});


      try {
        let monday = ctx.week[0];
        let sunday = ctx.week[6];
        let events = {};

        getMenusByPeriodGroupement_rt({idGroupement: ui.groupement.uid, presta, start: monday, end: sunday}, (data) => {
            data.forEach(doc => events[doc.uid] = {...doc, day: doc.day.toDate()});
            // dispatch({type: "setProperty", property: "loading", value: false});
            dispatch({type: "setProperty", property: "data", value: events});
          }
        );
          
      } catch (e) {
        console.error(e);
        dispatch({type: "setProperty", property: "data", value: {}});
        dispatch({type: "setProperty", property: "loading", value: false});
      }
    })();
  }, [ctx.week]);

  //fill state with data
  useEffect(() => {
    if (!ctx.week) return;
    if (!template) return;

    const _defaultDay = {};

    Object.keys(template).forEach(element => {
      const _content = {};
      template[element].categories.forEach(category => {
        _content[category] = {};
      });
      _defaultDay[element] = _content;
    });
  
    if (ctx.data === null || Object.keys(ctx.data).length === 0) {
      const newData = {};
      ctx.week.forEach(day => {
        const docId = moment(day).format("YYYY-MM-DD");  
        newData[docId] = {
          day,
          ...deepClone(_defaultDay)
        };
      });
     
      dispatch({type: "setProperty", property: "data", value: newData});
      dispatch({type: "setProperty", property: "state", value: newData});

    } else {
      
      dispatch({type: "setProperty", property: "state", value: deepClone(ctx.data)});
    }

  }, [ctx.data, ctx.week]);

  const isSaved = () => {
    return deepMenuEqual(ctx.data, ctx.state);
  };


  const save = async (published) => {
      if (ctx.state === null) return;

      if(published){
        const _etabs = ui.groupement.presta[presta] ?? ui.groupement.establishments;
        execMicroService("publishMenuEtabs", {
          establishments: _etabs,
          menus: ctx.state});
      }

      console.log(ctx.state);
      try {          
          let batch = {};
        
          Object.entries(ctx.state).forEach(([docId,document]) => {
            //let docId = moment(document.day).format("YYYY-MM-DD");

            
            const _data = {...document, published : published};
            
            batch[docId] = _data;
          });
          setMenuBatchGroupement({idGroupement: ui.groupement.uid, presta, menus: batch});

          dispatch({type: "setProperty", property: "data", value: deepClone(ctx.state)});
          toast.success(`Votre menu a bien été ${published ? "publié" : "sauvegardé"}`);
          if (!published) toast.warn("Attention: votre menu n'a pas été publié");
      } catch (e) {
        console.error(e);
        toast.error(`Une erreur est survenue lors de la ${published ? "publication" : "sauvegarde"} de votre menu`);
      }
  };

  ctx.isSaved = isSaved;
  ctx.save = save;

  return (
    <Context.Provider value={[ctx, dispatch]}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  presta: PropTypes.string.isRequired,
  children: PropTypes.element,
};
const useMenu = () => useContext(Context);
export default useMenu;
export {Provider, Context};