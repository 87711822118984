import React from "react";
import Youtube from "react-youtube";
import PropTypes from "prop-types";

const YoutubePlayer = ({src}) => {
  return (
    <Youtube 
      videoId={src}
      style={{width: "98%", height: "80%"}}
      opts={{
        width: "100%",
        height: "100%",
        playerVars: {
          autoplay: 1
        }
      }}
    /> 
  );
};

YoutubePlayer.propTypes = {
  src: PropTypes.string.isRequired
};

export default YoutubePlayer;

