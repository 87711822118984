import Answering from "./Answering";
import DisplayAnswer from "./DisplayAnswer";
import EditorDisplay from "./EditorDisplay";
import EditorIcon from "./EditorIcon";
import EditorSidebar from "./EditorSidebar";

const TrueFalse = {
  name: "Vrai ou Faux",
  slug: "true_false",
  Answering,
  DisplayAnswer,
  EditorDisplay,
  EditorIcon,
  EditorSidebar,
  sidebarEnabled: false,
};

export default TrueFalse;