import React, {useMemo, useCallback, useState} from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Spinner, Input, Select, FormGroup, Button, DatePicker, Checkbox } from "../../../../../../lib/components";
import useCtx from "../../Context/ctx";
import moment from "moment";
import useMicroService from "helpers/microService";
import Tags from "./Subscription/_Tags";

import roles from "enums/roles";
import sexeOptions from "enums/sexes";
import { useHistory } from "react-router-dom";
import {toast} from "react-toastify";
import useFeature from "hooks/useFeature";

const UserInfos = () => {
  const execMicroService = useMicroService();
  const {state, dispatch, save, deletUser, softDeleteUser} = useCtx();
  const {user, isLoading, groupement} = (state ?? {});
  const history = useHistory();
  const [deleteAccountLoader, setDeleteAccountLoader] = useState(false);

  const handleChange = useCallback((key, value) => dispatch({type: "setUser", user: {...user, [key]: value}}), [user]);

  const handleChangePassword = async () => {
    try {
      await execMicroService("superadminForceResetPassword", {uid: user.uid});
      toast.success("Email de réinitialisation envoyé");
    } catch (e) {
      toast.error("Une erreur est survenue");
      console.error(e);
    }
  };

  const handleSuppression = async () => {
    if(confirm("Voulez vous vraiment supprimer cet utilisateur ?")){
      setDeleteAccountLoader(true);
      // await deletUser(user.uid);
      await softDeleteUser(user.uid);
      setDeleteAccountLoader(false);
      history.push("/dashboard/users");
    }
  };

  if (isLoading) return (<Spinner />);
  return (
    <div>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: 10}}>
        <Button color="primary" onClick={save}>Enregistrer</Button>
      </div>
      <Container>
        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader><h5>Utilisateur</h5></CardHeader>
              <CardBody style={{display: "flex", flexDirection: "column"}}>
                <FormGroup>
                  <Input label="Nom" value={user?.name} onChange={e => handleChange("name", e.target.value)} placeholder="Name..." />
                </FormGroup>
                <FormGroup>
                  <Input label="Prénom" value={user?.surname} onChange={e => handleChange("surname", e.target.value)} placeholder="Surname..." />
                </FormGroup>
                {/*
                <FormGroup>
                  <Input label="Email" value={user?.email} onChange={e => handleChange("email", e.target.value)} placeholder="Email..." disabled />
                </FormGroup>
                */}
                <FormGroup>
                  <Input label="Téléphone" value={user?.phone} onChange={e => handleChange("phone", e.target.value)} placeholder="Phone..." />
                </FormGroup>
                <FormGroup>
                  <Input label="n° d'appartement" value={user?.room} onChange={e => handleChange("room", e.target.value)} placeholder="Appartement..." />
                </FormGroup>
                <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-start", gap: 20}}>
                  <FormGroup>
                    <DatePicker label="Date de naissance" value={user?.birthdate} onChange={e => handleChange("birthdate", e)} placeholder="Date de naissance..." />
                  </FormGroup>
                  <FormGroup>
                    <Checkbox label="Notifier dans les applications" checked={user?.showBirthday} onChange={() => handleChange("showBirthday", !user?.showBirthday)}/>  
                  </FormGroup>
                </div>
                <FormGroup>
                  <Select 
                    label="Sexe"
                    value={sexeOptions.find(i => i.value === user?.gender) ?? null}
                    onChange={e => handleChange("gender", e?.value ?? null)}
                    placeholder="Sexe..."
                    options={sexeOptions}
                  />
                </FormGroup>
                {/*<FormGroup>
                  <Select
                    disabled
                    label="Role"
                    value={roles.find(i => i.value === user?.role) ?? null}
                    onChange={e => handleChange("role", e?.value ?? null)}
                    options={roles}
                    placeholder="Role..."
                  />
                </FormGroup>*/}
              </CardBody>
            </Card>
            
          </Col>
          <Col lg={6}>
            <Tags></Tags>
            <Card>
              <CardHeader><h5>Statistiques</h5></CardHeader>
              <CardBody>
                <p>Date de création: {user.createdAt ? moment(user.createdAt).format("LLL") : "N/A"}</p>
                {/*<p>Dernière connection: {user.lastLogin ? moment(user.lastLogin.toDate()).format("LLL") : "N/A"}</p>*/}
              </CardBody>
            </Card>
            <Card>
              <CardHeader><h5>Gestion</h5></CardHeader>
              <CardBody style={{display: "flex", flexDirection: "column", alignItems: "center", gap: 20}}>
                <Button color="warning" onClick={handleChangePassword}>Envoyer un mail de rénitialisation de mot de passe</Button>
                <Button color="danger" onClick={handleSuppression} loading={deleteAccountLoader}>Supprimer le compte</Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserInfos;

//archive 
//const abonnementOptions = useMemo(() => groupement?.abonnements ? Object.entries(groupement.abonnements).map(([k, v]) => ({label: v.name, value: k})) : [], [groupement]);
/*
<Col lg={5}>
  <Card>
    <CardHeader><h5>Menu</h5></CardHeader>
    <CardBody>
      <Select 
        placeholder="Abonnement..."
        options={abonnementOptions}
        value={abonnementOptions?.find(i => i.value === user?.subscription) ?? null}
        onChange={e => handleChange("subscription", e?.value ?? null)}
        clearable
      />
    </CardBody>
  </Card>
</Col>
*/