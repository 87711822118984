import { useEffect, useState } from "react";
import {hotjar} from "react-hotjar";
import useUI from "./ui.hook";

const useHotjar = () => {
  const [ui] = useUI();
  const [isEnabled] = useState(window.location.hostname !== "localhost");

  useEffect(() => {
    if (isEnabled)
      hotjar.initialize(2845376, 6);
  }, []);

  useEffect(() => {
    if (isEnabled) {
      hotjar.identify(ui.user ? ui.user.uid : null, {
        userRole: ui.user ? ui.user.role : null,
        establishmentId: ui.establishment ? ui.establishment.id : null,
        establishmentName: ui.establishment ? ui.establishment.name : null,
      });
    }
  }, [ui.establishment, ui.user]);
};

export default useHotjar;