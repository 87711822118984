import React, { useEffect, useState } from "react";
import {
    Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Table, Card, CardHeader, CardBody, Input, FormGroup
} from "lib/components";
import { Edit, Plus, List, BarChart, PlusCircle, Trash2 } from "react-feather";
import PropTypes from "prop-types";
import Restrictions, { RestrictionDisplay } from "./restrictions";

import styles from "../scss/bloc.module.scss";


export const ListEdit = ({ blockData, parentRestrictions, remove }) => {
    const [isOpenSetup, setIsOpenSetup] = useState(false);
    const [restrictions, setRestrictions] = useState([]);
    const [refresh, setRefresh] = useState(true);

    useEffect(() => {
        if (blockData && !blockData.value) {
            blockData.value = [{
                value: "",
                restriction: null,
            }];
            setRefresh(!refresh);
        } else if (blockData && blockData.value) {
            setRestrictions(blockData.restrictions ?? []);
        }
    }, [blockData]);

    const _setup = () => {
        blockData.restrictions = restrictions;
        setIsOpenSetup(false);
    };

    const _addListElement = () => {
        if (!blockData.value) blockData.value = [];

        blockData.value.push({
            value: "",
            restriction: null,
        });

        setRefresh(!refresh);
    };

    const _removeItem = (_indx) => {
        console.log(blockData?.value, _indx);
        blockData?.value.splice(_indx, 1);
        setRefresh(!refresh);
    };


    return (
        <div style={{ width: "100%", padding: 20 }}>

            <div className={styles.titleComponent} >
                <div style={{ width: "90%" }}>{blockData?.value ?
                    blockData?.value.map((_listComponent, _indx) => (
                        <div key={_indx}>
                            <ListItemEdit data={_listComponent} parentRestrictions={[...parentRestrictions, ...restrictions]} remove={() => _removeItem(_indx)} />
                        </div>
                    ))
                    : null}
                </div>
                {restrictions.length > 0 ?
                    <div className={styles.restrictionContainer}>
                        {restrictions.map((_r) => (
                            _r.value && _r.value.length > 0 ?
                                _r.value.map((_r2, _indx) => (
                                    <div key={_r.type + _indx}>
                                        <RestrictionDisplay restriction={{ type: _r.type, value: _r2 }} />
                                    </div>
                                ))
                                : null

                        ))}
                    </div>
                    : null
                }
                <Edit className={styles.icon} color={"blue"} size={30} onClick={() => setIsOpenSetup(true)} />
                {remove ? <Trash2 className={styles.icon} color={"red"} size={30} onClick={remove} /> : null}
            </div>


            <PlusCircle className={styles.icon} size={30} color={"purple"} onClick={_addListElement} />


            <Modal isOpen={isOpenSetup} toggle={() => setIsOpenSetup(false)} size="lg">
                <ModalHeader>{"Setup du composant"}</ModalHeader>
                <ModalBody>
                    <h3 className={styles.restrictions}>Restrictions : </h3>
                    <Restrictions parentRestrictions={parentRestrictions} currentRestrictions={restrictions} setRestrictions={setRestrictions} />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={_setup}>Valider</Button>
                    <Button color="secondary" onClick={() => setIsOpenSetup(false)}>Annuler</Button>
                </ModalFooter>
            </Modal>

        </div>);
};



export const ListView = ({ blockData, parentRestrictions }) => {
    return (
        <div style={{ width: "100%", padding: 20 }}>

            <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                <ul>
                    {blockData?.value.map((_item, _indx) => (
                        <li key={_indx}>
                            <div className={styles.list}>
                                <div>{_item.value}</div>

                                {_item?.restrictions?.length > 0 ?
                                    <div className={styles.restrictionContainer}>
                                        {_item?.restrictions.map((_r) => (
                                            _r.value && _r.value.length > 0 ?
                                                _r.value.map((_r2, _indx) => (
                                                    <div key={_r.type + _indx}>
                                                        <RestrictionDisplay restriction={{ type: _r.type, value: _r2 }} />
                                                    </div>
                                                ))
                                                : null

                                        ))}
                                    </div>
                                    : null
                                }

                            </div>

                        </li>
                    ))}
                </ul>
                {blockData?.restrictions?.length > 0 ?
                    <div className={styles.restrictionContainer}>
                        {blockData?.restrictions.map((_r) => (
                            _r.value && _r.value.length > 0 ?
                                _r.value.map((_r2, _indx) => (
                                    <div key={_r.type + _indx}>
                                        <RestrictionDisplay restriction={{ type: _r.type, value: _r2 }} />
                                    </div>
                                ))
                                : null

                        ))}
                    </div>
                    : null
                }
            </div>
        </div>
    );
};

const ListItemEdit = ({ data, parentRestrictions, remove }) => {
    const [isOpenSetup, setIsOpenSetup] = useState(false);
    const [text, setText] = useState("");
    const [restrictions, setRestrictions] = useState([]);

    useEffect(() => {
        if (data && data.value) {
            setRestrictions(data.restrictions ?? []);
            setText(data.value ?? "");
        }
    }, [data]);

    useEffect(() => {
        if (text && data.value !== text) {
            data.value = text;
        }
    }, [text]);

    const _setup = () => {
        data.restrictions = restrictions;
        setIsOpenSetup(false);
    };


    return (
        <div style={{ width: "100%", padding: 20 }}>

            <div className={styles.titleComponent}>
                <Input style={{ width: "90%" }} value={text} onChange={e => setText(e.target.value)} />
                {restrictions.length > 0 ?
                    <div className={styles.restrictionContainer}>
                        {restrictions.map((_r) => (
                            _r.value && _r.value.length > 0 ?
                                _r.value.map((_r2, _indx) => (
                                    <div key={_r.type + _indx}>
                                        <RestrictionDisplay restriction={{ type: _r.type, value: _r2 }} />
                                    </div>
                                ))
                                : null

                        ))}
                    </div>
                    : null
                }
                <Edit className={styles.icon} color={"blue"} size={30} onClick={() => setIsOpenSetup(true)} />
                {remove ? <Trash2 className={styles.icon} color={"red"} size={30} onClick={remove} /> : null}

            </div>

            <Modal isOpen={isOpenSetup} toggle={() => setIsOpenSetup(false)} size="lg">
                <ModalHeader>{"Setup du composant"}</ModalHeader>
                <ModalBody>
                    <h3 className={styles.restrictions}>Restrictions : </h3>
                    <Restrictions parentRestrictions={parentRestrictions} currentRestrictions={restrictions} setRestrictions={setRestrictions} />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={_setup}>Valider</Button>
                    <Button color="secondary" onClick={() => setIsOpenSetup(false)}>Annuler</Button>
                </ModalFooter>
            </Modal>

        </div>);
};



ListEdit.propTypes = {
    blockData: PropTypes.object,
    parentRestrictions: PropTypes.object,
    remove: PropTypes.func
};

ListItemEdit.propTypes = {
    data: PropTypes.object,
    parentRestrictions: PropTypes.object,
    remove: PropTypes.func
};

ListView.propTypes = {
    blockData: PropTypes.object,
    parentRestrictions: PropTypes.object,
    content: PropTypes.arrayOf(PropTypes.object)
};