import EditorDisplay from "./EditorDisplay";
import EditorIcon from "./EditorIcon";
import EditorSidebar from "./EditorSidebar";

const TrueFalse = {
  slug: "true_false",
  name: "Vrai ou faux",
  EditorDisplay,
  EditorIcon,
  EditorSidebar,
};

export default TrueFalse;