import React, {useState, useEffect} from "react";
import moment from "moment";
import { VideoTuto } from "lib/components/YoutubeModal";
import { Tab, Spinner } from "lib/components";

import DateSelector from "lib/Stats/components/dateSelector";

import { Global, Users, Animations, Restauration, Actualites, Gazette } from "lib/Stats/pages";
import useStats, {Provider } from "lib/Stats/statsContext";

const HomeStatComponent = () => {
  const [ctx, dispatch, hasFeature] = useStats();
  const [page, setPage] = useState(0);

  if(!ctx.dates)return <></>;

  let tab = [
    {title: "Vue Globale",         content: <Global/>},
    {title: "Utilisateurs",         content: <Users/>}];

  if(hasFeature("animationPlanning"))tab.push({title: "Animations",         content: <Animations/>});
  if(hasFeature("menuPlanning"))tab.push({title: "Restauration",         content: <Restauration/>});
  if(hasFeature("news"))tab.push({title: "Actualités",         content: <Actualites/>});
  if(hasFeature("gazette"))tab.push({title: "Gazettes",         content: <Gazette/>});

  return (
    <div style={{ width: "calc(100% + 30px)", height: "calc(100vh - 80px)", margin: -15, padding: 15, display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto", position: "relative"}}>
      <DateSelector/>

      {ctx.loading &&
        <div style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0, backgroundColor: "rgba(0,0,0,0.4)", display: "flex", alignItems: "center", justifyContent: "center", zIndex: 2500 }}>
          <Spinner style={{borderColor: "#300438", width: 100, height: 100, borderWidth: 7}}/>
        </div>
      }

      {ctx.data1 && ctx.data2 ? 
         hasFeature("statistics") ? 
         <Tab activeTab={page}
           onClick={(_page) => setPage(_page)}
           render={tab} 
         />    
         : 
         <Global/>    
      : null}
     
    </div>
  );
};

const HomeStat = () => {
  return(
    <Provider targetType="establishment">
      <HomeStatComponent/>
    </Provider>
  );
};


export default HomeStat;
