import React from "react";
import PropTypes from "prop-types";

const RessourceBundler = (props) => {
  const {resources, children} = props;

  const Provider = resources?.length ? resources[0] : null;

  return (
    <>
      {Provider ? 
        <Provider>
          <RessourceBundler resources={resources.slice(1)}>{children}</RessourceBundler>
        </Provider>
      : children}
    </>
  );
};

RessourceBundler.propTypes = {
  resources: PropTypes.array,
  children: PropTypes.node
};

export default RessourceBundler;