import { Checkbox, FormGroup, Input } from "lib/components";
import PropTypes from "prop-types";
import ImagePickerCrop from "lib/components/Form/ImagePickerCrop";

import {
  Image,
  StyleSheet,
  View,
  Text
} from "@react-pdf/renderer";

import React from "react";
import styles from "assets/scss/pages/Gazette/contentTypes/image.module.scss";

import "../fonts";

const ImageComponent = (props) => {
  const { image, format } = props;
  return (
    <div className={`${styles.editorView} ${styles[format]}`}>
      <img src={image} />
    </div>
  );
};

const ImageEditor = (props) => {
  const { onChange, onSubmit, onCancel, field } = props;

  return (
    <>
      <h5>Ajouter une image</h5>
      <FormGroup style={{flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <ImagePickerCrop
          ratio={372/524}
          onChange={(image) => onChange("image", image)}
          value={field.image}
        />
        <Checkbox 
          checked={field.fullSize}
          onChange={(e) => onChange("fullSize", e.target.checked)}
          label="Afficher en pleine page"
        />
        <Input
          value={field.legend}
          onChange={(e) => onChange("legend", e.target.value)}
          label="Légende"
        />

      </FormGroup>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 10,
        }}
      >
        {/*<Button color="secondary" onClick={onCancel}>
          Annuler
        </Button>
        <Button color="primary" onClick={onSubmit}>
          Valider
      </Button>*/}
      </div>
    </>
  );
};

const ImagePdf = (props) => {
  const { settings, image, legend} = props;

  
const pdfStyle = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  image: {
    objectFit: "cover",
  },
  imageLegend: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: settings?.fontSize4 ?? 12,
    fontFamily: settings?.fontFamily ?? "Roboto",
    textAlign: "center",
    textDecoration: "underline",
  },
});


  return (
    <View style={pdfStyle.container}>
      {image && <Image src={image} style={pdfStyle.image} />}
      {legend && legend.length > 0 ? <Text style={pdfStyle.imageLegend}>{legend}</Text> : null}
    </View>
  );
};

//TODO Definir les proptypes 
ImageComponent.propTypes = {
  image : PropTypes.any,
  fullSize: PropTypes.any,
  format : PropTypes.any,
};

ImageEditor.propTypes = {
  onChange : PropTypes.any,
  onSubmit : PropTypes.any,
  onCancel : PropTypes.any,
  field : PropTypes.any,
};

ImagePdf.propTypes = {
  image : PropTypes.any,
  fullSize: PropTypes.any,
  settings : PropTypes.any,
  legend: PropTypes.any,
};

export { ImageComponent, ImageEditor, ImagePdf };
