import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "../../../assets/scss/pages/CutlureQuizz/sidebar.module.scss";
import QuestionTypes from "./QuestionTypes";
import { toast } from "react-toastify";
import { Button } from "../../../lib/components";

const Sidebar = (props) => {
  const { isOpen, onClose, question, onChange, onDelete } = props;

  const [tempQuestion, setTempQuestion] = useState(null);
  const [imageLoaderForm, setImageLoaderForm] = useState(false);

  useEffect(() => {setTempQuestion(question);}, [question]);

  const _onSubmit = () => {
    //onClose();
    onChange(tempQuestion);
    toast.success("Question modifiée avec succès");
  };

  const _onDelete = () => onDelete();
  const _onChange = (e) => setTempQuestion(e);

  const questionType = question ? QuestionTypes.filter((i) => i.slug === question.type)[0] : null;
  
  if (questionType && questionType.sidebarEnabled === false) return null;
  return (
    <div className={`${styles.container} ${isOpen ? styles.isOpen : ""}`}>
      <div className={styles.header}>
        {/*<X size={32} style={{cursor: "pointer"}} onClick={onClose} />*/}
      </div>
      <div className={styles.body}>
        {question
          ? QuestionTypes.filter((i) => i.slug === question.type).map((questionType, index) => (
            <questionType.EditorSidebar
              key={index}
              question={question}
              onChange={_onChange}
              setImageLoaderForm={setImageLoaderForm}
            />
          ))
          : null}
      </div>
      <div className={styles.footer}>
        <Button onClick={_onSubmit} disabled={imageLoaderForm}>
          Enregistrer
        </Button>
        <Button color="danger" onClick={_onDelete}>
          Supprimer
        </Button>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  isOpen : PropTypes.bool.required,
  onClose : PropTypes.func,
  question : PropTypes.object.required,
  onChange : PropTypes.func.required,
  onDelete : PropTypes.func.required,
};


export default Sidebar;
