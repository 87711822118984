import React, { useState, useEffect, useMemo } from "react";
import { Edit, Clipboard, Plus, Square, CheckSquare } from "react-feather";
import {
  Container, Row, Col,
  Table, Icon, Tag, Button,
  Card, CardHeader, CardBody, Input
} from "lib/components";
import useUI from "hooks/ui.hook";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import useSearch from "hooks/useSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortNumericDown, faSortNumericUpAlt } from "@fortawesome/free-solid-svg-icons";
import styles from "./tableau.module.scss";
import { getAllInterventionsByEtab_rt, updateInterventionByEtab } from "services/ressources/interventions";
import { getUsersByEtab } from "services/ressources/user";
import AddInterventionModal from "./AddInterventionModal";
import moment from "moment";

const parameters = ["intervention", "name", "room"];
const state = ["todo", "done"];

const Intervention = () => {
  const [ui] = useUI();
  const [value, setValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [interventions, setInterventions] = useState([]);
  const [currentIntervention, setCurrentIntervention] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const colors = {"Électricité":"#e8b809", "Ménage":"#83bc53","Plomberie": "#518cc4", "Autre":"#5c5c5c"};
  const { filtered } = useSearch(interventions, value, parameters);

  const getUsers = async () => {
    const _users = await getUsersByEtab({ etabId: ui.establishment.uid });
    setUsers(_users.filter(_u => ["senior", "seniorTotem"].includes(_u.role)));
  };

  useEffect(() => {
    getUsers();

    getAllInterventionsByEtab_rt({ id: ui.establishment.uid }, (data) => {
      setInterventions(data);
    });
  }, []);

  useEffect(() => {
    if (isOpen === false) {
      setCurrentIntervention(null);
    }
  }, [isOpen]);

  const handleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const _onClick = (e, intervention) => {
    e.stopPropagation();

    const _data = {
      state: intervention.state === "todo" ? "done" : "todo"
    };
    updateInterventionByEtab({ etabId: ui.establishment.uid, data: _data, interventionId: intervention.uid });

  };

  const sorted = useMemo(() => {
    if (!filtered) return [];

    return filtered.sort((a, b) => {
      if (sortOrder === "asc") {
        return a.date - b.date;
      } else {
        return b.date - a.date;
      }
    }).sort((a, b) => state.indexOf(a.state) - state.indexOf(b.state));
  }, [filtered, sortOrder]);
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <Card style={{ backgroundColor: "white" }}>
            <CardHeader style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", justifyContent: "space-between", gap: 60, alignItems: "center" }}>
                <h5 style={{ marginBottom: 0, whiteSpace: "nowrap" }}>{"Demandes d'interventions"}</h5>
                <div style={{ width: "60%", display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}>
                  <h6 style={{ marginBottom: 0, whiteSpace: "nowrap" }}>Recherche :</h6>
                  <Input value={value} onChange={(e) => setValue(e.target.value)} />
                </div>
              </div>
              <Button color="primary" onClick={() => setIsOpen(true)}><Plus /></Button>
            </CardHeader>
            <CardBody>
              <Row className={styles.titreColonne} >
                <div onClick={handleSort} style={{fontWeight: "bold", fontSize: "18px", flex: 1, marginRight: "15px", display: "flex",cursor: "pointer" }}>
                  <div>Date</div>
                  <FontAwesomeIcon icon={sortOrder === "asc" ? faSortNumericDown : faSortNumericUpAlt} style={{ marginLeft: "6px", fontSize: "20px", width: "20px" }} />
                </div>
                <div style={{ fontWeight: "bold", display: "flex", justifyContent: "center", fontSize: "18px", flex: 1 }}>Type</div>
                <div style={{ flex: 2, display: "flex", fontWeight: "bold", justifyContent: "center", fontSize: "18px", width: "fit-content" }}>Demande</div>
                <div style={{display: "flex", justifyContent: "center", fontWeight: "bold", fontSize: "18px", flex: 2, marginLeft: "15px" }}>Nom</div>
                <div style={{ fontWeight: "bold", display: "flex", justifyContent: "center", fontSize: "18px", flex: 0.5 }}>Appartement</div>
                <div style={{ display: "flex", justifyContent: "flex-end", fontWeight: "bold", fontSize: "18px", flex: 0.5}}>Etat</div>
              </Row>
              <div style={{ borderBottom: "1px solid lightgray", marginBlock: "10px", width: "100%" }}></div>
              {sorted.map((_inte, idx) =>  {
                console.log(_inte.category);
                        return (
                            <Row key={idx} className={styles.rowContainer}>
                            <div className={styles.column}>
                            <Container  className={styles.rowContainer}>
                                <div className={styles.column}>
                                    <Container  className={`${styles.dayContainer} ${styles.select}`} onClick={() => { setCurrentIntervention(_inte); setIsOpen(true); }} style={{paddingInline:"30px", cursor: "pointer", backgroundColor: _inte.state === "done" ? "#bae1ba" : null }} >
                                        
                                            <div className={styles.date}>
                                            {moment(_inte?.date).format("DD/MM/YYYY HH:mm")}
                                            </div>
                                            <div className={styles.type}>
                                              {_inte.category !== undefined && _inte.category !== " " ?<div className={styles.category} style={{backgroundColor:colors[_inte.category]}}>
                                                {_inte.category}
                                              </div>:null}
                                            </div>
                                            <div className={styles.demande}>
                                            {_inte.intervention}
                                            </div>
                                            <div className={styles.nom}>
                                            {_inte.name}
                                            </div>
                                      <div  className={styles.appartement}>
                                        {_inte.room}
                                      </div>
                                      <div className={styles.etat} onClick={(e) => _onClick(e, _inte)}>
                                        {_inte.state === "todo" ?
                                          <Square size={40} />
                                          :
                                          <CheckSquare size={40} color="green" />}
                                      </div> 
                                    </Container>
                                </div>
                            </Container>
                            </div></Row>);
                    })}
              {/* <Table >
                <thead>
                  <tr>
                    <th onClick={handleSort} style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <div>Date</div>
                      <FontAwesomeIcon icon={sortOrder === "asc" ? faSortNumericDown : faSortNumericUpAlt} style={{ marginLeft: "6px", fontSize: "20px", width: "20px" }} />
                    </th>
                    <th>Demande</th>
                    <th>Nom</th>
                    <th>Appartement</th>
                    <th>Etat</th>
                  </tr>
                </thead>
                <tbody>
                  {sorted.map((_inte, _ind) => (
                    <tr key={_ind} onClick={() => { setCurrentIntervention(_inte); setIsOpen(true); }} style={{ cursor: "pointer", backgroundColor: _inte.state === "done" ? "#bae1ba" : null }}>
                      <td >{moment(_inte?.date).format("DD/MM/YYYY HH:mm")}</td>
                      <td>{_inte.intervention}</td>
                      <td>{_inte.name}</td>
                      <td>{_inte.room}</td>
                      <td onClick={(e) => _onClick(e, _inte)}>{_inte.state === "todo" ?
                        <Square size={40} />
                        :
                        <CheckSquare size={40} color="green"/>}</td>
                    </tr>
                  ))}
                </tbody>
              </Table> */}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <AddInterventionModal isOpen={isOpen} users={users} toggle={() => setIsOpen(false)} currentIntervention={currentIntervention} />

    </Container>
  );
};


export default Intervention;