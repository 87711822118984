import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody,ModalFooter, Spinner } from "../../../../../lib/components";

import { useMenu } from "../../router_context";

import useUi from "hooks/ui.hook";
import deepClone from "helpers/deepClone";
import PropTypes from "prop-types";
import moment from "moment";

const InfoComponent = ({uid}) => {
    const [ui] = useUi();
    const [ctx, dispatch] = useMenu();

    return (
        <div style={{flex: 3, display: "flex", flexDirection: "row", gap: 40, overflowX: "auto", paddingTop: 40, paddingBottom: 40}}>

        {ctx?.brokenList?.length > 0 ? Object.values(ctx.brokenList.reduce((acc, obj) => {
            if(obj.uid === uid){
                const key = obj.date + "." + obj.repas;
                if(!acc[key])acc[key] = [];
                acc[key].push(obj);
            }
            return acc;
        }, {})).map((_modif, indx) => (

        <div key={indx} style={{display: "flex", flexDirection: "column", backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 8,  padding: 15, paddingBottom: 40}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <span style={{fontSize: 18, fontWeight: "bold"}}>
                {moment(_modif[0].date).format("dddd DD MMMM")}
            </span>
            <span style={{fontSize: 16, fontWeight: "bold"}}>
                {_modif[0].repas}
            </span>
            </div>
            <div style={{flex: 1, display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-around", gap: 30}}>
            {Object.values(_modif.reduce((acc, obj) => {
                const key = obj.index + "";
                if(!acc[key])acc[key] = [];
                acc[key].push(obj);
                return acc;
                }, {})).map((_modif2, indx2) => (
                <div key={indx2} style={{flex: 1, minWidth: 250, display: "flex", flexDirection: "column", justifyContent: "space-around", marginTop: 20}}>
                    {Object.entries(ctx.data[_modif2[0].date].reservation[_modif2[0].uid][_modif2[0].repas][_modif2[0].index]).filter(([_categ]) => ui.establishment.template[_modif2[0].repas].categories.includes(_categ)).sort((a,b)=> ui.establishment.template[_modif2[0].repas].categories.indexOf(a[0]) - ui.establishment.template[_modif2[0].repas].categories.indexOf(b[0])).map(([_categ, _dishId], indx3) => (
                        <div key={indx3} style={{display: "flex", flexDirection: "row", flexWrap: "nowrap", borderBottomWidth: 1, borderBottomStyle: "dotted"}}>
                        <span style={{marginRight : 5, whiteSpace: "nowrap", fontWeight: "bold"}}>
                            {_categ} : 
                        </span>
                        <div  style={{flex: 1, display: "flex", flexDirection: "column", flexWrap: "nowrap", alignItems: "flex-end"}}>
                            <span style={_modif2.reduce((acc, obj) => [...acc, obj.category], []).includes(_categ) ? {color: "red", textDecoration:"line-through", whiteSpace: "nowrap"} : {whiteSpace: "nowrap"}}>{ctx.data[_modif2[0].date][_modif2[0].repas][_categ][_dishId]?.name ?? "Aucun plat existant"}</span>
                            {_modif2.reduce((acc, obj) => [...acc, obj.category], []).includes(_categ) ? 
                            <span style={{whiteSpace: "nowrap", marginLeft: 5}}>{ctx.state[_modif2[0].date][_modif2[0].repas][_categ][ctx.state[_modif2[0].date].reservation[_modif2[0].uid][_modif2[0].repas][_modif2[0].index][_categ]]?.name ?? "Aucun plat existant"}</span>
                            : null}
                        </div>
                        </div>

                    ))}
                </div>
                ))
            }
            
            </div>
        </div>
        )) : null}
        </div>
    );
};

const ModalReservationChange = () => {
    const [ctx, dispatch] = useMenu();
    const [uid, setUid] = useState(null);

  const resetReservations = () => {
    let _state = deepClone(ctx.state);

    Object.keys(_state).forEach(_date => {
      _state[_date].reservation = deepClone(ctx.data[_date].reservation);
    });

    dispatch({ type: "setProperty", property : "state", value:_state});
  };

    return(
        <Modal isOpen={ctx.modalBrokenOpen} toggle={()=> dispatch({ type: "setProperty", property : "modalBrokenOpen", value:false})} size="xl">
      <ModalHeader>
        Modification de réservations
      </ModalHeader>
        <ModalBody>
          <div>
            <span>Les modifications que vous allez effectuer sur les menus vont modifier les réservations des résidents suivants :</span>
            <br></br>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <span style={{fontStyle: "italic", fontSize: 12}}>Il est possible de modifier les réservations dans l'onglet <a href="/dashboard/reservation/menus">réservation des menus</a>.</span>

            <div style={{width: "100%", display: "flex", flexDirection: "row", gap: 20, marginTop: 10, padding: 8, borderRadius: 8, backgroundColor: "#faf6ff"}}>
                <div style={{flex: 1, maxHeight: "40vh", minHeight: 200, display: "flex", flexDirection: "column", alignItems: "center", overflowY: "auto", gap: 8}}>
                    {ctx?.brokenList?.length > 0 ? Object.values(ctx.brokenList.reduce((acc, obj) => {
                            const key = obj.uid;
                            if(!acc[key])acc[key] = [];
                            acc[key].push(obj);
                            return acc;
                        }, {})).map((_modif, indx) => (
                          _modif && _modif[0] && _modif[0].uid ?
                            <div key={indx} onClick={() => setUid(_modif[0].uid)} style={{width: "95%", padding: 5, paddingTop: 10, paddingBottom: 10, backgroundColor: _modif[0].uid === uid ? "#51075e" : "#821097", borderRadius: 8, cursor: "pointer"}}>
                                {indx === 0 && uid === null ? setUid(_modif[0].uid): null}
                                <div style={{width: "100%", textAlign: "center", overflow: "hidden", textOverflow: "ellipsis", color: "white"}}>{ctx.users[_modif[0].uid]?.surname ?? ""} {ctx.users[_modif[0].uid]?.name ?? ""}</div>
                            </div>
                        :null )) : null}
                </div>
                <InfoComponent uid={uid}/>
            </div>

          </div>
        </ModalBody>
      <ModalFooter>
        <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
          <div style={{width: "40%", display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
          <Button color="primary" onClick={() => {ctx.save(ctx.brokenPublish !== undefined ? ctx.brokenPublish : true, true); dispatch({ type: "setProperty", property : "modalBrokenOpen", value:false}); dispatch({ type: "setProperty", property : "brokenList", value:[]});}}>{ctx.brokenPublish === false ? "Sauvegarder" : "Publier"}</Button>
          <Button color="secondary" onClick={()=> {dispatch({ type: "setProperty", property : "modalBrokenOpen", value:false}); resetReservations();}}>Annuler</Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
    );
};

InfoComponent.propTypes = {
    uid : PropTypes.string,
  };
export default ModalReservationChange;