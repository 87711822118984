import React from "react";
import {Link} from "react-router-dom";
import Logo from "../assets/images/jabiLogo/MAJOR_DETOUR.png";

import styles from "../assets/scss/config/PublicHeader.module.scss";

const PublicHeader = () => (
  <div className={styles.publicHeader}>
    <img src={Logo} style={{height: 42, width: 150}} />
    <div className={styles.links}>
      <Link to="/login">
        Se connecter
      </Link>
    </div>
  </div>
);

export default PublicHeader;