import React, { useState, useEffect } from "react";
import firebase from "firebase";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Select,
  Button,
  Spinner,
} from "lib/components";
import { useHistory, useParams } from "react-router-dom";
import { useFirebaseDocumentOnce } from "lib/hooks/firebase";
import { toast } from "react-toastify";
import roles from "enums/roles";
import useGroupement from "hooks/useGroupement";
import { Edit } from "react-feather";
import { getUserById, updateUserById } from "services/ressources/user";

const firestore = firebase.firestore;

const User = () => {
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const {groupement, establishments, loading: loadingEstablishments } = useGroupement();
  const [user, setUser] = useState({});
  const [establishmentsOptions, setEstablishmentsOptions] = useState([]);
  
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [role, setRole] = useState(null);
  const [region, setRegion] = useState(null);
  const [establishment, setEstablishment] = useState(null);

  useEffect(() => {
    (async () => {
      setUser(await getUserById({id: params.id}));
    })();
  });

  useEffect(() => {
    if (!user) return;
    setName(user.name);
    setSurname(user.surname);
    setRole(roles ? roles.find((i) => i.value === user.role) ?? null : null);
    setEstablishment(user.establishment);
    setRegion(user.region ?? null);
    setLoading(false);
  }, [user]);
  useEffect(() => {
    if (!establishments) return;
    let _options = [];
    establishments.forEach((e) => _options.push({ value: e.uid, label: e.name }));
    setEstablishmentsOptions(_options);
  }, [establishments]);

  useEffect(() => {
    if (!role) return;
    if(role.value === "regionAdmin")setEstablishment(null);

  }, [role]);

  const _handleUpdate = async () => {
    try {
      await updateUserById({id: params.id, data: {
        name,
        surname,
        role: role.value,
        establishment: establishment,
        region
      }});
      toast.success("Utilisateur mis à jour");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  if (loading || loadingEstablishments) return <Spinner />;
  return (
    <Container>
      <Row>
        <Col lg={8}>
          <Card>
            <CardHeader>
              <h4>Utilisateur</h4>
            </CardHeader>
            <CardBody>
              <FormGroup>
                <Input
                  type="text"
                  label="Nom"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Nom..."
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  label="Surname"
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                  placeholder="Surname..."
                />
              </FormGroup>
              <FormGroup>
                <Select
                  options={roles.filter((r) => !["superadmin", "groupementAdmin"].includes(r.value))}
                  label="Role"
                  value={role}
                  onChange={setRole}
                  placeholder="Role..."
                />
              </FormGroup>
              {role.value === "regionAdmin" ? 
              
              <FormGroup>
                <Select
                  options={groupement?.regions?.map(r=> ({label: r, value: r}))}
                  value={
                    groupement?.regions?.map(r=> ({label: r, value: r})).find((i) => i.value === region) ?? null
                  }
                  label="Région"
                  onChange={(e) => setRegion(e.value)}
                />
              </FormGroup>
              
              : 
              <FormGroup>
                <Select
                  options={establishmentsOptions}
                  value={
                    establishment
                      ? establishmentsOptions.find((i) => i.value === establishment) ?? null
                      : null
                  }
                  label="Etablissement"
                  onChange={(e) => setEstablishment(e.value)}
                />
              </FormGroup>
              }
              
            </CardBody>
            <CardFooter style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Button color="primary" onClick={_handleUpdate}>
                Enregistrer
              </Button>
            </CardFooter>
          </Card>
        </Col>
        {user.establishment && establishments.find((i) => i.uid === user.establishment) ? (
          <Col lg={4}>
            <Card>
              <CardHeader>
                <h4>Etablissement</h4>
              </CardHeader>
              <CardBody
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 20,
                }}
              >
                {establishments.find((i) => i.uid === user.establishment).profileImage ? (
                  <img
                    src={establishments.find((i) => i.uid === user.establishment).profileImage}
                    style={{ width: 200, borderRadius: 500 }}
                  />
                ) : null}
                <h5>{establishments.find((i) => i.uid === user.establishment).name}</h5>
                <Edit
                  size={30}
                  color="#4a0956"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push(`/dashboard/establishments/${user.establishment}`)}
                />
              </CardBody>
            </Card>
          </Col>
        ) : null}
      </Row>
    </Container>
  );
};

export default User;
