import React from "react";
import { Card, Spinner } from "lib/components";
import useGroupement from "hooks/useGroupement";
import styles from "assets/scss/pages/Groupement/home.module.scss";
import useStats, {Provider } from "lib/Stats/statsContext";
import { Global } from "lib/Stats/pages";
import moment from "moment";

const Home = () => {
  const { groupement, establishments, loading } = useGroupement();
  const [ctx, dispatch, hasFeature] = useStats();

  if (loading) return <Spinner />;
  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <h5>Mon Groupement:</h5>
        <span>{groupement.name}</span>
      </Card>
      <Card className={styles.card}>
        <h5>Mes établissements:</h5>
        {establishments &&
          establishments.map((i) => (
            <div key={i.uid}>
              <span>{i.name}</span>
            </div>
          ))}
      </Card>
      <div className={styles.statsContainer}>
          {ctx.dates ? 
            <>
              <div className={styles.dateDisplay}> Semaine du <b>{moment(ctx.dates.startDate).format("DD/MM/YYYY")}</b> comparé à la semaine du <b>{moment(ctx.dates.startCompare).format("DD/MM/YYYY")}</b></div>
              <Global/>
            </>
          : <Spinner />}
      </div>
    </div>
  );
};

const HomeContainer = () => {

  return (
    <Provider targetType="groupement">
      <Home/>
    </Provider>
  );
};
export default HomeContainer;
