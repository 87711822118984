import React, { useState, useEffect } from "react";
import useUI from "hooks/ui.hook";

import firebase from "firebase";

import styles from "../Assets/scss/menu.module.scss";

import moment from "moment";

import { Resume, ReservationTable } from "../../router_components";
import { useMenuReservation } from "../../router_context";

import { VideoTuto } from "lib/components/YoutubeModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Button } from "lib/components";
import {ExportTable} from "../../router_components";

import { filterRepas, sortRepas, sortCategories } from "pages/Major/Menu/helpers/operations";

const ReservationMenus = () => {
  const [ui] = useUI();
  const [ctx, dispatch] = useMenuReservation();
  const [showTotal, setShowTotal] = useState(false);
  const [modalExportOpen, setModalExportOpen] = useState(false);
  const [modalExportOpen2, setModalExportOpen2] = useState(false);

  //const [ui] = useUI();
  //const [page, setPage] = useState(0);

  useEffect(() => {
    if (ctx.data && ctx.users) {
      selectDate();
    }
  }, [ctx.data, ctx.users]);

  useEffect(() => {
    if (ctx.data && ctx.users) {
      selectDate();
    }
  }, [ctx?.selectedWeek, ctx.daysToDisplay]);

  const selectDate = () => {

    if (!ctx?.selectedWeek) {
      dispatch({ type: "setProperty", property: "selectedWeek", value: moment().startOf("week") });
    } else {
      let _dateArray = [];

      for (let i = 0; i < 7; i++) {
        if (ctx.daysToDisplay.includes(i + 1)) {
          _dateArray.push(ctx?.selectedWeek?.clone().add(i, "days").format("YYYY-MM-DD"));
        }
      }

      dispatch({ type: "setProperty", property: "dateArray", value: _dateArray });
    }
  };

  const onCloseExport = () => {
    setModalExportOpen(false);
    setModalExportOpen2(false);
  };

  const changeWeek = (direction) => {
    // direction = -1 || 1
    if (!ctx?.selectedWeek) {
      dispatch({ type: "setProperty", property: "selectedWeek", value: moment().startOf("week") });
    } else {
      dispatch({ type: "setProperty", property: "selectedWeek", value: ctx?.selectedWeek?.clone().add(direction, "week") });
    }
  };

  const generateResumeRepas = (data, repas) => {

    let retour = "";

    if (data[repas]) {
      const _categories = sortCategories(ui.establishment.template, repas, Object.keys(data[repas]));

      _categories.forEach(_categ => {
        const dishes = Object.keys(data[repas][_categ]);
        if (dishes.length > 0) {

          dishes.forEach(_dishId => {
            let count = 0;

            Object.keys(ctx.users).forEach(_uid => {
              if (data.reservation != undefined) {
                if (data.reservation[_uid] && data.reservation[_uid][repas] && data.reservation[_uid][repas].length > 0) {
                  data.reservation[_uid][repas].forEach(element => {
                    if (element[_categ] === _dishId) {
                      count++;
                    }
                  });
                }
              }
            });

            retour += data[repas][_categ][_dishId].name + " : " + count + "\n";

          });

        }
      });
    }


    return retour;
  };

  const generateExportData = (period, date, identifiedBySurname, identifiedByName, identifiedByNumero, total, signature) => {
    
    return generateExportDataBasic(period, date, identifiedBySurname, identifiedByName, identifiedByNumero, total, signature);

  };


  const isHomeDelivery = (repas) => {
    return repas[0].homeDelivery;
  };

  const generateExportDataBasic = async (period, date, identifiedBySurname, identifiedByName, identifiedByNumero, total, signature) => {

    const start = date.clone().toDate();
    const end = date.clone().add(1, period).toDate();
    const data = await ctx.getData(start, end);

    let generatedData = null;

    let header = {};

    if (identifiedByNumero) {
      header["Appartement"] = [];
    }
    if (identifiedBySurname) {
      header["Prénom"] = [];
    }
    if (identifiedByName) {
      header["Nom"] = [];
    }


    let _users = {};
    Object.entries(ctx.filteredUsers).forEach(([key, value]) => {
      _users[key] = { surname: value.surname, name: value.name, room: value.room, table: [] };
    });

    let totalList = [];
    let localDate = date.clone();
    let numDays = - localDate.diff(localDate.clone().add(1, period), "days");
    for (let i = 1; i <= numDays; i++) {
      let formated = localDate.format("YYYY-MM-DD");
      header[localDate.format("ddd DD/MM")] = [];
      if (data[formated] != undefined) {
        const dataDay = data[formated];
        sortRepas(ui.establishment.template, filterRepas(ui.establishment.template, Object.keys(data[formated]))).forEach(_repas => {
          let obj = {};
          let obj_content = {};
          //let content = generateResumeRepas(dataDay, _repas);

          //obj_content[content] = [];
          obj[_repas] = [];//[obj_content];
          header[localDate.format("ddd DD/MM")].push(obj);

          let tot = 0;

          Object.entries(_users).forEach(([key, value]) => {
            if (dataDay.reservation != undefined) {
              if (dataDay.reservation[key] && dataDay.reservation[key][_repas] && dataDay.reservation[key][_repas].length > 0) {
                value.table.push(dataDay.reservation[key][_repas].length);
                tot += dataDay.reservation[key][_repas].length;
              }
              else value.table.push("");
            } else value.table.push("");

          });


          if (total) {
            if (tot > 0) {
              totalList.push(tot);
            } else totalList.push("");
          }

        });
      } else { // aucun événement ce jour
        Object.entries(_users).forEach(([key, value]) => {
          value.table.push("");
        });
        if (total) totalList.push("");
      }
      localDate = moment(localDate).add(1, "day");
    }

    if (total) {
      header["Total"] = [];
    }
    if (signature) {
      header["Signature"] = [];
    }

    let _data = [];
    Object.values(_users).forEach((value) => {
      let table = value.table;
      let sum = 0;
      if (total) {
        table.forEach(element => {
          if (element != "" && Number.isInteger(parseInt(element))) sum += parseInt(element);
        });
      }
      if (identifiedByName) {
        table = [value.name, ...table];
      }
      if (identifiedBySurname) {
        table = [value.surname, ...table];
      }
      if (identifiedByNumero) {
        table = [value.room, ...table];
      }
      if (total) {
        table = [...table, sum];
      }
      if (signature) {
        table = [...table, ""];
      }
      _data.push(table);
    });
    let colStart = 0 + identifiedBySurname + identifiedByName + identifiedByNumero; // true == 1, false == 0;
    let colEnd = 0 + total + signature;

    if (total) {
      let total_total = 0;
      totalList.forEach(element => {
        let num = parseInt(element);
        if (!isNaN(num)) total_total += num;
      });

      for (let index = 0; index < colStart - 1; index++) {
        totalList = ["", ...totalList];
      }
      totalList = ["Total", ...totalList];


      totalList = [...totalList, total_total.toString()];

      _data.push(totalList);
    }

    generatedData = {
      header: header,
      data: _data,
      depth: 2,
      colStart: colStart,
      colEnd: colEnd
    };

    return generatedData;
  };


  const generateExportData2 = async (period, date) => {

    const start = date.clone().toDate();
    const end = date.clone().add(1, period).toDate();
    const data = await ctx.getData(start, end);


    let generatedData = null;
    let header = {};

    header["Repas"] = [];

    let _data = [];

    sortRepas(ui.establishment.template, filterRepas(ui.establishment.template, Object.keys(ui.establishment.template))).forEach(_repas => {
      let line = [];
      line.push(_repas);

      let localDate = date.clone();
      let numDays = - localDate.diff(localDate.clone().add(1, period), "days");
      for (let i = 1; i <= numDays; i++) {
        let formated = localDate.format("YYYY-MM-DD");
        header[localDate.format("ddd DD/MM")] = [];
        if (data[formated] != undefined) {
          const dataDay = data[formated];

          let content = generateResumeRepas(dataDay, _repas);

          line.push(content);

        } else { // aucun événement ce jour
          line.push("");
        }
        localDate = moment(localDate).add(1, "day");
      }
      // line.push("");
      _data.push(line);
    });
    _data.push([]);
    header[""] = [];

    generatedData = {
      header: header,
      data: _data,
      depth: 1,
      colStart: 1,
      colEnd: 0
    };

    return generatedData;
  };

  if (!ctx || !ctx.users || !ctx.data) return (<div></div>);
  return (
    <>
      <VideoTuto url="https://youtu.be/1P61Re4pRx4" />
      <div className={styles.menu}>
        <div className={styles.weekSelector}>
          <FontAwesomeIcon
            className={styles.icon}
            icon={faArrowLeft}
            onClick={() => {
              changeWeek(-1);
            }}
          />
          <div className={styles.weekText}>
            {ctx?.selectedWeek?.clone().format("DD MMMM YYYY")} - {ctx?.selectedWeek?.clone().endOf("week").format("DD MMMM YYYY")}
          </div>
          <FontAwesomeIcon
            className={styles.icon}
            icon={faArrowRight}
            onClick={() => {
              changeWeek(1);
            }}
          />
        </div>

        <div className={styles.buttonContainer} style={{ marginBottom: -52, marginLeft: 210 }}>
          <Button className={styles.exportButton} color="primary" onClick={() => setModalExportOpen(true)}>
            <span>
              Export résidents
            </span>
            <FontAwesomeIcon
              className={styles.angle}
              icon={faAngleDown}
            />
          </Button>

          <div className={styles.filterContainer}>
            <div onClick={() => dispatch({ type: "setFilterProperty", property: "role", value: "senior" })} className={`${styles.filterElement} ${ctx?.filter?.role === "senior" ? styles.active : ""}`}>
              Résidents
            </div>
            <div onClick={() => dispatch({ type: "setFilterProperty", property: "role", value: "employee" })} className={`${styles.filterElement} ${ctx?.filter?.role === "employee" ? styles.active : ""}`}>
              Collaborateurs
            </div>
          </div>

          <Button onClick={() => setShowTotal(!showTotal)}>{!showTotal ? "Afficher les totaux" : "Cacher les totaux"}</Button>
          
        </div>

        <ReservationTable showTotal={showTotal}/>

        {/* On enlève le deuxième tableau pour ovélia. */}
          <div className={styles.buttonContainer} style={{ marginLeft: 6, marginTop: 50, marginBottom: -100 }}>
            <Button className={styles.exportButton} color="primary" onClick={() => setModalExportOpen2(true)}>
              <span>
                Exporter ce tableau
              </span>
              <FontAwesomeIcon
                className={styles.angle}
                icon={faAngleDown}
              />
            </Button>
          </div>

          <Resume />

        {modalExportOpen ? (
          <ExportTable onClose={onCloseExport} dataGeneration={generateExportData} infos={{ title: "Export des Menus 1", type: "menu", date: ctx?.selectedDate }} />
        ) : null}
        {modalExportOpen2 ? (
          <ExportTable onClose={onCloseExport} dataGeneration={generateExportData2} infos={{ title: "Export des Menus", type: "menuDishes", date: ctx?.selectedDate }} />
        ) : null}
        {/*<Tab render={render} activeTab={page} onClick={pressHeaderHandler}/>*/}
        {/* <Details data={dataSelected} users={etabUsers} allData={dataMenus} updateData={updateData} selectedDate={selectedWeek}/> */}
      </div>
    </>

  );
};
export default ReservationMenus;
