import React, {useEffect, useContext} from "react";
import { useParams, useHistory } from "react-router-dom";
import { Spinner, Button, Card } from "../../../lib/components";
import useCultureQuizzInstance from "../../../hooks/useCultureQuizzInstance";
import { useFullscreen } from "rooks";
import {SidebarContext} from "../../../lib/context/dashboard/sidebar.context";
import styles from "assets/scss/pages/CutlureQuizz/QuizzStart.module.scss";

const QuizzStart = () => {
  const params = useParams();
  const history = useHistory();
  const {exit, request, isFullscreen} = useFullscreen();
  const [sidebarOpen, setSidebarOpen] = useContext(SidebarContext);

  const {
    loading,
    instance,
    participants,
    quizz,
    update,
    abort
  } = useCultureQuizzInstance(params.uid);
  
  const enterFullScreen = () => {
    if (!isFullscreen) request();
    if(sidebarOpen) setSidebarOpen(false);
  };

  const exitFullScreen = () => {
    if (isFullscreen) exit();
    //if(!sidebarOpen) setSidebarOpen(false);
  };

  useEffect(() => {
    enterFullScreen();
  }, []);

  const _abortThisQuizz = () => {exitFullScreen();abort();};

  const _startThisQuizz = async () => {
    update({
      status: "started",
      currentQuestion: 1,
      questionState: "answering",
    });
    history.push(`/dashboard/cultureQuizz/${params.uid}/play`);
  };

  if (loading || !instance)
    return <Spinner />;

  return (
    <div style={{ position: "relative", width: "100%", height: "85vh", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", gap: "15%"}}>
      {/* eslint-disable-next-line react/no-unescaped-entities*/}
      <h2 className={styles.h2}>Quiz "{quizz.title}"</h2>
      <Card style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
        <h3>Participants ({participants.length})</h3>
        {participants.length === 0 ? 
          // eslint-disable-next-line react/no-unescaped-entities
          <p>Aucun joueur n'a rejoint la parties actuellement</p>
          : <div style={{width: "60%", display: "flex", marginTop: "2%", flexWrap: "wrap", justifyContent: "space-around", gap: 30}}>
            {participants.map(participant => (
              <span key={participant.uid} 
                style={{border: "1px solid grey", padding: "5px 10px", borderRadius: 5, backgroundColor: "white", userSelect: "none"}}>
                {participant.surname} {participant.name}
              </span>
            ))}
          </div>
        }
      </Card>
      <h3 className={styles.h3}>En attente du début de la partie</h3>
      <Spinner size={"md"}/>

      <div style={{position: "absolute", zIndex: 1001, top: -95,left: "70px", right: "165px", height: "80px", display: "flex", justifyContent: "center"}}>
        <div style={{position: "relative", width: "95%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems:"center", gap: 30}}>
          <Button color="danger" onClick={_abortThisQuizz} style={{fontSize: "1.2vw", fontWeight:"bold"}}>Annuler le quiz</Button>
          <Button onClick={_startThisQuizz} style={{fontSize: "1.2vw", fontWeight:"bold"}}>Démarrer la partie</Button>
        </div>
      </div>
    </div>
  );
};

export default QuizzStart;