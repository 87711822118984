import {TextComponent, TextEditor, TextPdf} from "./Text";
import {Article, ArticleEditor, ArticlePdf} from "./Article";
import {ImageComponent, ImageEditor, ImagePdf} from "./Image";
import {PdfComponent,PdfEditor,ImagePdfPDF} from "./Pdf";


const contentType = [
  {
    slug: "text",
    name: "Bloc de texte",
    component: TextComponent,
    editor: TextEditor,
    formatCompatibility: ["full", "half", "tiers"],
    pdf: TextPdf,
  },
  {
    slug: "article",
    name: "Article",
    component: Article,
    editor: ArticleEditor,
    formatCompatibility: ["full", "half", "tiers"],
    pdf: ArticlePdf,
  },
  {
    slug: "image",
    name: "Image",
    component: ImageComponent,
    editor: ImageEditor,
    formatCompatibility: ["full"],
    pdf: ImagePdf,
  },
  {
    slug: "PDF",
    name: "PDF",
    component: PdfComponent,
    editor: PdfEditor,
    formatCompatibility: ["full"],
    pdf: ImagePdfPDF,
  }
];

export default contentType;