import React, { useEffect, useState } from "react";
import {
    Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Table, Card, CardHeader, CardBody, Input, FormGroup, ImagePicker
} from "lib/components";
import { Edit, Plus, List, BarChart, PlusCircle, Trash2 } from "react-feather";
import PropTypes from "prop-types";
import Restrictions, { RestrictionDisplay } from "./restrictions";

import styles from "../scss/bloc.module.scss";

export const ImageEdit = ({ blockData, parentRestrictions, remove }) => {
    const [isOpenSetup, setIsOpenSetup] = useState(false);
    const [text, setText] = useState("");
    const [image, setImage] = useState(null);
    const [restrictions, setRestrictions] = useState([]);

    useEffect(() => {
        if (blockData) {
            setText(blockData.text ?? "");
            setImage(blockData.image ?? "");
            setRestrictions(blockData.restrictions ?? []);
        }
    }, [blockData]);

    useEffect(() => {
        if (text && blockData.text !== text) {
            blockData.text = text;
        }
    }, [text]);

    useEffect(() => {
        if (image && blockData.image !== image) {
            blockData.image = image;
        }
    }, [image]);

    const _setup = () => {
        blockData.restrictions = restrictions;
        setIsOpenSetup(false);
    };


    return (
        <div style={{ width: "100%", padding: 20 }}>

            <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Input label="Informations liées à l'image" type="textarea" style={{ width: "100%" }} value={text} onChange={e => setText(e.target.value)} />
                    <ImagePicker value={image} onChange={setImage} />
                </div>
                {restrictions.length > 0 ?
                    <div className={styles.restrictionContainer}>
                        {restrictions.map((_r) => (
                            _r.value && _r.value.length > 0 ?
                                _r.value.map((_r2, _indx) => (
                                    <div key={_r.type + _indx}>
                                        <RestrictionDisplay restriction={{ type: _r.type, value: _r2 }} />
                                    </div>
                                ))
                                : null

                        ))}
                    </div>
                    : null
                }
                <Edit className={styles.icon} color={"blue"} size={30} onClick={() => setIsOpenSetup(true)} />
                <Trash2 className={styles.icon} color={"red"} size={30} onClick={remove} />

            </div>
            <Modal isOpen={isOpenSetup} toggle={() => setIsOpenSetup(false)} size="lg">
                <ModalHeader>{"Setup du composant"}</ModalHeader>
                <ModalBody>
                    <h3 className={styles.restrictions}>Restrictions : </h3>
                    <Restrictions parentRestrictions={parentRestrictions} currentRestrictions={restrictions} setRestrictions={setRestrictions} />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={_setup}>Valider</Button>
                    <Button color="secondary" onClick={() => setIsOpenSetup(false)}>Annuler</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};



export const ImageView = ({ blockData, parentRestrictions }) => {
    return (
        <div className={styles.viewContainer}>
            <div className={styles.viewContent}>
                <div className={styles.header} style={{width: "60%"}}>
                    <div className={styles.text} style={{flex: 1}}>{blockData?.text}</div>
                    {blockData?.restrictions?.length > 0 ?
                        <div className={styles.restrictionContainer}>
                            {blockData?.restrictions.map((_r) => (
                                _r.value && _r.value.length > 0 ?
                                    _r.value.map((_r2, _indx) => (
                                        <div key={_r.type + _indx}>
                                            <RestrictionDisplay restriction={{ type: _r.type, value: _r2 }} />
                                        </div>
                                    ))
                                    : null

                            ))}
                        </div>
                        : null
                    }

                </div>


                <img className={styles.imageSimpleContainer} src={blockData?.image}></img>
            </div>

        </div>
    );
};


ImageEdit.propTypes = {
    blockData: PropTypes.object,
    parentRestrictions: PropTypes.object,
    remove: PropTypes.func
};

ImageView.propTypes = {
    blockData: PropTypes.object,
    parentRestrictions: PropTypes.object,
};