import React, { useState, useEffect } from "react";
import useUI from "hooks/ui.hook";

import firebase from "firebase";

import styles from "../../../Basic/Assets/scss/menu.module.scss";

import moment from "moment";

import { Resume, ReservationTable } from "../../../router_components";
import { useMenuReservation } from "../../../router_context";

import { VideoTuto } from "lib/components/YoutubeModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Download } from "react-feather";
import { Button } from "lib/components";
import DropDown from "lib/components/DropDown";
import { ExportTable } from "../../../router_components";
import ExportDay from "../Components/ExportDay";

import { filterRepas, sortRepas, sortCategories } from "pages/Major/Menu/helpers/operations";
import ReservationTableGuest from "../Components/ReservationTableGuest";

const comptaInfo = [
  {
    "id": "RAPER",
    "name": "Apéritif",
    "price": "9,58"
  },
  {
    "id": "RMART",
    "name": "Martini",
    "price": "10,92"
  },
  {
    "id": "RCOIN",
    "name": "Liqueur orange Cointreau",
    "price": "1,09"
  },
  {
    "id": "RPAST",
    "name": "Pastis 45°",
    "price": "1,67"
  },
  {
    "id": "RPORT",
    "name": "Porto blanc 19°",
    "price": "1,67"
  },
  {
    "id": "RKIR",
    "name": "Kir",
    "price": "1,18"
  },
  {
    "id": "RCOGN",
    "name": "Cognac",
    "price": "1,27"
  },
  {
    "id": "RWISK",
    "name": "Whisky Eddu",
    "price": "5,33"
  },
  {
    "id": "RGET2",
    "name": "Get 27 / Menthe pastille",
    "price": "4,68"
  },
  {
    "id": "RBAAL",
    "name": "Bière avec alcool - Heineken",
    "price": "12,27"
  },
  {
    "id": "RBLAN",
    "name": "Bière avec alcool - Lancelot",
    "price": "5,08"
  },
  {
    "id": "RBANR",
    "name": "Anjou rouge (bouteille)",
    "price": "9,23"
  },
  {
    "id": "RBBCB",
    "name": "Baron de Canteblois (bouteille)",
    "price": "9,58"
  },
  {
    "id": "RBBOR",
    "name": "Bordeaux (bouteille)",
    "price": "9,58"
  },
  {
    "id": "RBCAB",
    "name": "Cabernet d'Anjou (bouteille)",
    "price": "6,36"
  },
  {
    "id": "RBCDP",
    "name": "Côte de Provence (bouteille)",
    "price": "4,68"
  },
  {
    "id": "RBCHA",
    "name": "Champagne (bouteille)",
    "price": "56,41"
  },
  {
    "id": "RBCHR",
    "name": "Chinon Rouge Bouteille - 75 CL",
    "price": "0,45"
  },
  {
    "id": "RBCID",
    "name": "Cidre (bouteille) doux",
    "price": "0,91"
  },
  {
    "id": "RBCIB",
    "name": "Cidre (bouteille) brut",
    "price": "0,68"
  },
  {
    "id": "RBCLA",
    "name": "Coteaux du Layon (bouteille)",
    "price": "0,45"
  },
  {
    "id": "RBCLO",
    "name": "Crémant de Loire (bouteille)",
    "price": "20,83"
  },
  {
    "id": "RBCLV",
    "name": "Coteaux du Layon (Verre 17 cl)",
    "price": "4,55"
  },
  {
    "id": "RBCOB",
    "name": "Côte du Rhône - vin blanc (bouteille)",
    "price": "2,07"
  },
  {
    "id": "RBCOT",
    "name": "Côte du Rhône - vin rouge (bouteille)",
    "price": "3,45"
  },
  {
    "id": "RBCSB",
    "name": "Coteaux de Samuletto (bouteille)",
    "price": "2,68"
  },
  {
    "id": "RBCSV",
    "name": "Coteaux de Samuletto (verre 17 cl)",
    "price": "2,91"
  },
  {
    "id": "RBMER",
    "name": "Merlot (bouteille)",
    "price": "5"
  },
  {
    "id": "RBMRP",
    "name": "Vin mousseux rose petillant (bouteille)",
    "price": "2,5"
  },
  {
    "id": "RBMUS",
    "name": "Muscadet (bouteille)",
    "price": "11,67"
  },
  {
    "id": "RBRDL",
    "name": "Rosé de Loire (bouteillle)",
    "price": "19,16"
  },
  {
    "id": "RBRVB",
    "name": "Vin mousseux Rose Veuve (bouteille)",
    "price": "19,17"
  },
  {
    "id": "RBSAL",
    "name": "Bière sans alcool",
    "price": "1,82"
  },
  {
    "id": "RBSAU",
    "name": "Sauvignon (bouteille)",
    "price": "0,91"
  },
  {
    "id": "RBSCH",
    "name": "Saumur Champigny Bouteille - 37.5 CL",
    "price": "1,67"
  },
  {
    "id": "RBSEB",
    "name": "Saint Emilion (bouteille)",
    "price": "12,5"
  },
  {
    "id": "RBSIR",
    "name": "Boisson au sirop",
    "price": "2,08"
  },
  {
    "id": "RBSNB",
    "name": "St Nicolas de Bourgueil (bouteille)",
    "price": "2,5"
  },
  {
    "id": "RBSNV",
    "name": "St Nicolas de Bourgueil (Verre 17cl)",
    "price": "4,58"
  },
  {
    "id": "RBVIN",
    "name": "Bouteille de vin",
    "price": "7,08"
  },
  {
    "id": "RCHAR",
    "name": "Chardonnay (bouteille)",
    "price": "1,09"
  },
  {
    "id": "RCHAB",
    "name": "Chablis blanc Jean Chaudenay Bourgogne (bouteille)",
    "price": "7,27"
  },
  {
    "id": "RCAEG",
    "name": "Cannette d'eau gazeuse",
    "price": "1,818"
  },
  {
    "id": "RCASO",
    "name": "Cannette de soda - coca",
    "price": "1,083"
  },
  {
    "id": "RCAOR",
    "name": "Cannette de soda - orangina",
    "price": "1,166"
  },
  {
    "id": "RDIGE",
    "name": "Digestif",
    "price": "1,667"
  },
  {
    "id": "REAGA",
    "name": "Eau gazeuse - 0.5 L",
    "price": "0,454"
  },
  {
    "id": "REAUG",
    "name": "Eau gazeuse - 1.5 L",
    "price": "0,909"
  },
  {
    "id": "REAUM",
    "name": "Eau minérale (1,5L)",
    "price": "0,6818"
  },
  {
    "id": "REAUL",
    "name": "Eau minérale (0,5L)",
    "price": "0,454"
  },
  {
    "id": "RPVIG",
    "name": "Pichet de vin (50cl)",
    "price": "5"
  },
  {
    "id": "RPVIP",
    "name": "Pichet de vin (25cl)",
    "price": "2,5"
  },
  {
    "id": "RREMI",
    "name": "Remise restauration",
    "price": "1"
  },
  {
    "id": "RROCO",
    "name": "Vin Rosé Corse (bouteillle)",
    "price": "12,916"
  },
  {
    "id": "RSUPA",
    "name": "Supplément Ardoise",
    "price": "1,818"
  },
  {
    "id": "RVJDF",
    "name": "Verre de jus de fruits",
    "price": "0,909"
  },
  {
    "id": "RVVIN",
    "name": "Verre de vin",
    "price": "1,667"
  },
  {
    "id": "RVMER",
    "name": "Merlot (verre)",
    "price": "2,083"
  },
  {
    "id": "RGASP",
    "name": "Changement de plat après commande",
    "price": "7,27"
  },
  {
    "id": "RDEJR",
    "name": "Déjeuner résident",
    "price": "13,1818"
  },
  {
    "id": "RDINR",
    "name": "Dîner résident",
    "price": "7,7273"
  },
  {
    "id": "RPOFD",
    "name": "Potage fromage et dessert",
    "price": "5"
  },
  {
    "id": "RPOTA",
    "name": "Potage",
    "price": "3,3634"
  },
  {
    "id": "RDEJI",
    "name": "Déjeuner invité",
    "price": "14,55"
  },
  {
    "id": "RDINI",
    "name": "Dîner invité",
    "price": "9,09"
  },
  {
    "id": "RDEJP",
    "name": "Déjeuner salarié",
    "price": "5,37"
  },
  {
    "id": "RPLAP",
    "name": "Plat salarié",
    "price": "2,41"
  },
  {
    "id": "XLIVR",
    "name": "Livraison des repas",
    "price": "1,0909"
  },
  {
    "id": "RDEJE",
    "name": "Déjeuner invité enfants",
    "price": "9,2273"
  },
  {
    "id": "RPOTD",
    "name": "Potage et dessert",
    "price": "3,4545"
  }
];

// Soir : Potage, plat, accompagnement, produit laitier, dessert.

const ReservationMenus = () => {
  const [ui] = useUI();
  const [ctx, dispatch] = useMenuReservation();
  const [modalExportOpen, setModalExportOpen] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [showTotal, setShowTotal] = useState(false);
  const [modalExportOpen2, setModalExportOpen2] = useState(false);
  const [modalExportOpen3, setModalExportOpen3] = useState(false);

  const template = ui?.establishment?.template;

  const sortedRepas = sortRepas(template, Object.keys(template));

  // update data ! 
  // useEffect(() => {

  // Attention, ne pas réactiver si on ne sait pas à quoi ça sert ! (rewrite toutes les données des orders pour rajouter la date en timestamp manquante)
  //   firestore()
  //     .collection("establishments")
  //     .doc(ui.user.establishment)
  //     .collection("blocks")
  //     .doc("restaurant")
  //     .collection("orders")
  //     .get().then((s) => {

  //       const newDocs = s.docs.map(_d => ({ ..._d.data() }));

  //       const docsToUpdate = newDocs.filter(_d => !_d.day);

  //       const updatedDocs = docsToUpdate.map(_d => ({..._d, day: moment(_d.date).toDate()}));
  //       console.log(docsToUpdate.length, docsToUpdate);

  //       // Diviser les nouveaux documents en lots de 500
  //       const batchSize = 500;
  //       for (let i = 0; i < updatedDocs.length; i += batchSize) {
  //         const batch = firestore().batch();
  //         const batchDocs = updatedDocs.slice(i, i + batchSize);

  //         batchDocs.forEach((doc) => {
  //           const ref = firestore()
  //             .collection("establishments")
  //             .doc(ui.user.establishment)
  //             .collection("blocks")
  //             .doc("restaurant")
  //             .collection("orders")
  //             .doc(doc.uid);

  //           batch.update(ref, doc);
  //         });

  //         // Commit the batch
  //         batch.commit().then(() => {
  //           console.log("Batch committed successfully.");
  //         }).catch((error) => {
  //           console.error("Error committing batch:", error);
  //         });
  //       }


  //     });
  //   return () => {

  //   };
  // }, []);


  useEffect(() => {
    if (ctx.data && ctx.users) {
      selectDate();
    }
  }, [ctx.data, ctx.users]);

  useEffect(() => {
    if (ctx.data && ctx.users) {
      selectDate();
    }
  }, [ctx?.selectedWeek, ctx.daysToDisplay]);

  const selectDate = () => {

    if (!ctx?.selectedWeek) {
      dispatch({ type: "setProperty", property: "selectedWeek", value: moment().startOf("week") });
    } else {
      let _dateArray = [];

      for (let i = 0; i < 7; i++) {
        if (ctx.daysToDisplay.includes(i + 1)) {
          _dateArray.push(ctx?.selectedWeek?.clone().add(i, "days").format("YYYY-MM-DD"));
        }
      }

      dispatch({ type: "setProperty", property: "dateArray", value: _dateArray });
    }
  };

  const onCloseExport = () => {
    setModalExportOpen(false);
    setModalExportOpen2(false);
    setModalExportOpen3(false);
  };

  const changeWeek = (direction) => {
    // direction = -1 || 1
    if (!ctx?.selectedWeek) {
      dispatch({ type: "setProperty", property: "selectedWeek", value: moment().startOf("week") });
    } else {
      dispatch({ type: "setProperty", property: "selectedWeek", value: ctx?.selectedWeek?.clone().add(direction, "week") });
    }
  };

  const generateResumeRepas = (data, repas) => {

    let retour = "";

    if (data[repas]) {
      const _categories = sortCategories(ui.establishment.template, repas, Object.keys(data[repas]));

      _categories.forEach(_categ => {
        const dishes = Object.keys(data[repas][_categ]);
        if (dishes.length > 0) {

          dishes.forEach(_dishId => {
            let count = 0;

            Object.keys(ctx.users).forEach(_uid => {
              if (data.reservation != undefined) {
                if (data.reservation[_uid] && data.reservation[_uid][repas] && data.reservation[_uid][repas].length > 0) {
                  data.reservation[_uid][repas].forEach(element => {
                    if (element[_categ] === _dishId) {
                      count++;
                    }
                  });
                }
              }
            });

            retour += data[repas][_categ][_dishId].name + " : " + count + "\n";

          });

        }
      });
    }


    return retour;
  };


  const isHomeDelivery = (repas) => {
    return repas[0].homeDelivery;
  };

  const generateExportData = async (date) => {
    const start = moment(date).startOf("day").clone().toDate();
    const end = moment(date).clone().endOf("day").toDate();
    const periodData = await ctx.getData(start, end);

    const formated = moment(date).format("YYYY-MM-DD");

    const _obj = {};
    const _obj2 = {};

    if (periodData.data[formated] && periodData.data[formated].reservation) {
      Object.entries(periodData.data[formated].reservation).forEach(([userId, values]) => {
        if (values) {
          Object.entries(values).forEach(([repas, reservations]) => {
            // avec portage
            if (reservations && reservations[0] && reservations[0].status !== "canceled" && reservations[0].homeDelivery === true) {
              const _categories = template[repas].categories;

              if (!_obj[repas]) _obj[repas] = {};

              if (!_obj[repas][userId]) _obj[repas][userId] = [];

              _obj[repas][userId] = reservations.map(_r => {
                const _array = [];
                _categories.forEach(_categ => {
                  if (_r[_categ]) _array.push(periodData.data[formated][repas][_categ][_r[_categ]].name);
                });
                return _array;
              });

            }

            // sans portage
            if (reservations && reservations[0] && reservations[0].status !== "canceled" && !reservations[0].homeDelivery) {
              const _categories = template[repas].categories;

              if (!_obj2[repas]) _obj2[repas] = {};

              if (!_obj2[repas][userId]) _obj2[repas][userId] = [];

              _obj2[repas][userId] = reservations.map(_r => {
                const _array = [];
                _categories.forEach(_categ => {
                  if (_r[_categ]) _array.push(periodData.data[formated][repas][_categ][_r[_categ]].name);
                });
                return _array;
              });
            }
          });
        }

      });
    }


    const data = {};

    // LES TOTAUX
    const totalHeaders = [{ v: moment(date).format("dddd DD MMMM YYYY"), s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true }, border: { bottom: { style: "thin", color: "000000" } } } }];
    const totalSubHeaders = [{ v: "", s: {} }];

    sortRepas(template, Object.keys(template)).forEach(_repas => {
      totalHeaders.push({ v: _repas, s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true }, border: { bottom: { style: "thin", color: "000000" } } } });
      totalHeaders.push({ v: "", s: {} });
      totalHeaders.push({ v: "", s: {} });
      totalHeaders.push({ v: "", s: {} });
      totalHeaders.push({ v: "", s: {} });

      totalSubHeaders.push({ v: "total", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } }, border: { bottom: { style: "thin", color: "000000" } } });
      totalSubHeaders.push({ v: "résident", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } }, border: { bottom: { style: "thin", color: "000000" } } });
      totalSubHeaders.push({ v: "invité", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } }, border: { bottom: { style: "thin", color: "000000" } } });
      totalSubHeaders.push({ v: "collaborateur", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } }, border: { bottom: { style: "thin", color: "000000" } } });
      totalSubHeaders.push({ v: "", s: {} });


    });
    if (!data["Totaux"]) data["Totaux"] = [totalHeaders];
    data["Totaux"].push(totalSubHeaders);

    const totalData = {
      "Total": {
        "Déjeuner": {
          "total": [0, 0, 0, 0],
          "complet": [0, 0, 0, 0],
          "plat seul": [0, 0, 0, 0],
        },
        "Dîner": {
          "total": [0, 0, 0, 0],
          "complet": [0, 0, 0, 0],
          "allégé": [0, 0, 0, 0],
          "potage": [0, 0, 0, 0],
          "plat seul": [0, 0, 0, 0],
        }
      },
      "Total Restaurant": {
        "Déjeuner": {
          "total": [0, 0, 0, 0],
          "complet": [0, 0, 0, 0],
          "plat seul": [0, 0, 0, 0],
        },
        "Dîner": {
          "total": [0, 0, 0, 0],
          "complet": [0, 0, 0, 0],
          "allégé": [0, 0, 0, 0],
          "potage": [0, 0, 0, 0],
          "plat seul": [0, 0, 0, 0],
        }
      },
      "Total Portage": {
        "Déjeuner": {
          "total": [0, 0, 0, 0],
          "complet": [0, 0, 0, 0],
          "plat seul": [0, 0, 0, 0],
        },
        "Dîner": {
          "total": [0, 0, 0, 0],
          "complet": [0, 0, 0, 0],
          "allégé": [0, 0, 0, 0],
          "potage": [0, 0, 0, 0],
          "plat seul": [0, 0, 0, 0],
        }
      },
    };

    // [total, résident, invité, collaborateur]
    const updateTotalData = (totalArray, array, uid, change, index) => {
      array[0] += change;
      totalArray[0] += change;

      if (index > 0) {
        array[2] += change;
        totalArray[2] += change;
      } else {
        if (ctx?.users[uid]) {
          if (ctx?.users[uid].role === "senior" || ctx?.users[uid].role === "seniorTotem") {
            array[1] += change;
            totalArray[1] += change;
          }

          if (ctx?.users[uid].role === "employee" || ctx?.users[uid].role === "owner") {

            array[3] += change;
            totalArray[3] += change;
          }
        }
      }

    }


    sortedRepas.forEach(_repas => {

      Object.keys(periodData.data[formated].reservation).forEach(resa => {
        const _resa = periodData.data[formated].reservation[resa];
        if (_resa[_repas] && _resa[_repas].length > 0 && _resa[_repas][0].status !== "canceled") {
          _resa[_repas].forEach((_r, index) => {
            let mode = null;

            if (_r.homeDelivery) {
              updateTotalData(totalData["Total"][_repas]["total"], totalData["Total Portage"][_repas]["total"], resa, 1, index);
              // totalData["Portage"][_repas]["total"] += 1;
              mode = "Total Portage";

            } else {
              // totalData["Restaurant"][_repas]["total"] += 1;
              updateTotalData(totalData["Total"][_repas]["total"], totalData["Total Restaurant"][_repas]["total"], resa, 1, index);
              mode = "Total Restaurant";
            }


            if (sortedRepas.indexOf(_repas) === 0) {

              //si c'est un employé et qu'il n'y a seulement un plat de commandé :
              if ((_r["Plat"] || _r["Accompagnement"]) && !_r["Potage"] && !_r["Entrée"] && !_r["Dessert"] /* && !_r["Boisson chaude"] */) {
                if (ctx?.users[resa].role === "employee" || ctx?.users[resa].role === "owner") {
                  updateTotalData(totalData["Total"][_repas]["plat seul"], totalData[mode][_repas]["plat seul"], resa, 1, index);
                } else {
                  updateTotalData(totalData["Total"][_repas]["complet"], totalData[mode][_repas]["complet"], resa, 1, index);
                }
              } else {
                updateTotalData(totalData["Total"][_repas]["complet"], totalData[mode][_repas]["complet"], resa, 1, index);
              }

              // totalData[mode][_repas]["complet"] += 1;
            } else {

              // Si réservation vide
              if (Object.keys(_r).filter(element =>
                template[_repas].categories.includes(element) && _r[element]).length === 0) {

                // alors on fait en fonction des abonnements : 

                const _subscription = ctx.usersUnfiltered[resa]?.subscription;

                if (_subscription && _subscription.includes("Dîner")) {
                  if (_subscription.includes("allégé")) {
                    updateTotalData(totalData["Total"][_repas]["allégé"], totalData[mode][_repas]["allégé"], resa, 1, index);
                    // totalData[mode][_repas]["allégé"] += 1;
                  } else if (_subscription.includes("potage")) {
                    updateTotalData(totalData["Total"][_repas]["potage"], totalData[mode][_repas]["potage"], resa, 1, index);
                    // totalData[mode][_repas]["potage"] += 1;
                  } else {
                    updateTotalData(totalData["Total"][_repas]["complet"], totalData[mode][_repas]["complet"], resa, 1, index);
                    // totalData[mode][_repas]["complet"] += 1;
                  }
                } else
                  updateTotalData(totalData["Total"][_repas]["complet"], totalData[mode][_repas]["complet"], resa, 1, index);
                // totalData[mode][_repas]["complet"] += 1;
              }
              // Si plat ou accompagnement
              else if (_r["Plat"] || _r["Accompagnement"]) {

                //si c'est un employé et qu'il n'y a seulement un plat de commandé :
                if ((_r["Plat"] || _r["Accompagnement"]) && !_r["Potage"] && !_r["Entrée"] && !_r["Dessert"] /* && !_r["Boisson chaude"] */) {
                  if (ctx?.users[resa].role === "employee" || ctx?.users[resa].role === "owner") {
                    updateTotalData(totalData["Total"][_repas]["plat seul"], totalData[mode][_repas]["plat seul"], resa, 1, index);
                  } else {
                    updateTotalData(totalData["Total"][_repas]["complet"], totalData[mode][_repas]["complet"], resa, 1, index);
                  }
                } else {
                  updateTotalData(totalData["Total"][_repas]["complet"], totalData[mode][_repas]["complet"], resa, 1, index);
                }
                // totalData[mode][_repas]["complet"] += 1;s
              }
              // Si uniquement potage
              else if (_r["Potage"] &&
                Object.entries(_r).filter(([key, value]) =>
                  key !== "Potage" && template[_repas].categories.includes(key) && value).length === 0) {
                updateTotalData(totalData["Total"][_repas]["potage"], totalData[mode][_repas]["potage"], resa, 1, index);
                // totalData[mode][_repas]["potage"] += 1;
              }
              // Si plusieurs catégories
              else {
                updateTotalData(totalData["Total"][_repas]["potage"], totalData[mode][_repas]["potage"], resa, 1, index);
                // totalData[mode][_repas]["allégé"] += 1;s
              }
            }
          });
        }
      });
    });

    Object.keys(totalData).forEach(mode => {
      data["Totaux"].push(["", "", ""]);

      ["total", "complet", "allégé", "potage", "plat seul"].forEach((_type, index) => {
        const line = [];

        let style = {};
        if (index === 0) style = { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true }, border: { bottom: { style: "thin", color: "000000" } } };
        else style = { alignment: { vertical: "center", horizontal: "center", wrapText: true } };

        line.push({ v: `${index === 0 ? mode : _type}`, s: style });

        Object.keys(totalData[mode]).forEach(_repas => {
          if (totalData[mode][_repas][_type]) {
            totalData[mode][_repas][_type].forEach(_count => {
              line.push({ v: _count, s: style });
            });
          } else {
            line.push({ v: "", s: {} }); line.push({ v: "", s: {} }); line.push({ v: "", s: {} }); line.push({ v: "", s: {} });
          }
          line.push({ v: "", s: {} });
        });

        data["Totaux"].push(line);
      });
    });



    // DETAILS PORTAGE

    sortedRepas.forEach(_repas => {
      const sheetName = "Portage " + _repas;
      let _repasData = [];
      if (!data[sheetName]) data[sheetName] = [
        [{ v: moment(date).format("dddd DD MMMM YYYY"), s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
        { v: sheetName, s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } }
        ],
        [
          { v: "Nom", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: "Prénom", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: "Appartement", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: "Abonnement", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: "Régime/Allergie", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          ...template[_repas].categories.map(_categ => {
            return { v: _categ, s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } };
          })
        ]];

      if (_obj && _obj[_repas]) {


        Object.entries(_obj[_repas]).forEach(([userId, _resas], idx) => {

          const _localData = [];

          _resas.forEach((_resa, _indx) => {
            const _subscription = ctx.usersUnfiltered[userId]?.subscription;
            const _restoInfos = ctx.usersUnfiltered[userId]?.restoInfos;

            if(!ctx.usersUnfiltered[userId])console.log(userId);
            let _line = [
              { v: ctx.usersUnfiltered[userId]?.name ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: ctx.usersUnfiltered[userId]?.surname ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: ctx.usersUnfiltered[userId]?.room ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: _subscription ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: _restoInfos ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } }
            ];

            if (_indx > 0) {
              _line = [
                { v: "invité", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
                { v: "invité", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
                { v: "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
                { v: "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
                { v: ctx.usersUnfiltered[userId].room, s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } }
              ];
            }
            if (_resa.length === 0) {
              // alors on fait en fonction des abonnements : 


              if (_subscription && _repas === "Dîner" && _subscription.includes("Dîner")) {

                if (_subscription.includes("allégé")) {
                  template[_repas].categories.forEach(_categ => {
                    _line.push({
                      v: _categ !== "Plat" && _categ !== "Accompagnement" ? Object.values(periodData.data[formated][_repas][_categ])
                        .find(_plate => _plate.origin === "Menu du jour")?.name ?? _categ + " du jour" : "",
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    }
                    );
                  });
                } else if (_subscription.includes("potage")) {
                  template[_repas].categories.forEach(_categ => {
                    _line.push({
                      v: _categ === "Potage" ? Object.values(periodData.data[formated][_repas][_categ])
                        .find(_plate => _plate.origin === "Menu du jour")?.name ?? _categ + " du jour" : "",
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    }
                    );
                  });
                } else {
                  template[_repas].categories.forEach(_categ => {
                    _line.push({
                      v: Object.values(periodData.data[formated][_repas][_categ])
                        .find(_plate => _plate.origin === "Menu du jour")?.name ?? _categ + " du jour",
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    }
                    );
                  });
                }
              } else {
                // Cas de réservation sans désigner des plats spécifiques = Plat du jour
                template[_repas].categories.forEach(_categ => {
                  _line.push({
                    v: Object.values(periodData.data[formated][_repas][_categ])
                      .find(_plate => _plate.origin === "Menu du jour")?.name ?? _categ + " du jour",
                    s: {
                      alignment: { vertical: "center", horizontal: "center", wrapText: true },
                    }
                  }
                  );
                });

              }

            } else {
              // Si on spécifie des plats
              template[_repas].categories.forEach(_categ => {
                let plate = Object.values(periodData.data[formated][_repas][_categ])
                  .find(_plate => _resa.includes(_plate.name));
                if (plate) {
                  // Plat du jour donc on affiche le nom
                  if (plate.origin === "Menu du jour") {
                    _line.push({
                      v: plate.name,
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    });
                  } else {
                    // Sinon on affiche le plat avec un style différent
                    _line.push({
                      v: plate.name,
                      s: {
                        font: { color: { rgb: "000000" }, bold: true },
                        // fill: { fgColor: { rgb: "e39da7" } },
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    });
                  }
                } else {
                  _line.push("");
                }
              });
            }
            _localData.push(_line);
          });

          // console.log("localData", _localData);
          data[sheetName] = [...data[sheetName]];
          _repasData.push(_localData);
        });
      }
      // Tri des lignes
      // _repasData.sort((a, b) => {
      //   const vANom = a[0][0].v ?? "";
      //   const vAPrenom = a[0][1].v ?? "";
      //   const vBNom = b[0][0].v ?? "";
      //   const vBPrenom = b[0][1].v ?? "";

      //   return `${vANom} ${vAPrenom}`.localeCompare(`${vBNom} ${vBPrenom}`);
      // });
      _repasData.sort((a, b) => {
        const vARoom = a[0][2].v ?? "";
        const vBRoom = b[0][2].v ?? "";

        return vARoom.localeCompare(vBRoom);
      });

      // Coloriser une ligne sur deux
      _repasData.forEach((lines, linesIndex) => {
        lines.forEach((line, lineIndex) => {
          line.forEach((cell, cellIndex) => {
            if (linesIndex % 2 === 0) {
              _repasData[linesIndex][lineIndex][cellIndex] = {
                ...cell,
                s: {
                  ...cell.s,
                  fill: {
                    fgColor: {
                      rgb: "b7dacf"
                    }
                  },
                }
              };
            }
          });
        });
        return lines;
      });
      data[sheetName] = [...data[sheetName], ..._repasData.flat()];
    });


    // DETAILS RESERVATIONS

    sortedRepas.forEach(_repas => {
      const sheetName = "Restaurant " + _repas;
      let _repasData = [];
      if (!data[sheetName]) data[sheetName] = [
        [{ v: moment(date).format("dddd DD MMMM YYYY"), s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
        { v: sheetName, s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } }
        ],
        [
          { v: "Nom", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: "Prénom", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: "Appartement", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: "Abonnement", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          { v: "Régime/Allergie", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
          ...template[_repas].categories.map(_categ => {
            return { v: _categ, s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } };
          })
        ]];

      if (_obj2 && _obj2[_repas]) {


        Object.entries(_obj2[_repas]).forEach(([userId, _resas], idx) => {

          const _localData = [];

          _resas.forEach((_resa, _indx) => {
            const _subscription = ctx.usersUnfiltered[userId]?.subscription;
            const _restoInfos = ctx.usersUnfiltered[userId]?.restoInfos;

            let _line = [
              { v: ctx.usersUnfiltered[userId]?.name ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: ctx.usersUnfiltered[userId]?.surname ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: ctx.usersUnfiltered[userId]?.room ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: _subscription ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
              { v: _restoInfos ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } }
            ];

            if (_indx > 0) {
              _line = [
                { v: "invité", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
                { v: "invité", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
                { v: "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
                { v: "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
                { v: ctx.usersUnfiltered[userId].room, s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } }
              ];
            }
            if (_resa.length === 0) {
              // alors on fait en fonction des abonnements : 


              if (_subscription && _repas === "Dîner" && _subscription.includes("Dîner")) {

                if (_subscription.includes("allégé")) {
                  template[_repas].categories.forEach(_categ => {
                    _line.push({
                      v: "",
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    }
                    );
                  });
                } else if (_subscription.includes("potage")) {
                  template[_repas].categories.forEach(_categ => {
                    _line.push({
                      v: "",
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    }
                    );
                  });
                } else {
                  template[_repas].categories.forEach(_categ => {
                    _line.push({
                      v: "",
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    }
                    );
                  });
                }
              } else {
                // Cas de réservation sans désigner des plats spécifiques = Plat du jour
                template[_repas].categories.forEach(_categ => {
                  _line.push({
                    v: "",
                    s: {
                      alignment: { vertical: "center", horizontal: "center", wrapText: true },
                    }
                  }
                  );
                });

              }

            } else {
              // Si on spécifie des plats
              template[_repas].categories.forEach(_categ => {
                let plate = Object.values(periodData.data[formated][_repas][_categ])
                  .find(_plate => _resa.includes(_plate.name));
                if (plate) {
                  // Plat du jour donc on affiche le nom
                  if (plate.origin === "Menu du jour") {
                    _line.push({
                      v: plate.name,
                      s: {
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    });
                  } else {
                    // Sinon on affiche le plat avec un style différent
                    _line.push({
                      v: plate.name,
                      s: {
                        font: { color: { rgb: "000000" }, bold: true },
                        // fill: { fgColor: { rgb: "e39da7" } },
                        alignment: { vertical: "center", horizontal: "center", wrapText: true },
                      }
                    });
                  }
                } else {
                  _line.push({
                    v: "",
                    s: {
                      alignment: { vertical: "center", horizontal: "center", wrapText: true },
                    }
                  });
                }
              });
            }
            _localData.push(_line);
          });

          // console.log("localData", _localData);
          data[sheetName] = [...data[sheetName]];
          _repasData.push(_localData);
        });
      }
      // Tri des lignes
      // _repasData.sort((a, b) => {
      //   const vANom = a[0][0].v ?? "";
      //   const vAPrenom = a[0][1].v ?? "";
      //   const vBNom = b[0][0].v ?? "";
      //   const vBPrenom = b[0][1].v ?? "";

      //   return `${vANom} ${vAPrenom}`.localeCompare(`${vBNom} ${vBPrenom}`);
      // });
      _repasData.sort((a, b) => {
        const vARoom = a[0][2].v ?? "";
        const vBRoom = b[0][2].v ?? "";

        return vARoom.localeCompare(vBRoom);
      });

      // Coloriser une ligne sur deux
      _repasData.forEach((lines, linesIndex) => {
        lines.forEach((line, lineIndex) => {
          line.forEach((cell, cellIndex) => {
            if (linesIndex % 2 === 0) {
              _repasData[linesIndex][lineIndex][cellIndex] = {
                ...cell,
                s: {
                  ...cell.s,
                  fill: {
                    fgColor: {
                      rgb: "b7dacf"
                    }
                  },
                }
              };
            }
          });
        });
        return lines;
      });
      data[sheetName] = [...data[sheetName], ..._repasData.flat()];
    });

    console.log("cc", data);
    return data;
  };

  const generateExportData2 = async (period, date) => {
    const start = date.clone().toDate();
    const end = date.clone().add(1, period).toDate();
    const periodData = await ctx.getData(start, end);

    let generatedData = null;
    let header = {};

    header["Repas"] = [];

    let _data = [];

    sortRepas(ui.establishment.template, filterRepas(ui.establishment.template, Object.keys(ui.establishment.template))).forEach(_repas => {
      let line = [];
      line.push(_repas);

      let localDate = date.clone();
      let numDays = - localDate.diff(localDate.clone().add(1, period), "days");
      for (let i = 1; i <= numDays; i++) {
        let formated = localDate.format("YYYY-MM-DD");
        header[localDate.format("ddd DD/MM")] = [];
        if (periodData.data[formated] != undefined) {
          const dataDay = periodData.data[formated];

          let content = generateResumeRepas(dataDay, _repas);

          line.push(content);

        } else { // aucun événement ce jour
          line.push("");
        }
        localDate = moment(localDate).add(1, "day");
      }
      // line.push("");
      _data.push(line);
    });
    _data.push([]);
    header[""] = [];

    generatedData = {
      header: header,
      data: _data,
      depth: 1,
      colStart: 1,
      colEnd: 0
    };

    return generatedData;
  };

  const isObjectEmpty = (resa) => {
    let retour = true;
    Object.values(resa).forEach(element => {
      if (element) retour = false;
    });
    return retour;
  };

  const isResaEmpty = (resa, correspondingTemplate) => {
    let retour = true;
    correspondingTemplate.categories.forEach(_categ => {
      if (resa[_categ]) retour = false;
    });
    return retour;
  };

  const getSKU = (repas, reservations, userId) => {
    const _resa = reservations[0];

    const _resaInfo = Object.entries(_resa).filter(([key, value]) => template[repas]?.categories?.includes(key)).filter(([key, value]) => value);

    const _role = ctx.usersUnfiltered && ctx.usersUnfiltered[userId] && ctx.usersUnfiltered[userId].role ? ["senior", "seniorTotem"].includes(ctx.usersUnfiltered[userId].role) ? "senior" : ["employee", "owner"].includes(ctx.usersUnfiltered[userId].role) ? "employee" : "senior" : "senior";
    if (sortedRepas.indexOf(repas) === 0) {
      if (_role === "senior") {
        return comptaInfo.find((_c) => _c.id === "RDEJR");
      } else {
        // s'il n'y a qu'un plat de pris alors, SKU Plat simple
        if ((_resa["Plat"] || _resa["Accompagnement"]) && !_resa["Potage"] && !_resa["Entrée"] && !_resa["Dessert"] /* && !_resa["Boisson chaude"] */) {
          return comptaInfo.find((_c) => _c.id === "RPLAP");
        } else {
          return comptaInfo.find((_c) => _c.id === "RDEJP");
        }
      }
    } else {
      if (_resa["Plat"] || _resa["Accompagnement"] || (_resaInfo.length == 0)) {
        return comptaInfo.find((_c) => _c.id === "RDINR");
      } else if (_resa["Potage"] && !_resa["Plat"] && !_resa["Accompagnement"] && !_resa["Produit Laitier"] && !_resa["Dessert"]) {
        return comptaInfo.find((_c) => _c.id === "RPOTA");
      } else {
        return comptaInfo.find((_c) => _c.id === "RPOFD");
      }
    }
  };

  const getGuestSKU = (repas, userId, guests) => {
    const guest = guests.find((_g) => _g.guestId === userId);

    if (guest && (guest.type === "establishmentGuest" || guest.type === "guest")) {
      if (repas === "Déjeuner")
        return comptaInfo.find((_c) => _c.id === "RDEJI");
      if (repas === "Dîner")
        return comptaInfo.find((_c) => _c.id === "RDINI");
    }

  };

  const generateExportDataRestaurant = async (period, date) => {
    const start = date.clone().toDate();
    let end = date.clone().add(1, period).toDate();
    const currentDate = moment();
    if (currentDate.isBetween(moment(start), moment(end), undefined, "[]")) {
      end = currentDate.toDate();
    }
    const periodData = await ctx.getData(start, end);

    let generatedData = null;
    let header = {};

    header["Société"] = [];
    header["Date export"] = [];
    header["Type de tiers"] = [];
    header["Compte client"] = [];
    header["Montant HT Article"] = [];
    header["Détails Article information libre"] = [];
    header["N° devis Observation"] = [];
    header["Période 1er cadre texte pied bloc 1"] = [];
    header["Période 2ème cadre texte pied bloc 2"] = [];
    header["Période 3ème cadre texte pied bloc 3"] = [];
    header["Libellé Article"] = [];
    header["Code article"] = [];
    header["Prix unitaire"] = [];
    header["Quantité facturée"] = [];
    header["Type logement Axe analytique 6"] = [];


    let obj = {};
    let _guestObj = {};
    let localDate = date.clone();
    let numDays = - localDate.diff(localDate.clone().add(1, period), "days");
    const dateExport = `${moment().format("DD/MM/YYYY")}`;

    const datePeriod = `Du ${moment(start).format("DD MMMM YYYY")} au ${moment(end).format("DD MMMM YYYY")}`;

    const exportMonth = `${numDays < 35 ? localDate.format("MMMM YYYY") : localDate.format("YYYY")}`;

    for (let i = 1; i <= numDays; i++) {
      let formated = localDate.format("YYYY-MM-DD");
      if (periodData.dataOrders[formated] != undefined) {
        Object.entries(periodData.dataOrders[formated]).forEach(([userId, values]) => {
          if (!obj[userId])
            obj[userId] = {};

          Object.entries(values).forEach(([repas, reservations]) => {
            if (!isObjectEmpty(reservations)) {
              const _SKU = getSKU(repas, reservations, userId);
              if (!obj[userId][_SKU.name])
                obj[userId][_SKU.name] = {
                  total: 0,
                  id: _SKU.id,
                  name: _SKU.name,
                  price: _SKU.price
                };
              obj[userId][_SKU.name].total += 1;
            }
          });

        });
        if (periodData.dataOrdersSupplements[formated] != undefined) {

          Object.entries(periodData.dataOrdersSupplements[formated]).forEach(([userId, values]) => {
            if (!obj[userId])
              obj[userId] = {};

            Object.values(values).forEach((reservations) => {
              reservations.forEach((supplement) => {
                const _SKUSupplement = comptaInfo.find(_s => _s.id === supplement.id);
                if (!obj[userId][supplement.name]) {
                  obj[userId][supplement.name] = {
                    total: 0,
                    id: _SKUSupplement?.id,
                    name: _SKUSupplement?.name,
                    price: _SKUSupplement?.price,
                  };
                }
                obj[userId][supplement.name].total += supplement.number;
              });
            });
          });
        }
      }
      // LES INVITES

      if (periodData.dataGuestOrders[formated] != undefined) {
        Object.entries(periodData.dataGuestOrders[formated]).forEach(([userId, values]) => {
          if (!_guestObj[userId])
            _guestObj[userId] = {};

          Object.entries(values).forEach(([repas, reservations]) => {
            if (!isObjectEmpty(reservations)) {
              const _SKU = getGuestSKU(repas, userId, periodData.guests);
              if (_SKU && !_guestObj[userId][_SKU.name]) {
                _guestObj[userId][_SKU.name] = {
                  total: 0,
                  id: _SKU.id,
                  name: _SKU.name,
                  price: _SKU.price,
                };
                _guestObj[userId][_SKU.name].total += 1;
              }
            }
          });

        });
        if (periodData.dataGuestOrdersSupplements[formated] != undefined) {

          Object.entries(periodData.dataGuestOrdersSupplements[formated]).forEach(([userId, values]) => {
            if (!_guestObj[userId])
              _guestObj[userId] = {};

            Object.values(values).forEach((reservations) => {
              reservations.forEach((supplement) => {
                const _SKUSupplement = comptaInfo.find(_s => _s.id === supplement.id);
                if (!_guestObj[userId][supplement.name]) {
                  _guestObj[userId][supplement.name] = {
                    total: 0,
                    id: _SKUSupplement?.id,
                    name: _SKUSupplement?.name,
                    price: _SKUSupplement?.price,
                  };
                }
                _guestObj[userId][supplement.name].total += supplement.number;
              });
            });
          });
        }
      }

      //Ajout des résas :
      // uniquement si il n'y a pas de présence au restaurant.

      if (periodData.data[formated] && periodData.data[formated].reservation) {
        Object.entries(periodData.data[formated].reservation).forEach(([userId, values]) => {
          if (values) {
            Object.entries(values).forEach(([repas, reservations]) => {
              // si il n'y a pas de présence en restaurant.
              if (!(periodData.dataOrders[formated] && periodData.dataOrders[formated][userId] && periodData.dataOrders[formated][userId][repas])) {
                if (reservations && reservations[0] && !(reservations[0]?.status === "canceled")) {
                  if (!obj[userId])
                    obj[userId] = {};

                  if (!isObjectEmpty(reservations)) {

                    if (reservations[0].createdLocally || isResaEmpty(reservations[0], template[repas])) {
                      // Dans ce cas on applique le sku en fonction de l'abonnement.

                      if (repas === "Dîner") {
                        const _subscription = ctx.usersUnfiltered[userId]?.subscription;

                        let _SKU = comptaInfo.find((_c) => _c.id === "RDINR");

                        if (_subscription && _subscription.includes("Dîner")) {
                          if (_subscription.includes("allégé")) {
                            _SKU = comptaInfo.find((_c) => _c.id === "RPOFD");
                          } else if (_subscription.includes("potage")) {
                            _SKU = comptaInfo.find((_c) => _c.id === "RPOTA");
                          } else {
                            _SKU = comptaInfo.find((_c) => _c.id === "RDINR");
                          }
                        }

                        if (!obj[userId][_SKU.name])
                          obj[userId][_SKU.name] = {
                            total: 0,
                            id: _SKU.id,
                            name: _SKU.name,
                            price: _SKU.price,
                          };
                        obj[userId][_SKU.name].total += 1;

                      } else {
                        const _SKU = comptaInfo.find((_c) => _c.id === "RDEJR");
                        if (!obj[userId][_SKU.name])
                          obj[userId][_SKU.name] = {
                            total: 0,
                            id: _SKU.id,
                            name: _SKU.name,
                            price: _SKU.price,
                          };
                        obj[userId][_SKU.name].total += 1;
                      }

                    } else {
                      const _SKU = getSKU(repas, reservations, userId);
                      if (!obj[userId][_SKU.name])
                        obj[userId][_SKU.name] = {
                          total: 0,
                          id: _SKU.id,
                          name: _SKU.name,
                          price: _SKU.price,
                        };
                      obj[userId][_SKU.name].total += 1;
                    }


                    //invités :
                    if (reservations.length > 1) {
                      let guestSKU = "";


                      if (repas === "Déjeuner")
                        guestSKU = comptaInfo.find((_c) => _c.id === "RDEJI");
                      if (repas === "Dîner")
                        guestSKU = comptaInfo.find((_c) => _c.id === "RDINI");

                      if (!obj[userId][guestSKU.name])
                        obj[userId][guestSKU.name] = {
                          total: 0,
                          id: guestSKU.id,
                          name: guestSKU.name,
                          price: guestSKU.price,
                        };

                      obj[userId][guestSKU.name].total += reservations.length - 1;
                    }

                    if (reservations[0].homeDelivery === true) {
                      const _SKUPortage = comptaInfo.find(_c => _c.id === "XLIVR");

                      // si le portage n'est pas gratuit :
                      if (!reservations[0].freeHomeDelivery) {

                        if (!obj[userId][_SKUPortage.name])
                          obj[userId][_SKUPortage.name] = {
                            total: 0,
                            id: _SKUPortage.id,
                            name: _SKUPortage.name,
                            price: _SKUPortage.price,
                          };
                        obj[userId][_SKUPortage.name].total += reservations.length;
                      }
                    }

                  }
                }
              }
            });
          }

        });
      }



      localDate = moment(localDate).add(1, "day");
    }

    let _data = [];

    Object.entries(obj).sort(([aUserId, _dataA], [bUserId, _dataB]) => {
      if (ctx.usersUnfiltered[aUserId] && ctx.usersUnfiltered[bUserId]) {
        const codeA = ctx.usersUnfiltered[aUserId]?.codeClient ?? `Pas de code client (${ctx.usersUnfiltered[aUserId]?.name ?? ctx.guest[aUserId]?.name ?? "Pas de nom"})`;
        const codeB = ctx.usersUnfiltered[bUserId]?.codeClient ?? `Pas de code client (${ctx.usersUnfiltered[bUserId]?.name ?? ctx.guest[bUserId]?.name ?? "Pas de nom"})`;

        return codeA.localeCompare(codeB);
      } else {
        return true;
      }
    }).forEach(([userId, values]) => {
      if (ctx.usersUnfiltered[userId]) {
        Object.entries(values).forEach(([commande, value]) => {
          _data.push([ui?.establishment?.societe ?? "", dateExport, "", ctx.usersUnfiltered[userId]?.codeClient ?? `Pas de code client (${ctx.usersUnfiltered[userId]?.name ?? ctx.guest[userId]?.name ?? "Pas de nom"})`, "", "", "", datePeriod, exportMonth, "", commande ?? "", value.id ?? "", value?.price ? parseFloat(value?.price?.replace(",", ".") ?? "") ?? "" : "", value.total ?? "", ctx.usersUnfiltered[userId]?.roomType ?? ""]);
        });
      }
    });


    // Reformater l'objet guest pour reduce sur deux types (invité résidence et invité seul)
    const _guestObjFormated = {
      "Invités établissement": {},
      "Invités seuls": {},
    };

    Object.entries(_guestObj).forEach(([userId, values]) => {
      const guest = periodData.guests.find(guest => guest.guestId === userId);
      if (guest) {
        if (guest.type === "establishmentGuest") {
          _guestObjFormated["Invités établissement"] = Object.entries(values).reduce((acc, [key, value]) => {
            if (acc[value.name]) {
              acc[value.name].total += value.total;
            } else {
              acc[value.name] = value;
            }
            return acc;
          }, { ..._guestObjFormated["Invités établissement"] });
        }
        if (guest.type === "guest") {
          _guestObjFormated["Invités seuls"] = Object.entries(values).reduce((acc, [key, value]) => {
            if (acc[value.name]) {
              acc[value.name].total += value.total;
            } else {
              acc[value.name] = value;
            }
            return acc;
          }, { ..._guestObjFormated["Invités seuls"] });
        }
      }
    });

    Object.entries(_guestObjFormated).forEach(([key, values]) => {
      Object.entries(values).forEach(([commande, value]) => {
        _data.push([ui?.establishment?.societe ?? "", dateExport, "", key, "", "", "", datePeriod, exportMonth, "", commande ?? "", value.id ?? "", parseFloat(value?.price?.replace(",", ".") ?? "") ?? "", value.total ?? "", ""]);
      });
    });

    generatedData = {
      header: header,
      data: _data,
      depth: 1,
      colStart: 0,
      colEnd: 0
    };

    return generatedData;
  };

  if (!ctx || !ctx.users || !ctx.data) return (<div></div>);
  return (
    <>
      <VideoTuto url="https://youtu.be/1P61Re4pRx4" />
      <div className={styles.menu}>
        <div className={styles.weekSelector}>
          <FontAwesomeIcon
            className={styles.icon}
            icon={faArrowLeft}
            onClick={() => {
              changeWeek(-1);
            }}
          />
          <div className={styles.weekText}>
            {ctx?.selectedWeek?.clone().format("DD MMMM YYYY")} - {ctx?.selectedWeek?.clone().endOf("week").format("DD MMMM YYYY")}
          </div>
          <FontAwesomeIcon
            className={styles.icon}
            icon={faArrowRight}
            onClick={() => {
              changeWeek(1);
            }}
          />
        </div>

        <div className={styles.buttonContainer} style={{ marginBottom: -52, marginLeft: "15%" }}>
          <Button onClick={() => setIsDropDownOpen(!isDropDownOpen)} style={{ position: "relative", display: "flex", gap: 10 }}>
            Exporter
            <FontAwesomeIcon
              className={styles.angle}
              icon={isDropDownOpen ? faAngleDown : faAngleRight}
            />
            <DropDown isOpen={isDropDownOpen} style={{ top: 38, paddingLeft: 30, paddingRight: 30 }} toggle={() => setIsDropDownOpen(false)}>

              <Button className={styles.exportButton} color="primary" onClick={() => setModalExportOpen3(true)}>
                <span>
                  Export restauration
                </span>
                <Download
                  color="white"
                  size={30}
                />
              </Button>

              <Button className={styles.exportButton} style={{ marginTop: 10 }} color="primary" onClick={() => setModalExportOpen(true)}>
                <span>
                  Export du jour
                </span>
                <Download
                  color="white"
                  size={30}
                />
              </Button>


            </DropDown>
          </Button>


          <div className={styles.filterContainer}>
            <div onClick={() => dispatch({ type: "setFilterProperty", property: "role", value: "senior" })} className={`${styles.filterElement} ${ctx?.filter?.role === "senior" ? styles.active : ""}`}>
              Résidents
            </div>
            <div onClick={() => dispatch({ type: "setFilterProperty", property: "role", value: "employee" })} className={`${styles.filterElement} ${ctx?.filter?.role === "employee" ? styles.active : ""}`}>
              Collaborateurs
            </div>
            <div onClick={() => dispatch({ type: "setFilterProperty", property: "role", value: "guest" })} className={`${styles.filterElement} ${ctx?.filter?.role === "guest" ? styles.active : ""}`}>
              Invités
            </div>


          </div>

          <Button onClick={() => setShowTotal(!showTotal)}>{!showTotal ? "Afficher les totaux" : "Cacher les totaux"}</Button>

        </div>





        {ctx.filter?.role === "guest" ? <ReservationTableGuest showTotal={showTotal} /> : <ReservationTable showTotal={showTotal} />}
        {/* On enlève le deuxième tableau pour ovélia. */}
        <div className={styles.buttonContainer} style={{ marginLeft: 6, marginTop: 50, marginBottom: -100 }}>
          <Button className={styles.exportButton} style={{ display: "flex", gap: 10, padding: 8 }} color="primary" onClick={() => setModalExportOpen2(true)}>
            <span>
              Exporter ce tableau
            </span>
            <Download
              color="white"
              size={30}
            />
          </Button>
        </div>

        <Resume />

        {modalExportOpen ? (
          <ExportDay onClose={onCloseExport} dataGeneration={generateExportData} infos={{ title: "Export du jour", type: "portage", date: ctx?.selectedWeek }} />
        ) : null}
        {modalExportOpen2 ? (
          <ExportTable onClose={onCloseExport} dataGeneration={generateExportData2} infos={{ title: "Export des Menus", type: "menuDishes", date: ctx?.selectedWeek }} />
        ) : null}
        {modalExportOpen3 ? (
          <ExportTable onClose={onCloseExport} dataGeneration={generateExportDataRestaurant} infos={{ title: "Export restaurant", type: "restaurant", date: ctx?.selectedWeek }} />
        ) : null}
        {/*<Tab render={render} activeTab={page} onClick={pressHeaderHandler}/>*/}
        {/* <Details data={dataSelected} users={etabUsers} allData={dataMenus} updateData={updateData} selectedDate={selectedWeek}/> */}
      </div>
    </>

  );
};
export default ReservationMenus;
