import React, {useEffect,useState} from "react";
import {useServiceReservation} from "../../routerContext";
import { ArrowLeft, Plus, Check, Edit } from "react-feather";
import { Button, Tab, Row, Icon , Card, Modal, ModalHeader,Container, Spinner } from "lib/components";
import { Reservation,ListePrestataire,CreationPrestataire,CreationPrestation, Disponibility } from "../../routerPages";

const ServiceReservationIndex = (props) => {    
    const [ctx, { updatePage,updateChosenService }] = useServiceReservation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(()=> {
      if(ctx.prestataires && ctx.planning)
        setLoading(false);
    },[ctx.prestataires, ctx.planning]);


    if(loading)return <Spinner/>;

    return (
        <Container>
        {ctx.page < 2 ? //redirection de la page à afficher
         <> 
                <Tab  activeTab={ctx.page}  onClick={(e) => {updatePage(e);}} render={[
                    { title: "Reservation", content: <Reservation/> },
                    { title: "Liste des prestataires", content: <ListePrestataire />, }
                ]}>
                </Tab>
            </>
           : 
           ctx.page==2 ? 
             <CreationPrestataire/>
              :
              ctx.page==3?
                <CreationPrestation/>
                :
                <Disponibility></Disponibility>
         } 
    </Container>
		);
};

export default ServiceReservationIndex;

  