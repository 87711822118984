import { Button, Modal, ModalFooter, ModalBody, ModalHeader } from "lib/components";
import React, {useState, useEffect} from "react";
import {PDFViewer, Document} from "@react-pdf/renderer";
import pageTypes from "../pageTypes";
import useUI from "hooks/ui.hook";

import contentTypes from "../contentTypesHeurus";
import useGazette from "../../../context";

const ContentEditorHeurus = () => {
  const [ui] = useUI();
  const [ctx, dispatch] = useGazette();
  const [mode, setMode] = useState(null); // "edit" or "create"
  const [field, setField] = useState({});

  const [isPreviOpen, setIsPreviOpen] = useState(false);

  useEffect(() => {
    if (!ctx.selectedPage || !ctx.selectedField || !ctx.selectedContent) return;

    //get page in context
    let _page = ctx.pages.find(page => page.uid === ctx.selectedPage.uid);
    _page = {..._page};
    if (!_page) {dispatch({type: "setStep", step: 1}); return;}

    let _field = _page[ctx.selectedField.key];
    if (_field) {
      setMode("edit");
      setField(JSON.parse(_field));
    } else {
      setMode("create");
      setField({
        contentType: ctx.selectedContent.slug,
        format: ctx.selectedField.format,
      });
    }
  }, [ctx.step]);
  useEffect(() => {
    if (!ctx.selectedPage) return;
    let _page = ctx.pages.find(page => page.uid === ctx.selectedPage.uid);
    _page = {..._page};
    _page[ctx.selectedField.key] = JSON.stringify(field);
    dispatch({type: "setPage", page: _page});
  }, [field]);

  const _onSubmit = () => {
    let _page = ctx.pages.find(page => page.uid === ctx.selectedPage.uid);
    _page = {..._page};
    _page[ctx.selectedField.key] = JSON.stringify(field);
    dispatch({type: "setPage", page: _page});
    
    setMode(null);
    setField({});
    dispatch({type: "setSelectedPage", page: null});
    dispatch({type: "setSelectedField", field: null});
    dispatch({type: "setSelectedContent", contentType: null});
    dispatch({type: "setStep", step: 1});
  };
  const _onGoBack = () => {
    setMode(null);
    setField({});
    dispatch({type: "setSelectedPage", page: null});
    dispatch({type: "setSelectedField", field: null});
    dispatch({type: "setSelectedContent", contentType: null});
    dispatch({type: "setStep", step: 1});
    setIsPreviOpen(false);
  };
  const _onCancel = () => {
    setMode(null);
    setField({});
    dispatch({type: "setSelectedPage", page: null});
    dispatch({type: "setSelectedField", field: null});
    dispatch({type: "setSelectedContent", contentType: null});
    dispatch({type: "setStep", step: 1});
  };

  const _onChangeField = (key, value) => {setField({...field, [key]: value});};
  const _onSetField = (value) => {setField(value);};

  if (!mode) return <>loading...</>;
  return (
    <>
      {field ? contentTypes.find(i => i.slug === field.contentType).editor({
        onChange: _onChangeField,
        onSet: _onSetField,
        onSubmit: _onSubmit,
        onCancel: _onCancel,
        field,
        page: {
          slug: ctx.selectedPage?.slug,
          key: ctx.selectedField?.key
        },
      }) : null}

      <div style={{display: "flex", justifyContent: "center", gap: 10}}>
        <Button color="primary" onClick={() => setIsPreviOpen(true)}>Continuer</Button>
      </div>
      {isPreviOpen ? 
        <Modal isOpen={isPreviOpen} toggle={() => setIsPreviOpen(false)} size="lg">
          <ModalHeader>
            <h4 style={{marginBottom: -10, marginRight: 20, marginLeft: 20, textAlign: "center"}}>Vérifiez que le format de prévisualisation correspond à vos attentes</h4>
          </ModalHeader>
          <ModalBody>
            {ctx.pages ? 
              <PDFViewer style={{width: "100%", height: "73vh"}}>
                <Document>
                  {ctx.pages.filter(i => i.uid === ctx.selectedPage.uid).map((page, index) => (
                    <>
                      {pageTypes.find(type => type.slug === page.slug) ? 
                        pageTypes.find(type => type.slug === page.slug).pdf({page, ctx, index, ui, contentTypes})
                        : null}
                    </>
                  ))}
                </Document>
              </PDFViewer>
              : null}
          </ModalBody>
          <ModalFooter style={{display: "flex", justifyContent: "center"}}>
            <Button color="secondary" onClick={() => setIsPreviOpen(false)}>Retour</Button>
            <Button color="primary" onClick={() => _onGoBack()}>Valider</Button>
          </ModalFooter>
        </Modal>
        : null}
    </>
  );
};

export default ContentEditorHeurus;