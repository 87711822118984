import React,{useEffect} from "react";
import PropTypes from "prop-types";
import {createContext, useReducer, useContext} from "react";
import firebase from "firebase";
import moment from "moment";
import useUI from "hooks/ui.hook";
import deepClone from "helpers/deepClone";
import {toast} from "react-toastify"; 
import axios from "axios";
import { getEstablishmentById } from "services/ressources/establishment";
import { getGroupementById } from "services/ressources/groupement";

const Context = createContext();

const Default = {
  dates: null,
  data1Raw: null,
  data2Raw: null,
  data1: null,
  data2: null,
  target: null,
  targetType: "all",
  features: null,
  superAdminView: false,
  loading: true,
};

function Reducer(state, action) {
    switch(action.type) {
    case "setProperty": return ({...state, [action.property]: action.value});
    default: return {...state};
    }
  }

const Provider = ({targetType, target, children}) => {
  const [ui] = useUI();
  const [ctx, dispatch] = useReducer(Reducer, Default);

    useEffect(() => {
        (async ()=> {
            dispatch({ type: "setProperty", property : "dates", value: {
                startDate: moment().startOf("week").format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
                startCompare: moment().add(-1, "week").startOf("week").format("YYYY-MM-DD"),
                endCompare: moment().add(-1, "week").format("YYYY-MM-DD"),
            } });

            if(targetType === "establishment"){
                dispatch({ type: "setProperty", property : "targetType", value: "establishment"});

                let _features = [];
        
                if(target){
                    _features = await findEstablishmentFeatures(target);
                }else{
                    _features = ui.establishment.features;
                }

                dispatch({ type: "setProperty", property : "features", value: _features});
            }
            if(targetType === "groupement" || targetType === "region"){
                let _id = "";

                if(target){
                    _id = target;
                }else{
                    _id = ui.user.groupement;
                }

                let _features = await findGroupementFeatures(_id);
                dispatch({ type: "setProperty", property : "features", value: _features});
            }
        })()
    },[]);

    const getData = async () => {
        if(targetType === "groupement"){
            // let _id = "loBfmuH2POn8ArKJr2Dl";
            let _id = "";

            if(target){
                _id = target;
            }else{
                _id = ui.user.groupement;
            }

            const res = await axios.get("/analytics?&groupement="+_id+"&startDate="+ctx.dates.startDate+"&endDate="+ctx.dates.endDate, {withCredentials: false, timeout: 0});
            const res2 = await axios.get("/analytics?&groupement="+_id+"&startDate="+ctx.dates.startCompare+"&endDate="+ctx.dates.endCompare, {withCredentials: false, timeout: 0});
            
            dispatch({ type: "setProperty", property : "data1Raw", value: res.data});
            dispatch({ type: "setProperty", property : "data2Raw", value: res2.data});

            if(ctx.targetType === "all"){
                dispatch({ type: "setProperty", property : "data1", value: res.data});
                dispatch({ type: "setProperty", property : "data2", value:  res2.data});
            }else if(ctx.targetType === "region"){
                const _data1 = res.data.regions[ctx.target];
                const _data2 = res2.data.regions[ctx.target];
                if(_data1 && _data2){
                dispatch({ type: "setProperty", property : "data1", value: _data1});
                dispatch({ type: "setProperty", property : "data2", value: _data2});
                }
            }else{
                const _data1 = findEstablishmentData(res.data, ctx.target);
                const _data2 = findEstablishmentData(res2.data, ctx.target);
                if(_data1 && _data2){
                dispatch({ type: "setProperty", property : "data1", value: _data1});
                dispatch({ type: "setProperty", property : "data2", value: _data2});
                }
            }
            
        }
        if(targetType === "region"){
            // let _id = "loBfmuH2POn8ArKJr2Dl";
            let _id = "";
            let _region = "";
            if(target){
                _id = target;
            }else{
                _id = ui.user.groupement;
                _region = ui.user.region;
            }

            const res = await axios.get("/analytics?&groupement="+_id+"&region="+_region+"&startDate="+ctx.dates.startDate+"&endDate="+ctx.dates.endDate, {withCredentials: false, timeout: 0});
            const res2 = await axios.get("/analytics?&groupement="+_id+"&region="+_region+"&startDate="+ctx.dates.startCompare+"&endDate="+ctx.dates.endCompare, {withCredentials: false, timeout: 0});

            dispatch({ type: "setProperty", property : "data1Raw", value: res.data});
            dispatch({ type: "setProperty", property : "data2Raw", value: res2.data});

            if(ctx.targetType === "all"){
                dispatch({ type: "setProperty", property : "data1", value: res.data});
                dispatch({ type: "setProperty", property : "data2", value:  res2.data});
            }else{
                const _data1 = findEstablishmentData(res.data, ctx.target);
                const _data2 = findEstablishmentData(res2.data, ctx.target);
                if(_data1 && _data2){
                dispatch({ type: "setProperty", property : "data1", value: _data1});
                dispatch({ type: "setProperty", property : "data2", value: _data2});
                }
            }
            
        }
        if(targetType === "establishment"){

            let _id = "";

            if(target){
                _id = target;
            }else{
                _id = ui.establishment.uid;
            }

            const res = await axios.get("/analytics?&establishment="+_id+"&startDate="+ctx.dates.startDate+"&endDate="+ctx.dates.endDate, {withCredentials: false, timeout: 0});
            const res2 = await axios.get("/analytics?&establishment="+_id+"&startDate="+ctx.dates.startCompare+"&endDate="+ctx.dates.endCompare, {withCredentials: false, timeout: 0});
      
            if(res.data && res2.data)
            dispatch({ type: "setProperty", property : "establishment", value: ""});

            dispatch({ type: "setProperty", property : "data1Raw", value: res.data});
            dispatch({ type: "setProperty", property : "data2Raw", value: res2.data});
            dispatch({ type: "setProperty", property : "data1", value: res.data});
            dispatch({ type: "setProperty", property : "data2", value: res2.data});
        }

        dispatch({ type: "setProperty", property : "loading", value: false});

    };

    useEffect(()=> {
        if(ctx.dates){
            dispatch({ type: "setProperty", property : "loading", value: true});
            getData();
        }
        
      }, [ctx.dates]);

    const hasFeature = (feature, real) => {
        if(real){
            if(ctx.features){
                if(ctx.features.includes(feature)){
                    return true;
                }else{
                    return false;
                }
            }else{
                return false;
            }
        }else{
            if(ctx.superAdminView === true && (ui.user.role === "superadmin" || ui.user.superadmin === true) && feature === "statistics") return true;
            if(ctx.features){
                if(ctx.features.includes(feature)){
                    return true;
                }else{
                    return false;
                }
            }else{
                return false;
            }
        }
    };
    
  return (
    <Context.Provider value={[ctx, dispatch, hasFeature]}>
      {children}
    </Context.Provider>
  );
};

const findGroupementFeatures = async (groupementId) => {
    const grpmntData =  await getGroupementById({id: groupementId});
    const establishments = grpmntData.establishments;

    let features = [];
    if(grpmntData.features)features = grpmntData.features;

    const promises = establishments.map(id => getEstablishmentById({id: id}));

    const snapshots = await Promise.all(promises);
    
    snapshots.forEach(element => {
        const etabFeatures = element.features;

        if(etabFeatures){
            etabFeatures.forEach(feature => {
                if(!features.includes(feature)) features.push(feature);
            });
        }
    });

    return features;
};

const findEstablishmentFeatures = async (establishmentId) => {
    const etabData = getEstablishmentById({id: establishmentId});

    let features = [];
    if(etabData && etabData.features)features = etabData.features;

    return features;
};

const findEstablishmentData = (data, id) => {
    const etabs = data.establishments;
    let _data = null;
    etabs.forEach(_etab => {
      if(_etab.id === id)_data = _etab;
    });
    return _data;
  };

Provider.propTypes = {
  target: PropTypes.string,
  targetType: PropTypes.string,
  children: PropTypes.element,
};
const useStats = () => useContext(Context);
export default useStats;
export {Provider, Context};