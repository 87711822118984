import React, {useState} from "react";
import styles from "assets/scss/pages/Gazette/gazettes.module.scss";
import { Plus, Trash } from "react-feather";
import {Button} from "lib/components";
import { useHistory } from "react-router-dom";
import { useFirebaseCollection } from "lib/hooks/firebase";
import useUI from "hooks/ui.hook";
import firebase from "firebase";
import moment from "moment";
import { toast } from "react-toastify";
import { VideoTuto } from "lib/components/YoutubeModal";

const firestore = firebase.firestore;

const Gazette = () => {
  const history = useHistory();
  const [ui] = useUI();
  const [gazettesRef] = useState(firestore().collection("establishments").doc(ui.user.establishment).collection("gazettes"));
  const {data} = useFirebaseCollection(gazettesRef, []);

  const _delete = async (uid) => {
    try {
      if (window.confirm("Voulez-vous vraiment supprimer cette gazette ?")) {
        await gazettesRef.doc(uid).delete();
      }
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  };

  console.log(data);

  return (
    <>
      <div className={styles.container} style={{paddingTop: 40}}>
        <div className={styles.createContainer} onClick={() => history.push("/dashboard/gazette/edit")}>
          <div></div>
          <Plus size={120} color="white" />
          <Button color="secondaryDark">Créer une gazette</Button>
        </div>
        {data.sort((a, b) => moment(b.createdAt.toDate()).format("YYYYMMDD") - moment(a.createdAt.toDate()).format("YYYYMMDD")).map(gazette => (
          <div className={styles.gazette} key={gazette.uid}>
            <div className={styles.header}>
              <h2>{gazette.title}</h2>
              <Trash color="red" size={26} style={{cursor: "pointer"}} onClick={() => _delete(gazette.uid)} />
            </div>
            <div style={{width: "100%", maxHeight: "70%", display: "flex", flexDirection: "row"}}>
              <div style={{ height: "100%", flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                {gazette.pages.map((pageStr, indx) => (
                  JSON.parse(pageStr).title ? 
                      indx < 5 ? 
                        JSON.parse(pageStr).slug !== "summary" ? 
                          <div key={indx} style={{display: "flex",width: "90%",maxWidth: 200, flexDirection: "row"}}>
                            <div>{JSON.parse(pageStr).title}</div>
                              <div style={{flex: 1,overflow: "hidden",marginLeft: 5,marginRight: 5}}>
                                {new Array(500).fill(".").join("")}
                              </div>
                              <div style={{color: "#D26F47"}}>{(indx+1)}</div>
                            </div>
                        : null
                      : 
                      indx === 5 ? 
                        <div key={indx} style={{width: "90%", flexDirection: "row"}}>
                          ...
                        </div>
                      : null
                  : null
              ))}

              </div>
              <div style={{flex: 1}}>
                  {gazette.pages.filter(pageStr => JSON.parse(pageStr)["1"] ? JSON.parse(JSON.parse(pageStr)["1"]).image !== undefined : false).length > 0 ?
                    <img style={{width: "100%", maxHeight: "100%", objectFit: "contain"}} src={JSON.parse(JSON.parse(gazette.pages.filter(pageStr => JSON.parse(pageStr)["1"] ? JSON.parse(JSON.parse(pageStr)["1"]).image !== undefined : false)[0])["1"]).image} />
                   : null}
                      
              </div>

              
            </div>
            <div className={styles.footer}>
              <span className={styles.createdAt}>Publié le {moment(gazette.createdAt.toDate()).format("L")}</span>
              <Button color="primary" size="sm" onClick={() => history.push(`/dashboard/gazette/${gazette.uid}/edit`)}>Editer la gazette</Button>
            </div>
          </div>
        ))}
      </div>
      <VideoTuto url="https://youtu.be/rgM06jOvick" />
    </>
  );
};

export default Gazette;



// import React, {useState} from "react";
// import styles from "assets/scss/pages/Gazette/gazettes.module.scss";
// import { Plus, Trash } from "react-feather";
// import {Button} from "lib/components";
// import { useHistory } from "react-router-dom";
// import { useFirebaseCollection } from "lib/hooks/firebase";
// import useUI from "hooks/ui.hook";
// import firebase from "firebase";
// import moment from "moment";
// import { toast } from "react-toastify";
// import { pdfjs, Document, Page } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const firestore = firebase.firestore;

// const Gazette = () => {
//   const history = useHistory();
//   const [ui] = useUI();
//   const [gazettesRef] = useState(firestore().collection("establishments").doc(ui.user.establishment).collection("gazettes"));
//   const {data} = useFirebaseCollection(gazettesRef, []);

//   const _delete = async (uid) => {
//     try {
//       if (window.confirm("Voulez-vous vraiment supprimer cette gazette ?")) {
//         await gazettesRef.doc(uid).delete();
//       }
//     } catch (e) {
//       console.error(e);
//       toast.error("Une erreur est survenue");
//     }
//   };

//   const filtered = data.sort((a, b)=> b.createdAt.seconds - a.createdAt.seconds);


//   return (
//     <>
//       <div className={styles.container}>
//         <div className={styles.createContainer} onClick={() => history.push("/dashboard/gazette/edit")}>
//           <div></div>
//           <Plus size={120} color="white" />
//           <Button color="secondaryDark">Créer une gazette</Button>
//         </div>
//         {filtered.map((item) => (
//             <div className={styles.gazetteObject} key={item.uid} onClick={() => history.push(`/dashboard/gazette/${item.uid}/edit`)}>
//                <div className={styles.pageShadow}></div>
//                <Document
//               file={item.publication}
//               // onLoadSuccess={onDocumentLoadSuccess}
//                >
//                 <Page pageNumber={1} width={250}/>
//                </Document>     
//               <div className={styles.gazettesOptions}>
//                 <Trash color="red" size={26} style={{cursor: "pointer"}} onClick={() => _delete(item.uid)} />
//                 <Button color="primary" size="sm" onClick={() => history.push(`/dashboard/gazette/${item.uid}/edit`)}>Editer la gazette</Button>
//               </div>
//             </div>       
//           ))}
//       </div>
//     </>
//   );
// };

// export default Gazette;