import React from "react";
import styles from "../assets/scss/component/avatar.module.scss";
import {oneOf, string, bool, func, any} from "prop-types";

const Avatar = (props) => {
  const {
    img,
    size,
    online,
    firstname,
    lastname,
    onClick,
    className,
    style,
  } = props;

  return (
    <span className={`${styles.avatar}
                      ${styles[size]}
                      ${onClick ? styles.clickable : ""}
                      ${(!img && (firstname || lastname)) ? styles.initials : ""}
                      ${className ?? ""}`}
    onClick={onclick}
    style={{...style}}>
      {img && <img src={img} />}
      {(!img && (firstname || lastname)) &&
        <div className={styles.initials}>
          <span>
            {firstname ? firstname[0] : ""}
            {lastname ? lastname[0] : ""}
          </span>
        </div>
      }
      {online && <div className={styles.online}>&nbsp;</div>}
    </span>
  );
};

Avatar.defaultProps = {
  size: "md",
  style: {}
};

Avatar.propTypes = {
  img            : string,
  size           : oneOf(["xs", "sm", "md", "lg", "xl"]),
  online         : bool,
  firstname      : string,
  lastname       : string,
  onClick        : func,
  className      : string,
  style : any
};

export default Avatar;