import React from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import {getFromPath} from "./operations";
import styles from "../../assets/scss/component/stats/pieChart.module.scss";
import roles, {getRoleName} from "enums/roles";

const defaultColors = ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"];

const PieChart = ({data1, path, colors, isRole}) => {

    let _data = getFromPath(data1, path);

    const _list = Object.values(_data);
    let _labels = Object.keys(_data);
    const lastColor = "#999";
    let repeatedColors = _list?.length ? defaultColors.concat(...Array(Math.ceil(_list.length / defaultColors.length) - 1).fill(defaultColors)) : defaultColors;

    if(colors){
      repeatedColors = [...colors];
    }
    if(isRole){
      _labels = _labels.map(id => getRoleName(id));
      repeatedColors = _labels.map(id => roles.find(r => r.label === id).color);
    }

    const options = {
        chart: {
          width: "100%",
          height: "100%",
          type: "pie",
        },
        plotOptions: {
          pie: {
            offsetY: 20,
            customScale: 1.2,
          }
        },
        labels: _labels,
        legend: {
            position: "bottom",
          },
        colors: _labels[_labels.length - 1] === "autres" ? [...repeatedColors.slice(0, _labels.length - 1), lastColor] : repeatedColors,
        defaultColors: _labels[_labels.length - 1] === "autres" ? [...repeatedColors.slice(0, _labels.length - 1), lastColor] : repeatedColors
      };

      // console.log("o", options);

    return (
      <div className={styles.container}>
        {
          _list.length > 0 ? 
          <Chart
            options={options}
            series={_list}
            type="pie"
            width={options.chart.width}
            height={options.chart.height}
          />
          : "Aucunes données disponibles sur cette période."
        }
      
      </div>
    );
};

PieChart.propTypes = {
  data1: PropTypes.object,
  path: PropTypes.string,
  colors: PropTypes.array,
  isRole: PropTypes.bool,
};

export default PieChart;
