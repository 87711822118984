import React, { createContext, useReducer, useContext, useEffect, useState, useCallback } from "react";
import firebase from "firebase";
import PropTypes from "prop-types";
import moment from "moment";
import useUI from "../hooks/ui.hook";
import { toast } from "react-toastify";
import { getEventCategoriesForEtab_rt, createEventCategories, updateEventCategories,removeEventCategories } from "services/ressources/event_categories";
import { createAnimation, getAllAnimationByEtab_rt, removeAnimation, updateAnimationByEtab } from "services/ressources/animations";

const firestore = firebase.firestore;

const AnimationContext = createContext();

const DefaultValue = {
  events: [],
  categories: [],
  loading: true,

  //private
  loadedWeeks: [],
  subscribeWeek: null,
};

const AnimationProvider = ({ children }) => {
  const [ui] = useUI();

  const [state, dispatch] = useReducer((oldState, action) => {
    switch (action.type) {
      case "ADD_LOADED": return { ...oldState, loadedWeeks: [...new Set([...oldState.loadedWeeks, action.week])].filter(i => i) };
      case "SET_EVENTS": return { ...oldState, events: [...action.payload] };
      case "SET_SUBSCRIBE_WEEK": return { ...oldState, subscribeWeek: action.payload };
      case "SET_CATEGORIES": return ({ ...oldState, categories: action.payload });
      case "SET_LOADING": return { ...oldState, loading: action.payload };
      default: return oldState;
    }
  }, DefaultValue);

  //useEffect(() => () => state.subscribeWeek.forEach(sub => sub()), []);
  useEffect(() => {
    loadWeek(new Date());
    //si dimanche, on charge également la semaine suivante
    if (moment().day() === 0) loadWeek(moment().add(1, "week").toDate());

    const subscribe = getEventCategoriesForEtab_rt({ ui }, (data) => {
      dispatch({ type: "SET_CATEGORIES", payload: data });
    });

    return () => subscribe();
  }, [ui.user.establishment]);

  const loadWeek = useCallback(async (date) => {
    const monday = moment(date).startOf("week").toDate();

    if (state.loadedWeeks.includes(moment(monday).format())) return;
    const loadedWeeks = ([...state.loadedWeeks, moment(monday).format()]).map(i => moment(i)).sort((a, b) => a.isBefore(b) ? -1 : 1);

    dispatch({ type: "ADD_LOADED", week: moment(monday).format() });
    if (state.subscribeWeek) state.subscribeWeek();
    
    const subscribe = getAllAnimationByEtab_rt({
      id: ui.user.establishment,
      start: loadedWeeks[0].startOf("week").startOf("day").toDate(),
      end: loadedWeeks[loadedWeeks.length - 1].endOf("week").endOf("day").toDate()
    }, (d) => dispatch({ type: "SET_EVENTS", payload: d }));
    
    dispatch({ type: "SET_SUBSCRIBE_WEEK", payload: subscribe });
  }, [state.loadedWeeks, ui.user.establishment, state.subscribeWeek]);

  const create = useCallback(async (event) => {
    try {
      let start = new Date(event.date.getTime());
      let end = new Date(event.date.getTime());

      start.setHours(event.startTime.value.split("h")[0]);
      start.setMinutes(event.startTime.value.split("h")[1]);
      start.setSeconds(0);
      end.setHours(event.endTime.value.split("h")[0]);
      end.setMinutes(event.endTime.value.split("h")[1]);
      end.setSeconds(0);
      await createAnimation({ui,data:{
        categoryId: event.categoryId,
        title: event.title,
        place: event.place,
        description: event.description,
        eventType: event.type,
        file: event.file,
        image: event.image
          ? typeof event.image === "string"
            ? event.image
            : event.image.urls.small
          : null,
        maxParticipants: event.maxParticipants ? event.maxParticipants : 0,
        start,
        end,
        disableSubscribe: event.disableSubscribe,
        openSubscribe: event.openSubscribe,
        closeSubscribe: event.closeSubscribe,
        isPublished: event.isPublished,
      }});
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  }, [ui.user.establishment, state.events]);

  const update = useCallback(async (id, event) => {
    try {
      // Get the start and end date from the event
      let start = new Date(event.date.getTime());
      let end = new Date(event.date.getTime());

      // If we have a start time, update the start date
      if (event.startTime && event.startTime.value) {
        start.setHours(event.startTime.value.split("h")[0]);
        start.setMinutes(event.startTime.value.split("h")[1]);
        start.setSeconds(0);
      }

      // If we have an end time, update the end date
      if (event.endTime && event.endTime.value) {
        end.setHours(event.endTime.value.split("h")[0]);
        end.setMinutes(event.endTime.value.split("h")[1]);
        end.setSeconds(0);
      }

      // Save the event
      updateAnimationByEtab({etabId: ui.user.establishment, animId: id, data: {
          categoryId: event.categoryId,
          title: event.title,
          place: event.place,
          description: event.description,
          eventType: event.type,
          file: event.file ?? null,
          image: event.image ? (typeof(event.image) === "string" ? event.image : event.image.urls.small) : null,
          maxParticipants: event.maxParticipants ? event.maxParticipants : 0,
          start,
          end,
          disableSubscribe: event.disableSubscribe,
          openSubscribe: event.openSubscribe,
          closeSubscribe: event.closeSubscribe,
          isPublished: event.isPublished,
      }});
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  }, [ui.user.establishment, state.events]);

  const remove = useCallback(async (id) => {
    try {
      await removeAnimation({ui,id});
      const events = state.events.filter(event => event.uid !== id);
      dispatch({ type: "SET_EVENTS", payload: events });
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  }, [ui.user.establishment, state.events]);

  const createCategory = useCallback(async (category) => {
    await createEventCategories({
      ui, data: {
        title: category?.title ?? null,
        color: category?.color ?? null,
        smiley: category?.smiley ?? null
      }
    });
    return true;
  }, [ui.user.establishment]);

  const updateCategory = useCallback(async (id, category) => {
    await updateEventCategories({
      ui, id, data: {
        title: category?.title ?? null,
        color: category?.color ?? null,
        smiley: category?.smiley ?? null
      }
    });
    return true;
  }, [ui.user.establishment]);

  const removeCategory = useCallback(async (id) => {
    try {
      //delete events
      let eventsToDelete = [];
      let resEvents = await firestore().collection("establishments").doc(ui.user.establishment)
        .collection("blocks").doc("planning")
        .collection("events").where("categoryId", "==", id).get();
      resEvents.forEach((doc) => eventsToDelete.push(doc.id));

      const batch = firestore().batch();

      await Promise.all(eventsToDelete.map(async (id) => {
        // await remove(id);
        const docRef = firestore().collection("establishments").doc(ui.user.establishment).collection("blocks").doc("planning").collection("events").doc(id);
        batch.update(docRef, { categoryId: null }); // merge : true permet de remplacer uniquement les champs qu'on envoit.
      }));

      await batch.commit();

      removeEventCategories({ui, id});
      return (true);
    } catch (e) {
      console.error(e);
      toast.error(e?.message);
      return false;
    }
  }, [ui.user.establishment, state.events]);

  return (
    <AnimationContext.Provider value={{
      state,
      loadWeek,
      create,
      update,
      remove,
      createCategory,
      updateCategory,
      removeCategory
      //dispatch // outside modification method is forbidden
    }}>
      {children}
    </AnimationContext.Provider>
  );
};
AnimationProvider.propTypes = {
  children: PropTypes.node
};

const useAnimationEvents = (props) => {
  const { filter, order, format, find } = props ?? {};
  const { state, ...rest } = useContext(AnimationContext);
  const { events } = state;

  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let _result = [...events];

    if (!find) {
      if (filter) _result = _result.filter(filter);
      if (order) _result = _result.sort(order);
    }
    if (find) _result = _result.find(find);
    if (format) _result = _result.map(format);

    setResult(_result);
    setLoading(false);
  }, [format, filter, order, find, events]);

  return { ...rest, events: result, loading };
};

const useAnimationCategories = (props) => {
  const { filter, order, format, find } = props ?? {};
  const { state, ...rest } = useContext(AnimationContext);
  const { categories } = state;

  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let _result = [...categories];

    if (!find) {
      if (filter) _result = _result.filter(filter);
      if (order) _result = _result.sort(order);
    }
    if (find) _result = _result.find(find);
    if (format) _result = _result.map(format);

    setResult(_result);
    setLoading(false);
  }, [format, filter, order, find, categories]);

  return { ...rest, categories: result, loading };
};

export { AnimationProvider, useAnimationEvents, useAnimationCategories };
export default useAnimationEvents;