import React, {useState, useMemo} from "react";
import useUI from "hooks/ui.hook";
import styles from "assets/scss/components/homeWidgets/monthBirthday.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import firebase from "firebase";
import {useFirebaseCollection} from "lib/hooks/firebase";

const firestore = firebase.firestore;

const MonthBirthday = () => {

  const [ui] = useUI();
  const [usersRef] = useState(firestore().collection("users").where("establishment", "==", ui.user.establishment));
  const {data: users} = useFirebaseCollection(usersRef);

  const monthUsersBirthday = useMemo(() => {
    return users.filter(user => user.birthdate && moment(user.birthdate.toDate()).set("year", moment().year()).diff(moment(), "days") >= 1 && moment(user.birthdate.toDate()).set("year", moment().year()).diff(moment(), "days") <= 30)
    .sort((a, b) => {
      const dateA = moment(a.birthdate.toDate()).format("MMDD");
      const dateB = moment(b.birthdate.toDate()).format("MMDD");
      return dateA.localeCompare(dateB);
    });
  }, [users]);

  console.log("af",monthUsersBirthday);
  const reducedMonthUsersBirthday = monthUsersBirthday.reduce((acc, user) => {
    const date = moment(user.birthdate.toDate()).format("DD/MM");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(user);
    return acc;
  }, {});


  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h5 style={{fontWeight:600, fontSize:18, textAlign: "center"}}>Les anniversaires des 30 prochains jours</h5>
      </div>
      <div className={styles.body}>
        <FontAwesomeIcon className={styles.img} icon={faCalendar} size={"6x"} color={"#6fcf97"}/>
        <h5>{monthUsersBirthday.length} anniversaires dans les 30 prochains jours</h5>
        <div className={styles.birthdaysContainer}>
              {Object.keys(reducedMonthUsersBirthday).map((date, idx) => 
                <div key={`date-${idx}`} className={styles.birthday}>
                  <span className={styles.date}>{date}</span>

                  {reducedMonthUsersBirthday[date].map((user, indexUser) =>
                    (
                      <>
                        <span key={user.uid} className={styles.user}>{`${user.surname} ${user.name}`}</span>
                        {indexUser < reducedMonthUsersBirthday[date].length - 1 && <span> - </span>}
                      </>
                    )
                  )}
                  
                </div>
              )}
          </div>
      </div>
    </div>
  );
};

export default MonthBirthday;