import React,{useState,useEffect,useMemo} from "react";
import PropTypes from "prop-types";
import {Modal, ModalHeader, ModalBody, ModalFooter,Button, Select, FormGroup,Input} from "lib/components";
import { useServiceManagement } from "../../routerContext";
import "react-tippy/dist/tippy.css";
import { toast } from "react-toastify";


const CreatePrestationModal = (props) =>{
    const {
        isOpen,
        toggle,
        currentPrestation
    } = props;

    const [ctx,{addPrestation,updatePrestation,removePrestation}] = useServiceManagement();
    const [title,setTitle] = useState("");
    const [description,setDescription] = useState("");
    const [modificationMode,setModificationMode] = useState(false);
    const [price,setPrice] = useState("0");
    const [duration,setDuration] = useState("");
    const [error, setError] = useState({});

    useEffect(() => {
        if (isOpen) {
          resetForm();
          setModificationMode(false);
          if(currentPrestation.prestaId){ 
            setTitle(currentPrestation.title);
            setDescription(currentPrestation.description);
            setPrice(currentPrestation.price);
            setDuration(currentPrestation.duration);
            setModificationMode(true);
          }
         
        }
      }, [isOpen,currentPrestation]);


    const resetForm = () => {
        setTitle("");
        setDescription("");
        setPrice("0");
        setDuration("");
        setModificationMode(false);
        setError({});
    };
    const _delete=()=>{
        try{
            removePrestation(currentPrestation.prestaId);
            resetForm();
            setModificationMode(false);
            toggle();

        }catch(e){
            toast.error("la suppression n'a pas abouti");
            console.error("Erreur lors de la suppression de la prestation:", error);
            throw error;
        }
    };
    const _submit= () =>{
        try{
            let _error = {};
            if (!title) _error.title = "Ce champ est obligatoire";
            if (!duration) _error.duration ="Ce champ est obligatoire";
            setError(_error);
            if (Object.keys(_error).length > 0) {
                toast.warning("Prestation incomplète. N'a pas abouti");
                return;
            }
            if(modificationMode){
                updatePrestation(currentPrestation.prestaId,title,description,duration,price);
                toast.success("La prestation à été modifiée");
            }else{
                addPrestation(title, description, duration, price);
                toast.success("La prestation à été ajoutée");

            }
            setModificationMode(false);
            toggle();

        }catch(e){
            toast.error("la creation n'a pas abouti");
            console.error("Erreur lors de la récupération du prestataire:", error);
            throw error;
        }

    
    };

    const generateQuarterHourOptions = useMemo(() => {
        const options = [];
        const totalMinutes = 3 * 60;
        for (let minutes = 15; minutes <= totalMinutes; minutes += 15) {
          const hours = Math.floor(minutes / 60);
          const mins = minutes % 60;
          const formattedTime = `${String(hours).padStart(2, "0")}h${String(mins).padStart(2, "0")}`;
          options.push({ label: formattedTime, value: formattedTime });
        }
    
        return options;
      },[]);


    return(
        <Modal isOpen={isOpen} size="lg" toggle={toggle}>
            <ModalHeader>Ajout d&apos;une nouvelle prestation</ModalHeader>
            <ModalBody>
            <FormGroup>
                <Input invalid={error.title} label="Titre de la prestation" value={title} onChange={e => setTitle(e.target.value)}/>
            </FormGroup>
            <FormGroup>
                <Input invalid={error.description} label="Description" value={description} onChange={e => setDescription(e.target.value)}/>
            </FormGroup>
            <FormGroup>
                <Input  invalid={error.price} label="Prix" value={price} onChange={e => setPrice(e.target.value)}/>
            </FormGroup>
            <FormGroup>
               <Select
                label="Durée"
                invalid={error.duration}
                placeholder="Sélectionnez la durée de la prestation..."
                options={generateQuarterHourOptions}
                clearable
                value={{label:duration,value:duration}}
                onChange={(e) => setDuration(e ? e.label : null)}
               >
               </Select>
            </FormGroup>
            </ModalBody>
            <ModalFooter style={{ justifyContent: "center", gap: "5px" }}>
                <Button  color='secondary' onClick={toggle}>Annuler</Button>
                {currentPrestation.prestaId?<Button color='danger' onClick={_delete}> Supprimer </Button> :null}
                <Button  color='primary' onClick={_submit}>Valider</Button>
            </ModalFooter>


        </Modal>
    );

};

CreatePrestationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    currentPrestation: PropTypes.object,
  };
  
  export default CreatePrestationModal;