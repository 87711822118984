import React, {useState} from "react";
import {Button} from "lib/components";
import firebase from "firebase";

const firestore = firebase.firestore;

const UserAuthXFirestore = () => {
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);

  const test = async () => {
    setReport([]);
    setLoading(true);

    //test User auth -> Firestore liaison
    for (let i = 0; i < users.length; i++) {
      let user = users[i];
      try {
        console.log(user);
        let f_user = await firestore().collection("users").doc(user.uid).get();
        if (!f_user.exists) setReport(prev => [...prev, `<span style="color: red;">user ${user.mail}: not found in firestore</span>`]);
        else setReport(prev => [...prev, `<span style="color: green;">user ${user.mail}: OK</span>`]);
      } catch (e) {
        setReport(prev => [...prev, `<span style="color: red;">user ${user.mail}: not found in firestore</span>`]);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <p style={{color: "red"}}>Sans modification dans le code, ce test ne fonctionnera pas pour des raisons de sécurité, CF les commentaires dans UserAuthxFirestore.js</p>
      <Button onClick={test} loading={loading}>launch test</Button>
      {
        report.map((r, i) => (
          <p key={i} dangerouslySetInnerHTML={{__html: r}} />
        ))
      }
    </>
  );
};

/**
 * Consignes:
 * 
 * - Scraper les utilisateurs dans le dashboard firebase (utiliser par ex: web scraper chrome extension)
 * - exporter en csv
 * - veiller a ce que les colonnes soient bien nommées "mail" et "uid"
 * - transformer en json
 * - coller le contenu dans la constante users ci-dessous
 * 
 * ATTENTION: veillez a bien supprimer une fois le test terminé,
 *            sinon la base de donnée user se retrouvera dans le front (= faille de sécurité)
 */


const users = [

];

export default UserAuthXFirestore;
