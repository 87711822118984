import React, {useState, useEffect} from "react";
import Tab from "lib/components/Tab";

import Healthcheck from "./HealthCheck";
import Notifications from "./Notifications";
import Menus from "./Menus/importExport";
import Express from "./express";
import Tools from "./Tools";

const Sandbox = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div style={{width: "100%"}}>
      <Tab 
        activeTab={activeTab}
        onClick={setActiveTab}
        render={[
          {
            title: "Healthcheck",
            content: <Healthcheck />
          }, {
            title: "Notifications",
            content: <Notifications />
          }, {
            title: "Menus",
            content: <Menus />
          }, {
            title: "Express",
            content: <Express />
          }, {
            title: "Tools",
            content: <Tools />
          }
        ]}
      />
    </div>
  );
};

export default Sandbox;