import PropTypes from "prop-types";
import {
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import {Input} from "lib/components";
import { FormGroup } from "reactstrap";
import Person from "../assets/Person.png";
import {DatePicker} from "lib/components";
import Shipping from "../assets/Shipping.png";
import Cake from "../assets/Cake.png";
import Cocktail from "../assets/Cocktail.png";
import moment from "moment";
import "../../../fonts";

const AgendaComponent = (props) => {
  const {welcomeDate, birthdayParty, newResidents} = props;

  return (
    <div style={{overflow: "hidden"}}>
      <p style={{fontWeight:"bold"}}>Evénements :</p>
      <p style={{fontSize: 12}}>Apéritif de bienvenue : {moment(welcomeDate).format("DD MMM")}</p>
      <p style={{fontSize: 12}}>Anniversaires : {moment(birthdayParty).format("DD MMM")}</p>
      <p style={{fontSize: 12}}>Nouveaux résidents : {newResidents.split("\n").length}</p>
    </div>
  );
};

const AgendaEditor = (props) => {
  const {
    onChange,
    field
  } = props;

  field.welcomeDate = field.welcomeDate ?? new Date();
  field.birthdayParty = field.birthdayParty ?? new Date();
  field.newResidents = field.newResidents ?? "";
  return (
    <>
      <h5>Ajouter les dates importantes à venir</h5>
      
      <p style={{marginBottom: 10}}>Vous pouvez indiquer des dates marquant des événements au sein de la résidence.</p>
      
      <FormGroup>
        <div style={{display: "flex", gap: 20}}>
          <DatePicker value={field.welcomeDate} onChange={e => onChange("welcomeDate", e)} placeholder="Date de l'événement" label="Apéritif de bienvenue" />
          <DatePicker value={field.birthdayParty} onChange={e => onChange("birthdayParty", e)} placeholder="Date de l'événement" label="Goûter d'anniversaire" />
        </div>
        <Input type="textarea" cols={100} rows={5} value={field.newResidents} onChange={e => onChange("newResidents", e.target.value)} placeholder="Prénom Nom, le XX MOIS" label="Futures entrées (1 par ligne)" /> 
      </FormGroup>
    </>
  );
};

const AgendaPdf = (props) => {
  const {
    welcomeDate,
    birthdayParty,
    newResidents,
  } = props; 

  const pdfStyle = StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      // backgroundColor: "#e7e6e6",
      width: "90%",
      height: "100%",
    },
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#1c2140",
      padding: 5,
      whidth: "20%",
    },
    title: {
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: 16,
      color: "white",
      fontFamily: "Brandon",
    },
    sectionContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      // alignItems: "center",
      marginTop: "5px",
    },
    sectionImage: {
      width: 50,
      height: 50,
      marginRight: "10px",
    },
    sectionText: {
      color: "#1c2140",
      fontSize: 14,
      wordWrap: "break-word",
      fontFamily: "Brandon",
    },
    sectionTextDetails: {
      color: "#1c2140",
      fontSize: 12,
      marginLeft: 15,
      fontFamily: "Brandon"
    },
  });

  return (
    <View style={pdfStyle.container}>
      <View style={pdfStyle.titleContainer}>
        <Text style={pdfStyle.title}>A VOS AGENDAS</Text>
      </View>
      <View>
        {welcomeDate && (
          <View style={pdfStyle.sectionContainer}>
            <Image style={pdfStyle.sectionImage} src={Cocktail} />
            <Text style={pdfStyle.sectionText}>Apéritif de bienvenue: {moment(welcomeDate).format("DD/MM")}</Text>
          </View>
        )}
        {birthdayParty && (
          <View style={pdfStyle.sectionContainer}>
            <Image style={pdfStyle.sectionImage} src={Cake} />
            <Text style={pdfStyle.sectionText}>Goûter des anniversaires: {moment(birthdayParty).format("DD/MM")}</Text>
          </View>
        )}
        {newResidents && (
          <View style={pdfStyle.sectionContainer}>
            <Image style={pdfStyle.sectionImage} src={Shipping} />
            <View style={pdfStyle.sectionTextContainer}>
              <Text style={pdfStyle.sectionText}>Futures entrées:</Text>              
                {newResidents?.split("\n")
                  .map((r, index) => 
                    <Text key={`resident+${index}`} style={pdfStyle.sectionTextDetails}>- {r}</Text>
                  )
                }
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

AgendaComponent.propTypes = {
  message: PropTypes.any,
};
AgendaEditor.propTypes = {
  onChange: PropTypes.any,
  field: PropTypes.any,
};
AgendaPdf.propTypes = {
  message: PropTypes.any,
};

export { AgendaEditor, AgendaComponent, AgendaPdf };