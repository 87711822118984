import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import useUI from "hooks/ui.hook";
import firebase from "firebase";

import { getEstablishmentById } from "services/ressources/establishment";
import { getUserById } from "services/ressources/user";

const firestore = firebase.firestore;


const Redirect = () => {
  const [ui, dispatch] = useUI();
  const { id } = useParams();

  const _takeControl = async () => {
    try {
      let _est = await getEstablishmentById({id: id});
      let _user = {...ui.user}; 

      let _owner = _est.owner;
      if(_owner){
        _owner = await getUserById({id: _owner});
        _est.owner = _owner;
      }

      let groupement = null;
      // ajout du groupement
      if(_est.uid){
        let groupementDoc = await firestore().collection("groupements").where("establishments", "array-contains", _est.uid).get();
        if(groupementDoc.docs.length > 0){
          groupement = {uid: groupementDoc.docs[0].id, ...groupementDoc.docs[0].data()};

          if(groupementDoc.docs.length > 1)console.warn("this etab is in more than 1 groupement");
        }
      }

      _user.role = "owner";
      _user.establishment = id;
      _user.superadmin = true;
      dispatch({type: "takeControl", user: _user, establishment: _est, groupement: groupement});
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(()=> {
    _takeControl();
  },[]);

  return <div></div>;
};

export default Redirect;