import PropTypes from "prop-types";
import {
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import styles from "../styles/recipe.module.scss";
import useGazette from "../../../context";
import useUI from "hooks/ui.hook";
import { Edit3, Plus, Trash } from "react-feather";
import contentTypes from "../contentTypesHeurus";
import Plate from "../assets/Plate.png";

const RecipeMiniature = (props) => {
  const { onClick } = props;

  return (
    <div onClick={onClick} className={styles.miniature}>
      Page de type &quot;Recette&quot;
    </div>
  );
};

const Recipe = (props) => {
  const {
    page,
    onEditPage,
    onRemove,
    onAddContent,
    onRemoveContent,
    onEditContent,
  } = props;

  if(page.title === "") {
    page.title = "Recette";
    
    const defaultContents = {
      // "1" : {
      //   contentType: "recipe",
      //   format: "recipe",
      //   title: "Titre de la recette",
      //   portion:"2",
      //   preparationTime: "3",
      //   coockingTime: "5",
      //   ingredients:"Ingrédient 1\nIndrédient 2\nIndgrédient 3",
      //   steps:"Etape 1\nEtape 2\nEtape 3",
      //   author: "Jean Jacques"
      // }
    };
    Object.keys(defaultContents).forEach(field => {
      page[field] = JSON.stringify(defaultContents[field]);
    });
  }
  
  return (
    <>
    <div className={styles.head}></div>
      <div className={styles.page}>
          <div className={styles.content}>
            <div className={styles.pageTitleContainer}>
              <p className={styles.pageTitle}>A VOS FOURNEAUX !</p>
            </div>
            {page["1"] &&
              contentTypes.find(
                (type) => type.slug === JSON.parse(page["1"]).contentType
              ) ? (
                <div className={`${styles.editoField}`}>
                  <Trash
                    color="red"
                    size={40}
                    className={styles.trash}
                    onClick={() => onRemoveContent("1")}
                  />
                  <Edit3
                    color="blue"
                    size={40}
                    className={styles.edit}
                    onClick={() => onEditContent({ format: "recipe", key: "1" })}
                  />
                  {contentTypes
                    .find((type) => type.slug === JSON.parse(page["1"]).contentType)
                    .component({ ...JSON.parse(page["1"]) })}
                </div>
              ) : (
                <div
                  className={`${styles.editoField} ${styles.add}`}
                  onClick={() => onAddContent({ format: "recipe", key: "1" })}
                >
                  <Plus size={120} />
                </div>
              )}
        </div>
        <Trash
          color="red"
          size={40}
          className={styles.bottomTrashIcon}
          onClick={onRemove}
        />
      </div>
    </>
  );
};

const RecipePdf = (props) => {
  const { page, ctx, index, contentTypes } = props;
  const pdfStyles = StyleSheet.create({
    container: {
      width: "100%",
      height: "100%",
      padding: "10px"
    },
    plate: {
      position: "absolute",
      top: "5px",
      left: "10px",
      width: "60px",
      height: "60px",
      padding: "5px",
      backgroundColor: "white",
    },
    pageCountContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      backgroundColor: "white",
      borderRadius: 25,
      width: 30,
      height: 30,
      bottom: 5,
      right: 5,
    },
    pageCountText: {
      color: "black",
      fontSize: 24,
      fontWeight: 600,
    },
  });
  return (
    <Page size="A4" orientation="portrait">
      <View style={pdfStyles.container}>
        <View style={pdfStyles.pageDecorator}></View>
        {page["1"] &&
          contentTypes.find(
            (type) => type.slug === JSON.parse(page["1"]).contentType
          )
            ? contentTypes
              .find(
                (type) => type.slug === JSON.parse(page["1"]).contentType
              )
              .pdf({settings: ctx.settings, ...JSON.parse(page["1"]) })
            : null}
        <Image src={Plate} style={pdfStyles.plate}/>
      </View>
      <View style={pdfStyles.pageCountContainer}>
        <Text style={pdfStyles.pageCountText}>{index + 1}</Text>
      </View>
    </Page>
  );
};

Recipe.propTypes = {
  page : PropTypes.any,
  onEditPage : PropTypes.any,
  onRemove : PropTypes.any,
  onAddContent : PropTypes.any,
  onRemoveContent : PropTypes.any,
  onEditContent : PropTypes.any,
};
RecipeMiniature.propTypes = {
  onClick : PropTypes.any,
};
RecipePdf.propTypes = {
  page : PropTypes.any,
  ctx : PropTypes.any,
  index : PropTypes.any,
  contentTypes: PropTypes.any,
};

export { Recipe, RecipeMiniature, RecipePdf };