import React, { useState } from "react";
import useCtx, { Provider } from "./ctx";
import { Tab, Button, Modal, ModalHeader, ModalFooter, ModalBody, Select } from "lib/components";
import { useHistory, useParams } from "react-router-dom";

import Repas from "./Repas";
import RepasSingle from "./RepasSingle";


const Menu = () => {
  const history = useHistory();
  const { id } = useParams();
  const [ctx] = useCtx();

  const applyToAll = (e) => {

    if (confirm("Cette action va modifier le template de tous les établissements du groupement.")) {
      ctx.pushToAllEtab();
    }
  };


  return (
    <div style={{ width: "100%" }}>
      <Tab activeTab={3} onClick={console.log} render={[
        { title: "Informations", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/infos`) },
        { title: "Etablissements", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/establishments`) },
        // {title: "Abonnements", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/abonnements`)},
        { title: "Statistiques", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/statistics`) },
        {
          title: "Menu", overrideOnClick: () => history.push(`/dashboard/groupements/${id}/menu`), content:
            <div>
              {ctx.step === 0 ? <Repas />
                : ctx.step === 1 ? <RepasSingle />
                  : null}
            </div>
        },

        { title: "Abonnement", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/subscription`) },
      ]} />
      <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "50%", marginLeft: "25%" }}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <Button onClick={ctx.save}>Enregistrer</Button>
        <Button onClick={applyToAll}>Appliquer à tous les établissements</Button>
      </div>
    </div>
  );
};

const MenuHoc = () => {
  const { id } = useParams();

  return (
    <Provider>
      <Menu />
    </Provider>
  );
};
export default MenuHoc;