import React, { useEffect, useState } from "react";
import {
    Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Table, Card, CardHeader, CardBody, Input, FormGroup
} from "lib/components";
import { Edit, PlusCircle, List, BarChart } from "react-feather";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { getPatchnoteById, updatePatchnoteById } from "services/ressources/patchnotes";
import { Provider } from "../context";

import { BlocView } from "../components/bloc";

const PatchnoteView = () => {
    const history = useHistory();
    const param = useParams();
    const [patchnote, setPatchnote] = useState({});

    useEffect(() => {
        if (param.uid) {
            const callAsync =  async () => {
                const _patchnote = await getPatchnoteById({id: param.uid});
                setPatchnote(_patchnote);
            };

            callAsync();
        }
    }, [param]);

    return (
        <Provider>

        <div style={{ flex: 1 }}>
            <div style={{display: "flex", gap: 20}}>
                <Button onClick={() => history.goBack()} >Retour</Button>
            </div>

            {patchnote ?
                <div>
                    {patchnote?.content ?  
                        <BlocView blockData={patchnote.content} parentRestrictions={[]} childIndex={1}/>
                    : null}
                    
                </div>
                : null}




        </div>
        </Provider>
    );
};

export default PatchnoteView;
