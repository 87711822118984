import {
    Font
  } from "@react-pdf/renderer";

import Roboto from "../../../fonts/roboto/Roboto-Condensed.ttf";
import RobotoBold from "../../../fonts/roboto/Roboto-BoldCondensed.ttf";
import Calibri from "../../../fonts/calibri/calibri-regular.ttf";
import CalibriBold from "../../../fonts/calibri/calibri-bold.ttf";
import Brandon from "../../../fonts/brandon/BrandonGrotesque-Regular.ttf";
import BrandonBold from "../../../fonts/brandon/BrandonGrotesque-Bold.ttf";

Font.register({
  family: "Calibri",
  fontWeight: "normal",
  src: Calibri,
});
Font.register({
  family: "Calibri",
  format: "bold",
  src: CalibriBold,
});
Font.register({
  family: "Roboto",
  fontWeight: "normal",
  src: Roboto,
});
Font.register({
  family: "Roboto",
  format: "bold",
  src: RobotoBold,
});
Font.register({
  family: "Brandon",
  format: "normal",
  src: Brandon,
});
Font.register({
  family: "Brandon",
  format: "bold",
  src: BrandonBold,
});
