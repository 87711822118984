import React from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import {Form} from "../components";

const PolymerForm = (props) => {
  const {children} = props;

  return (
    <Formik {...props}>
      {({
        handleSubmit, errors
      }) => (
        <Form onSubmit={handleSubmit}>
          {children}
        </Form>
      )}
    </Formik>
  );
};

PolymerForm.propTypes = {
  children  : PropTypes.any,
};

export default PolymerForm;