import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";

import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import firebase from "firebase";
import { toast } from "react-toastify";
import useUI from "hooks/ui.hook";
import useMicroService from "helpers/microService";
import moment from "moment";

const firestore = firebase.firestore();
const FieldValue = firebase.firestore.FieldValue;
const functions = firebase.functions;
const auth = firebase.auth();

const Ctx = createContext();

const Reducer = (state, action) => {
  switch (action.type) {
    case "setIsLoading":
      return { ...state, isLoading: action.isLoading };
    case "setUser":
      return { ...state, user: action.user };
    case "setGroupement":
      return { ...state, groupement: action.groupement };
    case "addBook":
      return { ...state, book: [action.book, ...state.book] };
    case "removeBook":
      return { ...state, book: state.book.filter((i) => i.uid !== action.uid) };
    case "updateBook":
      return {
        ...state,
        book: state.book.map((i) =>
          i.uid === action.uid ? { ...i, content: action.content } : i
        ),
      };
    case "setBook":
      return { ...state, book: action.book };
    case "setCtx":
      return { ...state, ...action.ctx };
    default:
      return { ...state };
  }
};

const Default = {
  isLoading: true,
  error: {},
  state: null,

  //fonctionnement
  establishment: null, //current establishment of user
  groupement: null, //current groupement of user establishment
};

const Provider = ({ children, id }) => {
  const [ui] = useUI();
  const [state, dispatch] = useReducer(Reducer, Default);
  const execMicroService = useMicroService();

  useEffect(() => {
    if(id){
      (async () => {
        try {
          const user = await firestore.collection("users").doc(id).get();
          const establishment = await firestore
            .collection("establishments")
            .doc(user.data().establishment)
            .get();
          let groupement = null;
          if (establishment.data().groupement) {
            groupement = await firestore
              .collection("groupements")
              .doc(establishment.data().groupement)
              .get();
          }
          const data = user.data();
  
          dispatch({
            type: "setCtx",
            ctx: {
              user: {
                uid: user.id,
                ...data,
                createdAt: data.createdAt ? data.createdAt.toDate() : null,
                lastLogin: data.lastLogin ? data.lastLogin.toDate() : null,
                birthdate: data.birthdate ? data.birthdate.toDate() : null,
              },
              establishment: { uid: establishment.id, ...establishment.data() },
              groupement: !groupement || groupement.empty
                ? null
                : { uid: groupement.id, ...groupement.data() },
              isLoading: false,
              error: {},
            },
          });
        } catch (error) {
          console.error("error", error);
        }
      })();
    }
    
  }, [id]);

  useEffect(() => {
    firestore
      .collection("users")
      .doc(id)
      .collection("book")
      .onSnapshot((book) => {
        dispatch({
          type: "setBook",
          book: book.docs
            .map((doc) => ({ uid: doc.id, ...doc.data() }))
            .map((i) => ({ ...i, createdAt: i.createdAt.toDate() })),
        });

      });
  }, []);

  useEffect(() => {
    if(state?.user?.birthdate && (!state?.user?.birthday || state?.user?.birthday !== moment(state.user.birthdate).format("MM-DD"))) {
      dispatch({type: "setUser", user: {...state.user, birthday: moment(state.user.birthdate).format("MM-DD")}});
    }
  }, [state.user]);

  const _public = {
    state,
    dispatch,
    save: async () => {
      try {
        const { role, ...user } = state.user;
        await firestore.collection("users").doc(id).update(user);
        toast.success("Utilisateur mis à jour");
      } catch (error) {
        console.log("error", error);
      }
    },

    addBook: async (content) => {
      const _book = {
        content,
        createdAt: new Date(),
        createdByUid: ui.user.uid,
        createdByName: `${ui.user.surname} ${ui.user.name}`,
      };
      await firestore.collection("users").doc(id).collection("book").add(_book);
      // dispatch({ type: "addBook", book: _book });
    },

    removeBook: async (uid) => {
      await firestore
        .collection("users")
        .doc(id)
        .collection("book")
        .doc(uid)
        .delete();
      // dispatch({ type: "removeBook", uid: uid });
      toast.success("Message supprimé");
    },

    updateBook: async (uid, content) => {
      await firestore
        .collection("users")
        .doc(id)
        .collection("book")
        .doc(uid)
        .update({ content });
      // dispatch({ type: "updateBook", content, uid });
      toast.success("Message mis à jour");
    },

    softDeleteUser: async (uid) => {
      const batch = firestore.batch();


      // Supprimer toutes les réservations de cet utilisateur :
      let animations = await firestore
        .collection("establishments")
        .doc(ui.user.establishment)
        .collection("blocks")
        .doc("planning")
        .collection("events")
        .get();
      let menus = await firestore
        .collection("establishments")
        .doc(ui.user.establishment)
        .collection("blocks")
        .doc("menu")
        .collection("menu")
        .get();

      animations.forEach((doc) => {
        let event = {uid: doc.id, ...doc.data()};

        if (event.reservation && event.reservation.includes(uid) && moment(event.start.toDate()).isAfter(moment().endOf("week"))) {
          batch.update(doc.ref, {
            reservation: FieldValue.arrayRemove(uid),
          });
        }
      });

      menus.forEach((doc) => {
        let menu = doc.data();
        
        if (menu.reservation && menu.reservation[uid] && moment(menu.uid).isAfter(moment().endOf("week"))) {
          batch.update(doc.ref, {
            ["reservation." + uid]: FieldValue.delete(),
          });
        }
      });

      await batch.commit();

      await firestore.collection("users").doc(uid).update({ isDeleted: true, deletedAt: new Date(), subscription: null });
      toast.success("L'utilisteur a bien été supprimé");
      return true;
    },

    deletUser: async (uid) => {
      const batch = firestore.batch();

      // Supprimer toutes les réservations de cet utilisateur :
      let animations = await firestore
        .collection("establishments")
        .doc(ui.user.establishment)
        .collection("blocks")
        .doc("planning")
        .collection("events")
        .get();
      let menus = await firestore
        .collection("establishments")
        .doc(ui.user.establishment)
        .collection("blocks")
        .doc("menu")
        .collection("menu")
        .get();

      animations.forEach((doc) => {
        let event = doc.data();

        if (event.reservation && event.reservation.includes(uid)) {
          batch.update(doc.ref, {
            reservation: FieldValue.arrayRemove(uid),
          });
        }
      });

      menus.forEach((doc) => {
        let menu = doc.data();

        if (menu.reservation && menu.reservation[uid]) {
          batch.update(doc.ref, {
            ["reservation." + uid]: FieldValue.delete(),
          });
        }
      });

      await batch.commit();

      await execMicroService("deleteUser", { uid: uid });

      toast.success("L'utilisteur a bien été complétement supprimé");

      return true;
    },
  };

  return <Ctx.Provider value={_public}>{children}</Ctx.Provider>;
};

Provider.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
};

const useCtx = () => {
  const ctx = useContext(Ctx);
  if (ctx === undefined) {
    throw new Error("useCtx must be used within a Provider");
  }
  return ctx;
};

export { Ctx, Provider, useCtx };
export default useCtx;
