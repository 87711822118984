import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import styles from "../../../../assets/scss/pages/news.module.scss";
import { Button } from "../../../../lib/components";

const NewsCard = (props) => {
  const {
    item,
    onClick
  } = props;

  return (
    <div className={styles.newsCard}>
      <div className={styles.cardHeader}>
        <span className={styles.title}>{item.title}</span>
        {item.type ? 
              <div className={styles.tag} style={{backgroundColor: item.type === "national" ? "#0B8ED4" : "#0BD4AD"}}>
              {item.type === "national" ? "Actualité Nationale" : "Actualité Régionale"}</div>
        : null}
      </div>
      {item.image ? 
        <img className={styles.image} src={item.image} />
        : <></>}
      <span className={styles.description}>{item.description}</span>
      <div className={styles.controlsContainer}>
        <span className={styles.date}>{moment(item.date.toDate()).format("L")}</span>
        <Button onClick={() => onClick(item)}>Editer</Button>
      </div>
    </div>
  );
};

NewsCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

export default NewsCard;