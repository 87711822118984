import React from "react";
import PropTypes from "prop-types";
import styles from "../assets/scss/pages/loader.module.scss";

const LoaderPage = ({show}) => (
  <div className={`${styles.loaderWrapper} ${show ? "" : styles.hide}`} >
    <div className={styles.loader}>
      <div className={`${styles.spinner}`}> </div>
    </div>
  </div>
);

LoaderPage.propTypes = {
  show: PropTypes.bool,
};


export default LoaderPage;