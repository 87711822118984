import React from "react";
import useUI from "hooks/ui.hook";

import Basic_WeekPdf from "./Basic/Components/_weekPdf";
import Basic_DetailCell from "./Basic/Components/DetailCell";
import Basic_ModalReservation from "./Basic/Components/ModalReservation";
import Basic_ModalWeek from "./Basic/Components/ModalWeek";
import Basic_ReservationTable from "./Basic/Components/ReservationTable";
import Basic_Resume from "./Basic/Components/Resume";
import Basic_WeekReservationTable from "./Basic/Components/WeekReservationTable";
import Basic_CategorySelector from "./Basic/Components/CategorySelector";
import Basic_ModalInformations from "./Basic/Components/ModalInformations";
import Basic_DishCard from "./Basic/Components/DishCard";
import Basic_ExportTable from "../ExportTable";


import Ovelia_ModalReservation from "./Specific/ovelia/Components/ModalReservation";
import Ovelia_CategorySelector from "./Specific/ovelia/Components/CategorySelector";
import Ovelia_ModalInformations from "./Specific/ovelia/Components/ModalInformations";
import Ovelia_ReservationTable from "./Specific/ovelia/Components/ReservationTable";
import Ovelia_ReservationTableGuest from "./Specific/ovelia/Components/ReservationTableGuest";
import Ovelia_DishCard from "./Specific/ovelia/Components/DishCard";


import Heurus_ModalInformations from "./Specific/heurus/Components/ModalInformations";
import Heurus_ReservationTable from "./Specific/heurus/Components/ReservationTable";
import Heurus_ModalReservation from "./Specific/heurus/Components/ModalReservation";
import Heurus_ReservationTableGuest from "./Specific/heurus/Components/ReservationTableGuest";
import Heurus_CategorySelector from "./Specific/heurus/Components/CategorySelector";
import Heurus_DishCard from "./Specific/heurus/Components/DishCard";

import Arpavie_ExportTable from "./Specific/arpavie/Components/ExportTable";

export const CategorySelector = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_CategorySelector {...props} />;
    if(ui?.groupement?.specific === "heurus") return <Heurus_CategorySelector {...props} />;
    return <Basic_CategorySelector {...props} />;
};

export const ModalInformation = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_ModalInformations {...props} />;
    if (ui?.groupement?.specific === "heurus") return <Heurus_ModalInformations {...props} />;
    return <Basic_ModalInformations {...props} />;
};

export const DishCard = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_DishCard {...props} />;
    if (ui?.groupement?.specific === "heurus") return <Heurus_DishCard {...props} />;
    return <Basic_DishCard {...props} />;
};


export const WeekPdf = (props) => {
    return <Basic_WeekPdf {...props} />;
};

export const DetailCell = (props) => {
    return <Basic_DetailCell {...props} />;
};

export const ModalReservation = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_ModalReservation {...props} />;
    if (ui?.groupement?.specific === "heurus") return <Heurus_ModalReservation {...props} />;
    return <Basic_ModalReservation {...props} />;
};

export const ModalWeek = (props) => {
    return <Basic_ModalWeek {...props} />;
};

export const ReservationTable = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_ReservationTable {...props} />;
    if (ui?.groupement?.specific === "heurus") return <Heurus_ReservationTable {...props} />;
    return <Basic_ReservationTable {...props} />;
};

export const ReservationTableGuest = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "heurus") return <Heurus_ReservationTableGuest {...props} />;
    if (ui?.groupement?.specific === "ovelia") return <Ovelia_ReservationTableGuest {...props} />;
    return <></>;
};

export const Resume = (props) => {
    return <Basic_Resume {...props} />;
};

export const WeekReservationTable = (props) => {
    return <Basic_WeekReservationTable {...props} />;
};

export const ExportTable = (props) => {
const [ui] = useUI();

    if (ui?.groupement?.specific === "arpavie") return <Arpavie_ExportTable {...props} />;
    
    return <Basic_ExportTable {...props} />;
};
