import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button, Input, FormGroup, Select, Checkbox, DatePicker
} from "lib/components";
import roles from "enums/roles";

import firebase from "firebase";
import { toast } from "react-toastify";
import useUI from "hooks/ui.hook";
import useMicroService from "helpers/microService";
import styles from "../Assets/scss/addModal.module.scss";
import useFeature from "hooks/useFeature";
import moment from "moment";

const functions = firebase.functions;

const AddModal = (props) => {
  const { isOpen, toggle } = props;
  const execMicroService = useMicroService();
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [ui] = useUI();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [mail, setMail] = useState("");
  const [role, setRole] = useState(null);
  const [room, setRoom] = useState("");
  const [birthdate, setBirthdate] = useState(null);
  const [showBirthday, setShowBirthday] = useState(false);


  const _add = async () => {
    setLoading(true);
    try {
      let _error = {};
      if (!name) _error.name = "Ce champ est obligatoire";
      if (!surname) _error.surname = "Ce champ est obligatoire";
      if (!["seniorTotem", "prospect"].includes(role?.value) && !mail) _error.mail = "Ce champ est obligatoire";
      // eslint-disable-next-line no-useless-escape
      if ((role?.value !== "seniorTotem" || role?.value === "prospect") && mail && !mail.match(/^([a-zA-Z0-9_\-+\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) _error.mail = "L'adresse mail n'est pas valide";
      if (!role) _error.role = "Ce champ est obligatoire";

      setError(_error);
      if (Object.keys(_error).length > 0) {
        setLoading(false);
        return;
      }
      let _role = role?.value;
      if (_role === "prospect" && mail) _role = "senior";
      if (_role === "prospect" && !mail) _role = "seniorTotem";

      let res = null;
      if (ui.user.groupementAdmin || ui.user.superadmin) {
        res = await execMicroService("createUserFromSuperadmin", {
          name,
          surname,
          role: _role,
          room,
          establishment: ui.user.establishment,
          ...(_role === "seniorTotem" ? {} : { mail }),
          ...(role.value === "prospect" ? { isProspect: true } : {}),
          ...(birthdate ? {
            birthdate,
            birthday: moment(birthdate).format("MM-DD"),
          } : {}),
          ...(birthdate && showBirthday ? { showBirthday } : {}),

        });
      } else {
        res = await execMicroService("createUserFromOwner", {
          name,
          surname,
          role: _role,
          room,
          ...(_role === "seniorTotem" ? {} : { mail }),
          ...(role.value === "prospect" ? { isProspect: true } : {}),
          ...(birthdate ? {
            birthdate,
            birthday: moment(birthdate).format("MM-DD"),
          } : {}),
          ...(birthdate && showBirthday ? { showBirthday } : {}),
        });
      }
      if (res.status === 200 && !res.data.error) {
        setName("");
        setSurname("");
        setMail("");
        setBirthdate(null);
        setShowBirthday(false);
        setRole(null);
        toast.success(`Utilisateur ajouté${_role !== "seniorTotem" ? ", il recevra un mail avec ses codes pour se connecter" : ""}`);
      } else {
        console.error(res.data);
        toast.error(res?.data?.error ?? "Erreur lors de la création de l'utilisateur");
      }
      toggle();
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
    setLoading(false);
  };
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>- Ajouter un utilisateur -</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Select label="Role"
            invalid={error.role}
            placeholder="Role..."
            value={role}
            onChange={e => setRole(e)}
            options={[...roles.filter(i => ["family", "senior", "employee", "seniorTotem"].includes(i.value)), { label: "Prospect", value: "prospect" }]}
          />
        </FormGroup>
        <FormGroup><Input invalid={error.name} label="Nom" placeholder="Nom..." value={name} onChange={e => setName(e.target.value)} /></FormGroup>
        <FormGroup><Input invalid={error.surname} label="Prénom" placeholder="Prénom..." value={surname} onChange={e => setSurname(e.target.value)} /></FormGroup>

        {!["seniorTotem"].includes(role?.value) ?
          <FormGroup><Input invalid={error.mail} label="Email" placeholder="@" value={mail} onChange={e => setMail(e.target.value)} /></FormGroup>
          : null}
        {["seniorTotem", "senior"].includes(role?.value) ?
          <FormGroup><Input label="n° d'appartement" placeholder="42" value={room} onChange={e => setRoom((prev) => e.target.value)} /></FormGroup>
          : null}

        <div style={{ display: "flex", flexDirection: "column" }}>
          <FormGroup>
            <DatePicker label="Date de naissance" value={birthdate} onChange={e => setBirthdate(e)} placeholder="Date de naissance..." />
          </FormGroup>
          <FormGroup>
            <Checkbox label="Notifier dans les applications" checked={showBirthday} onChange={() => setShowBirthday(!showBirthday)} />
          </FormGroup>

        </div>

      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={toggle}>Retour</Button>
        <Button color='primary' onClick={_add} loading={loading}>Ajouter</Button>
      </ModalFooter>
    </Modal>
  );
};

AddModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default AddModal;
