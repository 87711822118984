import React from "react";
import PropTypes from "prop-types";
import { Check } from "react-feather";

const DetailCell = (props) => {
  const { data } = props;

  return (
    <>
      <Check color="#99cc00" size={32} />
    </>
  );
};

DetailCell.propTypes = {
  data: PropTypes.object,
};

export default DetailCell;