import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styles from "lib/assets/scss/component/stats/dateSelector.module.scss";

import { VideoTuto } from "lib/components/YoutubeModal";

import { Select, Checkbox, DatePicker, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "lib/components";
import { ArrowLeft, ArrowRight } from "react-feather";
import useStats from "lib/Stats/statsContext";
import useUI from "hooks/ui.hook";

const options = [
    { value: "week", label: "Semaine" },
    { value: "7days", label: "7 derniers jours" },
    { value: "month", label: "Mois" },
    { value: "30days", label: "30 derniers jours" },
    { value: "year", label: "Année" },
    { value: "custom", label: "Personnalisé" },
];

const partialList = ["week", "month", "year"];
const DateSelector = () => {
    const [ctx, dispatch, hasFeature] = useStats();
    const [ui] = useUI();

    const [value, setValue] = useState(options[0]);
    const [partial, setPartial] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [startCompare, setStartCompare] = useState(new Date());
    const [endCompare, setEndCompare] = useState(new Date());
    const [modalOpen, setModalOpen] = useState(false);

    const onChange = (e) => {
        setValue(e);
    };

    useEffect(() => {

        if (ctx.dates.startDate) {
            setStartDate(moment(ctx.dates.startDate, "YYYY-MM-DD").toDate());
        }
        if (ctx.dates.endDate) {
            setEndDate(moment(ctx.dates.endDate, "YYYY-MM-DD").toDate());
        }
        if (ctx.dates.startCompare) {
            setStartCompare(moment(ctx.dates.startCompare, "YYYY-MM-DD").toDate());
        }
        if (ctx.dates.endCompare) {
            setEndCompare(moment(ctx.dates.endCompare, "YYYY-MM-DD").toDate());
        }

    }, [ctx.dates]);

    useEffect(() => {

        if (ctx.dates && value.value) {
            handleChangeRange(0);
            if (value.value === "custom") setModalOpen(true);
        }

    }, [value]);

    useEffect(() => {

        if (ctx.dates && value.value) {
            handleChangeRange(0, true);
        }

    }, [partial]);

    const handleChangeRange = (direction, partialChange) => {
        let newDateObject = {};
        switch (value.value) {
            case "week":
                newDateObject = objectCalculus(direction, "week", partialChange);
                break;
            case "7days":
                if (direction === 0) {
                    newDateObject.startDate = moment().add(-6, "days").format("YYYY-MM-DD");
                    newDateObject.endDate = moment().format("YYYY-MM-DD");
                    newDateObject.startCompare = moment().add(-13, "days").format("YYYY-MM-DD");
                    newDateObject.endCompare = moment().add(-7, "days").format("YYYY-MM-DD");
                } else {
                    newDateObject.startDate = moment(startDate).add(direction * 7, "days").format("YYYY-MM-DD");
                    newDateObject.endDate = moment(endDate).add(direction * 7, "days").format("YYYY-MM-DD");
                    newDateObject.startCompare = moment(startCompare).add(direction * 7, "days").format("YYYY-MM-DD");
                    newDateObject.endCompare = moment(endCompare).add(direction * 7, "days").format("YYYY-MM-DD");
                }
                break;
            case "month":
                newDateObject = objectCalculus(direction, "month", partialChange);

                break;
            case "30days":
                if (direction === 0) {
                    newDateObject.startDate = moment().add(-29, "days").format("YYYY-MM-DD");
                    newDateObject.endDate = moment().format("YYYY-MM-DD");
                    newDateObject.startCompare = moment().add(-59, "days").format("YYYY-MM-DD");
                    newDateObject.endCompare = moment().add(-30, "days").format("YYYY-MM-DD");
                } else {
                    newDateObject.startDate = moment(startDate).add(direction * 30, "days").format("YYYY-MM-DD");
                    newDateObject.endDate = moment(endDate).add(direction * 30, "days").format("YYYY-MM-DD");
                    newDateObject.startCompare = moment(startCompare).add(direction * 30, "days").format("YYYY-MM-DD");
                    newDateObject.endCompare = moment(endCompare).add(direction * 30, "days").format("YYYY-MM-DD");
                }

                break;
            case "year":
                newDateObject = objectCalculus(direction, "year", partialChange);

                break;
            default:
                break;
        }

        if (newDateObject.startDate) {
            dispatch({ type: "setProperty", property: "dates", value: newDateObject });
        }
    };

    const objectCalculus = (direction, rangeType, partialChange) => {
        let newDateObject = {};
        if (direction === 0) {
            if (partial) {
                newDateObject.startDate = moment().startOf(rangeType).format("YYYY-MM-DD");
                newDateObject.endDate = moment().format("YYYY-MM-DD");
                newDateObject.startCompare = moment().add(-1, rangeType).startOf(rangeType).format("YYYY-MM-DD");
                newDateObject.endCompare = moment().add(-1, rangeType).format("YYYY-MM-DD");
            } else {
                newDateObject.startDate = moment().startOf(rangeType).format("YYYY-MM-DD");
                newDateObject.endDate = moment().endOf(rangeType).format("YYYY-MM-DD");
                newDateObject.startCompare = moment().add(-1, rangeType).startOf(rangeType).format("YYYY-MM-DD");
                newDateObject.endCompare = moment().add(-1, rangeType).endOf(rangeType).format("YYYY-MM-DD");
            }

        } else {
            if (partial && willBeInDateRange(direction)) {
                newDateObject.startDate = moment().startOf(rangeType).format("YYYY-MM-DD");
                newDateObject.endDate = moment().format("YYYY-MM-DD");
                newDateObject.startCompare = moment().add(-1, rangeType).startOf(rangeType).format("YYYY-MM-DD");
                newDateObject.endCompare = moment().add(-1, rangeType).format("YYYY-MM-DD");
            } else {
                newDateObject.startDate = moment(startDate).add(direction, rangeType).startOf(rangeType).format("YYYY-MM-DD");
                newDateObject.endDate = moment(endDate).add(direction, rangeType).endOf(rangeType).format("YYYY-MM-DD");
                newDateObject.startCompare = moment(startCompare).add(direction, rangeType).startOf(rangeType).format("YYYY-MM-DD");
                newDateObject.endCompare = moment(endCompare).add(direction, rangeType).endOf(rangeType).format("YYYY-MM-DD");
            }

        }

        return newDateObject;
    };

    const confirmSelection = () => {
        if (startDate && endDate && startCompare && endCompare) {
            if (startDate > endDate) {
                alert("Vous ne pouvez pas avoir une date de fin avant une date de début (pour la période)");
                return;
            }

            if (startCompare > endCompare) {
                alert("Vous ne pouvez pas avoir une date de fin avant une date de début (pour la comparaison)");
                return;
            }
            dispatch({
                type: "setProperty", property: "dates", value: {
                    startDate: moment(startDate).format("YYYY-MM-DD"),
                    endDate: moment(endDate).format("YYYY-MM-DD"),
                    startCompare: moment(startCompare).format("YYYY-MM-DD"),
                    endCompare: moment(endCompare).format("YYYY-MM-DD"),
                }
            });
            setModalOpen(false);
        }
    };

    const isInDateRange = () => {

        if (value.value === "week") {
            if (moment(startDate).format("YYYY-MM-DD") === moment().startOf("week").format("YYYY-MM-DD")) return true;
        }
        if (value.value === "month") {
            if (moment(startDate).format("YYYY-MM-DD") === moment().startOf("month").format("YYYY-MM-DD")) return true;
        }
        if (value.value === "year") {
            if (moment(startDate).format("YYYY-MM-DD") === moment().startOf("year").format("YYYY-MM-DD")) return true;
        }
        return false;
    };

    const willBeInDateRange = (direction) => {

        if (value.value === "week") {
            if (moment(startDate).add(direction, "week").format("YYYY-MM-DD") === moment().startOf("week").format("YYYY-MM-DD")) return true;
        }
        if (value.value === "month") {
            if (moment(startDate).add(direction, "month").format("YYYY-MM-DD") === moment().startOf("month").format("YYYY-MM-DD")) return true;
        }
        if (value.value === "year") {
            if (moment(startDate).add(direction, "year").format("YYYY-MM-DD") === moment().startOf("year").format("YYYY-MM-DD")) return true;
        }
        return false;
    };


    const displayDateRange = () => {


        // if(value.value === "week"){
        //     if(moment(startDate).format("YYYY-MM-DD") === moment().startOf("week").format("YYYY-MM-DD")) return true;
        // }
        // if(value.value === "month"){
        //     if(moment(startDate).format("YYYY-MM-DD") === moment().startOf("month").format("YYYY-MM-DD")) return true;
        // }
        // if(value.value === "year"){
        //     if(moment(startDate).format("YYYY-MM-DD") === moment().startOf("year").format("YYYY-MM-DD")) return true;
        // }

        // return <div> <b>{moment(ctx.dates.startDate).format("dddd DD/MM/YYYY")}</b> - <b>{moment(ctx.dates.endDate).format("dddd DD/MM/YYYY")}</b> comparé au <b>{moment(ctx.dates.startCompare).format("dddd DD/MM/YYYY")}</b> - <b>{moment(ctx.dates.endCompare).format("dddd DD/MM/YYYY")}</b></div>;

        if (hasFeature("statistics")) {
            switch (value.value) {
                case "week":
                    return <div> Semaine du <b>{moment(ctx.dates.startDate).format("DD/MM/YYYY")}</b> comparé à la semaine du <b>{moment(ctx.dates.startCompare).format("DD/MM/YYYY")}</b></div>;
                case "7days":
                    return <div> <b>{moment(ctx.dates.startDate).format("dddd DD/MM/YYYY")}</b> - <b>{moment(ctx.dates.endDate).format("dddd DD/MM/YYYY")}</b> comparé au <b>{moment(ctx.dates.startCompare).format("dddd DD/MM/YYYY")}</b> - <b>{moment(ctx.dates.endCompare).format("dddd DD/MM/YYYY")}</b></div>;
                case "month":
                    return <div> Mois de <b>{moment(ctx.dates.startDate).format("MMMM YYYY")}</b> comparé à <b>{moment(ctx.dates.startCompare).format("MMMM YYYY")}</b></div>;
                case "30days":
                    return <div><b>{moment(ctx.dates.startDate).format("dddd DD/MM/YYYY")}</b> - <b>{moment(ctx.dates.endDate).format("dddd DD/MM/YYYY")}</b> comparé au <b>{moment(ctx.dates.startCompare).format("dddd DD/MM/YYYY")}</b> - <b>{moment(ctx.dates.endCompare).format("dddd DD/MM/YYYY")}</b></div>;
                case "year":
                    return <div> Année <b>{moment(ctx.dates.startDate).format("YYYY")}</b> comparé à <b>{moment(ctx.dates.startCompare).format("YYYY")}</b></div>;
                case "custom":
                    return <div> <b>{moment(ctx.dates.startDate).format("dddd DD/MM/YYYY")}</b> - <b>{moment(ctx.dates.endDate).format("dddd DD/MM/YYYY")}</b> comparé au <b>{moment(ctx.dates.startCompare).format("dddd DD/MM/YYYY")}</b> - <b>{moment(ctx.dates.endCompare).format("dddd DD/MM/YYYY")}</b></div>;
            }
        } else {
            switch (value.value) {
                case "week":
                    return <div> Semaine du <b>{moment(ctx.dates.startDate).format("DD/MM/YYYY")}</b></div>;
                case "7days":
                    return <div> <b>{moment(ctx.dates.startDate).format("dddd DD/MM/YYYY")}</b> - <b>{moment(ctx.dates.endDate).format("dddd DD/MM/YYYY")}</b></div>;
                case "month":
                    return <div> Mois de <b>{moment(ctx.dates.startDate).format("MMMM YYYY")}</b></div>;
                case "30days":
                    return <div><b>{moment(ctx.dates.startDate).format("dddd DD/MM/YYYY")}</b> - <b>{moment(ctx.dates.endDate).format("dddd DD/MM/YYYY")}</b></div>;
                case "year":
                    return <div> Année <b>{moment(ctx.dates.startDate).format("YYYY")}</b></div>;
                case "custom":
                    return <div> <b>{moment(ctx.dates.startDate).format("dddd DD/MM/YYYY")}</b> - <b>{moment(ctx.dates.endDate).format("dddd DD/MM/YYYY")}</b></div>;
            }
        }


        return <></>;
    };

    return (
        <div className={styles.container}>
            <div className={styles.headBar}>
                <Select
                    onChange={onChange}
                    value={value}
                    options={options}
                />
                {/* Rajouter la véfification qu'on est dans le mois courrant, car si on change de mois, ça n'a pas de sens */}
                {/* Rajouter le (i) pour expliquer le fonctionnement */}

                {hasFeature("statistics") && partialList.includes(value.value) && isInDateRange() ?
                    <Checkbox label="Comparaison Partielle" checked={partial} onChange={() => setPartial(!partial)} />
                    : null}
                {!hasFeature("statistics", true) && (ui.user.role === "superadmin" || ui.user.superadmin === true) ?
                    <Checkbox label="Vue SuperAdmin" checked={ctx.superAdminView} onChange={() => dispatch({ type: "setProperty", property: "superAdminView", value: !ctx.superAdminView })} />
                    : null}
                {value.value === "custom" ?
                    <Button onClick={() => setModalOpen(true)}>Modification des dates</Button>
                    : null}
                {ui.establishment && <VideoTuto url="https://youtu.be/RvfDYtMWM3g" />}

            </div>
            <Modal isOpen={modalOpen} toggle={() => { setModalOpen(false); }} size="lg">
                <ModalHeader>Sélection des plages de dates</ModalHeader>
                <ModalBody>
                    <div>
                        <div className={styles.customPicker}>
                            <div>
                                <DatePicker
                                    value={startDate}
                                    onChange={(e) => setStartDate(e)}
                                />
                                <DatePicker
                                    value={endDate}
                                    onChange={(e) => setEndDate(e)}
                                />
                            </div>
                            {hasFeature("statistics") ?
                                <>
                                    <div>
                                        Comparé au
                                    </div>
                                    <div>
                                        <DatePicker
                                            value={startCompare}
                                            onChange={(e) => setStartCompare(e)}
                                        />
                                        <DatePicker
                                            value={endCompare}
                                            onChange={(e) => setEndCompare(e)}
                                        />
                                    </div>
                                </> : null}

                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={{ display: "flex", justifyContent: "center" }}>
                    <Button color="secondary" onClick={() => setModalOpen(false)}>Retour</Button>
                    <Button onClick={confirmSelection}>Valider</Button>
                </ModalFooter>
            </Modal>
            <div className={styles.display}>


                {value.value !== "custom" ? <ArrowLeft size={50} style={{ cursor: "pointer" }} onClick={() => handleChangeRange(-1)} /> : null}
                {displayDateRange()}
                {value.value !== "custom" ? <ArrowRight size={50} style={{ cursor: "pointer" }} onClick={() => handleChangeRange(1)} /> : null}

            </div>
        </div>
    );
};



export default DateSelector;