import React, { useEffect } from "react";
import styles from "./senior.module.scss";
import { Button } from "lib/components";
import useUI from "../../hooks/ui.hook.js";
import firebase from "firebase";
import useMicroService from "helpers/microService";
import MODE from "../../env";
import Loader from "lib/pages/Loader";

const Senior = () => {
  const [ui, dispatch] = useUI();
  const execMicroService = useMicroService();

  useEffect(() => {
    if (!ui.user) return;
    (async () => {
      try {
        let token = (await execMicroService("genJwt"));
        token = token.data.token;
        firebase.auth().signOut();
        setTimeout(() => window.location.href = MODE === "DEV" ? `http://localhost:3001?token=${token}` : `https://app.majorconnect.fr?token=${token}`, 1000);
      } catch (e) {
        console.error(e);
        firebase.auth().signOut();
        setTimeout(() => window.location.href = MODE === "DEV" ? "http://localhost:3001" : "https://app.majorconnect.fr", 1000);
      }
    })();
  }, [ui]);

  return (
    <div className={styles.errorPage}>
      <Loader show />
    </div>
  );
};

export default Senior;