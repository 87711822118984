import React, {useState} from "react";
import PropTypes from "prop-types";
import { NumberVariations, SimpleStatComponent} from "../components";
import Card from "lib/components/Stats/Card";
import {PieChart} from "../components";
import useStats from "lib/Stats/statsContext";
import { faUsers} from "@fortawesome/free-solid-svg-icons";

const infos = {
  users : {
    establishment: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre d'utilisateurs existant sur la période sélectionnée. \n"}
                         <b>{"2) Pourcentage:"}</b> {" Indique les variations du nombre d'utilisateurs entre cette période et la période précédente. \n"}
                   </div>,
    groupement: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre total d'utilisateurs existant sur la période sélectionnée. \n"}
                      <b>{"2) Pourcentage:"}</b> {" Indique les variations du nombre d'utilisateurs entre cette période et la période précédente. \n"}
                      <b>{"3) Moyenne:"}</b> {" Indique le nombre moyen d'utilisateurs par résidence. \n"}
                      <b>{"4) Tableau:"}</b> {" Indique pour chaque résidence, le nombre d'utilisateurs sur la période donnée, avec les variations du nombre d'utilisateurs entre cette période et la période précédente."}</div>
  },
  usersNew : {
    establishment: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre de nouveaux utilisateurs créés sur la période sélectionnée. \n"}
                         <b>{"1) Pourcentage:"}</b> {" Indique les variations du nombre de nouveaux utilisateurs créés entre cette période et la période précédente. \n"}</div>,
    groupement: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre total de nouveaux utilisateurs créés sur la période sélectionnée. \n"}
                      <b>{"2) Pourcentage:"}</b> {" Indique les variations du nombre de nouveaux utilisateurs entre cette période et la période précédente. \n"}
                      <b>{"3) Moyenne:"}</b> {" Indique le nombre moyen de nouveaux utilisateurs par résidence sur la période sélectionnée. \n"}
                      <b>{"4) Tableau:"}</b> {" Indique pour chaque résidence, le nombre de nouveaux utilisateurs sur la période donnée, avec les variations du nombre de nouveaux utilisateurs entre cette période et la période précédente."}</div>
  },
  usersRepartition : 
    <div> <b>{"1) Diagramme circulaire:"}</b> {" Représente graphiquement la répartition des différents types d'utilisateurs. \n"}</div>,
  usersNewRepartition :     
    <div> <b>{"1) Diagramme circulaire:"}</b> {" Représente graphiquement la répartition des différents types de nouveaux utilisateurs. \n"}</div>,
};

const Users = () => {
  const [ctx, dispatch, hasFeature] = useStats();

  const NumberComponent = hasFeature("statistics") ? NumberVariations : SimpleStatComponent;

  return (
    <div style={{marginTop: 30, display: "flex", flexDirection: "row", justifyContent: "space-around", flexWrap: "wrap", gap: 40, paddingBottom: 30}}>    
      {/* <UserNumber type={"groupement"} data1={data1} data2={data2}/> */}
      <NumberComponent path="users/count" title="Utilisateurs" info={ctx?.targetType === "establishment" ? infos.users.establishment : infos.users.groupement} icon={faUsers} iconBackgroundColor={"#F4C1E0"}/>
      <NumberComponent path="users/new" title="Nouveaux Utilisateurs" info={ctx?.targetType === "establishment" ? infos.usersNew.establishment : infos.usersNew.groupement} icon={faUsers} iconBackgroundColor={"#F4C1E0"}/>

      
      
      {hasFeature("statistics") ? 
      <>
        <Card title="Répartition des utilisateurs" info={infos.usersRepartition} size="lg">
          <PieChart data1={ctx.data1} path="users/countByRole" isRole={true}/>
        </Card>
      </>
      : null}
     
      {hasFeature("statistics") ? 
      <>
        <Card title="Répartition des nouveaux utilisateurs" info={infos.usersNewRepartition} size="lg">
          <PieChart data1={ctx.data1} path="users/newByRole" isRole={true}/>
        </Card>
      </>
      : null}
      
    </div>
  );
};


Users.propTypes = {    
    establishment: PropTypes.string,
    data1: PropTypes.object,
    data2: PropTypes.object,
};

export default Users;
