import React from "react";
import {SidebarLink, SidebarImage} from "../lib/components/dashboard";
import Logo from "../assets/images/jabiLogo/MAJOR_BLANC.png";
import env from "../env";
import {Flag, Home, Users, Grid, Terminal, AlertOctagon, Info, Activity} from "react-feather";

import styles from "../assets/scss/config/SuperAdminMenu.module.scss";

const SuperAdminMenu = () => {
  return (
    <div className={styles.menu}>
      <SidebarImage img={Logo}  />
      <div className={styles.warning}>
        SUPERADMIN MODE
      </div>
      <div className={styles.warning} style={{marginTop: 0, ...(env === "DEV" ? {backgroundColor: "#cdff3d"} : {})}}>
        {env}
      </div>

      <SidebarLink to="/dashboard/one"             icon={Home}> Accueil</SidebarLink>
      <SidebarLink to="/dashboard/groupements"     icon={Grid}> Groupements</SidebarLink>
      <SidebarLink to="/dashboard/establishments"  icon={Flag}> Etablissements</SidebarLink>
      <SidebarLink to="/dashboard/users"           icon={Users}>Utilisateurs</SidebarLink>
      <SidebarLink to="/dashboard/quizz"           icon={Users}>Quizz</SidebarLink>
      <SidebarLink to="/dashboard/bornes"          icon={Terminal}>Bornes</SidebarLink>
      <SidebarLink to="/dashboard/reports"         icon={AlertOctagon}>Error report</SidebarLink>
      <SidebarLink to="/dashboard/patchnotes"      icon={Info}>Patchnotes</SidebarLink>
      <SidebarLink to="/dashboard/monitoring"      icon={Activity}>Monitoring</SidebarLink>
      <SidebarLink to="/dashboard/notifications"   icon={Terminal}>Notifications</SidebarLink>
      <SidebarLink to="/dashboard/sandbox"         icon={Terminal}>Sandbox</SidebarLink>
    </div>
  );
};

export default SuperAdminMenu;