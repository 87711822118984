import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormGroup, Select } from "../../../../../lib/components";
import { useAnimationCategories } from "resources/animations";

const CategorySelector = (props) => {
  const { value, onChange } = props;
  const { categories } = useAnimationCategories();

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [options, setOptions] = useState([]);
  const [color, setColor] = useState("#f2994a");

  useEffect(() => {
    if (!categories) return;
    const _options = categories.map((category) => ({
      value: category.uid,
      label: category.title,
    }));
    setOptions(_options);
  }, [categories]);

  useEffect(() => {
    if (value) {
      const _selectedCategory = categories.find((category) => category.uid === value);
      if (_selectedCategory) {
        setSelectedCategory({ label: _selectedCategory.title, value: _selectedCategory.uid });
        setColor(_selectedCategory.color);
      } else {
        setSelectedCategory(null);
        setColor("#f2994a");
      }
    } else {
      setSelectedCategory(null);
      setColor("#f2994a");
    }
  }, [value, categories]);

  return (
    <FormGroup>
      <span>Catégorie:</span>
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <Select
          options={options}
          containerStyle={{ flex: 1 }}
          clearable
          value={selectedCategory}
          onChange={(e) => onChange(e ? e.value : null)}
          theme="grey"
        />
        <div style={{ height: 38, width: 38, padding: 5 }}>
          <div
            style={{
              backgroundColor: color,
              height: "100%",
              width: "100%",
              borderRadius: 50,
            }}
          ></div>
        </div>
      </div>
    </FormGroup>
  );
};

CategorySelector.propTypes = {
  value : PropTypes.object.isRequired,
  onChange : PropTypes.func.isRequired,
};

export default CategorySelector;
