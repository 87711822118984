import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";

import { Tab, Spinner } from "lib/components";

import DateSelector from "lib/Stats/components/dateSelector";

import { Global, Users, Animations, Restauration, Actualites, Gazette} from "lib/Stats/pages";
import EstablishmentSelector from "lib/Stats/components/establishmentSelector";
import useStats, { Provider } from "lib/Stats/statsContext";


const EstablishmentStatsComponent = () => {
  const { id } = useParams();
  const history = useHistory();
  const [ctx, dispatch, hasFeature] = useStats();
  const [page, setPage] = useState(0);

  if (!ctx.dates) return <></>;

  let tab = [
    { title: "Vue Globale", content: <Global /> },
    { title: "Utilisateurs", content: <Users /> }];

  if (hasFeature("statistics")) {
    if (hasFeature("animationPlanning")) tab.push({ title: "Animations", content: <Animations /> });
    if (hasFeature("menuPlanning")) tab.push({ title: "Restauration", content: <Restauration /> });
    if (hasFeature("news")) tab.push({ title: "Actualités", content: <Actualites /> });
    if (hasFeature("gazette")) tab.push({ title: "Gazettes", content: <Gazette /> });
  }


  return (
    <div style={{ width: "100%", overflow: "auto", position: "relative" }}>
      <Tab activeTab={3} onClick={console.log} render={[
        { title: "Paramètres", content: <div></div>, overrideOnClick: () => history.push(`/dashboard/establishments/${id}/settings`) },
        { title: "Menu", content: <></>, overrideOnClick: () => history.push(`/dashboard/establishments/${id}/menu`) },
        { title: "Abonnement", content: <></>, overrideOnClick: () => history.push(`/dashboard/establishments/${id}/subscription`) },
        {
          title: "Statistiques", content: <>
            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>

              {ctx.loading &&
                <div style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0, backgroundColor: "rgba(0,0,0,0.4)", display: "flex", alignItems: "center", justifyContent: "center", zIndex: 2500 }}>
                  <Spinner style={{borderColor: "#300438", width: 100, height: 100, borderWidth: 7}} />
                </div>
              }

              <DateSelector />
              {/* <EstablishmentSelector/> */}

              {ctx.data1 && ctx.data2 ?
                hasFeature("statistics") ?
                  <Tab activeTab={page}
                    onClick={(_page) => setPage(_page)}
                    render={tab}
                  />
                  :
                  <Global />
                : null}

            </div>
          </>
        },

      ]} />
    </div>
  );
};

const EstablishmentStats = () => {
  const { id } = useParams();

  return (
    <Provider targetType="establishment" target={id}>
      <EstablishmentStatsComponent />
    </Provider>
  );
};


export default EstablishmentStats;
