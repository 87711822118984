import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Page, Text, View, Document, Image, StyleSheet, Font } from "@react-pdf/renderer";
import moment from "moment";
import Logo from "./assets/Logo.png";
import illustration from "./assets/Image1.jpg";
import Brandon from "fonts/brandon/BrandonGrotesque-Regular.ttf";
import BrandonBold from "fonts/brandon/BrandonGrotesque-Bold.ttf";

Font.register({
  family: "Brandon",
  fontWeight: "normal",
  src: Brandon,
});
Font.register({
  family: "Brandon",
  fontWeight: "bold",
  src: BrandonBold,
});


const styles = StyleSheet.create({
  //-------------------------- layout -------------------------------//
  layoutContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    padding: 0,
  },
  sidebar: {
    flex: 6,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingRight: 4,
  },
  content: {
    flex: 30,
    border: "4px solid #0d1c41",
    padding: 2,
  },
  contentBorder: {
    border: "2px solid #0d1c41",
    height: "100%",
    width: "100%",
    backgroundColor: "white"
  },
  //-------------------------- header -------------------------------//
  header: {
    // backgroundColor: "#f07193",
    height: 100,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  header_left: {
    width: "80%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  header_right: {
    width: "20%",
    height: "100%",
    backgroundColor: "#ec871c",
  },
  header_image_container: {
    position: "absolute",
    right: 20,
    top: 10,
    width: 170,
    height: 85,

  },
  header_image: {
    width: "100%",
    height: "100%",
    objectFit: "contain"
  },
  header_title: {
    color: "#0d1c41",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 25,
    fontFamily: "Brandon",
    paddingLeft: 20
  },
  header_subtitle: {
    color: "#f06691",
    textAlign: "center",
    fontSize: 25,
    fontWeight: "bold",
    paddingLeft: 20,
    fontFamily: "Brandon",
  },
  //-------------------------- weekday header -------------------------------//
  dayHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "stretch",
    justifyContent: "center",
    backgroundColor: "#0d1c41",
  },
  weekDay: {
    backgroundColor: "#0d1c41",
    color: "#f06691",
    flex: 1,
    textAlign: "center",
    fontSize: 16,
    fontFamily: "Brandon",
    fontWeight: "bold",
    paddingVertical: 12,
  },
  //-------------------------- month layout -------------------------------//
  monthContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    alignItems: "stretch",
    justifyContent: "center",
    // backgroundColor: "#f07193",
  },
  monthHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#0d1c41",
    height: 60,
  },
  monthLine: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  monthDateContainer: {
    flex: 1,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#0d1c41",
  },
  monthDate: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Brandon",
    color: "#ec871c",
  },
  //-------------------------- halfday -------------------------------//
  monthLineHalfDay: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    backgroundColor: "black",
  },
  monthLine_halfDayCase: {
    backgroundColor: "white",
    flex: 1,
    height: "100%",
    marginLeft: 1,
    marginRight: 1,
  },
  monthLineSeparator: {
    height: 4,
    backgroundColor: "#0d1c41",
    width: "100%",
  },

  //-------------------------- events -------------------------------//
  event: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: 4,
    marginBottom: 4
  },
  eventTitle: {
    fontSize: 12,
    marginLeft: 2,
    marginRight: 2,
    textAlign: "center",
    fontFamily: "Brandon",
    fontWeight: "bold",
    color: "#0d1c41"
  },

  eventHour: {
    width: "100%",
    color: "#f06691",
    textAlign: "center",
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Brandon",
  },

  // ------ Footer ------

  footer: {
    width: "100%",
    height: 50,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor:  "#0d1c41"

  },
  textFooter: {
    fontFamily: "Brandon",
    fontSize: 12,
    color: "white",
    paddingRight: 50,
  },
  logo: {
    position: "absolute",
    width: 150,
    height: 40,
    right: 2,
    bottom: 9,
    objectFit: "contain"
  },
});

const MonthPlanningPdf = (props) => {
  const {
    firstDay,
    events,
    ui
  } = props;

  const _computeDays = useMemo(() => {
    let firstDayOfMonth = new Date(firstDay);
    let firstDayOfExport = new Date(firstDay);
    let weeks = [];

    firstDayOfMonth.setDate(1);
    firstDayOfExport.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDay() + 1);

    do {
      weeks.push([]);
      for (let i = 0; i < 7; i++) {
        weeks[weeks.length - 1].push(new Date(firstDayOfExport));
        firstDayOfExport.setDate(firstDayOfExport.getDate() + 1);
      }
    } while (weeks[weeks.length - 1][weeks[weeks.length - 1].length - 1].getMonth() === firstDayOfMonth.getMonth());
    return (weeks);
  }, [firstDay]);

  const _getEventByDay = (day) => {
    let start = new Date(day);
    let end = new Date(day);

    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(1);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);

    return (events.filter(event => event.start > start && event.end < end));
  };

  const getTextSize = (text) => {
    let _size = 12 - Math.floor(text.length / 12);

    const uppercaseCount = (text.match(/[A-Z]/g) || []).length;

    if(uppercaseCount > text.length/2)_size -= 2;

    return  _size <= 7 ? 7 : _size;
  };

  const _renderEvent = (events) => {
    // on ne peut afficher qu'un seul événement, donc on choisit celui qui dure le plus longtemps.

    let event = null;

    if (events.length === 1) {
      event = events[0];
    } else if (events.length > 1) {
      let longestEvent = null;
      let longestDuration = 0;

      events.forEach(event => {
        const duration = event.end.getTime() - event.start.getTime();
        if (duration > longestDuration) {
          longestEvent = event;
          longestDuration = duration;
        }
      });

      event = longestEvent;
    }


    if (!event) return (
      <View style={styles.event}>
      </View>
    );

    return (
      <View key={event.uid} style={styles.event}>
        <Text style={styles.eventHour}>{moment(event.start).format("HH[h]mm")}</Text>
        <Text style={[styles.eventTitle, {fontSize: getTextSize(event.title)}]}>{event.title}</Text>
      </View>
    );
  };

  const _monthToString = (date) => moment(date).format("MMMM");
  const _dateToString = (date) => moment(date).format("Do");
  const _hourToString = (date) => moment(date).format("HH:mm");

  return (
    <Document>
      <Page size="A4" orientation="portrait" style={{ position: "relative" }}>
        <View style={styles.layoutContainer}>
          <View style={styles.content}>
            <View style={styles.contentBorder}>

              <View style={styles.header}>
                <View style={styles.header_left}>
                  <Text style={styles.header_title}>Planning des animations</Text>
                  <Text style={styles.header_subtitle}>{moment(firstDay).format("MMMM YYYY")}</Text>
                </View>
                <View style={styles.header_right}>

                </View>
              </View>

              <View style={styles.container}>
                <View style={styles.dayHeaderContainer}>
                  <Text style={styles.weekDay}>Lundi</Text>
                  <Text style={styles.weekDay}>Mardi</Text>
                  <Text style={styles.weekDay}>Mercredi</Text>
                  <Text style={styles.weekDay}>Jeudi</Text>
                  <Text style={styles.weekDay}>Vendredi</Text>
                  <Text style={styles.weekDay}>Samedi</Text>
                  <Text style={styles.weekDay}>Dimanche</Text>
                </View>
              </View>


              <View style={styles.monthContainer}>
                {new Array(5).fill(0).map((_, index) => (
                  <View style={styles.monthLine} key={index}>
                    <View style={styles.monthHeaderContainer}>
                      {_computeDays[index].map((d, index2) => (
                        <View key={"date" + index + index2} style={styles.monthDateContainer}>
                          <Text style={styles.monthDate} >{_dateToString(d)}</Text>
                        </View>
                      ))}
                    </View>
                    <View style={styles.monthLineHalfDay}>
                      {_computeDays[index].map((d, index2) => (
                        <View style={styles.monthLine_halfDayCase} key={"A" + index + index2}>
                          {_renderEvent(_getEventByDay(d)
                            .filter(event => moment(event.start).isBetween(moment(d).set("hour", 0).set("minute", 1), moment(d).set("hour", 13).set("minute", 0), null, "[]"))
                          )}
                        </View>
                      ))}
                    </View>
                    <View style={styles.monthLineSeparator}>
                    </View>
                    <View style={styles.monthLineHalfDay}>
                      {_computeDays[index].map((d, index2) => (
                        <View style={styles.monthLine_halfDayCase} key={"B" + index + index2}>
                          {_renderEvent(
                            _getEventByDay(d)
                              .filter(event => moment(event.start).isBetween(moment(d).set("hour", 13).set("minute", 1), moment(d).set("hour", 23).set("minute", 59), null, "[]"))
                          )}
                        </View>
                      ))}
                    </View>
                  </View>
                ))}
              </View>

              <View style={styles.footer}>
                <Text style={styles.textFooter}>Chez Heurus, le planning d’animations est coconstruit avec les résidents.</Text>
                <Text style={styles.textFooter}>Le Grand Salon et les extérieurs sont à votre disposition toute la journée.</Text>
              </View>
            </View>
          </View>
        </View>


        <Image src={Logo} style={styles.logo} />

        <View style={styles.header_image_container}>
          <Image style={styles.header_image} src={illustration} />
        </View>
      </Page>
    </Document>
  );
};

MonthPlanningPdf.propTypes = {
  firstDay: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  ui: PropTypes.object,
};

export default MonthPlanningPdf;