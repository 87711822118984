import React from "react";
import VideoLibrary from "./VideoLibrary";
import styles from "../../../assets/scss/pages/Tutoriels/videoplayer.module.scss";

const Tuto = () => {
  return (
    <div style={{width: "100%"}}>
      <h2>A la découverte des fonctionnalités : </h2>
      <VideoLibrary />
    </div>
  );
};

export default Tuto;
