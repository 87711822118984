import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useFlexLayout,
  useFilters,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
//import { matchSorter } from "match-sorter";
import { useSticky } from "react-table-sticky";

import styles from "../../../lib/assets/scss/component/stats/table.module.scss";

const colors = [styles.background1, styles.background2, styles.background3, styles.background4];

const Table = ({ _columns, _data , colWidth, hasFooter, onClick, color}) => {
  if(!colWidth)colWidth=80;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (_columns != undefined) setColumns(_columns);
    if (_data != undefined){
      let newData = [];
      _data.forEach(element => {
        let newObject = {};
        Object.entries(element).forEach(([key, value]) => {
          if(typeof(value) === "number"){
            newObject[key] = reduceFloating(value);
          }else{
            newObject[key] = value;
          }
        });
        newData.push(newObject);
      });
      setData(newData);
    } 
  }, [_data, _columns]);

  const defaultColumn = React.useMemo(
    () => ({
      width: colWidth,
    }),
    []
  );
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters, // useFilters!
    useFlexLayout,
    useSortBy,
    useSticky
  );

  /*function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }*/


  const footerGroups = headerGroups.slice().reverse();

  return (
    <div style={{userSelect: "none"}} className={styles.tableContainer}>
      <div
        {...getTableProps()}
        className={`${styles.table} ${styles.sticky}`}
      >
      
        <div className={styles.header}>
          {headerGroups.map((headerGroup, index) => (
            <div {...headerGroup.getHeaderGroupProps()} className={styles.tr} key={index}>
              {headerGroup.headers.map((column, index2) => (
                <div key={index + index2} {...column.getHeaderProps(column.getSortByToggleProps())} className={`${styles.th} ${column.depth == 0 ? styles.firstHeader : styles.otherHeader}`} style={{ flex: `${column.width}`,  backgroundColor: color ?? "initial" }}>
                  <>
                    {column.render("Header")}
                    <span className={styles.sort}>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className={styles.body}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <div key={index} {...row.getRowProps()} className={styles.tr}>
                {row.cells.map((cell, index2) => (
                  <div key={index + index2} {...cell.getCellProps()} className={`${styles.td} ${cell.column.canUpdate ? styles.pointer : null} ${cell.column.colorChange != undefined ? colors[cell.column.colorChange%4] : null}`} onClick={() => {if(cell.column.canUpdate)onClick(cell);}}> 
                    {cell.render("Cell")}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        {hasFooter ? 
          <div className={styles.footer}>
            {footerGroups.map((footerGroup, index) => (
              <div key={index} {...footerGroup.getHeaderGroupProps()} className={styles.tr}>
                {footerGroup.headers.map((column, index2) => (
                  <>
                    {column.Footer != undefined && column.Footer != "" ? (
                      <div key={index + index2} {...column.getHeaderProps()} className={styles.td}>
                        {column.render("Footer")}
                      </div>
                    ) : null}
                  </>
                ))}
              </div>
            ))}
          </div> : null}
      </div>
    </div>  
  );
};

const reduceFloating = (number) => {
  return Number.parseInt(number*10) /10;
};


Table.propTypes = {
  _columns: PropTypes.array.isRequired,
  _data: PropTypes.array.isRequired,
  colWidth: PropTypes.number,
  hasFooter: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
};

export default Table;
