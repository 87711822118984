import React, {useState, useEffect} from "react";
import { Select, Input, Button, FormGroup } from "lib/components";
import { getAllGroupements } from "services/ressources/groupement";
import { toast } from "react-toastify";
import { getAllUsers, getUsersByGroupement } from "services/ressources/user";
import useMicroService from "helpers/microService";

const Notifications = () => {
  const ms = useMicroService();
  const [loading, setLoading] = useState(false);
  
  const [groupements, setGroupements] = useState([]); // [{label: "groupement", value: "id"}

  const [type, setType] = useState(""); //admin | senior | all
  const [selected, setSelected] = useState(null);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    (async () => {
      let _groupements = await getAllGroupements();
      _groupements = _groupements.map(i => ({
        label: i.name,
        value: i.uid
      }));
      setGroupements(_groupements);
    })();
  }, []);

  const _send = async () => {
    setLoading(true);
    try {
      console.log(type);
      let users = selected?.value === "all" ? await getAllUsers() : await getUsersByGroupement({groupementId: selected.value});
      users = type?.value === "admin" ? users.filter(i => i.role === "owner") : 
              type?.value === "senior" ? users.filter(i => i.role === "senior") : users;
      users = users.map(i => i.uid);

      await ms("sendPushToUserById", {
        userId: users,
        title,
        body: message
      });
      toast.success("Notification envoyée");
    } catch (e) {
      toast.error("Erreur lors de l'envoi de la notification");
    }
    setLoading(false);
  };

  return (
    <div style={{width: "100%"}}>
      <h2>Notifications:</h2>
      <FormGroup>
        <Select
          placeholder="type..."
          options={[
            {label: "Responsable", value: "admin"},
            {label: "Senior", value: "senior"},
            {label: "Tous", value: "all"}
          ]}
          value={type}
          onChange={setType}
        />
      </FormGroup>
      {type && <>
        <FormGroup>
          <Select 
            placeholder="groupement..."
            options={[{label: "tous", value: "all"}, ...groupements]}
            value={selected}
            onChange={setSelected}
          />
        </FormGroup>
        {selected && <>
          <Input type="text" placeholder="title..." style={{marginTop: 15}} onChange={e => setTitle(e.target.value)} value={title} />
          <Input type="textarea" placeholder="message..." style={{marginTop: 15, minHeight: 200}} onChange={e => setMessage(e.target.value)} value={message} />
          <div style={{display: "flex", justifyContent: "center", marginTop: 10}}>
            <Button loading={loading} onClick={_send}>Envoyer</Button>
          </div>
        </>}
      </>}
    </div>
  );
};

export default Notifications;