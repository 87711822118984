import React, {useState} from "react";
import firebase from "firebase";
import { useFirebaseCollection } from "lib/hooks/firebase";
import useUI from "hooks/ui.hook";
import styles from "assets/scss/pages/Gazette/ArticleSelector.module.scss";
import { Button } from "lib/components";
import useGazette from "../context";
import moment from "moment";

const firestore = firebase.firestore;

const ArticlesSelector = () => {
  const [ui] = useUI();
  const [ctx, dispatch] = useGazette();
  const [articlesRef] = useState(firestore().collection("establishments").doc(ui.user.establishment).collection("blocks").doc("actus").collection("actus").orderBy("date", "desc"));
  const {data} = useFirebaseCollection(articlesRef, []);

  const articles = data.map(i => ({...i, date: i.date.toDate(), updatedAt: i.updatedAt.toDate()}));
  const [showAll, setShowAll] = useState(false);

  const _onToggleSelection = (article) => {
    if (ctx.articles.some(i => i.uid === article.uid))
      dispatch({type: "removeArticle", article});
    else
      dispatch({type: "addArticle", article});
  };
  const _onSubmit = () => {
    dispatch({type: "setStep", step: 1});
  };

  return (
    <div className={styles.container}>
      <div className={styles.articlesContainer}>
        {articles
          .filter(i => showAll || moment(i.date).isBetween(moment().startOf("month"), moment().endOf("month")))
          .map(article => (
          <div className={styles.article} key={article.uid}>
            <div className={`${styles.checkbox} ${ctx.articles.some(i => i.uid === article.uid) ? styles.checked : ""}`}
              onClick={() => _onToggleSelection(article)}>
            </div>
            <h4 style={{paddingBottom: 0, marginBottom: 0}}>{article.title}</h4>
            <span style={{fontSize: 14, marginTop: 0, marginLeft: 10, fontStyle: "italic"}}>{moment(article.date).format("DD/MM/YYYY")}</span>
            <img src={article.image} />
            <p>{article.description.slice(0, 100)}...</p>
          </div>
        ))}
      </div>
      <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 15}}>
        {!showAll ? <Button type="primary" size="large" onClick={() => setShowAll(!showAll)}>Voir les anciennes actus</Button> : null}
      </div>
      <div className={styles.actions}>
        <div></div>
        <div><Button type="primary" size="large" onClick={_onSubmit}>Etape suivante</Button></div>
        <div></div>
      </div>
    </div>
  );
};

export default ArticlesSelector;