import React from "react";
import RSelect from "react-select";
import PropTypes from "prop-types";
import styles from "../../assets/scss/component/select.module.scss";

const greyStyle = {
  container: (provided, state) => ({
    ...provided,
    border: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    border: 0,
    backgroundColor: "#F6F7FB",
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({ ...provided, zIndex: 9999 })
};

const Select = (props) => {
  const {label, options, onChange, value, clearable, disabled, theme, invalid, placeholder, containerStyle, labelStyle, className} = props;

  return (
    <div className={`${styles[theme]}`} style={containerStyle ?? {}}>
      {label &&
        <label style={labelStyle ?? {}}>
          {label}:
        </label>
      }
      <RSelect onChange={onChange}
        value={value}
        options={options}
        isClearable={clearable}
        isDisabled={disabled}
        isSearchable
        className={`${styles.select} ${className}`}
        styles={theme === "grey" ? greyStyle : {}}
        placeholder={placeholder}
      />
      {invalid && typeof(invalid) === "string" ?
        <span style={{color: "red"}}>{invalid}</span>
        : ""}
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.exact({
    value: PropTypes.string,
    label: PropTypes.string
  }),
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  theme: PropTypes.any,
  invalid: PropTypes.any,
  containerStyle: PropTypes.any,
  labelStyle: PropTypes.any,
  placeholder: PropTypes.string,
  className: PropTypes.any,
};

Select.defaultProps = {
  clearable : false,
  disabled  : false
};

export default Select;