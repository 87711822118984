import PropTypes from "prop-types";
import {
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import styles from "../styles/game.module.scss";
import useGazette from "../../../context";
import { Edit3, Plus, Trash } from "react-feather";
import contentTypes from "../contentTypesHeurus";
import "../../../fonts";
import { Input } from "lib/components";

const GameMiniature = (props) => {
  const { onClick } = props;

  return (
    <div onClick={onClick} className={styles.miniature}>
      Page de type &quot;Jeux&quot;
    </div>
  );
};

const Game = (props) => {
  const {
    page,
    onEditPage,
    onRemove,
    onAddContent,
    onRemoveContent,
    onEditContent
  } = props;

  if(page.title === "") {
    page.title = "Jeux";
    
    const defaultContents = {
    };
    Object.keys(defaultContents).forEach(field => {
      page[field] = JSON.stringify(defaultContents[field]);
    });
  }
  return (
    <>
      <div className={styles.head}>
        <Input
          placeholder="Demander les réponses à"
          value={page.standfirst}
          onChange={(e) => onEditPage("standfirst", e.target.value)}
          className={styles.input}
        />
      </div>
      <div className={styles.page}>
          <div className={styles.content}>
            <div className={styles.pageTitleContainer}>
              <p className={styles.pageTitle}>LES JEUX</p>
            </div>
            <div className={styles.upperGameRow}>
              {page["1"] &&
                contentTypes.find(
                  (type) => type.slug === JSON.parse(page["1"]).contentType
                ) ? (
                  <div className={`${styles.editoField} ${styles.half}`}>
                    <Trash
                      color="red"
                      size={40}
                      className={styles.trash}
                      onClick={() => onRemoveContent("1")}
                    />
                    <Edit3
                      color="blue"
                      size={40}
                      className={styles.edit}
                      onClick={() => onEditContent({ format: "game", key: "1" })}
                    />
                    {contentTypes
                      .find((type) => type.slug === JSON.parse(page["1"]).contentType)
                      .component({ ...JSON.parse(page["1"]) })}
                  </div>
                ) : (
                  <div
                    className={`${styles.editoField} ${styles.add} ${styles.half}`}
                    onClick={() => onAddContent({ format: "game", key: "1" })}
                  >
                    <Plus size={120} />
                  </div>
                )}
                {page["2"] &&
                contentTypes.find(
                  (type) => type.slug === JSON.parse(page["2"]).contentType
                ) ? (
                  <div className={`${styles.editoField} ${styles.half}`}>
                    <Trash
                      color="red"
                      size={40}
                      className={styles.trash}
                      onClick={() => onRemoveContent("2")}
                    />
                    <Edit3
                      color="blue"
                      size={40}
                      className={styles.edit}
                      onClick={() => onEditContent({ format: "game", key: "2" })}
                    />
                    {contentTypes
                      .find((type) => type.slug === JSON.parse(page["2"]).contentType)
                      .component({ ...JSON.parse(page["2"]) })}
                  </div>
                ) : (
                  <div
                    className={`${styles.editoField} ${styles.add} ${styles.half}`}
                    onClick={() => onAddContent({ format: "game", key: "2" })}
                  >
                    <Plus size={120} />
                  </div>
                )}
              </div>


              {page["3"] &&
              contentTypes.find(
                (type) => type.slug === JSON.parse(page["3"]).contentType
              ) ? (
                <div className={`${styles.editoField}`}>
                  <Trash
                    color="red"
                    size={40}
                    className={styles.trash}
                    onClick={() => onRemoveContent("3")}
                  />
                  <Edit3
                    color="blue"
                    size={40}
                    className={styles.edit}
                    onClick={() => onEditContent({ format: "game", key: "3" })}
                  />
                  {contentTypes
                    .find((type) => type.slug === JSON.parse(page["3"]).contentType)
                    .component({ ...JSON.parse(page["3"]) })}
                </div>
              ) : (
                <div
                  className={`${styles.editoField} ${styles.add}`}
                  onClick={() => onAddContent({ format: "game", key: "3" })}
                >
                  <Plus size={120} />
                </div>
              )}
              
        </div>
        <Trash
          color="red"
          size={40}
          className={styles.bottomTrashIcon}
          onClick={onRemove}
        />
      </div>
    </>
  );
};

const GamePdf = (props) => {
  const { page, ctx, index, contentTypes } = props;
  const pdfStyles = StyleSheet.create({
    container: {
      width: "100%",
      height: "100%",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center"
    },
    title: {
      fontFamily: "Brandon",
      fontSize: "18px",
      fontWeight: "bold",
      textAlign: "center",
      backgroundColor: "#1c2140",
      color: "white",
      width: "150px",
    },
    upperGameRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      maxHeight: "40%",
    },
    gameContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "10px"
    },
    decorator: {
      width: "80%",
      height: "80%",
      position: "absolute",
      bottom: "0px",
      right: "0px",
    },
    standfirst: {
      fontFamily: "Brandon",
      fontSize: "16px",
      textAlign: "right",
      color: "#1c2140",
      width: "100%",
    },
    pageCountContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      backgroundColor: "white",
      borderRadius: 25,
      width: 30,
      height: 30,
      bottom: 5,
      right: 5,
    },
    pageCountText: {
      color: "black",
      fontSize: 24,
      fontWeight: 600,
    },

  });

  return (
    <Page size="A4" orientation="portrait">
      <View style={pdfStyles.container}>
        <Text style={pdfStyles.title}>LES JEUX</Text>
        <View style={pdfStyles.upperGameRow}>
          <View style={pdfStyles.gameContainer}>
            <View style={[pdfStyles.decorator, {backgroundColor: "#e76d90"}]}></View>
            <View style={pdfStyles.image}>
              {page["1"] &&
                contentTypes.find(
                  (type) => type.slug === JSON.parse(page["1"]).contentType
                )
                  ? contentTypes
                    .find(
                      (type) => type.slug === JSON.parse(page["1"]).contentType
                    )
                    .pdf({settings: ctx.settings, ...JSON.parse(page["1"]) })
                  : null}
            </View>
          </View>
          <View style={pdfStyles.gameContainer}>
            <View style={[pdfStyles.decorator, {backgroundColor: "#ec871c"}]}></View>
            <View style={pdfStyles.image}>
              {page["2"] &&
                contentTypes.find(
                  (type) => type.slug === JSON.parse(page["2"]).contentType
                )
                  ? contentTypes
                    .find(
                      (type) => type.slug === JSON.parse(page["2"]).contentType
                    )
                    .pdf({settings: ctx.settings, ...JSON.parse(page["2"]) })
                  : null}
            </View>
          </View>
        </View>
        <View style={pdfStyles.gameContainer}>
          <View style={[pdfStyles.decorator, {backgroundColor: "#1c2140"}]}></View>
          <View style={pdfStyles.image}>
            {page["3"] &&
              contentTypes.find(
                (type) => type.slug === JSON.parse(page["3"]).contentType
              )
                ? contentTypes
                  .find(
                    (type) => type.slug === JSON.parse(page["3"]).contentType
                  )
                  .pdf({settings: ctx.settings, ...JSON.parse(page["3"]) })
                : null}
          </View>
        </View>
         
          <Text style={pdfStyles.standfirst}>{page.standfirst && `Demandez les réponses à ${page.standfirst} !`}</Text>

      </View>
      <View style={pdfStyles.pageCountContainer}>
        <Text style={pdfStyles.pageCountText}>{index + 1}</Text>
      </View>
    </Page>
  );
};

Game.propTypes = {
  page : PropTypes.any,
  onEditPage : PropTypes.any,
  onRemove : PropTypes.any,
  onAddContent : PropTypes.any,
  onRemoveContent : PropTypes.any,
  onEditContent : PropTypes.any,
};
GameMiniature.propTypes = {
  onClick: PropTypes.any,
};
GamePdf.propTypes = {
  page : PropTypes.any,
  ctx : PropTypes.any,
  index : PropTypes.any,
  contentTypes: PropTypes.any,
};

export {Game, GameMiniature, GamePdf};