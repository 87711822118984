import React from "react";
import useUI from "hooks/ui.hook";

import Basic_ProfileIndex from "./Basic/Pages";
import Basic_Residence from "./Basic/Pages/Residence";
import Basic_Profile from "./Basic/Pages/Profile";

import Heurus_Residence from "./Specific/Heurus/Pages/Residence";


const ProfileIndex = (props) => {
  const [ui] = useUI();
  return (<Basic_ProfileIndex {...props} />);
};

const Residence = (props) => {
  const [ui] = useUI();
  if(ui?.groupement?.specific === "heurus") return (<Heurus_Residence {...props} />);
  return (<Basic_Residence {...props} />);
};

const Profile = (props) => {
  const [ui] = useUI();
  return (<Basic_Profile {...props} />);
};



export {
  ProfileIndex,
  Residence,
  Profile,
};