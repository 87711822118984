import React from "react";
import Basic_PrestataireCard from "../Basic/Components/PrestataireCard";
import Basic_ChooseCategorieModal from "./Basic/Components/chooseCategorieModal";
import Basic_CategorieCard from "./Basic/Components/categorieCard";
import Basic_CreatePrestationModal from "./Basic/Components/createPrestationModal";
import Basic_PlanningModal from "./Basic/Components/planningModal";
import Basic_CategoriesCrudService from "./Basic/Components/categoriesCrudService";

const PrestataireCard = (props) => {
    return <Basic_PrestataireCard {...props} />;
};

const ChooseCategorieModal = (props) => {
    return <Basic_ChooseCategorieModal {...props} />;
};

const CategorieCard =(props)=>{
    return <Basic_CategorieCard {...props}/>;
};

const CreatePrestationModal=(props)=>{
    return <Basic_CreatePrestationModal{...props}/>;
};

const PlanningModal=(props)=>{
    return <Basic_PlanningModal{...props}/>;
};

const CategoriesCrudService=(props)=>{
    return <Basic_CategoriesCrudService{...props}/>;
};

export { PrestataireCard,ChooseCategorieModal,CategorieCard, CreatePrestationModal,PlanningModal,CategoriesCrudService};