import React from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";

import styles from "../../assets/scss/component/stats/pieChart.module.scss";


const SatisfactionPlot = ({data1, data2}) => {

    const options = {
        chart: {
          width: "100%",
          height: "100%",
          type: "bar",
          toolbar: {
            show: false,
        }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 2,
            borderRadiusApplication: 'end',
            barHeight: "90%"
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
            xaxis: {
              lines: {
                show: false
              }
            },
            yaxis: {
                lines: {
                  show: false
                }
              }
          },
        xaxis: {
          categories: ["5", "4", "3", "2", "1", "0"],
          labels: {
            show: false
        },
        axisTicks: {
            show: false
        },
        },
        yaxis: {
            max: 100,
        },
        tooltip: {
            enabled: false,
        },
        
      };

      const _list = [];

      const _list1 = ["5", "4", "3", "2", "1", "0"].map(_n => ((data1[_n] / (data1.total === 0 ? 1 : data1.total)) * 100));

      _list.push({
        name: "période actuelle",
        data: _list1,
        color: "#00008B"
        });
      if(data2){
        const _list2 = ["5", "4", "3", "2", "1", "0"].map(_n => ((data2[_n] / (data2.total === 0 ? 1 : data2.total)) * 100));
        _list.push({
            name: "période précédente",
            data: _list2,
            color: "#add8e6"
            });
      }

      // console.log("o", options);s

    return (
      <div className={styles.container}>
        {
          _list.length > 0 ? 
          <Chart
            options={options}
            series={_list}
            type="bar"
            width={options.chart.width}
            height={options.chart.height}
          />
          : "Aucunes données disponibles sur cette période."
        }
      
      </div>
    );
}


SatisfactionPlot.propTypes = {
    data1: PropTypes.any,
    data2: PropTypes.any,
  };
  
  
  export default SatisfactionPlot;