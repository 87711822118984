import React, {useState, useEffect} from "react";
import { Select, Input, Button } from "lib/components";
import { getAllUsers } from "services/ressources/user";
import axios from "axios";
import { toast } from "react-toastify";

const Notifications = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedUser, setSelectedUser] = useState([]);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");


  useEffect(() => {
    (async () => {
      let _users = await getAllUsers();
      console.log(_users);
      _users = _users.map(i => ({
        label: `${i.surname} ${i.name}`,
        value: i.uid
      }));
      setUsers(_users);
    })();
  }, []);

  const _send = async () => {
    if (!selectedUser || !title || !message) return;
    try {
      await axios.post("/notifications", {
        userId: selectedUser.value,
        title: title,
        body: message
      });
      toast.success("Notification envoyée");
    } catch (e) {
      toast.error("Erreur lors de l'envoi de la notification");
    }
  };

  return (
    <>
      <div>
        <Select
          placeholder="users..."
          options={users}
          value={selectedUser}
          onChange={setSelectedUser}
        />
        <Input type="text" placeholder="title..." style={{marginTop: 15}} onChange={e => setTitle(e.target.value)} value={title} />
        <Input type="textarea" placeholder="message..." style={{marginTop: 15, minHeight: 200}} onChange={e => setMessage(e.target.value)} value={message} />
      </div>
      <div style={{display: "flex", justifyContent: "center", marginTop: 10}}>
        <Button onClick={_send}>Envoyer</Button>
      </div>
    </>
  );
};

export default Notifications;