import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  ColorPicker,
  FormGroup,
  Spinner}
 from "lib/components";
import { useAnimationCategories } from "resources/animations";
import styles from "../../../../../../assets/scss/components/homeWidgets/animation_categoriesCrud.module.scss";
import { useServiceManagement } from "../../routerContext";


import { Plus } from "react-feather";

const CategoriesCrudService = () => {
//   const [modalOpen, setModalOpen] = useState(false);
const [ctx] = useServiceManagement();
  const [categoryOpen, setCategoryOpen] = useState(null);
//   const [loading, setLoading] = useState(false);

//   const [error, setError] = useState({});
  const [categories,setCategories]= useState([]);
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("#f2994a");

  useEffect(() => {
    if (!ctx.planning || !ctx.prestataires || !ctx.availableServices) {
      return; }
      setCategories(ctx.availableServices);
     


  },  [ctx.planning, ctx.prestataires, ctx.availableServices]);
//   useEffect(() => {
//     if (categoryOpen) {
//       setTitle(categoryOpen.title);
//       setColor(categoryOpen.color);
//     } else {
//       setTitle("");
//       setColor("#f2994a");
//     }
//   }, [categoryOpen]);

//   const _closeModal = () => {
//     setModalOpen(false);
//     setCategoryOpen(false);
//   };

//   const _onSubmit = async () => {
//     let _error = {};

//     if (!title) _error.title = "required";
//     if (!color) _error.color = "required";

//     setError(_error);
//     if (Object.keys(_error).length > 0) return;

//     setLoading(true);
//     if (categoryOpen) {
//       let res = await updateCategory(categoryOpen.uid, { title, color });
//       if (res) _closeModal();
//     } else {
//       let res = await createCategory({ title, color });
//       if (res) _closeModal();
//     }
//     setLoading(false);
//   };

//   const _onDelete = async () => {
//     if (!window.confirm("Etes vous sur de vouloir supprimer cette catégorie ?")) return;
//     setLoading(true);
//     let res = await removeCategory(categoryOpen.uid);
//     setLoading(false);
//     if (res) _closeModal();
//   };

  return (
    <div className={styles.container}>
      <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
        {categories? categories.map((i) => (
          <span
            key={i.name}
            className={styles.button}
            style={{ backgroundColor: i.color }}
            // onClick={() => {
            //   setCategoryOpen(i);
            //   setModalOpen(true);
            // }}
          >
            {i.name}
          </span>
        )):null}
        {/* <span
          className={styles.button}
          style={{ backgroundColor: "#b9bdc7" }}
          onClick={() => setModalOpen(true)}
        >
          catégorie <Plus size={16} color="white" />
        </span> */}
      </div>

      {/* <Modal isOpen={modalOpen} toggle={_closeModal} size="large">
        <ModalHeader>{categoryOpen ? "Editer une catégorie" : "Ajouter une catégorie"}</ModalHeader>
        {loading ? (
          <ModalBody>
            <Spinner />
          </ModalBody>
        ) : (
          <ModalBody>
            <FormGroup>
              <Input
                type="text"
                label="Titre"
                placeholder="Titre..."
                onChange={(e) => setTitle(e.target.value.slice(0, 18).toLowerCase())}
                value={title}
                invalid={error.title}
                theme="grey"
              />
            </FormGroup>
            <FormGroup
              style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}
            >
              <ColorPicker
                label="Couleur"
                placeholder="Couleur..."
                onChange={setColor}
                value={color}
                invalid={error.color}
                style={{ width: "55%" }}
              />
            </FormGroup>
          </ModalBody>
        )}
        <ModalFooter>
          {categoryOpen && (
            <Button color="danger" onClick={_onDelete}>
              Supprimer
            </Button>
          )}
          <Button color="secondary" onClick={_closeModal}>
            Annuler
          </Button>
          <Button color="primary" onClick={_onSubmit}>
            Enregistrer
          </Button>
        </ModalFooter>
      </Modal> */}
    </div>
  );
};

export default CategoriesCrudService;
