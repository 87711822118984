import React, { useState, useEffect, useMemo, useCallback } from "react";
import useUI from "hooks/ui.hook";
import PropTypes from "prop-types";
import styles from "assets/scss/pages/reservation/weekReservationTable.module.scss";
import moment from "moment";


const DisplayMenuDay = ({ data, uid, week }) => {
  //console.log(data, uid, week);
  const [ui] = useUI();

  let docu = [];
  let orderdRepas = Object.keys(ui.establishment.template).sort((a, b) => ui.establishment.template[a].heure - ui.establishment.template[b].heure);

  const nbLines = Object.keys(ui.establishment.template).length;
  const width = "calc(" + Number.parseInt(80 / nbLines) + "vw - " + Number.parseInt(100 / nbLines) + "px)";
  for (let line = 0; line < 8; line++) {
    let ligne = [];
    for (let column = 0; column < nbLines + 1; column++) {
      let _date = week ? moment(week).startOf("week").add(line - 1, "days") : moment().startOf("week").add(line - 1, "days");
      // Header Jours 
      if (column == 0) {
        if (line > 0) {
          ligne.push(<td key={column + " " + line} className={styles.headerDate}><p>{_date.format("ddd")}</p><p>{_date.format("DD")}</p></td>);
        } else {
          ligne.push(<td key={column + " " + line}></td>);
        }
      } else {
        if (line > 0) { // affichage des menus
          let period = orderdRepas[column - 1];
          if (data[_date.format("YYYY-MM-DD")]?.reservation && data[_date.format("YYYY-MM-DD")]?.reservation[uid] != undefined && data[_date.format("YYYY-MM-DD")].reservation[uid][period] != undefined && data[_date.format("YYYY-MM-DD")].reservation[uid][period].length > 0) {
            const element = data[_date.format("YYYY-MM-DD")];
            const resa = data[_date.format("YYYY-MM-DD")].reservation[uid][period][0];

            let choix = [];
            ui.establishment.template[period].categories.forEach((plat) => {
              //gestion des multi garnitures
              if (plat && resa[plat] && Array.isArray(resa[plat])) {
                let _name = "";
                resa[plat].forEach((_val, _indx) => {
                  if (element && element[period] && element[period][plat] && element[period][plat][_val] && element[period][plat][_val].name) {
                    if (_indx > 0)
                      _name += ", ";

                    _name += element[period][plat][_val].name;
                  }
                });
                if (_name != "")
                  choix.push(_name);

              } else {
                if (plat && resa[plat] && element && element[period] && element[period][plat] && element[period][plat][resa[plat]] && element[period][plat][resa[plat]].name)
                  choix.push(element[period][plat][resa[plat]].name);
              }

            });

            ligne.push(<td key={column + " " + line} className={styles.tableMenu} style={{ width: width }}>
              <ul className={styles.tableMenuDetails}>
                {
                  choix.map(nom => {
                    return <li key={nom}><div>{nom}</div></li>;
                  })
                }
              </ul>
            </td>);
          } else {
            ligne.push(<td key={column + " " + line} className={styles.tableMenu} style={{ width: width }}>
              <div className={styles.tableMenuDetails}></div></td>);
          }

        } else { // affichage des header Verticaux
          if (line == 0) {
            ligne.push(<td key={column + " " + line} className={styles.noBackground}>
              <div className={styles.headerVertical}>
                <b>{orderdRepas[column - 1].toUpperCase()}</b>

              </div>
            </td>);
          }
        }
      }
    }
    docu.push(<tr key={line}>{ligne}</tr>);
  }
  return docu;
};

const WeekReservationTable = ({ _data, uid, week }) => {

  return (
    <div className={styles.tableContainer}>
      {_data && Object.values(_data).length > 0 ?
        <table className={styles.menu}>
          <DisplayMenuDay data={_data} uid={uid} week={week} />
        </table>
        : null}

    </div>
  );
};

WeekReservationTable.propTypes = {
  _data: PropTypes.object.isRequired,
  uid: PropTypes.string.isRequired,
  week: PropTypes.any,
};

export default WeekReservationTable;