import PropTypes from "prop-types";
import {
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import styles from "../styles/health.module.scss";
import useGazette from "../../../context";
import useUI from "hooks/ui.hook";
import { Edit3, Plus, Trash } from "react-feather";
import contentTypes from "../contentTypesHeurus";
import { Input } from "lib/components";
import HealthIllustration from "../assets/HealthIllustration.png";
import "../../../fonts";

const HealthMiniature = (props) => {
  const { onClick } = props;
  return (
    <div onClick={onClick} className={styles.miniature}>
      Page de type &quot;Point Santé&quot;
    </div>
  );
};

const Health = (props) => {
  const {
    page,
    onEditPage,
    onRemove,
    onAddContent,
    onRemoveContent,
    onEditContent,
  } = props;

  const [ctx, dispatch] = useGazette();
  const [ui] = useUI();
  
  // SI cette condition est remplie, on considère que la page vient d'être créée 
  if(page.title === "") {
    page.title = "Santé";
    
    const defaultContents = {
      // "1": {
      //     contentType: "articleHeurus",
      //     format: "health",
      //     title: "Titre de l'article",
      //     content: "Contenu",
      //   },
      // "2": {
      //   contentType: "articleHeurus",
      //   format: "health",
      //   title: "Titre de l'article",
      //   content: "Contenu",
      // }
    };
    Object.keys(defaultContents).forEach(field => {
      page[field] = JSON.stringify(defaultContents[field]);
    });
  }
  return (
    <>
      <div className={styles.head}>
        <Input
          placeholder="Introduction"
          value={page.standfirst}
          onChange={(e) => onEditPage("standfirst", e.target.value)}
          className={styles.input}
        />
      </div>
      <div className={styles.page}>
            <div className={styles.pageTitleContainer}>
              <p className={styles.pageTitle}>Point Santé</p>
            </div>
            <div className={styles.content}>
            {page["1"] &&
              contentTypes.find(
                (type) => type.slug === JSON.parse(page["1"]).contentType
              ) ? (
                <div className={`${styles.editoField}`}>
                  <Trash
                    color="red"
                    size={40}
                    className={styles.trash}
                    onClick={() => onRemoveContent("1")}
                  />
                  <Edit3
                    color="blue"
                    size={40}
                    className={styles.edit}
                    onClick={() => onEditContent({ format: "health", key: "1" })}
                  />
                  {contentTypes
                    .find((type) => type.slug === JSON.parse(page["1"]).contentType)
                    .component({ ...JSON.parse(page["1"]) })}
                </div>
              ) : (
                <div
                  className={`${styles.editoField} ${styles.add}`}
                  onClick={() => onAddContent({ format: "health", key: "1" })}
                >
                  <Plus size={120} />
                </div>
              )}
            {page["2"] &&
              contentTypes.find(
                (type) => type.slug === JSON.parse(page["2"]).contentType
              ) ? (
                <div className={`${styles.editoField}`}>
                  <Trash
                    color="red"
                    size={40}
                    className={styles.trash}
                    onClick={() => onRemoveContent("2")}
                  />
                  <Edit3
                    color="blue"
                    size={40}
                    className={styles.edit}
                    onClick={() => onEditContent({ format: "health", key: "2" })}
                  />
                  {contentTypes
                    .find((type) => type.slug === JSON.parse(page["2"]).contentType)
                    .component({ ...JSON.parse(page["2"]) })}
                </div>
              ) : (
                <div
                  className={`${styles.editoField} ${styles.add}`}
                  onClick={() => onAddContent({ format: "health", key: "2" })}
                >
                  <Plus size={120} />
                </div>
              )}
            </div>
        <Trash
          color="red"
          size={40}
          className={styles.bottomTrashIcon}
          onClick={onRemove}
        />
      </div>
    </>
  );
};

const HealthPdf = (props) => {
  const { page, ctx, index, contentTypes } = props;
  const pdfStyles = StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    header: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
      backgroundColor: "#1c2140",
      padding: "20px",
    },
    title: {
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: "18px",
      color: "white",
      width: "150px",
      padding: "5px",
      textAlign: "center",
      backgroundColor: "#ec871c",
    },
    intro: {
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: "14px",
      color: "white",
    },
    illustration: {
      position: "absolute",
      bottom: "25px",
      left: "25px",
      width: "75px",
      height: "75px",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      width: "95%",
      overflow: "hidden",
      justifyContent: "space-evenly",
      height: "100%",
    },
    contentItem: {
      maxHeight: "45%",
      overflow: "hidden",
    },
    pageCountContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      backgroundColor: "white",
      borderRadius: 25,
      width: 30,
      height: 30,
      bottom: 5,
      right: 5,
    },
    pageCountText: {
      color: "black",
      fontSize: 24,
      fontWeight: 600,
    },
  });

  return (
    <Page size="A4" orientation="portrait">
      <View style={pdfStyles.container}>
        <View style={pdfStyles.header}>
            <Text style={pdfStyles.title}>POINT SANTE</Text>
            <Text style={pdfStyles.intro}>{page.standfirst}</Text>
        </View>
        <View style={pdfStyles.content}>
          <View style={pdfStyles.contentItem}>
            {page["1"] &&
              contentTypes.find(
                (type) => type.slug === JSON.parse(page["1"]).contentType
              )
                ? contentTypes
                  .find(
                    (type) => type.slug === JSON.parse(page["1"]).contentType
                  )
                  .pdf({settings: ctx.settings, ...JSON.parse(page["1"]) })
                : null}
          </View>
          <View style={pdfStyles.contentItem}>
            {page["2"] &&
              contentTypes.find(
                (type) => type.slug === JSON.parse(page["2"]).contentType
              )
                ? contentTypes
                  .find(
                    (type) => type.slug === JSON.parse(page["2"]).contentType
                  )
                  .pdf({settings: ctx.settings, ...JSON.parse(page["2"]) })
                : null}
          </View>
        </View>
        <Image style={pdfStyles.illustration} src={HealthIllustration}/>
      </View>
      <View style={pdfStyles.pageCountContainer}>
        <Text style={pdfStyles.pageCountText}>{index + 1}</Text>
      </View>
    </Page>
  );
};

Health.propTypes = {
  page : PropTypes.any,
  onEditPage : PropTypes.any,
  onRemove : PropTypes.any,
  onAddContent : PropTypes.any,
  onRemoveContent : PropTypes.any,
  onEditContent : PropTypes.any,
};
HealthMiniature.propTypes = {
  onClick : PropTypes.any,
};
HealthPdf.propTypes = {
  page : PropTypes.any,
  ctx : PropTypes.any,
  index : PropTypes.any,
  contentTypes: PropTypes.any,
};

export { Health, HealthMiniature, HealthPdf };