import React, {forwardRef} from "react";
import PropTypes from "prop-types";
import RDatePicker from "react-datepicker";
import { registerLocale }  from "react-datepicker";
import fr from "date-fns/locale/fr";
import { Input as InputBootstrap } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import styles from "../../assets/scss/component/datepicker.module.scss";
import moment from "moment";

registerLocale("fr", fr);

const DatePicker = (props) => {
  const {value, onChange, label, invalid, theme, style, placeholder} = props;

  const formatDate = () => {
    return value ? moment(value).format("DD/MM/YYYY") : null;
  };

  const _onKeyDown = (e) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      onChange("");
    }
  };

  const isDateValid = (dateString) => {
    const formats = ["DD/MM/YYYY"];
    return formats.some((format) => moment(dateString, format, true).isValid())
  }

  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <InputBootstrap 
      onKeyDown={_onKeyDown} 
      onClick={onClick} 
      ref={ref} 
      value={formatDate(value)} 
      onChange={(e) => {
        isDateValid(e.target.value) && onChange(e); 
      }}
      placeholder={placeholder} 
    />
  ));

  CustomInput.displayName = "CustomInput";
  CustomInput.propTypes = {
    value : PropTypes.any,
    onClick : PropTypes.func,
  };
  return (
    <div className={`${styles.datePicker} ${styles[theme]}`} style={style ?? {}}>
      {label && <label>{label}:</label>}
      <RDatePicker selected={value}
        onChange={onChange}
        customInput={<CustomInput />}
        dateFormat="P"
        locale="fr" 
        dropdownMode="select"
        showMonthDropdown
        showYearDropdown
        adjustDateOnChange
        popperClassName={styles.datePickerPopper}
        />
      {invalid && typeof(invalid) === "string" && <span style={{color: "red"}}>{invalid}</span>}
    </div>
  );
};

DatePicker.propTypes = {
  value : PropTypes.any,
  onChange : PropTypes.func,
  label : PropTypes.string,
  invalid : PropTypes.any,
  theme : PropTypes.any,
  style : PropTypes.any,
  placeholder: PropTypes.string,
};

export default DatePicker;