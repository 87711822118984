import React from "react";
import PropTypes from "prop-types";

import styles from "../../assets/scss/component/card.module.scss";

const Card = (props) => {
  const {
    color
  } = props;

  return (
    <div {...props} className={`${styles.card} ${props.className ?? ""} ${color === "grey" ? styles.grey : 
      color === "white" ? styles.white : styles.border}`}>
      {props.children}
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  color: PropTypes.oneOf(["border", "grey", "white"])
};

Card.defaultProps = {
  color: "grey"
};

export default Card;