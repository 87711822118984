import React from "react";
import PropTypes from "prop-types";
import PolymerForm, {PolymerField, PolymerSubmit} from "../../lib/polymer-form";
import {Card, FormGroup} from "../components";
import * as Yup from "yup";
import Logo from "../assets/image/HydrogenLogo.png";

import styles from "../assets/scss/pages/login.module.scss";

const MailPasswordLogin = (props) => {
  const {onSubmit} = props;

  const initialValues = {
    mail: "",
    password: ""
  };

  const validationSchema = Yup.object({

  });

  return (
    <div className={styles.loginPage}>
      <img src={Logo} />
      <Card className="wd-400">
        <h1>Se connecter</h1>
        <PolymerForm initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          <FormGroup>
            <PolymerField type="text" name="mail" label="Mail" placeholder="email..." labelClassName="f-c-dark-grey" />
          </FormGroup>
          <FormGroup>
            <PolymerField type="password" name="password" label="Mot de passe" placeholder="mot de passe..." labelClassName="f-c-dark-grey" />
          </FormGroup>
          <FormGroup>
            <PolymerSubmit className="wd-100-p m-t-10">Se connecter</PolymerSubmit>
          </FormGroup>
        </PolymerForm>
      </Card>
    </div>
  );
};

MailPasswordLogin.propTypes = {
  onSubmit  : PropTypes.func.isRequired,
};

export default MailPasswordLogin;