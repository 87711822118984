import { Button, Card } from "../../../../../lib/components";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import useUI from "hooks/ui.hook";
import { ExportDay } from "../../router_pdf";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import sleep from "../../../../../helpers/asyncWait";

const MenuWeekExport = () => {
  const history = useHistory();
  const location = useLocation();
  const params = location.state;
  const [counter, setCounter] = useState(5);
  const dlButton = useRef(null);
  const [ui] = useUI();

  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    (async () => {
      await sleep(2000);
      if (dlButton.current) {
        dlButton.current.click();
        setRedirect(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (!redirect) return;

    let interval = setInterval(() => {
      setCounter(counter - 1);
      if (counter === 0) {
        history.push("/dashboard/menu");
        clearInterval(interval);
      }
    }, 1000);
  }, [redirect, counter]);

  return (
    <Card
      style={{
        width: "100%",
        height: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          color="secondary"
          onClick={() => history.goBack()}
          style={{ marginRight: 5 }}
        >
          Retour
        </Button>
        <p style={{ marginTop: 10 }}>
          Votre fichier est en cours de téléchargement. Vous allez être redirigé
          dans {counter >= 0 ? counter : 0}
        </p>
      </div>
      <p>
        <span>Si le téléchargement ne commence pas automatiquement </span>
        {/* <PDFViewer style={{width: "80vw", height: "70vh"}}>
        <PDF {...params} ui={ui} />
        </PDFViewer> */}
        <PDFDownloadLink document={<ExportDay {...params} ui={ui} />} fileName="menu.pdf">
          {({ blob, url, loading, error }) =>
            loading ? (
              "Loading document..."
            ) : (
              <>
                <span ref={dlButton}>Cliquez ici</span>
              </>
            )
          }
        </PDFDownloadLink>
      </p>
    </Card>
  );
};

export default MenuWeekExport;
