import PropTypes from "prop-types";
import {
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import styles from "../styles/monthAnimation.module.scss";
import useUI from "hooks/ui.hook";
import { Edit3, Plus, Trash } from "react-feather";
import contentTypes from "../contentTypesHeurus";
import "../../../fonts";


const MonthAnimationMiniature = (props) => {
  const { onClick } = props;
  return (
    <div onClick={onClick} className={styles.miniature}>
      Page de type &quot;Animations du Mois&quot;
    </div>
  );
};

const MonthAnimation = (props) => {
  const {
    page,
    onEditPage,
    onRemove,
    onAddContent,
    onRemoveContent,
    onEditContent,
  } = props;

  const [ui] = useUI();
  
  // SI cette condition est remplie, on considère que la page vient d'être créée 
  if(page.title === "") {
    page.title = "Animations du mois";
    
    const defaultContents = {
      // "1": {
      //     contentType: "animation",
      //     format: "animation",
      //     selectedAnimations: [],
      //   }
    };
    Object.keys(defaultContents).forEach(field => {
      page[field] = JSON.stringify(defaultContents[field]);
    });
  }

  return (
    <>
      <div className={styles.head}></div>
      <div className={styles.page}>
            <div className={styles.pageTitleContainer}>
              <p className={styles.pageTitle}>LES ANIMATIONS DU MOIS</p>
            </div>
            <div className={styles.content}>
            {page["1"] &&
              contentTypes.find(
                (type) => type.slug === JSON.parse(page["1"]).contentType
              ) ? (
                <div className={`${styles.editoField}`}>
                  <Trash
                    color="red"
                    size={40}
                    className={styles.trash}
                    onClick={() => onRemoveContent("1")}
                  />
                  <Edit3
                    color="blue"
                    size={40}
                    className={styles.edit}
                    onClick={() => onEditContent({ format: "animation", key: "1" })}
                  />
                  {contentTypes
                    .find((type) => type.slug === JSON.parse(page["1"]).contentType)
                    .component({ ...JSON.parse(page["1"]) })}
                </div>
              ) : (
                <div
                  className={`${styles.editoField} ${styles.add}`}
                  onClick={() => onAddContent({ format: "animation", key: "1" })}
                >
                  <Plus size={120} />
                </div>
              )}
            </div>
        <Trash
          color="red"
          size={40}
          className={styles.bottomTrashIcon}
          onClick={onRemove}
        />
      </div>
    </>
  );
};

const MonthAnimationPdf = (props) => {
  const { page, ctx, index, contentTypes } = props;
  const pdfStyles = StyleSheet.create({
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "15px",
      width: "100%",
      height: "100%",
    },
    page: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      margin: "10px",
      backgroundColor: "#e7e6e6"
    },
    bottomDecorator: {
      position: "absolute",
      backgroundColor: "#ec871c",
      bottom: "0px",
      left: "0px",
      width: "50%",
      height: "20%",
    },
    pageDecorator: {
      border: "1px solid white",
      width: "525px",
      height: "757px",
      margin: "20px",
      position: "absolute",
    },
    header: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignSelf: "center",
      alignItems: "center",
      backgroundColor: "#1c2140",
      paddingTop: "50px",
      paddingHorizontal: "20px",
      paddingBottom: "5px",
      width: "100%"
    },
    title: {
      width: "250px",
      color: "white",
      backgroundColor: "#e76d90",
      paddingVertical: "5px",
      fontSize: "18px",
      fontFamily: "Brandon",
      fontWeight: "bold",
      textAlign: "center",
      position: "absolute",
      left: "50%",
      top: "5px",
      transform: "translate(-130%, 0%)",
    },
    introContainer: {
      display: "flex",
      flexDirection: "column",
      width: "80%"
    },
    intro: {
      color: "white",
      fontFamily: "Brandon",
      fontSize: "14px",
      fontWeight: "bold"
    },
    pinkText: {
      color: "#e76d90",
      fontFamily: "Brandon",
      fontSize: "14px",
      fontWeight: "bold"
    },
    content: {
      marginTop: "5px",
      alignSelf : "center",
      width: "90%",
      height: "85%",
    },
    pageCountContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      backgroundColor: "white",
      borderRadius: 25,
      width: 30,
      height: 30,
      bottom: 5,
      right: 5,
    },
    pageCountText: {
      color: "black",
      fontSize: 24,
      fontWeight: 600,
    },
  });
  return (
    <Page size="A4" orientation="portrait">
      <View style={pdfStyles.container}>
        <View style={pdfStyles.bottomDecorator}></View>
        <View style={pdfStyles.page}>
          
          <View style={pdfStyles.header}>
              <View style={pdfStyles.introContainer}>
              <Text   style={pdfStyles.pinkText}>N&apos;oubliez pas : <Text style={pdfStyles.intro}>l&apos;animatrice propose des accompagnements aux courses, il vous suffit juste de vous inscrire à l&apos;accueil</Text></Text>
              </View>
          </View>
          <View style={pdfStyles.content}>
          {page["1"] &&
            contentTypes.find(
              (type) => type.slug === JSON.parse(page["1"]).contentType
            )
              ? contentTypes
                .find(
                  (type) => type.slug === JSON.parse(page["1"]).contentType
                )
                .pdf({cardBackgroundColor: "white", settings: ctx.settings, categoriesEvent: ctx.categoriesEvent, ...JSON.parse(page["1"]) })
              : null}
          </View>
          <View style={pdfStyles.pageDecorator}></View>
          <Text style={pdfStyles.title}>LES ANIMATIONS DU MOIS</Text>
          
        </View>
      </View>
      <View style={pdfStyles.pageCountContainer}>
        <Text style={pdfStyles.pageCountText}>{index + 1}</Text>
      </View>
    </Page>
  );
};

MonthAnimation.propTypes = {
  page : PropTypes.any,
  onEditPage : PropTypes.any,
  onRemove : PropTypes.any,
  onAddContent : PropTypes.any,
  onRemoveContent : PropTypes.any,
  onEditContent : PropTypes.any,
};
MonthAnimationMiniature.propTypes = {
  onClick : PropTypes.any,
};
MonthAnimationPdf.propTypes = {
  page : PropTypes.any,
  ctx : PropTypes.any,
  index : PropTypes.any,
  contentTypes: PropTypes.any,
};

export { MonthAnimation, MonthAnimationMiniature, MonthAnimationPdf };