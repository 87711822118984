import React from "react";
import RSelect from "react-select";
import PropTypes from "prop-types";

const MultiSelect = (props) => {
  const {label, options, onChange, value, clearable, disabled, invalid, placeholder} = props;

  return (
    <>
      {label &&
        <label>
          {label}:
        </label>
      }
      <RSelect onChange={onChange}
        value={value}
        options={options}
        isClearable={clearable}
        isDisabled={disabled}
        placeholder={placeholder}
        isSearchable
        isMulti
      />
      {invalid && typeof(invalid) === "string" ?
        <span style={{color: "red"}}>{invalid}</span>
        : ""}
    </>
  );
};

MultiSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  onChange : PropTypes.any,
  value : PropTypes.any,
  clearable : PropTypes.any,
  disabled : PropTypes.any,
  invalid : PropTypes.any,
  placeholder : PropTypes.any,
};

MultiSelect.defaultProps = {
  clearable: false,
  disabled: false
};

export default MultiSelect;