import React, {useState} from "react";
import {Button} from "lib/components";
import firebase from "firebase";
import { getAllGroupements } from "services/ressources/groupement";
import { getAllEtablishments } from "services/ressources/establishment";

const firestore = firebase.firestore;

const EtabXgroupement = () => {
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);

  const test = async () => {
    setReport([]);
    setLoading(true);

    //test Etab -> Groupement liaison
    let _etabs = await getAllEtablishments();
    let _groupements = await getAllGroupements();

    setReport(prev => [...prev, "<span style='font-weight: 800'>test etab -> groupement</span>"]);
    _etabs.forEach(etab => {
      if (!etab.groupement) return setReport(prev => [...prev, `<span style="color: blue;">etab ${etab.name}: no groupement</span>`]);
      const groupement = _groupements.find(g => g.uid === etab.groupement);

      if (!groupement) return setReport(prev => [...prev, `<span style="color: red;">etab ${etab.name}: groupement not found</span>`]);
      if (!groupement.establishments.includes(etab.uid)) return setReport(prev => [...prev, `<span style="color: red;">etab ${etab.name}: groupement ${groupement.name} does not include etab ${etab.uid}</span>`]);

      setReport(prev => [...prev, `<span style="color: green;">etab ${etab.name} -> groupement ${groupement.name}: OK</span>`]);
    });

    setReport(prev => [...prev, "<span style='font-weight: 800'>test groupement -> etab</span>"]);

    _groupements.forEach(groupement => {
      if (!groupement.establishments.length) return setReport(prev => [...prev, `<span style="color: orange;">groupement ${groupement.name}: no establishments</span>`]);
      groupement.establishments.forEach(uid => {
        const etab = _etabs.find(e => e.uid === uid);
        if (!etab) return setReport(prev => [...prev, `<span style="color: red;">groupement ${groupement.name}: etab ${uid} not found</span>`]);
        if (etab.groupement !== groupement.uid) return setReport(prev => [...prev, `<span style="color: red;">groupement ${groupement.name}: etab ${etab.name} not linked to groupement ${groupement.uid}</span>`]);

        setReport(prev => [...prev, `<span style="color: green;">groupement ${groupement.name} -> etab ${etab.name}: OK</span>`]);
      });
    });

    setLoading(false);
  };

  return (
    <div>
      <Button onClick={test} loading={loading}>launch test</Button>
      {
        report.map((r, i) => (
          <p key={i} dangerouslySetInnerHTML={{__html: r}} />
        ))
      }
    </div>
  );
};

export default EtabXgroupement;