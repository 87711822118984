import React from "react";
import { useParams } from "react-router-dom";
import useCultureQuizzInstance from "hooks/useCultureQuizzInstance";

import styles from "./questionResult.module.scss";
import {Circle, Square, Star ,Triangle } from "react-feather";
import * as c from "./colors";

const QuestionResult = () => {
  const params = useParams();
  const {
    instance,
    question,
    participants
  } = useCultureQuizzInstance(params.uid);

  if(instance == undefined)return <></>;
  if(!question)return <></>;

  let Icons = null;
  let questionArray = null;
  if(question.type == "multiple_choice"){
    Icons =  [<Circle key="circle" stroke='#FFFFFF' width={35} height={35} fill="#FFFFFF"/>,
      <Square key="square" stroke='#FFFFFF' width={35} height={35} fill="#FFFFFF"/>,
      <Star key="star" stroke='#FFFFFF' width={35} height={35} fill="#FFFFFF"/>,
      <Triangle key="triangle" stroke='#FFFFFF' width={35} height={35} fill="#FFFFFF"/>];
    questionArray = question.answers;
  }else{
    Icons = [<Circle key="circle" stroke='#FFFFFF' width={35} height={35} fill="#FFFFFF"/>,
      <Triangle key="triangle" stroke='#FFFFFF' width={35} height={35} fill="#FFFFFF"/>];
    questionArray = ["true", "false"];
  }
  let answers = {};
  if(instance.answers != undefined)
    answers = instance.answers[instance.currentQuestion];
  let scores = [];
  let total = 0;

  for(let i = 0 ; i <questionArray.length; i++){
    scores.push(0);
  }

  if(answers){
    Object.entries(answers).forEach(([,value]) => {
      if(question.type == "true_false"){
        let index = 0 + !value.answer;
        scores[index] ++;
      }else{
        scores[value.answer] ++;
      }
      total++;
    });}

  
  const displayBlock = (index) => {
    let percent = 0;
    if(scores[index])
      percent = scores[index] / total * 100;
    return(
      <div className={styles.blockContainer}>
        <div className={styles.scoreGraph} style={{background: "linear-gradient(0deg, "+c.COLOR_PALETTE_2[index]+" 0%, "+c.COLOR_PALETTE_2[index]+" "+percent+"%, rgba(0,0,0,0) "+percent+"%)"}}>
        </div>
        <div className={styles.scoreInfos} style={{backgroundColor: c.COLOR_PALETTE_1[index]}}>
          {Icons[index]}
          <div className={styles.scoreText}>{scores[index]}</div>
          <div></div>
        </div>
      </div>);
  };

  const DisplayScore = () => {
    let retour = [];
    if(question){
      for(let i = 0 ; i <questionArray.length; i++){
        retour.push(displayBlock(i));
      }
    }
    return retour;
  };
 
  return (
    <div className={styles.scoreContainer}>
      {participants.length > 0 ? <DisplayScore/> : null}
    </div>
  );
};

export default QuestionResult;