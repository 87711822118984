import React from "react";
import PropTypes from "prop-types";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";

import Roboto from "../../../../../../fonts/roboto/Roboto-Condensed.ttf";
import RobotoBold from "../../../../../../fonts/roboto/Roboto-BoldCondensed.ttf";

import Calibri from "../../../../../../fonts/calibri/calibri-regular.ttf";
import CalibriBold from "../../../../../../fonts/calibri/calibri-bold.ttf";

import Brandon from "../../../../../../fonts/brandon/BrandonGrotesque-Regular.ttf";
import BrandonBold from "../../../../../../fonts/brandon/BrandonGrotesque-Bold.ttf";

import CenturyGothic from "../../../../../../fonts/centuryGothic/CenturyGothic.ttf";
import CenturyGothicBold from "../../../../../../fonts/centuryGothic/CenturyGothicBold.ttf";


const allergenes = [
  "Soja et produit à base de soja.",
  "Poisson et produits à base de poisson.",
  "Mollusques.",
  "Crustacés et produits à base de crustacés.",
  "Graines de sésames et produits à base de sésame.",
  "Céréales contenant du gluten : blé, seigle épeautre, kamut et les produits à base de ces céréales.",
  "Œuf et produits à base d'œuf.",
  "Lupin",
  "Fruits à coque : amande, noisette, noix de cajou, noix de pécan, noix du brésil, pistache, noix de macadamia et produits à base de ces fruits.",
  "Lait et produit à base de lait.",
  "Arachide et produits à base d'arachide.",
  "Anhydride sulfureux et sulfites en concentration de plus de 10mg/kg ou mg/l exprimés en SO2.",
  "Moutarde et produit à base de moutarde.",
  "Céleri et produits à base de céleri",
];

Font.register({
  family: "Brandon",
  fontWeight: "normal",
  src: Brandon,
});
Font.register({
  family: "Brandon",
  format: "bold",
  src: BrandonBold,
});
Font.register({
  family: "Roboto",
  fontWeight: "normal",
  src: Roboto,
});
Font.register({
  family: "Roboto",
  format: "bold",
  src: RobotoBold,
});
Font.register({
  family: "Calibri",
  fontWeight: "normal",
  src: Calibri,
});
Font.register({
  family: "Calibri",
  format: "bold",
  src: CalibriBold,
});

Font.register({
  family: "CenturyGothic",
  fontWeight: "normal",
  src: CenturyGothic,
});
Font.register({
  family: "CenturyGothic",
  format: "bold",
  src: CenturyGothicBold,
});

const styles = StyleSheet.create({
  page: {
    paddingTop: 15,
    paddingHorizontal: 15,
    display: "flex",
    flexDirection: "column",
    height: 595,
  },
  pageTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 10,
  },
  pageTitle: {
    fontFamily: "Brandon",
    color: "#0d1c41",
    fontSize: 25,
    fontWeight: "bold",
  },
  pageTitleDate: {
    fontFamily: "CenturyGothic",
    color: "#00448a",
    fontSize: 25,
    fontWeight: "bold",
  },
  //--------------------------  -------------------------------//
  weekContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    flex: 3
  },
  weekDayContainer: {
    border: "1px solid black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  weekDayTitle: {
    color: "white",
    backgroundColor: "#74bb8a",
    width: "100%",
    padding: 5,
    textAlign: "center",
    fontFamily: "CenturyGothic",
  },
  weekDayContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  dish: {
    fontSize: 13,
    fontFamily: "CenturyGothic",
    textAlign: "center",
    fontWeight: "bold",
    color: "#1c2140",
  },
  // orText: {
  //   fontSize: 11,
  //   color: "#E46D8E",
  //   fontStyle:"bold"
  // },
  //--------------------------  -------------------------------//
  allergenesContainer: {
    paddingTop: 15,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    marginBottom: 50,
  },
  allergenesTitle:{
    backgroundColor: "#00448a",
    color: "white",
    fontWeight: "bold",
    fontSize: 11,
    padding: 5,
    marginBottom: 5,
    fontFamily: "CenturyGothic"
  },
  allergenesItemContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignContent: "space-between",
    height: 120,
    width: "100%",
    gap: 1,
  },
  allergeneItem: {
    fontSize: 11,
    width: "49%",
  },
  allergeneIndex: {
    fontSize: 10,
  },
  //--------------------------  -------------------------------//
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 1,
  },
  footer1: {
    flex: 6
  },
  footer1Text: {
    fontFamily: "CenturyGothic",
    fontSize: 18,
    color: "#1c2140",
    fontWeight: "bold",
  },
  footer2: {
    flex: 4,
    backgroundColor: "#74bb8a",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 10,
  },
  footer2Text: {
    color: "white",
    fontFamily: "CenturyGothic",
    fontWeight: "bold",
  },
  logo: {
    flex: 2,
    width: 150,
    height: 80
  }

});

const allergenesIndexDisplay = (dish, _indxDish, array) => {
  const regex = /(.*)\((\d+)\)/;
  const match = dish.match(regex);

  if (match) {
    return (
      <>
        <Text key={_indxDish} style={styles.dish}>
          {match[1]}
          <Text style={styles.allergeneIndex}>({match[2]})</Text>
        </Text>
        {/* {(_indxDish < array.length - 1) && (
          <Text style={styles.orText}>{"ou"}</Text> 
        )} */}
      </>
    );
  } else {
    return (
      <>
        <Text key={_indxDish} style={styles.dish}>{dish}</Text>
        {/* {(_indxDish < array.length - 1) && (
          <Text style={styles.orText}>{"ou"}</Text> 
        )} */}
      </>
    );
  }
};

const MarpaLeCanalet = (props) => {
  const { week, menuState, logo, ui, data } = props;
  const template = ui.establishment.template;
  
  const _repas = Object.keys(template).sort((a,b)=> template[a].heure - template[b].heure );
  const {Dej, Din} = _repas;

  console.log(_repas);
  console.log(week);
  console.log(template);
  console.log(menuState);

  /* On peut filtrer les plats en fonction de "origin" pour n'avoir que les plats du jour
  *  Exemple: dish.origin === "Menu du jour" ? allergenesIndexDisplay(dish.name, index, array) : null
  */
  return (
    <Document>
      {_repas.map((_repas) => (
        <Page size="A4" orientation="landscape" key={_repas}>
          <View style={styles.page}>
            <View style={styles.pageTitleContainer}>
              <Text style={styles.pageTitle}>{_repas} du </Text>
              <Text style={styles.pageTitleDate}>{moment(week[0]).format("DD")} au {moment(week[6]).format("DD MMMM YYYY")}</Text>
            </View>
            <View style={styles.weekContainer}>
              {week.map((date, index) => (
                <View style={styles.weekDayContainer} key={moment(date).format()}>
                  <Text style={styles.weekDayTitle}>{moment(date).format("dddd")}</Text>
                  <View style={styles.weekDayContent}>
                    {template[_repas].categories.map((category, index) => (
                      <>
                        {Object.values(menuState[moment(date).format("YYYY-MM-DD")][_repas][category])
                        .sort((a,b) => a.order - b.order)
                        .map((dish, index, array) => (
                          allergenesIndexDisplay(dish.name, index, array)
                        ))}
                        {template[_repas].categories.length - 1 !== index &&
                          <Text>-</Text>
                        }
                      </>
                    ))}
                  </View>
                </View>
              ))}
            </View>
            <View style={styles.allergenesContainer}>
              <Text style={styles.allergenesTitle}>Liste des allergènes : Marpa Canalet</Text>
              <View style={styles.allergenesItemContainer}>
                {allergenes.map((_allergene, index) => (
                  <Text key={index} style={styles.allergeneItem}>({index + 1}) {_allergene}</Text>
                ))}
              </View>
            </View>
            <View style={styles.footerContainer}>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};


MarpaLeCanalet.propTypes = {
  week: PropTypes.array.isRequired,
  menuState: PropTypes.object.isRequired,
  logo: PropTypes.string,
  ui: PropTypes.any,
  data: PropTypes.any,
};

export default MarpaLeCanalet;
