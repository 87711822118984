import React from "react";
import PropTypes from "prop-types";
import styles from "../Scss/categorieCard.module.scss";


const CategorieCard = (props) =>{
    const{
        data,
        onClick,
    } = props;

    return(
        <div className={styles.cardContainer} onClick={onClick}>
            <div className={styles.cardBody}style={{backgroundColor: data.color}}>
               <div className={styles.name}>{data.name}</div>
            </div>
           
        </div>
    );
};

CategorieCard.propTypes={
    data: PropTypes.object,
    onClick:PropTypes.func,
};
export default CategorieCard;