import React, {useState, useEffect} from "react";
import useUI from "./ui.hook";
import { getQuizzesByEstablishment, getQuizzesByGroupement, getQuizzesByRegion, getQuizzesShareWithEstablishment, getQuizzesShareWithGroupement, getQuizzById, updateQuizz } from "services/ressources/quizz";
import { getEstablishmentById } from "services/ressources/establishment";
import { getGroupementById } from "services/ressources/groupement";


// fonctionnement des scopes
// cette structure doit permettre la possibilité d'utiliser les clause where arrayIn et arrayContains
/*
  stocké dans 
    quizzId.shareEtab : [etabId, permCode, etabId, permCode, ...]
    quizzId.shareRegion : [GroupementId-regionCode, permCode, GroupementId-regionCode, permCode, ...]
    quizzId.shareGlobal : [groupementId, permCode]

  si share<type> pas présent, alors le quizz est accessible uniquement en local

  le permCode est un code qui permet de définir les droits d'accès au quizz selon un système a bitmasking
*/

export const READ  = 0x10000000;
export const WRITE = 0x01000000;
export const OWNER = 0x00100000;

const useCultureQuizz = (noFetch) => {
  const [ui] = useUI();
  const [quizzes, setQuizzes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchQuizzes();
  }, []);

  const fetchQuizzes = async () => {
    if (noFetch) return;
    setLoading(true);
    let _quizzes = [];

    //TODO
    //  .collection("quizz")
    //  .where("superadmin", "==", true)
    //  .get();
    //resAdmin.forEach((doc) => _quizzes.push({ uid: doc.id, ...doc.data()}));

    if (ui?.establishment?.uid) { // cas d'un owner controllant un établissement
      const restLocal = await getQuizzesByEstablishment({ui});
      restLocal.forEach((doc) => _quizzes.push(doc));
    } else if (ui?.user?.role === "regionAdmin") { // cas d'un admin de région
      const restLocal = await getQuizzesByRegion({ui});
      restLocal.forEach((doc) => _quizzes.push(doc));
    } else if (ui?.user?.role === "groupementAdmin") { // cas d'un admin de groupement
      const restLocal = await getQuizzesByGroupement({ui});
      restLocal.forEach((doc) => _quizzes.push(doc));
    }

    if (ui?.establishment?.uid) {
      const restEst = await getQuizzesShareWithEstablishment({ui});
      restEst.forEach((doc) => _quizzes.push(doc));
    }

    if ((ui?.establishment?.groupement && ui?.establishment?.region) || ui?.user?.role === "regionAdmin") {//etq etablissement, on récupère les partages region
      const restReg = await getQuizzesByRegion({ui});
      restReg.forEach((doc) => _quizzes.push(doc));
    }

    if (ui?.establishment?.groupement) {
      const restGlob = await getQuizzesShareWithGroupement({ui});
      restGlob.forEach((doc) => _quizzes.push(doc));
    }

    //fill shareby
    _quizzes = await Promise.all(_quizzes.map(async (q) => {
      if (checkRights(q.uid, OWNER)) return q; //si owner, on ne fait rien
      if (q.establishment) {
        const _est = await getEstablishmentById({id: q.establishment});
        const {id, ...est} = _est;
        return {...q, sharedBy: est?.name};
      } else if (q.region) {
        return {...q, sharedBy: q.region.split("-")[1]};
      } else if (q.groupement) {
        const _groupement = getGroupementById({id: q.groupement});
        const {id, ...groupement} = _groupement;
        return {...q, sharedBy: groupement?.name};
      } else { //superadmin
        return {...q, sharedBy: "jabi"};
      }
    }));

    //remove duplicates
    _quizzes = _quizzes.filter((v,i,a)=>a.findIndex(t=>(t.uid === v.uid))===i);

    setQuizzes(_quizzes);
    setLoading(false);
  };

  const share = async (
    quizzId,
    shareType, //etab, region, global
    shareTo, //etabId, regionId, groupementId
    sharePerm, //permcode
  ) => {
    const quizz = await getQuizzById({id: quizzId});
    if (!quizz) return false;

    let shareField = `share${((shareType) => shareType.charAt(0).toUpperCase() + shareType.slice(1))(shareType)}`;
    let shareArray = quizz[shareField] || [];

    //parse shareArray, group by [id, perm]
    shareArray = shareArray.reduce((acc, val, i, arr) => [...acc, ...(i % 2 === 0 ? [[val, arr[i+1]]] : [])], []);

    //check if already shared
    const alreadyShared = shareArray.find((el) => el[0] === shareTo);
    if (alreadyShared) {
      if (alreadyShared[1] === sharePerm) return;
      else {
        shareArray = shareArray.filter((el) => el[0] !== shareTo);
      }
    }

    shareArray.push([shareTo, sharePerm]);
    shareArray = shareArray.reduce((acc, val) => acc.concat(val), []);


    await updateQuizz({id: quizzId, data: {[shareField]: shareArray}});
    await fetchQuizzes();
  };
  const unshare = async (quizzId, shareTo) => {
    const quizz = await getQuizzById({id: quizzId});

    let _share = {
      shareEtab: quizz.shareEtab || [],
      shareRegion: quizz.shareRegion || [],
      shareGlobal: quizz.shareGlobal || [],
    };

    Object.keys(_share).forEach((key) => {
      _share[key] = _share[key]
                      .reduce((acc, val, i, arr) => [...acc, ...(i % 2 === 0 ? [[val, arr[i+1]]] : [])], [])
                      .filter((el) => el[0] !== shareTo)
                      .reduce((acc, val) => acc.concat(val), []);
    });

    await updateQuizz({id: quizzId, data: _share});
    await fetchQuizzes();
  };
  const checkRights = (quizzId, perm) => {
    const quizz = quizzes.find((q) => q.uid === quizzId);
    if (!quizz) return false;

    const estCode = ui?.establishment?.uid;
    const groupementCode = ui?.establishment?.groupement ?? ui?.user?.groupement ?? "";
    const regionCode = ui?.establishment?.region && ui?.establishment?.groupement ? `${ui.establishment.groupement}-${ui.establishment.region}` : 
                       quizz.region && ui?.user?.role === "regionAdmin" ? `${ui?.groupement?.uid}-${ui?.user?.region}` : "";

    if (quizz.superadmin && perm === READ) return true; //admin quizz
    if (quizz.establishment && quizz.establishment === estCode) return true; //etab est propriétaire, donc peut tout faire
    if (quizz.region && ui.user.role === "regionAdmin" && quizz.region === regionCode) return true; //adminregion est propriétaire, donc peut tout faire
    if (quizz.groupement && ui.user.role === "groupementAdmin" && quizz.groupement === groupementCode) return true; //admingroupement est propriétaire, donc peut tout faire

    if (perm === OWNER) return false; //si on demande les droits d'owner, on ne peut pas les avoir ici, ils sont check plus haut et ne font pas partie des permCode de partage

    let _share = [
      ...(quizz.shareEtab || [])  .reduce((acc, val, i, arr) => [...acc, ...(i % 2 === 0 ? [[val, arr[i+1]]] : [])], []),
      ...(quizz.shareRegion || []).reduce((acc, val, i, arr) => [...acc, ...(i % 2 === 0 ? [[val, arr[i+1]]] : [])], []),
      ...(quizz.shareGlobal || []).reduce((acc, val, i, arr) => [...acc, ...(i % 2 === 0 ? [[val, arr[i+1]]] : [])], []),
    ];

    return !!(_share.find((el) => (el[0] === estCode || el[0] === groupementCode || el[0] === regionCode) && el[1] & perm));
  };

  return {quizzes, loading, share, unshare, checkRights};
};

export default useCultureQuizz;
