import { useState, useEffect } from "react";
import H from "@here/maps-api-for-javascript";

const useGeocode = () => {
    const [service, setService] = useState(null);

    useEffect(()=>{

        const _platform = new H.service.Platform({
            apikey: "PuhsCEcdotr4BloGgLhM59paeP_EHW49bZ0MSzT-Iaw"
        });
        
        const _service = _platform.getSearchService();

        setService(_service);
        
    },[]);


    return {service};
};



export default useGeocode;



