import React, { useState, useEffect } from "react";
import { Input, Spinner } from "lib/components";
import { Trash, Save, PlusCircle } from "react-feather";
import styles from "assets/scss/pages/CutlureQuizz/new.module.scss";
import {AddContainer} from "./_addQuestionModal";
import { useHistory, useParams } from "react-router-dom";
import firebase from "firebase";
import { toast } from "react-toastify";
import Sidebar from "./_questionEditSidebar";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

//import * as QuestionsTypes from "./QuestionTypes";
import QuestionsTypes from "./QuestionTypes";
import useUI from "hooks/ui.hook";
import { getQuizzById } from "services/ressources/quizz";

const firestore = firebase.firestore;

const Edit = () => {
  const [ui] = useUI();
  const params = useParams();
  const history = useHistory();

  const [mode, setMode] = useState(null);

  const [quizzParams, setQuizzParams] = useState({});
  const [questions, setQuestions] = useState([]);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const [loading, setLoading] = useState(true);
  const [launchSave, setLaunchSave] = useState(false);

  const [autoSelect, setAutoSelect] = useState(false);

  useEffect(() => {
    //initialize
    (async () => {
      if (mode === null) {
        setLoading(true);
        let uid = params.uid;

        if (!uid) {
          history.push("/dashboard/cultureQuizz");
          setMode(null);
        } else if (uid === "new") {
          setMode("new");
          setQuestions([]);
          setLoading(false);
          setQuizzParams({
            title: "",
            ...(
              ui?.establishment?.uid ? {establishment: ui.establishment.uid} :
              ui?.user?.role === "regionAdmin" ? {region: `${ui.groupement.uid}-${ui.user.region}`} :
              ui?.user?.role === "groupementAdmin" ? {groupement: `${ui.groupement.uid}`} : 
              ui?.user?.role === "superadmin" ? {superadmin: true} : {}
            ),
          });
        } else {
          setMode("edit");
          try {
            let _quizz = await getQuizzById({id: uid});
            if (!_quizz) {
              toast.error("Le quizz sélectionné n'existe plus");
              history.push("/dashboard/cultureQuizz");
              return;
            }
            let _questionsRes = await firestore()
              .collection("quizz")
              .doc(uid)
              .collection("questions")
              .get();
            let _questions = [];
            _questionsRes.forEach((doc) => _questions.push({ uid: doc.id, ...doc.data() }));

            setQuizzParams(_quizz);
            setQuestions(_questions);
            setLoading(false);
          } catch (e) {
            console.error(e);
            toast.error(e.message);
            history.push("/dashboard/cultureQuizz");
          }
        }
      }
    })();
  }, [params]);

  useEffect(() => {
    //initialize
    if (questions) {
      if (questions.length > 0) {
        save();
      }
    }
  }, [launchSave]);

  useEffect(() => {
    if(autoSelect){
      if(questions){
        if(questions.length > 0){
          setCurrentQuestion(questions[questions.length -1]);
          setSidebarOpen(true);
          setAutoSelect(false);
        }
      }
    }
  }, [autoSelect]);

  const autoSelectNewQuestion = () => {setAutoSelect(true);};

  const save = async () => {
    try {
      if (mode === "new") {
        const _quizz = await firestore().collection("quizz").add(quizzParams,);
        await Promise.all(
          questions.map(async (data, id) => {
            let d = { ...data };
            delete d.uid;
            await firestore()
              .collection("quizz")
              .doc(_quizz.id)
              .collection("questions")
              .doc(`${id + 1}`)
              .set(d);
          })
        );

        setMode("edit");
        history.push("/dashboard/cultureQuizz/edit/"+_quizz.id);

      } else {
        let a = { ...quizzParams };
        delete a.uid;
        await firestore().collection("quizz").doc(params.uid).set(a);
        //reset questions collection
        let _questions = [];
        let res = await firestore()
          .collection("quizz")
          .doc(params.uid)
          .collection("questions")
          .get();
        res.forEach((doc) => _questions.push({ uid: doc.id, ...doc.data() }));
        await Promise.all(
          _questions.map(async (data) => {
            await firestore()
              .collection("quizz")
              .doc(params.uid)
              .collection("questions")
              .doc(data.uid)
              .delete();
          })
        );
        await Promise.all(
          questions.map(async (data, id) => {
            let _b = { ...data };
            delete _b.uid;
            await firestore()
              .collection("quizz")
              .doc(params.uid)
              .collection("questions")
              .doc(`${id + 1}`)
              .set(_b);
          })
        );
      }
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };
  const saveButton = async () => {
    setLoading(true);
    await save();
    setLoading(false);
    history.push("/dashboard/cultureQuizz");
  };
  const deleteQuizz = async () => {
    try {
      setLoading(true);
      await firestore().collection("quizz").doc(params.uid).delete();
      history.push("/dashboard/cultureQuizz");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
    setLoading(false);
  };
  const onDeleteQuestion = (id) => {
    setCurrentQuestion(null);
    setSidebarOpen(false);
    setQuestions(questions.filter((q) => q.uid !== id).map((i, id) => ({ ...i, uid: id + 1 })));
    setLaunchSave(!launchSave);
  };
  const onChangeQuestion = (e) => {
    let _questions = [...questions];
    for (let i = 0; i < _questions.length; i++) {
      if (_questions[i].uid === currentQuestion.uid) {
        _questions[i] = { ..._questions[i], ...e };
        setCurrentQuestion(_questions[i]);
        console.log(_questions[i]);
      }
    }
    setQuestions(_questions);
    //setLaunchSave(!launchSave);
    //setAddModalOpen(true);
  };
  const drop = (e) => {
    if (!e.source || !e.destination) return;
    let _questions = [...questions];

    _questions.splice(e.source.index, 1);
    _questions.splice(e.destination.index, 0, questions[e.source.index]);
    for (let i = 0; i < _questions.length; i++) _questions[i].uid = `${i + 1}`;

    setQuestions(_questions);
    setCurrentQuestion(_questions[e.destination.index]);
  };

  if (mode === null || loading) return <Spinner />;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerLeft}>
          <div className={styles.header}>
            <div className={styles.left}>
              {/*mode !== "new" && ui.user.role !== "superadmin" && <Play color="#6a0d7c" size={32} onClick={play} style={{cursor: "pointer"}} />*/}
              {/*<Settings color="#6a0d7c" size={32} onClick={openSettings} style={{cursor: "pointer"}} />*/}
              <Save color="#6a0d7c" size={32} onClick={saveButton} style={{ cursor: "pointer" }} />
              {mode !== "new" && (
                <Trash
                  color="#D95550"
                  size={32}
                  onClick={deleteQuizz}
                  style={{ cursor: "pointer" }}
                />
              )}
              <Input
                value={quizzParams.title}
                onChange={(e) => setQuizzParams({ ...quizzParams, title: e.target.value })}
                placeholder="Titre"
              />
            </div>
            <div className={styles.right}></div>
          </div>
          <div className={styles.body}>
            {currentQuestion === null || addModalOpen ? (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 40,
                }}
              >
                <span style={{ fontSize: 32 }}>
                  Veuillez sélectionner le type de question à ajouter.
                </span>
                <AddContainer
                  isOpen={addModalOpen}
                  toggle={() => setAddModalOpen(false)}
                  context={[questions, setQuestions]}
                  autoSelectNewQuestion = {autoSelectNewQuestion}
                />
              </div>
            ) : (
              <>
                {QuestionsTypes.map((questionType) => (
                  <>
                    {questionType.slug === currentQuestion.type && (
                      <questionType.EditorDisplay
                        onClose={() => setSidebarOpen(false)}
                        onDelete={() => onDeleteQuestion(currentQuestion.uid)}
                        question={currentQuestion}
                        onChange={onChangeQuestion}
                      />
                    )}
                  </>
                ))}
              </>
            )}
          </div>
          <div className={`${styles.toolbar} ${sidebarOpen ? styles.sidebarOpen : ""}`}>
            <DragDropContext onDragEnd={drop}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided /*, snapshot*/) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ display: "flex", gap: 10 }}
                  >
                    {questions.map((q, index) => (
                      <Draggable key={q.uid} draggableId={q.uid} index={index}>
                        {(provided /*, snapshot*/) => (
                          <div
                            className={`${styles.card} ${
                              currentQuestion && q.uid === currentQuestion.uid && !addModalOpen ? styles.active : ""
                            }`}
                            onClick={() => {
                              setCurrentQuestion(q);
                              setSidebarOpen(true);
                              setAddModalOpen(false);
                            }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div style={{position: "relative", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                              <Trash 
                                style={{position: "absolute", top: 5, right: 5, cursor: "pointer"}}
                                color="red"
                                size={19}
                                onClick={() => onDeleteQuestion(q.uid)}
                              />
                              <span style={{ textAlign: "center", fontWeight: 500 }}>
                                {q.title ? q.title : "Question sans titre"}
                              </span>
                            </div>
                            {/*<Settings style={{cursor: "pointer"}} onClick={() => {setSidebarOpen(true); setCurrentQuestion(q)}}/>*/}
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className={`${styles.card} ${styles.add} ${addModalOpen ? styles.active : ""}`} onClick={() => setAddModalOpen(true)}>
              <PlusCircle color="#8b11a2" size={44} />
            </div>
          </div>

        </div>
        
        <Sidebar
          isOpen={sidebarOpen && !addModalOpen}
          onClose={() => setSidebarOpen(false)}
          onDelete={() => onDeleteQuestion(currentQuestion.uid)}
          question={currentQuestion}
          onChange={onChangeQuestion}
        />
      </div>
    </>
  );
};

export default Edit;
