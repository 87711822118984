import React from "react";
import useUI from "hooks/ui.hook";

import Basic_MenuReservationIndex from "./Basic/Pages/index";

import Ovelia_MenuReservationIndex from "./Specific/ovelia/Pages/index";

import Heurus_MenuReservationIndex from "./Specific/heurus/Pages/index";

const MenuReservationIndex = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_MenuReservationIndex {...props} />;
    if (ui?.groupement?.specific === "heurus") return <Heurus_MenuReservationIndex {...props} />;
    return <Basic_MenuReservationIndex {...props} />;
};


export { MenuReservationIndex };