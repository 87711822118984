import React, {useCallback, useState, useEffect} from "react";
import useCtx, {Provider} from "./ctx";
import {useHistory, useParams} from "react-router-dom";
import {Card, Input, MultiSelect, FormGroup, Tab, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter} from "lib/components";
import { Plus, Trash, Edit } from "react-feather";
import * as PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

const Abonnements = () => {
  const {id} = useParams();
  const history = useHistory();
  const {state, dispatch, save} = useCtx();

  const [isOpen, setIsOpen] = useState(false);
  const [abonnementId, setAbonnementId] = useState(null);
  const [name, setName] = useState("");

  const _handleChange = useCallback(
    (key, value) => dispatch({type: "setGroupement", groupement: {...state.groupement, [key]: value}}), 
  [state.groupement]);

  const _save = useCallback(() => {
    let _id = abonnementId || uuidv4();

    _handleChange("abonnements", {
      ...state.groupement.abonnements,
      [_id]: {
        name,
        options: {}
      }
    });

    setIsOpen(false);
  }, [_handleChange, abonnementId, name, state?.groupement?.abonnements]);

  const _delete = useCallback((key) => {
    if (window.confirm("Voulez-vous vraiment supprimer cet abonnement ? Attention les seniors associés se retrouveront sans abonnement.")) {
      const {[key] : _, ...abonnements} = state.groupement.abonnements;
      dispatch({type: "setGroupement", groupement: {...state.groupement, abonnements}});
    }
  }, [dispatch, state?.groupement?.abonnements]);

  if (state.isLoading) return <></>;
  return (
    <div style={{width: "100%"}}>
      <Tab activeTab={2} onClick={console.log} render={[
        {title: "Informations", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/infos`)},
        {title: "Etablissements", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/establishments`)},
        {title: "Abonnements", content:
          <>
            <Card style={{flex: 1}}>
              <div style={{display: "flex", justifyContent: "flex-end", marginBottom: 10}}>
                <Button onClick={() => {setName(""); setAbonnementId(null); setIsOpen(true);}}><Plus /></Button>
              </div>
              <Table>
                <thead>
                  <th>Nom</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  {Object.keys(state?.groupement?.abonnements || {}).map(key => (
                    <tr key={key}>
                      <td>{state.groupement.abonnements[key].name}</td>
                      <td>
                        <Edit style={{cursor: "pointer"}} onClick={() => {setAbonnementId(key); setName(state.groupement.abonnements[key].name); setIsOpen(true);}} />{" "}
                        <Trash style={{cursor: "pointer"}} onClick={() => _delete(key)} color="red" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10, gap: 10}}>
              <Button onClick={() => history.push("/dashboard/groupements")} color="secondary">Retour</Button>
              <Button onClick={save}>Enregistrer</Button>
            </div>
          </>
        },
        {title: "Statistiques", content: <></>, overrideOnClick: () => history.push(`/dashboard/groupements/${id}/statistics`)},
      ]} />
      <CreateEdit
        isOpen={isOpen}
        name={name}
        setName={setName}
        onSave={_save}
        onBack={() => setIsOpen(false)}
      />
    </div>
  );
};

// eslint-disable-next-line react/display-name
const CreateEdit = React.memo(({isOpen, name, setName, onSave, onBack}) => (
  <Modal isOpen={isOpen}>
    <ModalHeader></ModalHeader>
    <ModalBody>
      <Input label="Nom" value={name} onChange={e => setName(e.target.value)} />
    </ModalBody>
    <ModalFooter style={{justifyContent: "center"}}>
      <Button color="secondary" onClick={onBack}>Retour</Button>
      <Button color="primary" onClick={onSave}>Enregistrer</Button>
    </ModalFooter>
  </Modal>
));

CreateEdit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string,
  setName: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
};

const GroupementHOC = () => {
  const {id} = useParams();

  return (
    <Provider id={id}>
      <Abonnements />
    </Provider>
  );
};

export default GroupementHOC;