import React from "react";

import Basic_ServiceManagementIndex from "./Basic/Pages/index";
import Basic_Planning from "./Basic/Pages/planning";
import Basic_ListePrestataire from "./Basic/Pages/listePrestataire";
import Basic_CreationPrestataire from "./Basic/Pages/creationPrestataire";
import Basic_CreationPrestation from "./Basic/Pages/creationPrestation";

const ServiceManagementIndex = (props) => {
    return <Basic_ServiceManagementIndex {...props} />;
};
const Planning = (props) => {
    return <Basic_Planning {...props} />;
};
const ListePrestataire = (props) => {
    return <Basic_ListePrestataire {...props} />;
};
const CreationPrestataire = (props) => {
    return <Basic_CreationPrestataire {...props} />;
};
const CreationPrestation = (props) => {
    return <Basic_CreationPrestation {...props} />;
};


export { ServiceManagementIndex,Planning,ListePrestataire,CreationPrestataire,CreationPrestation };