import React from "react";

import {ServiceReservationIndex} from "./routerPages";
import { Provider as ProviderService } from "../serviceCtx";
import { ServiceReservationProvider } from "./routerContext";


const ServiceReservation = (props) => {    
    return (
		<ProviderService>
			<ServiceReservationProvider>
			<ServiceReservationIndex {...props} />
			</ServiceReservationProvider>
		</ProviderService>
		);
};

export default ServiceReservation;