import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  ColorPicker,
  FormGroup,
  Spinner,
} from "lib/components";
import { useAnimationCategories } from "resources/animations";
import styles from "assets/scss/components/homeWidgets/animation_categoriesCrud.module.scss";
import PropTypes from "prop-types";

import { Eye, EyeOff, Plus } from "react-feather";

const FiltreButton = (props) => {
  const { title,color,isVisibleInfo,toggleVisibility  } = props;
//   const [isVisible,setIsVisible] = useState(true);
  
const handleClick = () => {
    toggleVisibility();
  };

 
  return (
    <div style={{opacity:isVisibleInfo?1:0.5, cursor: "pointer",backgroundColor: color, display: "flex",borderRadius:"10px",gap: "5px",paddingInline:"10px",justifyContent:"center",paddingBlock:"2px",alignItems:"center"}} 
        onClick={()=>{handleClick();}} >
          <span style={{color:"black",fontSize:"15px",fontFamily:600}}
          >
            {title}
          </span>
          { isVisibleInfo? <Eye size={25}/>:<EyeOff/>}
    </div>
  );
};
FiltreButton.propTypes = {
    title : PropTypes.object.isRequired,
    color : PropTypes.func.isRequired,
    isVisibleInfo: PropTypes.bool.isRequired,
    toggleVisibility: PropTypes.func.isRequired,
  };
  

export default FiltreButton;
