import React from "react";
import {Form as BootstrapForm} from "reactstrap";
import PropTypes from "prop-types";

const Form = (props) => {
  const {children, className} = props;

  return (
    <BootstrapForm className={className}>
      {children}
    </BootstrapForm>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element,
};

Form.defaultProps = {
  className: ""
};

export default Form;