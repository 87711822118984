import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

export const usePrompt = (when, message = "Les modifications que vous avez apportées ne seront peut-être pas enregistrées.", reverseFunction) => {
  const history = useHistory();

  const self = useRef(null);

  const isValid = () => {
    if(typeof(when) === "function"){
      if(reverseFunction)
        return !when();
      else
        return when();
    }
    return when;
  };

  const onWindowOrTabClose = event => {
    if (!isValid()) {
      return;
    }

    if (typeof event == "undefined") {
      event = window.event;
    }

    if (event) {
      event.returnValue = message;
    }

    return message;
  };

  useEffect(() => {
    if (isValid()) {
      self.current = history.block(message);
    } else {
      self.current = null;
    }

    window.addEventListener("beforeunload", onWindowOrTabClose);

    return () => {
      if (self.current) {
        self.current();
        self.current = null;
      }

      window.removeEventListener("beforeunload", onWindowOrTabClose);
    };
  }, [message, when]);
};
