import { Redirect, Route, Switch } from "react-router-dom";
import React from "react";
import ProfilePage from "./Profile";
import PropTypes from "prop-types";

import useUI                    from "../../hooks/ui.hook";
import MajorMenu                from "../../config/MajorMenu";
import { DashboardLayout }      from "../../lib/layouts";
import DashboardHeader          from "../../config/DashboardHeader";

import Animation                from "../Major/Animation/Basic/Pages";
import Ateliers                 from "../Major/Ateliers";
import CultureQuizzEditor       from "../Major/CultureQuizz/QuizzEdit";
import CultureQuizzQuizzList    from "../Major/CultureQuizz/QuizzList";
import CultureQuizzQuizzPlay    from "../Major/CultureQuizz/QuizzPlay";
import CultureQuizzQuizzResults from "../Major/CultureQuizz/QuizzResults";
import CultureQuizzQuizzStart   from "../Major/CultureQuizz/QuizzStart";
import Gazette                  from "../Major/Gazette";
import GazetteEdit              from "../Major/Gazette/edit/index.js";
import Home                     from "../Major/Home";
import Menu                     from "../Major/Menu/Menu";
import MenuWeekExport           from "../Major/Menu/Basic/Pages/MenuWeekExport";
import MenuDayExport            from "../Major/Menu/Basic/Pages/MenuDayExport";
import MenuRestaurantExport     from "../Major/Menu/Basic/Pages/MenuRestaurantExport";
import NewsPage                 from "../Major/News";
import Notifications            from "../Major/Notifications";
import PlanningMonthExport      from "../Major/Animation/Basic/Pages/PlanningMonthExport";
import PlanningWeekExport       from "../Major/Animation/Basic/Pages/PlanningWeekExport";
import ReservationAnimations    from "../Major/Reservation/Planning";
import ReservationMenus         from "../Major/Reservation/Menu";
import Tuto                     from "../Major/Tutoriels/Tuto";
import UserList                 from "../Major/UserList/Basic/Pages/UserList";
import UserSingle               from "../Major/UserList/Basic/Pages/UserSingle";
import HomeStat                 from "../Major/Statistics/statistics";


const MajorRouter = ({ match }) => {
  const [ui] = useUI();

  return (
    <DashboardLayout menu={<MajorMenu />} header={<DashboardHeader />}>
      <Switch>
        <Route exact path={`${match.url}/one`}>
          <Home />
        </Route>
        <Route exact path={`${match.url}/users`}>
          <UserList />
        </Route>
        <Route exact path={`${match.url}/users/:id`}>
          <UserSingle />
        </Route>
        <Route exact path={`${match.url}/profile`}>
          <ProfilePage />
        </Route>
        <Route exact path={`${match.url}/tuto`}>
          <Tuto />
        </Route>
        
        {ui.establishment.features &&
        ui.establishment.features.includes("menuPlanning") ? (
          <Route exact path={`${match.url}/menu`}>
            <Menu />
          </Route>
        ) : null}
        {ui.establishment.features &&
        ui.establishment.features.includes("menuPlanning") ? (
          <Route exact path={`${match.url}/menu/export`}>
            <MenuWeekExport />
          </Route>
        ) : null}
        {ui.establishment.features &&
          ui.establishment.features.includes("menuPlanning") && ["ovelia", "heurus"].includes(ui?.groupement?.specific) ? (
          <Route exact path={`${match.url}/menu/exportDay`}>
            <MenuDayExport />
          </Route>
        ) : null}
        {ui.establishment.features &&
          ui.establishment.features.includes("menuPlanning") && ["heurus"].includes(ui?.groupement?.specific) ? (
          <Route exact path={`${match.url}/menu/exportRestaurant`}>
            <MenuRestaurantExport />
          </Route>
        ) : null}


        {ui.establishment.features &&
        ui.establishment.features.includes("animationPlanning") ? (
          <Route exact path={`${match.url}/animations`}>
            <Animation />
          </Route>
        ) : null}
        {ui.establishment.features &&
        ui.establishment.features.includes("animationPlanning") ? (
          <Route exact path={`${match.url}/animations/weekExport`}>
            <PlanningWeekExport />
          </Route>
        ) : null}
        {ui.establishment.features &&
        ui.establishment.features.includes("animationPlanning") ? (
          <Route exact path={`${match.url}/animations/monthExport`}>
            <PlanningMonthExport />
          </Route>
        ) : null}

        {ui.establishment.features &&
        ui.establishment.features.includes("news") ? (
          <Route exact path={`${match.url}/news`}>
            <NewsPage />
          </Route>
        ) : null}

        {ui.establishment.features &&
        ui.establishment.features.includes("AnimationWorkshopDatabase") ? (
          <Route path={`${match.url}/ateliers`}>
            <Ateliers />
          </Route>
        ) : null}

        {ui.establishment.features &&
        ui.establishment.features.includes("cultureQuizz") ? (
          <Route exact path={`${match.url}/cultureQuizz`}>
            <CultureQuizzQuizzList />
          </Route>
        ) : null}
        {ui.establishment.features &&
        ui.establishment.features.includes("cultureQuizz") ? (
          <Route exact path={`${match.url}/cultureQuizz/edit/:uid`}>
            <CultureQuizzEditor />
          </Route>
        ) : null}
        {ui.establishment.features &&
        ui.establishment.features.includes("cultureQuizz") ? (
          <Route exact path={`${match.url}/cultureQuizz/:uid/start`}>
            <CultureQuizzQuizzStart />
          </Route>
        ) : null}
        {ui.establishment.features &&
        ui.establishment.features.includes("cultureQuizz") ? (
          <Route exact path={`${match.url}/cultureQuizz/:uid/play`}>
            <CultureQuizzQuizzPlay />
          </Route>
        ) : null}
        {ui.establishment.features &&
        ui.establishment.features.includes("cultureQuizz") ? (
          <Route exact path={`${match.url}/cultureQuizz/:uid/results`}>
            <CultureQuizzQuizzResults />
          </Route>
        ) : null}

        {ui.establishment.features &&
        ui.establishment.features.includes("banner") ? (
          <Route path={`${match.url}/banner`}>
            <Notifications />
          </Route>
        ) : null}
        {ui.establishment.features &&
        ui.establishment.features.includes("reservationMenu") ? (
          <Route path={`${match.url}/reservation/menus`}>
            <ReservationMenus />
          </Route>
        ) : null}
        {ui.establishment.features &&
        ui.establishment.features.includes("reservationPlanning") ? (
          <Route path={`${match.url}/reservation/animations`}>
            <ReservationAnimations />
          </Route>
        ) : null}

        {ui.establishment.features &&
        ui.establishment.features.includes("gazette") ? (
          <Route path={`${match.url}/gazette/:uid/edit`}>
            <GazetteEdit />
          </Route>
        ) : null}
        {ui.establishment.features &&
        ui.establishment.features.includes("gazette") ? (
          <Route path={`${match.url}/gazette/edit`}>
            <GazetteEdit />
          </Route>
        ) : null}
        {ui.establishment.features &&
        ui.establishment.features.includes("gazette") ? (
          <Route path={`${match.url}/gazette`}>
            <Gazette />
          </Route>
        ) : null}

        {ui.establishment.features ? <Route path={`${match.url}/statistics`}><HomeStat /></Route> : null}

        <Route path={"*"}>
          <Redirect to={`${match.url}/one`} />
        </Route>
      </Switch>
    </DashboardLayout>
  );
};

MajorRouter.propTypes = {
  match: PropTypes.object.isRequired,
};

export default MajorRouter;
