import React, {useState} from "react";
import Tab from "../../../../../lib/components/Tab";

import {Profile, Residence} from "../../router_pages";

const ProfilePage = () => {
  const [page, setPage] = useState(0);

  const pressHeaderHandler = id => setPage(id);

  const render = [
    {
      title : "Profil",
      content:  <Profile/>,
    },
    {
      title : "Résidence",
      content: <Residence />,
    }
  ];

  return (
    <div style={{flex:1}}>
      <Tab render={render} activeTab={page} onClick={pressHeaderHandler}/>
    </div>
  );
};

export default ProfilePage;