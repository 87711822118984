import React from "react";

import Map from "./Home/Map";
import Mode from "../../env";

const Home = () => {
  return (
    <>
      {Mode === "PROD" ? <Map/> : 
      <div> Feature disponible uniquement pour la PROD </div>
      }
      
    </>
  );
};

export default Home;