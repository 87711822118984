import React, {useState} from "react";
import PropTypes from "prop-types";
import { NumberVariations, SimpleStatComponent} from "../components";
import Card from "lib/components/Stats/Card";
import {PieChart} from "../components";
import useStats from "lib/Stats/statsContext";
import {faFileAlt} from "@fortawesome/free-solid-svg-icons";

const infos = {
  gazette : {
    establishment: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre de gazettes publiées sur la période sélectionnée. \n"}
                         <b>{"2) Pourcentage:"}</b> {" Indique les variations du nombre de gazettes publiées entre cette période et la période précédente. \n"}
                   </div>,
    groupement: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre total de gazettes publiées sur la période sélectionnée. \n"}
                      <b>{"2) Pourcentage:"}</b> {" Indique les variations du nombre de gazettes publiées entre cette période et la période précédente. \n"}
                      <b>{"3) Moyenne:"}</b> {" Indique le nombre moyen de gazettes publiées par résidence. \n"}
                      <b>{"4) Tableau:"}</b> {" Indique pour chaque résidence, le nombre de gazettes publiées sur la période donnée, avec les variations entre cette période et la période précédente."}</div>
  },
};

const Gazette = () => {
  const [ctx, dispatch, hasFeature] = useStats();

  const NumberComponent = hasFeature("statistics") ? NumberVariations : SimpleStatComponent;


  return (
    <div style={{marginTop: 30, display: "flex", flexDirection: "row", justifyContent: "space-around", flexWrap: "wrap", gap: 40, paddingBottom: 30}}>    
      <NumberComponent path="gazette/count" title="Gazettes" info={ctx?.targetType === "establishment" ? infos.gazette.establishment : infos.gazette.groupement} icon={faFileAlt} iconBackgroundColor={"#C99FF2"}/>
      {/* <BasicNumber type={"groupement"} data1={data1} data2={data2} path="users/new" title="Nouveaux Utilisateurs" info=""/>
      <Card title="Répartition des nouveaux utilisateurs" info="Indique le nombre d'utilisateurs pour tous les établissements du groupement" size="lg">
        <PieChart data1={data1} data2={data2} path="users/newByRole"/>
      </Card> */}
    </div>
  );
};


Gazette.propTypes = {
    establishment: PropTypes.string,
    data1: PropTypes.object,
    data2: PropTypes.object,
};

export default Gazette;
