import React,{useState, useEffect, useMemo, useCallback} from "react";
import useUI from "hooks/ui.hook";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
  } from "lib/components";

import { useMenuReservation } from "../../router_context";
import { WeekReservationTable, WeekPdf } from "../../router_components";

import styles from "assets/scss/pages/reservation/weekModal.module.scss";
import {
  pdf, PDFViewer, PDFDownloadLink
} from "@react-pdf/renderer";
// import printJS from "print-js";
// import sleep from "helpers/asyncWait";

const ModalWeek = () => {
  const [ui] = useUI();
  const [ctx, dispatch] = useMenuReservation();

  // const printDocument = async () => {
  //   const doc = (<WeekPdf data={ctx?.data} uid={ctx?.modalInfos?.uid} name={ctx?.modalInfos?.user} week={ctx.selectedWeek} template={ui.establishment.template}/>);
  //   const pdfDoc = pdf(doc);
  //   const result = await pdfDoc.toBlob();
  //   const url = URL.createObjectURL(result);
  //   printJS(url, "pdf");
  // };

  return (
    <Modal isOpen={ctx.modalWeekOpen} className={styles.modal} toggle={()=> dispatch({ type: "setProperty", property : "modalWeekOpen", value:false})}>
      <ModalHeader>{ctx?.modalInfos?.user}</ModalHeader>
        <ModalBody>
          <WeekReservationTable _data={ctx.data} uid={ctx?.modalInfos?.uid} week={ctx.selectedWeek}/>
        </ModalBody>
      <ModalFooter>
        <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <div style={{width: "20%", display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
          <PDFDownloadLink
          document={
            <WeekPdf data={ctx?.data} uid={ctx?.modalInfos?.uid} name={ctx?.modalInfos?.user} week={ctx.selectedWeek} template={ui.establishment.template}/>
          }
          fileName="planning.pdf"
        >
          {({ /* blob, url,*/ loading /*, error */ }) =>
            loading ? (
              "Loading document..."
            ) : (
              <>
                <Button color="secondary">Télécharger</Button>
              </>
            )
          }
        </PDFDownloadLink>
          <Button color="primary" onClick={()=> dispatch({ type: "setProperty", property : "modalWeekOpen", value:false})}>Retour</Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const SuspenseHOC = (props) => {
  const [ctx] = useMenuReservation();

  if(!ctx || !ctx.data || !ctx.modalInfos) return <></>;
  return <ModalWeek {...props} />;
};


export default SuspenseHOC;
