import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import Logo from "./assets/logo.png";

import Roboto from "fonts/roboto/Roboto-Condensed.ttf";
import RobotoBold from "fonts/roboto/Roboto-BoldCondensed.ttf";

import Nunito from "fonts/nunito/Nunito-Regular.ttf";
import NunitoBold from "fonts/nunito/Nunito-Bold.ttf";

import Image1 from "./assets/Image1.png";
import Image2 from "./assets/Image2.png";

Font.register({
  family: "Roboto",
  fontWeight: "normal",
  src: Roboto,
});
Font.register({
  family: "Roboto",
  format: "bold",
  src: RobotoBold,
});

Font.register({
  family: "Nunito",
  fontWeight: "normal",
  src: Nunito,
});
Font.register({
  family: "Nunito",
  format: "bold",
  src: NunitoBold,
});

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: "#007078",
    width: "100%",
    height: "100%",
    padding: 5
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
    height: "11%"
  },
  logoContainer: {
    position: "relative",
    width: 250,
    height: "100%",
  },
  logo: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    objectFit: "contain"
  },
  dayHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    marginTop: 5,
  },
  weekDay: {
    flex: 6,
    color: "#007078",
    paddingVertical: 10,
    textAlign: "center",
    borderRightWidth: 1,
    borderRightColor: "#ccc",
  },
  weekEndDay: {
    flex: 4,
    color: "#007078",
    paddingVertical: 10,
    textAlign: "center",
    borderRightWidth: 1,
    borderRightColor: "#007078",
  },

  weekContainer: {
    width: "100%",
    flex: 1,
  },
  week: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  day: {
    border: "1px solid white",
    flex: 6,
    padding: 2,
    display: "flex",
    flexDirection: "column",
    height: 97,
    backgroundColor: "#f4f2f2",
  },
  weekendDay: {
    border: "1px solid #d9d9d9",
    borderTop: "1px solid white",
    flex: 4,
    padding: 2,
    display: "flex",
    flexDirection: "column",
    height: 95,
    backgroundColor: "#d9d9d9",
  },
  dayTitle: {
    color: "#007078",
    fontWeight: "bold",
    fontSize: 16,
    fontFamily: "Nunito",
  },
  eventHour: {
    color: "#007078",
    marginRight: 5,
    fontWeight: "bold",
    fontSize: 13,
    fontFamily: "Nunito",
  },
  eventTitle: {
    color: "#007078",
    fontWeight: "bold",
    fontSize: 13,
    fontFamily: "Nunito",
  },

  image1: {
    position: "absolute",
    top: 110,
    left: 100,
    width: 75
  },
  image2: {
    position: "absolute",
    bottom: 200,
    right: 30,
    width: 120
  }
});

const MonthPlanningPdf = (props) => {
  const { ui, firstDay, events, logo, additionnalData } = props;
  const day = new Date(firstDay);

  const _computeDays = () => {
    let firstDayOfMonth = new Date(firstDay);
    let firstDayOfExport = new Date(firstDay);
    let weeks = [];
    
    firstDayOfMonth.setDate(1);
    firstDayOfExport.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDay() + 1);

    do {
      weeks.push([]);
      for (let i = 0; i < 7; i++) {
        weeks[weeks.length - 1].push(new Date(firstDayOfExport));
        firstDayOfExport.setDate(firstDayOfExport.getDate() + 1);
      }
    } while (weeks[weeks.length - 1][weeks[weeks.length - 1].length - 1].getMonth() === firstDayOfMonth.getMonth());
    return (weeks);
  };

  const _getEventByDay = (day) => {
    let start = new Date(day);
    let end = new Date(day);

    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(1);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);

    return (events.filter(event => event.start > start && event.end < end));
  };

  const _dateToString = (date) => {
    return moment(date).format("DD");
  };
  const _hourToString = (date) => {
    return moment(date).format("HH:mm").replace(":", "H");
  };

  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View style={styles.mainContainer}>
          <View style={styles.header}>
            <View style={styles.logoContainer}>
              <Image source={Logo} style={styles.logo} />
            </View>
            <Text style={{color: "white", fontSize: 32}}>Animations et sorties</Text>
            <Text style={{textTransform: "capitalize", color: "white", textDecorationStyle: "italic", fontWeight: "bold", fontSize: 28}}>
              {moment(firstDay).format("MMMM")} {moment(firstDay).format("YYYY")}
            </Text>
          </View>
          <View style={styles.dayHeaderContainer}>
            <Text style={styles.weekDay}>Lundi</Text>
            <Text style={styles.weekDay}>Mardi</Text>
            <Text style={styles.weekDay}>Mercredi</Text>
            <Text style={styles.weekDay}>Jeudi</Text>
            <Text style={styles.weekDay}>Vendredi</Text>
            <Text style={styles.weekEndDay}>Samedi</Text>
            <Text style={styles.weekEndDay}>Dimanche</Text>
          </View>
          <View style={styles.weekContainer}>
            {_computeDays().map((w, index) => (
              <View style={styles.week} key={index}>
                {w.map((d, index2) => (
                  <>
                    {index2 >= 5 ? 
                      <View style={styles.weekendDay}>
                      </View> 
                    : 
                      <>
                        <View style={styles.day} key={index + index2}>
                          {moment(d).isBetween(moment(firstDay).startOf("month").toDate(), moment(firstDay).endOf("month").toDate()) ?
                            <>
                              <Text style={styles.dayTitle}>{_dateToString(d)}/</Text>
                              {_getEventByDay(d).map((event, index3) => (
                                index3 === 0 ?
                                <View key={index + index2 + index3} style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", flexWrap: "wrap", marginTop: 4, marginBottom: 4}}>
                                  <Text style={styles.eventHour}>{_hourToString(event.start)}</Text>
                                  <Text style={styles.eventTitle}>{event.title}</Text>
                                </View>
                                : null
                              ))}
                            </>
                          : 
                            <></>
                          }
                        </View>
                      </>
                    }
                  </>
                ))}
              </View>
            ))}
          </View>
          <Image source={Image1} style={styles.image1} />
          <Image source={Image2} style={styles.image2} />
          <Text style={{position: "absolute", bottom: 15, right: 50, textAlign: "center", fontSize: 12, width: 120}}>Planning sous réserve de modifications.</Text>
        </View>
      </Page>
    </Document>
  );
};

MonthPlanningPdf.propTypes = {
  firstDay: PropTypes.any.required,
  events: PropTypes.array.required,
  logo: PropTypes.string,
  additionnalData: PropTypes.string,
  ui: PropTypes.object.required,
};

export default MonthPlanningPdf;