import { useEffect, useState, useCallback, useMemo } from "react";
import useUI from "hooks/ui.hook";

import * as Actus from "./ressources/actus";
import * as Establishments from "./ressources/establishment";
import * as EventCategories from "./ressources/event_categories";
import * as Groupements from "./ressources/groupement";
import * as Menus from "./ressources/menu";
import * as Users from "./ressources/user";
import * as Quizzes from "./ressources/quizz";

const ressources = {Actus, Establishments, EventCategories, Groupements, Menus, Users, Quizzes};

const useRessources = ({ressource = "", handler, ...rest}, err) => {
  const ref_cpr = useMemo(() => JSON.stringify(rest), [rest]);

  const [ui] = useUI();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!ressources[ressource]) throw new Error(`Ressource ${ressource} not found`);
    if (!ressources[ressource][handler]) throw new Error(`Ressource ${ressource} has no handler ${handler}`);

    //check if handler end with _rt for real time
    if (handler.endsWith("_rt")) {
      //onSnapshot mode
      setLoading(true);
      const unsubscribe = ressources[ressource][handler]({ui, ...rest}, (data) => {
        setData(data);
        setLoading(false);
      }, (e) => {
        setError(e);
        setLoading(false);
        if (err) err(e);
      });
      return unsubscribe;
    } else {
      //get mode
      setLoading(true);
      (async () => {
        const data = await ressources[ressource][handler]({ui, ...rest}, (e) => {
          setError(e);
          setLoading(false);
          if (err) err(e);
        });
        setData(data);
        setLoading(false);
      })();
    }
  }, [ressource, handler, ref_cpr]);

  const refresh = useCallback(async () => {
    if (handler.endsWith("_rt")) throw new Error("Can't refresh real time handler");

    const data = await ressources[ressource][handler]({ui, ...rest}, (e) => {
      setError(e);
      setLoading(false);
      if (err) err(e);
    });
    console.log(data);
    setData(data);
    setLoading(false);
  }, [ressource, handler, rest, ui]);

  return {data, loading, error, refresh};
};

export default useRessources;
export {ressources};