import React from "react";
import PropTypes from "prop-types";

import Card from "lib/components/Stats/Card";
import NumberDisplay from "lib/components/Stats/NumberDisplay2";
import Chart from "react-apexcharts";
import Table from "lib/components/Stats/table";

import { ArrowUp, ArrowDown } from "react-feather";

import { percent, mean, extractElementsFromObject, getFromPath} from "./operations";
import useStats from "lib/Stats/statsContext";

const NumberVariations = ({path, title, info, icon, iconBackgroundColor }) => {
  const [ctx, dispatch, hasFeature] = useStats();

  if(ctx.targetType === "all" || ctx.targetType === "region"){

    let _number = getFromPath(ctx.data1, path);
    let _variation = percent(_number,getFromPath(ctx.data2, path));

    const array = extractElementsFromObject(ctx.data1.establishments, path);
    let _mean = mean(array);
    // let _mean2 = mean(extractElementsFromObject(ctx.data2.establishments, path));

    // let _meanVariation = percent(_mean,_mean2);

    let data = [];

    let _list = ctx.data2.establishments;

    ctx.data1.establishments.forEach(_obj => {
      let etabLastPeriod = null;
      _list.forEach(_etab => {
        if(_etab.id === _obj.id)etabLastPeriod = _etab;
      });

      const _etabVariation = percent(getFromPath(_obj, path), getFromPath(etabLastPeriod, path));
      data.push({
        etab: _obj.name,
        value: getFromPath(_obj, path),
        variation: _etabVariation,
      });
    });


    const columns = [
      {
        Header: "RÉSIDENCE",
        accessor: "etab",
        Footer: "",
        disableSortBy: false,
        width: 5
        // width: 220,
      },
      {
        Header: "NB",
        accessor: "value",
        Footer: "",
        width: 2,
        disableSortBy: false,
      },
    ];

    if(hasFeature("statistics")){
      columns.push({
        Header: "%",
        accessor: "variation",
        Footer: "",
        disableSortBy: false,
        width: 2,
        Cell: CellFunc,
      });
    }
    return (
      <div>
        <Card title={title} info={info} size="lg">
          <div style={{height: "100%", width: "38%"}}>
            <NumberDisplay number={_number} variation={_variation} mean={_mean} icon={icon} iconBackgroundColor={iconBackgroundColor} /*meanVariation={_meanVariation}*//>
          </div>
          <div style={{height: "50%", borderRight: "2px solid #ECE4EB", marginRight: "2%"}}></div>
          <div style={{width: "58%", height: "100%"}}>
            <Table _columns={columns} _data={data} hasFooter={false} color={iconBackgroundColor} onClick={() => (null)}/>
          </div>
        </Card>
      </div>
    );
  }else{
    let _number = getFromPath(ctx.data1, path);
    let _variation = percent(_number,getFromPath(ctx.data2, path));

    return (
      <div>
        <Card title={title} info={info} size="md">
          <NumberDisplay number={_number} variation={_variation} icon={icon} iconBackgroundColor={iconBackgroundColor} /*mean={_mean} meanVariation={_meanVariation}*//>
        </Card>
      </div>
    );
  }
};

    const CellFunc = ({ cell }) => {
      return <div style={{ width: "100%", color: typeof(cell.value) === "number" ? cell.value >= 0 ? "green" : "red" : "black"}}>
          {typeof(cell.value) === "number" ? cell.value >= 0 ? <ArrowUp size={16} color="green"/> : <ArrowDown size={16} color="red"/> : null} {typeof(cell.value) === "number" ?cell.value >= 0 ? "+" : "" : null}{cell.value}{typeof(cell.value) === "number" ? "%" : null}
      </div>;
    };
    CellFunc.propTypes = {
      cell: PropTypes.object.isRequired,
    };

NumberVariations.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  info: PropTypes.string,
  icon: PropTypes.any,
  iconBackgroundColor: PropTypes.string,
};

export default NumberVariations;
