import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import styles from "../../../../assets/scss/pages/reservation/planningResume.module.scss";
import usePlanningReservation from "./contextReservationPlanning";
import {Eye} from "react-feather";
import { Modal, ModalHeader, ModalBody } from "lib/components";

const Resume = () => {
  const [ctx] = usePlanningReservation();
  const [isVisible, setIsVisible] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);

  if(!ctx.dataSelected) return <></>;
  
  const openHistory = (_event) => {
    setCurrentEvent({..._event});
    setIsVisible(true);
  };

  const DisplayResume = () => {
    let docu = [];
  
    let keys = Object.keys(ctx.dataSelected);

    for(let line=0; line<5; line ++){
      let ligne = [];
      for(let column=0; column<7; column++){
        // Header Jours 
        if(line == 0){
          ligne.push(<td className={styles.headerDate}><p>{moment(keys[column]).format("ddd")}</p><p>{moment(keys[column]).format("DD")}</p></td>);
        }else{
          let _event = null;
          if(keys[column])
            if(ctx.dataSelected[keys[column]])
              _event = ctx.dataSelected[keys[column]][line-1];
          if(_event){
            ligne.push(<td> 
              <div className={styles.tableMenu}>
                <Eye size={22} color="black" style={{position:"absolute", right: 4, top: 2, cursor: "pointer"}} onClick={() => openHistory(_event)}/>
                <span className={styles.hours}>{moment(_event.start.seconds * 1000).format("HH:mm")} - {moment(_event.end.seconds * 1000).format("HH:mm")}</span>
                <span className={styles.eventTitle}>{_event.title}</span>
                <span className={styles.reservation}>Réservations : <span className={styles.resaNumber}>{_event.reservation ? _event.reservation.length : 0}{_event.maxParticipants > 0 ? "/"+_event.maxParticipants : ""}</span></span>
              </div>
            </td>);
          } 
          else{
            ligne.push(<td><div  className={styles.tableMenu}></div></td>);
          }
        }
             
      }
      docu.push(<tr>{ligne}</tr>);
    }
    return docu;
  };

  return (
    <table className={styles.resume}>
      <DisplayResume/>
      <Modal isOpen={isVisible} toggle={() => setIsVisible(false)} size="lg">
          <ModalHeader>
            Historique des Réservations
          </ModalHeader>
          <ModalBody>
            {console.log(currentEvent)}
            <div style={{maxHeight: "40vh", width: "100%", display: "flex", flexDirection: "column", overflow: "auto"}}>
              {currentEvent && currentEvent.history &&  currentEvent.history.sort((a,b) => b.date.toDate().getTime() - a.date.toDate().getTime()).map((_h, _indx) => (
                <div key={_indx} style={{ width: "99%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 8, padding: 8, borderRadius: 8, color: "black", backgroundColor: _h?.action === "add" ? "#95c9b8" : _h?.action === "remove" ? "#edb126" : "" }}>
                  <div style={{flex: 1, fontWeight: "600"}}>
                    {_h?.action === "add" ? "Réservation" : _h?.action === "remove" ? "Suppression" : ""}
                  </div>
                  <div style={{flex: 1, textTransform: "capitalize"}}>
                    {_h?.app ?? ""}
                  </div>
                  <div style={{flex: 1, fontWeight: "600"}}>
                    {_h?.user ? ctx.etabUsers[_h?.user].name + " " +  ctx.etabUsers[_h?.user].surname : ""}
                  </div>
                  <div style={{flex: 1}}>
                    {_h?.date ? moment(_h?.date.toDate()).format("DD MMMM YYYY HH:mm") : ""}
                  </div>
                </div>
              ))
            }
            </div>

          </ModalBody>
      </Modal>
    </table>
  );
};

Resume.propTypes = {
  _data: PropTypes.object.isRequired,
};


export default Resume;
