import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import useUI from "hooks/ui.hook";

import background from "./assets/background.jpg";
import Roboto from "fonts/roboto/Roboto-Condensed.ttf";
import RobotoBold from "fonts/roboto/Roboto-BoldCondensed.ttf";

import Nunito from "fonts/nunito/Nunito-Regular.ttf";
import NunitoBold from "fonts/nunito/Nunito-Bold.ttf";


Font.register({
  family: "Roboto",
  fontWeight: "normal",
  src: Roboto,
});
Font.register({
  family: "Roboto",
  format: "bold",
  src: RobotoBold,
});

Font.register({
  family: "Nunito",
  fontWeight: "normal",
  src: Nunito,
});
Font.register({
  family: "Nunito",
  format: "bold",
  src: NunitoBold,
});

const green = "#466021";

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    height: "100%",
  },
  backgroundDiv: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  backgroundImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  titleContainer: {
    marginTop: 40,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    width: "80%",
    fontSize: 30,
    fontFamily: "Nunito",
    fontWeight: "bold",
    color: green,
    textAlign: "center"
  },
  weekContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
  },
  fromto: {
    fontFamily: "Roboto",
    fontSize: 15,
  },
  container: {
    width: "100%",
    height: "74%",
    flexDirection: "column",
    justifyContent: "space-around",
    marginTop: 10,
    marginBottom: 10
  },
  day: {
    margin: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  dateContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    marginBottom: 8,
    marginLeft: 5,
  },
  dayString: {
    color: green,
    fontSize: 20,
    fontFamily: "Nunito",
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  eventsWrapper: {
    flex: 3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 15,
  },
  event: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  eventTitleContainer: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  eventTitle: {
    fontFamily: "Nunito",
    fontWeight: "bold",
    fontSize: 20,
    color: green
  },
  informations: {
    marginLeft: 20,
    marginRight: 20,
    fontSize: 18,
    fontFamily: "Nunito",
    fontWeight: "bold",
    color: green,
    textAlign: "center"
  },

  //ekipage

  banner : {
    marginTop: 5,
    fontFamily: "Roboto",
    fontSize: 18,
  }
});

const WeekPlanningPdf = (props) => {
  const { ui, monday, events, logo, additionnalData } = props;
  const day = new Date(monday);

  const _getDays = () => {
    let _days = [];

    for (let i = 0; i < 5; i++) {
      let day = new Date(monday);
      day.setDate(day.getDate() + i);
      _days.push(day);
    }
    return _days;
  };
  const _getEventByDay = (day) => {
    let start = new Date(day);
    let end = new Date(day);

    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(1);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);

    return events.filter((event) => event.start > start && event.end < end);
  };
  const _hourToString = (date) => {
    const minutes =  moment(date).format("mm") !== "00" ? moment(date).format("mm") : "";
    return moment(date).format("HH") + "h" + minutes;
  };

  return (
    <Document>
      <Page size="A4" orientation="paysage">
        <View style={styles.mainContainer}>
          <View style={styles.backgroundDiv}>
            <Image src={background} style={styles.backgroundImage} />
          </View>
          <View style={styles.titleContainer}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <Text style={styles.title}>Cette semaine au Vill'Age Bleu du {moment(day).startOf("week").format("DD MMMM")} au{" "}
              {moment(day).endOf("week").format("DD MMMM YYYY")}</Text>
          </View>
          <View style={styles.container}>
            {_getDays().map((day) => (
              <View style={styles.day} key={day.toISOString()}>
                <View style={styles.dateContainer}>
                  <Text style={styles.dayString}>
                    {moment(day).format("dddd")}{" "}{moment(day).format("D")}:
                  </Text>
                </View>
                <View style={styles.eventsWrapper}>
                    {_getEventByDay(day).map((event, index) => (
                      index < 3 ? 
                        <View style={styles.event} key={index}>
                          <Text style={styles.eventTitle}> {_hourToString(event.start)} {event.title}</Text>
                        </View>
                      : null 
                    ))}
                  </View>
              </View>
            ))}
          </View>
          <View>
            <Text style={styles.informations}>
              {additionnalData.banniere}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

WeekPlanningPdf.propTypes = {
  monday: PropTypes.any.required,
  events: PropTypes.array.required,
  logo: PropTypes.string,
  additionnalData: PropTypes.string,
  ui: PropTypes.object.required,
};
export default WeekPlanningPdf;
