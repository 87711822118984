import React from "react";
import PropTypes from "prop-types";
import { Card } from "lib/components";
import { ArrowUp, ArrowDown } from "react-feather";

import styles from "../../assets/scss/component/stats/satisfaction.module.scss";

const SatisfactionCard = (props) => {
  const { title, value, diff, statColor, img } = props;

  const isVariation = value && diff != undefined && diff != null && typeof(diff) === "number" ? true : false;

  let positive = true;

  if (isVariation) {
    if (diff < 0) {
      positive = false;
    }
  }

  
  return (
    <Card style={{ position: "relative" }}>
      <p style={{ fontSize: 14 }}>{title}</p>
      <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
        {img && <img style={{ width: 50, height: 50 }} src={img} alt="" />}
        <p style={{ fontSize: 24, fontWeight: "bold", color: statColor ? statColor : "black" }}>{reduceFloating(value)}%</p>
        <div className={positive ? styles.positive : styles.negative}>
          {isVariation ?
            <>
              {positive ? <ArrowUp size={16} color="green" /> : <ArrowDown size={16} color="red" />}
              <span>{positive ? "+" : ""}{reduceFloating(diff)}%</span>
            </>
            : null}
        </div>
      </div>
    </Card>
  );
};

const reduceFloating = (number) => {
  return Number.parseInt(number*10)/10;
};


SatisfactionCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.object,
  diff: PropTypes.any,
  statColor: PropTypes.string,
  img: PropTypes.any,
};


export default SatisfactionCard;