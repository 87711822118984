import React from "react";

import Basic_useServiceManagement, {Provider as Basic_ServiceManagementProvider} from "./Basic/Context/serviceManagementCtx";


const ServiceManagementProvider = (props) => {
    return <Basic_ServiceManagementProvider {...props} />;
};

const useServiceManagement = () => {
    return Basic_useServiceManagement();
};

export { useServiceManagement, ServiceManagementProvider };