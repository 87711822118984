import { Redirect, Route, Switch } from "react-router-dom";

import initNotifications        from "hooks/initNotifications";

import {Animations}                from "./Animation/router_pages";
import Ateliers                 from "./Ateliers";
import CultureQuizzEditor       from "./CultureQuizz/QuizzEdit";
import CultureQuizzQuizzList    from "./CultureQuizz/QuizzList";
import CultureQuizzQuizzPlay    from "./CultureQuizz/QuizzPlay";
import CultureQuizzQuizzResults from "./CultureQuizz/QuizzResults";
import CultureQuizzQuizzStart   from "./CultureQuizz/QuizzStart";
import DashboardHeader          from "../../config/DashboardHeader";
import { DashboardLayout }      from "../../lib/layouts";
import Gazette                  from "./Gazette";
import GazetteEdit              from "./Gazette/edit/index.js";
import Home                     from "./Home";
import MajorMenu                from "../../config/MajorMenu";
import Menu                     from "./Menu/Menu";
import MenuWeekExport           from "./Menu/Basic/Pages/MenuWeekExport";
import MenuDayExport            from "./Menu/Basic/Pages/MenuDayExport";
import MenuRestaurantExport     from "./Menu/Basic/Pages/MenuRestaurantExport";
import NewsPage                 from "./News";
import Notifications            from "./Notifications";
import {PlanningMonthExport}    from "./Animation/router_pages";
import {PlanningWeekExport}     from "./Animation/router_pages";
import ProfilePage              from "./Profile";
import PropTypes                from "prop-types";
import React                    from "react";
import ReservationAnimations    from "./Reservation/Planning";
import ReservationMenus         from "./Reservation/Menu";
import Tuto                     from "./Tutoriels/Tuto";
import UserList                 from "./UserList";
import UserSingle               from "./UserList/Basic/Pages/UserSingle";
import useUI                    from "../../hooks/ui.hook";
import HomeStat                 from "./Statistics/statistics";
import ServiceManagement        from "./Service/Management/index";
import ServiceReservation       from "./Service/Reservation/index";
import HomeInterventions from "./Interventions";

import NotificationCenter       from "./Notification/index";


const MajorRouter = ({ match }) => {
  const [ui] = useUI();
  initNotifications();

  return (
    <DashboardLayout menu={<MajorMenu />} header={<DashboardHeader />}>
      <Switch>
        <Route exact path={`${match.url}/one`}>
          <Home />
        </Route>
        <Route exact path={`${match.url}/users`}>
          <UserList />
        </Route>
        <Route exact path={`${match.url}/users/:id`}>
          <UserSingle />
        </Route>
        <Route exact path={`${match.url}/profile`}>
          <ProfilePage />
        </Route>
        <Route exact path={`${match.url}/tuto`}>
          <Tuto />
        </Route>

        <Route exact path={`${match.url}/notificationCenter`}>
          <NotificationCenter />
        </Route>
        
        {ui.establishment.features &&
          ui.establishment.features.includes("menuPlanning") ? (
          <Route exact path={`${match.url}/menu`}>
            <Menu />
          </Route>
        ) : null}
        {ui.establishment.features &&
          ui.establishment.features.includes("menuPlanning") ? (
          <Route exact path={`${match.url}/menu/export`}>
            <MenuWeekExport />
          </Route>
        ) : null}
        {ui.establishment.features &&
          ui.establishment.features.includes("menuPlanning") && ["ovelia", "heurus"].includes(ui?.groupement?.specific) ? (
          <Route exact path={`${match.url}/menu/exportDay`}>
            <MenuDayExport />
          </Route>
        ) : null}

        {ui.establishment.features &&
          ui.establishment.features.includes("menuPlanning") && (ui?.groupement?.specific === "heurus") ? (
          <Route exact path={`${match.url}/menu/exportRestaurant`}>
            <MenuRestaurantExport />
          </Route>
        ) : null}

        {ui.establishment.features &&
          ui.establishment.features.includes("animationPlanning") ? (
          <Route exact path={`${match.url}/animations`}>
            <Animations />
          </Route>
        ) : null}
        {ui.establishment.features &&
          ui.establishment.features.includes("animationPlanning") ? (
          <Route exact path={`${match.url}/animations/weekExport`}>
            <PlanningWeekExport />
          </Route>
        ) : null}
        {ui.establishment.features &&
          ui.establishment.features.includes("animationPlanning") ? (
          <Route exact path={`${match.url}/animations/monthExport`}>
            <PlanningMonthExport />
          </Route>
        ) : null}

        {ui.establishment.features &&
          ui.establishment.features.includes("news") ? (
          <Route exact path={`${match.url}/news`}>
            <NewsPage />
          </Route>
        ) : null}

        {ui.establishment.features &&
          ui.establishment.features.includes("AnimationWorkshopDatabase") ? (
          <Route path={`${match.url}/ateliers`}>
            <Ateliers />
          </Route>
        ) : null}

        {ui.establishment.features &&
          ui.establishment.features.includes("cultureQuizz") ? (
          <Route exact path={`${match.url}/cultureQuizz`}>
            <CultureQuizzQuizzList />
          </Route>
        ) : null}
        {ui.establishment.features &&
          ui.establishment.features.includes("cultureQuizz") ? (
          <Route exact path={`${match.url}/cultureQuizz/edit/:uid`}>
            <CultureQuizzEditor />
          </Route>
        ) : null}
        {ui.establishment.features &&
          ui.establishment.features.includes("cultureQuizz") ? (
          <Route exact path={`${match.url}/cultureQuizz/:uid/start`}>
            <CultureQuizzQuizzStart />
          </Route>
        ) : null}
        {ui.establishment.features &&
          ui.establishment.features.includes("cultureQuizz") ? (
          <Route exact path={`${match.url}/cultureQuizz/:uid/play`}>
            <CultureQuizzQuizzPlay />
          </Route>
        ) : null}
        {ui.establishment.features &&
          ui.establishment.features.includes("cultureQuizz") ? (
          <Route exact path={`${match.url}/cultureQuizz/:uid/results`}>
            <CultureQuizzQuizzResults />
          </Route>
        ) : null}
        {ui.establishment.features &&
          ui.establishment.features.includes("banner") ? (
          <Route path={`${match.url}/banner`}>
            <Notifications />
          </Route>
        ) : null}
        {ui.establishment.features &&
          ui.establishment.features.includes("reservationMenu") ? (
          <Route path={`${match.url}/reservation/menus`}>
            <ReservationMenus />
          </Route>
        ) : null}
        {ui.establishment.features &&
          ui.establishment.features.includes("reservationPlanning") ? (
          <Route path={`${match.url}/reservation/animations`}>
            <ReservationAnimations />
          </Route>
        ) : null}

        {ui.establishment.features &&
          ui.establishment.features.includes("gazette") ? (
          <Route path={`${match.url}/gazette/:uid/edit`}>
            <GazetteEdit />
          </Route>
        ) : null}
        {ui.establishment.features &&
          ui.establishment.features.includes("gazette") ? (
          <Route path={`${match.url}/gazette/edit`}>
            <GazetteEdit />
          </Route>
        ) : null}
        {ui.establishment.features &&
          ui.establishment.features.includes("gazette") ? (
          <Route path={`${match.url}/gazette`}>
            <Gazette />
          </Route>
        ) : null}

        {ui.establishment.features &&
          ui.establishment.features.includes("interventionsRequest") ? (
          <Route path={`${match.url}/interventions`}>
            <HomeInterventions />
          </Route>
        ) : null}

        {ui.establishment.features ? <Route path={`${match.url}/statistics`}><HomeStat /></Route> : null}

        {ui.establishment.features &&
        ui.establishment.features.includes("services") ? (
          <Route path={`${match.url}/service-management`}>
            <ServiceManagement />
          </Route>
        ) : null}

        {ui.establishment.features &&
        ui.establishment.features.includes("services") ? (
          <Route path={`${match.url}/reservation/services`}>
            <ServiceReservation />
          </Route>
        ) : null}

        <Route path={"*"}>
          <Redirect to={`${match.url}/one`} />
        </Route>
      </Switch>
    </DashboardLayout>
  );
};

MajorRouter.propTypes = {
  match: PropTypes.object.isRequired,
};

export default MajorRouter;
