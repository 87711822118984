import React, {createContext, useReducer, useEffect, useState} from "react";
import firebase from "firebase";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import { useParams } from "react-router-dom";
import { updateEstablishmentById } from "services/ressources/establishment";

const firestore = firebase.firestore;
const Context = createContext();

const Default = {
  step: 0,
  subscription: null,
  template: {},
  templateSubscription: {},
  labels: {},
};

function Reducer(state, action) {
  switch(action.type) {
  case "setValue": return ({...state, [action.key]: action.value});
  case "setState": return ({...state, ...action.state});
  default: return ({...state});
  }
}

const Provider = ({children, establishmentId}) => {
  const {id} = useParams();
  const [ctx, reducer] = useReducer(Reducer, Default);

  useEffect(() => {
      firestore().collection("establishments").doc(establishmentId).onSnapshot((res)=>{
        let data = res.data();
        reducer({type: "setValue", key: "template", value: data?.template ?? {}});
        reducer({type: "setValue", key: "templateSubscription", value: data?.templateSubscription ?? {}});
      });
  }, [establishmentId]);

  const copyFromEtab = (etab) => {
    // reducer({type: "setValue", key: "template", value: etab?.template ?? {}});

    const _templateSubscriptionEtab = etab?.templateSubscription ?? {};

    let perfectCopy = true;
    let _templateSubscription = {};

    Object.keys(_templateSubscriptionEtab).forEach(_subName => {
      _templateSubscription[_subName] = {repas: {}};

      Object.keys(_templateSubscriptionEtab[_subName].repas).forEach(_repas => {
        if(Object.keys(ctx.template).includes(_repas)){
          _templateSubscription[_subName].repas[_repas] = [];

          _templateSubscriptionEtab[_subName].repas[_repas].forEach(_categ => {
            if(ctx.template[_repas].categories.includes(_categ)){
              _templateSubscription[_subName].repas[_repas].push(_categ);
            }else{
              perfectCopy = false;
            }
          });

        }else{
          perfectCopy = false;
        }
      });
    });

    if(!perfectCopy){
      toast.warn("Attention, Copie partielle car différence de template.");
    }

    reducer({type: "setValue", key: "templateSubscription", value: _templateSubscription});
  };

  const save = async () => {
    try {
      await updateEstablishmentById({id, data: {
        templateSubscription: ctx.templateSubscription
      }});
      toast.success("Modifications enregistrées");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  const remove = async () => {
    try {
      await updateEstablishmentById({id, data: {
        templateSubscription: null
      }});
      toast.success("Modifications enregistrées");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  return (
    <Context.Provider value={[
      {
        ...ctx,
        save,
        remove,
        copyFromEtab
      },
      reducer
    ]}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.element,
  establishmentId: PropTypes.string.isRequired
};

const useContext = () => React.useContext(Context);

export default useContext;
export {Provider};