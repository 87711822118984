import React from "react";
import PropTypes from "prop-types";
import { FormGroup } from "reactstrap";
import {Input, Label} from "lib/components";
import {
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import "../../../fonts";
import ImagePickerCrop from "lib/components/Form/ImagePickerCrop";
import styles from "../styles/recipe.contentType.module.scss";

const RecipeComponent = (props) => {
  const {
    title,
    portion,
    preparationTime,
    cookingTime,
    author,
    image,
  } = props;

  return (
    <div className={styles.miniature}>
      <h4 className={styles.title}>{title}</h4>
      <img src={image} style={{height: 150}}/>
      <p>Ingrédients pour {portion} personnes</p>
          
      <p>{preparationTime ?? 0} minutes de préparation</p>
      <p>{cookingTime ?? 0} minutes de cuisson</p>
      <p>D&apos;après une idée recette de {author}</p>
    </div>
  );
};
const RecipeEditor = (props) => {
  const {onChange, field} = props;
  return (
    <>
      <h5>Saisir une recette</h5>
      <FormGroup>
        <Input value={field.title} onChange={e => onChange("title", e.target.value)} placeholder="Titre" label="Titre de la recette" />
      </FormGroup>
      <FormGroup>
        <Input value={field.portion} onChange={e => onChange("portion", e.target.value)} placeholder="3" label="Nombre de portions" />
      </FormGroup>
      <FormGroup
        style={{
          display: "flex",
          justifyContent: "space-between",
          columnGap: 4,
        }}
      >
        <div style={{ flex: 1 }}>
          <Input value={field.preparationTime} onChange={e => onChange("preparationTime", e.target.value)} placeholder="3" label="Préparation (en minutes)" />
        </div>
        <div style={{ flex: 1 }}>
          <Input value={field.cookingTime} onChange={e => onChange("cookingTime", e.target.value)} placeholder="3" label="Cuisson (en minutes)" />
        </div>
      </FormGroup>
      <FormGroup>
        <Input type="textarea" cols={100} rows={10} value={field.ingredients} onChange={e => onChange("ingredients", e.target.value)} placeholder="Ingrédient 1" label="Liste des ingrédients (1 par ligne)" />
      </FormGroup>
      <FormGroup>
        <Input type="textarea" cols={100} rows={10} value={field.steps} onChange={e => onChange("steps", e.target.value)} placeholder="Instruction 1" label="Liste des étapes (1 par ligne)" />
      </FormGroup>
      <FormGroup>
        <Input value={field.author} onChange={e => onChange("author", e.target.value)} placeholder="Auteur" label="Auteur de la recette" />
      </FormGroup>
      <FormGroup style={{flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Label>Image d&apos;illustration</Label>
        <ImagePickerCrop
          ratio={1.2}
          onChange={(image) => onChange("image", image)}
          value={field.image}
        />
      </FormGroup>
    </>
  );
};

const RecipePdf = (props) => {
  const {
    title,
    portion,
    preparationTime,
    cookingTime,
    ingredients,
    steps,
    author,
    image,
  } = props;

  const pdfStyle = StyleSheet.create({
    globalContainer: {
      display: "flex",
      alignItems: "center",
      padding: "10px",
      width: "100%",
      height: "100%",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      border: "1px solid #ec871c",
      width: "100%",
      height: "100%",
      padding: "20px",
    },
    pageType: {
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: "18px",
      color: "white",
      backgroundColor: "#1c2140",
      padding: "5px",
      position: "absolute",
      top: "-1%",
      left: "50%",
      transform: "translate(-82%, 0%)",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      marginTop: "10px",
    },
    title: {
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: "22px",
      color: "#e76d90",
      marginBottom: "10px",
    },
    upperContent: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
    },
    upperLeft: {
      width: "50%",

    },
    decorator: {
      position: "absolute",
      bottom: 0,
      right: 0,
      width: "85%",
      height: "80%",
      backgroundColor: "#e76d90",
    },
    imageBlock: {
      width: "50%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingRight: "5px",
      paddingBottom: "5px",
      position: "relative",
      overflow: "hidden",
    },
    imageContainer: {
      width: "100%",
      objectFit: "cover",
    },
    upperRight: {
      display: "flex",
      flexDirection: "column",
      width: "45%",
    },
    lowerContent: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "space-between",
      marginTop: "20px",
    },
    bonAppetit: {
      textAlign: "right",
      color: "#e76d90",
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: "20px"
    },
    author: {  
      color: "#1c2140",
      fontFamily: "Brandon",
      fontSize: "16px"
    },
    portion: {
      color: "#ec871c",
      fontFamily: "Brandon",
      fontSize: "16px",
    },
    info: {
      fontFamily: "Brandon",
      fontSize: "14px",
      color: "#1c2140",
    },
    ingredientsContainer: {
      marginLeft: "5px",
      marginTop: "5px",
      marginBottom: "10px",
    },
    ingredient: {
      marginTop: "5px",
      fontFamily: "Brandon",
      fontSize: "14px",
      color: "#1c2140",
    },
    stepContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      marginTop: "20px",
    },
    step: {
      marginTop: "15px",
    },
    stepText: {
      fontFamily: "Brandon",
      fontSize: "14px",
      color: "#1c2140",
    },
  });
  return (
    <View style={pdfStyle.globalContainer}>
      <View style={pdfStyle.container}>
        <View style={pdfStyle.content}>
          <Text style={pdfStyle.title}>{title}</Text>
          <View style={pdfStyle.upperContent}>
            {image && <View style={pdfStyle.imageBlock}>
              <View style={pdfStyle.decorator}></View>
              <View style={pdfStyle.imageContainer}>
                <Image src={image} />
              </View>
            </View>}
            <View style={pdfStyle.upperRight}>
              <Text style={pdfStyle.portion}>Ingrédients pour {portion} personnes:</Text>
              <View style={pdfStyle.ingredientsContainer}>
                {ingredients !== "" &&ingredients?.split("\n")
                  .map((i, index) => <Text key={index} style={pdfStyle.ingredient}>- {i}</Text>)}
              </View>
              <Text style={pdfStyle.info}>Préparation: {preparationTime} min</Text>
              <Text style={pdfStyle.info}>Cuisson: {cookingTime} min</Text>
            </View>
          </View>
          <View style={pdfStyle.lowerContent}>
            <View style={pdfStyle.stepContainer}>
              {steps?.split("\n")
                .map((i, index) => <View key={index} style={pdfStyle.step}>
                  <Text style={pdfStyle.stepText}>ETAPE {index + 1}</Text>
                  <Text style={pdfStyle.stepText}>{i}</Text>
                </View>)}
            </View>
            <View>
            <Text style={pdfStyle.bonAppetit}>Bon appétit !</Text>
            <Text style={pdfStyle.author}>D&apos;après une idée recette de {author}</Text>
            </View>
          </View>
        </View>
      </View>
      <Text style={pdfStyle.pageType}>A VOS FOURNEAUX !</Text>
    </View>
  );
};

RecipeComponent.propTypes = {
  title: PropTypes.string,
  portion: PropTypes.string,
  preparationTime: PropTypes.string,
  cookingTime: PropTypes.string,
  author: PropTypes.string,
  image: PropTypes.any,
};
RecipeEditor.propTypes = {
  onChange: PropTypes.any,
  field: PropTypes.any,
};
RecipePdf.propTypes = {
  title: PropTypes.any,
  portion: PropTypes.any,
  preparationTime: PropTypes.any,
  cookingTime: PropTypes.any,
  ingredients: PropTypes.any,
  steps: PropTypes.any,
  author: PropTypes.any,
  image: PropTypes.any,
};

export { RecipeComponent, RecipeEditor, RecipePdf };