import React from "react";
import {CustomInput} from "reactstrap";
import PropTypes from "prop-types";
import Label from "./Label";

const Switch = (props) => {
  const {label, checked, className, disabled, onChange} = props;

  return (
    <span className={`d-flex ${className}`}>
      <CustomInput type="switch"
        className={className ?? ""}
        checked={checked}
        disabled={disabled}
        onChange={onChange} />
      {label && <Label>{label}</Label>}
    </span>
  );
};


Switch.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

Switch.defaultProps = {
  className: "",
  disabled: false,
  checked: false,
};

export default Switch;