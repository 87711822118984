import React, { useState, useEffect } from "react";

import CreateEditNews from "./components/CreateEditNews";
import NewsCard from "./components/NewsCard";
import { PlusCircle } from "react-feather";
import { Button, Spinner } from "../../../lib/components";
import moment from "moment";
import styles from "../../../assets/scss/pages/news.module.scss";
import useNews from "../../../hooks/useNews";
import useActus from "resources/actus";
import { VideoTuto } from "lib/components/YoutubeModal";
import useRessources from "services";
import useFeature from "hooks/useFeature";
import BirthdayNews from "./components/BirthdayNews";

const News = () => {
  const dataEtab = useRessources({ressource: "Actus", handler: "getActusForEtab_rt"});
  const dataNatio = useRessources({ressource: "Actus", handler: "getActusForNational_rt"});
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [preFilledData, setPreFilledData] = useState(null);

  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const _data = [...dataEtab?.data ?? [], ...dataNatio?.data ?? []];

    setData(_data);
  }, [dataEtab.data, dataNatio.data]);

  const onClickNews = (_item) => {
    setItemSelected(_item);
    setIsOpen(true);
  };

  if (!data) return <Spinner />;

  return (
    <div className={styles.container}>
      <div style={{marginBottom: 18}}>
        <VideoTuto url="https://youtu.be/3_i0XoifFxU" /*preview={animCard}*//>
      </div>

      <div className={styles.body}>

        
        <BirthdayNews setIsOpen={setIsOpen} setPreFilledData={setPreFilledData}/>
        
        <div className={styles.createCard} onClick={() => setIsOpen(true)}>
          <PlusCircle color="#4A0956" size={52} />
          <span>Créer une actualité</span>
        </div>
        {data.filter(i => moment(i.date.toDate()).isBetween(moment().startOf("month"), moment().endOf("month")))
             .sort((a, b) => moment(b.date.toDate()).format("YYYYMMDD") - moment(a.date.toDate()).format("YYYYMMDD"))
             .map((item) => (
          <NewsCard key={item.uid} item={item} onClick={onClickNews} />
        ))}
        {showAll ? data.filter(i => !moment(i.date.toDate()).isBetween(moment().startOf("month"), moment().endOf("month")))
                       .sort((a, b) => moment(b.date.toDate()).format("YYYYMMDD") - moment(a.date.toDate()).format("YYYYMMDD"))
                       .map((item) => (
          <NewsCard key={item.uid} item={item} onClick={onClickNews} />
        )) : null}
      </div>
      {data.filter(i => !moment(i.date.toDate()).isBetween(moment().startOf("month"), moment().endOf("month"))).length > 0 && !showAll ? 
        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          <Button color="primary" onClick={() => setShowAll(true)}>Voir les anciennes actus</Button>
        </div>
      : null}
      <CreateEditNews
        item={itemSelected}
        preFilledData={preFilledData}
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(false);
          setItemSelected(null);
        }}
      />
    </div>
  );
};

export default News;
