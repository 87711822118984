import React, {useState, useEffect} from "react";
import { Select, Separator, Button } from "lib/components";
import { getAllEtablishments, updateEstablishmentById } from "services/ressources/establishment";
import { toast } from "react-toastify";

const Export = () => {
  const [allEsts, setAllEsts] = useState([]);
  const [estList, setEstList] = useState([]);
  const [est, setEst] = useState(null);
  const [res, setRes] = useState("");

  useEffect(() => {
    (async () => {
      let _ests = await getAllEtablishments();
      setAllEsts(_ests);
      _ests = _ests.map(e => ({value: e.uid, label: e.name}));
      setEstList(_ests);
    })();
  }, []);

  const _export = () => {
    console.log(estList, est);
    let _est = allEsts.find(e => e.uid === est.value);
    setRes(JSON.stringify(_est.template));
  };

  return (
    <>
      <h2>Export</h2>
      <Select 
        label="Établissement"
        options={estList}
        value={est}
        onChange={setEst}
      />
      <textarea style={{height: 240}} value={res}></textarea>
      <div></div>
      <Button onClick={_export}>export</Button>
    </>
  );
};

const Import = () => {
  const [estList, setEstList] = useState([]);
  const [est, setEst] = useState(null);
  const [content, setContent] = useState("");

  useEffect(() => {
    (async () => {
      let _ests = await getAllEtablishments();
      _ests = _ests.map(e => ({value: e.uid, label: e.name}));
      setEstList(_ests);
    })();
  }, []);

  const _import = async () => {
    // console.log("here");
    if (!est) return toast.error("Veuillez choisir un établissement");
    let obj = null;
    try {
      obj = JSON.parse(content); //test if valid json
    } catch (e) {
      toast.error("Le contenu n'est pas un JSON valide");
    }
    console.log(obj);
    await updateEstablishmentById({id: est.value, data: {template: obj}});
    toast.success("Import réussi");
  };

  return (
    <>
      <h2>Import</h2>
      <Select 
        label="Établissement"
        options={estList}
        value={est}
        onChange={setEst}
      />
      <textarea style={{height: 240}} value={content} onChange={e => setContent(e.target.value)}></textarea>
      <div></div>
      <Button onClick={_import}>import</Button>
    </>
  );
};

//--------------------------  -------------------------------//

const ImportExport = () => {
  return (
    <div>
      <Export />
      <Separator />
      <Import />
    </div>
  );
};

export default ImportExport;