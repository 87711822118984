import React, {useState, useEffect} from "react";
import useCtx from "./ctx";
import {Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Separator} from "lib/components";
import ImagePickerCrop from "lib/components/Form/ImagePickerCrop";
import {BookOpen, Edit, Plus, Trash} from "react-feather";

import styles from "./Repas.module.scss";

const Repas = () => {
  const [ctx, dispatch] = useCtx();

  const [isOpen, setIsOpen] = useState(false);
  const [itemOpen, setItemOpen] = useState(null);
  const [title, setTitle] = useState("");
  const [heure, setHeure] = useState(null);

  useEffect(() => {
    if (itemOpen) {
      setTitle(itemOpen.title);
      setHeure(itemOpen.heure);
    } else {
      setTitle("");
      setHeure(null);
    }
  }, [isOpen, itemOpen]);

  const _handleSubmit = () => {
    let _template = {...ctx.template};

    if (itemOpen) {
      _template[title] = {
        ..._template[itemOpen.title],
        heure
      };
      if (itemOpen.title !== title)
        delete _template[itemOpen.title];
    } else {
      _template[title] = {
        heure,
        categories: [],
        menus:  {}
      };
    }
    dispatch({type: "setValue", key: "template", value: {..._template}});
    setIsOpen(false);
    setItemOpen(null);
  };
  const _handleDelete = (key) => {
    let _template = {...ctx.template};
    delete _template[key];
    dispatch({type: "setValue", key: "template", value: {..._template}});
    setIsOpen(false);
    setItemOpen(null);
  };

  return (
    <div className={styles.container}>
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <h3>Repas</h3>
        <Button onClick={() => {setItemOpen(null); setIsOpen(true);}}><Plus /></Button>
      </div>
      <Table>
        <thead>
          <tr>
            <th>Titre</th>
            <th>Horaire</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(ctx.template).map(key => (
            <tr key={key}>
              <td>{key}</td>
              <td>{ctx.template[key].heure}h</td>
              <td style={{display: "flex", gap: 10}}>
                <Edit color="#4a0956" style={{cursor: "pointer"}} onClick={() => {setIsOpen(true); setItemOpen({...ctx.template[key], title: key});}} />
                <BookOpen color="#4a0956" style={{cursor: "pointer"}} onClick={() => dispatch({type: "setState", state: {...ctx, step: 1, repas: key}})} />
                <Trash color="#e16f7e" style={{cursor: "pointer"}} onClick={() => _handleDelete(key)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal isOpen={isOpen} toggle={() => {setIsOpen(false); setItemOpen(null);}} size="large">
        <ModalHeader>
          <h3 style={{marginBottom: 0}}>{itemOpen ? "Modifier un repas" : "Ajouter un repas"}</h3>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input label="Titre" value={title} onChange={e => setTitle(e.target.value)} />
          </FormGroup>
          <FormGroup>
            <Input type="number" min={0} max={24} label="Horaire" value={heure} onChange={e => setHeure(parseInt(e.target.value ?? "0"))} />
          </FormGroup>
        </ModalBody>
        <ModalFooter style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Button color="secondary" onClick={() => {setIsOpen(false); setItemOpen(null);}}>Annuler</Button>
          <Button color="primary" onClick={_handleSubmit} style={{marginRight: 0}}>Valider</Button>
        </ModalFooter>
      </Modal>

    </div>
  );
};

const Labels = () => {
  const [ctx, dispatch] = useCtx();

  const [isOpen, setIsOpen] = useState(false);
  const [itemOpen, setItemOpen] = useState(null);

  const [title, setTitle] = useState("");
  const [img, setImg] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (itemOpen) {
      setTitle(itemOpen.title);
      setImg(itemOpen.img);
      setDescription(itemOpen.description);
    } else {
      setTitle("");
      setImg("");
      setDescription("");
    }
  }, [isOpen, itemOpen]);

  const _handleSubmit = () => {
    let _labels = {...ctx.labels};

    if (itemOpen) {
      _labels[title] = {
        ..._labels[itemOpen.title],
        img,
        description
      };
      if (itemOpen.title !== title)
        delete _labels[itemOpen.title];
    } else {
      _labels[title] = {
        img,
        description
      };
    }
    dispatch({type: "setValue", key: "labels", value: {..._labels}});
    setIsOpen(false);
    setItemOpen(null);
  };

  const _handleDelete = (key) => {
    let _labels = {...ctx.labels};
    delete _labels[key];
    dispatch({type: "setValue", key: "labels", value: {..._labels}});
    setIsOpen(false);
    setItemOpen(null);
  };

  return (
    <div className={styles.container}>
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <h3>Labels</h3>
        <Button onClick={() => setIsOpen(true)}><Plus /></Button>
      </div>
      <Table>
        <thead>
          <tr>
            <th>Titre</th>
            <th>Image</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(ctx.labels).map(key => (
            <tr key={key}>
              <td>{key}</td>
              <td>
                {ctx.labels[key].img ? <img src={ctx.labels[key].img} style={{width: 50, height: 50, borderRadius: 100}} />: null}
              </td>
              <td>{ctx.labels[key].description}</td>
              <td style={{display: "flex", gap: 10}}>
                <Edit color="#4a0956" style={{cursor: "pointer"}} onClick={() => {setIsOpen(true); setItemOpen({...ctx.labels[key], title: key});}} />
                <Trash color="#e16f7e" style={{cursor: "pointer"}} onClick={() => _handleDelete(key)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal isOpen={isOpen} toggle={() => {setIsOpen(false); setItemOpen(null);}} size="lg">
        <ModalHeader>
          <h3 style={{marginBottom: 0}}>{itemOpen ? "Modifier un label" : "Ajouter un label"}</h3>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input label="Titre" value={title} onChange={e => setTitle(e.target.value)} />
          </FormGroup>
          <FormGroup>
            <span>Icone:</span>
            <ImagePickerCrop 
              ratio={1/1}
              onChange={setImg}
              value={img}
            />
          </FormGroup>
          <FormGroup>
            <Input type="textarea" label="Description" value={description} onChange={e => setDescription(e.target.value)} />
          </FormGroup>
        </ModalBody>
        <ModalFooter style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Button color="secondary" onClick={() => {setIsOpen(false); setItemOpen(null);}}>Annuler</Button>
          <Button color="primary" onClick={_handleSubmit} style={{marginRight: 0}}>Valider</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const Page = () => (
  <div>
    <Repas />
    <Separator style={{marginBottom: 30}} />
    <Labels />
  </div>
);

export default Page;