import React from "react";
import PropTypes from "prop-types";

import Card from "lib/components/Stats/Card";
import NumberDisplay from "lib/components/Stats/NumberDisplay";
import Chart from "react-apexcharts";
import Table from "lib/components/Stats/table";

import { ArrowUp, ArrowDown } from "react-feather";

import { percent, mean, extractElementsFromObject } from "./operations";

const UserNumber = ({type, data1, data2}) => {

  if(type === "groupement"){

    let _number = data1.users.count;
    let _variation = percent(_number,data2.users.count);

    const array = extractElementsFromObject(data1.establishments, "users/count");
    let _mean = mean(array);
    let _meanVariation = percent(_mean,mean(extractElementsFromObject(data2.establishments, "users/count")));

    let data = [];

    let _list = data2.establishments;

    data1.establishments.forEach(_obj => {
      let etabLastPeriod = null;
      _list.forEach(_etab => {
        if(_etab.id === _obj.id)etabLastPeriod = _etab;
      });

      const _etabVariation = percent(_obj.users.count, etabLastPeriod.users.count);
      data.push({
        etab: _obj.name,
        value: _obj.users.count,
        // variation: _etabVariation,
      });
    });
        //   let data = [{etab: "cc", value: 25, variation: -2}, {etab: "aaa", value: 35, variation: 5}, {etab: "eerrr", value: 10, variation: 3}, {etab: "eut", value: 28, variation: -1}];


    const columns = [
      {
        Header: "Établissement",
        accessor: "etab",
        Footer: "",
        disableSortBy: false,
        width: 150,
      },
      {
        Header: "Nombre",
        accessor: "value",
        Footer: "",
        disableSortBy: false,
      },
      // {
      //   Header: "variation",
      //   accessor: "variation",
      //   Footer: "",
      //   disableSortBy: false,
      //   Cell: CellFunc
      // }
    ];

    return (
      <div>
        <Card title="Nombre d'Utilisateurs" info="Indique le nombre d'utilisateurs pour tous les établissements du groupement" size="lg">
            
        <NumberDisplay number={_number} mean={_mean}/>
          <div style={{height: "70%", width: "100%"}}></div>
          <div style={{position: "absolute", bottom: -1, left: 0, right: 0, top: "35%", overflow: "hidden"}}>
            <Table _columns={columns} _data={data} hasFooter={false} onClick={() => (null)}/>
          </div>
        </Card>
      </div>
    );
  }
  return (
    <div>

    </div>
  );
};

    const CellFunc = ({ cell }) => {
      return <div style={{color: cell.value >= 0 ? "green" : "red"}}>
          {cell.value >= 0 ? <ArrowUp size={16} color="green"/> : <ArrowDown size={16} color="red"/>} {cell.value >= 0 ? "+" : ""}{cell.value}%
      </div>;
    };
    CellFunc.propTypes = {
      cell: PropTypes.object.isRequired,
    };

UserNumber.propTypes = {
  type: PropTypes.string,
  data1: PropTypes.object,
  data2: PropTypes.object,
};

export default UserNumber;
