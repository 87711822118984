import {
  Dribbble,
  Edit,
  Home,
  Award,
} from "react-feather";

import { faRss, faPrint } from "@fortawesome/free-solid-svg-icons";
import { SidebarImage, SidebarLink, SidebarSubMenu } from "../lib/components/dashboard";

import Logo from "../assets/images/jabiLogo/MAJOR_BLANC.png";
import React from "react";
import useUI from "../hooks/ui.hook";

const SidebarMenu = () => {
  const [ui] = useUI();

  const comm = <>

    {ui.establishment.features &&
        ui.establishment.features.includes("menuPlanning") ? (
        <SidebarLink to="/dashboard/menu" iconAfter={faRss}>
            Menus
        </SidebarLink>
      ) : (
        <></>
      )}

  </>;


  return (
    <div style={{ position: "relative", top: -20 }}>
      <SidebarImage img={Logo} />
      <SidebarLink to="/dashboard/one" icon={Home}>
        Accueil
      </SidebarLink>

      <SidebarSubMenu title="communication">{comm}</SidebarSubMenu>

    </div>
  );
};

export default SidebarMenu;
