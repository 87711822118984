import { FormGroup, Input } from "lib/components";
import PropTypes from "prop-types";
import PdfPickerCrop from "lib/components/Form/PdfPickerCrop";

import {
  Image,
  StyleSheet,
  View,
  Text
} from "@react-pdf/renderer";

import React from "react";
import styles from "assets/scss/pages/Gazette/contentTypes/image.module.scss";

import "../fonts";

const PdfComponent = (props) => {
  const { image, format } = props;

  return (
    <div className={`${styles.editorView} ${styles[format]}`}>
      <img src={image} />
    </div>
  );
};

const PdfEditor = (props) => {
  const { onChange, onSubmit, onCancel, field } = props;

  return (
    <>
      <FormGroup style={{flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <PdfPickerCrop
          ratio={338/524}
          onChange={(image) => onChange("image", image)}
          value={field.image}
        />
        <Input
          value={field.legend}
          onChange={(e) => onChange("legend", e.target.value)}
          label="Légende"
        />
      </FormGroup>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 10,
        }}
      >
        {/*<Button color="secondary" onClick={onCancel}>
          Annuler
        </Button>
        <Button color="primary" onClick={onSubmit}>
          Valider
      </Button>*/}
      </div>
    </>
  );
};

const ImagePdfPDF = (props) => {
  const { settings, image, legend} = props;

  
const pdfStyle = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    objectFit: "cover",
  },
  imageLegend: {
    marginTop: 5,
    fontSize: settings?.fontSize4 ?? 12,
    fontFamily: settings?.fontFamily ?? "Roboto",
    textAlign: "center",
    textDecoration: "underline",
  },
});


  return (
    <View style={pdfStyle.container}>
      {image && <Image src={image} style={pdfStyle.image} />}
      {legend && legend.length > 0 ? <Text style={pdfStyle.imageLegend}>{legend}</Text> : null}
    </View>
  );
};

//TODO Definir les proptypes 
PdfComponent.propTypes = {
  image : PropTypes.any,
  format : PropTypes.any,
};

PdfEditor.propTypes = {
  onChange : PropTypes.any,
  onSubmit : PropTypes.any,
  onCancel : PropTypes.any,
  field : PropTypes.any,
};

ImagePdfPDF.propTypes = {
  image : PropTypes.any,
  settings : PropTypes.any,
  legend: PropTypes.any,
};

export { PdfComponent, PdfEditor, ImagePdfPDF };
