import PropTypes from "prop-types";
import {
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import {Input, Label} from "lib/components";
import { FormGroup } from "reactstrap";

import "../../../fonts";
import ImagePickerCrop from "lib/components/Form/ImagePickerCrop";

const ArticleComponent = (props) => {
  const {title, content} = props;
  const _content = (content && content.length > 0) ? (
    (content.length <= 47) ? content : content.substring(0, 47) + "..."
  ) : null;
  return (
    <div>
      <h5>{title}</h5>
      <p>{_content ? `«${_content}»`: ""}</p>
    </div>
  );
};

const ArticleEditor = (props) => {
  const {
    onChange,
    field
  } = props;


  return (
    <>
      <FormGroup>
        <Input value={field.title} onChange={e => onChange("title", e.target.value)} placeholder="Titre" label="Titre" />
      </FormGroup>
      <FormGroup>
        <Input type="textarea" cols={100} rows={10} value={field.content} onChange={e => onChange("content", e.target.value)} placeholder="Votre contenu ici" label="Contenu de l'article" />
      </FormGroup>
      <FormGroup style={{flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Label>Image d&apos;illustration</Label>
        <ImagePickerCrop
          ratio={0.8}
          onChange={(image) => onChange("image", image)}
          value={field.image}
        />
      </FormGroup>
    </>
  );
};

const ArticlePdf = (props) => {
  const {title, content, image} = props; 

  const pdfStyle = StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "10px",
      gap: "10px",
      marginBottom: 5,
    },
    title: {
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: 16,
      color: "#1c2140",
      
    },
    content: {
      fontFamily: "Brandon",
      fontSize: 12,
      color: "#1c2140",
    },
    textContentContainer: {
      display: "flex",
      flexDirection: "column",
    },
    imageContainer: {
      objectFit: "cover",
      height: 180,
    },
    
  });

  return (
    <View style={pdfStyle.container}>
      <View style={[pdfStyle.textContentContainer, image ? {width: "68%"} : {width: "100%"}]}>
        <Text style={pdfStyle.title}>{title}</Text>
        <Text style={pdfStyle.content}>{content}</Text>
      </View>
      {image &&
        <View style={pdfStyle.imageContainer}>
         <Image src={image} style={pdfStyle.image}/>
        </View>
      }
    </View>
  );
};

ArticleComponent.propTypes = {
  title: PropTypes.any,
  content: PropTypes.any,
};
ArticleEditor.propTypes = {
  onChange: PropTypes.any,
  field: PropTypes.any,
};
ArticlePdf.propTypes = {
  title: PropTypes.any,
  content: PropTypes.any,
  image: PropTypes.any
};

export { ArticleEditor, ArticleComponent, ArticlePdf };