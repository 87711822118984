import React,{useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Modal, ModalHeader, ModalBody, ModalFooter,Button,Select} from "lib/components";
import { useServiceManagement } from "../../routerContext";
// import "react-tippy/dist/tippy.css";
import styles from "../Scss/service.module.scss";
import { CategorieCard } from "../../routerComponents";

const CreatePrestataireModal = (props) => {
  const {
    isOpen,
    setIsModalVisible,
    toggle,
  } = props;
  const [ctx,{updatePage,updateChosenService}] = useServiceManagement();
  const [categoriesFiltered, setCategoriesFiltered] = useState([]); //tab des catégories
  const [category, setCategory] = useState(null);

  const handleClick=(_data)=>{
    setIsModalVisible(false);
    updateChosenService(_data.name);
    updatePage(2); 
  };

  useEffect(() => {
   if(category){
    const filteredList = ctx.availableServices.filter(service => service.name === category.label);
    setCategoriesFiltered(filteredList);
   }else{
    setCategoriesFiltered(ctx.availableServices);
  }
    
  },[category,ctx.availableServices]);

  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle} >
      <ModalHeader>Choix du type de service</ModalHeader>
      <ModalBody >
      <div style={{ display: "flex", gap: "5px", width: "fit-content"}}>
          <Select
            placeholder="Catégories"
            clearable
            options={ctx.availableServices.map((category) => ({ label: category.name, value: category.name }))}
            value={category}
            onChange={(e) => setCategory(e ?? null)}
          />
        </div>
        <div className={styles.bodyContainer} >
        {categoriesFiltered.map((_data,index)=><CategorieCard data={_data} key={index} onClick={() => handleClick(_data)}></CategorieCard>)} 

        </div>
      </ModalBody>
      <ModalFooter style={{ justifyContent: "center", gap: "5px" }}>
        <Button  color='primary' onClick={toggle}>Annuler</Button>
      </ModalFooter>
    </Modal>
  );

};

CreatePrestataireModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  setIsModalVisible:PropTypes.func,
  data: PropTypes.object,
  
};

export default CreatePrestataireModal;