import React, { useState, useEffect, useMemo, useCallback } from "react";
import useUI from "hooks/ui.hook";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
  FormGroup,
  Select,
  Tag,
} from "lib/components";

import deepEqual from "deep-equal";
import { ModalInformation, CategorySelector } from "../../router_components";
import { useMenuReservation } from "../../router_context";

import deepClone from "helpers/deepClone";
import styles from "../Assets/scss/modalReservation.module.scss";
import { getDefaultRepas } from "../../../../Menu/helpers/operations";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faUserMinus } from "@fortawesome/free-solid-svg-icons";
import { sendNotifToCentreNotif } from "services/ressources/notification";

const colors = ["#8c66dc", "#dc6681", "#00bfb2", "#DC965A", "#81171B"];
const colorUnite = "#123865";

const ModalReservation = () => {
  const [ui] = useUI();
  const [ctx, dispatch] = useMenuReservation();

  const template = ui.establishment.template;
  const templateSubscription = ui.establishment.templateSubscription;


  const [reservation, setReservation] = useState([]);
  const [empty, setEmpty] = useState(false);

  const [page, setPage] = useState(0);

  //aliases
  const dataDay = ctx?.data[ctx?.modalInfos?.date];
  const currentUser = ctx?.users[ctx?.modalInfos?.uid] ?? {};
  const subscription = currentUser?.subscription && currentUser?.subscriptionMonths && currentUser?.subscriptionMonths.includes(moment(ctx?.selectedDate).format("YYYY-MM")) ? currentUser?.subscription : null;
  const isEmployee = currentUser.role === "employee";

  useEffect(() => {
    try {
      setPage(0);
      console.log(ctx.modalInfos);
      console.log(ctx.data);

      const _resa = deepClone(ctx.data[ctx.modalInfos.date].reservation[ctx.modalInfos.uid][ctx.modalInfos.repas]);
      console.log(_resa);

      if (_resa) {
        setReservation(_resa);

        if (_resa[0] && _resa[0].status !== "canceled") {
          setEmpty(false);
        } else {
          setEmpty(true);
        }
      } else {
        setReservation([defaultReservationObject()]);
        setEmpty(true);
      }

    } catch (error) {
      setReservation([defaultReservationObject()]);
      setEmpty(true);
      setPage(0);
    }
  }, [ctx.modalResaOpen]);


  const defaultReservationObject = () => {
    if (template && ctx?.modalInfos?.repas && template[ctx.modalInfos.repas].categories) {
      const _defaultMenu = {};

      template[ctx.modalInfos.repas].categories.map((_categ) => _defaultMenu[_categ] = null);
      _defaultMenu["supplément"] = null;

      const _resaObj = {
        ..._defaultMenu,
        homeDelivery: reservation?.[0]?.homeDelivery ?? false
      };
      return _resaObj;

    } else {
      return {
        homeDelivery: false
      };
    }
  };

  const _save = () => {
    ctx.updateMenu(reservation);
    const _initial = ctx.data[ctx.modalInfos.date] && ctx.data[ctx.modalInfos.date].reservation && ctx.data[ctx.modalInfos.date].reservation[ctx.modalInfos.uid] ? ctx.data[ctx.modalInfos.date].reservation[ctx.modalInfos.uid][ctx.modalInfos.repas] ?? [] : [];
    const dateFinal = new Date(ctx?.modalInfos?.date);
    if(_initial.length === 0 ){
      sendNotifToCentreNotif({
      ui,
      data: {
        type: "menu",
        action: "add",
        data: {
          homeDelivery: reservation[0]?.homeDelivery,
          quantity: reservation?.length,
          userId: currentUser.uid,
          name: ctx?.modalInfos?.repas,
          date: dateFinal,
        }
      }
    }); 
    }else{
      sendNotifToCentreNotif({
      ui,
      data: {
        type: "menu",
        action: "update",
        data: {
          homeDelivery: reservation[0]?.homeDelivery,
          quantity: reservation?.length,
          userId: currentUser.uid,
          name: ctx?.modalInfos?.repas,
          date: dateFinal,
        }
      }
    }); }
    dispatch({ type: "setProperty", property: "modalResaOpen", value: false });
  };

  const _delete = () => {
    ctx.updateMenu("delete");
    const dateFinal = new Date(ctx?.modalInfos?.date);
    sendNotifToCentreNotif({
      ui,
      data: {
        type: "menu",
        action: "delete",
        data: {
          homeDelivery: reservation[0]?.homeDelivery,
          quantity: reservation?.length,
          userId: currentUser.uid,
          name: ctx?.modalInfos?.repas,
          date: dateFinal,
        }
      }
    }); 
    dispatch({ type: "setProperty", property: "modalResaOpen", value: false });
  };


  const updateHomeDelivery = () => {
    let currentValue = reservation[0].homeDelivery;

    let _resa = deepClone(reservation);

    _resa.forEach(element => {
      element.homeDelivery = !currentValue;
    });

    setReservation(_resa);
  };

  const updateSelection = (category, choice) => {
    let currentResa = deepClone(reservation);

    currentResa[page][category] = choice;

    setReservation(currentResa);
  };

  const addResa = () => {
    let currentResa = deepClone(reservation);

    currentResa.push(defaultReservationObject());

    setReservation(currentResa);
    setPage(currentResa.length - 1);
  };

  const removeResa = () => {
    if (page > 0 && page < reservation.length) {
      let currentResa = deepClone(reservation);

      currentResa.splice(page, 1);

      setReservation(currentResa);
      setPage(page - 1);
    }
  };

  const hasChanged = useMemo(() => {
    // On compare la resa actuelle à la resa initiale :
    const _initial = ctx.data[ctx.modalInfos.date] && ctx.data[ctx.modalInfos.date].reservation && ctx.data[ctx.modalInfos.date].reservation[ctx.modalInfos.uid] ? ctx.data[ctx.modalInfos.date].reservation[ctx.modalInfos.uid][ctx.modalInfos.repas] ?? [] : [];

    if (deepEqual(_initial, reservation)) {
      return false;
    } else {
      return true;
    }
  }, [reservation, ctx]);


  return (
    <Modal isOpen={ctx.modalResaOpen} toggle={() => dispatch({ type: "setProperty", property: "modalResaOpen", value: false })} className={styles.modal}>
      <ModalHeader className={styles.modalHeader}>
        <div className={styles.modalHeaderComponent}>

          <div className={styles.modalTitle}>
            Réservation pour le {ctx?.modalInfos?.repas} du <span className={styles.headerDate}>{(ctx?.modalInfos?.date ?? "").split("-").reverse().join("/")}</span>
          </div>

          <div className={styles.headerContent}>
            {page === 0 && templateSubscription ?
              <Tag className={styles.tag} style={{ backgroundColor: subscription ? colors[Object.keys(templateSubscription).indexOf(subscription) % 5] : colorUnite }}><div>{subscription ?? "Unité"}</div></Tag>
              : null}
          </div>

        </div>
        
      </ModalHeader>
      <ModalBody style={{ padding: 0 }}>
        <div className={styles.headerResa}>
          <div className={styles.resaList}>
            <div className={page === 0 ? styles.resaSelected : null} onClick={() => setPage(0)}> {currentUser?.surname} {currentUser?.name}</div>
            {reservation.length > 1 ?
              reservation.map((_r, indx) => (
                indx > 0 ?
                  <div key={indx} onClick={() => setPage(indx)} className={page === indx ? styles.resaSelected : null}>
                    <div>
                      {`(Invité ${indx})`}
                    </div>
                  </div>
                  : null
              ))
              : null}
          </div>
          <div className={styles.resaButton}>
              {!isEmployee ?
                <>
                  {page > 0 ? <div color="danger" onClick={removeResa} style={{ color: "red", textAlign: "center" }}> <FontAwesomeIcon icon={faUserMinus} color="red" /> Supprimer cette invitation</div> : null}
                  <div onClick={addResa} style={{textAlign: "center"}} > <FontAwesomeIcon icon={faUserPlus} color="#300438" /> Inviter une personne</div>
                </>
                : null}
          </div>
        </div>
        <div className={styles.modalContainer}>
          <ModalInformation reservation={reservation} page={page} />
          <div className={styles.contentHorizontal}>
            <div className={styles.contentVertical}>
              <div className={styles.content}>

                {reservation ?
                  <CategorySelector page={page} dataReservation={reservation[page]} updateSelection={(category, choice) => updateSelection(category, choice)} />
                  : null}

              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className={styles.footer}>
          <div>
            <Checkbox className={styles.checkbox} label="portage" checked={reservation ? reservation[0]?.homeDelivery : false} onChange={updateHomeDelivery} />
          </div>
          <div className={styles.footerButtons} style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
            {!empty ?
              <Button color="danger" onClick={_delete}>{reservation && reservation[0] && reservation[0].createdLocally ? "Annuler la réservation automatique" : "Supprimer"}</Button>
              : null}
            <Button color="secondary" onClick={() => dispatch({ type: "setProperty", property: "modalResaOpen", value: false })}>Annuler</Button>
            {dataDay ? <Button color="primary" disabled={!hasChanged} onClick={_save}>Valider la réservation</Button> : null}
          </div>
          <div className={styles.nbresas}>
            Total : {reservation ? reservation.length : 0} repas
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const SuspenseHOC = (props) => {
  const [ctx] = useMenuReservation();

  if (!ctx || !ctx.data || !ctx.modalInfos) return <></>;
  return <ModalReservation {...props} />;
};


export default SuspenseHOC;
