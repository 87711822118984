import * as PropTypes from "prop-types";

import { Button, Col, Input, Row } from "../../../../../lib/components";
import React, { useCallback, useEffect, useState } from "react";

import firebase from "firebase";
import { useFirebaseDocument } from "lib/hooks/firebase";
import useUI from "hooks/ui.hook";
import { toast } from "react-toastify";

const firestore = firebase.firestore();

const Banner = () => {
  const [ui, dispatch] = useUI();
  const [banniere, setBanniere] = useState("");

  const [estRef] = useState(
    firestore.collection("establishments").doc(ui.user.establishment).collection("blocks").doc("menu")
  );
  const { data: est, update } = useFirebaseDocument(estRef, {});

  useEffect(() => {
    if (!est) return;
    setBanniere(est.banner);
  }, [est]);

  const sendToDB = () => {
    update({banner : banniere});
    toast.success("La bannière a été envoyée vers l'application");
  };

  const updateBanner = (value) => {

    if(value.length < 82)
        setBanniere(value);
    else
        toast.warning("Vous avez atteint le nombre maximum de caractères");

  };

  return (
    <Row>
      <Col lg={12} style={{borderStyle: "solid", borderWidth: 1, borderBottomWidth: 1, borderTopWidth: 1, borderLeftWidth: 0, borderRightWidth: 0, backgroundColor: "#f0eaf3" }}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <div style={{fontSize: 26, fontWeight: "bold", textAlign: "center", marginTop: 10}}>{"Bannière d'informations".toLocaleUpperCase()}</div>

        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", paddingBottom: 30, marginTop: 20}}>
            <div style={{flex: 2, fontSize: 16, textAlign: "end", marginRight: 10}}>Partager aux résidents (sur l'application) :</div>
            <div style={{flex: 3}}>
                <Input
                    type="text"
                    placeholder="Ex : Réservations des menus avant dimanche 17h"
                    onChange={(e) => updateBanner(e.target.value)}
                    value={banniere}
                    style={{borderWidth: 3,borderColor: est.banner === banniere ? "green" : "orange"}}
                />
            </div>
            <div style={{flex: 2}}>
              <Button onClick={sendToDB} color="secondary" style={{marginLeft: 60}}>Envoyer</Button>
            </div>
        </div>
      </Col>
      
    </Row>
  );
};

Banner.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};

export default Banner;
