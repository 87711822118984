
const useAteliers = () => {
  const ateliers = {
    "Contrôle et coordination": {
      "Adaptation": {
        "Atelier 1 - Enigme": {
          "Thème 1 - Enigme et casse tête": {
            "Enigme et casse tête thème 1": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/enigme/Enigme_et_casse_tete_theme1.pdf"
            },
            "Template thème 1 - enigme et casse-tête": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/enigme/Template_enigme_et_casse_tete.pdf"
            },
          },
          "Thème 2 - enigme et casse tête": {
            "Enigme et casse tête théme 2": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/enigme/Enigme_et_casse_tete_theme2.pdf"
            },
            "Template thème 2 - enigme et casse-tête": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/enigme/Template_enigme_et_casse_tete.pdf"
            }
          }
        },
        "Atelier 2 - marathon": {
          "Thème 1 - Jeu de société": {
            "Marathon thème 1  jeu de société": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/marathon/Template_Marathon_theme_1_jeux_de_societe.pdf"
            },
            "Template Marathon thème 1  jeux de société": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/marathon/Marathon_theme_1_jeu_de_societe.pdf"
            },
          }, 
          "Thème 2 - Casse tête": {
            "Marathon thème 2  casse tête": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/marathon/Template_Marathon_theme_2_casse_tete.pdf"
            },
            "Template  Marathon  thème 2  casse-tête": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/marathon/Marathon_theme_2_casse_tete.pdf"
            }
          }
        },
        "Atelier 3 - débat": {
          "Thème 1 - Débat": {
            "Débat théme  1": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/debat/Template_Debat_theme_1.pdf"
            },
            "Template Débat - thème 1": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/debat/Debat_theme_1.pdf"
            },
          },
          "Thème 2 - Débat": {
            "Débat théme  2": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/debat/Template_Debat_theme_2.pdf"
            },
            "Template Débat - thème 2": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/debat/Debat_theme_2.pdf"
            }
          }
        },
        "Atelier 4: le mot juste": {
          "Thème 1 - Le mot juste": {
            "Le mot juste - thème 1": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/le_mot_juste/Le_mot_juste_theme_1.pdf"
            },
            "Template - le mot juste - thème 1": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/le_mot_juste/Template_le_mot_juste_theme_1.pdf"
            },
          },
          "Thème 2 - Le mot juste": {
            "Le mot juste - thème 2": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/le_mot_juste/Le_mot_juste_theme_2.pdf",
            },
            "Template  le mot juste - thème 2": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/le_mot_juste/Template_le_mot_juste_theme_2.pdf"
            }
          }
        },
        "Atelier 5: deux questions en même temps": {
          "Thème 1 - Deux questions en même temps": {
            "deux questions en même temps  thème 1": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/deux_question_en_meme_temps/Template_deux_questions_en_meme_temps_theme_1.pdf"
            },
            "Template  deux question en même temps - thème 1": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/deux_question_en_meme_temps/deux_questions_en_meme_temps_theme_1.pdf"
            },
          },
          "Thème 2 - Le mot juste": {
            "Template  le mot juste - thème 2": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/deux_question_en_meme_temps/Template_deux_questions_en_meme_temps_theme_2.pdf"
            },
            "Template deux question en même temps - théme 2": {
              link: "https://cdn.jabi.fr/ateliers/adaptation/deux_question_en_meme_temps/deux_questions_en_meme_temps_theme_2.pdf"
            }
          }
        }
      },
      "Résistance": {
        "Atelier 1 - peinture": {
          "Thème 1 - Peinture": {
            "Peinture thème 1": {
              link: "https://cdn.jabi.fr/ateliers/resistance/peinture/Peinture_theme_1.pdf"
            },
            "Template - peinture thème 1": {
              link: "https://cdn.jabi.fr/ateliers/resistance/peinture/Template_peinture.pdf"
            },
          },
          "Thème 2 - Peinture": {
            "Peinture théme 2": {
              link: "https://cdn.jabi.fr/ateliers/resistance/peinture/Peinture_theme_2.pdf"
            },
            "Template peinture - thème 2": {
              link: "https://cdn.jabi.fr/ateliers/resistance/peinture/Template_peinture.pdf"
            }
          }
        },
        "Atelier 2 - jeu de la suite": {
          "Thème 1 - Jeu de la suite": {
            "Template - jeu de la suite thème 1": {
              link: "https://cdn.jabi.fr/ateliers/resistance/jeu_de_la_suite/Template_jeu_de_la_suite.pdf"
            },
            "Jeu de la suite thème 1": {
              link: "https://cdn.jabi.fr/ateliers/resistance/jeu_de_la_suite/jeu_de_la_suite_theme_1.pdf"
            },
          },
          "Thème 2 - Jeu de la suite": {
            "Jeu de la suite thème 2": {
              link: "https://cdn.jabi.fr/ateliers/resistance/jeu_de_la_suite/jeu_de_la_suite_theme_2.pdf"
            },
            "Template - jeu de la suite thème 2": {
              link: "https://cdn.jabi.fr/ateliers/resistance/jeu_de_la_suite/Template_jeu_de_la_suite.pdf"
            }
          }
        },
        "Atelier 3 - land art": {
          "Thème 1 - Land art": {
            "Land art théme 1": {
              link: "https://cdn.jabi.fr/ateliers/resistance/land_art/Land_art_theme_1.pdf",
            },
            "Template - land art thème 1": {
              link: "https://cdn.jabi.fr/ateliers/resistance/land_art/Template_land_art.pdf"
            },
          },
          "Thème 2 - Land art": {
            "Land art thème 2": {
              link: "https://cdn.jabi.fr/ateliers/resistance/land_art/Land_art_theme_2.pdf"
            },
            "Template - land art thème 2": {
              link: "https://cdn.jabi.fr/ateliers/resistance/land_art/Template_land_art.pdf"
            }
          }
        },
        "Atelier 4 atelier de relaxation": {
          "Thème 1 - Relaxation et gym douce": {
            "Relaxation et gym douce thème 1": {
              link: "https://cdn.jabi.fr/ateliers/resistance/atelier_relaxation/Template_relaxation_et_gym_douce_theme_1.pdf"
            },
            "Template relaxation et gym douce - thème 1": {
              link: "https://cdn.jabi.fr/ateliers/resistance/atelier_relaxation/Relaxation_et_gym_douce_theme_1.pdf"
            },
          },
          "Thème 2 - Relaxation et gym douce": {
            "Relaxation et gym douce thème 2": {
              link: "https://cdn.jabi.fr/ateliers/resistance/atelier_relaxation/Template_relaxation_et_gym_douce_theme_2.pdf"
            },
            "Template relaxation et gym douce - thème 2": {
              link: "https://cdn.jabi.fr/ateliers/resistance/atelier_relaxation/Relaxation_et_gym_douce_theme_2.pdf"
            }
          }
        },
        "Atelier 5 jeu du bingo": {
          "Thème 1 - Les mots": {
            "Jeu du bingo les mots thème 1 ": {
              link: "https://cdn.jabi.fr/ateliers/resistance/jeu_du_bingo/Jeu_du_bingo_theme_1_les_mots.pdf"
            },
            "Template Jeu du Bingo thème 1 - les mots": {
              link: "https://cdn.jabi.fr/ateliers/resistance/jeu_du_bingo/Template_Jeu_du_Bingo_Theme_1.pdf"
            },
          },
          "Thème 2 - Les numéros": {
            "Jeu du bingo les numéros thème 2": {
              link: "https://cdn.jabi.fr/ateliers/resistance/jeu_du_bingo/Jeu_du_bingo_theme_2_les_mots.pdf"
            },
            "Template jeu du bingo thème 2 - les numéros": {
              link: "https://cdn.jabi.fr/ateliers/resistance/jeu_du_bingo/Template_Jeu_du_Bingo_Theme_2.pdf"
            }
          }
        }
      },
      "Planification": {
        "Atelier 1 - Créer une histoire": {
          "Thème 1 - Créer une histoire": {
            "Créer une histoire théme 1": {
              link: "https://cdn.jabi.fr/ateliers/planification/creer_une_histoire/Creer_une_histoire_theme_1.pdf"
            },
            "Template créer une histoire - thème 1": {
              link: "https://cdn.jabi.fr/ateliers/planification/creer_une_histoire/Template_creer_une_histoire.pdf"
            },
          },
          "Thème 2 - Créer une histoire": {
            "Créer une histoire théme 2": {
              link: "https://cdn.jabi.fr/ateliers/planification/creer_une_histoire/Creer_une_histoire_theme_2.pdf"
            },
            "Template créer une histoire - thème 2": {
              link: "https://cdn.jabi.fr/ateliers/planification/creer_une_histoire/Template_creer_une_histoire.pdf"
            }
          }
        },
        "Atelier 2 - faire mon arbre généalogique": {
          "Thème 1 - Mes prédécesseurs": {
            "Mon arbre généalogique - mes prédécesseurs": {
              link: "https://cdn.jabi.fr/ateliers/planification/arbre_genealogique/Mon_arbre_genealogique_theme_1_mes_predecesseurs.pdf"
            },
            "Template mon arbre généalogique - mes prédécesseurs": {
              link: "https://cdn.jabi.fr/ateliers/planification/arbre_genealogique/Template_mon_arbre_genealogique.pdf"
            },
          },
          "Thème 2 - Mes seccusseurs": {
            "Mon arbre généalogique - mes successeurs": {
              link: "https://cdn.jabi.fr/ateliers/planification/arbre_genealogique/Mon_arbre_genealogique_theme_1_mes_successeurs.pdf"
            },
            "Template mon arbre généalogique - mes successeurs": {
              link: "https://cdn.jabi.fr/ateliers/planification/arbre_genealogique/Template_mon_arbre_genealogique.pdf"
            }
          }
        },
        "Atelier 3 - Réorganiser la phrase": {
          "Thème 1 - Remettre de l'ordre": {
            "Remettre de l'ordre - thème 1": {
              link: "https://cdn.jabi.fr/ateliers/planification/remettre_de_lordre/Remettre_de_l_ordre_thematique_1.pdf"
            },
            "Template Remettre de l'ordre - thème 1": {
              link: "https://cdn.jabi.fr/ateliers/planification/remettre_de_lordre/Template_remettre_dans_l_ordre.pdf"
            },
          },
          "Thème 2 - Remettre de l'ordre": {
            "Remettre de l'ordre - thème 2": {
              link: "https://cdn.jabi.fr/ateliers/planification/remettre_de_lordre/Remettre_de_l_ordre_thematique_2.pdf"
            },
            "Template remettre dans l'ordre - thème 2": {
              link: "https://cdn.jabi.fr/ateliers/planification/remettre_de_lordre/Template_remettre_dans_l_ordre.pdf"
            }
          }
        },
        "Atelier 4 organiser un événement": {
          "Thème 1 - Organiser un évènement": {
            "Organiser un événement  thème 1 organiser un événement au sein de la structure.pdf": {
              link: "https://cdn.jabi.fr/ateliers/planification/organiser_un_evenement/Organiser_un_evenement_theme_1.pdf"
            },
            "Template  organiser un événement - thème  1 _  évènement en intérieur.pdf": {
              link: "https://cdn.jabi.fr/ateliers/planification/organiser_un_evenement/Template_organiser_un_evenement_theme_1_sortie_en_exterieur.pdf"
            },
          },
          "Thème 2 - Organiser un évènement": {
            "Organiser un événement  thème 2 organiser une sortie hors de la structure .pdf": {
              link: "https://cdn.jabi.fr/ateliers/planification/organiser_un_evenement/Organiser_un_evenement_theme_2.pdf"
            },
            "Template organiser un événement - thème  2 _ sortie en extérieur.pdf": {
              link: "https://cdn.jabi.fr/ateliers/planification/organiser_un_evenement/Template_organiser_un_evenement_theme_2_evenement_en_interieur.pdf"
            }
          },
        },
        "Atelier 5 - retrouver la recette": {
          "Thème 1 - Le gateau au chocolat": {
            "Retrouver la recette théme 1  le gateau au chocolat .pdf": {
              link: "https://cdn.jabi.fr/ateliers/planification/retrouver_la_recette/Retrouver_la_recette_theme_1_le_gateau_au_chocolat.pdf"
            },
            "Template  retrouver la recette - thème 1  le gâteau au chocolat.pdf": {
              link: "https://cdn.jabi.fr/ateliers/planification/retrouver_la_recette/Template_retrouver_la_recette_theme_1_le_gateau_au_chocolat.pdf"
            },
          },
          "Thème 2 - Les truffes au chocolat": {
            "Retrouver la recette théme 2  les truffes au chocolat.pdf": {
              link: "https://cdn.jabi.fr/ateliers/planification/retrouver_la_recette/Retrouver_la_recette_theme_2_la_truffe_au_chocolat.pdf"
            },
            "Template  retrouver la recette - thème 2 les truffes au chocolat.pdf": {
              link: "https://cdn.jabi.fr/ateliers/planification/retrouver_la_recette/Template_retrouver_la_recette_theme_2_la_truffe_au_chocolat.pdf"
            }
          }
        }
      }
    },
    "Mémoire": {
      "Mémoire à court terme": {
        "Atelier 1 - Anagrammes": {
          "Thème 1 - Anagrammes": {
            "Anagramme - Mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/travail/Atelier1_anagrammes/Anagramme_theme_1.pdf"
            },
            "Anagramme - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/travail/Atelier1_anagrammes/template_anagrammes_theme_1.pdf"
            },
          },
          "Thème 2 - Anagrammes": {
            "Anagramme - Mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/travail/Atelier1_anagrammes/Anagramme_theme_2.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/travail/Atelier1_anagrammes/Template_anagrammes_theme_2.pdf"
            }
          }
        },
        "Atelier 2 - jeu du pendu": {
          "Thème 1 - Les animaux": {
            "Jeu du pendu - Les animaux - Mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/travail/atelier2_jeu_du_pendu/Jeu_du_pendu_theme_1_les_animaux.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/travail/atelier2_jeu_du_pendu/Template_jeu_du_pendus_theme_1.pdf"
            },
          },
          "Thème 2 - Le language des fleurs": {
            "Jeu du pendu - Le language des fleurs - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/travail/atelier2_jeu_du_pendu/Jeu_du_pendu_theme_2_le_langage_des_fleurs.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/travail/atelier2_jeu_du_pendu/Template_jeu_du_pendus_theme_2.pdf"
            }
          }
        },
        "Atelier 3 - Déchiffrage": {
          "Thème 1 - Déchiffrage": {
            "Déchiffrage - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/travail/atelier3_déchiffrage/Dechiffrage_theme_1.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/travail/atelier3_déchiffrage/Template_dechiffrage_theme_1.pdf"
            },
          },
          "Thème 2 - Déchiffrage": {
            "Déchiffrage - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/travail/atelier3_déchiffrage/Dechiffrage_theme_2.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/travail/atelier3_déchiffrage/Template_dechiffrage_theme2.pdf"
            }
          }
        },
        "Atelier 4 - trouver ces mots": {
          "Thème 1 - Trouver ces mots": {
            "Trouver ces mots - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/travail/atelier4_trouver_ces_mots/Trouver_ces_mots_theme_1.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/travail/atelier4_trouver_ces_mots/Template_trouver_ces_mots_theme_1.pdf"
            },
          },
          "Thème 2 - Trouver ces mots": {
            "Trouver ces mots - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/travail/atelier4_trouver_ces_mots/Trouver_ces_mots_theme_2.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/travail/atelier4_trouver_ces_mots/Template_trouver_ces_mots_theme_2.pdf"
            }
          }
        },
        "Atelier 5 - le mot le plus long": {
          "Thème 1 - Le mot le plus long": {
            "Le mot le plus long - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/travail/atelier5_le_mot_le_plus_long/Le_mot_le_plus_long_theme_1.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/travail/atelier5_le_mot_le_plus_long/Template_le_mot_le_plus_long_theme_1.pdf"
            },
          },
          "Thème 2 - Le mot le plus long": {
            "Le mot le plus long - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/travail/atelier5_le_mot_le_plus_long/Le_mot_le_plus_long_Theme_2.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/travail/atelier5_le_mot_le_plus_long/Template_le_mot_le_plus_long_theme_2.pdf"
            }
          }
        }
      },
      "Mémoire des souvenirs": {
        "Atelier 1 - Photo langage": {
          "Thème 1 - Technologie d'hier et d'aujourd'hui": {
            "Technologie d'hier et d'aujourd'hui - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/episodique/photolangage/Photo_langage-Themes1_technologie.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/episodique/photolangage/Template_photo_langage-theme1-les_technologies_dhier_et_daujourdhui.pdf"
            },
          },
          "Thème 2 - Technologie d'hier et d'aujourd'hui": {
            "Technologie d'hier et d'aujourd'hui - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/episodique/photolangage/Photo_langage-themes 2_specialites_regionales.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/episodique/photolangage/Template_photo_langage_themes_2_specialites_regionales.pdf"
            }
          }
        },
        "Atelier 2 _ photomaton": {
          "Thème 1 - Challenge photo": {
            "Photomaton - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/episodique/photomaton/Photomaton_theme_1_challenge_photo.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/episodique/photomaton/Template_photomaton_theme_1_challenge_photo.pdf"
            },
          },
          "Thème 2 - Portrait chinois": {
            "Photomaton -  thème 2 _ portrait chinois.pdf": {
              link: "https://cdn.jabi.fr/ateliers/episodique/photomaton/Photomaton_thematique__portrait_chinois.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/episodique/photomaton/Template_photomaton_theme_2_portrait_chinois.pdf"
            }
          }
        },
        "Atelier 3 - jeux de Kim": {
          "Thème 1 - Jeux de Kim": {
            "Jeux de Kim - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/episodique/kim/Jeux_de_Kim_thematique_1.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/episodique/kim/Template_jeux_de_kim_theme_1.pdf"
            },
          },
          "Thème 2 - Jeux de Kim": {
            "Jeux de Kim - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/episodique/kim/Jeux_de_Kim_thematique_2.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/episodique/kim/Template_jeux_de_kim_theme_2.pdf"
            }
          }
        },
        "Atelier 4 - se souvenir du dessin": {
          "Thème 1 - Les paysages": {
            "Les paysages - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/episodique/souvenirdessin/Un_souvenir_un_dessin_thematique_1_les_paysages.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/episodique/souvenirdessin/Template_un_souvenir_un_dessin_theme_1.pdf"
            },
          },
          "Thème 2 - Les compositions": {
            "Un souvenir, un dessin  - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/episodique/souvenirdessin/Un_souvenir_un_dessin_thematique_2_les_compositions.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/episodique/souvenirdessin/Template_un_souvenir_un_dessin_theme_2_les_compositions.pdf"
            }
          }
        },
        "Atelier 5 - Mon autobiographie": {
          "Thème 1 - mon enfance": {
            "Mon autobiographie - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/episodique/autobiographie/mon_autobiographie_theme_1_mon_enfance.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/episodique/autobiographie/Template_mon_autobiographie_theme_1_Mon_enfance.pdf"
            },
          },
          "Thème 2 - ma vie d'adulte": {
            "Mon autobiographie - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/episodique/autobiographie/mon_autobiographie-theme_2_ma_vie_d_adulte.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/episodique/autobiographie/Template_mon_autobiographie_theme_2_Ma_vie_d_adulte.pdf"
            }
          }
        }
      },
      "Mémoire des mots": {
        "Atelier 1 - mots et cultures": {
          "Thème 1 - mots et expressions": {
            "Mot et culture - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier1_mots_et_cultures/Mot_et_culture-Thematique_1_Mot_et_expression.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier1_mots_et_cultures/Template_mot_et_culture_theme_1_expressions_d_hier_et_d_aujourd_hui.pdf"
            },
          },
          "Thème 2 - BD et contenus culturels": {
            "Mot et culture - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier1_mots_et_cultures/Mot_et_culture_Themes_2_BD_et_contenus_culturelle.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier1_mots_et_cultures/Template_mot_et_culture_Thème_2_BD.pdf"
            }
          }
        },
        "Atelier 2 - texte à trous": {
          "Thème 1 - les fables de la fontaine": {
            "Texte à trous - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier2_texte_a_trous/Template_texte_a_trous_theme_1_fables.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier2_texte_a_trous/Texte_a_trous_thematique_1_les_fables_de_la_fontaine.pdf"
            },
          },
          "Thème 2 - Poème et théatre": {
            "Texte à trous - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier2_texte_a_trous/Template_texte_a_trous_theme_2_rimes.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier2_texte_a_trous/Texte_a_trous_theme_2_poeme_et_theatre.pdf"
            }
          }
        },
        "Atelier 3 - petit bac": {
          "Thème 1 - petit bac": {
            "Petit bac - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier3_petit_bac/petit_bac_theme_1.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier3_petit_bac/Template_petit_bac_theme_1.pdf"
            },
          },
          "Thème 2 - petit bac": {
            "Petit bac - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier3_petit_bac/petit_bac_theme_2.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier3_petit_bac/Template_petit_bac_theme_2.pdf"
            }
          }
        },
        "Atelier 4 - expressions à compléter": {
          "Thème 1 - expression à compléter": {
            "Expression a compléter - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier4_expressions_a_completer/Expression_a_completer_theme_1.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier4_expressions_a_completer/Template_expressions_a-completer_theme1.pdf"
            },
          },
          "Thème 2 - expression à compléter": {
            "Expression a compléter - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier4_expressions_a_completer/Expression_a_completer_thematique_2.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier4_expressions_a_completer/Template_expressions_a_completer_theme_2.pdf"
            }
          }
        },
        "Atelier 5 - explosion d'expressions": {
          "Thème 1 - Explosion d'expressions": {
            "Explosion d'expressions - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier5_explosion_d_expression/Explosion_d_expressions_theme_1.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier5_explosion_d_expression/Template_explosions_d_expression_theme_1.pdf"
            },
          },
          "Thème 2 - Explosion d'expressions": {
            "Explosion d'expressions - mode présentation": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier5_explosion_d_expression/Explosion_d_expressions_theme_2.pdf"
            },
            "Livret d'instruction - A imprimer": {
              link: "https://cdn.jabi.fr/ateliers/semantique/atelier5_explosion_d_expression/Template_explosions_d_expression_theme_2.pdf"
            }
          }
        }
      }
    }
  };

  return ({ateliers});
};

export default useAteliers;