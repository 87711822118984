import React, {useState} from "react";
import {Button, Checkbox} from "lib/components";
import { getAllEtablishments } from "services/ressources/establishment";
import { updateAnimationByEtab, getAllAnimationByEtab } from "services/ressources/animations";

const AnimationReservationObject = () => {
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [autocorrect, setAutocorrect] = useState(false);

  const test = async () => {
    setReport([]);
    setLoading(true);

    //get all etabs
    let etabs = await getAllEtablishments();

    //etabs = [{uid: "zZfbQWvnh2scBL67MUhk"}];//for tests, force to annecy
    for (let i = 0; i < etabs.length; i++) {
      setReport(prev => [...prev, `<span style="font-weight: 800">test etab ${etabs[i].uid}</span>`]);
      //get all animations for this etab
      let _animations = await getAllAnimationByEtab({id: etabs[i].uid});

      _animations.forEach(animation => {
        if (!animation.reservation) return;
        for (let j = 0 ; j < animation.reservation.length; j++) {
          if (typeof(animation.reservation[j]) !== "string") {
            if (autocorrect) {
              let resas = animation.reservation.map(i => (
                typeof(i) === "string" ? i : i.uid
              )).filter(i => i);

              if (animation.reservation.length !== resas.length) {
                setReport(prev => [...prev, `<span style="color: red;">animation ${animation.uid} in etab ${etabs[i].uid}: autocorrection failed</span>`]);
              } 
              else {
                updateAnimationByEtab({etabId: etabs[i].uid, animId: animation.uid, data: {reservation: resas}});
                setReport(prev => [...prev, `<span style="color: green;">animation ${animation.uid} in etab ${etabs[i].uid}: autocorrection success</span>`]);
              }
            }
            return setReport(prev => [...prev, `<span style="color: red;">animation ${animation.uid} in etab ${etabs[i].uid}: reservation is not a string</span>`]);
          }
        }
      });
      setReport(prev => [...prev, "<span style=\"color: blue;\">-------------------------------------</span>"]);
    }
    setReport(prev => [...prev, "<span style=\"color: green;\">fini</span>"]);
    setLoading(false);
  };

  return (
    <div>
      <p>Utilisé pour vérifier l&apos;intégrité des arrays de réservation dans les animations.
        <br/>
        Dans certains cas il a été détecté qu&apos;au lieu d&apos;un uid dans l&apos;array réservation, l&apos;ensemble de l&apos;object user se retrouvais dedans, faisant bugger le système de résa</p>
      <Button onClick={test} loading={loading}>launch test</Button>
      <Checkbox checked={autocorrect} onChange={() => setAutocorrect(!autocorrect)} label="autocorrection" />
      {
        report.map((r, i) => (
          <p key={i} dangerouslySetInnerHTML={{__html: r}} />
        ))
      }
    </div>
  );
};

export default AnimationReservationObject;