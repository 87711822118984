import {useState, useEffect} from "react";
import useUI from "./ui.hook";

const useFeature = (featureName) => {
  const [ui] = useUI();
  const [isFeatureActive, setIsFeatureActive] = useState(null);

  useEffect(() => {
    if (ui.user && ui.establishment !== null) {
      const establishment = ui.establishment;
      setIsFeatureActive(establishment.features && establishment.features.includes(featureName));
    } else {
      setIsFeatureActive(false);
    }
  }, []);

  return isFeatureActive;
};

export default useFeature;