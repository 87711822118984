
const percent = (val1, val2) => {
    if(val1 === 0) return "/";
    if(val2 === 0) return "/";
    return (val1-val2)/val2 *100;
};

const mean = (array) => {
    let sum = 0;
    array.forEach(element => {
        sum+=element;
    });
    return sum / array.length;
};

const extractElementsFromObject = (list, path) => {
        let returnList = [];

        list.forEach(object => {
            let element = null;
            element = getFromPath(object, path);
            returnList.push(element);
        });
       
        return returnList;    
};

const getFromPath = (data, path) => {
    if(path === ""){
        return data;
    }else{
        const pathList = path.split("/");
        let element = null;

        pathList.forEach(_path => {       
            if(element === null){
                element = data[_path];
            }
            else{
                if(typeof(element) === "object" && element[_path] != undefined)element = element[_path];
            } 
        });

        return element;
    }
};

export {percent, mean, extractElementsFromObject, getFromPath};