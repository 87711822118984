import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createContext, useReducer, useContext } from "react";
import firebase from "firebase";
//import moment from "moment";
import useUI from "hooks/ui.hook";
import deepClone from "helpers/deepClone";
//import deepClone from "helpers/deepClone";
//import deepClone from "../../../../helpers/deepClone";
import { getDefaultDay } from "../../../../../Menu/helpers/operations";
import moment from "moment";
import { getMenusByPeriod } from "services/ressources/menu";

const firestore = firebase.firestore;
const Context = createContext();

const Default = {
  dataBase: null,
  dataBaseSubscription: null,
  data: null,
  dataOrders: null,
  dataOrdersSupplements: null,
  dataGuestOrders: null,
  dataGuestOrdersSupplements: null,
  usersUnfiltered: null,
  users: null,
  filteredUsers: null,
  guests: null,
  filteredGuests: null,
  selectedWeek: null,
  dateArray: null,
  selectedRepas: null,
  daysToDisplay: [1, 2, 3, 4, 5, 6, 7],
  loadingMenu: true,
  modalResaOpen: false,
  modalWeekOpen: false,
  printPDF: false,
  modalInfos: null,
  menuType: "category",
  legendForms: ["square", "circle", "hexagon", "diamond"],
  legendColors: ["#201E50", "#B23A48", "#8C86AA", "#F79D5C"], //["#2274a5", "#816C61", "#FCAB10", "#DAC4F7"],
  updateMenu: null,
  filter: {
    role: "senior", //or employee
  },
};

function Reducer(state, action) {
  switch (action.type) {
    case "setProperty":
      return { ...state, [action.property]: action.value };
    case "setFilter":
      return { ...state, filter: action.value };
    case "setFilterProperty":
      return {
        ...state,
        filter: { ...state.filter, [action.property]: action.value },
      };
    default:
      return { ...state };
  }
}

const Provider = ({ children }) => {
  const [ui] = useUI();
  const [ctx, dispatch] = useReducer(Reducer, Default);

  const template = ui?.establishment?.template;
  const templateSubscription = ui?.establishment?.templateSubscription;

  const isGuest = (_data) => {
    if (!_data.userId && _data.guest) return true;
    return false;
  };

  const getGuestInformation = (_data) => {
    if (!isGuest(_data)) return {};

    const guestInformation = {};

    if (_data.guest.guestId) guestInformation.id = _data.guest.guestId;
    if (_data.guest.type) guestInformation.type = _data.guest.type;
    if (_data.guest.name) guestInformation.name = _data.guest.name;
    if (_data.guest.linkedTo) guestInformation.linkedTo = _data.guest.linkedTo;
    if (_data.guest.hasPaid) guestInformation.hasPaid = _data.guest.hasPaid;



    return guestInformation;
  };

  //init


  useEffect(() => {
    firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("menu")
      .get()
      .then(res => {
        let data = res.data();
        if (data.daysToDisplay && data.daysToDisplay.length > 0) {
          dispatch({ type: "setProperty", property: "daysToDisplay", value: data.daysToDisplay });
        }
        if (data.type === "menu")
          dispatch({ type: "setProperty", property: "selectedWeek", value: moment().startOf("week") });
      })
      .catch(error => {
        console.log("error", error);
      });

    try {
      firestore()
        .collection("users")
        .where("establishment", "==", ui.user.establishment)
        .where(
          "role",
          "in",
          ["employee", "owner", "senior", "seniorTotem"]
        )
        .onSnapshot((res) => {
          let _dataUsers = {};
          res.forEach((doc) => {
            _dataUsers[doc.id] = doc.data();
          });
          dispatch({
            type: "setProperty",
            property: "usersUnfiltered",
            value: _dataUsers,
          });
        });
    } catch (e) {
      console.error(e);
    }

    if (ui.establishment.menuType && ui.establishment.menuType === "menu")
      dispatch({ type: "setProperty", property: "menuType", value: "menu" });
  }, [ui]);

  useEffect(() => {
    if (ctx.selectedWeek) {
      let _dataUsers = {};
      Object.entries(ctx.usersUnfiltered).forEach(([key, user]) => {
        if(user.isDeleted && moment(user.deletedAt.toDate()).isBefore(moment(ctx.selectedWeek).startOf("week"))) return;
        _dataUsers[key] = user;
        
      });
      dispatch({
        type: "setProperty",
        property: "users",
        value: _dataUsers,
      });
    } 

  }, [ctx.selectedWeek, ctx.usersUnfiltered]);

  useEffect(() => {
    if (ctx.selectedWeek) {
      const _start = ctx.selectedWeek.toDate();
      const _end = ctx.selectedWeek.clone().endOf("week").toDate();


      const unsubscribe = firestore()
        .collection("establishments")
        .doc(ui.user.establishment)
        .collection("blocks")
        .doc("menu")
        .collection("menu")
        .where("published", "==", true)
        .where("day", ">=", _start)
        .where("day", "<=", _end)
        .onSnapshot(s => {
          let _dataMenus = {};
          s.forEach(doc => {
            _dataMenus[doc.id] = doc.data();
          });
          dispatch({ type: "setProperty", property: "dataBase", value: _dataMenus });
        });

      const unsubscribe2 = firestore()
        .collection("establishments")
        .doc(ui.user.establishment)
        .collection("blocks")
        .doc("restaurant")
        .collection("orders")
        .where("day", ">=", _start)
        .where("day", "<=", _end)
        // .where("status", "==", "")
        .onSnapshot((s) => {
          const filteredDocs = [];

          s.forEach(doc => {
            filteredDocs.push(doc.data());
          });

          const orderedDocs = filteredDocs.sort((a, b) => a.day.toDate() - b.day.toDate());

          const _processedData = handleOrdersData(orderedDocs);

          if (_processedData) {
            dispatch({ type: "setProperty", property: "dataOrders", value: _processedData.dataOrders });
            dispatch({ type: "setProperty", property: "dataOrdersSupplements", value: _processedData.dataOrdersSupplements });
            dispatch({ type: "setProperty", property: "guests", value: _processedData.guests });
            dispatch({ type: "setProperty", property: "dataGuestOrders", value: _processedData.dataGuestOrders });
            dispatch({ type: "setProperty", property: "dataGuestOrdersSupplements", value: _processedData.dataGuestOrdersSupplements });
          }
        });

      return () => {
        unsubscribe();
        unsubscribe2();
      };
    }
  }, [ctx.selectedWeek]);

  useEffect(() => {
    if (ctx.dataBaseSubscription) {
      const _data = deepClone(ctx.dataBaseSubscription);

      if (ctx.dataOrders) {
        Object.entries(ctx.dataOrders).forEach(([_date, _resas]) => {
          if (_data[_date]) {
            Object.entries(_resas).forEach(([_uid, _resa]) => {
              if (!_data[_date].reservation) _data[_date].reservation = {};
              if (!_data[_date].reservation[_uid]) _data[_date].reservation[_uid] = {};

              Object.entries(_resa).forEach(([_repas, _resaData]) => {
                if (!_data[_date].reservation[_uid][_repas]) _data[_date].reservation[_uid][_repas] = [];
                _data[_date].reservation[_uid][_repas] = _resaData;
              });
            });
          }
        });

      }

      dispatch({ type: "setProperty", property: "data", value: _data });
      // console.log("_data", _data["2024-04-08"]);

      if (ctx.loadingMenu)
        dispatch({ type: "setProperty", property: "loadingMenu", value: false });
    }
  }, [ctx.dataOrders, ctx.dataBaseSubscription]);

  useEffect(() => {
    if (ctx.dataBase) {
      const _data = deepClone(ctx.dataBase);
      if (ctx.users) {
        // Gestion des abonnements :
        if (templateSubscription) {
          // on rajoute toutes les réservations nécessaires.

          Object.entries(_data).forEach(([_day, _menu]) => {
            const _defaultDay = getDefaultDay(template, _menu);
            if (!_menu.reservation) _menu.reservation = {};
            const _reservations = _menu.reservation;
            const _currentMonth = moment(_day).format("YYYY-MM");

            // on check tous les utilisateurs ayant un abonnement sur le jour en cours.
            Object.entries(ctx.users).forEach(([_uid, _user]) => {
              const _subscription = _user.subscription;
              const _subscriptionMonths = _user.subscriptionMonths;
              const _subscriptionHomeDelivery = _user.subscriptionHomeDelivery ?? {};
              const _subscriptionDays = _user.subscriptionDays ?? [1,2,3,4,5,6,7];

              // si l'utilisateur a des abonnements.
              if (_subscription && _subscriptionMonths) {
                // on vérifie s'il est abonné pour ce jour.
                if (_subscriptionMonths.includes(_currentMonth) && _subscriptionDays.includes(moment(_day).isoWeekday())) {


                  // on vérifie maintenant s'il a déjà un réservation pour les repas compris dans son abonnement. 
                  // Si il a déjà une réservation on ne change rien. S'il n'en a pas on rajoute la réservation de base.


                  if (templateSubscription[_subscription]) {
                    Object.entries(templateSubscription[_subscription].repas).forEach(([_repas, _repasInfos]) => {

                      // si il n'y a pas de resa pour ce repas on la rajoute.
                      if (!_reservations[_uid] || !_reservations[_uid][_repas]) {

                        if (!_reservations[_uid]) _reservations[_uid] = {};

                        const _newResa = {};

                        _repasInfos.forEach((_category) => {
                          _newResa[_category] = _defaultDay[_repas] && _defaultDay[_repas][_category] ? _defaultDay[_repas][_category] : null;
                        });

                        _reservations[_uid][_repas] = [{ ..._newResa, homeDelivery: _subscriptionHomeDelivery[_repas] ?? false, freeHomeDelivery: _user?.freeHomeDelivery ?? false, createdLocally: true }];
                      }

                    });
                  }

                }

              }

            });
          });
        }
      }

      dispatch({ type: "setProperty", property: "dataBaseSubscription", value: _data });

    }
  }, [ctx.dataBase, ctx.users]);

  useEffect(() => {
    if (ctx.users) {
      let filteredUsers = {};

      Object.keys(ctx.users).forEach(uid => {
        const user = ctx.users[uid];

        if (ctx.filter.role === "senior" && ["senior", "seniorTotem"].includes(user.role)) {
          filteredUsers[uid] = user;
        } else if (ctx.filter.role === "employee" && ["owner", "employee"].includes(user.role)) {
          filteredUsers[uid] = user;
        }
      });

      dispatch({ type: "setProperty", property: "filteredUsers", value: filteredUsers });


      // dispatch({ type: "setProperty", property: "filteredGuest", value: filteredDataOrders });
    }
  }, [ctx.users, ctx.filter, ctx.dataOrders, ctx.dateArray]);

  useEffect(() => {
    // console.log("AROUND HERE", ctx.dataGuestOrders);
    // console.log("DATE ARRAY", ctx.dateArray);
    const filteredDataOrders = Object.keys(ctx.dataGuestOrders ?? [])
      .filter(date => (ctx.dateArray ?? []).includes(date))
      .reduce((acc, date) => {
        acc[date] = ctx.dataGuestOrders[date];
        return acc;
      }, {});

    if (ctx.guests) {
      let filteredGuests = {};
      ctx.guests.forEach(guest => {
        if (Object.keys(filteredDataOrders).map(date => Object.keys(filteredDataOrders[date])).flat().includes(guest.guestId)) {
          filteredGuests[guest.guestId] = guest;
        }
      });
      dispatch({ type: "setProperty", property: "filteredGuests", value: filteredGuests });
    }
  }, [ctx.filter, ctx.dataOrders, ctx.dateArray, ctx.guests, ctx.dataGuestOrders]);


  useEffect(() => {
    if (!ctx.modalResaOpen && ctx.modalInfos != null) {
      dispatch({ type: "setProperty", property: "modalInfos", value: null });
    }
  }, [ctx.modalResaOpen]);

  const updateMenu = (_resa, date, uid, repas) => {
    // date = "2022-07-15" options = {"midi" : 0, "soir" : 2} action = ["update", "remove"]
    //let docId = moment(date).format("YYYY-MM-DD");
    let map = {};

    let _date = date ?? ctx.modalInfos.date ?? null;
    let _uid = uid ?? ctx.modalInfos.uid ?? null;
    let _repas = repas ?? ctx.modalInfos.repas ?? null;

    //let isMapEmpty = true;

    if (_resa === "delete") {

      if (ctx.data[_date].reservation[_uid][_repas][0].createdLocally) {
        //cancel
        map["reservation." + _uid + "." + _repas] = [{ status: "canceled", homeDelivery: false }];
      } else {
        map["reservation." + _uid + "." + _repas] = firestore.FieldValue.delete();
      }
    } else {
      if (_resa[0].status === "canceled") delete _resa[0].status;
      if (_resa[0].createdLocally === true) delete _resa[0].createdLocally;

      map["reservation." + _uid + "." + _repas] = _resa;
    }

    firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("menu")
      .collection("menu")
      .doc(_date)
      .update(map);
  };

  const updateGuestPaid = async (guest) => {
    try {
      const docRef = firestore()
        .collection("establishments")
        .doc(ui.user.establishment)
        .collection("blocks")
        .doc("restaurant")
        .collection("orders")
        .where("guest.guestId", "==", guest.guestId)
        .limit(1); // Limiter la recherche à un seul document

      const querySnapshot = await docRef.get();

      const doc = querySnapshot.docs[0];

      await doc.ref.update({ ...doc.data(), guest });
    } catch (error) {
      console.error("Erreur", error);
    }
  };


  const handleOrdersData = (orderedDocs) => {

    let _dataOrders = {};
    let _dataOrdersSupplements = {};

    let _dataGuestOrders = {};
    let _dataGuestOrdersSupplements = {};
    let _guests = [];

    orderedDocs.forEach((_data) => {

      // 4 Cas possibles : user (userId), invité d'un user (userId == guest.linkedTo), invité etab (?), invité seul (guestId)
      let id = _data.userId ?? _data.guest.linkedTo ?? null;

      if (id !== null) {
        if (!_dataOrders[_data.date]) _dataOrders[_data.date] = {};

        if (!_dataOrders[_data.date][id])
          _dataOrders[_data.date][id] = {};

        //handle plates
        let _resa = {};
        _data.plates.forEach((element) => {
          _resa[element.category] = element.uid;
        });

        //handle supplements
        let _supplements = [];

        if (
          _data.meta &&
          _data.meta.find((i) => i.name === "suppléments")
        ) {
          _supplements = [
            ..._data.meta.find((i) => i.name === "suppléments").value,
          ];
        }
        if (isGuest(_data) && getGuestInformation(_data).linkedTo) {
          _supplements.push({
            id: _data.service === "Déjeuner" ? "RDEJI" : _data.service === "Dîner" ? "RDINI" : "",
            name: `${_data.service} invité`,
            number: 1
          });
        }

        if (_supplements.length > 0) {
          if (!_dataOrdersSupplements[_data.date])
            _dataOrdersSupplements[_data.date] = {};
          if (!_dataOrdersSupplements[_data.date][id])
            _dataOrdersSupplements[_data.date][id] = {};

          if (!_dataOrdersSupplements[_data.date][id][_data.service])
            _dataOrdersSupplements[_data.date][id][_data.service] = [];



          // Des supplements peuvent déjà être présent 
          _dataOrdersSupplements[_data.date][id][_data.service].push(..._supplements);



          _dataOrdersSupplements[_data.date][id][_data.service] = _dataOrdersSupplements[_data.date][id][_data.service].reduce((acc, supplement) => {
            const existingItem = acc.find(item => item.id === supplement.id);
            if (existingItem) {
              existingItem.number += supplement.number;
            } else {
              acc.push(supplement);
            }
            return acc;
          }, []);

        }

        if (!_dataOrders[_data.date][id][_data.service])
          _dataOrders[_data.date][id][_data.service] = [];

        if (_data?.guest?.linkedTo)
          _dataOrders[_data.date][id][_data.service].push(_resa);
        else _dataOrders[_data.date][id][_data.service] = [_resa, ..._dataOrders[_data.date][id][_data.service]];
      }

      // ----------------------  GUEST  --------------------------


      if (_data.guest != undefined) {
        if (_guests.find(guest => guest.type === _data.guest.type && _data.guest.type === "establishmentGuest")) {
          const total = _guests.filter(guest => guest.type === _data.guest.type && guest.type === "establishmentGuest").length;
          _guests.push({ ..._data.guest, name: `${_data.guest.name} ${total + 1}`, date: _data.date });
        }

        else if (_guests.find(guest => guest.type === _data.guest.type && _data.guest.type === "guest" && guest?.name === _data.guest?.name)) {
          const total = _guests.filter(guest => guest.type === _data.guest.type && guest.type === "guest" && _data.guest.name === guest?.registredName).length;
          _guests.push({ ..._data.guest, registredName: _data.guest.name, name: `${_data.guest.name} ${total + 1}`, date: _data.date });
        }

        else {
          _guests.push({ ..._data.guest, date: _data.date });
        }

        let id = _data.guest?.guestId ?? null;

        if (!_dataGuestOrders[_data.date]) _dataGuestOrders[_data.date] = {};

        if (!_dataGuestOrders[_data.date][id])
          _dataGuestOrders[_data.date][id] = {};

        //handle plates
        let _resa = {};
        _data.plates.forEach((element) => {
          _resa[element.category] = element.uid;
        });

        //handle supplements
        let _supplements = [];

        if (
          _data.meta &&
          _data.meta.find((i) => i.name === "suppléments")
        ) {
          _supplements = [
            ..._data.meta.find((i) => i.name === "suppléments").value,
          ];
        }
        if (isGuest(_data) && getGuestInformation(_data).linkedTo) {
          _supplements.push({
            id: _data.service === "Déjeuner" ? "RDEJI" : _data.service === "Dîner" ? "RDINI" : "",
            name: `${_data.service} invité`,
            number: 1
          });
        }

        if (_supplements.length > 0) {
          if (!_dataGuestOrdersSupplements[_data.date])
            _dataGuestOrdersSupplements[_data.date] = {};
          if (!_dataGuestOrdersSupplements[_data.date][id])
            _dataGuestOrdersSupplements[_data.date][id] = {};

          if (!_dataGuestOrdersSupplements[_data.date][id][_data.service])
            _dataGuestOrdersSupplements[_data.date][id][_data.service] = [];



          // Des supplements peuvent déjà être présent 
          _dataGuestOrdersSupplements[_data.date][id][_data.service].push(..._supplements);



          _dataGuestOrdersSupplements[_data.date][id][_data.service] = _dataGuestOrdersSupplements[_data.date][id][_data.service].reduce((acc, supplement) => {
            const existingItem = acc.find(item => item.id === supplement.id);
            if (existingItem) {
              existingItem.number += supplement.number;
            } else {
              acc.push(supplement);
            }
            return acc;
          }, []);

        }

        if (!_dataGuestOrders[_data.date][id][_data.service])
          _dataGuestOrders[_data.date][id][_data.service] = [];

        _dataGuestOrders[_data.date][id][_data.service].push(_resa);

        _dataGuestOrders[_data.date][id][_data.service] = _dataGuestOrders[_data.date][id][_data.service].sort((a, b) => {
          if (a.userId === undefined)
            return 1; // Move null or undefined to the end

          if (b.userId === undefined)
            return -1; // Keep null or undefined at the end

          return a - b;
        });
      }


    });


    return {
      dataOrders: _dataOrders,
      dataOrdersSupplements: _dataOrdersSupplements,
      guests: _guests,
      dataGuestOrders: _dataGuestOrders,
      dataGuestOrdersSupplements: _dataGuestOrdersSupplements,
    };
  };

  const getData = async (start, end) => {
    const _rawData = await getMenusByPeriod({ ui, start, end });

    const _data = Object.fromEntries(_rawData.map(_d => [_d.uid, _d]));

    // apply subscriptions : 
    if (ctx.users) {
      // Gestion des abonnements :
      if (templateSubscription) {
        // on rajoute toutes les réservations nécessaires.

        Object.entries(_data).forEach(([_day, _menu]) => {
          const _defaultDay = getDefaultDay(template, _menu);
          if (!_menu.reservation) _menu.reservation = {};
          const _reservations = _menu.reservation;
          const _currentMonth = moment(_day).format("YYYY-MM");

          // on check tous les utilisateurs ayant un abonnement sur le jour en cours.
          Object.entries(ctx.users).forEach(([_uid, _user]) => {
            const _subscription = _user.subscription;
            const _subscriptionMonths = _user.subscriptionMonths;
            const _subscriptionHomeDelivery = _user.subscriptionHomeDelivery ?? {};
            const _subscriptionDays = _user.subscriptionDays ?? [1,2,3,4,5,6,7];

            // si l'utilisateur a des abonnements.
            if (_subscription && _subscriptionMonths) {
              // on vérifie s'il est abonné pour ce jour.
              if (_subscriptionMonths.includes(_currentMonth) && _subscriptionDays.includes(moment(_day).isoWeekday())) {


                // on vérifie maintenant s'il a déjà un réservation pour les repas compris dans son abonnement. 
                // Si il a déjà une réservation on ne change rien. S'il n'en a pas on rajoute la réservation de base.


                if (templateSubscription[_subscription]) {
                  Object.entries(templateSubscription[_subscription].repas).forEach(([_repas, _repasInfos]) => {

                    // si il n'y a pas de resa pour ce repas on la rajoute.
                    if (!_reservations[_uid] || !_reservations[_uid][_repas]) {

                      if (!_reservations[_uid]) _reservations[_uid] = {};

                      const _newResa = {};

                      _repasInfos.forEach((_category) => {
                        _newResa[_category] = _defaultDay[_repas] && _defaultDay[_repas][_category] ? _defaultDay[_repas][_category] : null;
                      });

                      _reservations[_uid][_repas] = [{ ..._newResa, homeDelivery: _subscriptionHomeDelivery[_repas] ?? false, freeHomeDelivery: _user?.freeHomeDelivery ?? false, createdLocally: true }];
                    }

                  });
                }

              }

            }

          });
        });
      }
    }

    const s = await firestore()
      .collection("establishments")
      .doc(ui.user.establishment)
      .collection("blocks")
      .doc("restaurant")
      .collection("orders")
      .where("day", ">=", start)
      .where("day", "<=", end).get();

    const filteredDocs = [];

    s.forEach(doc => {
      filteredDocs.push(doc.data());
    });

    const orderedDocs = filteredDocs.sort((a, b) => a.day.toDate() - b.day.toDate());

    const _processedData = handleOrdersData(orderedDocs);

    // if(_processedData.dataOrders){
    //   Object.entries(_processedData.dataOrders).forEach(([_date, _resas]) => {
    //     if (_data[_date]) {
    //       Object.entries(_resas).forEach(([_uid, _resa]) => {
    //         if (!_data[_date].reservation) _data[_date].reservation = {};
    //         if(!_data[_date].reservation[_uid]) _data[_date].reservation[_uid] = {};

    //         Object.entries(_resa).forEach(([_repas, _resaData]) => {
    //           if (!_data[_date].reservation[_uid][_repas]) _data[_date].reservation[_uid][_repas] = [];
    //           _data[_date].reservation[_uid][_repas] = _resaData;
    //         });
    //       });
    //     }
    //   });
    // }

    return {
      data: _data,
      ..._processedData
    };
  };

  ctx.getData = getData;
  ctx.updateMenu = updateMenu;
  ctx.updateGuestPaid = updateGuestPaid;

  return (
    <Context.Provider value={[ctx, dispatch]}>{children}</Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.element,
};
const useMenuReservation = () => {
  const contexte = useContext(Context);

  if (!contexte)
    throw new Error(
      "Le contexte n'est pas défini, il n'est probablement pas dans un provider !"
    );

  return contexte;
};
export default useMenuReservation;
export { Provider, Context };
