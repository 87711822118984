import React, {useEffect, useState} from "react";
import {
  Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Table, Card, CardHeader, CardBody, Input, FormGroup
} from "lib/components";
import {Edit, Plus, List, BarChart} from "react-feather";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import useSearch from "hooks/useSearch";
import { createGroupement, getAllGroupements_rt } from "services/ressources/groupement";
import {getAllEstablishments_rt} from "services/ressources/establishment";

const parameters = ["name"];

const Groupements = () => {
  const history = useHistory();
  const [groupements, setGroupements] = useState([]);
  const [establishments, setEstablishments] = useState([]);

  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const [value, setValue] = useState("");
  const { filtered } = useSearch(groupements, value, parameters);
  const [name, setName] = useState("");

  useEffect(() => getAllEstablishments_rt(null, setEstablishments), []);
  useEffect(() => getAllGroupements_rt(null, setGroupements), []);

  const _create = async () => {
    setLoading(true);
    try {
      await createGroupement({data: {name}});
      setIsOpen(false);
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Container><Row><Col xs={12}>
        <Card>
          <CardHeader style={{display: "flex", justifyContent: "space-between"}}>
            <div style={{display: "flex", justifyContent: "space-between", gap: 60, alignItems: "center"}}>
              <h5 style={{marginBottom: 0, whiteSpace: "nowrap"}}>Groupements</h5>
              <div style={{width: "60%", display: "flex", flexDirection: "row", alignItems: "center", gap: 5}}>
                <h6 style={{marginBottom: 0, whiteSpace: "nowrap"}}>Recherche :</h6>
                <Input value={value} onChange={(e)=> setValue(e.target.value)}/>
              </div>
            </div>
            <Button color="primary" onClick={() => setIsOpen(true)}><Plus color="white" /></Button>
          </CardHeader>
          <CardBody>
            <Table loading={loading}>
              <thead>
                <tr>
                  <th scope="row">Nom</th>
                  <th>établissements</th>
                  <th>actions</th>
                </tr>
              </thead>
              <tbody>
                {filtered && filtered.map(i => (
                  <tr key={i.uid}>
                    <th scope="row">{i.name}</th>
                    <td style={{cursor: "pointer"}} onClick={() => history.push(`/dashboard/groupements/${i.uid}/establishments`)}>
                      {i.establishments && i.establishments.map(e => establishments.find(est => est.uid === e)).filter(i => i).map(i => <p key={i}>{i.name}</p>)}
                    </td>
                    <td style={{display: "flex", gap: 20, alignItems: "center"}}>
                      <Edit color="#4a0956" style={{cursor: "pointer"}} onClick={() => history.push(`/dashboard/groupements/${i.uid}`)} />
                      <List color="#4a0956" style={{cursor: "pointer"}} onClick={() => history.push(`/dashboard/groupements/${i.uid}/establishments`)} />
                      <BarChart color="#4a0956" style={{cursor: "pointer"}} onClick={() => history.push(`/dashboard/groupements/${i.uid}/statistics`)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col></Row></Container>
      <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <ModalHeader>Créer un groupement</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input type="text" label="Nom" onChange={e => setName(e.target.value)} value={name} placeholder="Nom..." />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsOpen(false)}>Annuler</Button>
          <Button color="primary" onClick={_create}>Créer</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Groupements;