import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import H from "@here/maps-api-for-javascript";
import firebase from "firebase";
import { useFirebaseCollection } from "lib/hooks/firebase";
import MapComponent from "lib/components/MapComponent";
import mapHandler from "../../../hooks/mapHandler";
import mapStore from "store/mapStore";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Table } from "lib/components";
import useBornes from "../Bornes/useBornes";
import GeocodeAutocomplete from "../../../lib/components/Form/GeocodeAutocomplete";
import { updateEstablishmentById } from "../../../services/ressources/establishment";

const firestore = firebase.firestore;
const colors = [
  "red",
  "green",
  "blue",
  "orange",
  "purple",
  "pink",
  "brown",
  "gray",
  "black",
  "white",
  "turquoise",
  "gold",
  "silver",
  "navy",
  "magenta",
  "teal",
  "olive",
  "maroon",
  "indigo"
];

function findTextColor(hex) {
  const hexCode = hex.charAt(0) === '#' 
                      ? hex.substr(1, 6)
                      : hex;

  const hexR = parseInt(hexCode.substr(0, 2), 16);
  const hexG = parseInt(hexCode.substr(2, 2), 16);
  const hexB = parseInt(hexCode.substr(4, 2), 16);
  // Gets the average value of the colors
  const contrastRatio = (hexR + hexG + hexB) / (255 * 3);

  return contrastRatio >= 0.5
      ? 'black'
      : 'white';
}
const Map = () => {
  const divRef = useRef(null);
  const { addBasicMarker, addBubbleMarker, addGroup, hideGroup, showGroup, removeBasicMarkers, genSVG } = mapHandler();
  const bornes = useBornes();

  const [isOpen, setIsOpen] = useState(false);
  const [coords, setCoords] = useState({});

  // getting data
  const [etabRef] = useState(firestore().collection("establishments"));
  const [groupementsRef] = useState(firestore().collection("groupements"));
  const { data: establishments } = useFirebaseCollection(etabRef);
  const { data: groupements } = useFirebaseCollection(groupementsRef);
  const [filterGroupement, setFilterGroupement] = useState([]);

  const mapRef = mapStore((state) => state.mapRef);
  const groupList = mapStore((state) => state.groupList);
  const service = mapStore((state) => state.service);

  const addToBubbleMarkerList = mapStore((state) => state.addToBubbleMarkerList);


  const genHTML = (name, id) => {
    return `
      <div class="infoDiv">
        <div>
          ${name}
        </div>
        <a href="/dashboard/establishments/${id}/settings"><div>Fiche de l'établissement</div></a>
        <a href="/dashboard/one/${id}"><div>Prendre le contrôle</div></a>
      </div>
    `
  };


  useEffect(() => {
    if (mapRef && groupList && establishments && establishments.length > 0 && groupements && groupements.length > 0) {

      establishments.filter((_e) => _e.position && _e.position.lat && _e.position.long).forEach(_etab => {

        if (_etab.position) {
          const _groupementId = _etab.groupement;

          let _marker = null;
          let _color = null;
          if (_groupementId) {
            const _groupement = groupements.find(_g => _g.uid === _groupementId);
            _color = _groupement?.color ?? "gray";
            _marker = addBubbleMarker(_etab.position.lat, _etab.position.long, genHTML(_etab.name, _etab.uid), _color, _groupement?.name);
          } else {
            _color = "#6F686D";
            _marker = addBubbleMarker(_etab.position.lat, _etab.position.long, genHTML(_etab.name, _etab.uid), "#6F686D", "noGroup");
          }

          addToBubbleMarkerList({
            marker: _marker,
            color: _color,
            uid: _etab.uid,
          });
        }
      });
    }
  }, [mapRef, groupList, establishments, groupements]);

  useEffect(() => {
    const bubbleMarkerList = mapStore.getState().bubbleMarkerList;

    bubbleMarkerList.forEach(element => {
      const _bornes = bornes.filter(_b => _b?.establishment?.uid === element.uid);

      if (_bornes && _bornes.length > 0) {
        const allGood = _bornes.every(_b => _b?.appState?.text === "App Connected");
        const allNotGood = _bornes.every(_b => _b?.appState?.text !== "App Connected");

        let _color = _bornes[0]?.appState?.color;

        if (!allGood && !allNotGood) {
          _color = "orange";
        }

        if (element.color2 !== _color) {
          element.color2 = _color;
          element.marker.setIcon(new H.map.Icon(genSVG(element.color, _color)));
        }
      }

    });

    return () => {

    };
  }, [bornes]);
  useEffect(() => {
    if (mapRef && filterGroupement) {
      if (filterGroupement.length > 0) {
        [...groupements, { name: "noGroup" }].forEach(_g => {
          if (filterGroupement.includes(_g.name)) {
            showGroup(_g.name);
          } else {
            hideGroup(_g.name);
          }
        });
      } else {
        [...groupements, { name: "noGroup" }].forEach(_g => {
          showGroup(_g.name);
        });
      }
    }
  }, [filterGroupement, groupList]);

  useEffect(() => {

    if (mapRef && groupements && groupements.length > 0) {
      // ajout des groupes de groupements :
      [...groupements, { name: "noGroup" }].forEach(_g => {
        addGroup(_g.name);
      });
    }


  }, [mapRef, groupements]);

  const setCoord = (coord, etabId) => {
    const _coords = {...coords};

    let marker = new H.map.Marker(coord);
    mapRef.addObject(marker);
    
    mapRef.setCenter(coord);
    mapRef.setZoom(10);

    _coords[etabId] = {...coord, marker};

    setCoords(_coords);
  };

  const updateEtabCoord = (etabId, cancel) => {

    mapRef.setCenter({lat: 47, lng: 5});
      mapRef.setZoom(6);
      const _coords = {...coords};
      if(_coords[etabId].marker){
        mapRef.removeObject(_coords[etabId].marker);
      }
      

    if(cancel){
      delete _coords[etabId];
      setCoords(_coords);
    }
    else{
      // update Etab !
      updateEstablishmentById({id: etabId, data: {
        position: {
          lat: _coords[etabId].lat,
          long: _coords[etabId].lng
        }
      }})
    }
  };

  return (
    <div>
      <div style={{ position: "fixed", top: 20, zIndex: 1001, right: 200}}>
        <Button onClick={() => setIsOpen(true)}>
          Résidences sans positions : {establishments.filter((_e) => !_e?.position || !_e?.position?.lat || !_e?.position?.long).length}
        </Button>
      </div>

      <div style={{position: "absolute", width: "85vw", padding: 4, zIndex: 400, backgroundColor: "rgba(0,0,0,0.4)"}}>
        <RenderFilter groupements={groupements} filterGroupement={filterGroupement} setFilterGroupement={setFilterGroupement} />

      </div>
      <div
        style={{ width: "85vw", height: "85vh" }}
      //   ref={divRef}
      >
        <MapComponent style={{ width: "100%", height: "100%" }} startLat={47} startLng={5} startZoom={6} />
      </div>

      <Modal isOpen={isOpen} size="xl" toggle={() => setIsOpen(false)}>
        <ModalHeader>
          Etablissements sans position
        </ModalHeader>
        <ModalBody>
          <div style={{ width: "100%", maxHeight: "60vh", overflow: "auto" }}>
            <Table>
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Groupement</th>
                  <th>Adresse</th>
                  <th>Coordonnées</th>
                </tr>
              </thead>
              <tbody>
                {establishments.filter((_e) => !_e?.position || !_e?.position?.lat || !_e?.position?.long).sort((a, b) => a?.groupement?.localeCompare(b?.groupement)).map(i =>
                  <tr key={i.uid}>
                    <td scope="row"><a href={"/dashboard/establishments/" + i.uid + "/settings"}>{i.name}</a></td>
                    <td scope="row">{i?.groupement ? groupements.find(_g => _g.uid === i.groupement)?.name : ""}</td>
                    <td><GeocodeAutocomplete service={service} setCoordinates={(coord) => setCoord(coord, i.uid)}/></td>
                    <td>
                      {coords[i.uid] && <div>
                        <div>
                          <span>{coords[i.uid]?.lat},{coords[i.uid]?.lng}</span>
                        </div>
                        <Button onClick={() => updateEtabCoord(i.uid)}>Valider</Button>
                        <Button onClick={() => updateEtabCoord(i.uid, true)}>Annuler</Button>
                      </div>}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          {/* <div >
            {establishments.filter((_e) => !_e?.position || !_e?.position?.lat || !_e?.position?.long).sort((a, b) => a?.groupement?.name?.localeCompare(b?.groupement?.name)).map(_e => (
              <div key={_e.uid}>
                {_e.name}
              </div>
            ))}
          </div> */}
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setIsOpen(false)}> Retour</Button>
        </ModalFooter>
      </Modal>
    </div>
  );

};

const RenderFilter = ({ groupements, filterGroupement, setFilterGroupement }) => {

  const handleFilter = (value) => {
    let _filterGroupement = [...filterGroupement];

    if (value == "all") {
      _filterGroupement = [];
    } else {
      if (_filterGroupement.indexOf(value) == -1) {
        _filterGroupement.push(value);
      } else {
        _filterGroupement.splice(_filterGroupement.indexOf(value), 1);
      }
    }
    setFilterGroupement(_filterGroupement);
  };

  let retour = [];
  retour.push(<div style={{ backgroundColor: "#51075e", color: "#FFFFFF", padding: 6, borderRadius: 10, cursor: "pointer", opacity: filterGroupement.length > 0 ? 0.35 : 1 }} onClick={() => handleFilter("all")}>Tous</div>);

  let noColorCounter = 0;
  groupements.sort((a,b) => b?.establishments?.length - a?.establishments?.length).forEach(element => {
    if (!element.color) {
      noColorCounter += 1;
      noColorCounter %= colors.length;
      element.color = colors[noColorCounter];
    }
    retour.push(<div style={{ backgroundColor: element?.color ?? colors[noColorCounter], color: findTextColor(element?.color ?? colors[noColorCounter], "#FFFFFF", "#000000"), padding: 6, borderRadius: 10, cursor: "pointer", opacity: filterGroupement.indexOf(element.name) == -1 ? 0.5 : 1 }} onClick={() => handleFilter(element.name)}>{element.name}</div>);
  });

  retour.push(<div style={{ backgroundColor: "#6F686D", color: findTextColor("#6F686D", "#FFFFFF", "#000000"), padding: 6, borderRadius: 10, cursor: "pointer", opacity: filterGroupement.indexOf("noGroup") == -1 ? 0.4 : 1 }} onClick={() => handleFilter("noGroup")}>Pas de groupement</div>);

  return <div style={{ display: "flex", flex: 1, flexWrap: "wrap", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10 }}>
    {retour}
  </div>;
};


RenderFilter.propTypes = {
  groupements: PropTypes.array,
  filterGroupement: PropTypes.array,
  setFilterGroupement: PropTypes.func,
};



function listsHaveSameElements(list1, list2) {
  if (list1.length !== list2.length) {
    return false;
  }

  list1.forEach(element => {
    if (!list2.includes(element)) return false;
  });

  return true;
}

export default Map;