import React,{useEffect} from "react";
import PropTypes from "prop-types";
import {createContext, useReducer, useContext} from "react";
import firebase from "firebase";
import useUI from "../../../../hooks/ui.hook";
import useAnimationEvents, { useAnimationCategories} from "../../../../resources/animations";
import moment from "moment";


const firestore = firebase.firestore;
const Context = createContext();

const Default = {
  dataPlanning: null,
  dataRaw: null,
  dataSelected: null,
  etabUsers: null,
  selectedWeek:  null,
  daysToDisplay : [1, 2, 3, 4, 5, 6, 7],
  modalWeekOpen: false,
  modalInfos: null,
  changeWeek: null,
  updateData: null,
};

function Reducer(state, action) {
  switch(action.type) {
  case "setProperty": return ({...state, [action.property]: action.value});
  default: return {...state};
  }
}

const Provider = ({children}) => {
  const [ui] = useUI();
  const [ctx, dispatch] = useReducer(Reducer, Default);
//   const {categories} = useAnimationCategories();
  const {events, loadWeek, loading, eventLoading} = useAnimationEvents();

    useEffect(() => {
        dispatch({ type: "setProperty", property : "selectedWeek", value: moment().startOf("week") });
    }, []);

  useEffect(() => {
    let _dataPlanning = {};
    events.forEach((event) => {
      let _date = moment(event.start.seconds * 1000).format("YYYY-MM-DD");
      if(_dataPlanning[_date] == undefined) _dataPlanning[_date] = [];
      _dataPlanning[_date].push({id:event.uid ,...event});
    });
    dispatch({ type: "setProperty", property : "dataRaw", value: events});
    dispatch({ type: "setProperty", property : "dataPlanning", value: _dataPlanning });
  }, [events]);

  useEffect(() => {
    firestore()
      .collection("users")
      .where("establishment", "==", ui.user.establishment)
      .where("role", "in", ["senior","seniorTotem"])
      .onSnapshot(res => {
        let _dataUsers = {};
        res.forEach((doc) => {
          _dataUsers[doc.id] = doc.data();
        });
        dispatch({ type: "setProperty", property : "etabUsers", value: _dataUsers });
      });
  }, []);

  useEffect(() =>{
    if(ctx.dataPlanning && ctx.etabUsers && ctx.selectedWeek)
        selectData();
  }, [ctx.dataPlanning, ctx.etabUsers, ctx.selectedWeek]);


  const selectData = () => {
    let selectedData = {};
    let localDate = ctx.selectedWeek;
    for (let i = 1; i <= 7; i++) {
      let formated = localDate.format("YYYY-MM-DD");
      if (ctx.dataPlanning[formated] != undefined) {
        selectedData[formated] = ctx.dataPlanning[formated];
      } else {
        selectedData[formated] = [];
      }
      localDate = moment(localDate).add(1, "day");
    }
    dispatch({ type: "setProperty", property : "dataSelected", value: selectedData });
  };



  const updateData = (updateData, action) => {
    let _data = ctx.dataPlanning;
    let index = _data[updateData.date].findIndex(element => element.id == updateData.idEvent);
    if(action == "remove")_data[updateData.date][index].reservation = _data[updateData.date][index].reservation.filter(element => element != updateData.uid);
    if(action == "add"){
      if(_data[updateData.date][index].reservation == undefined){
        _data[updateData.date][index].reservation = [];
      }
      _data[updateData.date][index].reservation.push(updateData.uid);
    }
    // setDataPlanning(_data);
    // selectData();
  };

  const changeWeek = async ( direction ) => {
    // direction = -1 || 1
    let week = ctx.selectedWeek.clone().add(direction, "week");
    await loadWeek(week.toDate());
    dispatch({ type: "setProperty", property : "selectedWeek", value: week });
  };


  ctx.changeWeek = changeWeek;
  ctx.updateData = updateData;

  
  return (
    <Context.Provider value={[ctx, dispatch]}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.element,
};
const usePlanningReservation = () => {
  const contexte = useContext(Context);

  if(!contexte) throw new Error("Le contexte n'est pas défini, il n'est probablement pas dans un provider !");

  return contexte;
};
export default usePlanningReservation;
export {Provider, Context};