import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import UnsplashPicker from "./UnsplashPicker";
import ImagePicker from "./ImagePicker";

import styles from "../../assets/scss/component/ImagePickerService.module.scss";

const ImagePickerService = (props) => {
  const {
    className,
    style,
    label,
    value,
    onChange,
    setImageLoaderForm
  } = props;
  const [type, setType] = useState("unsplash");//unsplash or local

  useEffect(() => {
    if (value && typeof(value) === "string" && value.includes("firebasestorage.googleapis.com")) {
      setType("local");
    } else if (value && typeof(value) === "string" && value.includes("images.unsplash.com")) {
      setType("unsplash");
    } else {
      setType("unsplash");
    }
  }, []);

  const onChangeUploader = (e) => {
    if(e){
      if (typeof(e) === "object" && e.length > 0) {
        onChange(e[0]);
      } else {
        onChange(e);
      }
    }else{
      onChange(null);
    }
  };

  return (
    <div className={styles.container} style={style ?? {}}>
      <div className={styles.header}>
        <span className={`${styles.button} ${type === "unsplash" ? styles.active : ""}`}
          onClick={() => setType("unsplash")}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Banque d'image
        </span>
        <span className={`${styles.button} ${type === "local" ? styles.active : ""}`}
          onClick={() => setType("local")}>
          Téléverser
        </span>
      </div>
      {type === "unsplash" ? 
        <UnsplashPicker max={1} url={value} onChange={onChange}/>
        : type === "local" ?
          <ImagePicker max={1} value={value} onChange={onChangeUploader} setImageLoaderForm={setImageLoaderForm} />
          : <div style={{height: 750}}>
          </div>}
    </div>
  );
};


ImagePickerService.propTypes = {
  className : PropTypes.any,
  label : PropTypes.string,
  value : PropTypes.any,
  style : PropTypes.object,
  onChange : PropTypes.func,
  setImageLoaderForm : PropTypes.func,
};


export default ImagePickerService;