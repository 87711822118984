import React from "react";
import PropTypes from "prop-types";
import ReactPDF, {
  Document,
  Font,
  Image,
  PDFDownloadLink,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import moment from "moment";
import API from "./assets/api.png";
import label from "./assets/label.png";
import logoHS from "./assets/logo.png";
//import useUI from "hooks/ui.hook";
import Roboto from "fonts/roboto/Roboto-Condensed.ttf";
import RobotoBold from "fonts/roboto/Roboto-BoldCondensed.ttf";

Font.register({
  family: "Roboto",
  fontWeight: "normal",
  src: Roboto,
});
Font.register({
  family: "Roboto",
  format: "bold",
  src: RobotoBold,
});
const styles = {
  container: {display: "flex", flexDirection: "column"},
  header: {height: 80, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"},
  legendContainer: {width: 400, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", marginLeft: 10},
  legendRow: {display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"},
  legendItem: {display: "flex", flexDirection: "row", alignItems: "center", marginRight: 10, width: 130},
  legendItemImage: { width: 17, height: 17, marginLeft: 5},
  legendItemText: {fontSize: 10, marginRight: 5, marginLeft: 5},
  body: {height: 515},
  daysContainer: {display: "flex", flexDirection: "row", justifyContent: "flex-end"},
  day: {
    width: 115,
    height: 30,
    borderWidth: 1,
    borderColor: "black",
    backgroundColor: "#76923c",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }, 
  dayTitle: {color: "white", fontSize: 18,},
  dejeunerContainer: {
    backgroundColor: "#fce5cd",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  dejeunerHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  dejeunerHeaderText: {textAlign: "center", marginRight: 12},
  dejeunerItem: {
    width: 115,
    height: 280,
    borderLeftWidth: 1,
    borderLeftColor: "black",
    padding: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingBottom: 10,
  },
  greenSeparator: {backgroundColor: "#99cc00", height: 20, width: "100%"},
  menuSoftContainer: {
    backgroundColor: "#fff2cc",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    height: 50,
  },
  menuSoftHeader: {display: "flex", alignItems: "center", justifyContent: "center"},
  menuSoftHeaderTitle: {fontSize: 12, textAlign: "center"},
  menuSoftItem: {
    width: 115,
    height: 50,
    borderLeftWidth: 1,
    borderLeftColor: "black",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 10
  },
  orSeparator: {
    backgroundColor: "white",
    height: 50,
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  orSeparatorHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  orSeparatorHeaderText: {
    textAlign: "center",
    marginRight: 12
  },
  orSeparatorItem: {
    width: 115,
    height: 50,
    borderLeftWidth: 1,
    borderLeftColor: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  dinerContainer: {
    backgroundColor: "#c9daf8",
    height: 200,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  dinerHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  dinerHeaderText: {textAlign: "center", marginRight: 12},
  dinerItem: {
    width: 115,
    height: 130,
    borderLeftWidth: 1,
    borderLeftColor: "black",
    padding: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  menuEntry: {
    width: "85%",
    fontSize: 10,
    textAlign: "center",
  }
};

const HappySenior = ({ week, menuState, logo, ui }) => {

  const generateData = (day, repas, menu, category, num, style) => {
    if( day[repas] && day[repas][category] && Object.values(day[repas][category]).length > 0){
      let dataList = Object.values(day[repas][category]);
      let newDataList = [];

      if(menu){
        dataList.forEach(_data => {
          if(_data.origin === menu)
            newDataList.push(_data);
        });
      }else{
        newDataList = dataList;
      }
      let data = newDataList[num];

      if(data){
        return (<View style={style}>
          <Text style={styles.menuEntry}>{data.name}</Text>
          {data.label ? 
            <Image src={ui.establishment.labels[data.label].img} style={styles.legendItemImage} /> 
            : null}
        </View>);
      }else{
        return <View style={style}></View>;
      }
        
    }else{
      return <View style={style}></View>;
    }
  };

  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View style={styles.container}>
          <View style={styles.header}>
            <Image src={API} style={{width: 40, marginLeft: 5, marginRight: 10}} />
            <View style={{display: "flex", flexDirection: "column", marginRight: 20}}>
              <Text>MENU DES REPAS DE LA SEMAINE</Text>
              <Text>Semaine du {moment(week[0] ?? new Date()).format("DD")} au {moment(week[6] ?? new Date()).format("DD MMMM YYYY")}</Text>
            </View>
            <View style={styles.legendContainer}>
              {Object.values(ui.establishment.labels).map((_label,id) => (
                <View key={id} style={[styles.legendItem, {width: 110}]}>
                  <Image src={_label.img} style={styles.legendItemImage}/>
                  <Text style={styles.legendItemText}>{_label.description}</Text>
                </View>
              ))}
            </View>
            <Image src={logoHS} style={{width: 80, height: 80, marginRight: 5, marginLeft: 5}} />
          </View>
          <View style={styles.body}>
            <View style={styles.daysContainer}>
              {week.map(i => (
                <View style={styles.day} key={moment(i ?? new Date()).format("ll")}>
                  <Text style={styles.dayTitle}>{moment(i ?? new Date()).format("ddd DD MMM")}</Text>
                </View>
              ))}
            </View>
            <View style={styles.dejeunerContainer}>
              <View style={styles.dejeunerHeader}>
                {[..."DEJEUNER"].map((i, id) => (
                  <Text style={styles.dejeunerHeaderText} key={id}>{i ?? " "}</Text>
                ))}
              </View>
              {Object.values(menuState).map((d, id) => (
                <View style={styles.dejeunerItem} key={id}>
                  {generateData(d, "Déjeuner", "Menu du jour", "Entrée",0,{flexDirection: "row"})}
                  {generateData(d, "Déjeuner", "Menu du jour", "Plat",0,{flexDirection: "row"})}
                  {generateData(d, "Déjeuner", "Menu du jour", "Accompagnement",0,{flexDirection: "row"})}
                  {generateData(d, "Déjeuner", "Menu du jour", "Accompagnement",1,{flexDirection: "row"})}
                  {generateData(d, "Déjeuner", "Menu du jour", "Fromage",0,{flexDirection: "row"})}
                  {generateData(d, "Déjeuner", "Menu du jour", "Dessert",0,{flexDirection: "row"})}
                </View>
              ))}
            </View>
            
            <View style={styles.greenSeparator}></View>
            <View style={styles.menuSoftContainer}>
              <View style={styles.menuSoftHeader}>
                <Text style={styles.menuSoftHeaderTitle}>MENU</Text>
                <Text style={styles.menuSoftHeaderTitle}>SOFT</Text>
              </View>
              {Object.values(menuState).map((d, id) => (
                <>
                  {generateData(d, "Dîner", "Menu Soft", "Soupe gourmande", 0, styles.menuSoftItem)}
                </>
              ))}
            </View>
            <View style={styles.orSeparator}>
              <View style={styles.orSeparatorHeader}>
                {[..."ou"].map((i, id) => (
                  <Text style={styles.orSeparatorHeaderText} key={id}>{i}</Text>
                ))}
                {/*<View style={{position: "absolute", width: 500}}>
                  <Text style={{width: "100%", background: "red", textAlign: "center", fontSize: 9}}>Le Menu Soft est servi avec le dessert du soir ou du fromage.</Text>
                </View>*/}
              </View>
              
              
              {week.map(i => (
                <View style={styles.orSeparatorItem} key={moment(i).format("ll")}>
                  <Text style={{fontSize: 12}}>Ou</Text>
                </View>
              ))}
            </View>
            <View style={styles.dinerContainer}>
              <View style={styles.dinerHeader}>
                {[..."DINER"].map((i, id) => (
                  <Text style={styles.dinerHeaderText} key={id}>{i}</Text>
                ))}
              </View>
              {Object.values(menuState).map((d, id) => (
                <View style={styles.dinerItem} key={id}>
                  {generateData(d, "Dîner", "Menu Complet", "Plat", 0,{flexDirection: "row"})}
                  {generateData(d, "Dîner", "Menu Complet", "Accompagnement", 0,{flexDirection: "row"})}
                  {generateData(d, "Dîner", "Menu Complet", "Dessert", 0,{flexDirection: "row"})}
                </View>
              ))}
            </View>
            <View style={{marginVertical: 4}}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <Text style={{color: "red", fontSize: 9}}>* Si l'un des plats des menus de la semaine ne vous convient pas, tout remplacement doit être commandé avant dimanche 17 heures</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

HappySenior.propTypes = {
  week: PropTypes.any,
  menuState: PropTypes.any,
  logo: PropTypes.any,
  ui: PropTypes.any,
};

export default HappySenior;