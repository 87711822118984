import React from "react";
import styles from "../assets/scss/component/button.module.scss";
import PropTypes from "prop-types";
import Spinner from "./Spinner";

const Button = (props) => {
  const {color, size, disabled, className, type, onClick, children, loading, style} = props;

  const click = (e) => {
    if (!disabled && onClick)
      onClick(e);
  };

  const colorClass = color === "primary"   ? styles.primary   :
    color === "secondary" ? styles.secondary :
      color === "primaryDark" ? styles.primaryDark :
        color === "secondaryDark" ? styles.secondaryDark :
          color === "success"   ? styles.success   :
            color === "warning"   ? styles.warning   :
              color === "info"      ? styles.info      :
                color === "danger"    ? styles.danger    : styles.primary;
  
  const sizeClass = size === "md" ? styles.md :
    size === "sm" ? styles.sm :
      size === "xs" ? styles.xs :
        size === "xl" ? styles.xl : styles.md;

  return (
    <div className={`${styles.button} ${disabled ? styles.disabled : ""}
            ${loading ? styles.loading : ""} ${colorClass} ${sizeClass} ${className ?? ""}`}
    type={type}
    disabled={disabled}
    style={style}
    onClick={click}>
      {children}
      {loading && 
        <Spinner className="m-r-0 m-l-10" color="light" size="sm" />
      }
      {disabled &&
        <span className={styles.overlay}></span>
      }
    </div>
  );
};

Button.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "success", "warning", "info", "danger", "primaryDark", "secondaryDark"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "xl"]),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit"]),
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  style : PropTypes.any,
};

Button.defaultProps = {
  color     : "primary",
  size      : "md",
  disabled  : false,
  loading   : false,
  className : "",
  type      : "button",
  style     : {}
};

export default Button;