import React, {useState} from "react";
import {Container, Row, Col, Input, Card, CardHeader, CardBody, CardFooter, Button, FormGroup, Separator} from "../../lib/components";

import {useFormik} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";

import useUI from "../../hooks/ui.hook";

import firebase from "firebase";
import { updateUserById } from "services/ressources/user";

const ProfilePage = () => {
  const [ui, uiReducer] = useUI();
  
  const [infoError, setInfoError] = useState({});
  const infoForm = useFormik({
    initialValues: {
      name: ui.user.name,
      surname: ui.user.surname,
    },
    validationSchema: Yup.object({
      surname: Yup.string().min(2).max(15).required(),
      name: Yup.string().min(2).max(15).required(),
    })
  });
  const onSubmitInfo = async () => {
    setInfoError({});
    const errors = await infoForm.validateForm();

    if (Object.keys(errors).length)
      setInfoError(errors);
    else {
      await updateUserById({id: ui.user.uid, data: infoForm.values});
      uiReducer({
        type: "updateUser",
        user: infoForm.values
      });
      toast.success("Vos informations ont bien été mises à jour");
    }
  };

  const [securityError, setSecurityError] = useState({});
  const securityForm = useFormik({
    initialValues: {
      password: "",
      confirm: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().min(6).max(15).required(),
      confirm: Yup.string().oneOf([Yup.ref("password")], "Password's not match").required(),
    })
  });
  const onSubmitSecurity = async () => {
    setSecurityError({});
    const errors = await securityForm.validateForm();

    if (Object.keys(errors).length)
      setSecurityError(errors);
    else {
      await firebase.auth()
        .currentUser
        .updatePassword(securityForm.values.password);
      toast.success("Votre mot de passe a bien été mis à jour");
    }
  };


  return (
    <Container>
      <Row>
        <Col>
          <Card>
            <CardHeader><h5>Mon profil</h5></CardHeader>
            <CardBody>
              <Separator className="m-b-10">Informations</Separator>
              <FormGroup className="d-flex" style={{justifyContent: "space-between"}}>
                <div className="wd-50-p p-r-10">
                  <Input label={"Nom"} 
                    name="name"
                    invalid={infoError.name}
                    value={infoForm.values.name}
                    onChange={infoForm.handleChange} />
                </div>
                <div className="wd-50-p p-l-10">
                  <Input label={"Prénom"}
                    name="surname"
                    invalid={infoError.surname}
                    value={infoForm.values.surname}
                    onChange={infoForm.handleChange} />
                </div>
              </FormGroup>
              <Button onClick={onSubmitInfo}>Enregistrer</Button>

              <Separator className="m-t-40 m-b-10">Sécurité</Separator>

              <FormGroup className="d-flex" style={{justifyContent: "space-between"}}>
                <div className="wd-50-p p-r-10">
                  <Input type="password"
                    label={"Mot de passe"}
                    name="password"
                    invalid={securityError.password}
                    value={securityForm.values.password}
                    onChange={securityForm.handleChange} />
                </div>
                <div className="wd-50-p p-r-10">
                  <Input type="password"
                    label={"Confirmation"}
                    name="confirm"
                    invalid={securityError.confirm}
                    value={securityForm.values.confirm}
                    onChange={securityForm.handleChange} />
                </div>
              </FormGroup>
              <Button onClick={onSubmitSecurity}>Enregistrer</Button>
              <Separator />
            </CardBody>
            <CardFooter>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfilePage;