import React, {useEffect, useState} from "react";
import useGazette, {Provider} from "../context";
import { useParams } from "react-router-dom";
import { Tab, Input, DatePicker, ColorPicker } from "lib/components";
import useUI from "hooks/ui.hook";
import firebase from "firebase";
import ArticlesSelector from "./ArticlesSelector";
import PagesEditor from "./PagesEditor";
import ContentEditor from "./ContentEditor";
import Previsualisation from "./Previsualisation";
import { toast } from "react-toastify";
import moment from "moment";
import {getPageTypeFromSlug} from "../pageTypes";
import {SpecificRouter, hasGroupementFeature} from "specificGroupement/SpecificRouter";

const firestore = firebase.firestore;

const GazetteEdit = () => {
  const {uid} = useParams();
  const [ui] = useUI();
  const [ctx, dispatch] = useGazette();
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (uid) {
          let _gazette = await firestore().collection("establishments").doc(ui.user.establishment)
            .collection("gazettes").doc(uid).get();
          let pages = _gazette.data().pages.map(JSON.parse);
          let articles = _gazette.data().articles.map(JSON.parse).map(i => ({...i, date: moment().utc(i.date).toDate(), updatedAt: moment().utc(i.date).toDate()}));
          let title = _gazette.data().title && _gazette.data()?.title?.length > 0 ? _gazette.data().title : "Gazette du " + moment().format("DD/MM/YYYY HH:mm");

          dispatch({type: "clear"});
          dispatch({type: "setTitle", title});
          dispatch({type: "setPages", pages});
          dispatch({type: "setArticles", articles});
          dispatch({type: "setReady", ready: true});
          dispatch({type: "setProperty", property: "uid", value: uid});
          dispatch({type: "setProperty", property: "createdAt", value: _gazette.data().createdAt.toDate()});
          dispatch({type: "setProperty", property: "editionDate", value: _gazette.data().editionDate.toDate()});
          dispatch({type: "setProperty", property: "publication", value: _gazette.data().publication ?? null});
          if(_gazette.data().color)
            dispatch({type: "setProperty", property: "color", value: _gazette.data().color});

        } else {
          dispatch({type: "clear"});
          dispatch({type: "setReady", ready: true});
          dispatch({type: "setProperty", property: "createdAt", value: new Date()});
          dispatch({type: "setProperty", property: "title", value: "Gazette du " + moment().format("DD/MM/YYYY HH:mm")});
          dispatch({type: "addPage", pageType : getPageTypeFromSlug("summary")});
        }
      } catch (e) {
        console.error(e);
        toast.error("Une erreur est survenue");
      }
    })();
  }, [uid]);

  useEffect(() => {
    if(ctx.step === 2) {
      setIsHidden(true);
    }
    else{
      setIsHidden(false);
    }
  }, [ctx.step]);
  

  const updateColor = (color) => {
    dispatch({type: "setProperty", property: "color", value: color});
  };

  if (!ctx.ready) return <>loading...</>;
  return (
    <div style={{width: "100%"}}>
      <div style={{display: "flex", alignItems: "center", gap: 10, marginBottom: 15, display: isHidden ? "none" : "flex"}}>
        <span>Titre : </span><Input placeholder="Titre de la gazette..." value={ctx.title} onChange={e => dispatch({type: "setTitle", title: e.target.value.slice(0, 29)})}/>
        <span>Date d'édition : </span><DatePicker value={ctx.editionDate} onChange={date => dispatch({type: "setProperty", property: "editionDate", value: date})}/>
        {(!ui?.groupement?.specific || ui?.groupement?.specific !== "heurus") && <><span>Couleur de la trame</span>  <ColorPicker style={{width: 60}}value={ctx.color ?? "#FFFFFF"} onChange={updateColor}/></>}
      </div>

      <Tab activeTab={ctx.step}
        onClick={(step) => dispatch({type: "setStep", step})}
        render={[
          {title: "Sélection d'actualité",         content: <ArticlesSelector />, hide: isHidden},
          {title: "Création de la gazette",            content: <PagesEditor />, hide: isHidden},
          {title: "Contenu",          content: <ContentEditor />, hide: true},
          {title: "Finalisation", content: <Previsualisation />, hide: isHidden},
        ]} 
      />
    </div>
  );
};

const GazetteHOC = () => (

  <Provider>
    {hasGroupementFeature("gazette") 
      ? <SpecificRouter path="gazette"/> 
      : <GazetteEdit />}
  </Provider>
);

export default GazetteHOC;