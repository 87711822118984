import React from "react";
import PropTypes from "prop-types";
import { X } from "react-feather";
import styles from "../assets/scss/component/dropdown.module.scss";

const DropDown = (props) => {
  const {
    isOpen,
    className,
    toggle,
    style,
    children
  } = props;

  if (!isOpen) return null;
  return (
    <div className={styles.container} style={style ?? {}}>
      <X className={styles.x} onClick={toggle} color="black"/>
      {children}
    </div>
  );
};

DropDown.propTypes = {
  isOpen : PropTypes.bool,
  className : PropTypes.string,
  toggle : PropTypes.func,
  style : PropTypes.any,
  children : PropTypes.element,
};
export default DropDown;