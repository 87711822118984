import React, { useEffect, useState, useRef } from "react";
import { useServiceManagement } from "../../routerContext";
import { Button, Row, Col } from "lib/components";
import { CategoriesCrudService, PlanningModal } from "../../routerComponents";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import bootstrapPlugin from "@fullcalendar/bootstrap";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import frLocale from "@fullcalendar/core/locales/fr";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import styles from "../../../../../../assets/scss/pages/animations.module.scss";
import PropTypes from "prop-types";

const Planning = (props) => {
  const {
    forceCalendar
  } = props;
  const [ctx] = useServiceManagement();
  const calendarRef = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [eventsParsed, setEventsParsed] = useState([]);
  const [eventOpen, setEventOpen] = useState(null);
  const [preselectStart, setPreselectStart] = useState(null);
  const [preselectEnd, setPreselectEnd] = useState(null);
  const [preselectDate, setPreselectDate] = useState(null);
  // const [forceRenderCalendar, setForceRenderCalendar] = useState(null);

  // useEffect(() => {
  //   setForceRenderCalendar(forceCalendar);
  //   if (forceCalendar) {
  //     setTimeout(() => {
  //       setForceRenderCalendar(false);
  //     }, 200);
  //   }
  // }, [forceCalendar]);

  const findColorMatch = (data) =>{
    if (!ctx.prestataires || !ctx.availableServices) {
      return ""; 
    }
    const prestataireService =ctx.prestataires.find((item)=> item.uid === data.prestataireId).serviceRef;
      const service = ctx.availableServices.find(item => item.name === prestataireService);
      return service ? service.color : "";
  };

  useEffect(() => {
    if (!ctx.planning || !ctx.prestataires || !ctx.availableServices) {
      return; }
    let planning= ctx.planning;
    let data = planning.map((i) => {
      const prestataire = ctx.prestataires.find((item) => item.uid === i.prestataireId);
      if (!prestataire){
        return null;
      }
      return {
        title: prestataire.name,
        start: i.start,
        end: i.end,
        color: findColorMatch(i),
        extendedProps: {
          ...i,
        },
      };
     
    }
    
    ).filter((item) => item !== null);
    setEventsParsed(data);

  },  [ctx.planning, ctx.prestataires, ctx.availableServices]);

  const _onDateSelect = (e) => {
    if (e.view.type === "timeGridWeek") {
      setPreselectStart(e.start);
      setPreselectEnd(e.end);
    }
    setPreselectDate(e.start);
    setIsModalVisible(true);
  };

  return (
     <div className={styles.animation}>
      {ctx.prestataires.length>0 ?<> <Row>
            <CategoriesCrudService/>
          </Row>
      <Button
        style={{ display: "flex", alignItems: "center", width: "fit-content", height: "40px" }}
        color="primaryDark"
        onClick={() => setIsModalVisible(true)}
      >Ajouter une disponibilité
      </Button>
      <Row>
        <Col lg={12} style={{ marginTop: 20 }}>
          <div
            style={{
              border: "1px solid lightgrey",
              padding: 7,
              borderRadius: 15,
              height: "80vh",
              overflowY: "auto",
            }}
          >
            {!forceCalendar ? 
              <FullCalendar
                headerToolbar={{ center: "dayGridMonth,timeGridWeek" }}
                ref={calendarRef}
                plugins={[dayGridPlugin, bootstrapPlugin, interactionPlugin, timeGridPlugin]}
                themeSystem="bootstrap"
                initialView="timeGridWeek"
                locales={[frLocale]}
                locale={"fr"}
                selectable
                select={_onDateSelect}

                slotDuration="00:30:00"
                slotMinTime="08:00:00"
                slotMaxTime="23:00:00"
                allDaySlot={false}
                events={eventsParsed}
                eventClick={(e) => {
                  setEventOpen(e.event.extendedProps);
                  setIsModalVisible(true);
                }}
                
                nowIndicator
              />
            : null}
          </div>
        </Col>
      </Row>
      <PlanningModal 
      isOpen={isModalVisible} 
      eventOpen={eventOpen}
      preselectStart={preselectStart}
      preselectEnd={preselectEnd}
      preselectDate={preselectDate}
      toggle={() => { 
        setIsModalVisible(false); 
        setEventOpen(null);
        setPreselectStart(null);
        setPreselectEnd(null);
        setPreselectDate(null);
      }}
      events={eventsParsed}

      /></>:       <div style={{ fontStyle: "italic",textAlign: "center",fontSize: "20px", color: "#505050"}}>Pour commencer, ajoutez un nouveau prestataire en cliquant sur le bouton en haut a droite</div>}
    </div>
  );
};

Planning.propTypes = {
  forceCalendar: PropTypes.bool.isRequired
  
  
};
export default Planning;