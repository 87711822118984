export const colorByCate = {
    all: { primary: "#C087CA" , secondary: "#C087CA" },
    animation: { primary: "#EFA55C", secondary: "rgba(217, 141, 68, 0.35)" },
    service: { primary: "#99E36E", secondary: "rgba(172, 241, 132, 0.45)" },
    menu: { primary: "#5CADDB", secondary: "rgba(68, 172, 217, 0.35)" },
    intervention: { primary: "#EFD900", secondary: "rgba(255, 240,101, 0.45)" },
    birthday: { primary: "#DF7189", secondary: "rgba(245, 148, 194, 0.45)" },
};


export const colorByCateLessClear = {
    all: { primary: "#841494" , secondary: "#d69ddc" },
    animation: { primary: "#efa55c", secondary: "#ebcfb7" },
    service: { primary: "#99E36E", secondary: "#daf5cf" },
    menu: { primary: "#5CADDB", secondary: "#b3dbef" },
    intervention: { primary: "#efd900", secondary: "#f3e87e" },
    birthday: { primary: "#DF7189", secondary: "#f3d0e4" },
};
