import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import {getFromPath} from "./operations";

const TimeChart = ({dates, name, data1, data2, path}) => {

    if(!dates || !dates.startDate)return <></>;

    let _data = getFromPath(data1, path);
    const _labels = Object.keys(_data);

    let list = [];
    let labels = [];

    const startDate = moment(dates.startDate);
    const endDate = moment(dates.endDate);

    for(let i=0; i<endDate.diff(startDate, "days"); i++){
        const _date = startDate.clone().add(i, "days");
        const _formatDate = _date.format("YYYY-MM-DD");
        if(_labels.includes(_formatDate)){
            list.push(_data[_formatDate]);
            labels.push(_date.format("DD MMM"));
        }else{
            list.push(0);
            labels.push(_date.format("DD MMM"));
        }
    }

    const options = {
        series: [{
            name: name ?? "Series 1",
            data: list
          }],
        chart: {
          width: "100%",
          height: "100%",
          type: "line",
          zoom: {
            enabled: endDate.diff(startDate) > 1000*60*60*24*90 ? true : false // > 3 month
          },
          stroke: {
            curve: "stepline",
          }
        },
        dataLabels: {
            enabled: false
          },
        labels: labels,
      };

    return (
      <div style={{width: "100%", height: "100%", display:"flex", alignItems: "center", justifyContent:"center"}}>
        <Chart
              options={options}
              series={options.series}
              type="line"
              width={options.chart.width}
              height={options.chart.height}
            />
      </div>
    );
};

TimeChart.propTypes = {
  dates: PropTypes.object,
  name: PropTypes.string,
  data1: PropTypes.object,
  data2: PropTypes.object,
  path: PropTypes.string,
};

export default TimeChart;
