import React, { createContext, useReducer, useEffect, useState } from "react";
import firebase from "firebase";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { getGroupementById, updateGroupementById, updateAllGroupementEstablishmentsById } from "services/ressources/groupement";

const firestore = firebase.firestore;
const Context = createContext();

const Default = {
  step: 0,
  subscription: null,
  template: {},
  templateSubscription: {},
  labels: {},
  establishments: [],
};

function Reducer(state, action) {
  switch (action.type) {
    case "setValue": return ({ ...state, [action.key]: action.value });
    case "setState": return ({ ...state, ...action.state });
    default: return ({ ...state });
  }
}

const Provider = ({ children, establishmentId }) => {
  const { id } = useParams();
  const [ctx, reducer] = useReducer(Reducer, Default);

  useEffect(() => {
    (async () => {
      const _data = await getGroupementById({ id });
      reducer({ type: "setValue", key: "template", value: _data?.template ?? {} });
      reducer({ type: "setValue", key: "templateSubscription", value: _data?.templateSubscription ?? {} });
      reducer({ type: "setValue", key: "establishments", value: _data?.establishments ?? [] });

    })();
  }, [id]);

  const save = async () => {
    try {
      await updateGroupementById({
        id, data: {
          templateSubscription: ctx.templateSubscription
        }
      });
      toast.success("Modifications enregistrées");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  // const remove = async () => {
  //   try {
  //     await updateGroupementById({id, data: {
  //       templateSubscription: null
  //     }});
  //     toast.success("Modifications enregistrées");
  //   } catch (e) {
  //     console.error(e);
  //     toast.error(e.message);
  //   }
  // };

  const pushToAllEtab = async () => {

    await save();

    try {
      await updateAllGroupementEstablishmentsById({
        idList: ctx.establishments, data: {
          templateSubscription: ctx.templateSubscription
        }
      });
      toast.success("Etablissements mis à jour");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };


  return (
    <Context.Provider value={[
      {
        ...ctx,
        save,
        // remove,
        pushToAllEtab
      },
      reducer
    ]}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.element,
  establishmentId: PropTypes.string.isRequired
};

const useContext = () => React.useContext(Context);

export default useContext;
export { Provider };