import {
  Plus,
  Trash,
} from "react-feather";
import React, { useEffect, useState } from "react";

import { LabelSelector } from "../../../router_components";
import { useMenu } from "../../../router_context";

import PropTypes from "prop-types";
import deepClone from "helpers/deepClone";
import md5 from "md5";
import moment from "moment";
import styles from "assets/scss/components/menuCard.module.scss";
import useUI from "hooks/ui.hook";
import { getEstablishmentById } from "services/ressources/establishment";
import { Checkbox } from "../../../../../../lib/components";

const MenuInput = (props) => {
  const { category, date, color, origin } = props;
  const [ui] = useUI();

  const [dishInput, setDishInput] = useState("");
  const [ctx, dispatch] = useMenu();

  const _localWeek = ctx.meal === "Déjeuner" ? ctx.week.slice(0,5) : ctx.week ;

  useEffect(() => {
    setDishInput("");
  }, [date]);

  const add = () => {
    if (!dishInput) return;
    let order = Object.entries(ctx.state[date][ctx.meal][category]).filter(([, dish]) => dish.origin === origin).length + 1;
    let hash = md5(`${date}-${ctx.meal}-${category}-${origin}-${dishInput}-${order}`);

    if (ui.establishment.template[ctx.meal].menus[origin].type === "daily" || (ui.establishment.template[ctx.meal].menus[origin].type === "weekly" && moment(date).weekday() >= 5)) {
      dispatch({
        type: "setState",
        state: {
          ...ctx,
          state: {
            ...ctx.state,
            [date]: {
              ...ctx.state[date],
              [ctx.meal]: {
                ...ctx.state[date][ctx.meal],
                [category]: {
                  ...ctx.state[date][ctx.meal][category],
                  [hash]: {
                    name: dishInput,
                    origin,
                    label: null,
                    order: Object.entries(ctx.state[date][ctx.meal][category]).filter(([, dish]) => dish.origin === origin).length + 1
                  },
                },
              },
            },
          },
        },
      });
    } else if (
      ui.establishment.template[ctx.meal].menus[origin].type === "weekly"
    ) {
      // on duplique les données sur la semaine
      let newState = deepClone(ctx.state);

      _localWeek.forEach((day) => {
        const localDate = moment(day).format("YYYY-MM-DD");
        const _obj =  {
          name: dishInput,
          origin,
          label: null,
          order:
            Object.entries(ctx.state[date][ctx.meal][category]).filter(
              ([, dish]) => dish.origin === origin
            ).length + 1,
        };

        newState[localDate][ctx.meal][category][hash] = _obj;
      });

      //recompute order
      // Object.entries(newState[date][ctx.meal][category])
      //   .sort(([, a], [, b]) => b.order - a.order)
      //   .forEach(([, value]) => {
      //     if (value.origin === origin) {
      //       value.order =
      //         Object.entries(newState[date][ctx.meal][category]).filter(
      //           ([, dish]) => dish.origin === origin
      //         ).length + 1;
      //     }
      //   });

      dispatch({ type: "setState", state: { ...ctx, state: newState } });
    }
    setDishInput("");
    setDisplayDiv(false);
  };

  const setHotPlate = (dishId) => {
    dispatch({
      type: "setState",
      state: {
        ...ctx,
        state: {
          ...ctx.state,
          [date]: {
            ...ctx.state[date],
            [ctx.meal]: {
              ...ctx.state[date][ctx.meal],
              [category]: {
                ...ctx.state[date][ctx.meal][category],
                [dishId]: {
                  ...ctx.state[date][ctx.meal][category][dishId],
                  hotPlate: !ctx.state[date][ctx.meal][category][dishId].hotPlate ?? true,
                },
              },
            },
          },
        },
      },
    });
  };

  //update
  const setLabel = (dishId, label) => {
    if (dishId) {
      if (ui.establishment.template[ctx.meal].menus[origin].type === "daily" || (ui.establishment.template[ctx.meal].menus[origin].type === "weekly" && moment(date).weekday() >= 5)) {
        dispatch({
          type: "setState",
          state: {
            ...ctx,
            state: {
              ...ctx.state,
              [date]: {
                ...ctx.state[date],
                [ctx.meal]: {
                  ...ctx.state[date][ctx.meal],
                  [category]: {
                    ...ctx.state[date][ctx.meal][category],
                    [dishId]: {
                      ...ctx.state[date][ctx.meal][category][dishId],
                      label: label,
                    },
                  },
                },
              },
            },
          },
        });
      } else if (
        ui.establishment.template[ctx.meal].menus[origin].type === "weekly"
      ) {
        // on duplique les données sur la semaine
        let newState = deepClone(ctx.state);
        _localWeek.forEach((day) => {
          let localDate = moment(day).format("YYYY-MM-DD");
          newState[localDate][ctx.meal][category][dishId].label = label;
        });

        dispatch({ type: "setState", state: { ...ctx, state: newState } });
      }
    }
  };

  const remove = (dishId) => {
    let newState = deepClone(ctx.state);
    let _category = Object.entries(newState[date][ctx.meal][category])
      .map(([key, value]) => ({ ...value, id: key }))
      .filter((dish) => dish.origin === origin)
      .sort((a, b) => a.order - b.order);
    let index = _category.findIndex((dish) => dish.id === dishId);
    _category.splice(index, 1);
    _category = _category.map((dish, index) => ({ ...dish, order: index + 1 }));

    if (ui.establishment.template[ctx.meal].menus[origin].type === "daily" || (ui.establishment.template[ctx.meal].menus[origin].type === "weekly" && moment(date).weekday() >= 5)) {
      let othersEntries = Object.entries(
        newState[date][ctx.meal][category]
      ).filter(([, dish]) => dish.origin !== origin);
      dispatch({
        type: "setState",
        state: {
          ...ctx,
          state: {
            ...ctx.state,
            [date]: {
              ...ctx.state[date],
              [ctx.meal]: {
                ...ctx.state[date][ctx.meal],
                [category]: {
                  ...othersEntries.reduce(
                    (acc, [key, value]) => ({ ...acc, [key]: value }),
                    {}
                  ),
                  ..._category.reduce(
                    (acc, dish) => ({ ...acc, [dish.id]: dish }),
                    {}
                  ), //reconstruct to objects
                },
              },
            },
          },
        },
      });
    } else if (
      ui.establishment.template[ctx.meal].menus[origin].type === "weekly"
    ) {
      // on duplique les données sur la semaine
      _localWeek.forEach((day) => {
        let localDate = moment(day).format("YYYY-MM-DD");
        let othersEntries = Object.entries(
          newState[localDate][ctx.meal][category]
        ).filter(([, dish]) => dish.origin !== origin);

        newState[localDate][ctx.meal][category] = {
          ...othersEntries.reduce(
            (acc, [key, value]) => ({ ...acc, [key]: value }),
            {}
          ),
          ..._category.reduce((acc, dish) => ({ ...acc, [dish.id]: dish }), {}), //reconstruct to objects
        };
      });
      dispatch({ type: "setState", state: { ...ctx, state: newState } });
    }
  };

  const move = (dishId, direction) => {
    let newState = deepClone(ctx.state);
    let _category = Object.entries(newState[date][ctx.meal][category])
      .map(([key, value]) => ({ ...value, id: key }))
      .filter((dish) => dish.origin === origin)
      .sort((a, b) => a.order - b.order);

    //swap in array
    if (direction === "up") {
      let index = _category.findIndex((dish) => dish.id === dishId);
      if (index === 0) return;
      let tmp = _category[index - 1];
      _category[index - 1] = _category[index];
      _category[index] = tmp;
    } else if (direction === "down") {
      let index = _category.findIndex((dish) => dish.id === dishId);
      if (index === _category.length - 1) return;
      let tmp = _category[index + 1];
      _category[index + 1] = _category[index];
      _category[index] = tmp;
    }

    //recompute order
    _category = _category.map((dish, index) => ({
      ...dish,
      order: index + 1,
    }));

    //reconstruct state to object
    _category = _category.reduce((acc, dish) => {
      acc[dish.id] = dish;
      return acc;
    }, {});

    if (ui.establishment.template[ctx.meal].menus[origin].type === "daily") {
      dispatch({
        type: "setState",
        state: {
          ...ctx,
          state: {
            ...ctx.state,
            [date]: {
              ...ctx.state[date],
              [ctx.meal]: {
                ...ctx.state[date][ctx.meal],
                [category]: {
                  ...ctx.state[date][ctx.meal][category], //fill state with other origins
                  ..._category,
                },
              },
            },
          },
        },
      });
    } else if (
      ui.establishment.template[ctx.meal].menus[origin].type === "weekly"
    ) {
      // on duplique les données sur la semaine
      ctx.week.forEach((day) => {
        let localDate = moment(day).format("YYYY-MM-DD");
        newState[localDate][ctx.meal][category] = {
          ...newState[localDate][ctx.meal][category], //fill state with other origins
          ..._category,
        };
      });
      dispatch({ type: "setState", state: { ...ctx, state: newState } });
    }
  };

  const _onKeyDown = (e) => {
    if (e.key === "Enter") add();
  };

  const [displayDiv, setDisplayDiv] = useState(false);

  const handleBlur = () => {
    if (dishInput !== "") {
      setDisplayDiv(true);
    }
  };

  return (
    <div className={styles.field}>
      <div className={styles.inputContainer}>
        <input
          type="text"
          value={dishInput}
          onChange={(e) => setDishInput(e.target.value)}
          onKeyDown={_onKeyDown}
          onBlur={handleBlur}
        />
        <Plus className={styles.icon} size={30} onClick={add} />
        {/* <LabelSelector color={color} value={label} onChange={setLabel} /> */}
      </div>
      <div>
        {displayDiv && (
          <div>
            <span className={styles.errorInput}>
              Cliquez sur le + pour valider votre saisie
            </span>
          </div>
        )}
      </div>

      {ctx &&
      ctx.state &&
      ctx.state[date] &&
      ctx.state[date][ctx.meal] &&
      ctx.state[date][ctx.meal][category] ? (
        <div className={styles.categoryList}>
          {Object.entries(ctx.state[date][ctx.meal][category])
            .filter(([, dish]) => dish.origin === origin)
            .sort(([, a], [, b]) => a.order - b.order)
            .map(([dishId, dish]) => (
              <React.Fragment key={dishId}>
                {dish.origin === origin ? (
                  <div className={styles.categoryItem}>
                    {ui?.groupement?.labels && Object.keys(ui.groupement.labels).length > 0 ? (
                      <LabelSelector
                        color={color}
                        value={dish.label}
                        onChange={(label) => setLabel(dishId, label)}
                      />
                    ) : null}
                    {/*<ArrowDown onClick={() => move(dishId, "down")} color="#4a0956" size={18} style={{cursor: "pointer"}} />
                    <ArrowUp   onClick={() => move(dishId, "up")} color="#4a0956" size={18} style={{cursor: "pointer", marginLeft: -5}} />*/}
                    <div
                      style={{
                        color: "rgba(45, 52, 68, .9)",
                        flex: 1,
                        userSelect: "none",
                      }}
                    >
                      {dish.name}
                    </div>
                    {category === "entrée" && <Checkbox label={"Chaud"} checked={dish.hotPlate} onChange={() => setHotPlate(dishId)}/>}
                    <Trash
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => remove(dishId)}
                      size={18}
                    />
                  </div>
                ) : null}
              </React.Fragment>
            ))}
        </div>
      ) : null}
    </div>
  );
};

MenuInput.propTypes = {
  category: PropTypes.string,
  date: PropTypes.any,
  origin: PropTypes.string,
  color: PropTypes.string,
};

export default MenuInput;

