import React, { useEffect } from "react";
import { ArrowLeft, Plus, Check, Edit, ArrowRight, ChevronRight, ChevronLeft,User } from "react-feather";
import { Button, Row, Icon, Container, Card, Input, Select } from "lib/components";
import styles from "../Scss/addPrestataire.module.scss";
import PropTypes from "prop-types";
import { useServiceReservation } from "../../routerContext";

const ButtonAddPrestataire = (props) => {
  const [ctx, {  updatePage }] = useServiceReservation();


    return (
        <div className={styles.cardContainer} onClick={()=>updatePage(2)}>
          <div></div>
          <Plus size={120} color="white" />
          <Button color="secondaryDark" onClick={()=>updatePage(2)}>Créer un prestataire</Button>



        </div>
      );
};

export default ButtonAddPrestataire;

