import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import useUI from "hooks/ui.hook";
import styles from "assets/scss/pages/reservation/menuResume.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useMenuReservation } from "../../router_context";

const Resume = () => {
  const [ctx] = useMenuReservation();
  const [data, setData] = useState(null);

  useEffect(()=> {
    if(ctx.data !== null && ctx.selectedWeek){
      let _data = {};

      for(let i=0; i<7; i++){
        if(ctx.daysToDisplay.includes(i+1)){
          let _date = moment(ctx.selectedWeek).add(i,"days").format("YYYY-MM-DD");
          _data[_date] = ctx.data[_date];
        }
      }
      setData(_data);
    }
  },[ctx.data, ctx.selectedWeek, ctx.daysToDisplay]);

  return (
    <table className={styles.resume}>
      <ResumeTable data={data}/>
    </table>
  );
};

const getNumberReservations = (data, repas, category, dishId) => {
  let retour = 0;

  if(data.reservation){
    Object.values(data.reservation).forEach(_resaRepas => {
      if(_resaRepas[repas]){
        _resaRepas[repas].forEach(_resa => {
          if(_resa[category] === dishId) retour ++;
        });
      }
    }); 
  }
  
  return retour;
};

const ResumeTable = ({data}) => {
  const [ui] = useUI();
  const template = ui.establishment.template;
  const orderedRepas =  Object.keys(template).sort((a,b) => template[a].heure - template[b].heure);
  let docu = [];

  if(data){
    const keys = Object.keys(data);

    let header = [];
    header.push(<td></td>);
    for(let i=0; i<keys.length; i++){
      header.push(<td className={styles.headerDate}><p>{moment(keys[i]).format("ddd")}</p><p>{moment(keys[i]).format("DD")}</p></td>);
    }
    docu.push(<tr className={styles.headerLine}>{header}</tr>);

    orderedRepas.forEach(_repas => {
      let ligne = [];
      const _categories = template[_repas].categories;

      ligne.push(<td className={styles.repasTitle}>{_repas}</td>);

        Object.values(data).forEach(_dataDay => {
          if(_dataDay){
            const _dataRepas = _dataDay[_repas];
            let _infos = [];
            if(_dataRepas){
              _categories.forEach(_category => {
                try {
                  const orderedDishes = Object.entries(_dataRepas[_category]).sort((a,b) => template[_repas].menus[a[1].origin].weight - template[_repas].menus[b[1].origin].weight);
      
                  let empty = true;
                  orderedDishes.forEach(([_dishId, _dish]) => {
                    _infos.push(<div className={styles.dishContainer}>
                      <div  className={styles.dishName}>
                        {_dish.name} 
                      </div>
                      <div  className={styles.numberResas}>
                        {getNumberReservations(_dataDay, _repas, _category, _dishId)}
                      </div>
                      </div>);
                      empty = false;
                  });

                  if(!empty)
                    _infos.push(<div className={styles.separator}></div>);
                } catch (e) {
                  console.log(e, _category, _dataRepas[_category], _dataDay);
                }
              });
            } 

            _infos.pop(); // on retire le dernier séparateur


            ligne.push(<td>
              <div className={styles.dataContainer}>
                {_infos}
              </div>
              </td>);
          }else ligne.push(<td><div></div></td>);
        });
      docu.push(<tr>{ligne}</tr>);
    });
  }

  return docu;
};

Resume.propTypes = {
  _data: PropTypes.object.isRequired,
};

export default Resume;
