import React from "react";
import {Row as BootstrapRow} from "reactstrap";

const Row = (props) => (
  <BootstrapRow {...props}>
    {props.children}
  </BootstrapRow>
);

Row.propTypes = BootstrapRow.propTypes;
Row.defaultProps = BootstrapRow.defaultProps;

export default Row;