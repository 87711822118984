import {useContext, useEffect} from "react";
import UIContext from "../contexts/ui.context";
import firebase from "firebase";
import sleep from "../lib/helpers/asyncWait";
import axios from "axios";
import { getUserById } from "services/ressources/user";
import { getEstablishmentById } from "services/ressources/establishment";
import { getGroupementById } from "services/ressources/groupement";

const auth = firebase.auth();

const useAuth = () => {
  const [ui, UIReducer] = useContext(UIContext);

  //handle token creation and regeneration
  useEffect(() => {
    let intervalId = null;
    if (ui.user) {
      //regenerate token
      intervalId = setInterval(async () => {
        try {
          console.log("regenerating firebase<->backend token");
          axios.defaults.headers.common["Authorization"] = await auth.currentUser.getIdToken(true);
        } catch (e) {console.error("failed to regenerate token");}
      }, 1000 * 60 * 30);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [ui.user]);
  
  useEffect(() => {
    const subscribe = auth.onAuthStateChanged(async (_user) => {
      
      if (_user) {
        let userData = null;
        let establishmentData = null;
        let groupementData = null;
        let regionData = null;

        //get user data
        userData = await getUserById({id: firebase.auth().currentUser.uid});
        while (!userData) {//potentially wait custom data generated by cloud function onUserCreate
          await sleep(2000);
          userData = await getUserById({id: firebase.auth().currentUser.uid});
        }

        const token = await auth.currentUser.getIdToken(true);
        axios.defaults.headers.common["Authorization"] = token;

        if (userData.role === "superadmin" || userData.role === "senior") {
          UIReducer({type: "login", user: userData});
          return;
        }

        if (userData.establishment)
          establishmentData = await getEstablishmentById({id: userData.establishment});

        if(establishmentData && establishmentData.groupement){
          let groupementDoc = await getGroupementById({id: establishmentData.groupement});
          groupementData = {
            id: establishmentData.groupement, //keeped for old compatibility, need refacto
            ...groupementDoc
          };
        } else if (userData.groupement) {
          groupementData = await getGroupementById({id: userData.groupement});
        }

        if (groupementData && userData.region) {
          regionData = await Promise.all(groupementData.establishments.map(async (est) => {
            try {
              let estDoc = await getEstablishmentById({id: est});
              if (!estDoc) return (null);
              if (estDoc.region !== userData.region) return (null);
              return ({...estDoc});
            } catch (e) {
              return (null);
            }
          }));
          regionData = regionData.filter((est) => est !== null);
        }

        UIReducer({type: "login", user: userData, establishment: establishmentData, groupement : groupementData, region: regionData});
      } else {
        UIReducer({type: "endLoad"});
      }
    });
    return subscribe;
  }, []);

  //complete user data with groupement
  useEffect(() => {
    (async () => {
      if (!ui?.user?.establishment?.groupement) return;
      let res = await getGroupementById({id: ui?.user?.establishment?.groupement});
      UIReducer({type: "updateGroupement", groupement: res ?? null});
    })();
  }, [ui?.user?.establishment]);
};

export default useAuth;