import React from "react";
import axios from "axios";
import { Button } from "lib/components";

const Express = () => {

  const _get = async () => {
    let res = await axios.get("http://localhost:8080/healthcheck");
    console.log(res);
  };

  return (
    <>
      <Button onClick={_get}>GET</Button>
    </>
  );
};

export default Express;