import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import useUI from "hooks/ui.hook";
import illustration from "./assets/Image1.jpg";
import Logo from "./assets/Logo.png";
import Brandon from "fonts/brandon/BrandonGrotesque-Regular.ttf";
import BrandonBold from "fonts/brandon/BrandonGrotesque-Bold.ttf";

Font.register({
  family: "Brandon",
  fontWeight: "normal",
  src: Brandon,
});
Font.register({
  family: "Brandon",
  format: "bold",
  src: BrandonBold,
});


const styles = StyleSheet.create({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
  },
  illustration: {
    position: "absolute",
    right: 5,
    top: 5,
    width: 200, 
    height: 120,
    zIndex: 999
  },
  upContainer: {
    backgroundColor: "white",
    width: "100%",
    height: "20%",
    display: "flex",
    flexDirection: "row"
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "70%",
    marginLeft: "5%",
    justifyContent: "center",
  },
  titleText: {
    fontFamily: "Brandon",
    fontWeight: "bold",
    fontSize: 34,
    color: "#0d1c41",
  },
  dateText: {
    fontFamily: "Brandon",
    fontWeight: "bold",
    fontSize: 34,
    color: "#f06691",
  },
  styledBlock: {
    backgroundColor: "#ec871c",
    width: "30%",
    height: "100%",
  },
  downContainer: {
    backgroundColor: "#0d1c41",
    width: "100%",
    height: "80%",
    zIndex: 1,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  content: {
    marginTop: 10,
    width: "95%",
    height: "85%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  footer: {
    position: "absolute",
    width: "95%",
    height: 50,
    bottom: 10,
    display: "flex",
    flexDirection: "column",
  },
  textFooter: {
    fontFamily: "Brandon",
    fontSize: 18,
    color: "white",
  },
  logo: {
    position: "absolute",
    width: 150,
    height: 54,
    zIndex: 999,
    right: 5,
    bottom: 5
  },
  day: {
    display: "flex",
    flexDirection: "column",
    width: "14%",
  },
  dateContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  dayString: {
    fontFamily: "Brandon",
    fontWeight: "bold",
    fontSize: 18,
    color: "#f06691",
  },
  dayNumber: {
    fontFamily: "Brandon",
    fontWeight: "bold",
    fontSize: 20,
    color: "#ec871c",
  },
  eventBlockContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  eventPeriod: {
    width: "100%",
    display: "flex",
    height: "49%",
    // flexDirection: "column",
    backgroundColor: "white",
    justifyContent: "center",
    // alignItems: "center",
    // marginBottom: 3,
    // padding: "10px, 5px"
  },
  event: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  eventHour: {
    fontFamily: "Brandon",
    fontSize: 12,
    color: "#f06691",
  },
  eventTitle: {
    fontFamily: "Brandon",
    fontWeight: "bold",
    fontSize: 12,
    color: "#0d1c41",
    textAlign: "center"
  },
  eventDescription: {
    fontFamily: "Brandon",
    fontWeight:"normal",
    fontSize: 10,
    color: "#0d1c41",
    textAlign: "center",
    marginLeft:"2",
    marginRight:"2"
  },
  separator: {
    borderTop: "1px solid #ec871c",
    width: "100%",
    // position: "absolute",
    // top: "49.5%",
    paddingHorizontal: -2
  }
  
});

const WeekPlanningPdf = (props) => {
  const { ui, monday, events, logo, additionnalData } = props;
  const day = new Date(monday);

  const _getDays = () => {
    let _days = [];

    for (let i = 0; i < 7; i++) {
      let day = new Date(monday);
      day.setDate(day.getDate() + i);
      _days.push(day);
    }
    return _days;
  };
  const _getEventByDay = (day) => {
    let start = new Date(day);
    let end = new Date(day);

    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(1);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);

    return events.filter((event) => event.start > start && event.end < end);
  };
  const _hourToString = (date) => {
    return moment(date).format("HH:mm");
  };
  return (
    <Document>
      <Page size="A4" orientation="landscape">        
        <View style={styles.mainContainer}>
          
          <View style={styles.upContainer}>
            <View style={styles.titleContainer}>
              <Text style={styles.titleText}>Planning des animations</Text>
              <Text style={styles.dateText}>{moment(day).startOf("week").format("D")} au{" "}
                {moment(day).endOf("week").format("D MMMM YYYY")}</Text>
            </View>
            <View style={styles.styledBlock}>
              <Image src={illustration} style={styles.illustration} />
            </View>
          </View>
          <View style={styles.downContainer}>
            <View style={styles.content}>
              {_getDays().map((day) => (
                <View style={styles.day} key={day.toISOString()}>
                  { /* Header de date */}
                  <View style={styles.dateContainer}>
                    <Text style={styles.dayString}>{moment(day).format("dddd")}</Text>
                    <Text style={styles.dayNumber}>
                      {moment(day).format("D")}
                    </Text>
                  </View>
                  <View style={styles.eventBlockContainer}>
                    {/* Evenements du matin */}
                    <View style={styles.eventPeriod}>
                      {_getEventByDay(day).filter(_e => moment(_e?.start).hours() < 12).map((event, index) => (
                        index < 3 ?
                          <View style={styles.event} key={index}>
                            <Text style={styles.eventHour}>
                              {_hourToString(event.start)} -{" "}
                              {_hourToString(event.end)}
                            </Text>
                            <Text style={styles.eventTitle}>{event.title}</Text>
                           {event.extendedProps.description ? <Text style={styles.eventDescription}>{event.extendedProps.description}</Text> : null}
                          </View> : null
                      ))}
                    </View>
                    <View style={styles.separator}></View>
                    {/* Evenements de l'aprem */}
                    <View style={styles.eventPeriod}>
                      {_getEventByDay(day).filter(_e => moment(_e?.start).hours() >= 12).map((event, index) => (
                        index < 3 ?
                          <View style={styles.event} key={index}>
                            <Text style={styles.eventHour}>
                              {_hourToString(event.start)} -{" "}
                              {_hourToString(event.end)}
                            </Text>
                            <Text style={styles.eventTitle}>{event.title}</Text>
                            {event.extendedProps.description ? <Text style={styles.eventDescription}>{event.extendedProps.description}</Text> : null}
                          </View> : null
                      ))}
                    </View>
                  </View>
                </View>
              ))}
            </View>
            <View style={styles.footer}>
              <Text style={styles.textFooter}>Chez Heurus, le planning d’animations est coconstruit avec les résidents.</Text>
              <Text style={styles.textFooter}>Le Grand Salon et les extérieurs sont à votre disposition toute la journée.</Text>
            </View>
            <Image src={Logo} style={styles.logo} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

WeekPlanningPdf.propTypes = {
  monday: PropTypes.any.required,
  events: PropTypes.array.required,
  logo: PropTypes.string,
  additionnalData: PropTypes.string,
  ui: PropTypes.object.required,
};
export default WeekPlanningPdf;