import React, {useState} from "react";
import PropTypes from "prop-types";
import { NumberVariations, SimpleStatComponent} from "../components";
import useStats from "lib/Stats/statsContext";

import { faUsers, faUserPlus, faDiceFive, faUtensils, faImage, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "lib/components";

const infos = {
  users : {
    establishment: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre d'utilisateurs existant sur la période sélectionnée. Tout utilisateur créé après la période sélectionnée n'est pas décompté. \n"}</div>,
    groupement: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre total d'utilisateurs existant sur la période sélectionnée. Tout utilisateur créé après la période sélectionnée n'est pas décompté. \n"}
                      <b>{"2) Moyenne:"}</b> {" Indique le nombre moyen d'utilisateurs par résidence. \n"}
                      <b>{"3) Top 3:"}</b> {" Indique dans l'ordre décroissant les résidences possédant le plus d'utilisateurs."}</div>
  },
  usersNew : {
    establishment: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre de nouveaux utilisateurs créés sur la période sélectionnée. \n"}</div>,
    groupement: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre total de nouveaux utilisateurs créés sur la période sélectionnée. \n"}
                      <b>{"2) Moyenne:"}</b> {" Indique le nombre moyen de nouveaux utilisateurs par résidence sur la période sélectionnée. \n"}
                      <b>{"3) Top 3:"}</b> {" Indique dans l'ordre décroissant les résidences possédant le plus de nouveaux utilisateurs sur la période sélectionnée."}</div>
  },
  animations : {
    establishment: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre d'animations créées sur la période sélectionnée. \n"}</div>,
    groupement: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre total d'animations créées sur la période sélectionnée. \n"}
                      <b>{"2) Moyenne:"}</b> {" Indique le nombre moyen d'animations par résidence sur la période sélectionnée. \n"}
                      <b>{"3) Top 3:"}</b> {" Indique dans l'ordre décroissant les résidences possédant le plus d'animations sur la période sélectionnée."}</div>
  },
  animationsResas : {
    establishment: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre de participations à une animation sur la période sélectionnée. \n"}</div>,
    groupement: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre total de participations à une animation sur la période sélectionnée. \n"}
                      <b>{"2) Moyenne:"}</b> {" Indique le nombre moyen de participations à une animations par résidence sur la période sélectionnée. \n"}
                      <b>{"3) Top 3:"}</b> {" Indique dans l'ordre décroissant les résidences possédant le plus de participations à une animations sur la période sélectionnée."}</div>
  },
  menus : {
    establishment: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre de repas renseignés sur la période sélectionnée. \n"}</div>,
    groupement: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre total de repas renseignés sur la période sélectionnée. \n"}
                      <b>{"2) Moyenne:"}</b> {" Indique le nombre moyen de repas renseignés par résidence sur la période sélectionnée. \n"}
                      <b>{"3) Top 3:"}</b> {" Indique dans l'ordre décroissant les résidences possédant le plus de repas renseignés sur la période sélectionnée."}</div>
  },
  menusResas : {
    establishment: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre de repas réservés sur la période sélectionnée. \n"}</div>,
    groupement: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre de repas réservés sur la période sélectionnée. \n"}
                      <b>{"2) Moyenne:"}</b> {" Indique le nombre moyen de repas réservés par résidence sur la période sélectionnée. \n"}
                      <b>{"3) Top 3:"}</b> {" Indique dans l'ordre décroissant les résidences possédant le plus de repas réservés sur la période sélectionnée."}</div>
  },
  actus : {
    establishment: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre d'actualités créées sur la période sélectionnée. \n"}</div>,
    groupement: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre total d'actualités créées sur la période sélectionnée. \n"}
                      <b>{"2) Moyenne:"}</b> {" Indique le nombre moyen d'actualités créées par résidence sur la période sélectionnée. \n"}
                      <b>{"3) Top 3:"}</b> {" Indique dans l'ordre décroissant les résidences possédant le plus d'actualités créées sur la période sélectionnée."}</div>
  },
  gazette : {
    establishment: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre de gazettes créées sur la période sélectionnée. \n"}</div>,
    groupement: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre total de gazettes créées sur la période sélectionnée. \n"}
                      <b>{"2) Moyenne:"}</b> {" Indique le nombre moyen de gazettes créées par résidence sur la période sélectionnée. \n"}
                      <b>{"3) Top 3:"}</b> {" Indique dans l'ordre décroissant les résidences possédant le plus de gazettes créées sur la période sélectionnée."}</div>
  },
};
const Global = () => {
  const [ctx, dispatch, hasFeature] = useStats();
  const NumberComponent = SimpleStatComponent;//hasFeature("statistics") ? NumberVariations : SimpleStatComponent;

    if(!ctx || !ctx.data1 || !ctx.data2) return <Spinner/>;

    return (
    <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", flexWrap: "wrap", paddingBottom: 30}}>    
      <div style={{ padding: 15, flex: 1, display: "flex", flexDirection: "column", alignItems: "center", gap: 30, marginTop: 10}}>
        <NumberComponent path="users/count" title="Utilisateurs" icon={faUsers} iconBackgroundColor={"#F4C1E0"} info={ctx?.targetType === "establishment" ? infos.users.establishment : infos.users.groupement}/>
        <NumberComponent path="users/new" title="Nouveaux Utilisateurs" icon={faUserPlus} iconBackgroundColor={"#F4C1E0"} info={ctx?.targetType === "establishment" ? infos.usersNew.establishment : infos.usersNew.groupement}/>
        {hasFeature("animationPlanning") ? 
        <>
          <NumberComponent path="animations/count" title="Animations" icon={faDiceFive} iconBackgroundColor={"#B5E3EF"} info={ctx?.targetType === "establishment" ? infos.animations.establishment : infos.animations.groupement}/>
          
        </>
        : null}
        {hasFeature("menuPlanning") ? 
        <>
          <NumberComponent path="menus/count" title="Repas" icon={faUtensils} iconBackgroundColor={"#F4987A"} info={ctx?.targetType === "establishment" ? infos.menus.establishment : infos.menus.groupement}/>
        </>
        : null}
      {hasFeature("news") ? 
        <>
          <NumberComponent path="actus/count" title="Actualités" icon={faImage} iconBackgroundColor={"#B0DDAF"} info={ctx?.targetType === "establishment" ? infos.actus.establishment : infos.actus.groupement}/>
        </>
        : null}
        {hasFeature("gazette") ? 
        <>
          <NumberComponent path="gazette/count" title="Gazettes" icon={faFileAlt} iconBackgroundColor={"#C99FF2"} info={ctx?.targetType === "establishment" ? infos.gazette.establishment : infos.gazette.groupement}/>
        </>
        : null}
      </div>
      {hasFeature("reservationPlanning") || hasFeature("reservationMenu") ? 
        <div  style={{padding: 15,  flex: 1, display: "flex", flexDirection: "column", alignItems: "center", gap: 30}}>
          {hasFeature("animationPlanning") && hasFeature("reservationPlanning") ? 
            <>
              <NumberComponent path="animations/reservations" title="Réservations d'Animations" icon={faDiceFive} iconBackgroundColor={"#B5E3EF"} info={ctx?.targetType === "establishment" ? infos.animationsResas.establishment : infos.animationsResas.groupement} format={"noCard"}/>
            </> : null}
          {hasFeature("menuPlanning") && hasFeature("reservationMenu") ? 
            <>
              <NumberComponent path="menus/reservations" title="Réservations de Repas" icon={faUtensils} iconBackgroundColor={"#F4987A"} info={ctx?.targetType === "establishment" ? infos.menusResas.establishment : infos.menusResas.groupement} format={"noCard"}/>
            </> : null}
        </div>
      : null}
     
  
    </div>
  );
};


Global.propTypes = {
    establishment: PropTypes.string,
    data1: PropTypes.object,
    data2: PropTypes.object,
};

export default Global;
