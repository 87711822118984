import React, {Fragment} from "react";
import Tab from "./TabCore.js";
import PropTypes from "prop-types";

import styles from "../assets/scss/component/steps.module.scss";

const Step = ({label, subLabel, status, onClick}) => (
  <div className={`${styles.step} 
                   ${onClick && (status === "active" || status === "current") ? styles.clickable : ""}
                   ${status === "active" ? styles.active :
    status === "current" ? styles.current :
      status === "disabled" ? styles.disabled : styles.active}`}
  onClick={onClick}>
    <p className={styles.pill}>{label}</p>
    {subLabel && <p className={styles.subLabel}>{subLabel}</p>}
  </div>
);

const Path = ({
  status, //[empty|half|full]
}) => (
  <div className={`${styles.path}
                   ${status === "empty" ? styles.empty : 
    status === "half" ? styles.half :
      styles.full}`}></div>
);

const Steps = (props) => {
  let {activeTab, children, className, onClick, steps, value} = props;

  const stepClick = (id) => {
    if (id <= value + 0.5 && onClick)
      onClick(id);
  };

  if (typeof(steps) === "number") {
    let stepArray = [];
    for (let i = 0; i < steps; i++)
      stepArray.push(null);
    steps = stepArray;
  }

  return (
    <>
      <div className={`${styles.steps} 
                      ${steps.filter((i) => i).length > 0 ? styles.withLabel : ""}
                      ${className}`}>
        {steps.map((step, id) => (
          <Fragment key={id}>
            <Step label={id + 1}
              onClick={onClick && (() => {stepClick(id);})}
              subLabel={step}
              status={Math.ceil(value) === id ? "current" :
                id < value ? "active" : "disabled"}/>
            {id !== steps.length - 1 && <Path status={(id + 0.5).toString() === value.toString() ? "half" :
              id < value ? "full" :
                id === value ? "empty" :
                  "empty"} />}
          </Fragment>
        ))}
      </div>
      <Tab activeTab={activeTab}>
        {children}
      </Tab>
    </>
  );
};

Step.propTypes = {
  label : PropTypes.string,
  subLabel  : PropTypes.string,
  status : PropTypes.string,
  onClick   : PropTypes.func,
};
Path.propTypes = {
  status : PropTypes.string,
};
Steps.propTypes = {
  activeTab : PropTypes.number.isRequired,
  children  : PropTypes.element,
  className : PropTypes.string,
  onClick   : PropTypes.func,
  steps     : PropTypes.number.isRequired,
  value     : PropTypes.number.isRequired, //accepts .5 float
};

Steps.defaultProps = {
};

export default Steps;