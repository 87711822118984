import React, { useEffect, useState, useMemo } from "react";
import { useServiceReservation } from "../../routerContext";

import { useHistory, useParams } from "react-router-dom";
import { ArrowLeft, Plus, Check, Edit, ChevronDown } from "react-feather";
import { Container, Row, Button, Icon } from "lib/components";
import { ReservationModal, TableauUneDispo } from "../../routerComponents";

const Disponibility = (props) => {
    const [ctx, { updatePage, updateChosenService }] = useServiceReservation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [prestataireName, setPrestataireName] = useState("");
    const [availability, setAvailability] = useState(null);
    const [selectedResa, setSelectedResa] = useState("");
    const [place,setPlace] = useState("");

    const [preciseDate, setPreciseDate] = useState("");
    const nowTime = new Date();

    useEffect(() => {
        if (ctx.currentAvailibility && ctx.planning) {
            setAvailability(ctx.planning.find(_a => _a.uid === ctx.currentAvailibility));
        } else {
            setAvailability(null);
        }
    }, [ctx.currentAvailibility, ctx.planning]);

    const prestataireInfo = useMemo(() => {
        if (ctx?.prestataires && availability?.prestataireId) {
            setPlace(availability?.place);
            const presta = ctx.prestataires.find((resa) => resa.uid === availability.prestataireId);
            setPrestataireName(presta.name);
            return presta;
        }

        else return {};
    }, [availability, ctx.prestataires]);




    if (!availability) return <></>;
    return (
        <Container >
            <ArrowLeft color="#300438" size={42} onClick={() => { updatePage(0); }} />
            <div>
            <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", marginLeft: "auto", marginRight: " auto" }}>

                <h2>{" Réservations de "}
                    <span style={{ color: "#DF7189" }}>{prestataireName}</span>
                    {" le "}
                    <span style={{ color: "#DF7189" }}>{availability.start.toLocaleDateString("fr-FR", { weekday: "short", day: "2-digit", month: "2-digit" })}</span>
                    { " de "}
                    <span style={{ color: "#DF7189" }}>{availability.start.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" })}</span>
                    {" à "}
                    <span style={{ color: "#DF7189" }}>{availability.end.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" })}</span>
                </h2>
               
               
                <Row style={{ marginRight: "10px" }}>
                    <Button disabled={availability.end.getTime() <= nowTime.getTime()? true: false} style={{ width: "fit-content", display: "flex", alignItems: "center", gap: "5px", marginRight: "10px" }} onClick={() => { setPreciseDate(availability); setIsModalVisible(true); }}  >
                        <Icon icon={Plus} color="light" size="40" />

                    </Button>

                    <Button style={{ border: "solid 1px black", backgroundColor: "white", color: "black", borderRadius: "15px", display: "flex", flexDirection: "flex-row", alignItems: "center" }}>
                        <div>Exporter</div>
                        <ChevronDown />
                    </Button>
                </Row>
            </Row> 
            {place &&<h5>{"Lieu : "+place}</h5>}
            </div>
            <TableauUneDispo
                onModifyOpen={(resa) => { setSelectedResa(resa); setIsModalVisible(true); }}
                availability={availability}
            />
            <ReservationModal
                isOpen={isModalVisible}
                dataPresta={prestataireInfo}
                eventOpen={selectedResa}
                preciseDate={preciseDate}
                toggle={() => {
                    setIsModalVisible(false);
                    setSelectedResa(null);
                    setPreciseDate(null);
                }}
            >

            </ReservationModal>
        </Container>
    );
};

export default Disponibility;

