import {
    pdf,
    Document,
    Font,
    Image,
    Page,
    StyleSheet,
    Text,
    View,
  } from "@react-pdf/renderer";
  
  import PropTypes from "prop-types";
  import React from "react";
  import moment from "moment";


  const styles = StyleSheet.create({
      mainContainer: {
      margin: 6,
      //borderColor: "#D26F47",
      justifyContent: "space-around"
    },
    title: {
      fontSize: 22,
      textAlign: "center",
      //fontFamily: "TTNorms",
      color: "#430f53",
    },
    titleContainer: {
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: 10
    },
    weekContainer: {
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 10,
    },
    fromto: {
      //fontFamily: "TTNorms",
      fontSize: 15,
    },
    table: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      flexWrap: "wrap"
    },
    cell:{
        marginTop: 10,
        width:"22%",
        height: "42vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    cellHead: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    date: {
        fontWeight: "bold",
        textAlign: "center",
        color: "#430f53",
    },
    cellBody :{
      marginTop: 5,
      width: "100%",
      backgroundColor: "#cae7de",
      height: "98%",
      borderRadius: 8,
    },
    participations: {
        position: "relative",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    hours: {
        fontSize: 15,
        fontWeight: "bold",
        textAlign: "center",
    },
    eventTitle: {
        fontSize: 14,
        textAlign: "center",
        marginLeft: "5%",
        marginRight: "5%",
        width: "90%"
    }
  });

  const WeekPdf = (props) => {
    const { data, uid, name, week} = props;
    let _table = [];
    for(let days=0; days<7; days++){
        const _date = week.clone().add(days, "days");
        const participation = [];
        data.forEach(event => {
            if (event.start) {
                if (moment(event.start.seconds * 1000).isSame(_date, "day")) {
                  if(event?.reservation?.includes(uid)){
                    participation.push(
                      <View style={styles.participations}>
                        <Text style={styles.hours}>
                          {moment(event.start.seconds * 1000).format("HH:mm")} -{" "}
                          {moment(event.end.seconds * 1000).format("HH:mm")}
                        </Text>
                        <Text style={styles.eventTitle}>{event.title}</Text>
                      </View>
                    );
                  } 
                }
              }
        });


        _table.push(<View style={styles.cell}>
            <View style={styles.cellHead}>
                <Text style={[styles.date, {fontSize:20}]}>
                    {_date.format("ddd")}
                </Text>
                <Text style={[styles.date, {fontSize:24}]}>
                    {_date.format("DD")}
                </Text>
            </View>
            <View style={styles.cellBody}>{participation}</View>
        </View>);
    }

    return (
      <Document>
        <Page size="A4" orientation="landscape">
          <View style={styles.mainContainer}>
            <View style={styles.titleContainer}>
              <Text style={styles.title}>Réservations de {name}</Text>
            </View>
            <View style={styles.weekContainer}>
              <Text style={styles.fromto}>
                Semaine du {moment(week).startOf("week").format("DD MMMM YYYY")} au{" "}
                {moment(week).endOf("week").format("DD MMMM YYYY")}
              </Text>
            </View>
  
              <View style={styles.table}>
                  {_table}
              </View>
          </View>
        </Page>
      </Document>
    );
  };
  
  WeekPdf.propTypes = {
  data: PropTypes.object.isRequired,
  uid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  week: PropTypes.any.isRequired,
};

  export default WeekPdf;
  