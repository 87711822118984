import React from "react";
import { Provider } from "./router_context";
import { UserList } from "./router_pages";
const UserListIndex = (props) => {
  return (
    <Provider>
      <UserList {...props}/>
    </Provider>
  );
};

export default UserListIndex;