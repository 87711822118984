import React, {useState} from "react";
import { useParams } from "react-router-dom";
import {Provider} from "../Context/ctx";
import {Button, Tab} from "../../../../../lib/components";
import { useHistory } from "react-router-dom";
import {UserBook, Subscription, UserReservations} from "./User";
import { UserInfos } from "../../router_components";
import useFeature from "hooks/useFeature";
import useUI from "hooks/ui.hook";
import useCtx from "../Context/ctx";


const UserRouter = () => {
  const {state} = useCtx();
  const {user} = (state ?? {});
  const [ui] = useUI();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);
  
  const hasReservationMenu = useFeature("reservationMenu");
  const hasReservationPlanning = useFeature("reservationPlanning");
  const hasSubscription = ui?.establishment?.templateSubscription ? true : false; 
  const hasUserBook = useFeature("userBook");

  if(!user) return <></>;

  const pages = [
    {title: "Infos", content: <UserInfos />},
    // {title: "Suivi résident", content: <UserBook />},
  ];

  if(hasUserBook){
    pages.push({title: "Suivi résident", content: <UserBook />});
  }
  
  if(hasReservationMenu || hasReservationPlanning){
    pages.push({title: "Réservations", content: <UserReservations />});
  }


  if(hasReservationMenu && hasSubscription && ["senior","seniorTotem"].includes(user.role)){
    pages.push({title: "Abonnement / Menu", content: <Subscription />});
  }

  return (
      <div style={{width: "100%"}}>
        <div style={{display: "flex", flexDirection:"row", justifyContent: "flex-start", alignItems: "center"}}>
          <Button onClick={() => history.goBack()} style={{marginBottom: 10}}>retour</Button>
        </div>
        <Tab
          activeTab={activeTab}
          onClick={setActiveTab}
          render={pages}
        />
      </div>
  );
};

const UserHOC = () => {
  const {id} = useParams();
  return(
    <Provider id={id}>
      <UserRouter/>
    </Provider>
  );
};



export default UserHOC;