import EditorDisplay from "./EditorDisplay";
import EditorIcon from "./EditorIcon";
import EditorSidebar from "./EditorSidebar";

const TrueFalse = {
  slug: "multiple_choice",
  name: "Choix multiple",
  EditorDisplay,
  EditorIcon,
  EditorSidebar,
};

export default TrueFalse;