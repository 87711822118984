import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
    Document,
    Font,
    Image,
    Page,
    StyleSheet,
    Text,
    View,
} from "@react-pdf/renderer";

import moment from "moment";

import Arial from "fonts/arial/ARIAL.TTF";
import ArialBold from "fonts/arial/ARIALBD.TTF";

import BodoniBold from "fonts/bodoni/static/BodoniModa_9pt-Bold.ttf";

import { sortDishes, sortMenus, sortCategories } from "pages/Major/Menu/helpers/operations";
import OveliaFondSemaine from "./assets/OveliaFondWeHeader.png";
import Star from "./assets/star2.png";

Font.register({
    family: "Arial",
    fontWeight: "normal",
    src: Arial,
});
Font.register({
    family: "Arial",
    format: "bold",
    src: ArialBold,
});

Font.register({
    family: "Bodoni",
    format: "bold",
    src: BodoniBold,
});


// pdf size : 595 * 842

const styles = StyleSheet.create({

    globalContainer: {
        width: "100%",
        height: "100%",
        padding: 5,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 10,
    },

    container: {
        position: "relative",
        width: "24%",
        height: "49%",
        padding: 0,
    },

    backgroundContainer: {
        position: "absolute",
        width: "100%",
        height: "100%",
    },

    imageBackground: {
        width: "100%",
        height: "100%",
    },

    reducedContainer: {
        position: "absolute",
        marginLeft: 18,
        marginTop: 22,
        width: "81%",
        height: "85%",
    },

    reducedContent: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
    },

    header: {
        width: "92%",
        marginTop: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
    },

    pageTitle: {
        textAlign: "center",
        fontFamily: "Arial",
        fontSize: 25,
        color: "#192956",
    },

    dateTitle: {
        textAlign: "center",
        fontFamily: "Arial",
        color: "#192956",
    },

    headerMoreInfos: {
        textAlign: "center",
        fontFamily: "Arial",
        color: "#192956",
        fontSize: 11,
        width: "92%",
        height: "25%",
        whiteSpace: "break-spaces",
    },

    pageTitleBis: {
        textAlign: "center",
        fontFamily: "Arial",
        fontSize: 42,
        color: "#09526c",
    },

    content: {
        flex: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

    div: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: 10,
    },

    divHorizontal: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
    },

    dayTitle: {
        textAlign: "center",
        fontFamily: "Bodoni",
        fontWeight: "bold",
        fontSize: 11,
        color: "#c19e24",
        textTransform: "uppercase",
        padding: 0,
    },

    repasTitle: {
        marginTop: 10,
        textAlign: "center",
        fontFamily: "Arial",
        fontWeight: "bold",
        fontSize: 9,
        color: "#192956",
        padding: 0,
    },

    categTitle: {
        textAlign: "center",
        fontFamily: "Arial",
        fontWeight: "bold",
        fontSize: 24,
        color: "#09526c",
        textTransform: "uppercase",
        marginTop: 10,
    },

    categTitleBis: {
        textAlign: "center",
        fontFamily: "Arial",
        fontWeight: "bold",
        fontSize: 17,
        color: "#09526c",
        textTransform: "uppercase",
        marginTop: 7,
    },

    categDayTitle: {
        textAlign: "center",
        fontFamily: "Arial",
        fontWeight: "bold",
        fontSize: 18,
        color: "#09526c",
        textTransform: "uppercase",
        marginTop: 2,
    },

    horizontalSeparator: {
        width: "25%",
        borderBottomColor: "#09526c",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        marginTop: 16,
    },

    verticalSeparator: {
        marginTop: 8,
        marginBottom: 2,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: 2
    },

    horizontalSeparatorBis: {
        width: "60%",
        borderBottomWidth: 2,
        borderBottomStyle: "dotted",
        marginTop: 15,
        marginBottom: 5,
    },

    separatorStar: {
        height: 6,
    },

    bigSeparator: {
        width: 515,
        marginLeft: -2,
        borderBottomWidth: 3,
        borderBottomStyle: "solid",
        marginTop: 10,
        borderColor: "#09526c",
    },

    dish: {
        marginTop: 6,
        paddingHorizontal: 3,
        textAlign: "center",
        fontSize: 9,
        fontFamily: "Arial"
    },

    dishBis: {
        fontSize: 15,
        marginTop: 7,
        paddingHorizontal: 3,
        textAlign: "center"
    },

    cocotte: {
        position: "absolute",
        width: 45,
        left: 5,
        top: -15,
    }

});

function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}



export const OveliaWeekPDF = (props) => {
    const { week, menuState, logo, ui, currentRepas } = props;
    const template = ui.establishment.template;

    //   const getDishes = useCallback((repas, category, menu) => {
    //     let list = [];

    //     if (dataDay[repas] && dataDay[repas][category]) {
    //       sortDishes(Object.entries(dataDay[repas][category])).forEach(([uid, element]) => {
    //         if (menu) {
    //           if (element.origin === menu) list.push(element.name);
    //         } else {
    //           list.push(element.name);
    //         }
    //       });
    //     }
    //     return list;
    //   }, [day, dataDay]);

    const sortedRepas = Object.keys(template).sort((a, b) => template[a].heure - template[b].heure);
    const _dej = currentRepas ?? sortedRepas[0];
    //   const _din = sortedRepas[1];

    //   const _dejSortedMenus = sortMenus(template, _dej, Object.keys(template[_dej].menus));


    return (
        <Document>
            <Page size="A4" orientation="landscape">
                <View style={styles.globalContainer}>
                    <View style={styles.container}>
                        <View style={styles.backgroundContainer}>
                            <Image src={OveliaFondSemaine} style={styles.imageBackground}></Image>
                        </View>

                        <View style={styles.reducedContainer}>
                            <View style={styles.reducedContent}>
                                <View style={styles.header}>
                                    <Text style={styles.pageTitle}>
                                        {_dej} de la semaine
                                    </Text>
                                </View>

                                <View style={[styles.content, { justifyContent: "center" }]}>
                                    <Text style={styles.dateTitle}>{capitalizeFirstLetter(moment(week[0]).format("DD MMMM YYYY"))}</Text>
                                    <Text style={styles.dateTitle}>-</Text>
                                    <Text style={styles.dateTitle}>{capitalizeFirstLetter(moment(week[week.length - 1]).format("DD MMMM YYYY"))}</Text>

                                </View>

                                {_dej === sortedRepas[0] &&
                                    <Text style={styles.headerMoreInfos}>Pour les diners, ils sont consultables sur la borne Major ou à l'accueil.</Text>
                                }

                            </View>
                        </View>




                    </View>


                    {week.map((_day) => {
                        const formatedDay = moment(_day).format("YYYY-MM-DD");
                        const _categories = sortCategories(template, _dej, template[_dej].categories);
                        const _menus = sortMenus(template, _dej, Object.keys(template[_dej].menus))
                        return (
                            <View key={formatedDay} style={[styles.container, { borderColor: "#192956", borderWidth: 1 }]}>

                                <View style={[styles.content, { justifyContent: "space-between", paddingBottom: 10 }]}>
                                    <Text style={styles.dayTitle}>{moment(_day).format("dddd").toUpperCase()}</Text>
                                    <Text style={styles.repasTitle}>{_dej}</Text>

                                    {_categories.map(_c => (
                                        <>
                                            {menuState && menuState[formatedDay] && menuState[formatedDay][_dej] && menuState[formatedDay][_dej][_c] ?
                                                sortDishes(Object.entries(menuState[formatedDay][_dej][_c])).map(([uid, element]) => (
                                                    <Text style={[styles.dish, { fontWeight: element.origin === _menus[0] ? "bold" : "normal" }]} key={uid}>{element.name}</Text>
                                                ))
                                                : null}

                                            {_c !== _categories[_categories.length - 1] && Object.entries(menuState[formatedDay][_dej][_c]).length > 0 ?
                                                <View style={styles.verticalSeparator}>
                                                    {(!template[_dej].links || (template[_dej].links && _c !== template[_dej].links.split(",")[0])) && [...Array(5)].map((x, i) => (<Image key={i} src={Star} style={styles.separatorStar}></Image>))}
                                                </View>
                                                : null}
                                        </>
                                    ))}
                                </View>


                            </View>
                        );

                    })}

                </View>

            </Page>
        </Document>
    );
};


OveliaWeekPDF.propTypes = {
    week: PropTypes.array.isRequired,
    menuState: PropTypes.object.isRequired,
    logo: PropTypes.string,
    ui: PropTypes.any,
};
export default OveliaWeekPDF;
