import React from "react";
import {MenuProvider} from "./router_context";
import { MenuIndex } from "./router_pages";

const Menu = () => {
  return (
    <MenuProvider>
      <MenuIndex />
    </MenuProvider>
  );
};

export default Menu;