import React from "react";
import PropTypes from "prop-types";
import styles from "../../../../../assets/scss/cultureQuizzQuestionTypes/trueFalse.module.scss";

const TrueFalse = ({question}) => {
  return (
    <div className={styles.container}>
      <h2>Vrai ou faux ?</h2>
      {question.media ? 
        <img src={question.media} className={styles.media} />
        : null}
      <h4>{question.title}</h4>
      <div className={styles.answers}>
        <span className={`${styles.answer} ${question.answer === true ? styles.isCorrect : ""}`}>VRAI</span>
        <span className={`${styles.answer} ${question.answer === false ? styles.isCorrect : ""}`}>FAUX</span>
      </div>
    </div>
  );
};

TrueFalse.propTypes = {
  question: PropTypes.object.isRequired,
};

export default TrueFalse;