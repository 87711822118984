import React from "react";

import { Provider as ProviderService } from "../serviceCtx";
import { ServiceManagementProvider } from "./routerContext";
import { ServiceManagementIndex } from "./routerPages";

const ServiceManagement = (props) => {
	return (
		<ProviderService>
			<ServiceManagementProvider>
				<ServiceManagementIndex {...props} />
			</ServiceManagementProvider>
		</ProviderService>
	);
};

export default ServiceManagement;