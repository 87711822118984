import React, { useEffect, useState } from "react";
import {
    Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Table, Card, CardHeader, CardBody, Input, FormGroup
} from "lib/components";
import { Edit, PlusCircle, List, BarChart } from "react-feather";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { getPatchnoteById, updatePatchnoteById } from "services/ressources/patchnotes";
import { Provider } from "../context";

import { BlocEdit, BlocView } from "../components/bloc";

const PatchnoteEdit = () => {
    const history = useHistory();
    const param = useParams();
    const [patchnote, setPatchnote] = useState({});
    const [previsu, setPrevisu] = useState(false);

    useEffect(() => {
        if (param.uid) {
            const callAsync =  async () => {
                const _patchnote = await getPatchnoteById({id: param.uid});
                console.log(_patchnote);

                setPatchnote(_patchnote);
            };

            callAsync();
        }
    }, [param]);

    const _save = () => {
        updatePatchnoteById({id: param.uid, data: patchnote});
    };

    return (
        <Provider>

        <div style={{ flex: 1 }}>
            <div style={{display: "flex", gap: 20}}>
                <Button onClick={() => history.goBack()} >Retour</Button>
                <Button onClick={_save} >Sauvegarder</Button>
                <Button onClick={() => setPrevisu(!previsu)} >{previsu ? "Retour à l'édition" : "Prévisualisation"}</Button>
            </div>

            {patchnote ?
                <div>
                    {patchnote?.content ?  
                        !previsu ? 
                        <BlocEdit blockData={patchnote.content} parentRestrictions={[]} childIndex={1}/>
                        :
                        <BlocView blockData={patchnote.content} parentRestrictions={[]} childIndex={1}/>
                    : null}
                    
                </div>
                : null}




        </div>
        </Provider>
    );
};

export default PatchnoteEdit;


// ajouter les useFeature
// ajouter les specifique (groupement)
// ajouter les specifique (etablissement)
// ajouter les rôles

// Bloc, avec un titre
// Titre (avec glossaire h1-h6)
// Description
// Images (avec description)
// Images comparaison (avec description)

// chaque bloc peut avoir d'autres blocs.