import React, { useEffect, useState, useRef } from "react";
import H from "@here/maps-api-for-javascript";
import PropTypes from "prop-types";
import mapHandler from "../../hooks/mapHandler";

const MapComponent = ({ style, startLat, startLng, startZoom }) => {
    const divRef = useRef(null);
    const {init, changeView, cleanup} = mapHandler();


    const handleMapViewChange = (ev) => {
        if (ev.newValue && ev.newValue.lookAt) {
            const lookAt = ev.newValue.lookAt;

            // adjust precision
            const _lat = Math.trunc(lookAt.position.lat * 1E7) / 1E7;
            const _lng = Math.trunc(lookAt.position.lng * 1E7) / 1E7;
            const _zoom = Math.trunc(lookAt.zoom * 1E2) / 1E2;

            changeView(_lat, _lng, _zoom);
        }
    };

    useEffect(() => {
        if (divRef.current) {
            // instantiate a platform, default layers and a map as usual
            const _platform = new H.service.Platform({
                apikey: "PuhsCEcdotr4BloGgLhM59paeP_EHW49bZ0MSzT-Iaw"
            });

            const _service = _platform.getSearchService();


            const layers = _platform.createDefaultLayers();

            const map = new H.Map(
                divRef.current,
                layers.vector.normal.map,
                {
                    pixelRatio: window.devicePixelRatio,
                    center: { lat: startLat, lng: startLng },
                    zoom: startZoom,
                    //onMapViewChange: {handleMapViewChange}
                },
            );


            // attach the listeners
            map.addEventListener("mapviewchange", handleMapViewChange);

            window.addEventListener("resize", function () {
                map.getViewPort().resize();
            });

            // add the interactive behaviour to the map
            new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

            const _mapUi = H.ui.UI.createDefault(map, layers);

            init(map, _platform, _service, _mapUi);

        }

        return () => {
            cleanup();
        };
    }, [divRef]);

    return (
        <div
            style={style}
            ref={divRef}
        >
        </div>
    );
};


MapComponent.defaultProps = {
    startLat: 47,
    startLng: 5,
    startZoom: 6,
};

MapComponent.propTypes = {
    startLat: PropTypes.number,
    startLng: PropTypes.number,
    startZoom: PropTypes.number,
    style: PropTypes.any,
};

export default MapComponent;