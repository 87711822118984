import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, ImagePicker, Checkbox} from "../../../../lib/components";
import {toast} from "react-toastify";
import firebase from "firebase";
import useUI from "../../../../hooks/ui.hook";
import useGoogleAnalytics from "../../../../hooks/useGoogleAnalytics";
import { updateActu, createActu, removeActu } from "services/ressources/actus";
import useMicroService from "helpers/microService";
import { getUsersByEtab } from "services/ressources/user";

const firestore = firebase.firestore;

const CreateEditNews = (props) => {
  const {
    isOpen,
    toggle,
    item,
    preFilledData,
  } = props;

  const ga = useGoogleAnalytics();
  const ms = useMicroService();
  const [ui] = useUI();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState([]);
  const [galery, setGalery] = useState([]);
  const [imageLoaderForm, setImageLoaderForm] = useState(false);
  const [editing, setEditing] = useState(null);
  const [error,setError] =useState({});
  const [loading,setLoading] = useState(false);
  // const [sendNotif, setSendNotif] = useState(false);
  
  useEffect(() => {
    if (editing === null) return;
    setEditing(true);
  }, [title, description, image, galery, imageLoaderForm]);

  useEffect(() => {
    if (!item && !preFilledData) {
      setTitle("");
      setDescription("");
      setImage([]);
      setError({});
      setGalery([]);
    } else if (preFilledData) {
      setTitle(preFilledData.title ?? "");
      setDescription(preFilledData.description ?? "");
      setImage([]);
      setGalery([]);
    } else {
      setTitle(item.title);
      setDescription(item.description);
      setImage(item.image ? [item.image] : []);
      setGalery(item.galery ?? []);
      setError({});
    }
    // setSendNotif(false);
    //initialized, waiting for changes, 1s timeout for avoid useEffect preshot
    setTimeout(() => setEditing(false), 1000);
  }, [item, isOpen]);

  const onSubmit = async () => {
    setLoading(true);
    let _error={};
    if (!title) { _error.title = "Ce champ est obligatoire";}
     setError(_error);
    if (Object.keys(_error).length > 0) {
      toast.warning("Il vous manque un titre");
      setLoading(false);
      return ;
    }
    try {
      if (!item) {
        await createActu({ui, data: {
          title,
          description,
          image: image.length ? image[0] : null,
          galery,
          date: new Date(),
          updatedAt: new Date(),
        }});
        
          //notif pour les users
          let users = await getUsersByEtab({etabId: ui.user.establishment}, () => {throw new Error("Une erreur est survenue");});
          users = users.filter(u => u.role === "senior");
          users = users.map(u => u.uid);

          await ms("sendPushToUserById", {
            userId: users,
            title: "Nouvelle actualité publiée",
            body: `${title}. Rendez-vous sur la page actualité!`,
          });
      } else {
        await updateActu({ui, id: item.uid, data: {
          title,
          description,
          image: image.length ? image[0] : null,
          galery,
          updatedAt: new Date()
        }});
      }
      ga("news_edit");
      setLoading(false);
      toggle();
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  const remove = async () => {
    try {
      if (!window.confirm("Êtes-vous sûr de vouloir supprimer cet article ?")) return;
      await removeActu({ui, id: item.uid});
      toggle();
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  const close = () => {
    if (!editing) return toggle();
    if (window.confirm("Êtes-vous sûr de vouloir quitter sans sauvegarder ?")) {
      toggle();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={close} size="lg">
      <ModalHeader>{item ? item.title : "Nouvel article"}</ModalHeader>
      <ModalBody>
        <Input type="text" label="Titre" value={title} invalid={error.title} onChange={e => setTitle(e.target.value)} disabled={item?.type!= undefined}/>
        <Input type="textarea" label="Contenu" value={description} onChange={e => setDescription(e.target.value)} disabled={item?.type!= undefined}/>
        <ImagePicker value={image} onChange={setImage} label="Image mise en avant" max={1} setImageLoaderForm={setImageLoaderForm} disabled={item?.type!= undefined}/>
        <ImagePicker value={galery} onChange={setGalery} label="Galerie" max={15} setImageLoaderForm={setImageLoaderForm} disabled={item?.type!= undefined}/>
        {/* {!item ? <Checkbox label="Envoyer une notification" checked={sendNotif} onChange={() => setSendNotif(!sendNotif)} disabled={item?.type!= undefined}/> : null} */}
      </ModalBody>
      <ModalFooter>
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", gap: 10}}>
          {!item ? <>
            <Button color="secondary" onClick={close}>Retour</Button>
            <Button color="primary" onClick={onSubmit} disabled={imageLoaderForm || loading} loading={loading}>Enregistrer</Button>
          </> : <>
            <Button color="secondary" onClick={close}>Retour</Button>
            {!item.type ?
            <>
              <Button color="danger" onClick={remove}>Supprimer</Button>
              <Button color="primary" onClick={onSubmit} disabled={imageLoaderForm || loading} loading={loading}>Mettre à jour</Button>
            </>
             : null}
          </>
          }
        </div>
      </ModalFooter>
    </Modal>
  );
};

CreateEditNews.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  preFilledData: PropTypes.object,
};

export default CreateEditNews;