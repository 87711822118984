import React, {useState, useEffect} from "react";
import { Spinner } from "../../../lib/components";
import moment from "moment";
import useAnimationEvents from "../../../resources/animations";

import image from "../../../assets/images/home/activity.png";
import styles from "../../../assets/scss/components/homeWidgets/todayActivities.module.scss";

const TodayActivities = () => {
  const [activities, setActivities] = useState([]);
  const {events} = useAnimationEvents();

  useEffect(() => {
    if (events === null) return;
    let today = moment().format("YYYY-MM-DD");
    let data = events.filter(i => moment(i.start.toDate()).format("YYYY-MM-DD") === today);
    setActivities(data);
  }, [events]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h5 style={{fontWeight:600, fontSize:18, textAlign: "center"}}>les activités du jour</h5>
      </div>
      <div className={styles.body}>
        <img src={image} />
        <h5>Activités du jour</h5>
        {activities === null ? 
          <Spinner />
          :
          activities.length === 0 ?
            // eslint-disable-next-line react/no-unescaped-entities
            <p>Aucune activité aujourd'hui</p>
            :<div className={styles.activitiesContainer}>
              {activities.map(i => 
                <div key={i.uid} className={styles.activity}>
                  <span className={styles.start}>{moment(i.start.toDate()).format("HH:mm")}</span>
                  <span className={styles.title}>{i.title}</span>
                </div>
              )}
            </div>}
      </div>
    </div>
  );
};

export default TodayActivities;