import React from "react";
import PropTypes from "prop-types";
import {Table as BootstrapTable} from "reactstrap";

const Table = (props) => {
  const {children} = props;

  return (
    <BootstrapTable {...props} responsive>
      {children}
    </BootstrapTable>
  );
};
Table.propTypes = {
  children  : PropTypes.any,
};
export default Table;