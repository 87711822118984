import React, { useEffect, useState } from "react";
import { useServiceManagement } from "../../routerContext";
import { Tab, Button, Modal, ModalHeader,Container,Icon } from "lib/components";
import { Plus} from "react-feather";
import { Planning, ListePrestataire, CreationPrestataire,CreationPrestation } from "../../routerPages";
import { ChooseCategorieModal} from "../../routerComponents";


const ServiceManagementIndex = (props) => {
    const [ctx, { updatePage,updateChosenService }] = useServiceManagement();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [forceRenderCalendar, setForceRenderCalendar] = useState(false);

    useEffect(() => {
      if (forceRenderCalendar) {
        setTimeout(() => {
          setForceRenderCalendar(false);
        }, 200);
      }
    }, [forceRenderCalendar]);

    return (
        <Container>
            {ctx.page < 2 ? //redirection de la page à afficher
                <>
                    <Button  style={{ width: "fit-content",display: "flex",gap: "5px",marginLeft: "auto",}} onClick={() => setIsModalVisible(true)} >
                        <div>Nouveau prestataire </div> 
                        <Icon icon={Plus} color="light" size="24"/>

                    </Button>
                    <Tab activeTab={ctx.page} onClick={(e) => {updatePage(e);setForceRenderCalendar(true);}} render={[
                            { title: "Planning", content: <Planning forceCalendar={forceRenderCalendar} /> },
                            // { title: "Liste des prestataires", content: <ListePrestataire />, }
                    ]}>
                    </Tab>
                </>
                : ctx.page==2 ? 
                <CreationPrestataire/>
                :<CreationPrestation/>
            }
            <ChooseCategorieModal isOpen={isModalVisible} setIsModalVisible={setIsModalVisible} toggle={()=>{setIsModalVisible(false);}}/>
        </Container>
    );
};

export default ServiceManagementIndex;