import React from "react";
import PropTypes from "prop-types";
import {Input} from "lib/components";
import { FormGroup } from "reactstrap";
import {Text, View, StyleSheet, Font} from "@react-pdf/renderer";

import styles from "assets/scss/pages/Gazette/contentTypes/text.module.scss";

import "../fonts";

const TextComponent = (props) => {
  const {title, content, format} = props;
  
  return (
    <div className={`${styles.editorView} ${styles[format]}`}>
      <h3 style={{textAlign: "center"}}>{title}</h3>
      <p>{content}</p>
    </div>
  );
};

const TextEditor = (props) => {
  const {
    onChange,
    field,
  } = props;

  return (
    <>
      <FormGroup>
        <Input value={field.title} onChange={e => onChange("title", e.target.value)} placeholder="Titre..." label="Titre" />
      </FormGroup>
      <FormGroup>
        <Input type="textarea" value={field.content} onChange={e => onChange("content", e.target.value)} placeholder="Contenu..." label="Contenu" />
      </FormGroup>
      {/*<div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: 10}}>
        <Button color="secondary" onClick={onCancel}>Annuler</Button>
        <Button color="primary" onClick={onSubmit}>Valider</Button>
      </div>*/}
    </>
  );
};

const TextPdf = (props) => {
  const {
    settings,
    title,
    content,
    ui,
  } = props;
  let pdfStyle = StyleSheet.create({
    container: {
      height: "100%",
      borderWidth: 3,
      borderColor: "lightgrey",
      padding: 10,
      borderRadius: 5
    },
    title: {
      fontFamily: settings?.fontFamily ?? "Roboto",
      fontWeight: "bold",
      textAlign: "center",
      fontSize: settings?.fontSize2 ?? 22,
      marginBottom: 20,
      marginTop: 10,
    },
    content: {
      fontSize: settings?.fontSize3 ?? 15,
      fontFamily: settings?.fontFamily ?? "Roboto",
    }
  });


  if(ui?.groupement?.specific === "heurus") {
    pdfStyle = {
      ...pdfStyle,
      container: {
        ...pdfStyle.container,
        borderColor: "#e76d90",
      },
      title: {
        ...pdfStyle.title,
        fontFamily: "Brandon",
        color: "#1c2140",
      },
      content: {
        ...pdfStyle.content,
        fontFamily: "Brandon",
        color: "#1c2140",
      }
    };
  }
  
  return (
    <View style={pdfStyle.container}>
      <Text style={pdfStyle.title}>{title}</Text>
      <Text style={pdfStyle.content}>{content}</Text>
    </View>
  );
};


//TODO Definir les proptypes 

TextComponent.propTypes = {
  title : PropTypes.any,
  content : PropTypes.any,
  format : PropTypes.any,
};

TextEditor.propTypes = {
  onChange : PropTypes.any,
  field : PropTypes.any,
};

TextPdf.propTypes = {
  settings: PropTypes.any,
  title : PropTypes.any,
  content : PropTypes.any,
  ui: PropTypes.any,
};

export { TextComponent, TextEditor, TextPdf };