import React, { useMemo, useCallback, useState, useEffect } from "react";
import moment from "moment";
import styles from "../../../../../assets/scss/pages/User/subscriptionComponent.module.scss";
import firebase from "firebase";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Select, Checkbox } from "lib/components";
import { isDayEmpty, getDefaultRepas, getDefaultDay, isRepasEmpty, areReservationsEqual, sortRepas } from "../../../Menu/helpers/operations";
import { Modal, ModalBody, ModalHeader } from "lib/components";
import { Button, ModalFooter } from "../../../../../lib/components";

const firestore = firebase.firestore;

const subscriptionDates = [moment().format("YYYY-MM"), moment().add(1, "month").format("YYYY-MM"), moment().add(2, "month").format("YYYY-MM")];



const SubscriptionComponent = ({ user, establishment }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [subscriptionDays, setSubscriptionDays] = useState([]);

  // const months = moment.months();

  const template = establishment?.template;
  const templateSubscription = establishment?.templateSubscription;
  const currentSubscription = user?.subscription ? templateSubscription[user?.subscription] : null;
  const possibleRepas = currentSubscription?.repas ? sortRepas(template, Object.keys(currentSubscription?.repas)) : null;

  useEffect(() => {
    // On passe au nouveau système de gestion des portages d'abonnements
    if (user?.subscriptionHomeDelivery === true && possibleRepas?.length > 0) {
      const _data = {};

      possibleRepas.forEach(element => {
        _data[element] = true;
      });
      firestore().collection("users").doc(user.uid).update({
        subscriptionHomeDelivery: _data
      });
    }
  }, [user?.subscriptionHomeDelivery, possibleRepas]);


  const openDaysModal = () => {
    if(user?.subscriptionDays){
      setSubscriptionDays([...user?.subscriptionDays]);
    }else{
      setSubscriptionDays([1,2,3,4,5,6,7]);
    }
    setIsOpen2(true);
  };
 
  const onClickMonth = async (_date) => {
    let _subscriptionMonths = [...user.subscriptionMonths ?? []];

    if (_subscriptionMonths.includes(_date)) {
      _subscriptionMonths = _subscriptionMonths.filter(date => date != _date);
    } else {
      _subscriptionMonths.push(_date);
    }

    await firestore().collection("users").doc(user.uid).update({
      subscriptionMonths: _subscriptionMonths
    });

    // toast.success("modification de l'abonnement réussie");
  };

  const onChangeSubscription = async (value) => {

    if (user.subscription !== value) {

      await firestore().collection("users").doc(user.uid).update({
        subscription: value
      });

      // toast.success("modification de l'abonnement réussie");
    }

  };

  const handlePortageClick = () => {
    if (possibleRepas) {
      if (possibleRepas.length > 1) {
        setIsOpen(true);
      } else {
        onChangePortage(possibleRepas[0], user?.subscriptionHomeDelivery && user?.subscriptionHomeDelivery[possibleRepas[0]] ? !user.subscriptionHomeDelivery[possibleRepas[0]] : true);
      }
    }

  };

  const onChangePortage = async (repas, value) => {
    if (user?.subscriptionHomeDelivery && user?.subscriptionHomeDelivery[repas] ? user?.subscriptionHomeDelivery[repas] !== value : true) {
      await firestore().collection("users").doc(user.uid).update({
        [`subscriptionHomeDelivery.${repas}`]: value
      });
      // toast.success("modification de l'abonnement réussie");
    }

  };

  const onChangeDays = (_day) => {
    if(subscriptionDays.includes(_day)){
      setSubscriptionDays(subscriptionDays.filter((a) => a !== _day));
    }else{
      setSubscriptionDays([...subscriptionDays, _day].sort((a,b) => a - b));
    }
  };

  const onValidateDays = async () => {
      await firestore().collection("users").doc(user.uid).update({
        subscriptionDays: subscriptionDays ?? []
      });
      setIsOpen2(false);
  };


  return (
    <div>
      {user.role != "owner" ?
        <div className={styles.subscriptionContainer}>
          <div className={styles.subscriptionTitle}>
            <div className={styles.selector}>

              <Select
                placeholder="Aucun abonnement"
                options={Object.keys(templateSubscription).map(sub => ({ value: sub, label: sub }))}
                value={user.subscription ? { value: user.subscription, label: user.subscription } : null}
                onChange={e => onChangeSubscription(e?.value ?? null)/*handleChange("subscription", e?.value ?? null)*/}
                clearable
                className={styles.select}
                containerStyle={{ display: "flex", flex: 1 }}
              />
              {user.subscription && <Button style={{ width: "15%", marginLeft: "1%", marginRight: "1%", fontSize: 18, padding: 4 }} onClick={openDaysModal}>⚙</Button>}
            </div>
          </div>
          <div>

          </div>
          {user.subscription ?
            <div className={styles.subSubscriptionContainer}>
              <div className={styles.subscriptionDatesContainer}>
                {subscriptionDates.map((d, id) => (
                  <div key={id}
                    className={`${styles.subscriptionDate} ${user.subscriptionMonths && user.subscriptionMonths.includes(d) ? styles.selected : ""}`}
                    onClick={() => onClickMonth(d)}>
                    <div>
                      {moment(d).format("MMM")}
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.checkboxContainer}>
                <h6>
                  Portage
                </h6>
                <Checkbox
                  checked={user?.subscriptionHomeDelivery ? Object.entries(user?.subscriptionHomeDelivery).some(([_repas, _val]) => possibleRepas?.includes(_repas) && _val === true) : false}
                  onChange={handlePortageClick}
                  className={styles.checkbox}
                />
              </div>
              <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
                <ModalHeader>
                  {"Gestion des portages de l'abonnement"}
                </ModalHeader>
                <ModalBody>
                  {possibleRepas && possibleRepas.map(_repas => (
                    <div key={_repas} className={styles.modalCheckBoxContainer}>
                      <div>
                        Portage {_repas}
                      </div>
                      <Checkbox
                        checked={user?.subscriptionHomeDelivery && user?.subscriptionHomeDelivery[_repas]}
                        onChange={() => onChangePortage(_repas, user?.subscriptionHomeDelivery ? !user?.subscriptionHomeDelivery[_repas] : true)}
                        className={styles.checkbox}
                      />
                    </div>

                  ))}

                </ModalBody>

              </Modal>

              <Modal isOpen={isOpen2} toggle={() => setIsOpen2(false)}>
                <ModalHeader>
                  {"Gestion des jours inclus dans l'abonnement"}
                </ModalHeader>
                <ModalBody>
                  {[1, 2, 3, 4, 5, 6, 7].map((_day) => (
                    <div key={_day} className={styles.modalCheckBoxContainer}>
                      <div>
                        {moment().isoWeekday(_day).format("dddd")}
                      </div>
                      <Checkbox
                        checked={subscriptionDays && subscriptionDays.includes(_day)}
                        onChange={() => onChangeDays(_day)}
                        className={styles.checkbox}
                      />
                    </div>
                  ))}
                </ModalBody>
                <ModalFooter>
                  <Button onClick={() => setIsOpen2(false)}>
                    Annuler
                  </Button>
                  <Button onClick={onValidateDays}>
                    Valider
                  </Button>
                </ModalFooter>

              </Modal>
            </div>
            : null}

        </div>
        : null}
    </div>
  );
};

SubscriptionComponent.propTypes = {
  user: PropTypes.object.isRequired,
  establishment: PropTypes.object.isRequired,
};


export default SubscriptionComponent;
