import React, { useEffect, useState } from "react";
import {
    Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Table, Card, CardHeader, CardBody, Input, FormGroup
} from "lib/components";
import { Edit, Plus, List, BarChart, PlusCircle, Trash2, ChevronRight, ChevronDown } from "react-feather";
import PropTypes from "prop-types";
import Restrictions, {RestrictionDisplay} from "./restrictions";
import { TextEdit, TextView } from "./text";
import { ImageEdit, ImageView } from "./image";
import { ImageCompareEdit, ImageCompareView } from "./imageCompare";
import { ListEdit, ListView } from "./list";

import styles from "../scss/bloc.module.scss";

const composants = [
    { type: "block", title: "bloc (avec titre)" },
    { type: "text", title: "texte" },
    { type: "list", title: "liste" },
    { type: "image", title: "image" },
    { type: "imageDiffential", title: "comparaison d'images (avant, après)" }];
/*
{
    type: "block",
    restrictions: [{
        type: "" // application, feature, role, groupement, establishment,
        value ""
    }],
    content: [
        {}
    ],
    title : ""
}

*/

export const BlocEdit = ({ blockData, parentRestrictions, childIndex, remove }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenSetup, setIsOpenSetup] = useState(false);
    const [title, setTitle] = useState("");
    const [restrictions, setRestrictions] = useState([]);
    const [refresh, setRefresh] = useState(true);
    const [isContentOpen, setIsContentOpen] = useState(true);

    useEffect(() => {
        if (blockData && !blockData.title) {
            setIsOpenSetup(true);
        } else if (blockData && blockData.title) {
            setTitle(blockData.title ?? "");
            setRestrictions(blockData.restrictions ?? []);
        }
    }, [blockData]);

    const _setup = () => {
        if (title) {
            blockData.title = title;
            blockData.restrictions = restrictions;
            setIsOpenSetup(false);
        }
    };

    const _addComposant = (composant) => {
        if (!blockData.content) blockData.content = [];

        blockData.content.push({
            type: composant,
            restriction: null,
        });

        setIsOpen(false);
    };

    const _remove = (_indx) => {
        blockData?.content.splice(_indx, 1);
        setRefresh(!refresh);
    };


    return (
        <div style={{ width: "100%", padding: 20, borderLeft: "solid", borderWidth: 1, borderColor: "gray" }}>

            <div className={styles.titleComponent}>
                {isContentOpen ?
                    <ChevronDown size={25} onClick={() => setIsContentOpen(false)} style={{cursor: "pointer"}}/>
                    :
                    <ChevronRight size={25} onClick={() => setIsContentOpen(true)} style={{cursor: "pointer"}}/>
                }
                <HeaderByIndex index={childIndex}>{blockData?.title ?? ""}</HeaderByIndex>
                {restrictions.length > 0 ?
                <div className={styles.restrictionContainer}>
                    {restrictions.map((_r) => (
                        _r.value && _r.value.length > 0 ? 
                            _r.value.map((_r2, _indx) => (
                                <div key={_r.type + _indx}>
                                    <RestrictionDisplay restriction={{type: _r.type, value: _r2}}/>
                                </div>
                            ))
                        : null
                      
                    ))}
                </div>
                : null
                }
                <Edit className={styles.icon} color={"blue"} size={30} onClick={() => setIsOpenSetup(true)} />
                {remove ? <Trash2 className={styles.icon} color={"red"} size={30} onClick={remove} /> : null}

            </div>
            {isContentOpen && blockData?.content ?
                blockData?.content.map((_component, _indx) => (
                    <div key={_indx}>
                        {_component.type === "block" ?
                            <BlocEdit blockData={_component} parentRestrictions={[...parentRestrictions, ...restrictions]} childIndex={childIndex + 1} remove={() => _remove(_indx)} />
                            : _component.type === "text" ?
                                <TextEdit blockData={_component} parentRestrictions={[...parentRestrictions, ...restrictions]} remove={() => _remove(_indx)} />
                                : _component.type === "image" ?
                                    <ImageEdit blockData={_component} parentRestrictions={[...parentRestrictions, ...restrictions]} remove={() => _remove(_indx)} />
                                    : _component.type === "imageDiffential" ?
                                        <ImageCompareEdit blockData={_component} parentRestrictions={[...parentRestrictions, ...restrictions]} remove={() => _remove(_indx)} />
                                        : _component.type === "list" ?
                                            <ListEdit blockData={_component} parentRestrictions={[...parentRestrictions, ...restrictions]} remove={() => _remove(_indx)} />
                                            : null}
                    </div>
                ))
                : null}


            <PlusCircle className={styles.icon} size={30} color={"purple"} onClick={() => setIsOpen(true)} />


            <Modal isOpen={isOpenSetup} toggle={() => setIsOpenSetup(false)} size="lg">
                <ModalHeader>{"Setup du composant"}</ModalHeader>
                <ModalBody>
                    <Input label={"Titre"} value={title} onChange={(e) => setTitle(e.target.value)} />

                    <h3 className={styles.restrictions}>Restrictions : </h3>
                    <Restrictions parentRestrictions={parentRestrictions} currentRestrictions={restrictions} setRestrictions={setRestrictions} />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={_setup}>Valider</Button>
                    <Button color="secondary" onClick={() => setIsOpenSetup(false)}>Annuler</Button>
                </ModalFooter>
            </Modal>


            {/* Création d'un nouveau composant */}

            <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
                <ModalHeader>{"Ajout d'un composant"}</ModalHeader>
                <ModalBody>
                    <div className={styles.addDiv}>
                        {composants.map((_c, _indx) => (
                            <div key={_indx} className={styles.addComponent} onClick={() => _addComposant(_c.type)}>
                                <div>
                                    {_c.title}
                                </div>
                            </div>
                        ))}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setIsOpen(false)}>Annuler</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};



export const BlocView = ({ blockData, parentRestrictions, childIndex }) => {
    const [isContentOpen, setIsContentOpen] = useState(true);


    return (
        <div style={{ width: "100%", padding: 20 }}>
            <div className={styles.titleComponent}>
                {isContentOpen ?
                    <ChevronDown size={25} onClick={() => setIsContentOpen(false)} style={{cursor: "pointer"}}/>
                    :
                    <ChevronRight size={25} onClick={() => setIsContentOpen(true)} style={{cursor: "pointer"}}/>
                }
                <HeaderByIndex index={childIndex}>{blockData?.title ?? ""} </HeaderByIndex>
                {blockData?.restrictions?.length > 0 ?
                <div className={styles.restrictionContainer}>
                    {blockData.restrictions.map((_r) => (
                        _r.value && _r.value.length > 0 ? 
                            _r.value.map((_r2, _indx) => (
                                <div key={_r.type + _indx}>
                                    <RestrictionDisplay restriction={{type: _r.type, value: _r2}}/>
                                </div>
                            ))
                        : null
                      
                    ))}
                </div>
                : null
                }
            </div>
            {isContentOpen && blockData?.content ?
                blockData?.content.map((_component, _indx) => (
                    <div key={_indx}>
                        {_component.type === "block" ?
                            <BlocView blockData={_component} parentRestrictions={[...parentRestrictions, ...blockData.restrictions ?? []]} childIndex={childIndex + 1} />
                            : _component.type === "text" ?
                                <TextView blockData={_component} parentRestrictions={[...parentRestrictions, ...blockData.restrictions ?? []]} />
                                : _component.type === "image" ?
                                    <ImageView blockData={_component} parentRestrictions={[...parentRestrictions, ...blockData.restrictions ?? []]} />
                                    : _component.type === "imageDiffential" ?
                                        <ImageCompareView blockData={_component} parentRestrictions={[...parentRestrictions, ...blockData.restrictions ?? []]} />
                                        : _component.type === "list" ?
                                            <ListView blockData={_component} parentRestrictions={[...parentRestrictions, ...blockData.restrictions ?? []]} />
                                            : null}
                    </div>
                ))
                : null}

        </div>
    );
};


function HeaderByIndex({ index, children }) {
    let HeaderTag;

    switch (index) {
        case 1:
            HeaderTag = "h1";
            break;
        case 2:
            HeaderTag = "h2";
            break;
        case 3:
            HeaderTag = "h3";
            break;
        case 4:
            HeaderTag = "h4";
            break;
        case 5:
            HeaderTag = "h5";
            break;
        case 6:
            HeaderTag = "h6";
            break;
        default:
            HeaderTag = "div"; // Par défaut, si l'index est en dehors de la plage 1-6
            break;
    }

    return <HeaderTag className={styles.title}>{children}</HeaderTag>;
}

HeaderByIndex.propTypes = {
    index: PropTypes.number,
    children: PropTypes.any,
};


BlocEdit.propTypes = {
    blockData: PropTypes.object,
    parentRestrictions: PropTypes.object,
    childIndex: PropTypes.number,
    remove: PropTypes.func,
};

BlocView.propTypes = {
    blockData: PropTypes.object,
    parentRestrictions: PropTypes.object,
    childIndex: PropTypes.number,
};