import React from "react";
import PropTypes from "prop-types";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import moment from "moment";


import Logo from "./assets/Logo.png";
// import MenuBorder from "./assets/OveliaMenu.png";
// import Separator from "./assets/OveliaSeparator.png";

import Roboto from "fonts/roboto/Roboto-Condensed.ttf";
import RobotoBold from "fonts/roboto/Roboto-BoldCondensed.ttf";

import Calibri from "fonts/calibri/calibri-regular.ttf";
import CalibriBold from "fonts/calibri/calibri-bold.ttf";

import Brandon from "fonts/brandon/BrandonGrotesque-Regular.ttf";
import BrandonBold from "fonts/brandon/BrandonGrotesque-Bold.ttf";

import CenturyGothic from "fonts/centuryGothic/CenturyGothic.ttf";
import CenturyGothicBold from "fonts/centuryGothic/CenturyGothicBold.ttf";

Font.register({
  family: "Brandon",
  fontWeight: "normal",
  src: Brandon,
});
Font.register({
  family: "Brandon",
  format: "bold",
  src: BrandonBold,
});

Font.register({
  family: "Roboto",
  fontWeight: "normal",
  src: Roboto,
});
Font.register({
  family: "Roboto",
  format: "bold",
  src: RobotoBold,
});
Font.register({
  family: "Calibri",
  fontWeight: "normal",
  src: Calibri,
});
Font.register({
  family: "Calibri",
  format: "bold",
  src: CalibriBold,
});

Font.register({
  family: "CenturyGothic",
  fontWeight: "normal",
  src: CenturyGothic,
});
Font.register({
  family: "CenturyGothic",
  format: "bold",
  src: CenturyGothicBold,
});



const styles = StyleSheet.create({
  globalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "90%",
    border: "2 solid #ec871c",
    margin: 50,
    marginLeft: 50,
    paddingTop: 10,
  },
  
  logo: {
    position: "absolute",
    width: 150,
    height: 90,
    margin: 10,
    padding: 10,
    top: -60,
    backgroundColor: "white",
  },
  dateContainer: {
    display: "flex", 
    flexDirection: "row", 
    justifyContent: "center", 
    width: "85%", 
  },
  date: {
    backgroundColor: "#ec871c",
    padding: 12,
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
    marginBottom: 30,
    fontFamily: "CenturyGothic"
  },
  message: {
    textAlign: "center",
    fontSize: 10,
    fontWeight: "bold",
    color: "#1c2140",
    marginBottom: 20,
    wordWrap: "break-word",
    fontFamily: "CenturyGothic"
  },
  menusContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "62vh",
    width: "80%",
  },
  menuTitle: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#1c2140",
    fontFamily: "CenturyGothic",
    marginTop: 5,
    marginBottom: 15,
  },
  menu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginBottom: 20,
    flex: 1,
  },
  menuContent: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  category: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  dish: {
    color: "#1c2140",
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "CenturyGothic",
    textAlign: "center"
  },
  orText: {
    fontSize: 16,
    marginLeft: 5,
    marginRight: 5,
    color: "#E46D8E",
    fontStyle:"bold"
  },
  allergenesContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "90%",
    marginBottom: 50,
  },
  allergenesTitle:{
    backgroundColor: "#E46D8E",
    color: "white",
    fontWeight: "bold",
    fontSize: 11,
    padding: 5,
    fontFamily: "CenturyGothic",
    marginBottom: 5,
  },
  allergenesItemContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignContent: "space-between",
    height: 120,
    width: "100%",
    gap: 2
  },
  allergeneItem: {
    fontSize: 9,
    width: "45%"
  },
  allergeneIndex: {
    fontSize: 8,
    fontWeight: "normal",
    fontFamily: "CenturyGothic"
  }
});

const HeurusDayPDF = (props) => {
  const { day, data, ui } = props;
  const template = ui.establishment.template;
  
  const sortedRepas = Object.keys(template).sort((a,b) => template[a].heure - template[b].heure);

  const allergenes = [
    "Soja et produit à base de soja.",
    "Poisson et produits à base de poisson.",
    "Mollusques.",
    "Crustacés et produits à base de crustacés.",
    "Graines de sésames et produits à base de sésame.",
    "Céréales contenant du gluten : blé, seigle épeautre, kamut et les produits à base de ces céréales.",
    "Œuf et produits à base d'œuf.",
    "Lupin",
    "Fruits à coque : amande, noisette, noix de cajou, noix de pécan, noix du brésil, pistache, noix de macadamia et produits à base de ces fruits.",
    "Lait et produit à base de lait.",
    "Arachide et produits à base d'arachide.",
    "Anhydride sulfureux et sulfites en concentration de plus de 10mg/kg ou mg/l exprimés en SO2.",
    "Moutarde et produit à base de moutarde.",
    "Céleri et produits à base de céleri",
  ];

  const allergenesIndexDisplay = (dish, _indxDish, array) => {
    const regex = /(.*)\(([\d-]+)\)/;
    const match = dish.match(regex);

    if (match) {
      return (
        <>
          <Text key={_indxDish} style={styles.dish}>
            {match[1]}
            <Text style={styles.allergeneIndex}>({match[2]})</Text>
          </Text>
          {(_indxDish < array.length - 1) && (
            <Text style={styles.orText}>{"ou"}</Text> 
          )}
        </>
      );
    } else {
      return (
        <>
          <Text key={_indxDish} style={styles.dish}>{dish}</Text>
          {(_indxDish < array.length - 1) && (
            <Text style={styles.orText}>{"ou"}</Text> 
          )}
        </>
      );
    }
  };


  return (
    <Document>
        <Page size="A4" orientation="portrait">
          <View style={styles.globalContainer}>
            <View style={styles.container}>
              <Image src={Logo} style={styles.logo} />
              <View style={styles.menusContainer}>
                <View style={styles.dateContainer}>
                  <Text style={styles.date}>{moment(day).format("dddd D MMMM YYYY").toUpperCase()}</Text>
                </View>
                <View>
                  <Text style={styles.message}>VOUS AVEZ LA POSSIBILITE DE DEMANDER DES SUBSTITUTS EN SALLE DE RESTAURANT</Text>
                </View>
                {sortedRepas.map(_repas => (
                  <View key={_repas} style={styles.menu}>
                    {/* // DEJEUNER ET DINER */}
                    <Text style={styles.menuTitle}>{_repas.toUpperCase()}</Text>
                    {Object.keys(template[_repas].menus).sort((a, b) => template[_repas].menus[a].weight - template[_repas].menus[b].weight).slice(0, 1).map(_menu => (
                      <View key={_menu} style={styles.menuContent}>
                        {template[_repas].menus[_menu].categories.map((_category, _indx) => (
                          <View key={_indx} style={styles.category}>
                            {data && data[_repas] && data[_repas][_category] &&
                              Object.values(data[_repas][_category])
                                .filter(_dish => _dish.origin === _menu)
                                .sort((a, b) => a.order - b.order)
                                .map((dish, _indxDish, array) => allergenesIndexDisplay(dish.name, _indxDish, array))}
                          </View>
                        ))}
                      </View>
                    ))}
                  </View>)
                )}
              </View>
            </View>
              <View style={styles.allergenesContainer}>
                <Text style={styles.allergenesTitle}>Liste des allergènes :</Text>
                <View style={styles.allergenesItemContainer}>
                  {allergenes.map((_allergene, index) => (
                    <Text key={index} style={styles.allergeneItem}>({index + 1}) {_allergene}</Text>
                  ))}
                </View>
              </View>
          </View>
        </Page>
    </Document>
  );
};
HeurusDayPDF.propTypes = {
  day: PropTypes.any.isRequired,
  data: PropTypes.object.isRequired,
  ui: PropTypes.any,
};

export default HeurusDayPDF;
