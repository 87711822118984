import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Restrictions from "./components/restrictions";
import { Button } from "lib/components";
import { BlocView } from "./components/bloc";
import moment from "moment";
import deepClone from "helpers/deepClone";

const Searching = ({patchnotes}) => {
    const [restrictions, setRestrictions] = useState([]);
    const [filteredPatchnotes, setFilteredPatchnotes] = useState([]);

    /*
    Cas 1 :
    - search: application : Major et Major Connect

    restri : Major, Major-Totem, 

    Cas 2:
    search :
    - application: Major
    - features: menus et animations


    restri : Major, gazette
    */

    const isRestrictionOk = (restrictionsToCheck) => {        
        const sumFound = [];
        restrictions.forEach(chosenRestri => {
            const _resetrictionCorrespondant = restrictionsToCheck.find(_r => _r.type === chosenRestri.type);
            
            if(_resetrictionCorrespondant){
                sumFound.push(chosenRestri?.value.some(_r => _resetrictionCorrespondant.value.includes(_r)));
            }
        });
      
        if(sumFound.length > 0 && sumFound.length === restrictions.length){
            let retour = true;
            sumFound.forEach(element => {
                if(element === false)retour=false;
            });
            return retour;
        }else{
            return false;
        }
    };

    const recursiveCheck = (data, parentRestrictions) => {
        const _currentRestrictions = [...parentRestrictions, ...data?.restrictions ?? []];
        
        if(data.type === "block"){
            if(data.content && data.content.length > 0){
                // const restrictOk = isRestrictionOk(_currentRestrictions);
                const _newContent = [];
                data.content.forEach(element => {
                    const _retour = recursiveCheck(element, _currentRestrictions);
                    if (_retour)_newContent.push(_retour);
                });
                if(_newContent.length > 0){
                    return {...data, content: _newContent};
                }else{
                    return null;
                }
            }else{
                return null;
            }
        }else if(data.type === "list"){
            if(data.value && data.value.length > 0){

                const _newContent = [];
                data.value.forEach(element => {
                    if(isRestrictionOk([..._currentRestrictions, ...data?.restrictions ?? [], ...element?.restrictions ?? []])){
                        _newContent.push(element);
                    }
                });
                if(_newContent.length > 0 ){
                    return {...data, value: _newContent};
                }else{
                    return null;
                }
            }else{
                return null;
            }
        }else{
            if(data && isRestrictionOk(_currentRestrictions)){
              return data;
            }else{
                return null;
            }
        }
    };

    const search = () => {
        const _patchnotes = deepClone(patchnotes).sort((a, b) => b.date - a.date);

        const _patchnoteList = [];
        _patchnotes.forEach(patchnote => {
            if(patchnote.content){
                const _patchnoteContent = recursiveCheck(patchnote.content, []);
                if(_patchnoteContent){
                    _patchnoteList.push(
                        {
                            ...patchnote, content: _patchnoteContent
                        }
                    );
                }
            }
        });
        
        setFilteredPatchnotes(_patchnoteList);
    };

    // console.log(patchnotes);
    return (
        <div>
            <Restrictions parentRestrictions={[]} currentRestrictions={restrictions} setRestrictions={setRestrictions} search={true}/>

            <Button style={{marginTop: 10, width: 200}} onClick={search}>Rechercher</Button>

            <div>
                {filteredPatchnotes.map((_p, _indx) => (
                    <div key={_indx}>
                        {_p?.content ?  
                        <BlocView blockData={_p.content} parentRestrictions={[]} childIndex={1}/>
                    : null}
                    </div>
                ))}
            </div>
        </div>
    );
};



Searching.propTypes = {
    patchnotes: PropTypes.array,
};

export default Searching;