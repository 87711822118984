import React from "react";
import {Redirect, useParams } from "react-router-dom";

const Groupement = () => {
  const {id} = useParams();

  return (
    <Redirect to={`/dashboard/groupements/${id}/infos`} />
  );
};

export default Groupement;