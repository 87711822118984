import React from "react";
import PropTypes from "prop-types";
import styles from "./truefalse.module.scss";
import { Circle, Triangle } from "react-feather";
import * as c from "../colors";

const Answering = ({question}) => {
  return (
    <div className={styles.answering}>
      <div className={styles.title}>
        <h3>{question.title}</h3>
      </div>
      <div className={styles.mediaDiv}>
        {question.media ? 
          <img src={question.media} className={styles.media} />
          : null}
      </div>
      <div className={styles.answers}>
        <div className={`${styles.answer}`} style={{/*backgroundColor : c.COLOR_PALETTE_1[0]*/}}>
          <Circle stroke={c.COLOR_PALETTE_1[0]} width={70} height={70} fill={c.COLOR_PALETTE_1[0]}/>
          <span>VRAI</span></div>
        <div className={`${styles.answer}`} style={{/*backgroundColor : c.COLOR_PALETTE_1[1]*/}}>
          <Triangle stroke={c.COLOR_PALETTE_1[1]} width={70} height={70} fill={c.COLOR_PALETTE_1[1]}/>
          <span>FAUX</span></div>
      </div>
    </div>
  );
};

Answering.propTypes = {
  question : PropTypes.object.required,
};

export default Answering;