import React from "react";

import Basic_ctx, {Provider as Basic_Provider} from "./Basic/Context/ctx";

import useUI from "hooks/ui.hook";

const Provider = (props) => {
    const [ui] = useUI();

    return <Basic_Provider {...props} />;
};

const useCtx = () => {
    const [ui] = useUI();

    return Basic_ctx();
};

export { useCtx, Provider };
export default useCtx;