import { create } from 'zustand'

const userStore = create((set) => ({
  
  users: [],
  setUsers: (newUsers) => set({ users: newUsers }),

}));

export default userStore;
