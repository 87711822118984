import firebase from "firebase";
import { toast } from "react-toastify";

const firestore = firebase.firestore;

export const getUserById = async ({id}, err) => {
  try {
    const res = await firestore()
                        .collection("users")
                        .doc(id)
                        .get();
    if (!res.exists) return 0;
    return {uid: res.id, ...res.data()};
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};

export const getUsersByEtab = async ({etabId}, err) => {
  try {
    const res = await firestore()
                        .collection("users")
                        .where("establishment", "==", etabId)
                        .get();
    return res.docs.map(doc => ({uid: doc.id, ...doc.data()}));
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};

export const getUsersByGroupement = async ({groupementId}, err) => {
  try {
    let etabs = await firestore()
                        .collection("establishments")
                        .where("groupement", "==", groupementId)
                        .get();
    etabs = etabs.docs.map(doc => doc.id);

    let users = await firestore()
                        .collection("users")
                        .where("establishment", "in", etabs)
                        .get();
    return users.docs.map(doc => ({uid: doc.id, ...doc.data()}));
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};

export const updateUserById = async ({id, data}, err) => {
  try {
    const res = await firestore()
                      .collection("users")
                      .doc(id)
                      .update(data);
    return res;
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
  }
};

export const getAllUsers = async (err) => {
  try {
    const res = await firestore()
                      .collection("users")
                      .get();
    return res.docs.map(doc => ({uid: doc.id, ...doc.data()}));
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return null;
  }
};