import React, { useState, useMemo } from "react";
import { useServiceManagement } from "../../routerContext";
import { ArrowLeft, Plus, Check, Edit } from "react-feather";
import styles from "../Scss/creationPrestation.module.scss";
import { Button, Row, Icon, Container, Card, Table } from "lib/components";
import { CreatePrestationModal } from "../../routerComponents";
import { toast } from "react-toastify";



const CreationPrestation = (props) => {
    const [ctx, { updatePage, deleteCurrentPrestataire, addPrestataire, updatePrestataire,removePrestataire }] = useServiceManagement();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentPrestation, setCurrentPrestation] = useState({});
    const [prestataireId, setPrestataireId] = useState("");


    const isPrestataireAlreadyExists = useMemo(() => {
        const presta = ctx.prestataires.find(item => item.name === ctx.currentPrestataire.name);
        if (presta) return presta.uid;
    },[ctx.prestataires]);

    const _delete = () =>{
        try{
            const prestaId = isPrestataireAlreadyExists;
        if(prestaId){
            removePrestataire(prestaId);
            updatePage(ctx.startPage);
            deleteCurrentPrestataire();
        }
        else{
            toast.error("la suppression n'a pas abouti");
        }
        } catch(e){
            toast.error("la suppression n'a pas abouti");
        }
    };
    const _submit = () => {
        try {
            const prestaId = isPrestataireAlreadyExists;
            if (prestaId) {
                updatePrestataire(prestaId);
                toast.success("Modification effectuée");
            } else {
                addPrestataire();
                toast.success("Création effectuée");

            }
            updatePage(ctx.startPage);
            deleteCurrentPrestataire();

        } catch (e) {
            toast.error("la creation n'a pas abouti");

        }
    };

    return (
        <Container >
            <ArrowLeft color="#300438" size={42} onClick={() => { updatePage(2); }} />
            <h2 className={styles.title}> {isPrestataireAlreadyExists ? "Modification des prestations de \"" : "Création des prestations de \""}<span style={{ color: "#DF7189" }}>{ctx.currentPrestataire.name}</span>&quot;</h2>
            <Row className={styles.firstLine}>
                <div>Tableaux récapitulatif des prestations:</div>
                <Button className={styles.buttonWrapper} onClick={() => { setIsModalVisible(true); setCurrentPrestation({}); }} >
                    <Icon icon={Plus} color="light" size="30" />
                </Button>
            </Row>

            <Card className={styles.card}>
                {ctx.currentPrestataire.prestations.length === 0 ? <div className={styles.message}>Aucune prestation n&apos;a été ajoutée. Cliquez sur le bouton plus situé en haut à droite pour démarrer. </div> :
                    <Table>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Durée</th>
                                <th>Prix</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(ctx.currentPrestataire.prestations).map(prestaId => {
                                const prestation = ctx.currentPrestataire.prestations[prestaId];
                                return (
                                    <tr key={prestaId}>
                                        <td>{prestation.title}</td>
                                        <td>{prestation.duration}</td>
                                        <td>{prestation.price}</td>
                                        <td>{prestation.description}</td>
                                        <td><Icon size={20} icon={Edit} color='info'
                                            onClick={() => {
                                                setIsModalVisible(true);
                                                setCurrentPrestation({ ...prestation, prestaId });
                                            }

                                            } /></td>

                                    </tr>
                                );

                            })}
                        </tbody>
                    </Table>
                }
            </Card>
            <Row className={styles.rowButton}>
                {isPrestataireAlreadyExists ?
                    <Button onClick={_delete} className={styles.deleteButton} >
                        <div style={{ fontSize: "18px" }}> Supprimer le prestataire</div>
                    </Button>

                : null}
                <Button disabled={ctx.currentPrestataire.prestations.length === 0 ? true : false} onClick={_submit} className={styles.saveButton} >
                    <div style={{ fontSize: "18px" }}> {isPrestataireAlreadyExists ? "Modifier" : "Enregistrer"
                    }</div>
                    <Icon icon={Check} color="light" size="24" />
                </Button>
                
            </Row>

            <CreatePrestationModal isOpen={isModalVisible} toggle={()=>setIsModalVisible(false)} currentPrestation={currentPrestation} />
        </Container>
    );
};

export default CreationPrestation;