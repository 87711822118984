import {
  Dribbble,
  Info,
  Home,
  Award,
  Users,
  BarChart,
  Tool
} from "react-feather";

import { faRss, faPrint } from "@fortawesome/free-solid-svg-icons";
import { SidebarImage, SidebarLink, SidebarSubMenu, SidebarSubSubMenu } from "../lib/components/dashboard";

import Logo from "../assets/images/jabiLogo/MAJOR_BLANC.png";
import React from "react";
import useUI from "../hooks/ui.hook";
import useRedirectMCWebWithAutologin from "helpers/redirectMCWebWithAutologin";

const SidebarMenu = () => {
  const [ui] = useUI();

  const comm = (
    <>
      {ui.establishment.features &&
        ui.establishment.features.includes("animationPlanning") ? (
        <SidebarLink to="/dashboard/animations" iconAfter={faRss}>
          Animations
        </SidebarLink>
      ) : (
        <></>
      )}
      {ui.establishment.features &&
        ui.establishment.features.includes("menuPlanning") ? (
        <SidebarLink to="/dashboard/menu" iconAfter={faRss}>
          Menus {ui.user.role === "employee" ? "(édition)" : ""}
        </SidebarLink>
      ) : (
        <></>
      )}
      {ui.establishment.features &&
        ui.establishment.features.includes("news") ? (
        <SidebarLink to="/dashboard/news" iconAfter={faRss}>
          Actualités
        </SidebarLink>
      ) : (
        <></>
      )}
      {ui.establishment.features &&
        ui.establishment.features.includes("gazette") ? (
        <SidebarLink to="/dashboard/gazette" iconAfter={faRss}>
          Gazette
        </SidebarLink>
      ) : (
        <></>
      )}
      {ui.establishment.features &&
      ui.establishment.features.includes("banner") ? (
        <SidebarLink to="/dashboard/banner" iconAfter={faRss}>
          Annonces
        </SidebarLink>
      ) : (
        <></>
      )}
    </>
  );

  const gestion = (
    <>
      {(ui.establishment.features &&
        ui.establishment.features.includes("reservationMenu")) ||
        ui.establishment.features.includes("reservationPlanning") ||
        ui.establishment.features.includes("services") ? (
        <SidebarSubSubMenu title="Réservations">
          {ui.establishment.features.includes("reservationPlanning") ? (
            <SidebarLink to="/dashboard/reservation/animations">
              Animations
            </SidebarLink>
          ) : null}
          {ui.establishment.features.includes("reservationMenu") ? (
            <SidebarLink to="/dashboard/reservation/menus">Menus</SidebarLink>
          ) : null}
          {ui.establishment.features.includes("services") ? (
            <SidebarLink to="/dashboard/reservation/services">Services</SidebarLink>
          ) : null}
        </SidebarSubSubMenu>
      ) : (
        <></>
      )}
      <SidebarLink to="/dashboard/users" icon={Users}>
        Utilisateurs
      </SidebarLink>

        {ui.establishment.features.includes("interventionsRequest") ?
      <SidebarLink to="/dashboard/interventions" icon={Tool}>
        Interventions
      </SidebarLink> : null}

      {["owner", "superadmin", "groupementAdmin", "regionAdmin"].includes(ui.user.role) ? (
        <SidebarLink to="/dashboard/statistics" icon={BarChart}>
            Statistiques
        </SidebarLink>
      ) : null}
  </>);


  const activites = (
    <>
      {ui.establishment.features &&
        ui.establishment.features.includes("AnimationWorkshopDatabase") ? (
        <SidebarLink to="/dashboard/ateliers" icon={Dribbble}>
          Ateliers
        </SidebarLink>
      ) : (
        <></>
      )}

      {ui.establishment.features &&
        ui.establishment.features.includes("cultureQuizz") ? (
        <SidebarLink to="/dashboard/cultureQuizz" icon={Award}>
          Culture Quiz
        </SidebarLink>
      ) : (
        <></>
      )}
    </>
  );

  // const experimental = <>
  //       <SidebarLink to="/dashboard/kpi">KPI</SidebarLink>
  // </>;

  return (
    <div style={{ position: "relative", top: -20 }}>
      <SidebarImage img={Logo} />
      <SidebarLink to="/dashboard/one" icon={Home}>
        Accueil
      </SidebarLink>

      <SidebarLink to="/dashboard/tuto" icon={Info}>
        Tutoriels vidéo
      </SidebarLink>

      <SidebarSubMenu title="communication">{comm}</SidebarSubMenu>

      <SidebarSubMenu title="gestion">{gestion}</SidebarSubMenu>

      <SidebarSubMenu title="activités">{activites}</SidebarSubMenu>

      {/* {ui.user.superadmin && <SidebarSubMenu title="experimental">{experimental}</SidebarSubMenu> } */}

    </div>
  );
};

export default SidebarMenu;
