import PropTypes from "prop-types";
import {
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import styles from "../styles/importantAnimation.module.scss";
import useUI from "hooks/ui.hook";
import { Edit3, Plus, Trash } from "react-feather";
import contentTypes from "../contentTypesHeurus";
import "../../../fonts";
import Book from "../assets/Book.png";
import PlayCard from "../assets/PlayCard.png";


const ImportantAnimationMiniature = (props) => {
  const { onClick } = props;
  return (
    <div onClick={onClick} className={styles.miniature}>
      Page de type &quot;Animations Importantes&quot;
    </div>
  );
};

const ImportantAnimation = (props) => {
  const {
    page,
    onEditPage,
    onRemove,
    onAddContent,
    onRemoveContent,
    onEditContent,
  } = props;

  const [ui] = useUI();
  
  // SI cette condition est remplie, on considère que la page vient d'être créée 
  if(page.title === "") {
    page.title = "Animations à venir";
    
    const defaultContents = {
      // "1": {
      //   contentType: "animation",
      //   format: "animation",
      //   selectedAnimations: [],
      // }
    };
    Object.keys(defaultContents).forEach(field => {
      page[field] = JSON.stringify(defaultContents[field]);
    });
  }

  return (
    <>
      <div className={styles.head}></div>
      <div className={styles.page}>
        <div className={styles.decorator}></div>
        <div className={styles.pageTitleContainer}>
              <p className={styles.pageTitle}>ANIMATIONS IMPORTANTES</p>
            </div>
          <div className={styles.content}>

            {page["1"] &&
              contentTypes.find(
                (type) => type.slug === JSON.parse(page["1"]).contentType
              ) ? (
                <div className={`${styles.editoField}`}>
                  <Trash
                    color="red"
                    size={40}
                    className={styles.trash}
                    onClick={() => onRemoveContent("1")}
                  />
                  <Edit3
                    color="blue"
                    size={40}
                    className={styles.edit}
                    onClick={() => onEditContent({ format: "animation", key: "1" })}
                  />
                  {contentTypes
                    .find((type) => type.slug === JSON.parse(page["1"]).contentType)
                    .component({ ...JSON.parse(page["1"]) })}
                </div>
              ) : (
                <div
                  className={`${styles.editoField} ${styles.add}`}
                  onClick={() => onAddContent({ format: "animation", key: "1" })}
                >
                  <Plus size={120} />
                </div>
              )}
        </div>
        <Trash
          color="red"
          size={40}
          className={styles.bottomTrashIcon}
          onClick={onRemove}
        />
      </div>
    </>
  );
};

const ImportantAnimationPdf = (props) => {
  const { page, ctx, index, contentTypes } = props;
  const pdfStyles = StyleSheet.create({
    container: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      
    },
    header: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "10px",
    },
    title: {
      fontFamily: "Brandon",
      fontSize: "18px",
      fontWeight: "bold",
      backgroundColor: "#ec871c",
      color: "white",
      width: "50%",
      textAlign: "center",
      paddingVertical: "5px",
    },
    incomingMonth: {
      marginTop: "10px",
      color: "#1c2140",
      fontFamily: "Brandon",
      fontSize: "14px",
      textAlign: "center",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignSelf: "center",
      width: "90%",
      height: "99%",
      marginTop: "20px"
    },
    footer: {
      width: "100%",
      height: "15%",
    },
    footerContent: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "95%",
      padding: "20px",
      backgroundColor: "#e7e6e6",
    },
    decorator: {
      position: "absolute",
      width: "100%",
      height: "80%",
      bottom: "0px",
      backgroundColor: "#e76d90",
    },
    footerText: {
      fontFamily: "Brandon",
      fontSize: "18px",
      fontWeight: "bold",
      color: "#1c2140",
      maxWidth: "80%",
      wordWrap: "break-word",
      height: "100%",
    },
    book: {
      width: "60px",
      height: "60px",
    },
    playCard: {
      width: "70px",
      height: "70px",
      position: "absolute",
      left: "20px",
      top: "10px",
      transform: "rotate(-10deg)",
    },
    pageCountContainer: {
      position: "absolute",
      backgroundColor: "white",
      borderRadius: 25,
      width: 30,
      height: 30,
      bottom: 5,
      right: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    pageCountText: {
      color: "black",
      fontSize: 24,
      fontWeight: 600,
    },
  });

  return (
    <Page size="A4" orientation="portrait">
      <View style={pdfStyles.container}>
        <View>
          <View style={pdfStyles.header}>
            <Image src={PlayCard} style={pdfStyles.playCard}/>
            <Text style={pdfStyles.title}>ANIMATIONS IMPORTANTES</Text>
            <Text style={pdfStyles.incomingMonth}>À VENIR SUR LE MOIS DE {moment(ctx.editionDate ? ctx.editionDate : ctx.createdAt).startOf("month").add(1, "months").format("MMMM yyyy").toLocaleUpperCase()}</Text>
          </View>
          <View style={pdfStyles.content}>
            {page["1"] &&
              contentTypes.find(
                (type) => type.slug === JSON.parse(page["1"]).contentType
              )
                ? contentTypes
                  .find(
                    (type) => type.slug === JSON.parse(page["1"]).contentType
                  )
                  .pdf({cardBackgroundColor: "#e7e6e6",settings: ctx.settings, categoriesEvent: ctx.categoriesEvent, ...JSON.parse(page["1"]) })
                : null}
          </View>
        </View>
        <View style={pdfStyles.footer}>
          <View style={pdfStyles.decorator}></View>
          <View style={pdfStyles.footerContent}>
            <Text style={pdfStyles.footerText}>Des livres sont à votre disposition dans le grand salon, n&apos;hésitez pas à les emprunter !</Text>
            <Image src={Book} style={pdfStyles.book}/>
          </View>
        </View>
      </View>
      <View style={pdfStyles.pageCountContainer}>
        <Text style={pdfStyles.pageCountText}>{index + 1}</Text>
      </View>
    </Page>
  );
};

ImportantAnimation.propTypes = {
  page : PropTypes.any,
  onEditPage : PropTypes.any,
  onRemove : PropTypes.any,
  onAddContent : PropTypes.any,
  onRemoveContent : PropTypes.any,
  onEditContent : PropTypes.any,
};
ImportantAnimationMiniature.propTypes = {
  onClick : PropTypes.any,

};
ImportantAnimationPdf.propTypes = {
  page : PropTypes.any,
  ctx : PropTypes.any,
  index : PropTypes.any,
  contentTypes: PropTypes.any,
};

export { ImportantAnimation, ImportantAnimationMiniature, ImportantAnimationPdf };