import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../../../../lib/components";
import { Printer } from "react-feather";

const WeekExportButton = ({callback}) => {
    return (
        <Button
            onClick={callback}
            style={{
                paddingTop: 10,
                paddingBottom: 10,
                margin: "0 5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 5,
            }}
            color="primary"
        >
            <Printer size={20} />
            Impression semaine
        </Button>
    );
};

WeekExportButton.propTypes = {
    callback: PropTypes.func.required,
  };

export default WeekExportButton;