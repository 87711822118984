import React from "react";
import PropTypes from "prop-types";
import useUI from "../../hooks/ui.hook";

import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import {
  DashboardLayout,
} from "../../lib/layouts";

import Menu from "../Major/Menu/Menu";
import MenuWeekExport from "../Major/Menu/Basic/Pages/MenuWeekExport";

import RestaurantMenu from "../../config/RestaurantMenu";
import DashboardHeader from "../../config/DashboardHeader";

import Home from "../Major/Home";

const Restaurant = ({match}) => {
  const [ui] = useUI();
  return (
    <DashboardLayout menu={<RestaurantMenu />} header={<DashboardHeader />}>
      <Switch>
        <Route exact path={`${match.url}/one`}><Home /></Route>
        {ui.establishment.features && ui.establishment.features.includes("menuPlanning") ? <Route exact path={`${match.url}/menu`}><Menu /></Route> : null}
        {ui.establishment.features && ui.establishment.features.includes("menuPlanning") ? <Route exact path={`${match.url}/menu/export`}><MenuWeekExport /></Route> : null}
        <Route path={"*"}><Redirect to={`${match.url}/one`} /></Route>
      </Switch>
    </DashboardLayout>
  );
};

Restaurant.propTypes = {
  match: PropTypes.object.isRequired
};

export default Restaurant;