import React, {useState} from "react";
import PropTypes from "prop-types";

import styles from "../../assets/scss/component/stats/numberDisplay.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBalanceScale, faCircle } from "@fortawesome/free-solid-svg-icons";
import { ArrowUp, ArrowDown } from "react-feather";
import useStats from "lib/Stats/statsContext";

const NumberDisplay = (props) => {
  const {
    number,
    variation,
    mean, 
    meanVariation,
    icon, 
    iconBackgroundColor,
    className,
  } = props;
  const [ctx, dispatch, hasFeature] = useStats();

  const isVariation = hasFeature("statistics") && number && variation != undefined && variation != null && typeof(variation) === "number" ? true : false;

  let variationStyle = styles.positive;
  let positive = true;

  if(isVariation){
    if(variation < 0){
        positive = false;
        variationStyle = styles.negative;
    }
  }


  const isMeanVariation = hasFeature("statistics") && mean && meanVariation != undefined && meanVariation != null && typeof(meanVariation) === "number" ? true : false;

  let meanVariationStyle = styles.positive;
  let meanPositive = true;

  if(isMeanVariation){
    if(meanVariation < 0){
      meanPositive = false;
      meanVariationStyle = styles.negative;
    }
  }

  return (
    <div className={`${styles.component} ${className ?? ""}`}>
        <div className={styles.numberComponent}>
            <div className={styles.iconContainer} style={{backgroundColor: iconBackgroundColor ?? "gray"}}>
              <FontAwesomeIcon icon={icon ?? faCircle} style={{fontSize: "27px", width: "27px"}}/>
            </div>
         
          {mean ? <div className={styles.text}>Total</div> : null}
            <div className={styles.numberContainer}>
              <div className={styles.number}>
                  {reduceFloating(number)}
              </div>
              
              <div className={variationStyle}>
                {isVariation ? 
                <>
                  {positive ? <ArrowUp size={16} color="green"/> : <ArrowDown size={16} color="red"/>}
                  <span>{positive ? "+" : ""}{reduceFloating(variation)}%</span>
                </>
                  : null}
              </div>
            </div>
           
            
        </div>

        {mean ? 
        <>

        <div className={styles.numberComponent}>
          <div className={styles.iconContainer} style={{backgroundColor: "#D4F48F"}}>
            <FontAwesomeIcon icon={faBalanceScale} style={{fontSize: "27px", width: "27px"}}/>
          </div>
          <div className={styles.text}>Moyenne</div>
          <div className={styles.numberContainer}>

            <div className={styles.number}>
                  {reduceFloating(mean)}
            </div>
          
            <div className={meanVariationStyle}>
              {isMeanVariation ? 
              <>
                {meanPositive ? <ArrowUp size={16} color="green"/> : <ArrowDown size={16} color="red"/>}
                <span>{meanPositive ? "+" : ""}{reduceFloating(meanVariation)}%</span>
              </>
              : null}

            </div>
          </div>
        </div>
        </>
        : null}
    </div>
  );
};

const reduceFloating = (number) => {
  return Number.parseInt(number*10)/10;
};

NumberDisplay.propTypes = {
  number: PropTypes.number,
  variation: PropTypes.number,
  mean: PropTypes.number,
  meanVariation: PropTypes.number,
  className: PropTypes.string,
  icon: PropTypes.any,
  iconBackgroundColor: PropTypes.string,

};



export default NumberDisplay;