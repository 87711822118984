import React, {createContext, useReducer, useContext, useEffect, useState, useCallback} from "react";
import firebase from "firebase";
import PropTypes from "prop-types";
import moment from "moment";
import { getActusForEtab_rt } from "services/ressources/actus";

import useUI from "hooks/ui.hook";
import {toast} from "react-toastify";

const firestore = firebase.firestore;

const ActusContext = createContext();

const DefaultValue = {
  actus: [],
  loading: true,
};

const ActuProvider = ({children}) => {
  const [ui] = useUI();
  const [state, dispatch] = useReducer((oldState, action) => {
    switch (action.type) {
      case "SET_ACTUS": return {...oldState, actus: action.payload};
      case "SET_LOADING": return {...oldState, loading: action.payload};
      default: return oldState;
    }
  }, DefaultValue);

  useEffect(() => {
    return getActusForEtab_rt({ui}, (data) => {
      dispatch({type: "SET_ACTUS", payload: data});
      dispatch({type: "SET_LOADING", payload: false});
    });
  }, []);

/*  const create = useCallback((data) => {
    try {

    } catch (e) {
      console.error(e?.message ?? e);
      toast.error(e?.message);
    }
  }, []);

  const update = useCallback((data) => {
    try {

    } catch (e) {
      console.error(e?.message ?? e);
      toast.error(e?.message);
    }
  }, []);
  
  const remove = useCallback((data) => {
    try {

    } catch (e) {
      console.error(e?.message ?? e);
      toast.error(e?.message);
    }
  }, []);*/

  return (
    <ActusContext.Provider value={{
      state,
      //dispatch // outside modification method is forbidden
    }}>
      {children}
    </ActusContext.Provider>
  );
};
ActuProvider.propTypes = {
  children: PropTypes.node
};

const useActus = (props) => {
  const {filter, order, format, find} = props ?? {};
  const {state, ...rest} = useContext(ActusContext);
  const {actus} = state;

  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

  }, [format, filter, order, find, actus]);

  return {...rest, actus: result, loading};
};

export {ActuProvider, useActus};
export default useActus;