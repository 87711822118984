import { FormGroup } from "lib/components";
import PropTypes from "prop-types";
import ImagePickerCrop from "lib/components/Form/ImagePickerCrop";

import {
  Image,
  StyleSheet,
  View,
} from "@react-pdf/renderer";

import React from "react";
import styles from "../styles/image.contentType.module.scss";

import "../../../fonts";

const ImageComponent = (props) => {
  const { image, format } = props;
  return (
    <div className={`${styles.editorView} ${styles[format]}`}>
      <img src={image} />
    </div>
  );
};

const ImageEditor = (props) => {
  const { onChange, onSubmit, onCancel, field, page } = props;
  /* Ici on peut attribuer en dur des ratios d'images en fonction 
  *  du contexte dans lequel est appelé le composant
  */
  let ratio = 1;
  if(page.slug === "summary" && page.key === "1") ratio = 1.5;
  if(page.slug === "game" && (page.key === "1" || page.key === "2")) ratio = 0.90;
  if(page.slug === "game" && page.key === "3") ratio = 1.45;
  if(page.slug === "health") ratio = 1.40;
    
  return (
    <>
      <h5>Ajouter une image</h5>
      <FormGroup style={{flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <ImagePickerCrop
          ratio={ratio}
          onChange={(image) => onChange("image", image)}
          value={field.image}
        />
      </FormGroup>
    </>
  );
};

const ImagePdf = (props) => {
  const { image } = props;
  const pdfStyle = StyleSheet.create({
    container: {
      justifyContent: "center",
      alignItems: "center",
    },
    image: {
      objectFit: "cover",
    },
  });

  return (
    <View style={pdfStyle.container}>
      {image && <Image src={image} style={pdfStyle.image} />}
    </View>
  );
};

ImageComponent.propTypes = {
  image : PropTypes.any,
  format : PropTypes.any,
};
ImageEditor.propTypes = {
  onChange : PropTypes.any,
  onSubmit : PropTypes.any,
  onCancel : PropTypes.any,
  field : PropTypes.any,
  page: PropTypes.any,
};
ImagePdf.propTypes = {
  image : PropTypes.any,
};

export { ImageComponent, ImageEditor, ImagePdf };