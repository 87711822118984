import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Modal, ModalHeader, ModalBody, ModalFooter,
  Button, Input, Select, FormGroup, Checkbox} from "../../../lib/components";

const ModalForm = ({onClose,onSave,data}) => {
  const [value, setValue] = useState(null);
  const options = [{value : 0, label: "aucune option"},{value : 1, label: "option 1"}, {value : 2, label: "option 2"}];

  useEffect(()=>{
    if(data.type == "select")setValue(options[data.value]);
    else setValue(data.value);
  },[data]);

  const save = () => {
    let val = value;
    if(val.label)val = val.value;
    if(val != data.value){
      data.newValue = val;
      onSave(data);
    }
    onClose();
  };

  
  return (
    <Modal isOpen={true} size="sm" toggle={onClose}>
      <ModalHeader>
        {data.name}
      </ModalHeader>
      <ModalBody>
        <h5>{data.title}</h5>
        <FormGroup>

          {data.type == "input" ? 
            <Input theme="grey"
              label={""}
              onChange={e => setValue(e.target.value)}
              value={value}
              type="text" /> : null}
          
          {data.type == "select" ? 
            <Select
              theme="grey" label={"participation"}
              options={options}
              value={value}
              onChange={e => setValue(e)}
            /> : null}

          {data.type == "checkbox" ? 
            <Checkbox
              label={"participation"}
              onChange={() => setValue(!value)}
              checked={value} /> : null}
          
          
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>Annuler</Button>
        <Button color="primary" onClick={save}>Enregistrer</Button>
      </ModalFooter>
    </Modal>
  );
};

ModalForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,

};

export default ModalForm;