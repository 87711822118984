import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment/dist/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDice } from "@fortawesome/free-solid-svg-icons";
import styles from "../../../../assets/scss/pages/reservation/userPlanning.module.scss";


const DisplayPlanningDay = ({ _data, uid, week }) => {
  let docu = [];

  for (let line = 0; line < 2; line++) {
    let ligne = [];
    for (let column = 0; column < 7; column++) {
      let _date = moment(week).startOf("week").add(column, "days");
      // Header Jours
      if (line == 0) {
        ligne.push(
          <td className={styles.headerDate}>
            <p>{_date.format("ddd")}</p>
            <p>{_date.format("DD")}</p>
          </td>
        );
      } else {
        let eventList = [];
        Object.values(_data).forEach((event) => {
          if (event.start) {
            if (moment(event.start.seconds * 1000).isSame(_date, "day")) {
              if(event?.reservation?.includes(uid)){
                eventList.push(
                  <div>
                    <div className={styles.hours}>
                      {moment(event.start.seconds * 1000).format("HH:mm")} -{" "}
                      {moment(event.end.seconds * 1000).format("HH:mm")}
                    </div>
                    <div className={styles.title}>{event.title}</div>
                  </div>
                );
              } 
            }
          }
        });

        ligne.push(
          <td className={styles.tablePlanning}>
            <div className={styles.eventList}>{eventList}</div>
          </td>
        );
      }
    }
    docu.push(<tr>{ligne}</tr>);
  }
  return docu;
};

const WeekReservationTable = ({ _data, uid, week }) => {    

  return (
    <div className={styles.tableContainer}>
      {_data && Object.values(_data).length > 0 ? 
          <table className={styles.planning}>
              <DisplayPlanningDay _data={_data} uid={uid} week={week}/>
          </table>
      : null}
      
    </div>
  );
};

WeekReservationTable.propTypes = {
  _data: PropTypes.object.isRequired,
  uid: PropTypes.string.isRequired,
  week: PropTypes.any,
};

export default WeekReservationTable;