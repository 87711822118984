import { ArrowLeft, ArrowRight, Printer, Columns } from "react-feather";
import { Button, Checkbox, Spinner } from "../../../../lib/components";
import React, { useEffect, useState } from "react";

import Logo from "assets/images/jabiLogo/MAJOR_DETOUR.png";
import MenuCard from "./components/_menuCard";
import moment from "moment";
import styles from "../../../../assets/scss/pages/menu.module.scss";
import useMenu from "../context";
import { usePrompt } from "lib/hooks/usePrompt";
import { useSidebar } from "lib/context/dashboard/sidebar.context";
import useUi from "hooks/ui.hook";
import { useHistory } from "react-router-dom";

// import menuCard from "assets/images/TutoVideo/menuCard.svg";

const cardColors = [
  "green",
  "purple",
  "red",
  "yellow",
  "purple",
  "red",
  "yellow",
];

const RenderRepas = () => {
  const [ui] = useUi();
  const [ctx, dispatch] = useMenu();
  const history = useHistory();

  const _handleClick = (repas) => {
    dispatch({ type: "setMeal", meal: repas });
    dispatch({ type: "changePage", page: 1 });
    // console.log(ctx.meal);
  };

  if (!ui || !ui.groupement || !ui.groupement.template)
    return (
      <div className={styles.noTemplate}>
        <img src={Logo} />
        <h1>Menu introuvable</h1>
        <p>
          {"Votre fonctionnalité d'édition de menu n'est pas encore activée"}
        </p>
        <p>
          {
            "Rapprochez vous de l'équipe Jabi afin de paramétrer votre menu personnalisé"
          }
        </p>
        <Button
          style={{ marginTop: 20 }}
          onClick={() => (window.location.href = "mailto:briac.perrin@jabi.fr")}
        >
          {"Prendre contact avec l'équipe Major"}
        </Button>
      </div>
    );

  return Object.keys(ui.groupement.template)
    .sort(
      (a, b) =>
        ui.groupement.template[a].heure - ui.groupement.template[b].heure
    )
    .map((_repas, _id) => (
      <div
        className={styles.card}
        key={_id}
        onClick={() => _handleClick(_repas)}
        style={
          ctx.meal === _repas
            ? {
              backgroundColor: "#52c5a5"
            }
            : { backgroundColor: "#EEE" }
        }
      >
        <span
          className={styles.mealTitle}
          style={ctx.meal === _repas ? { color: "#fff" } : { color: "#444" }}
        >
          {_repas}
        </span>
      </div>
    ));
};

const Menu = () => {
  const [ctx, dispatch] = useMenu();
  const [ui] = useUi();
  const [focused, setFocused] = useState(null);
  const [publication, setPublication] = useState(false);
  const [sidebar] = useSidebar();

  // console.log(sidebar);


  useEffect(() => {
    if (ctx.data && Object.values(ctx.data).length > 0) {
      if (Object.values(ctx.data)[0].published === true) {
        setPublication(true);
      }
      if (Object.values(ctx.data)[0].published === false || Object.values(ctx.data)[0].published === undefined) {
        setPublication(false);
      }
    }
  }, [ctx.data]);

  usePrompt(
    ctx.isSaved,
    "Les modifications que vous avez apportées au Menu ne sont pas enregistrées. Voulez vous quitter ?",
    true
  );

  const localConfirm = (callback) => {
    let reponse = true;
    if (!ctx.isSaved()) {
      reponse = confirm(
        "Les modifications que vous avez apportées au Menu ne sont pas enregistrées. Voulez vous quitter ?"
      );
    }

    if (reponse) {
      callback();
    }
  };

  if (ctx.week === null) return <Spinner />;
  if (ctx.state === null) return <Spinner />;
  if (ctx.loading) return <Spinner />;

  const _menus = ui.groupement.template && ctx.meal && ui.groupement.template[ctx.meal] && ui.groupement.template[ctx.meal].menus ?
    Object.keys(ui.groupement.template[ctx.meal].menus)
      .sort(
        (a, b) =>
          ui.groupement.template[ctx.meal].menus[a].weight -
          ui.groupement.template[ctx.meal].menus[b].weight
      ) : null;

  const _localWeek = ctx.meal === "Déjeuner" ? ctx.week.slice(0, 5) : ctx.week;

  return (
    <div style={{ width: "100%", minWidth: 1150 }}>
      <div className={styles.pageControl}>
        <Button
          color="primary"
          onClick={() =>
            history.go(0)
          }
        >
          Retour
        </Button>
        <>
          <Button color="primary" onClick={() => ctx.save(false)} disabled={ctx.isSaved()}>
            Enregistrer en brouillon
          </Button>
          <Button color="secondary" onClick={() => ctx.save(true)}>
            Publier dans toutes les résidences
          </Button>
        </>
      </div>
      <div className={styles.choseMenu}>
        <RenderRepas />
      </div>

      <div className={`${styles.container} ${sidebar ? styles.sidebarOpen : ""}`}>
        <div className={styles.contentContainer}>
          {_menus ?
            _menus.map((menu, id) => {
              return (
                <div key={id}>
                  <div className={styles.planningHeader}>
                    <ArrowLeft
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        localConfirm(() =>
                          dispatch({ type: "previousWeek" })
                        )
                      }
                    />
                    <span>
                      {moment(ctx.week[0]).format("ll")} -{" "}
                      {moment(ctx.week[6]).format("ll")}
                    </span>
                    <ArrowRight
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        localConfirm(() => dispatch({ type: "nextWeek" }))
                      }
                    />

                  </div>
                  {ui.groupement.template[ctx.meal].menus[menu].type ===
                    "daily" && id === 0 ? (
                    <div className={styles.planningDatesContainer}>
                      {ctx.week.map((day) => (
                        <div
                          className={styles.date}
                          key={moment(day).format("llll").split(" ")[0]}
                        >
                          <h6>
                            {moment(day).format("llll").split(" ")[0]}
                          </h6>
                          <h5>{moment(day).format("L").split("/")[0]}</h5>

                        </div>
                      ))}
                    </div>
                  ) :
                    <div className={styles.planningDatesContainer}>
                      {_localWeek.map((day) => (
                        <div
                          className={styles.date}
                          key={moment(day).format("llll").split(" ")[0]}
                        >
                          <h6>
                            {moment(day).format("llll").split(" ")[0]}
                          </h6>
                          <h5>{moment(day).format("L").split("/")[0]}</h5>

                        </div>
                      ))}
                    </div>}
                  <h5 style={{ fontSize: 25 }}>
                    <span
                      style={{
                        textTransform: "uppercase",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {menu}
                    </span>
                  </h5>

                  <div className={styles.planningBody}>
                    <div className={styles.menuSection}>
                      {ui.groupement.template[ctx.meal].menus[menu]
                        .type === "daily" ? (
                        <>
                          {_localWeek.map((day, id) => (
                            <div
                              className={`${styles.menuDay} ${focused === id ? styles.focused : ""
                                }`}
                              key={moment(day).format("llll").split(" ")[0]}
                            >
                              <MenuCard
                                color={cardColors[id]}
                                date={moment(day).format("YYYY-MM-DD")}
                                origin={menu}
                              />
                            </div>
                          ))
                          }
                          {ctx.meal === "Déjeuner" && id === 0 ?
                            <>
                              <div
                                className={`${styles.menuDay} ${focused === id ? styles.focused : ""
                                  }`}
                              >
                                <MenuCard
                                  color={cardColors[1]}
                                  date={moment(ctx.week[5]).format("YYYY-MM-DD")}
                                  origin={_menus[1]}
                                />
                              </div>
                              <div
                                className={`${styles.menuDay} ${focused === id ? styles.focused : ""
                                  }`}
                              >
                                 <MenuCard
                                color={cardColors[2]}
                                date={moment(ctx.week[6]).format("YYYY-MM-DD")}
                                origin={_menus[1]}
                              />
                              </div>
                             
                            </>
                            : null}

                        </>


                      ) : ui.groupement.template[ctx.meal].menus[menu]
                        .type === "weekly" ? (
                        <>
                          <div className={styles.menuSection}>
                            <MenuCard
                              color={cardColors[0]}
                              date={moment(ctx.week[0]).format("YYYY-MM-DD")}
                              origin={menu}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })
            : null}
        </div>

        {/*<span key={id} className={`${styles.control} ${ctx.menu === menu ? styles.active : ""}`}
              onClick={() => localConfirm(() => dispatch({ type: "setMenu", menu: menu }))}>{menu}</span>*/}
      </div>
    </div>
  );
};

export default Menu;
