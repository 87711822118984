import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";


const mapStore = create(subscribeWithSelector((set) => ({
    lat: 0,
    lng: 0,
    zoom: 0,
    mapRef: null,
    platform: null,
    service: null,
    mapUi: null,
    group: null,
    groupList: {},

    //markers
    basicMarkerList: [],
    bubbleMarkerList: [],

    // setters
    setLat: (lat) => set({ lat }),
    setLng: (lng) => set({ lng }),
    setZoom: (zoom) => set({ zoom }),
    setMapRef: (mapRef) => set({ mapRef }),
    setPlatform: (platform) => set({ platform }),
    setService: (service) => set({ service }),
    setMapUi: (mapUi) => set({ mapUi }),
    setGroup: (group) => set({ group }),
    setGroupList: (groupList) => set({ groupList }),

    // markers setters
    setBasicMarkerList: (basicMarkerList) => set({ basicMarkerList }),
    setBubbleMarkerList: (bubbleMarkerList) => set({ bubbleMarkerList }),


    // adders
    addToGroupList: (key, value) => set(state => ({
        groupList: { ...state.groupList, [key]: value }
    })),
    addToBasicMarkerList: (marker) => set(state => ({
        basicMarkerList: [...state.basicMarkerList, marker]
    })),
    addToBubbleMarkerList: (marker) => set(state => ({
        bubbleMarkerList: [...state.bubbleMarkerList, marker]
    })),

})));


export default mapStore;