import React, {useState} from "react";
import {Button} from "lib/components";
import firebase from "firebase";
import { getAllGroupements } from "services/ressources/groupement";
import { getAllEtablishments } from "services/ressources/establishment";
import { getAllUsers } from "services/ressources/user";
import { updateUserById } from "services/ressources/user";
import moment from "moment";
const firestore = firebase.firestore;

const UserBirthday = () => {
  const [usersToUpdate, setUsersToUpdate] = useState([]);
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);

  const migrate = async () => {
    setReport([]);
    setLoading(true);

    //test Etab -> Groupement liaison
    let _users = await getAllUsers();

    _users.forEach(user => {
      if (user.birthdate && ["senior", "seniorTotem"].includes(user.role) && !user.birthday) {
        user.birthday = moment(user.birthdate.toDate()).format("MM-DD") ?? null;
        setUsersToUpdate(prev => [...prev, user]);
        setReport(prev => [...prev, `<span style="color: green;">user ${user.name ?? "No Name"} ${user.surname ?? "No Surname"} <span style="color: blue;">${user.birthdate} => ${user.birthday}</span>: OK</span>`]);
      }
    });
    usersToUpdate.forEach(user => ( async () => {
      await updateUserById({id: user.uid, data: user});
    })());
    setLoading(false);
  };

  return (
    <div style={{padding: 20}}>
      <span>Créer la date d&apos;anniversaire de tous les utilisateurs sénior et séniorTotem avec un format MM-DD </span>
      <Button onClick={migrate} loading={loading}>Start</Button>
      {
        report.map((r, i) => (
          <p key={i} dangerouslySetInnerHTML={{__html: r}} />
        ))
      }
    </div>
  );
};

export default UserBirthday;