import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Input, Select} from "lib/components";
import CompactImagePicker from "lib/components/Form/CompactImagePicker";

const EditorSidebar = (props) => {
  const {question, onChange, setImageLoaderForm} = props;

  const [answer, setAnswer] = useState(null);
  const [questionText, setQuestionText] = useState("");
  const [media, setMedia] = useState(null);
  const [mediaType, setMediaType] = useState(null);

  useEffect(() => {
    setAnswer(typeof(question.answer) === "boolean" ? question.answer : null);
    setQuestionText(question.title ? question.title : "");
    setMedia(question.media ? question.media : null);
    setMediaType(question.mediaType ? question.mediaType : null);
  }, [question]);

  useEffect(() => {
    onChange({
      ...question,
      title: questionText,
      answer: answer,
      media: media,
      mediaType: media ? "image" : null
    });
  }, [answer, questionText, media, mediaType]);

  return (
    <div>
      <Input value={questionText}
        onChange={e => setQuestionText(e.target.value)}
        placeholder="Question"
        style={{marginBottom: 10}} />

      <Select value={answer === null  ? null :
        answer === true  ? {label: "Vrai", value: true} :
          answer === false ? {label: "Faux", value: false} : null}
      onChange={e => setAnswer(e ? e.value : null)}
      placeholder="réponse"
      options={[
        {label: "Vrai", value: true},
        {label: "Faux", value: false}
      ]}
      />
      <CompactImagePicker 
        value={media}
        onChange={e => setMedia(e)}
        setImageLoaderForm={setImageLoaderForm}
      />
    </div>
  );
};

EditorSidebar.propTypes = {
  question: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  setImageLoaderForm: PropTypes.func.isRequired,
};

export default EditorSidebar;