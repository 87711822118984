
import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
    Container,
    Row,
    Col,
    Input,
    Card, CardHeader, CardBody, CardFooter,
    Button,
    Separator,
    Modal, ModalHeader, ModalBody,
    Icon
} from "lib/components";
import { toast } from "react-toastify";
import moment from "moment";

import { Gift, MoreVertical, Tool, Calendar, Settings, ChevronLeft, ChevronRight } from "react-feather";
import styles from "../Scss/tableau.module.scss";
import firebase from "firebase";
import ConciergeBellIcon from "../../../../lib/assets/svg/ConciergeBell.svg";
import ConciergeBellGreen from "../../../../lib/assets/svg/ConciergeBellGreen.svg";
import Utensils from "../../../../lib/assets/svg/Utensils.svg";
import UtensilsBlue from "../../../../lib/assets/svg/UtensilsBlue.svg";
import useUI from "hooks/ui.hook";
import { getNotificationsForEtab_rt, updateNotificationBatch, updateNotificationById } from "services/ressources/notification";
import { getUserById } from "services/ressources/user";
import notificationsStore from "store/notificationsStore";
import { ModalFooter } from "reactstrap";
import ControlNotifModal from "../Components/ControlNotifModal";
import InformationNotifModal from "../Components/InformationNotifModal";
import { colorByCate, colorByCateLessClear } from "../Scss/colors";
import logo from "../../../../assets/images/jabiLogo/LOGO_FAVICON.png";
import FiltreButton from "../Components/FiltreButton";
import stylesBanner from "../Scss/notifBanner.module.scss";

const firestore = firebase.firestore();

const centerOfNotif = () => {
    const [ui, uiReducer] = useUI();
    const DateRef = useRef(null);

    const [type, setType] = useState("all"); //"service","animation",...
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [search, setSearch] = useState("");
    const [notifBrutes, setNotifBrutes] = useState([]);
    const [notificationsCategory, setNotificationsCategory] = useState([]); // TOUTES les notifications affiché dans une catégorie (toutes,service...)
    const [notificationsSearch, setNotificationsSearch] = useState([]); // les notifFiltered restantes apres recherche
    const [infoModal, setInfoModal] = useState(null);
    const notifications = notificationsStore((state) => state.notifications);
    const notificationRequired = notificationsStore((state) => state.notificationRequired);
    const nombreNotif = notificationsStore((state) => state.nombreNotif);
    const [toRefresh, setToRefresh] = useState(false);
    const [filtreNotifs, setFiltreNotifs] = useState({ nonLues: false, importantes: false, add: true, delete: true, update: true });
    const [currentStickyDate,setCurrentStickyDate] = useState();
    const [numberPage,setNumberPage] = useState(1);
    const setPermission = notificationsStore((state) => state.setPermission);

    let previousDate = null; //pour l'affichage des jours 
    const sortedNotifications = notifications.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
    const nbNotifPerPage = 20;
    const startIndex = (numberPage - 1) * nbNotifPerPage;
    const endIndex = startIndex + nbNotifPerPage;
    useEffect(() => {
        if (notificationsSearch.length > 0) {
            const currentDate = new Date(notificationsSearch[0].createdAt.seconds * 1000).toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit", year: "numeric" });
            setCurrentStickyDate(currentDate);
        }
    }, [notificationsSearch]);

    useEffect(() => {
        const notif = sortedNotifications.filter(notification => notification.data);

        const NotifWithTitleAndBody = async () => {
            const updatedNotifications = await Promise.all(notif.map(async (notif) => {
                const user = notif.data ? await getUserById({ id: notif.data.userId }) : undefined;
                const dateObject = new Date(notif.data?.date?.seconds * 1000);
                const date = dateObject.toLocaleDateString("fr-FR", { day: "2-digit", month: "short" });
                const { title, body } = generateNotificationTitle(notif, user, date);
                return { ...notif, title, body };
            }));
            setNotifBrutes(updatedNotifications);
            setNotificationsCategory(updatedNotifications);
            setNotificationsSearch(updatedNotifications);
            
            const searchRefresh = search;
            const filtreNotifRefresh = filtreNotifs;
            if (filtreNotifRefresh != { nonLues: false, importantes: false, add: true, delete: true, update: true } || searchRefresh !== "") {
                setToRefresh(true);

            }
        };
        // notifWindows();
        NotifWithTitleAndBody();
         // init notifications :
         if (!("Notification" in window)) {
            console.log("This browser does not support notifications.");
            return;
        }

        Notification.requestPermission().then((permission) => {
            setPermission(permission);
            if (permission != "granted") console.log("notifications non activées");
        });
    }, [notifications, ui.user.uid]);



    useEffect(() => {
        //si on change de type de notif on remet les filtre comme au départ
        setFiltreNotifs({ nonLues: false, importantes: false, add: true, delete: true, update: true });
        setNumberPage(1);
    }, [type]);

    const _markRead = async (notif) => {
        const notifToHandle = notif.type === "birthday" ? notificationRequired.birthday : notificationRequired[notif.type]?.[notif.action];
        if (notifToHandle) {
            await updateNotificationById({ ui, id: notif.uid, data: { seenBy: firebase.firestore.FieldValue.arrayUnion(ui.user.uid) } });
        }
    };


    const generateNotificationTitle = (notif, user, date) => {
        let titleCategoryAction ={};
        let bodyCategoryAction={};
        const start= new Date(notif.data?.date?.seconds * 1000).toLocaleTimeString("fr-FR", {hour:"numeric",minute:"numeric"});
        const end = new Date(notif.data?.end?.seconds * 1000).toLocaleTimeString("fr-FR", {hour:"numeric",minute:"numeric"});
        let userName= user?.name + " " + user?.surname;
            titleCategoryAction = {
                animation: {
                    delete: `Annulation d'une participation à l'évènement ${notif.data?.name} le ${date}.`,
                    update: `Modification d'une participation à l'évènement ${notif.data?.name} le ${date}.`,
                    add: `Nouvelle participation à l'évènement ${notif.data?.name} le ${date}.`,
                },
                service: {
                    delete: `Annulation d'une réservation pour ${notif.data?.name} le ${date}.`,
                    update: `Modification d'une réservation pour ${notif.data?.name} le ${date}.`,
                    add: `Nouvelle réservation pour ${notif.data?.name} le ${date}.`,
                },
                menu: {
                    delete: `Annulation d'une réservation pour le ${notif.data?.name} du ${date}.`,
                    update: `Modification d'une réservation pour le ${notif.data?.name} du ${date}.`,
                    add: `Nouvelle réservation pour le ${notif.data?.name} du ${date}.`,
                },
                intervention: {
                    delete: `Une intervention de type ${notif.data?.category} a été supprimée.`,
                    update: `Une intervention de type ${notif.data?.category} a été modifiée.`,
                    add: `Nouvelle intervention de type ${notif.data?.category} demandée.`
                },
                birthday: "Nous souhaitons un anniversaire aujourd'hui !",
            };
            bodyCategoryAction = {
                animation: {
                    delete: `Pour ${userName}.`,
                    update: `Pour ${userName}.`,
                    add: `Pour ${userName}.`,
                },
                service: {
                    delete: `Pour ${userName}.`,
                    update: `Pour ${userName}.`,
                    add: `Pour ${userName}.`,
                },
                menu: {
                    delete: `Pour ${userName}.`,
                    update: `Pour ${userName}.`,
                    add: `Pour ${userName}.`,
                },
                intervention: {
                    delete: `Pour ${userName}.`,
                    update: `Pour ${userName}.`,
                    add: `Pour ${userName}.`,
                },
                birthday: `C'est l'anniversaire de ${userName}.`,
            };
            if(notif.type === "birthday") return { title: titleCategoryAction[notif.type], body: bodyCategoryAction[notif.type] } || "Pas d'informations sur cette notification";
            else return { title: titleCategoryAction[notif.type]?.[notif.action], body: bodyCategoryAction[notif.type]?.[notif.action] } || "Pas d'informations sur cette notification";
    };


    const updateNotifications = (notifications, userUid) => {
        return notifications.map((notification) => {
            if (!notification.seenBy.includes(userUid)) {
                const { title, body, ...rest } = notification;
                return {
                    ...rest,
                    seenBy: [...notification.seenBy, userUid],
                };
            }
            return notification;
        });
    };

    const _AllMarkRead = async () => {
        const notifToUpdate = updateNotifications(notificationsSearch.slice(startIndex,endIndex), ui.user.uid);
        await updateNotificationBatch({ ui, notif: notifToUpdate });

    };

    //Modifie la liste des notificationsCategory en fonction du type choisi
    const changeCateogrieOfNotification = (typeToDisplay) => {
        setType(typeToDisplay);
        let notifToHandle = notifBrutes;
        if (typeToDisplay !== "all") {
            notifToHandle = notifBrutes.filter((notif) => {
                return notif.type === typeToDisplay;
            });
        }
        //on remet les filtres à ceux d'origine qd on change de page
        setNotificationsCategory(notifToHandle);
    };

    const handleToggle = (key) => {

        setFiltreNotifs((prev) => ({
            ...prev,
            [key]: !prev[key]
        }));

    };

    useEffect(() => {

        let newNotifFiltre = notificationsCategory;

        //filtrage
        if (!filtreNotifs.add) {
            newNotifFiltre = newNotifFiltre.filter(notification => notification.action !== "add");
        }
        if (!filtreNotifs.delete) {
            newNotifFiltre = newNotifFiltre.filter(notification => notification.action !== "delete");
        }
        if (!filtreNotifs.update) {
            newNotifFiltre = newNotifFiltre.filter(notification => notification.action !== "update");
        }
        if (filtreNotifs.nonLues) {
            newNotifFiltre = newNotifFiltre.filter(notification => {
                const notifToShow = notification.type === "birthday" ? notificationRequired.birthday : notificationRequired[notification.type]?.[notification.action];
                return !notification.seenBy.includes(ui.user.uid) && notifToShow;
            });
        }
        if (filtreNotifs.importantes) {
            //StandBy filtre des notifs importantes
        }

        //recherche
        const searchNotifications = newNotifFiltre.filter((notif) =>
            notif.title?.toLowerCase().includes(search.toLowerCase()) || notif.body?.toLowerCase().includes(search.toLowerCase())
        );
        // setNotificationsFiltered(newNotifFiltre);
        setNotificationsSearch(searchNotifications);
        setToRefresh(false);
    }, [filtreNotifs, search, toRefresh]);


    const handleMorePage= () =>{
        if (numberPage < Math.ceil(notificationsSearch.length / nbNotifPerPage)) {
            setNumberPage(numberPage + 1);
        }
    };
    const handleLessPage= () =>{        
        if(numberPage>1)setNumberPage(numberPage-1);
    };

    return (
        <Container style={{ width: "100%", height: "95%" }} >
            <Card style={{ height: "88vh", overflowY: "clip", overflowX: "hidden" }}>
                <CardHeader className={styles.cardHeader}>
                    <h5>Centre de notification</h5>
                    <Button style={{ gap: "5px" }} onClick={() => setIsModalVisible(true)}>
                        <Settings></Settings>
                        <span>Gérer les notifications</span>

                    </Button>
                </CardHeader>
                <CardBody style={{ height: "96%", overflowY: "hidden", width: "100%",display: "flex",flexDirection: "column" }}>
                    <div className={styles.header}>
                        <div className={`${styles.button} ${type === "all" ? styles.active : ""}`} onClick={() => changeCateogrieOfNotification("all")}>
                            <div className={styles.container} style={{ backgroundColor: colorByCate.all.primary }}>
                                <span style={{ marginInline: "10px" }}>Toutes</span>
                                {nombreNotif["all"] !== 0 ? <div className={styles.notifRound}>
                                    {nombreNotif["all"]}
                                </div> : null}
                            </div>
                        </div>
                        {ui.establishment.features.includes("reservationPlanning") || ui.establishment.features.includes("animationPlanning")  ? <div className={`${styles.button} ${type === "animation" ? styles.active : ""}`} onClick={() => changeCateogrieOfNotification("animation")}>
                            <div className={styles.container} style={{ backgroundColor: colorByCate.animation.primary }}>
                                <Calendar size={16} style={{ marginBottom: "2px" }}></Calendar>
                                <span style={{ marginInline: "10px" }}>Animation</span>
                                <div className={styles.notifRound} >
                                    {nombreNotif["animation"]}
                                </div>
                            </div>
                        </div>:null}
                        {ui.establishment.features.includes("services") ? <div className={`${styles.button} ${type === "service" ? styles.active : ""}`} onClick={() => changeCateogrieOfNotification("service")}>
                            <div className={styles.container} style={{ backgroundColor: colorByCate.service.secondary }}>
                            <img src ={ConciergeBellIcon} style={{ width: "17px", height: "17px", stroke: "#300438" }} ></img>

                                {/* <ConciergeBellIcon style={{ width: "17px", height: "17px", color: "#300438" }} /> */}
                                <span style={{ marginInline: "10px" }}>Service</span>
                                <div className={styles.notifRound}>
                                    {nombreNotif["service"]}
                                </div>

                            </div>
                        </div>:null}
                        {ui.establishment.features.includes("menuPlanning") || ui.establishment.features.includes("reservationMenu") ? <div className={`${styles.button} ${type === "menu" ? styles.active : ""}`} onClick={() => changeCateogrieOfNotification("menu")}>
                            <div style={{ backgroundColor: colorByCate.menu.primary }} className={styles.container}>
                            <img src ={Utensils} color="#300438"  style={{ width: "17px", height: "17px", stroke: "#300438" }} ></img>

                                {/* <Utensils style={{ width: "17px", height: "17px", stroke: "#300438" }} /> */}
                                <span style={{ marginInline: "10px" }}>Menu</span>
                                <div className={styles.notifRound}>
                                    {nombreNotif["menu"]}
                                </div>

                            </div>
                        </div>:null}
                        {ui.establishment.features.includes("interventionsRequest") ?<div className={`${styles.button} ${type === "intervention" ? styles.active : ""}`} onClick={() => changeCateogrieOfNotification("intervention")}>
                            <div className={styles.container} style={{ backgroundColor: colorByCate.intervention.primary }}>
                                <Icon icon={Tool} size="16"></Icon>
                                <span style={{ marginInline: "10px" }}>Intervention</span>
                                <div className={styles.notifRound}>
                                    {nombreNotif["intervention"]}
                                </div>

                            </div>
                        </div>:null}
                        <div className={`${styles.button} ${type === "birthday" ? styles.active : ""}`} onClick={() => changeCateogrieOfNotification("birthday")}>
                            <div className={styles.container} style={{ backgroundColor: colorByCate.birthday.primary }}>
                                <Icon icon={Gift} size="15"></Icon>
                                <span style={{ marginInline: "10px" }}>Anniversaire</span>
                                <div className={styles.notifRound}>
                                    {nombreNotif["birthday"]}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            Recherche :
                            <Input
                                placeholder="par titre, par senior..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <Button style={{ marginLeft: "30px" }} color="secondary" onClick={() => { _AllMarkRead(); }}>Tout marquer comme lu</Button>
                        
                        </div>
                        <div className={styles.filtreContainer}>
                            <span>Filtrer les résultats par : </span>
                            <FiltreButton
                                title="Seulement les non lues"
                                color={colorByCate[type].secondary}
                                isVisibleInfo={filtreNotifs.nonLues}
                                toggleVisibility={() => handleToggle("nonLues")}
                            />
                            {/* <FiltreButton
                                title="Importantes"
                                color={colorByCate[type].secondary}
                                isVisibleInfo={filtreNotifs.importantes}
                                toggleVisibility={() => handleToggle("importantes")}
                            /> */}
                            {type !=="birthday"?<FiltreButton
                                title="Réservation/Ajout"
                                color={colorByCate[type].secondary}
                                isVisibleInfo={filtreNotifs.add}
                                toggleVisibility={() => handleToggle("add")}
                            />:null}
                             {type !=="birthday"?<FiltreButton
                                title="Suppression"
                                color={colorByCate[type].secondary}
                                isVisibleInfo={filtreNotifs.delete}
                                toggleVisibility={() => handleToggle("delete")}
                            />:null}
                             {type !=="birthday" && type !=="animation"?<FiltreButton
                                title="Modification"
                                color={colorByCate[type].secondary}
                                isVisibleInfo={filtreNotifs.update}
                                toggleVisibility={() => handleToggle("update")}
                            />:null}

                        </div>
                    </div>
                    {/* <div className={styles.texteDate} style={{marginBottom:"0px"}}>
                        {currentStickyDate}
                    </div> */}
                    <div style={{flex:1, display: "flex", flexDirection: "column", gap: "10px", overflowY: "auto" }}>
                                             
                        {/* <div style={{ display: "flex", flexDirection: "column", gap: "10px", height: "80vh", overflowY: "auto" }}> */}
                        {notificationsSearch.slice(startIndex,endIndex).map((notif, index) => {
                            if (type === "all" || type === notif.type) {
                                const currentDate = new Date(notif.createdAt.seconds * 1000).toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit", year: "numeric" });
                                const isNewDay = currentDate !== previousDate;

                                if (isNewDay) {
                                    previousDate = currentDate;
                                    const isToday = currentDate === new Date().toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit", year: "numeric" });
                                    return (
                                        <div key={index}>
                                            <div className={styles.texteDate} ref={DateRef}>
                                                {isToday ? "Aujourd'hui - " + currentDate : currentDate}
                                            </div>
                                            <NotificationBanner key={index} notifData={notif} setInfoModal={setInfoModal} onClick={() => { _markRead(notif); }} search={search} />
                                        </div>
                                    );
                                } else {
                                    previousDate = currentDate;
                                    return (
                                        <div key={index}>
                                            <NotificationBanner key={index} notifData={notif} setInfoModal={setInfoModal} onClick={() => { _markRead(notif);  }} search={search} />
                                        </div>
                                    );
                                }

                            } else {
                                return null;
                            }
                        })}
                        
                    </div> 
                    <div style={{display:"flex",justifyContent:"center",paddingBottom:"20px",marginTop:"10px",fontSize:"20px",alignItems:"center"}}> 
                        <ChevronLeft size="30" opacity={numberPage>1?1:0.3} style={{cursor:"pointer"}} onClick={handleLessPage}/>
                        <span>{"Page "+numberPage}</span>
                        <ChevronRight size="30" opacity={numberPage < Math.ceil(notificationsSearch.length / nbNotifPerPage)?1:0.3}  style={{cursor:"pointer"}}  onClick={handleMorePage}/>
                    </div>
                </CardBody>
                <CardFooter>
                </CardFooter>
            </Card>
            <ControlNotifModal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)}></ControlNotifModal>
            <InformationNotifModal isOpen={infoModal} onClose={() => { setInfoModal(null); }} eventOpen={infoModal}></InformationNotifModal>
        </Container>
    );
};

const NotificationBanner = ({ notifData, setInfoModal, onClick, search }) => {
    const [ui, uiReducer] = useUI();
    const notificationRequired = notificationsStore((state) => state.notificationRequired);


    const highlightText = (text, search) => {
        if (!search) return text;
        const regex = new RegExp(`(${search})`, "gi");
        return text.replace(regex, "<strong>$1</strong>");
    };

    if (notifData.data) {
        const secondaryColor = colorByCate[notifData.type]?.secondary ? colorByCate[notifData.type]?.secondary : "#DBDADA";
        const provenance = notifData?.source && notifData?.source === "major-connect-web" ? "L'App Web" : notifData?.source && notifData?.source === "major-totem" ? "La Borne" : notifData?.source && notifData?.source === "major-connect" ? "L'App Mobile" :notifData?.source && notifData?.source === "major" ?"Major": "";
        const createdAt = notifData?.createdAt.toDate().toLocaleTimeString("fr-FR",{hour:"numeric",minute:"numeric"});
        const notifToShow = notifData.type === "birthday" ? notificationRequired.birthday : notificationRequired[notifData.type]?.[notifData.action];
        return (
            <div onClick={onClick} className={stylesBanner.containerGlobal} style={{ cursor: !notifData.seenBy.includes(ui.user.uid) ? "pointer" : "default" }}>
                {/* Indication d'une notification non lue*/}
                {!notifData.seenBy.includes(ui.user.uid) && notifToShow ? (
                    <div className={stylesBanner.notifPastille}></div>
                ) : null}
                {/* La notification */}
                <div className={stylesBanner.containerNotif}>
                    <div style={{ backgroundColor: secondaryColor }} className={stylesBanner.iconCube}>
                        {notifData.type === "animation" && (<Calendar size={40} color={colorByCate.animation.primary} />)}
                        {notifData.type === "service" && (
                        <img src ={ConciergeBellGreen}  style={{ width: "40px", height: "40px" }} ></img>

                        // <ConciergeBellIcon style={{ width: "40px", height: "40px", stroke: "#99E36E" }} />
                        )}
                        {notifData.type === "menu" && (
                        <img src ={UtensilsBlue} color="#5CADDB"  style={{ width: "40px", height: "40px", stroke: "#5CADDB" }}></img>

                        // <Utensils style={{ width: "40px", height: "40px", stroke: "#5CADDB" }} />
                        )}
                        {notifData.type === "intervention" && (<Tool size={40} color={colorByCate.intervention.primary} />)}
                        {notifData.type === "birthday" && (<Gift size={40} color="#DF7189"></Gift>)}
                    </div>
                    <div className={stylesBanner.textContainer}>
                        <span className={stylesBanner.title} dangerouslySetInnerHTML={{ __html: highlightText(notifData?.title, search) }}></span>
                        <span className={stylesBanner.body} dangerouslySetInnerHTML={{ __html: highlightText(notifData?.body, search) }}></span>
                    </div>
                   
                    {provenance !== "" ? <div className={stylesBanner.provenanceContainer}>
                        <span className={stylesBanner.titleProvenance}>{"Depuis"}</span>
                        <div className={stylesBanner.bodyProvenance}>
                            {provenance.split(" ").map((word, index) => (
                                <div key={index}>
                                    {word + "\n"}
                                </div>
                            ))}
                        </div>
                    </div>: <div className={stylesBanner.provenanceContainer}></div>}
                    {createdAt?<div className={stylesBanner.provenanceContainer}>
                        <span className={stylesBanner.titleProvenance}>{"À"}</span>
                        <div className={stylesBanner.bodyProvenance}>
                           {createdAt}
                        </div>
                    </div>: null}
                    <div className={stylesBanner.moreVertical}
                        onClick={(e) => { e.stopPropagation(); setInfoModal(notifData); }}
                    >
                        <MoreVertical />
                    </div>

                </div>

            </div>
        );
    }
    else return (<></>);
};

NotificationBanner.propTypes = {
    notifData: PropTypes.any,
    setInfoModal: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    search: PropTypes.string,
};


export default centerOfNotif;