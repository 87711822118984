import {
  pdf,
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import PropTypes from "prop-types";
import React from "react";
import moment from "moment";

const styles = StyleSheet.create({
  mainContainer: {
    margin: 6,
    //borderColor: "#D26F47",
    justifyContent: "space-around"
  },
  title: {
    fontSize: 22,
    textAlign: "center",
    //fontFamily: "TTNorms",
    color: "#430f53",
  },
  titleContainer: {
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 10
  },
  weekContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  fromto: {
    //fontFamily: "TTNorms",
    fontSize: 15,
  },
  table: {
    width: "100%",
    display: "flex",
    flexDirection: "line",
  },
  column: {
    display: "flex",
    flexDirection: "row",
    marginVertical: 1,
  },
  cell: {
    flex: 4,
    justifyContent: "flex-start",
    margin: 2,
    minHeight: "9vh"
  },
  repas: {
    textAlign: "center",
    fontSize: 12,
    width: "100%",
  },
  choice: {
    width: "95%",
    marginHorizontal: "2.5%",
    // height: "100%",
    justifyContent: "space-around",
  },
  text: {
    width: "95%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginVertical: 2,
    maxHeight: 30,
    fontSize: 10,
  },
  listObject: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  listIndicator: {
    marginRight: 8,
    fontSize: 12,
  }
});

const WeekPdf = (props) => {
  const { data, uid, name, week, template } = props;

  const orderdRepas = Object.keys(template).sort((a, b) => template[a].heure - template[b].heure);

  const nbLines = Object.keys(template).length;

  let docu = [];
  for (let line = 0; line < 8; line++) {
    let ligne = [];
    for (let column = 0; column < nbLines + 1; column++) {
      let _date = week ? moment(week).startOf("week").add(line - 1, "days") : moment().startOf("week").add(line - 1, "days");
      // Header Jours 
      if (column == 0) {
        if (line > 0) {
          ligne.push(<View key={column + " " + line} style={[styles.cell, { flex: 1, alignItems: "center", justifyContent: "center" }]}><Text style={{ color: "#430f53" }}>{_date.format("ddd")}</Text><Text style={{ color: "#430f53" }}>{_date.format("DD")}</Text></View>);
        } else {
          ligne.push(<View key={column + " " + line} style={[styles.cell, { flex: 1, minHeight: 30 }]}></View>);
        }
      } else {
        if (line > 0) { // affichage des menus
          let period = orderdRepas[column - 1];
          if (data[_date.format("YYYY-MM-DD")]?.reservation && data[_date.format("YYYY-MM-DD")]?.reservation[uid] != undefined && data[_date.format("YYYY-MM-DD")].reservation[uid][period] != undefined && data[_date.format("YYYY-MM-DD")].reservation[uid][period].length > 0) {
            const element = data[_date.format("YYYY-MM-DD")];
            const resa = data[_date.format("YYYY-MM-DD")].reservation[uid][period][0];
            let choix = [];
            template[period].categories.forEach((plat) => {
              //gestion des multi garnitures
              if (plat && resa[plat] && Array.isArray(resa[plat])) {
                let _name = "";
                resa[plat].forEach((_val, _indx) => {
                  if (element && element[period] && element[period][plat] && element[period][plat][_val] && element[period][plat][_val].name) {
                    if (_indx > 0)
                      _name += ", ";

                    _name += element[period][plat][_val].name;
                  }
                });
                if (_name != "")
                  choix.push(_name);
              } else {
                if (plat && resa[plat] && element && element[period] && element[period][plat] && element[period][plat][resa[plat]] && element[period][plat][resa[plat]].name)
                  choix.push(element[period][plat][resa[plat]].name);
              }

            });

            ligne.push(<View key={column + " " + line} style={[styles.cell, { backgroundColor: column % 2 == 0 ? "#cae7de" : "#c7b7ca" }]}>
              <View style={styles.choice}>
                {
                  choix.map(nom => {
                    return <View key={nom} style={styles.listObject}>
                      <Text style={styles.listIndicator}>•</Text>
                      <Text style={styles.text}>{nom}</Text>
                    </View>;
                  })
                }
              </View>
            </View>);
          } else {
            ligne.push(<View key={column + " " + line} style={[styles.cell, { backgroundColor: column % 2 == 0 ? "#cae7de" : "#c7b7ca" }]}></View>);
          }

        } else { // affichage des header Verticaux
          if (line == 0) {
            ligne.push(<View key={column + " " + line} style={[styles.cell, { borderWidth: 1, minHeight: "auto", justifyContent: "center", borderColor: column % 2 == 0 ? "#2f8f70" : "#430f53" }]}>
              <Text style={[styles.repas, { color: column % 2 == 0 ? "#2f8f70" : "#430f53" }]}>
                {orderdRepas[column - 1].toUpperCase()}
              </Text>
            </View>);
          }
        }
      }
    }
    docu.push(<View key={line} style={styles.column}>{ligne}</View>);
  }

  return (
    <Document>
      <Page size="A4" orientation="portrait">
        <View style={styles.mainContainer}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>Réservations de {name}</Text>
          </View>
          <View style={styles.weekContainer}>
            <Text style={styles.fromto}>
              Semaine du {moment(week).startOf("week").format("DD MMMM YYYY")} au{" "}
              {moment(week).endOf("week").format("DD MMMM YYYY")}
            </Text>
          </View>

          <View style={styles.table}>
            {docu}
          </View>


        </View>
      </Page>
    </Document>
  );
};

WeekPdf.propTypes = {
  data: PropTypes.object.isRequired,
  uid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  week: PropTypes.any.isRequired,
  template: PropTypes.any.isRequired,
};

export default WeekPdf;
