import {useState, useEffect} from "react";

const useFirebaseDocumentOnce = (path, defaultValue) => {
  const [data, setData] = useState(defaultValue ?? []);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let res = await path.get();
        res = {uid: res.id, ...res.data()};
        setData(res);
        setLoading(false);
      } catch (e) {
        setError(e.message);
        console.error(e);
      }
    })();
  }, [path]);

  const update = async (data) => {
    try {
      await path.update({
        ...data,
        updatedAt : new Date()
      });
    } catch (e) {
      setError(e.message);
      console.error(e);
    }
  };
  const set = async (data) => {
    try {
      await path.set({
        ...data,
        updatedAt : new Date()
      });
    } catch (e) {
      setError(e.message);
      console.error(e);
    }
  };
  const remove = async () => {
    try {
      await path.delete();
    } catch (e) {
      setError(e.message);
      console.error(e);
    }
  };
  return {data, error, loading, update, set, remove};
};

export default useFirebaseDocumentOnce;