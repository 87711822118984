import React from "react";
import Basic_TableauResa from "./Basic/Components/TableauResa";
import Basic_PrestataireCard from "../Basic/Components/PrestataireCard";
import Basic_ButtonAddPrestataire from "./Basic/Components/ButtonAddPrestataire";
import Basic_CreatePrestationModal from "./Basic/Components/createPrestationModal";
import Basic_disponibilityModal from "./Basic/Components/disponibilityModal";
import Basic_CategoriesCrudService from "./Basic/Components/categoriesCrudService";
import Basic_ReservationModal from "./Basic/Components/reservationModal";
import Basic_HoraireChooser from "./Basic/Components/horaireChooser";
import Basic_TableauUneDispo from "./Basic/Components/TableauUneDispo";
import Basic_ToInformChangesModal from "./Basic/Components/ToInformChangesModal";
import Basic_LittlePrestataireCard from "./Basic/Components/littlePrestataireCard";
const TableauResa = (props) => {
    return <Basic_TableauResa {...props} />;
};
const PrestataireCard = (props) => {
    return <Basic_PrestataireCard {...props} />;
};
const ButtonAddPrestataire = (props) => {
    return <Basic_ButtonAddPrestataire {...props} />;
};
const DisponibilityModal=(props)=>{
    return <Basic_disponibilityModal{...props}/>;
};
const CreatePrestationModal=(props)=>{
    return <Basic_CreatePrestationModal{...props}/>;
};
const CategoriesCrudService=(props)=>{
    return <Basic_CategoriesCrudService{...props}/>;
};
const ReservationModal=(props)=>{
    return <Basic_ReservationModal{...props}/>;
};

const HoraireChooser=(props)=>{
    return <Basic_HoraireChooser{...props}/>;
};
const TableauUneDispo=(props)=>{
    return <Basic_TableauUneDispo{...props}/>;
};
 const ToInformChangesModal=(props)=>{
 return <Basic_ToInformChangesModal{...props}/>;
 };
 const LittlePrestataireCard=(props)=>{
    return <Basic_LittlePrestataireCard{...props}/>;
 };


export { TableauResa,PrestataireCard,ButtonAddPrestataire,CreatePrestationModal,DisponibilityModal,CategoriesCrudService,ReservationModal,HoraireChooser,TableauUneDispo,ToInformChangesModal,LittlePrestataireCard};