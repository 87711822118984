import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { ModalBody, ModalFooter } from "reactstrap";
import { Button, Modal, ModalHeader } from "../../../lib/components";

import QuestionTypes from "./QuestionTypes/system";

import styles from "../../../assets/scss/pages/CutlureQuizz/addModal.module.scss";

const AddModal = (props) => {
  const {
    isOpen,
    toggle,
    context
  } = props;

  const [questions, setQuestion] = context;
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(null);
  }, [isOpen]);

  const onAdd = () => {
    setQuestion([...questions, {
      type: selected,
      uid: `${questions.length + 1}`
    }]);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader>
        Ajouter une question
      </ModalHeader>
      <ModalBody>
        <div className={styles.container}>
          {QuestionTypes.map(i => (
            <div className={`${styles.card} ${selected === i.slug ? styles.selected : ""}`}
              onClick={() => setSelected(i.slug)}
              key={i.slug}>
              <h5>{i.name}</h5>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Retour</Button>
        <Button color="primary" onClick={onAdd}>Ajouter</Button>
      </ModalFooter>
    </Modal>
  );
};

AddModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
};

export default AddModal;