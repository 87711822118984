import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ModalBody, ModalFooter } from "reactstrap";
import { Button, Modal, ModalHeader } from "../../../lib/components";

import QuestionTypes from "./QuestionTypes";

import styles from "../../../assets/scss/pages/CutlureQuizz/addModal.module.scss";

const AddContainer = (props) => {
  const { isOpen, toggle, context, autoSelectNewQuestion } = props;

  const [questions, setQuestion] = context;
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(null);
  }, [isOpen]);

  useEffect(() => {
    if(selected){
      onAdd();
      if(autoSelectNewQuestion)
        autoSelectNewQuestion();
      toggle();
      setSelected(null);
    }
  }, [selected]);


  const onAdd = () => {
    setQuestion([
      ...questions,
      {
        type: selected,
        uid: `${questions.length + 1}`,
      },
    ]);
    if(autoSelectNewQuestion)
      autoSelectNewQuestion();
    toggle();
  };

  return (
    
        <div className={styles.container}>
          {QuestionTypes.map((i) => (
            <div
              className={`${styles.card} ${selected === i.slug ? styles.selected : ""}`}
              onClick={() => setSelected(i.slug)}
              key={i.slug}
            >
              <h5>{i.name}</h5>
            </div>
          ))}
        </div>
  );
};

const AddModal = (props) => {
  const { isOpen, toggle, context, autoSelectNewQuestion } = props;

  const [questions, setQuestion] = context;
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(null);
  }, [isOpen]);

  const onAdd = () => {
    setQuestion([
      ...questions,
      {
        type: selected,
        uid: `${questions.length + 1}`,
      },
    ]);
    if(autoSelectNewQuestion)
      autoSelectNewQuestion();
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader>Ajouter une question</ModalHeader>
      <ModalBody>
        <div className={styles.container}>
          {QuestionTypes.map((i) => (
            <div
              className={`${styles.card} ${selected === i.slug ? styles.selected : ""}`}
              onClick={() => setSelected(i.slug)}
              key={i.slug}
            >
              <h5>{i.name}</h5>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Retour
        </Button>
        <Button color="primary" onClick={onAdd}>
          Ajouter
        </Button>
      </ModalFooter>
    </Modal>
  );
};

AddModal.propTypes = {
  isOpen : PropTypes.bool.required,
  toggle : PropTypes.func.required,
  context : PropTypes.object.required,
  autoSelectNewQuestion : PropTypes.func,
};

AddContainer.propTypes = {
  isOpen : PropTypes.bool.required,
  toggle : PropTypes.func.required,
  context : PropTypes.object.required,
  autoSelectNewQuestion : PropTypes.func,
};
export {AddModal, AddContainer};
