import React, {useState, useEffect} from "react";
import firebase from "firebase";
import useUI from "../../../hooks/ui.hook";
import {Spinner} from "../../../lib/components";
import moment from "moment";


import styles from "../../../assets/scss/components/homeWidgets/lastActu.module.scss";

const firestore = firebase.firestore;

const LastActu = () => {
  const [ui] = useUI();
  const [actu, setActu] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        let res = await firestore().collection("establishments").doc(ui.user.establishment)
          .collection("blocks").doc("actus").collection("actus")
          .orderBy("date", "desc").limit(1).get();
        let data = [];
        res.forEach(d => data.push({uid: d.id, ...d.data()}));
        setActu(data.length === 1 ? data[0] : null);
      } catch (e) {
        console.error(e.message);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <div className={styles.container}>
      <h4 style={{fontWeight:700, fontSize:20}}>Dernière actualité</h4>
      {loading ? <Spinner /> : <>
        {actu === null ? <>
          <p>Aucune actualité pour votre établissement</p>
        </> :
          <div className={styles.actu}>
            <div className={styles.articleInfo}>
              <span className={styles.title}>{actu.title}</span>
              <span className={styles.description}>
                {actu.description.substring(0, 200)}...
              </span>
              <div className={styles.actuActions}>
                <span className={styles.actuDate}>Publié le {moment(actu.date.toDate()).format("l")}</span>
                {/*<Button color="primary">Consulter</Button>*/}
              </div>
            </div>
            {actu.image ? 
              <div className={styles.articleImageContainer}>
                <img src={actu.image} className={styles.articleImage} />
              </div>
              :<></>}
          </div>
        }
      </>}
    </div>
  );
};

export default LastActu;