import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import useUI from "../../hooks/ui.hook";

const useSentry = () => {
  const [ui] = useUI();
  const [isEnabled] = useState(window.location.hostname !== "localhost");

  useEffect(() => {
    if (isEnabled) {
      Sentry.init({
        dsn: "https://e38bcda2447c8f03ef5871e21e79a99f@o4507214226259968.ingest.de.sentry.io/4507385433555024",
        integrations: [
          Sentry.replayIntegration(),
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        tracesSampleRate: 1.0,
      });
    }
  }, []);

  useEffect(() => {
    if (isEnabled) {
      Sentry.setUser({
        id: ui?.user?.uid ?? null,
        role: ui?.user?.role ?? null,
        establishmentId: ui?.establishment?.uid ?? null,
        establishmentName: ui?.establishment?.name ?? null,
        groupementId: ui?.groupement?.uid ?? null,
        groupementName: ui?.groupement?.name ?? null,
        specific: ui?.groupement?.specific ?? null
      });
    }
  }, [ui.establishment, ui.user]);
};

export default useSentry;