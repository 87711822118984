import React, { useEffect, useState, useRef } from "react";
import {
    Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Table, Card, CardHeader, CardBody, Input, FormGroup, ImagePicker
} from "lib/components";
import { Edit, Plus, List, BarChart, PlusCircle, Trash2 } from "react-feather";
import PropTypes from "prop-types";
import Restrictions, {RestrictionDisplay} from "./restrictions";

import styles from "../scss/bloc.module.scss";

export const ImageCompareEdit = ({ blockData, parentRestrictions, remove }) => {
    const [isOpenSetup, setIsOpenSetup] = useState(false);
    const [text, setText] = useState("");
    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [text1, setText1] = useState("");
    const [text2, setText2] = useState("");
    const [restrictions, setRestrictions] = useState([]);

    useEffect(() => {
        if (blockData) {
            setText(blockData.text ?? "");
            setImage1(blockData.image1 ?? "");
            setImage2(blockData.image2 ?? "");
            setText1(blockData.text1 ?? "");
            setText2(blockData.text2 ?? "");
            setRestrictions(blockData.restrictions ?? []);
        }
    }, [blockData]);

    useEffect(() => {
        if (text && blockData.text !== text) {
            blockData.text = text;
        }
    }, [text]);

    useEffect(() => {
        if (text1 && blockData.text1 !== text1) {
            blockData.text1 = text1;
        }
    }, [text1]);

    useEffect(() => {
        if (text2 && blockData.text2 !== text2) {
            blockData.text2 = text2;
        }
    }, [text2]);

    useEffect(() => {
        if (image1 && blockData.image1 !== image1) {
            blockData.image1 = image1;
        }
    }, [image1]);

    useEffect(() => {
        if (image2 && blockData.image2 !== image2) {
            blockData.image2 = image2;
        }
    }, [image2]);

    const _setup = () => {
        blockData.restrictions = restrictions;
        setIsOpenSetup(false);
    };

    const _delete = () => {
        blockData = null;
    };

    if (!blockData) return <></>;
    return (
        <div style={{ width: "100%", padding: 20 }}>

            <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Input label="Informations liées aux images" type="textarea" style={{ width: "100%" }} value={text} onChange={e => setText(e.target.value)} />
                    <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                        <div style={{flex: 1, display: "flex", flexDirection: "column" }}>
                            <ImagePicker value={image1} onChange={setImage1} />
                            <Input style={{ width: "100%" }} value={text1} onChange={e => setText1(e.target.value)} />
                        </div>

                        <div style={{flex: 1, display: "flex", flexDirection: "column" }}>
                            <ImagePicker value={image2} onChange={setImage2} />
                            <Input style={{ width: "100%" }} value={text2} onChange={e => setText2(e.target.value)} />
                        </div>
                       
                        
                    </div>

                </div>
                {restrictions.length > 0 ?
                    <div className={styles.restrictionContainer}>
                        {restrictions.map((_r) => (
                            _r.value && _r.value.length > 0 ?
                                _r.value.map((_r2, _indx) => (
                                    <div key={_r.type + _indx}>
                                        <RestrictionDisplay restriction={{ type: _r.type, value: _r2 }} />
                                    </div>
                                ))
                                : null

                        ))}
                    </div>
                    : null
                }
                <Edit className={styles.icon} color={"blue"} size={30} onClick={() => setIsOpenSetup(true)} />
                <Trash2 className={styles.icon} color={"red"} size={30} onClick={remove} />

            </div>
            <Modal isOpen={isOpenSetup} toggle={() => setIsOpenSetup(false)} size="lg">
                <ModalHeader>{"Setup du composant"}</ModalHeader>
                <ModalBody>
                    <h3 className={styles.restrictions}>Restrictions : </h3>
                    <Restrictions parentRestrictions={parentRestrictions} currentRestrictions={restrictions} setRestrictions={setRestrictions} />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={_setup}>Valider</Button>
                    <Button color="secondary" onClick={() => setIsOpenSetup(false)}>Annuler</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};



export const ImageCompareView = ({ blockData, parentRestrictions }) => {
    const ref1 = useRef();
    const ref2 = useRef();

    const [padding1, setPadding1] = useState(0);
    const [padding2, setPadding2] = useState(0);


    useEffect(() => {
        if(ref1 && ref1.current){
            const _height = ref1.current.offsetHeight;

            if(_height !== padding1)setPadding1(_height);
        }
    },[ref1, ]);

    useEffect(() => {
        if(ref2 && ref2.current){
            const _height = ref2.current.offsetHeight;

            if(_height !== padding2)setPadding2(_height);
        }
    },[ref2]);

    return (
        <div className={styles.viewContainer}>

            <div className={styles.viewContent}>
                <div className={styles.text}>{blockData?.text}
                    {blockData?.restrictions?.length > 0 ?
                        <div className={styles.restrictionContainer}>
                            {blockData?.restrictions.map((_r) => (
                                _r.value && _r.value.length > 0 ?
                                    _r.value.map((_r2, _indx) => (
                                        <div key={_r.type + _indx}>
                                            <RestrictionDisplay restriction={{ type: _r.type, value: _r2 }} />
                                        </div>
                                    ))
                                    : null

                            ))}
                        </div>
                        : null
                    }
                </div>
                <div className={styles.imageComparaison}>
                    <div className={styles.imageComparaisonUnit} style={{paddingBottom: padding1 + 5}}>
                        <img src={blockData?.image1}></img>
                        <div ref={ref1}>{blockData?.text1}</div>
                    </div>
                    <div className={styles.imageComparaisonUnit} style={{paddingBottom: padding1 + 5}}>
                        <img src={blockData?.image2}></img>
                        <div>{blockData?.text2}</div>
                    </div>
                </div>


            </div>
        </div>
    );
};


ImageCompareEdit.propTypes = {
    blockData: PropTypes.object,
    parentRestrictions: PropTypes.object,
    remove: PropTypes.func
};

ImageCompareView.propTypes = {
    blockData: PropTypes.object,
    parentRestrictions: PropTypes.object,
};