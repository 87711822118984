import React, {useState, useEffect} from "react";
import firebase from "firebase";
import useUI from "../../../hooks/ui.hook";
import useMicroService from "helpers/microService";

import {Button, FormGroup, Input, Select, Checkbox} from "lib/components";
import { toast } from "react-toastify";

import styles from "../../../assets/scss/components/homeWidgets/banner.module.scss";
import { getEstablishmentById } from "services/ressources/establishment";
import { getUsersByEtab } from "services/ressources/user";

const firestore = firebase.firestore;

const options = [{label: "Information", value: "info"}, {label: "Attention", value: "warning"}, {label: "Alerte", value: "alert"}];

const Banner = () => {
  const [ui] = useUI();
  const [BannerText, setBannerText] = useState("");
  const [BannerType, setBannerType] = useState(options[0]);

  const [loading, setLoading] = useState(false);

  const ms = useMicroService();

  useEffect(() => {
    (async () => {
      try {
        const res2 = await getEstablishmentById({id: ui.user.establishment});
        setBannerText(res2.banner ?? "");
        const _bannerType = res2.bannerType;
        setBannerType(options.find(o => o.value === _bannerType) ?? options[0]);
      } catch (e) {
        console.error(e.message);
      }
    })();
  }, []);

  const submit = async () => {
    setLoading(true);
    try {
      await firestore().collection("establishments").doc(ui.user.establishment).update({banner : BannerText, bannerType : BannerType.value});
      toast.success("Le bandeau dynamique a été mis à jour");
    } catch (e) {
      return toast.error("La mise à jour du bandeau dynamique a échoué");
    }
    setLoading(false);
   
  };

  return (
    <div className={styles.container}>
      <FormGroup className={styles.form}>
        <Input
          value={BannerText}
          className={styles.input}
          onChange={e => setBannerText(e.target.value)}
          placeholder="Texte du bandeau"
          label="Titre"
        />
        <Select label="Type" options={options} onChange={e => setBannerType(e ?? null)} value={BannerType} />
      </FormGroup>
      <div style={{display: "flex", justifyContent: "center"}}>
        <Button color="primary" onClick={submit} className={styles.button}>
          Enregistrer
        </Button>
      </div>
    </div>
  );

};

export default Banner;

