import contentType from "pages/Major/Gazette/contentTypes";
import { EditoComponent, EditoEditor, EditoPdf } from "./Edito";
import { PoemComponent, PoemEditor, PoemPdf } from "./Poem";
import { RecipeComponent, RecipeEditor, RecipePdf } from "./Recipe";
import { ImageComponent, ImageEditor, ImagePdf } from "./Image";
import { ArticleComponent, ArticleEditor, ArticlePdf } from "./Article";
import { AnimationComponent, AnimationEditor, AnimationPdf } from "./Animation";
import { QuoteComponent, QuoteEditor, QuotePdf } from "./Quote";
import { AgendaComponent, AgendaEditor, AgendaPdf } from "./Agenda";

const _contentTypesHeurus = [
  {
    slug: "imageHeurus",
    name: "Image",
    component: ImageComponent,
    editor: ImageEditor,
    formatCompatibility: ["game", "summary", "health"],
    pdf: ImagePdf,
  },
  {
    slug: "edito",
    name: "Edito",
    component: EditoComponent,
    editor: EditoEditor,
    formatCompatibility: ["edito"],
    pdf: EditoPdf,
  },
  {
    slug: "poem",
    name: "Poème",
    component: PoemComponent,
    editor: PoemEditor,
    formatCompatibility: ["poem"],
    pdf: PoemPdf,
  },
  {
    slug: "recipe",
    name: "Recette",
    component: RecipeComponent,
    editor: RecipeEditor,
    formatCompatibility: ["recipe"],
    pdf: RecipePdf,
  },
  {
    slug: "articleHeurus",
    name: "Article",
    component: ArticleComponent,
    editor: ArticleEditor,
    formatCompatibility: ["health"],
    pdf: ArticlePdf,
  },
  {
    slug: "animation",
    name: "Animations",
    component: AnimationComponent,
    editor: AnimationEditor,
    formatCompatibility: ["animation"],
    pdf: AnimationPdf,
  },
  {
    slug: "quote",
    name: "Dicton",
    component: QuoteComponent,
    editor: QuoteEditor,
    formatCompatibility: ["quote"],
    pdf: QuotePdf,
  },
  {
    slug: "agenda",
    name: "Agenda",
    component: AgendaComponent,
    editor: AgendaEditor,
    formatCompatibility: ["agenda"],
    pdf: AgendaPdf,
  }

];

const mergeContentTypes = (allow = ["text", "article", "image", "PDF"]) => {
  let _contentTypes = _contentTypesHeurus;
  contentType.forEach(ct => {
    if(_contentTypes.filter(c => c.slug === ct.slug).length === 0 && allow.indexOf(ct.slug) !== -1) {
      // On intégrer des composants de la gazette standard aux composants de la gazette Heurus
      if(ct.slug === "image") ct.formatCompatibility.push("animation");
      _contentTypes.push(ct);
    }
  });


  return _contentTypes;
};

const contentTypesHeurus = mergeContentTypes();



export default contentTypesHeurus;
