import React, { useMemo, useCallback, useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Spinner, Input, Select, FormGroup, Button, DatePicker, Checkbox, MultiSelect, Modal, ModalBody, ModalHeader, ModalFooter } from "../../../../../../../lib/components";
import useCtx from "../../../Context/ctx";
import moment from "moment";
import { ArrowLeft, ArrowRight, CheckSquare, Square } from "react-feather";
import { usePrompt } from "lib/hooks/usePrompt";
import styles from "../../../../../../../assets/scss/pages/User/subscription.module.scss";
import { useEffect } from "react";
import firebase from "firebase";
import { isDayEmpty, getDefaultRepas, getDefaultDay, isRepasEmpty, areReservationsEqual, sortRepas } from "../../../../../Menu/helpers/operations";

const firestore = firebase.firestore;

// A function to compare if two arrays have the same elements regardless of their order
const ignoreOrderCompare = (a, b) => {
  if (a.length !== b.length) return false;
  const elements = new Set([...a, ...b]);
  for (const x of elements) {
    const count1 = a.filter(e => e === x).length;
    const count2 = b.filter(e => e === x).length;
    if (count1 !== count2) return false;
  }
  return true;
};


const Subscription = () => {
  const { state, dispatch, save } = useCtx();
  const { user, establishment, isLoading } = (state ?? {});
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [subscription, setSubscription] = useState(null);
  const [subscriptionMonths, setSubscriptionMonths] = useState([]);
  const [subscriptionHomeDelivery, setSubscriptionHomeDelivery] = useState({});
  const [subscriptionDays, setSubscriptionDays] = useState([]);
  const [isSaved, setIsSaved] = useState(true);
  const [saving, setSaving] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const months = moment.months();

  const template = establishment?.template;
  const templateSubscription = establishment?.templateSubscription;
  const currentSubscription = subscription ? templateSubscription[subscription] : null;
  const possibleRepas = currentSubscription?.repas ? sortRepas(template, Object.keys(currentSubscription?.repas)) : null;

  useEffect(() => {
    // On passe au nouveau système de gestion des portages d'abonnements
    if (user?.subscriptionHomeDelivery === true && possibleRepas.length > 0) {
      const _data = {};

      possibleRepas.forEach(element => {
        _data[element] = true;
      });
      firestore().collection("users").doc(user.uid).update({
        subscriptionHomeDelivery: _data
      });
    }
  }, [user?.subscriptionHomeDelivery, possibleRepas]);

  useEffect(() => {
    if (user && !saving) {
      if (user.subscription) {
        setSubscription(user.subscription);
      }
      if (user.subscriptionMonths) {
        setSubscriptionMonths([...user.subscriptionMonths]);
      }
      if (user.subscriptionHomeDelivery) {
        setSubscriptionHomeDelivery(user.subscriptionHomeDelivery);
      }

      if (user.subscriptionDays) {
        setSubscriptionDays([...user.subscriptionDays]);
      } else {
        setSubscriptionDays([1, 2, 3, 4, 5, 6, 7]);
      }
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      if (saving && user) {
        if (user.subscription === subscription && ignoreOrderCompare(subscriptionMonths, user.subscriptionMonths) && user.subscriptionHomeDelivery === subscriptionHomeDelivery) {
          await save();
          setSaving(false);
        }
      }
    })();
  }, [user, saving]);

  useEffect(() => {
    if (user) {
      const userSub = user.subscription ?? undefined;
      const userSubMonth = user.subscriptionMonths ?? [];
      const userSubPortage = user.subscriptionHomeDelivery ?? false;
      if ((subscription !== userSub || !ignoreOrderCompare(subscriptionMonths, userSubMonth) || subscriptionHomeDelivery !== userSubPortage) && userSub) {
        setIsSaved(false);

      } else {
        setIsSaved(true);
      }
    }
  }, [subscription, subscriptionMonths, subscriptionHomeDelivery, user]);

  usePrompt(
    !isSaved,
    "Les modifications apportées aux abonnements ne sont pas enregistrées. Voulez vous quitter ?",
    true
  );

  const handleSave = () => {
    dispatch({ type: "setUser", user: { ...user, ["subscription"]: subscription, ["subscriptionMonths"]: subscriptionMonths, ["subscriptionHomeDelivery"]: subscriptionHomeDelivery, ["subscriptionDays"]: subscriptionDays } });
    setSaving(true);
  };


  const handleMonthClick = (month) => {
    const _date = selectedYear + "-" + month;
    if (subscriptionMonths.includes(_date)) {
      setSubscriptionMonths([...subscriptionMonths].filter(date => date != _date));
    } else {
      setSubscriptionMonths([...subscriptionMonths, _date]);
    }
  };


  const handlePortageClick = () => {
    if (possibleRepas) {
      if (possibleRepas.length > 1) {
        setIsOpen(true);
      } else {
        setSubscriptionHomeDelivery({ ...subscriptionHomeDelivery, [possibleRepas[0]]: !(subscriptionHomeDelivery[possibleRepas[0]] ?? false) });
      }
    }

  };

  const onChangeDays = (_day) => {
    if (subscriptionDays.includes(_day)) {
      setSubscriptionDays(subscriptionDays.filter((a) => a !== _day));
    } else {
      setSubscriptionDays([...subscriptionDays, _day].sort((a, b) => a - b));
    }
  };

  const onValidateDays = async () => {
    setIsOpen2(false);
  };


  if (isLoading) return (<Spinner />);
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Card>
              <CardHeader><h5>Menu</h5></CardHeader>
              <CardBody>
                <div className={styles.inputs}>
                  <div className={styles.selector}>
                    <h4>Abonnement :</h4>
                    <Select
                      placeholder="Aucun abonnement"
                      options={Object.keys(templateSubscription).map(sub => ({ value: sub, label: sub }))}
                      value={subscription ? { value: subscription, label: subscription } : null}
                      onChange={e => setSubscription(e?.value ?? null)/*handleChange("subscription", e?.value ?? null)*/}
                      clearable
                    />
                  </div>


                  <div>
                    <h4>Portage par défaut :</h4>
                    <Checkbox checked={subscriptionHomeDelivery ? Object.entries(subscriptionHomeDelivery).some(([_repas, _val]) => possibleRepas?.includes(_repas) && _val === true) : false}
                      onChange={handlePortageClick}
                      className={styles.checkbox}
                    />
                  </div>

                  <div>
                    <Button style={{ width: "20%", marginLeft: "1%", marginRight: "1%", fontSize: 18, padding: 6 }} onClick={() => setIsOpen2(true)}>⚙</Button>
                  </div>


                  <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
                    <ModalHeader>
                      {"Gestion des portages de l'abonnement"}
                    </ModalHeader>
                    <ModalBody>
                      {possibleRepas && possibleRepas.map(_repas => (
                        <div key={_repas} className={styles.modalCheckBoxContainer}>
                          <div>
                            Portage {_repas}
                          </div>
                          <Checkbox
                            checked={subscriptionHomeDelivery[_repas] ?? false}
                            onChange={() => setSubscriptionHomeDelivery({ ...subscriptionHomeDelivery, [_repas]: !subscriptionHomeDelivery[_repas] ?? true })}
                            className={styles.checkbox}
                          />
                        </div>

                      ))}

                    </ModalBody>

                  </Modal>

                  <Modal isOpen={isOpen2} toggle={() => setIsOpen2(false)}>
                    <ModalHeader>
                      {"Gestion des jours inclus dans l'abonnement"}
                    </ModalHeader>
                    <ModalBody>
                      {[1, 2, 3, 4, 5, 6, 7].map((_day) => (
                        <div key={_day} className={styles.modalCheckBoxContainer}>
                          <div>
                            {moment().isoWeekday(_day).format("dddd")}
                          </div>
                          <Checkbox
                            checked={subscriptionDays && subscriptionDays.includes(_day)}
                            onChange={() => onChangeDays(_day)}
                            className={styles.checkbox}
                          />
                        </div>
                      ))}
                    </ModalBody>
                    <ModalFooter>
                      <Button onClick={() => setIsOpen2(false)}>
                        Annuler
                      </Button>
                      <Button onClick={onValidateDays}>
                        Valider
                      </Button>
                    </ModalFooter>

                  </Modal>

                </div>


                {subscription ?
                  <div className={styles.monthPicker}>
                    <div className={styles.header}>
                      <ArrowLeft size={50} style={{ cursor: "pointer" }} onClick={() => setSelectedYear(selectedYear - 1)} />
                      <div className={styles.title}>{selectedYear}</div>
                      <ArrowRight size={50} style={{ cursor: "pointer" }} onClick={() => setSelectedYear(selectedYear + 1)} />
                    </div>
                    <div className={styles.monthPickerContent}>
                      {months.map((m, indx) => (
                        <div key={indx} className={`${styles.month} ${moment(selectedYear + "-" + ((indx + 1).toString().length === 1 ? "0" + (indx + 1) : indx + 1)).diff(moment().startOf("month")) < 0 ? styles.past : ""} ${subscriptionMonths.includes(selectedYear + "-" + ((indx + 1).toString().length === 1 ? "0" + (indx + 1) : indx + 1)) ? styles.selected : ""}`} onClick={() => handleMonthClick((indx + 1).toString().length === 1 ? "0" + (indx + 1) : indx + 1)}>
                          <div className={styles.monthName}>
                            {m}
                          </div>
                          <div className={styles.monthContent}>
                            {subscriptionMonths.includes(selectedYear + "-" + ((indx + 1).toString().length === 1 ? "0" + (indx + 1) : indx + 1)) ?
                              <CheckSquare size={50} />
                              :
                              <Square size={50} />
                            }
                          </div>

                        </div>
                      ))}
                    </div>
                  </div>
                  : null}
              </CardBody>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: 10 }}>
                <Button color="primary" onClick={handleSave} loading={saving}>Enregistrer</Button>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Subscription;