import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import useUI from "hooks/ui.hook";

import Calibri from "fonts/calibri/calibri-regular.ttf";
import CalibriBold from "fonts/calibri/calibri-bold.ttf";

Font.register({
  family: "Calibri",
  fontWeight: "normal",
  src: Calibri,
});
Font.register({
  family: "Calibri",
  format: "bold",
  src: CalibriBold,
});


const styles = StyleSheet.create({
  mainContainer: {
    border: 6,
    borderColor: "#D26F47",
  },
  title: {
    fontSize: 35,
    fontFamily: "Calibri",
    fontWeight: "bold",
    color: "#D26F47",
  },
  titleContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  weekContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: 2,
  },
  fromto: {
    fontFamily: "Calibri",
    fontSize: 15,
  },
  container: {
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: -20
  },
  logoContainer: {
    position: "absolute",
    top: 0,
    right: -30,
    width: 160,
    height: 70,
  },
  logo: {
    width: "100%",
    objectFit: "contain",
  },
  day: {
    borderRadius: 20,
    backgroundColor: "#F6EAE5",
    margin: 8,
    width: "22%",
    height: 222,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 0,
    marginLeft: 5,
  },
  dayString: {
    margin: 5,
    color: "#D26F47",
    fontSize: 13,
    fontFamily: "Calibri",
  },
  eventsWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    flex: 1,

  },
  event: {
    marginTop: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  eventHour: {
    fontSize: 12,
  },
  eventTitleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginVertical: 15,
    alignItems: "center",
  },
  eventTitle: {
    fontFamily: "Calibri",
    fontWeight: "bold",
    fontSize: 12,
  },
  eventDescription: {
    fontFamily: "Calibri",
    fontSize: 11,
    marginLeft: 5,
    marginRight: 5,
    maxHeight: 40,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  eventImageContainer: {
    height: 70,
    alignItems: "center",
    justifyContent: "center",
  },
  eventImage: {
    width: "100%",
    objectFit: "contain",
    marginBottom: 6,
  },
  banner: {
    marginTop: 5,
    fontFamily: "Calibri",
    fontSize: 14,
    textAlign: "center"
  }
});

const EkipagePdf = (props) => {
  const { ui, monday, events, logo, additionnalData } = props;
  const day = new Date(monday);

  const _getDays = () => {
    let _days = [];

    for (let i = 0; i < 7; i++) {
      let day = new Date(monday);
      day.setDate(day.getDate() + i);
      _days.push(day);
    }
    return _days;
  };
  const _getEventByDay = (day) => {
    let start = new Date(day);
    let end = new Date(day);

    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(1);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);

    return events.filter((event) => event.start > start && event.end < end).sort((a, b) => a.start - b.start);
  };
  const _hourToString = (date) => {
    return moment(date).format("HH:mm");
  };

  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View style={styles.mainContainer}>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>ANIMATIONS</Text>
          </View>
          <View style={styles.weekContainer}>
            <Text style={styles.fromto}>
              Semaine du {moment(day).startOf("week").format("ll")} au{" "}
              {moment(day).endOf("week").format("ll")}
            </Text>
            {additionnalData.banniere && additionnalData.banniere.length > 0 ? <Text style={styles.banner}>{additionnalData.banniere}</Text> : <></>}
          </View>
          <View style={styles.logoContainer}>
            {logo ? <Image src={logo} style={styles.logo} /> : <></>}
          </View>
          <View style={styles.container}>
            {_getDays().map((day) => (
              <View style={styles.day} key={day.toISOString()}>
                <View style={styles.dateContainer}>
                  <Text style={styles.dayString}>
                    {moment(day).format("dddd").toLocaleUpperCase()}{" "}
                    {moment(day).format("Do")}{" "}
                    {moment(day).format("MMM").toLocaleUpperCase()}
                  </Text>
                </View>
                <View style={styles.eventsWrapper}>
                    {_getEventByDay(day).map((event, index) => (
                      index < 3 ?
                        <View style={styles.event} key={index}>
                          <Text style={styles.eventHour}>
                            {_hourToString(event.start)} -{" "}
                            {_hourToString(event.end)}
                          </Text>
                          <Text style={styles.eventTitle}>{event.title}</Text>
                          <Text style={styles.eventDescription}>
                            {event.extendedProps.description}
                          </Text>
                        </View> : null
                    ))}
                  {_getEventByDay(day).length < 3 ?
                    <View style={styles.eventImageContainer}>
                      {/* Si Jamais il y a plusieurs images le même jour, on récupère l'image de l'événement le plus long */}

                      {_getEventByDay(day).filter(e => e?.extendedProps?.image !== null).sort((a, b) => (b.end - b.start) - (a.end - a.start))[0]?.extendedProps.image ? (
                        <Image
                          src={_getEventByDay(day).filter(e => e?.extendedProps?.image !== null).sort((a, b) => (b.end - b.start) - (a.end - a.start))[0].extendedProps.image}
                          style={styles.eventImage}
                        />
                      ) : (
                        <></>
                      )}
                    </View>
                    : null}

                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

EkipagePdf.propTypes = {
  monday: PropTypes.any.required,
  events: PropTypes.array.required,
  logo: PropTypes.string,
  additionnalData: PropTypes.string,
  ui: PropTypes.object.required,
};
export default EkipagePdf;
