const featuresOptions = [
  // product activation
  {label: "Major", value: "major"},
  {label: "App",   value: "app"},
  {label: "Resto By Major", value: "restaurant"},

  // feature activation
  {label: "Culture Quizz",                                value: "cultureQuizz"},
  {label: "Planning collaborateur",                       value: "salaryPlanning"},
  {label: "Formulaire de notification",                   value: "manualNotifications"},
  {label: "Ateliers",                                     value: "AnimationWorkshopDatabase"},
  {label: "Planning d'animation",                         value: "animationPlanning"},
  {label: "Planning des menus",                           value: "menuPlanning"},
  {label: "Actualités",                                   value: "news"},
  {label: "Gazette",                                      value: "gazette"},
  {label: "Réservation des animations",                   value: "reservationPlanning"},
  {label: "Réservation des menus",                        value: "reservationMenu"},
  {label: "Totem",                                        value: "totem"},
  {label: "Statistiques payantes",                        value: "statistics"},
  {label: "Bandeau Dynamique",                            value: "banner"},
  {label: "Sauvegarde et publication des Menus",          value: "savePublishMenus"},
  {label: "Sauvegarde et publication des Animations",     value: "savePublishAnimations"},
  {label: "parcours accompagnement (utilisateur)",        value: "userBook"},
  {label: "Services",                                     value: "services"},
  {label: "Demande d'Interventions",                      value: "interventionsRequest"},

];

export default featuresOptions;