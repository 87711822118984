import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Input, Select} from "lib/components";
import {ImagePickerService} from "lib/components";
import styles from "assets/scss/pages/CutlureQuizz/editorSidebar.module.scss";

const EditorSidebar = (props) => {
  const {question, onChange, setImageLoaderForm} = props;

  const [answer, setAnswer] = useState(null);
  const [questionText, setQuestionText] = useState("");
  const [explications, setExplications] = useState("");
  const [media, setMedia] = useState(null);
  const [mediaType, setMediaType] = useState(null);

  useEffect(() => {
    setAnswer(typeof(question.answer) === "boolean" ? question.answer : null);
    setQuestionText(question.title ? question.title : "");
    setMedia(question.media ? question.media : null);
    setMediaType(question.mediaType ? question.mediaType : null);
    setExplications(question.explications ?? "");
  }, [question]);

  useEffect(() => {
    onChange({
      ...question,
      title: questionText,
      answer: answer,
      explications : explications ?? "",
      media: media,
      mediaType: media ? "image" : null
    });
  }, [answer, questionText, media, mediaType, explications]);

  return (
    <div>

    </div>
  );
};

EditorSidebar.propTypes = {
  question : PropTypes.object.required,
  onChange : PropTypes.func.required,
  setImageLoaderForm : PropTypes.func.required,
};

export default EditorSidebar;