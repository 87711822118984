import firebase from "firebase";
import { toast } from "react-toastify";

const firestore = firebase.firestore;

export const getEventCategoriesForEtab_rt = ({ ui }, cb, err) => {
    const subscribe = firestore().collection("establishments").doc(ui.user.establishment)
        .collection("blocks").doc("planning")
        .collection("categories").onSnapshot((docs) => {
            const _data = docs.docs.map(doc => ({
                uid: doc.id,
                ...doc.data(),
            }));
            cb(_data);
        }, err ?? (() => { }));
    return subscribe;
};

export const createEventCategories = async ({ ui, data }, err) => {
    try {
        let res = await firestore()
            .collection("establishments").doc(ui.user.establishment)
            .collection("blocks").doc("planning")
            .collection("categories").add({
                ...data,
            });
        return true;
    } catch (e) {
        console.error(e);
        toast.error(e?.message);
    }
};

export const updateEventCategories = async ({ ui, id, data }, err) => {
    try {
        await firestore().collection("establishments").doc(ui.user.establishment)
            .collection("blocks").doc("planning")
            .collection("categories").doc(id).update({
                ...data,
            });
        return (true);
    } catch (e) {
        console.error(e);
        toast.error(e?.message);
        return (false);
    }
};

export const removeEventCategories = async ({ui,id},err)=>{
    try{
        await firestore().collection("establishments").doc(ui.user.establishment)
        .collection("blocks").doc("planning")
        .collection("categories").doc(id).delete();
      return (true);
    }catch (e){
        console.error(e);
        toast.error(e?.message);
        return false;

    }
};