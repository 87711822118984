import React, { useState, useEffect, useMemo, useCallback } from "react";
import useUI from "hooks/ui.hook";
import {
    Button,
    Checkbox,
    FormGroup,
    Select
} from "lib/components";
import PropTypes from "prop-types";
import { useMenuReservation } from "../../../router_context";

import styles from "../../../Basic/Assets/scss/dishCard.module.scss";

const DishCard = (props) => {
    const { selected, category, dishId, dishInfo, handleClick, disabled } = props;
    const [ctx] = useMenuReservation();
    const textSize = useMemo(() => {

        const _reducer = ctx?.modalInfos?.repas === "Déjeuner" && dishInfo?.origin?.toLowerCase() === "menu du jour" ? 14 : 19;

        if(dishInfo.name.length > 20){
            const newSize =  1.1 - Math.round((dishInfo.name.length / _reducer)*100)/1000;  // Que le hasard soit, et le hasard fut.
            if(newSize < 0.55) return "0.55vw";
            return newSize+"vw";
        }else{
            return null;
        }
    },[dishInfo]);


    return (
        <div className={`${styles.container} ${disabled ? styles.disabled : null}`} style={{backgroundColor: selected ? "#300438" : null, color: selected ? "white" : null}} onClick={() => !disabled ? handleClick(dishId) : null}>
            { dishInfo?.origin?.toLowerCase() === "menu du jour" ? <div className={styles.info}>
                Menu du jour
            </div>
            : dishInfo?.origin?.toLowerCase() === "menu de la veille" ? <div className={styles.info} style={{backgroundColor: "#97a69e"}}>
                    Menu de la veille
                </div>
                 : <div className={styles.info} style={{backgroundColor: "#123865"}}>
                Menu de substitution    
            </div>}
            <div style={{flex: 1, fontSize: textSize ?? "unset", paddingTop: dishInfo?.name.length <= 22 ? "1vh" : null}}>
                {dishInfo.name}
            </div>
        </div>
    );
};


DishCard.propTypes = {
    selected: PropTypes.bool,
    category: PropTypes.string,
    dishId: PropTypes.string,
    dishInfo: PropTypes.object,
    handleClick: PropTypes.func,
    disabled: PropTypes.bool,
};


export default DishCard;
