import React from "react";
import PropTypes from "prop-types";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import template from "./assets/template.png";

import moment from "moment";

import Roboto from "fonts/roboto/Roboto-Condensed.ttf";
import RobotoBold from "fonts/roboto/Roboto-BoldCondensed.ttf";

Font.register({
  family: "Roboto",
  fontWeight: "normal",
  src: Roboto,
});
Font.register({
  family: "Roboto",
  format: "bold",
  src: RobotoBold,
});
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
  backgroundImage: {
    position: "absolute",
    width: "100vw",
    height: "100vh"
  },
  headerContainer: {
    position: "absolute",
    width: "100vw",
    marginTop: 140 
  },
  headerText:{
    color: "#ecce86",
    width: "100%",
    textAlign: "center",
    fontSize: 24,
    fontFamily: "Roboto",
  },
  contentContainer: {
    position: "absolute",
    width: "100vw",
    marginTop: 300, 
    //backgroundColor: "rgba(255,0,0,0.2)",
    height: 450,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around"
  },
  contentText: {
    color: "#ecce86",
    width: "80%",
    fontSize: 20,
    fontFamily: "Roboto", 
    //backgroundColor: "rgba(0,255,0,0.2)",
  }
});

const Girandieres = (props) => {
  const { week, menuState, logo } = props;

  return (
    <Document>
      {menuState.map((d, id) => (
        <Page key={id} size="A4">
          <View style={styles.container}>
            <Image
              src={template}
              style={styles.backgroundImage}
            />
            <View style={styles.headerContainer}>
              <Text style={styles.headerText}>{moment(week[id]).format("dddd DD MMMM")}</Text>
            </View>

            <View style={styles.contentContainer}>
              <Text style={styles.contentText}>
                {d.MIDI[0].entry}
              </Text>
              <Text style={styles.contentText}>
                {d.MIDI[0].dish}
              </Text>
              <Text style={styles.contentText}>
                {d.MIDI[0].dessert}
              </Text>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};
Girandieres.propTypes = {
  week: PropTypes.array.isRequired,
  menuState: PropTypes.object.isRequired,
  logo: PropTypes.string,
};

export default Girandieres;
