import React, { useState, useEffect, useMemo, useCallback } from "react";
import useUI from "hooks/ui.hook";
import moment from "moment";
import PropTypes from "prop-types";
import { useMenuReservation } from "../../../router_context";
import styles from "../../../Basic/Assets/scss/modalInformations.module.scss";
import { Tag } from "lib/components";

const colors = ["#8c66dc", "#dc6681", "#00bfb2", "#DC965A", "#81171B"];
const colorUnite = "#123865";

const ModalInformations = ({ reservation, page }) => {
    const [ui] = useUI();
    const [ctx, dispatch] = useMenuReservation();

    const [supplements, setSupplements] = useState([]);

    const dataDay = ctx?.data[ctx?.modalInfos?.date];
    const currentUser = ctx?.users[ctx?.modalInfos?.uid] ?? (ctx.guests && ctx.guests.find(guest => guest.guestId === ctx.modalInfos.uid)) ?? {};
    const template = ui.establishment.template;
    const templateSubscription = ui.establishment.templateSubscription;
    const subscription = currentUser?.subscription && currentUser?.subscriptionMonths && currentUser?.subscriptionMonths.includes(moment(ctx?.modalInfos?.date).format("YYYY-MM")) ? currentUser?.subscription : null;

    useEffect(() => {
        if (
            currentUser
            && !currentUser.guestId
            && ctx.dataOrdersSupplements
            && ctx.dataOrdersSupplements[ctx.modalInfos.date]
            && ctx.dataOrdersSupplements[ctx.modalInfos.date][ctx.modalInfos.uid]
            && ctx.dataOrdersSupplements[ctx.modalInfos.date][ctx.modalInfos.uid][ctx.modalInfos.repas])
            setSupplements(ctx.dataOrdersSupplements[ctx.modalInfos.date][ctx.modalInfos.uid][ctx.modalInfos.repas]);
        else if (
            currentUser
            && currentUser.guestId
            && ctx.dataGuestOrdersSupplements
            && ctx.dataGuestOrdersSupplements[ctx.modalInfos.date]
            && ctx.dataGuestOrdersSupplements[ctx.modalInfos.date][ctx.modalInfos.uid]
            && ctx.dataGuestOrdersSupplements[ctx.modalInfos.date][ctx.modalInfos.uid][ctx.modalInfos.repas])
            setSupplements(ctx.dataGuestOrdersSupplements[ctx.modalInfos.date][ctx.modalInfos.uid][ctx.modalInfos.repas]);
        else setSupplements([]);
    }, [ctx]);

    const getFormule = useMemo(() => {
        const formule = template[ctx.modalInfos.repas].categories.map((_categ, indx) => (
            reservation[page] && reservation[page][_categ] && ((page === 0 && !supplements.includes(_categ)) || page > 0) && _categ
        ));

        if(ctx.modalInfos.repas === "Dîner" && (formule.includes("Plat") || formule.includes("Accompagnement"))) return <Tag className={styles.tag} style={{ backgroundColor: colors[0] }}>Dîner complet</Tag>;
        else if(ctx.modalInfos.repas === "Dîner" && formule.includes("Potage") && formule.filter(_p => _p).length === 1) return <Tag className={styles.tag} style={{ backgroundColor: colors[2] }}>Potage</Tag>;
        else if(ctx.modalInfos.repas === "Dîner" && formule.filter(_p => _p).length > 0) return <Tag className={styles.tag} style={{ backgroundColor: colors[1] }}>Dîner allégé</Tag>;
        return formule.map(categ => <div key={`formule-${categ}`}>{categ}</div>);
    }, [ctx, template, reservation]);


    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.title}> {currentUser?.surname} {currentUser?.name} {page > 0 ? `(Invité ${page})` : ""}</div>
            </div>
            {page === 0 && templateSubscription ?
                <div className={styles.content}>
                    <div className={styles.title}> Abonnement : </div>
                    <Tag className={styles.tag} style={{ backgroundColor: subscription ? colors[Object.keys(templateSubscription).indexOf(subscription) % 5] : colorUnite }}><div>{subscription ?? "Unité"}</div></Tag>
                </div>
                : null}

            <div className={styles.content}>
                <div className={styles.title}> Formule sélectionnée : </div>
                {getFormule}
            </div>

            {(page === 0 || currentUser?.guestId) && supplements.length > 0 ?
                <div className={styles.content}>
                    <div className={styles.title}> Suppléments sélectionnés : </div>
                    {
                        supplements.map((i, indx) => (
                            <div key={i.id} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <span style={{textAlign: "center"}}>{i.name}  <span style={{ fontSize: 16, fontStyle: "italic" }}>({i.id}) </span> <span style={{ fontWeight: "bold" }}>x{i.number}</span></span>
                        </div>
                        ))
                    }
                </div>
                : null}

            {templateSubscription && subscription ?
                <div className={styles.content}>
                    {subscription && templateSubscription[subscription].repas[ctx?.modalInfos?.repas] ?
                        <div className={styles.text}> Le {ctx?.modalInfos?.repas} est compris dans votre abonnement.</div>
                        :
                        <div className={styles.text}> Le {ctx?.modalInfos?.repas} n&apos;est pas compris dans votre abonnement. </div>
                    }
                </div>
                : <div></div>}
        </div>
    );
};


ModalInformations.propTypes = {
    reservation: PropTypes.array,
    page: PropTypes.number,
};

export default ModalInformations;
