import React from "react";
import axios from "axios";

const Monitoring = () => {


      return (
    <div className="home_css" style={{width: "100%", display: "flex", justifyContent: "center"}}>
      <iframe 
        title="Dashboard" 
        src="https://jenkins.jabi.fr/view/Public/?fullscreen=true" 
        height="1400px" 
        style={{flex: 4}}
      />
      <iframe
        title="Backend status"
        src={
          "https://apibackup.jabi.fr/status"
          //`${axios.defaults.baseURL}/status`
        }
        style={{flex: 2}}
      />
    </div>);
};

export default Monitoring;

