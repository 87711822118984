import React from "react";
import {ModalHeader as BootstrapModalHeader} from "reactstrap";

const ModalHeader = (props) => {
  const {children} = props;
  
  return (
    <BootstrapModalHeader {...props}>
      {children}
    </BootstrapModalHeader>
  );
};

ModalHeader.propTypes = BootstrapModalHeader.propTypes;
ModalHeader.defaultProps = BootstrapModalHeader.defaultProps;

export default ModalHeader;