import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { createContext, useReducer, useContext } from "react";
import firebase from "firebase";
import moment from "moment";
import useUI from "hooks/ui.hook";
import deepClone from "helpers/deepClone";
import useService from "../../../serviceCtx";
import md5 from "md5";
import { getSeniorUsers, fbAddReservation, fbDeleteReservation, getPlanningOfPrestataireById, getAvailabiltyIdByIdAndStartDate } from "services/ressources/service_reservation";
import { createPrestataire, updatePrestataireBDD, servicesInit, deletePrestataire, addAvailabiliyPlanning, updateAvailabiliyPlanning, removeAvailabiliyPlanning,createCategorieService,updateCategorieService,deleteCategorieService } from "services/ressources/service";

const firestore = firebase.firestore;

const Context = createContext();


const Default = {
    prestataires: null, // La liste des prestataires et leurs types de prestations
    planning: null, // la liste des prestations
    users: {}, // la liste des utilisateurs, identifiés par leurs uid
    availableServices: null, // la liste des types de prestataires possibles
    currentPrestataire: {}, // le prestataire en cours de création / modification
    currentPrestataireId:"",
    page: 0, //la page ou l'on est
    startPage: 0, //la dernière page de départ pour revenir sur la bonne page a la fin de la création de prestataire
    chosenService: "",
    weekStart: new Date(),
    currentAvailibility: {}
};

function Reducer(state, action) {
    switch (action.type) {
        // case "setMenu": return ({ ...state, menu: action.menu });
        // case "nextWeek": return ({ ...state, week: state.week.map(day => moment(day).add(7, "day").toDate()) });
        case "setProperty": return ({ ...state, [action.property]: action.value });
        // case "setState": return { ...action.state };
        default: return { ...state };
    }
}

const Provider = ({ children }) => {
    const [ui] = useUI();
    const [ctx, dispatch] = useReducer(Reducer, Default);
    const [ctxService] = useService();

    useEffect(() => {
        (async () => {
            try {
                await servicesInit({ ui });
            } catch (e) {
                console.log(e);
            }
        })();

    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getSeniorUsers(ui.user.establishment);
                let _dataUsers = {};
                res.forEach((doc) => {
                    _dataUsers[doc.id] = doc.data();
                });
                dispatch({ type: "setProperty", property: "users", value: _dataUsers });
            } catch (e) {
                console.log(e);
            }
        };
        fetchData();
    }, []);


    useEffect(() => {
        dispatch({ type: "setProperty", property: "prestataires", value: ctxService.prestataires });
    }, [ctxService.prestataires]);

    useEffect(() => {
        dispatch({ type: "setProperty", property: "planning", value: ctxService.planning });
    }, [ctxService.planning]);

    useEffect(() => {
        dispatch({ type: "setProperty", property: "availableServices", value: ctxService.availableServices });
    }, [ctxService.availableServices]);

    const updatePage = (newpage) => {
        if (ctx.page < 2) { dispatch({ type: "setProperty", property: "startPage", value: ctx.page }); }
        dispatch({ type: "setProperty", property: "page", value: newpage });
    };
    const updateCurrentPrestataireId = (newId) => {
        dispatch({ type: "setProperty", property: "currentPrestataireId", value: newId });
    };
    const updateCurrentWeekStart = (newMonday) => {
        dispatch({ type: "setProperty", property: "weekStart", value: newMonday });
    };
    const returnStartPage = (newpage) => {
        dispatch({ type: "setProperty", property: "page", value: newpage });
    };
    const updateChosenService = (newChosenService) => {
        dispatch({ type: "setProperty", property: "chosenService", value: newChosenService });
    };


    /**
    * Initialise localement un nouvelle availibility
    * @param {Object} item - item contenant toutes les informations d'une disponibilité(un créneau)
    *
    */
    const initCurrentAvailibility = (uid) => {
        if (uid) {
            dispatch({ type: "setProperty", property: "currentAvailibility", value: uid });
        } else {
            console.error("informations non conformes pour initialiser la disponibilite");
        }

    };


    /**
     * Ajoute une nouvelle réservation.
     *
     * @param {String} availabilityId - Id de le plage horaire du prestataire.
     * @param {String} userId - Id de l'utilisateur
     * @param {String} prestationId - Id de la prestation sélectionné
     * @param {Date} start - Date de début de la prestation.
     * @param {Date} end - Date de fin de la prestation.
     * @param {Array} personnel - Membres du personnel qui viennent pour cette prestation.
     */
    const addReservation = (availabilityId, userId, prestationId, start, end, personnel, personnelByDefault) => {
        if (availabilityId && userId && prestationId && start && end) {
            const _data = {
                userId,
                prestationId,
                start,
                end,
                personnel: personnel ?? [],
                personnelByDefault
            };
            const _resaId = md5(start.toString() + end.toString() + personnelByDefault ?? "" + prestationId + userId);
            fbAddReservation({ ui: ui, availabilityId, resaId: _resaId, data: _data });
        } else {
            console.error("informations non conformes");
        }
    };

    /**
     * Supprimer une réservation.
     *
     * @param {String} availabilityId - Id de le plage horaire du prestataire.
     * @param {String} reservationId - Id de la réservation
     */
    const removeReservation = (availabilityId, reservationId) => {

        if (availabilityId && reservationId) {

            fbDeleteReservation({ ui: ui, availabilityId, resaId: reservationId });

        } else {
            console.error("informations non conformes");
        }
    };

    /**
     * Update une réservation.
     *
     * @param {String} reservationId - Id de la réservation précédente
     * @param {String} availabilityId - Id de le plage horaire du prestataire.
     * @param {String} userId - Id de l'utilisateur
     * @param {String} prestationId - Id de la prestation sélectionné
     * @param {Date} start - Date de début de la prestation.
     * @param {Date} end - Date de fin de la prestation.
     * @param {Array} personnel - Membres du personnel qui viennent pour cette prestation.
     */
    const updateReservation = (reservationId, availabilityId, userId, prestationId, start, end, personnel, personnelByDefault) => {
        if (availabilityId && reservationId) {
            removeReservation(availabilityId, reservationId);
            addReservation(availabilityId, userId, prestationId, start, end, personnel, personnelByDefault);
        } else {
            console.error("informations non conformes");
        }
    };

    /**
 * Ajoute une nouvelle prestation aux planning.
 *
 * @param {String} prestataireId - Id du prestataire.
 * @param {Date} start - Date de début de la prestation.
 * @param {Date} end - Date de fin de la prestation.
 * @param {Array} personnel - Membres du personnel qui viennent pour cette prestation.
 * @param {Array} prestationsAvailable - Liste des prestations non disponibles pendant cette période.
 * @param {string} place - Lieu de la prestation.
 */
    const addAvailabiliy = (prestataireId, start, end, personnel, prestationsAvailable, place,isComplet) => {

        if (prestataireId && start && end) {

            const _data = {
                prestataireId,
                start,
                end,
                personnel: personnel ?? [],
                prestationsAvailable: prestationsAvailable ?? [],
                place: place ?? "",
                isComplet: isComplet ?? false,
            };
            addAvailabiliyPlanning({ ui: ui, data: _data });


        } else {
            console.error("informations non conformes");
        }
    };

    /**
     * Modifie une prestation existante.
     *
     * @param {String} prestationId - Id de la prestation.
     * @param {String} prestataireId - Id du prestataire.
     * @param {Date} start - Date de début de la prestation.
     * @param {Date} end - Date de fin de la prestation.
     * @param {Array} personnel - Membres du personnel qui viennent pour cette prestation.
     * @param {Array} prestationsAvailable - Liste des prestations non disponibles pendant cette période.
     * @param {string} place - Lieu de la prestation.
     */
    const updateAvailabiliy = (prestationId, prestataireId, start, end, personnel, prestationsAvailable, place,isComplet) => {

        if (prestationId && prestataireId && start && end) {
            
            const _data = {
                prestataireId,
                start,
                end,
                personnel : personnel ?? [],
                prestationsAvailable : prestationsAvailable ?? [],
                place : place ?? "",
                isComplet: isComplet ?? false,
            };
            updateAvailabiliyPlanning({ui:ui,data:_data,prestationId:prestationId});
    
        } else {
            console.error("informations non conformes");
        }
    };


    /**
     * Supprime une prestation existante.
     *
     * @param {String} prestationId - Id de la prestation.=
     */
    const removeAvailabiliy = (prestationId) => {
        if (prestationId) {
            removeAvailabiliyPlanning({ ui: ui, prestationId: prestationId });

        } else {
            console.error("informations non conformes");
        }
    };

    /**
     * Initialise localement un nouveau prestataire
     *
     * @param {String} name - Nom du prestataire.
     * @param {String} contact - Contact du prestataire.
     * @param {String} description - Informations supplémentaire sur le prestataire.
     * @param {Array} personnel - Membres du personnel qui viennent pour cette prestation.
     * @param {String} img - Url d'une image représentant le prestataire.
     * @param {string} serviceRef - Nom de la reference du type de service (ex : coiffeur)
     */
    const initCurrentPrestataire = (name, contact, description, personnel, img, serviceRef) => {
        if (name && contact) {
            const _currentPrestataire = {
                name,
                contact,
                description: description ?? "",
                personnel: personnel ?? [],
                img: img ?? "",
                serviceRef:serviceRef ?? "",
                prestations: []
            };
            dispatch({ type: "setProperty", property: "currentPrestataire", value: _currentPrestataire });
        } else {
            console.error("informations non conformes pour initialiser le presta");
        }

    };
    /**
    * Modifie un prestataire existant
    *
    * @param {String} name - Nom du prestataire.
    * @param {String} contact - Contact du prestataire.
    * @param {String} description - Informations supplémentaire sur le prestataire.
    * @param {Array} personnel - Membres du personnel qui viennent pour cette prestation.
    * @param {String} img - Url d'une image représentant le prestataire.
    * @param {string} serviceRef - Nom de la reference du type de service (ex : coiffeur)
    */
    const updateCurrentPrestataire = (name, contact, description, personnel, img, serviceRef, prestations) => {
        if (name && contact) {
            const _currentPrestataire = {
                name,
                contact,
                description: description ?? "",
                personnel: personnel ?? [],
                img: img ?? "",
                serviceRef:serviceRef ?? "",
                prestations: prestations ?? []
            };

            dispatch({ type: "setProperty", property: "currentPrestataire", value: { ...ctx.currentPrestataire, ..._currentPrestataire } });

        } else {
            console.error("informations non conformes pour update le presta");
        }
    };
    const deleteCurrentPrestataire = () => {
        if (ctx.currentPrestataire) {
            const _currentPrestataire = {};

            dispatch({ type: "setProperty", property: "currentPrestataire", value: _currentPrestataire });

        } else {
            console.error("informations non conformes pour delete le presta");
        }
    };

    /**
     * Ajoute une prestation au prestataire courant (pas directement dans la bdd)
     *
     * @param {String} title - Nom de la prestation.
     * @param {String} description - details de la prestation.
     * @param {Number} duration - Durée de la prestation (multiple de 15 min)
     * @param {Number} price - prix de la prestation en euros.
     */
    const addPrestation = (title, description, duration, price) => {
        if (title && duration && price) {
            const prestaId = md5(title + duration.toString() + price.toString());

            dispatch({
                type: "setProperty", property: "currentPrestataire", value: {
                    ...ctx.currentPrestataire, prestations: {
                        ...ctx.currentPrestataire.prestations, [prestaId]: {
                            title,
                            description: description ?? "",
                            duration,
                            price,
                            createdAt: new Date()
                        }
                    }
                }
            });
        } else {
            console.error("informations non conformes pour add une prestation");
        }
    };


    /**
    * Modifie un prestation du prestataire courant (pas directement dans la bdd)
    *
    * @param {String} prestationId - Id de la prestation.
    * @param {String} title - Nom de la prestation.
    * @param {String} description - details de la prestation.
    * @param {Number} duration - Durée de la prestation (multiple de 15 min)
    * @param {Number} price - prix de la prestation en euros.
    */
    const updatePrestation = (prestationId, title, description, duration, price) => {
        if (prestationId && title && duration && price) {
            dispatch({
                type: "setProperty", property: "currentPrestataire", value: {
                    ...ctx.currentPrestataire, prestations: {
                        ...ctx.currentPrestataire.prestations, [prestationId]: {
                            title,
                            description: description ?? "",
                            duration,
                            price,
                        }
                    }
                }
            });
        } else {
            console.error("informations non conformes pour update une presta");
        }

    };

    /**
     * Supprime une prestation du prestataire courant
     * 
     * @param {String} prestationId - Id de la prestation.
     */
    const removePrestation = (prestationId) => {
        const _prestations = deepClone(ctx.currentPrestataire.prestations);

        if (prestationId) {
            delete _prestations[prestationId];
        }


        dispatch({ type: "setProperty", property: "currentPrestataire", value: { ...ctx.currentPrestataire, prestations: _prestations } });
    };

    /**
     * Ajoute le prestataire courant dans la base de données
     */
    const addPrestataire = () => {
        createPrestataire({ ui: ui, data: ctx.currentPrestataire });
    };

    /**
    * Update le prestataire dans la base de données à partir de son id
    */
    const updatePrestataire = (prestataireId,prestataireData) => {
        if (prestataireId) { updatePrestataireBDD({ ui: ui, id: prestataireId, data: prestataireData }); }
        else {
            console.error("prestataireId non conforme");
        }
    };


    /**
    * Supprime un prestataire existant de la base de données.
    *
    * @param {String} prestataireId - Id du prestataire à supprimer.
    */
    const removePrestataire = (prestataireId) => {
        if (prestataireId) {
            deletePrestataire({ ui: ui, id: prestataireId });
        } else {
            console.error("prestataireId non conforme");
        }
    };

    const addCategorie =(name,color)=>{
        if (name && color){
            const _newCategorie ={
                name,
                color 
            };
            createCategorieService({ui:ui,data:_newCategorie});
        }else{
            console.error("informations non conformes");
        }
    };

    const updateCategorie=(categorieId,name,color)=>{
        if(categorieId && name && color){
            const _data ={
                name,
                color
            };
            updateCategorieService({ui:ui,categorieId:categorieId,data:_data});
        } else {
            console.error("informations non conformes");
        }
    };

    const deleteCategorie=(categorieId)=>{
        if(categorieId){
            deleteCategorieService({ui:ui,categorieId:categorieId});
        }else {
            console.error("informations non conformes");
        }
    };


    const generateTimeRange = useCallback((availability) => {
        const timeRange = [];
        const start = availability.start;
        const end = availability.end;
        let currentTime = new Date(start);
        while (currentTime < end) {
            const toAdd = new Date(currentTime);
            timeRange.push(toAdd);
            currentTime.setMinutes(currentTime.getMinutes() + 15);
        }
        return timeRange;
    }, []);


    const getAvailabiltyObject = (availability, prestationTime,prestationChosen, reservationActuelle) => {
        const _horaireDetails = {};
        console.log(availability, prestationTime,prestationChosen, reservationActuelle);
        if (availability) {
            if(availability.personnel.length >0){
                availability.personnel.forEach((perso) => { // On mets tt les horaires pour chaque personnel
                _horaireDetails[perso] = generateTimeRange(availability);
            });
            }else{
                _horaireDetails["unknown"] = generateTimeRange(availability);
            }
            if(availability.reservations.length != 0){
                 availability.reservations.forEach((resa) => { 
                const start = (resa.start.seconds) ? resa.start.toDate() : resa.start;
                const end = (resa.end.seconds) ? resa.end.toDate() : resa.end;
                _horaireDetails[resa.personnelByDefault] = _horaireDetails[resa.personnelByDefault].filter((horaire) => {
                    if (reservationActuelle && reservationActuelle.personnelByDefault === resa.personnelByDefault) {
                        if (horaire >= reservationActuelle.start && horaire < reservationActuelle.end) // garde les horaires de la resa en cours de modif
                            return true;
                        else
                            return !(horaire >= start && horaire < end);
                    } else {
                        return !(horaire >= start && horaire < end); //Enleve les horaires déjà réservé avec chaque personnel
                    }
                });
            });
            }
           
            //vérifier que la prestation chosen est bien dispo pour le créneau
            let isALLPrestationChosenAvailable=true;
            prestationChosen.map((prestaChosen)=>{
                let isPrestaChosenAvail = false;
                const selectedPrestataire = ctx.prestataires.find((item) => item.uid === availability.prestataireId);
                const prestationsObj = selectedPrestataire.prestations || {}; //on récupère les prestations du presta
                const prestasId = availability.prestationsAvailable; //on a aussi le tableau des prestaId disponible
                const listPrestationsNames = Object.entries(prestationsObj)
                                                .filter(([id,_p]) => prestasId.includes(id))
                                                .map(([id,_p]) => _p.title); //on peut récupérer le nom des presta en fonction des id des presta 
                if(listPrestationsNames){
                    listPrestationsNames.forEach((prestaAvail)=>{
                        if(prestaAvail == prestaChosen){return isPrestaChosenAvail=true;}
                    });
                    if(!isPrestaChosenAvail)return isALLPrestationChosenAvailable=false; // si la prestaChosen n'a pas été trouvé on a plus besoin de chercher il n'y aura aucun créneau dispo du coup
                }
            });
            if(!isALLPrestationChosenAvailable)return {};
            if( availability.personnel.length >0){
                availability.personnel.forEach((perso) => {
                    if(prestationTime ){ // Si on a une durée de prestation on refiltre nos horaires
                        _horaireDetails[perso] = _horaireDetails[perso].filter((horaire) => {
                            for (let i = 0; i < prestationTime; i += 15) { //Calcul chaque horaire pr la presta voir si tt les horaires sont dispo 
                                const heureTest = new Date(horaire);
                                heureTest.setMinutes(heureTest.getMinutes()+i);
                                if (!_horaireDetails[perso].some(h => h.getTime() === heureTest.getTime())) {
                                    return false; // L'heure testée n'est pas disponible, donc l'horaire n'est pas disponible
                                }
                            }
                            return true;
                        });
                    }
                });

                const total = [];
                availability.personnel.forEach((perso) => { //On fait la grille total, (si pour 1 horaire peronne n'est libre on enlève l'horaire)
                    _horaireDetails[perso].forEach((element) => {
                        if (!total.find((_d) => _d.getTime() === element.getTime())) total.push(element);
                    });
                });
            _horaireDetails.total = total.sort((a, b) => a.getTime() - b.getTime());
            }else{
                if(prestationTime){ // Si on a une durée de prestation on refiltre nos horaires
                    _horaireDetails["unknown"] = _horaireDetails["unknown"].filter((horaire) => {
                        for (let i = 0; i < prestationTime; i += 15) { //Calcul chaque horaire pr la presta voir si tt les horaires sont dispo 
                            const heureTest = new Date(horaire);
                            heureTest.setMinutes(heureTest.getMinutes()+i);
                            if (!_horaireDetails["unknown"].some(h => h.getTime() === heureTest.getTime()) ) {
                                return false; // L'heure testée n'est pas disponible, donc l'horaire n'est pas disponible
                            }
                        }
                        return true;
                    });
                }
            }
        }
        return _horaireDetails;
    };

    return (
        <Context.Provider value={[ctx, {updateCurrentPrestataireId, initCurrentAvailibility, updateReservation, addReservation, removeReservation, addAvailabiliy, updateAvailabiliy, removeAvailabiliy, initCurrentPrestataire, updateCurrentPrestataire, deleteCurrentPrestataire, addPrestation, updatePrestation, removePrestation, addPrestataire, removePrestataire, updatePrestataire, updatePage, returnStartPage, updateChosenService, updateCurrentWeekStart, getAvailabiltyObject,addCategorie,updateCategorie,deleteCategorie }]}>
            {children}
        </Context.Provider>
    );
};

Provider.propTypes = {
    children: PropTypes.element,
};


const useServiceReservation = () => useContext(Context);
export default useServiceReservation;
export { Provider, Context };