import React, {useState} from "react";
import PropTypes from "prop-types";
import { NumberVariations, SimpleStatComponent} from "../components";
import Card from "lib/components/Stats/Card";
import {TimeChart} from "../components";
import useStats from "lib/Stats/statsContext";
import {faImage} from "@fortawesome/free-solid-svg-icons";

const infos = {
  actu : {
    establishment: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre d'actualités publiées sur la période sélectionnée. \n"}
                         <b>{"2) Pourcentage:"}</b> {" Indique les variations du nombre d'actualités publiées entre cette période et la période précédente. \n"}
                   </div>,
    groupement: <div> <b>{"1) Nombre:"}</b> {" Indique le nombre total d'actualités publiées sur la période sélectionnée. \n"}
                      <b>{"2) Pourcentage:"}</b> {" Indique les variations du nombre d'actualités publiées entre cette période et la période précédente. \n"}
                      <b>{"3) Moyenne:"}</b> {" Indique le nombre moyen d'actualités publiées par résidence. \n"}
                      <b>{"4) Tableau:"}</b> {" Indique pour chaque résidence, le nombre d'actualités publiées sur la période donnée, avec les variations entre cette période et la période précédente."}</div>
  },
  publication :     
    <div> <b>{"1) Graphique:"}</b> {" Représente graphiquement le nombre de publications d'actualités par jour pendant la période sélectionnée. \n"}</div>,
};

const Actualites = () => {
  const [ctx, dispatch, hasFeature] = useStats();

  const NumberComponent = hasFeature("statistics") ? NumberVariations : SimpleStatComponent;


  return (
    <div style={{marginTop: 30, display: "flex", flexDirection: "row", justifyContent: "space-around", flexWrap: "wrap", gap: 40, paddingBottom: 30}}>    
      <NumberComponent path="actus/count" title="Nombre d'actualités" info={ctx?.targetType === "establishment" ? infos.actu.establishment : infos.actu.groupement} icon={faImage} iconBackgroundColor={"#B0DDAF"}/>
      {hasFeature("statistics") ? 
      <>
        <Card title="Répartition des publications" info={infos.publication} size="lg">
          <TimeChart dates={ctx.dates} name="nombre de publications" data1={ctx.data1} data2={ctx.data2} path="actus/repartition"/>
        </Card>
      </>
      : null}
      
    </div>
  );
};


export default Actualites;
