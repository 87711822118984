import React, { useState } from "react";

import CreateEditNews from "./components/CreateEditNews";
import NewsCard from "./components/NewsCard";
import { PlusCircle } from "react-feather";
import { Button, Spinner } from "../../../lib/components";
import moment from "moment";
import styles from "../../../assets/scss/pages/news.module.scss";
import useGroupementNews from "./useGroupementNews";

const NewsGroupement = () => {
  const { data, loading } = useGroupementNews();
  const [isOpen, setIsOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);

  const [showAll, setShowAll] = useState(false);

  const onClickNews = (_item) => {
    setItemSelected(_item);
    setIsOpen(true);
  };


  return (
    <div className={styles.container}>

      <div className={styles.body}>

        <div className={styles.createCard} onClick={() => setIsOpen(true)}>
          <PlusCircle color="#4A0956" size={52} />
          <span>Créer une actualité</span>
        </div>

        {loading ? null : <>
        
                {data.filter(i => moment(i.date.toDate()).isBetween(moment().startOf("month"), moment().endOf("month")))
                    .sort((a, b) => moment(b.date.toDate()).format("YYYYMMDD") - moment(a.date.toDate()).format("YYYYMMDD"))
                    .map((item) => (
                <NewsCard key={item.uid} item={item} onClick={onClickNews} />
                ))}
                {showAll ? data.filter(i => !moment(i.date.toDate()).isBetween(moment().startOf("month"), moment().endOf("month")))
                            .sort((a, b) => moment(b.date.toDate()).format("YYYYMMDD") - moment(a.date.toDate()).format("YYYYMMDD"))
                            .map((item) => (
                <NewsCard key={item.uid} item={item} onClick={onClickNews} />
                )) : null}
            {data.filter(i => !moment(i.date.toDate()).isBetween(moment().startOf("month"), moment().endOf("month"))).length > 0 && !showAll ? 
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Button color="primary" onClick={() => setShowAll(true)}>Voir les anciennes actus</Button>
                </div>
            : null}
        
        </>}
    </div>

      <CreateEditNews
        item={itemSelected}
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(false);
          setItemSelected(null);
        }}
      />
    </div>
  );
};

export default NewsGroupement;
