import { useState, useEffect, useCallback } from "react";

const removeAccents = str =>
  str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

const compare = (searchValue, value) => {
    if(value && searchValue){
      const _value = removeAccents(value.trim().toLowerCase());
      const _searchValue = removeAccents(searchValue.trim().toLowerCase());
      return _value.includes(_searchValue);
    }
    return false;
  };

const useSearch = (array, value, searchParameters) => {
  const [filtered, setFiltered] = useState([]);

useEffect(()=>{
    if(value !== "" && array && array.length > 0){
      let _array = [...array];

      if(searchParameters && searchParameters.length > 0){
        let resultArrays = [];
        let _values = [value];
        const _valuesList = value?.trim().split(" ");

        if(_valuesList.length > 0)_values =_values.concat(_valuesList);

        _values.forEach(_value => {
          searchParameters.forEach(element => {
            resultArrays.push(_array.filter((a)=> compare(_value, a[element])));
          });
        });

        let concatArray = [];
        resultArrays.forEach(_list => {
          concatArray = concatArray.concat(_list);
        });

        const occurrences = concatArray.reduce((acc, obj) => {
          const key = JSON.stringify(obj); // On utilise JSON.stringify pour créer une clé unique pour chaque objet
          acc[key] = {num: (acc[key] || 0) + 1, obj};
          return acc;
        }, {});

        const sortByOccurrences = Object.keys(occurrences).sort((a, b) => occurrences[b].num - occurrences[a].num);

        _array = sortByOccurrences.map((key) => occurrences[key].obj);
        // let concatArray = [];
        // resultArrays.forEach(_list => {
        //   concatArray = concatArray.concat(_list);
        // });

        // _array = concatArray.filter((objet, index) => {
        //   return concatArray.indexOf(objet) === index;
        // });
      }else{
        _array = _array.filter((a)=> compare(value, a));
      }

      setFiltered(_array);
    }else{
        setFiltered(array);
    }
  },[value,array,searchParameters]);

  return { filtered };
};

export default useSearch;