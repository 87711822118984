import React from "react";

import Basic_useMenu, {Provider as Basic_MenuProvider} from "./Basic/Context/context";

import Heurus_useMenu, {Provider as Heurus_MenuProvider} from "./Specific/heurus/Context/context";
import useUI from "hooks/ui.hook";

const MenuProvider = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "heurus") return <Heurus_MenuProvider {...props} />;
    return <Basic_MenuProvider {...props} />;
};

const useMenu = () => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "heurus") return Heurus_useMenu();
    return Basic_useMenu();
};

export { useMenu, MenuProvider };