import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import styles from "lib/assets/scss/component/stats/establishmentSelector.module.scss";

import { Select, Checkbox, DatePicker, Button } from "lib/components";
import { ArrowLeft, ArrowRight } from "react-feather";
import useStats from "lib/Stats/statsContext";
import useUI from "hooks/ui.hook";


const EstablishmentSelector = () => {
  const [ctx, dispatch] = useStats();
  const [ui] = useUI();
  const [options, setOptions] = useState([
    {value: "all", label: "Tous", type: "all"},
  ]);
  const [value, setValue] = useState(options[0]);

  useEffect(()=>{
    if(ctx.data1Raw){
      console.log(ctx.data1Raw);
        let _options = [{value: "all", label: "Tous", type: "all"}];

        if(ctx.data1Raw.regions && !ui.user.region){
          Object.keys(ctx.data1Raw.regions).forEach(element => {
            _options.push({value: element, label: element, type: "region"});
        });
        }

        ctx.data1Raw.establishments.forEach(element => {
            _options.push({value: element.id, label: element.name, type: "establishment"});
        });
    
        if(options.length != _options.length){
            setOptions(_options);
        }
    }
  },[ctx.data1Raw]);


  useEffect(()=>{
    updateEstablishment(value.value, value.type);
  },[value]);

  
  const updateEstablishment = (etab, type) => {
    if(ctx.data1 && ctx.data2){
      if(type === "all"){
        dispatch({ type: "setProperty", property : "data1", value: ctx.data1Raw});
        dispatch({ type: "setProperty", property : "data2", value: ctx.data2Raw});
      }else if(type === "region"){
        const _data1 = ctx.data1Raw.regions[etab];
        const _data2 = ctx.data2Raw.regions[etab];
        if(_data1 && _data2){
          dispatch({ type: "setProperty", property : "data1", value: _data1});
          dispatch({ type: "setProperty", property : "data2", value: _data2});
        }
      }else{
        const _data1 = findEstablishmentData(ctx.data1Raw, etab);
        const _data2 = findEstablishmentData(ctx.data2Raw, etab);
        if(_data1 && _data2){
          dispatch({ type: "setProperty", property : "data1", value: _data1});
          dispatch({ type: "setProperty", property : "data2", value: _data2});
        }
      }
    }
    dispatch({ type: "setProperty", property : "target", value: etab});
    dispatch({ type: "setProperty", property : "targetType", value: type});

  };

  const onChange = (e) => {
    setValue(e);
  };
  
  return (
    <div className={styles.container}>
        <div className={styles.headBar}>
                <Select 
                onChange={onChange}
                value={value}
                label="Établissement / Région"
                options={options}
                labelStyle={{marginBottom: 0}}
            />
        </div>
    </div>
  );
};


const findEstablishmentData = (data, id) => {
  const etabs = data.establishments;
  let _data = null;
  etabs.forEach(_etab => {
    if(_etab.id === id)_data = _etab;
  });
  return _data;
};


export default EstablishmentSelector;