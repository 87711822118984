import React, {createContext, useReducer, useEffect, useContext, useCallback, useState} from "react";
import PropTypes from "prop-types";
import firebase from "firebase";
import {toast} from "react-toastify";
import debounce from "lodash.debounce";
import useMicroService from "helpers/microService";

const firestore = firebase.firestore();
const FieldValue = firebase.firestore.FieldValue;

const Ctx = createContext();

const Reducer = (state, action) => {
  switch(action.type) {
  case "setIsLoading":
    return {...state, isLoading: action.isLoading};
  case "setUser":
    return {...state, user: action.user};
  case "setEstablishments":
    return {...state, establishments: action.establishments};
  case "setCognitives":
    return {...state, cognitives: action.cognitives};
  case "setGroupement":
    return {...state, groupement: action.groupement};
  case "setCtx":
    return {...action.ctx};
  default:
    return {...state};
  }
};

const Default = {
  isLoading: true,
  error: {},
  state: null,

  //fonctionnement
  establishments: [],
  cognitives: [],
  groupement: null,//current groupement of user establishment
};

const Provider = ({children, id}) => {
  const [state, dispatch] = useReducer(Reducer, Default);
  const [userBuff, setUserBuff] = useState(null);
  const execMicroService = useMicroService();

  useEffect(() => {
    (async () => {
      try {
        const user = await firestore.collection("users").doc(id).get();
        const establishments = await firestore.collection("establishments").get();
        const cognitives = await firestore.collection("users").doc(id).collection("cognitives").get();

        dispatch({
          type: "setCtx", 
          ctx: {
            user: {uid: user.id, ...user.data()},
            cognitives: cognitives.docs.map(c => ({uid: c.id, ...c.data()})),
            establishments: establishments.docs.map(e => ({uid: e.id, ...e.data()})),
            isLoading: false,
            error: {},
          }
        });
        setUserBuff({uid: user.id, ...user.data()});
      } catch (error) {
        console.log("error", error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!state?.user?.establishment || !state?.establishments) return;
      const establishment = state.establishments.find(e => e.uid === state.user.establishment);
      const groupement = await firestore.collection("groupements").where("establishments", "array-contains", establishment.uid).get();
      if (groupement.empty) return;
      dispatch({type: "setGroupement", groupement: {uid: groupement.docs[0].id, ...groupement.docs[0].data()}});
    })();
  }, [state?.user?.establishment, state?.establishments]);

  //autosave
  const debouncedSave = useCallback(
    debounce(async (formData) => {
      if (!formData || !userBuff || JSON.stringify(formData) === JSON.stringify(userBuff)) return;
      const {uid, ...user} = formData;
      firestore.collection("users").doc(uid).update(user);
      setUserBuff(formData);
      toast.success("save");
    }, 2000), [userBuff]
  );
  useEffect(() => debouncedSave(state.user), [state.user]);

  const _public = {
    state,
    dispatch,
    save: async () => {
      try {
        await firestore.collection("users").doc(id).update(state.user);
        toast.success("Utilisateur mis à jour");
      } catch (error) {
        console.log("error", error);
      }
    },
    deletUser: async (uid) => {

      const _user = await firestore.collection("users").doc(uid).get();

      const _etabId = _user.data().establishment;

      if(_etabId){
          const batch = firestore.batch();

          // Supprimer toutes les réservations de cet utilisateur :
          let animations = await firestore
          .collection("establishments")
          .doc(_etabId)
          .collection("blocks")
          .doc("planning")
          .collection("events")
          .get();
          let menus = await firestore
          .collection("establishments")
          .doc(_etabId)
          .collection("blocks")
          .doc("menu")
          .collection("menu")
          .get();

          animations.forEach((doc) => {
          let event = doc.data();

          if (event.reservation && event.reservation.includes(uid)) {
            batch.update(doc.ref, {
              reservation: FieldValue.arrayRemove(uid),
            });
          }
          });

          menus.forEach((doc) => {
          let menu = doc.data();

          if (menu.reservation && menu.reservation[uid]) {
            batch.update(doc.ref, {
              ["reservation." + uid]: FieldValue.delete(),
            });
          }
          });

          await batch.commit();
      }
     

      await execMicroService("deleteUser", { uid: uid });

      toast.success("L'utilisteur a bien été supprimé");

      return true;
    },
  };

  return (
    <Ctx.Provider value={_public}>
      {children}
    </Ctx.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
};

const useCtx = () => {
  const ctx = useContext(Ctx);
  if (ctx === undefined) {
    throw new Error("useCtx must be used within a Provider");
  }
  return ctx;
};

export {Ctx, Provider, useCtx};
export default useCtx;
