import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {ImagePickerService, Input} from "lib/components";
import styles from "assets/scss/cultureQuizzQuestionTypes/trueFalse.module.scss";
import ImagePickerCrop from "lib/components/Form/ImagePickerCrop";

const TrueFalse = (props) => {
  const {question, onChange, setImageLoaderForm} = props;

  const _onChange = (key, value) => onChange({...question, [key]: value});
  const _batchChange = (obj) => onChange({...question, ...obj});

  return (
    <div className={styles.container}>
      <Input value={question?.title ?? ""}
        onChange={e => _onChange("title", e.target.value)}
        placeholder="Titre de la question"
        className={styles.questionTitle} />

      <div className={styles.answers}>
        <span style={{cursor: "pointer"}} className={`${styles.answer} ${question.answer === true ? styles.isCorrect : ""}`}  onClick={() => _onChange("answer", true)}>VRAI</span>
        <span style={{cursor: "pointer"}} className={`${styles.answer} ${question.answer === false ? styles.isCorrect : ""}`} onClick={() => _onChange("answer", false)}>FAUX</span>
      </div>

      <ImagePickerService
        max={1}
        onChange={e => {
          console.log(e);
          if (typeof(e) === "string"){
            _batchChange({media: e, mediaType: "image"});
          } else if (typeof(e) === "object" && e && e.urls && e.urls.raw){
            _batchChange({media: e.urls.raw, mediaType: "image"});
          }
          else {
            _batchChange({media: "", mediaType: ""});
          }
        }}
        value={question?.media ?? null}
        theme="grey"
      />

      <Input value={question?.explications ?? ""}
        onChange={e => _onChange("explications", e.target.value)}
        placeholder="Explications"
        className={styles.explanation} />
    </div>
  );
};

TrueFalse.propTypes = {
  question : PropTypes.object.required,
  onChange : PropTypes.func.required,
  setImageLoaderForm : PropTypes.func.required
};

export default TrueFalse;