// CULTURE QUIZZ

export const COLOR_QUIZZ_BACKGROUND = "#E9DFCE";
export const COLOR_QUIZZ_DIV = "#F8F4F3";

export const COLOR_QUIZZ_SELECTED = "#3fb7cd";

export const COLOR_PALETTE_RESULT = ["#468729","#D03542"];

//export const COLOR_PALETTE_1 = ["#eb9042","#3a81d7","#4f9c6d","#bc426a"];
//export const COLOR_PALETTE_2 = ["#f5c69e","#8fb7e8","#8cc5a2","#d78da5"];
//export const COLOR_PALETTE_1 = ["#ff00e0","#eb9042","#3a81d7","#bc426a"];
//export const COLOR_PALETTE_2 = ["#ff66ec","#f5c69e","#8fb7e8","#d78da5"];

// Image slack 3 :

//export const COLOR_PALETTE_1 = ["#a21132","#1b1731","#1f615b","#dece9c"];
//export const COLOR_PALETTE_2 = ["#e9305a","#413876","#38aea4","#f8f4e9"];

// Image slack 2 :
export const COLOR_PALETTE_1 = ["#ffa988","#799eaf","#655989","#d26087"];
export const COLOR_PALETTE_2 = ["#ffcebb","#b9ccd5","#9a91b7","#e8b0c3"];