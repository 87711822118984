
import React, { useEffect, useState, useMemo } from "react";
import {
    MultiSelect,
    Tag
} from "lib/components";
import PropTypes from "prop-types";
import featuresOptions from "enums/features";
import rolesOptions from "enums/roles";

import styles from "../scss/restrictions.module.scss";
import usePatchnote from "../context";

/*
{
    type: "block",
    restrictions: [{
        type: "" // application, feature, role, groupement, establishment,
        value ""
    }],
    content: [
        {}
    ],
    title : ""
}

*/
const colors = [{ type: "application", color: "#ABB800" }, { type: "feature", color: "#FF4242" }, { type: "role", color: "#F910F1" }, { type: "groupement", color: "#645DD7" }, { type: "establishment", color: "#00CCC5" }];

const applications = ["major", "major-connect", "major-connect-web", "major-totem", "major-tv", "backend", "resto-by-major"];


export const RestrictionDisplay = ({ restriction }) => {
    const [ctx] = usePatchnote();

    const _value = useMemo(() => {
        if (restriction?.value) {
            console.log(restriction);
            switch (restriction?.type) {
                case "application":
                    return restriction?.value;
                case "feature":
                    return featuresOptions.find(_f => _f.value === restriction?.value)?.label ?? "";
                case "role":
                    return rolesOptions.find(_f => _f.value === restriction?.value)?.label ?? "";
                case "groupement":
                    return ctx.groupementOptions.find(_f => _f.value === restriction?.value)?.label ?? "";
                case "establishment":
                    return ctx.establishmentOptions.find(_f => _f.value === restriction?.value)?.label ?? "";
                default:
                    break;
            }
            return "";
        } else {
            return "";
        }
    }, [restriction, featuresOptions, rolesOptions, ctx.groupementOptions, ctx.establishmentOptions]);

    if (restriction?.value) {
        return (<Tag style={{ backgroundColor: colors.find(_c => _c.type === restriction.type)?.color ?? "#EEEEEEE" }}>{_value}</Tag>);
    } else {
        return null;
    }

};

const Restrictions = ({ parentRestrictions, currentRestrictions, setRestrictions, search }) => {
    // console.log(parentRestrictions, currentRestrictions, setRestrictions);
    const _restrictions = [...parentRestrictions, ...currentRestrictions];
    const [ctx] = usePatchnote();

    const onChange = (type, list) => {
        if (currentRestrictions.find(_r => _r.type === type)) {
            const _currentRestrictions = [...currentRestrictions];

            const _index = _currentRestrictions.findIndex(_a => _a.type === type);

            if (list.length > 0)
                _currentRestrictions[_index] = { type, value: list.map(_v => _v.value) };
            else _currentRestrictions.splice(_index, 1);

            setRestrictions(_currentRestrictions);
        } else {
            setRestrictions([...currentRestrictions, { type, value: list.map(_v => _v.value) }]);
        }
    };

    return (
        <div className={styles.restrictionContainer}>

            {search ?
                <div className={styles.text}>
                    Appliquer des filtres pour effectuer une recherche précise sur des éléments des applications.
                </div> :
                <div className={styles.text}>
                    Les restrictions que vous allez sélectionner affecteront tout le bloc, ainsi que ses sous composants. Vous pouvez créer de nouvelles restrictions dans les composants enfants.
                </div>
            }

            {/* Application Selector */}

            <div>
                <MultiSelect
                    disabled={parentRestrictions.find(_r => _r.type === "application")}
                    onChange={e => onChange("application", e)}
                    value={_restrictions.find(_r => _r.type === "application")?.value?.map(_a => { return { value: _a, label: _a }; })}
                    options={applications.map(_a => { return { value: _a, label: _a }; })}
                    placeholder="Sélectionnez les applications concernées"
                    label="Applications" />
            </div>

            {/* Feature Selector */}

            <div>
                <MultiSelect
                    disabled={parentRestrictions.find(_r => _r.type === "feature")}
                    onChange={e => onChange("feature", e)}
                    value={_restrictions.find(_r => _r.type === "feature")?.value?.map(_a => { return featuresOptions.find(_f => _f.value === _a); })}
                    options={featuresOptions}
                    placeholder="Sélectionnez les fonctionnalités concernées"
                    label="Fonctionnalités" />
            </div>


            {/* Role Selector */}

            <div>
                <MultiSelect
                    disabled={parentRestrictions.find(_r => _r.type === "role")}
                    onChange={e => onChange("role", e)}
                    value={_restrictions.find(_r => _r.type === "role")?.value?.map(_a => { return rolesOptions.find(_f => _f.value === _a); })}
                    options={rolesOptions}
                    placeholder="Sélectionnez les roles concernées"
                    label="Roles" />
            </div>


            {/* Groupement Selector */}

            {ctx.groupementOptions ?
                <div>
                    <MultiSelect
                        disabled={parentRestrictions.find(_r => _r.type === "groupement")}
                        onChange={e => onChange("groupement", e)}
                        value={_restrictions.find(_r => _r.type === "groupement")?.value?.map(_a => { return ctx.groupementOptions.find(_f => _f.value === _a); })}
                        options={ctx.groupementOptions}
                        placeholder="Sélectionnez les groupements concernées"
                        label="Groupement" />
                </div>
                : null}



            {/* Establishment Selector */}

            {ctx.establishmentOptions ?
                <div>
                    <MultiSelect
                        disabled={parentRestrictions.find(_r => _r.type === "establishment")}
                        onChange={e => onChange("establishment", e)}
                        value={_restrictions.find(_r => _r.type === "establishment")?.value?.map(_a => { return ctx.establishmentOptions.find(_f => _f.value === _a); })}
                        options={ctx.establishmentOptions}
                        placeholder="Sélectionnez les établissements concernées"
                        label="Etablissement" />
                </div>
                : null}
        </div>
    );
};


Restrictions.propTypes = {
    parentRestrictions: PropTypes.array,
    currentRestrictions: PropTypes.array,
    setRestrictions: PropTypes.func,
    search: PropTypes.bool
};

RestrictionDisplay.propTypes = {
    restriction: PropTypes.object,
};

export default Restrictions;