/* eslint-disable react/prop-types */
import {useEffect, useMemo, useState} from "react";
import useMicroService from "helpers/microService";
import { Container, Row, Col, Table, Input, Modal, ModalHeader, ModalBody } from "lib/components";
import PropTypes from "prop-types";
import useBornes from "./useBornes";
import { Info } from "react-feather";
import {useDebounce} from "@uidotdev/usehooks";


const BorneItem = ({item}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <tr>
      <td>{item.name} ({item.address}) <Info onClick={() => setIsOpen(!isOpen)} style={{cursor: "pointer"}} /></td>
      <td>{item?.groupement?.name}</td>
      <td>{item?.establishment?.name}</td>
      <td>{item.computedLastHandshake}</td>
      <td style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
      {/* check if item.latestHandshakeAt is inforior at 10min*/ }
      <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
        <div style={{width: 25, height: 25, backgroundColor: item.vpnState?.color, borderRadius: 50, display: "flex", justifyContent: "center", alignItems: "center"}}>
          <div style={{width: 15, height: 15, backgroundColor: item.appState?.color, borderRadius: 50}}>
          </div>
        </div>
        <span style={{textAlign: "center", marginTop: 3, fontSize: 12}}>{item.vpnState.text}</span>
        <span style={{textAlign: "center", marginTop: 3, fontSize: 12}}>{item.appState.text}</span>
      </div>
      </td>
      <td>
        launcher: {item.launcherVersion ?? "N/A"}<br />
        app: {item.version ?? "N/A"}
      </td>
      <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} size="lg">
        <ModalHeader>{item.name} ({item.address})</ModalHeader>
        <ModalBody>
          <h2>Details de la Borne</h2>
          <ul>
            <li>Adresse: {item.address}</li>
            <li>Establishment: {item?.establishment?.name}</li>
            <li>Groupement: {item?.groupement?.name}</li>
            <li>Dernière connexion: {item.latestHandshakeAt}</li>
            <li>Version du lanceur: {item.launcherVersion}</li>
            <li>URL en cours: {item.location}</li>
            <li>Nom: {item.name}</li>
            <li>Date lisible: {item.readableDate}</li>
            <li>Heure lisible: {item.readableHour}</li>
            <li>État: {item.state}</li>
            <li>Horodatage VPN: {item.timestamp}</li>
            <li>Type: {item.type}</li>
            <li>Utilisateur: {item.user}</li>
            <li>Utilisateur: {item.userId}</li>
            <li>Version: {item.version}</li>
          </ul>
        </ModalBody>
      </Modal>
    </tr>
  )
}

const Bornes = () => {
  const [search, setSearch] = useState("");
  const filter = useMemo(() => ({filter: {search}}), [search]);
  const debouncedSearch = useDebounce(filter, 500);
  
  const bornes = useBornes(debouncedSearch);

  return (
    <div style={{width: "100%"}}>
      <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <h1>Bornes</h1>
        <div>
          <Input placeholder="Search..." value={search} onChange={e => setSearch(e.target.value)} />
        </div>
      </div>

      <Container><Row><Col lg={12}>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Groupement</th>
              <th>Etablissement</th>
              <th>Latest Handshake At</th>
              <th>Connected</th>
              <th>Version</th>
            </tr>
          </thead>
          <tbody>
            {bornes.map(i => <BorneItem item={i} key={i.address} />)}
          </tbody>
        </Table>
      </Col></Row></Container>

    </div>
  );
}

export default Bornes;