import React from "react";
import {Spinner as BSpinner} from "reactstrap";
import styles from "../assets/scss/component/spinner.module.scss";
import PropTypes from "prop-types";

const Spinner = (props) => {
  const {color, size, className, style} = props;

  const colorClass = color === "primary"   ? styles.primary   :
    color === "secondary" ? styles.secondary :
      color === "success"   ? styles.success   :
        color === "warning"   ? styles.warning   :
          color === "info"      ? styles.info      :
            color === "light"     ? styles.light     : 
              color === "danger"    ? styles.danger    : styles.primary;
  
  return (
    <BSpinner style={style} size={size} className={`${styles.spinner} ${colorClass} ${className}`}/>
  );
};

Spinner.defaultProps = {
  size: "md",
  color: "primary",
  style: {}
};

Spinner.propTypes = {
  size      : PropTypes.oneOf(["sm", "md"]),
  color     : PropTypes.oneOf(["primary", "secondary", "success", "warning", "info", "danger", "light"]),
  className : PropTypes.string,
  style : PropTypes.any
};

export default Spinner;