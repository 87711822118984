
const roles = [
  {label: "Famille",                   value: "family"         , color: "#c11cad"},
  {label: "Résident",                  value: "senior"         , color: "#277da1"},
  {label: "Résident (borne)",          value: "seniorTotem"    , color: "#3b498e"},
  {label: "Compte borne",              value: "totem"          , color: "#7fc96b"},
  {label: "Responsable",               value: "owner"          , color: "#f8961e"},
  {label: "Super Admin",               value: "superadmin"     , color: "#e63232"},
  {label: "Collaborateur",             value: "employee"       , color: "#43aa8b"},
  {label: "Administrateur Groupement", value: "groupementAdmin", color: "#f3722c"},
  {label: "Administrateur Région",     value: "regionAdmin",     color: "#c7c422"},
  {label: "Restaurant",                value: "restaurant",      color: "#22bcc7"},
];

export const getRoleName = (role) => {
  let roleName="";
  roles.forEach(r => {
    if(r.value == role)roleName = r.label;
  });
  return roleName;
};

export default roles;

/*
famille
résident
collaborateur
Résident via borne
*/