import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import styles from "../../../../assets/scss/pages/news.module.scss";
import { Button } from "../../../../lib/components";

const NewsCard = (props) => {
  const {
    item,
    onClick
  } = props;

  return (
    <div className={styles.newsCard}>
      <div className={styles.cardHeader}>
        <span className={styles.title}>{item.title}</span>
        <div className={styles.tag} style={{ backgroundColor: item.type === "national" ? "#E49F9F" : item.type != undefined ? "#F9DEC9" : "#99B2DD" }}>
          {item.type === "national" ? "Actualité Nationale" : item.type != undefined ? "Actualité Régionale" : "Actualité Locale"}</div>
      </div>
      <div className={styles.imageContainer}>
        {item.image ?
          <img className={styles.image} src={item.image} />
          :
          <span className={styles.description}>{item.description}</span>}
      </div>
      <div className={styles.controlsContainer}>
        <span className={styles.date}>{moment(item.date.toDate()).format("L")}</span>
        <Button onClick={() => onClick(item)}>{item.type != undefined ? "Voir l'actualité" : "Editer"}</Button>
      </div>
    </div>
  );
};

NewsCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

export default NewsCard;