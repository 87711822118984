import React from "react";
import {MenuReservationProvider} from "./router_context";
import { MenuReservationIndex } from "./router_pages";

const MenuReservation = () => {
  return (
    <MenuReservationProvider>
      <MenuReservationIndex />
    </MenuReservationProvider>
  );
};

export default MenuReservation;