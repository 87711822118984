import React from "react";

import Basic_useServiceReservation, {Provider as Basic_ServiceReservationProvider} from "./Basic/Context/serviceReservationCtx";


const ServiceReservationProvider = (props) => {
    return <Basic_ServiceReservationProvider {...props} />;
};

const useServiceReservation = () => {
    return Basic_useServiceReservation();
};

export { useServiceReservation, ServiceReservationProvider };