import PropTypes from "prop-types";
import {
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import styles from "../styles/poem.module.scss";
import useGazette from "../../../context";
import useUI from "hooks/ui.hook";
import { Edit3, Plus, Trash } from "react-feather";
import contentTypes from "../contentTypesHeurus";

const PoemMiniature = (props) => {
  const { onClick } = props;
  return (
    <div onClick={onClick} className={styles.miniature}>
      Page de type &quot;Poème&quot;
    </div>
  );
};

const Poem = (props) => {
  const {
    page,
    onEditPage,
    onRemove,
    onAddContent,
    onRemoveContent,
    onEditContent,
  } = props;

  const [ui] = useUI();
  
  // SI cette condition est remplie, on considère que la page vient d'être créée 
  if(page.title === "") {
    page.title = "Poème";
    
    const defaultContents = {
      // "1": {
      //     contentType: "poem",
      //     format: "poem",
      //     title: "Titre",
      //     content: "Contenu",
      //     author: "Auteur"
      //   }
    };
    Object.keys(defaultContents).forEach(field => {
      page[field] = JSON.stringify(defaultContents[field]);
    });
  }
  return (
    <>
      <div className={styles.head}></div>
      <div className={styles.page}>
        <div className={styles.decorator}></div>
          <div className={styles.content}>
            <div className={styles.pageTitleContainer}>
              <p className={styles.pageTitle}>LE POEME</p>
            </div>
            {page["1"] &&
              contentTypes.find(
                (type) => type.slug === JSON.parse(page["1"]).contentType
              ) ? (
                <div className={`${styles.editoField}`}>
                  <Trash
                    color="red"
                    size={40}
                    className={styles.trash}
                    onClick={() => onRemoveContent("1")}
                  />
                  <Edit3
                    color="blue"
                    size={40}
                    className={styles.edit}
                    onClick={() => onEditContent({ format: "poem", key: "1" })}
                  />
                  {contentTypes
                    .find((type) => type.slug === JSON.parse(page["1"]).contentType)
                    .component({ ...JSON.parse(page["1"]) })}
                </div>
              ) : (
                <div
                  className={`${styles.editoField} ${styles.add}`}
                  onClick={() => onAddContent({ format: "poem", key: "1" })}
                >
                  <Plus size={120} />
                </div>
              )}
        </div>
        <Trash
          color="red"
          size={40}
          className={styles.bottomTrashIcon}
          onClick={onRemove}
        />
      </div>
    </>
  );
};

const PoemPdf = (props) => {
  const { page, ctx, index, contentTypes } = props;
  const pdfStyles = StyleSheet.create({
    container: {
      width: "100%",
      height: "100%",
      padding: "10px"
    },
    pageDecorator: {
      width: "85%",
      height: "85%",
      position: "absolute",
      right: "0%",
      bottom: "0%",
      backgroundColor: "#1c2140",
    },
    pageCountContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      backgroundColor: "white",
      borderRadius: 25,
      width: 30,
      height: 30,
      bottom: 5,
      right: 5,
    },
    pageCountText: {
      color: "black",
      fontSize: 24,
      fontWeight: 600,
    },
  });

  return (
    <Page size="A4" orientation="portrait">
      <View style={pdfStyles.container}>
        <View style={pdfStyles.pageDecorator}></View>
        {page["1"] &&
          contentTypes.find(
            (type) => type.slug === JSON.parse(page["1"]).contentType
          )
            ? contentTypes
              .find(
                (type) => type.slug === JSON.parse(page["1"]).contentType
              )
              .pdf({settings: ctx.settings, ...JSON.parse(page["1"]) })
            : null}
      </View>
      <View style={pdfStyles.pageCountContainer}>
        <Text style={pdfStyles.pageCountText}>{index + 1}</Text>
      </View>
    </Page>
  );
};
Poem.propTypes = {
  page : PropTypes.any,
  onEditPage : PropTypes.any,
  onRemove : PropTypes.any,
  onAddContent : PropTypes.any,
  onRemoveContent : PropTypes.any,
  onEditContent : PropTypes.any,
};
PoemMiniature.propTypes = {
  onClick : PropTypes.any,
};
PoemPdf.propTypes = {
  page : PropTypes.any,
  ctx : PropTypes.any,
  index : PropTypes.any,
  contentTypes: PropTypes.any,
};

export { Poem, PoemMiniature, PoemPdf };