import React from "react";
import PropTypes from "prop-types";
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import {
  DashboardLayout,
} from "../../lib/layouts";

import DashboardHeader from "../../config/DashboardHeader";
import NoFeatureMenu from "../../config/NoFeatureMenu";

import Home from "./Home";
import ProfilePage from "../Major/Profile";

const NoFeature = ({match}) => {

  return (
    <DashboardLayout menu={<NoFeatureMenu />} header={<DashboardHeader />}>
      <Switch>
        <Route exact path={`${match.url}/one`}><Home /></Route>
        <Route exact path={`${match.url}/profile`}><ProfilePage /></Route>
        <Route path={"*"}><Redirect to={`${match.url}/one`} /></Route>
      </Switch>
    </DashboardLayout>
  );
};

NoFeature.propTypes = {
  match: PropTypes.object.isRequired,
};

export default NoFeature;