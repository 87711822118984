import firebase from "firebase";
import { toast } from "react-toastify";

const firestore = firebase.firestore;


export const getSeniorUsers = async (id, err) => {
    const res = await firestore()
        .collection("users")
        .where("establishment", "==", id)
        .where("role", "in", ["senior", "seniorTotem"])
        .get();

    return res;
};

export const fbAddReservation = ({ui, availabilityId, resaId, data}, err) => {
    firestore()
        .collection("establishments")
        .doc(ui.user.establishment)
        .collection("blocks")
        .doc("services")
        .collection("planning")
        .doc(availabilityId)
        .update({
            ["reservations."+resaId]: data
        });

};

export const fbDeleteReservation = ({ui, availabilityId, resaId}, err) => {
    firestore()
        .collection("establishments")
        .doc(ui.user.establishment)
        .collection("blocks")
        .doc("services")
        .collection("planning")
        .doc(availabilityId)
        .update({
            ["reservations."+resaId]: firestore.FieldValue.delete()
        });
};

export const getPlanningOfPrestataireById = async ({ui,prestataireId},err) =>{
   try{
    const res = await firestore()
                        .collection("establishments")
                        .doc(ui.user.establishment)
                        .collection("blocks")
                        .doc("services")
                        .collection("planning")
                        .where("prestataireId", "==", prestataireId)
                        .get();
    let _data = [];
    res.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
    return _data;
   }catch(e){
    console.error(e);
    if(err)err(e);
    else toast.error("Une erreur est survenue");
    return [];
   }
};
export const getAvailabiltyIdByIdAndStartDate = async ({ui,prestataireId,start},err)=>{
    try{
        const res = await firestore()
                            .collection("establishments")
                            .doc(ui.user.establishment)
                            .collection("blocks")
                            .doc("services")
                            .collection("planning")
                            .where("prestataireId", "==", prestataireId)
                            .where("start", "==", start)
                            .get();
                            let _data = null; 
                            res.forEach(doc => {
                                _data = doc.id; 
                                return; 
                            });
                            return _data;
    }catch(e){
        console.error(e);
        if(err)err(e);
        else toast.error("Une erreur est survenue");
        return [];
       }
};