import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "../../assets/scss/component/geocodeAutocomplete.module.scss";
import Input from "./Input";

const GeocodeAutocomplete = ({ service, setCoordinates }) => {
    const [address, setAddress] = useState("");
    const [suggestions, setSuggestions] = useState([]);

    const handleInputChange = async (e) => {
        const input = e.target.value;
        setAddress(input);

        if (input.length > 3) {
            service.autosuggest({
                q: input,
                at: "47,5" // Adjust as needed
            }, (result) => {
                setSuggestions(result.items);
            }, (error) => {
                console.error("Error fetching suggestions:", error);
            });
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = async (suggestion) => {
        setAddress(suggestion.title);
        setSuggestions([]);

        service.geocode({
            q: suggestion.title
        }, (result) => {
            if (result.items && result.items.length > 0) {
                const location = result.items[0].position;
                setCoordinates({ lat: location.lat, lng: location.lng });
            } else {
                console.log("No results found");
            }
        }, (error) => {
            console.error("Error fetching coordinates:", error);
        });
    };

    return (
        <div className={styles.container}>
            <Input
                label="adresse"
                type="text"
                value={address}
                onChange={handleInputChange}
                placeholder="Entrer une adresse"
                className={styles.input}
            />
           {suggestions.length > 0 && <div className={styles.hoverSelector}>
                 {suggestions.map((suggestion, index) => (
                    index < 10 &&
                    <div key={index}
                        className={styles.element}
                        onClick={() => handleSuggestionClick(suggestion)}>
                        {suggestion.title}
                    </div>
                ))}
            </div>}

        </div>
    );
};

GeocodeAutocomplete.propTypes = {
    service: PropTypes.object.isRequired,
    setCoordinates: PropTypes.func.isRequired
};

export default GeocodeAutocomplete;