import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
//import { matchSorter } from "match-sorter";
import { useSticky } from "react-table-sticky";
import "regenerator-runtime/runtime";
import { Download } from "react-feather";

import styles from "../../../assets/scss/pages/reservation/detailedTable.module.scss";

const colors = [styles.background1, styles.background2, styles.background3];

const Table = ({ _columns, _data , colWidth, hasFooter, onClick}) => {
  if(!colWidth)colWidth=150;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (_columns != undefined) setColumns(_columns);
    if (_data != undefined) setData(_data);
  }, [_data, _columns]);

  const defaultColumn = React.useMemo(
    () => ({
      width: colWidth,
    }),
    []
  );
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useBlockLayout,
    useSortBy,
    useSticky
  );

  /*function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }*/

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const footerGroups = headerGroups.slice().reverse();

  return (
    <div style={{userSelect: "none"}}>
      <div className={styles.research}>
        <p>Rechercher</p>
        <input
          value={searchValue || ""}
          onChange={(e) => {
            setSearchValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${preGlobalFilteredRows.length} lignes...`}
          className={styles.input}
        />
      </div>
      <div
        {...getTableProps()}
        className={`${styles.table} ${styles.sticky}`}
      >
      
        <div className={styles.header}>
          {headerGroups.map((headerGroup, index) => (
            <div {...headerGroup.getHeaderGroupProps()} className={styles.tr} key={index}>
              {headerGroup.headers.map((column, index2) => (
                <div key={index + index2} {...column.getHeaderProps(column.getSortByToggleProps())} className={`${styles.th} ${column.depth == 0 ? styles.firstHeader : styles.otherHeader}`}>
                  {column.time ? <div>{column.time}</div> : null}
                  <>
                    {column.render("Header")}
                    {column.export ? <div onClick={(e) => {e.stopPropagation(); column.export(column);}}><Download size={20}/></div> : null}
                    <span className={styles.sort}>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className={styles.body}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <div key={index} {...row.getRowProps()} className={styles.tr}>
                {row.cells.map((cell, index2) => (
                  <div key={index + index2} {...cell.getCellProps()} className={`${styles.td} ${cell.column.canUpdate ? styles.pointer : null} ${cell.column.colorChange != undefined ? colors[cell.column.colorChange%3] : null}`} onClick={() => {if(cell.column.canUpdate)onClick(cell);}}> 
                    {cell.render("Cell")}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        {hasFooter ? 
          <div className={styles.footer}>
            {footerGroups.map((footerGroup, index) => (
              <div key={index} {...footerGroup.getHeaderGroupProps()} className={styles.tr}>
                {footerGroup.headers.map((column, index2) => (
                  <>
                    {column.Footer != undefined && column.Footer != "" ? (
                      <div key={index + index2} {...column.getHeaderProps()} className={`${styles.td} ${styles.footerStyle}`}>
                        {column.render("Footer")}
                      </div>
                    ) : null}
                  </>
                ))}
              </div>
            ))}
          </div> : null}
      </div>
    </div>  
  );
};

Table.propTypes = {
  _columns: PropTypes.array.isRequired,
  _data: PropTypes.array.isRequired,
  colWidth: PropTypes.number,
  hasFooter: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Table;
