import React from "react";
import useUI from "hooks/ui.hook";

import Basic_Banner from "./Basic/Components/Banner";
import Basic_LabelSelector from "./Basic/Components/_labelSelector";
import Basic_MenuCard from "./Basic/Components/_menuCard";
import Basic_MenuInput from "./Basic/Components/_menuInput";
import Basic_ModalReservationChange from "./Basic/Components/ModalReservationChange";


import Ovelia_PrintDayMenu from "./Specific/ovelia/Components/PrintDayMenu";
import Ovelia_MenuCard from "./Specific/ovelia/Components/menuCard";
import Ovelia_MenuInput from "./Specific/ovelia/Components/menuInput";

import Heurus_PrintDayMenu from "./Specific/heurus/Components/PrintDayMenu";
import Heurus_MenuInput from "./Specific/heurus/Components/menuInput";

const Banner = (props) => {
    return <Basic_Banner {...props} />;
};

const LabelSelector = (props) => {
    return <Basic_LabelSelector {...props} />;
};

const MenuCard = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_MenuCard {...props} />;
    return <Basic_MenuCard {...props} />;
};


const MenuInput = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_MenuInput {...props} />;
    if (ui?.groupement?.specific === "heurus") return <Heurus_MenuInput {...props} />;
    return <Basic_MenuInput {...props} />;
};


const ModalReservationChange = (props) => {
    return <Basic_ModalReservationChange {...props} />;
};

const PrintDay = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "ovelia") return <Ovelia_PrintDayMenu {...props} />;
    if (ui?.groupement?.specific === "heurus") return <Heurus_PrintDayMenu {...props} />;
    return null;
};



export { Banner, LabelSelector, MenuCard, MenuInput, ModalReservationChange, PrintDay };