import {Page1Slot, Page1SlotPdf, Page1SlotMiniature} from "./page-1-slot";
import {Page2Slot, Page2SlotPdf, Page2SlotMiniature} from "./page-2-slot";
import {Page3Slot, Page3SlotPdf, Page3SlotMiniature} from "./page-3-slot";
import {Summary, SummaryPdf, SummaryMiniature} from "./Summary";

const pageTypes = [
  {
    slug: "page-1-slot",
    name: "Page 1 Slot",
    component: Page1Slot,
    pdf: Page1SlotPdf,
    miniature: Page1SlotMiniature,
  }, 
  {
    slug: "page-2-slot",
    name: "Page 2 Slot",
    component: Page2Slot,
    pdf: Page2SlotPdf,
    miniature: Page2SlotMiniature,
  },
  {
    slug: "page-3-slot",
    name: "Page 3 Slot",
    component: Page3Slot,
    pdf: Page3SlotPdf,
    miniature: Page3SlotMiniature,
  },
  {
    slug: "summary",
    name: "Sommaire",
    component: Summary,
    pdf: SummaryPdf,
    miniature: SummaryMiniature,
  }
];

const getPageTypeFromSlug = (slug) => {
  return pageTypes.find(type => type.slug === slug);
};

export default pageTypes;
export { getPageTypeFromSlug };