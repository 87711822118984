import firebase from "firebase";
import { toast } from "react-toastify";

const firestore = firebase.firestore;

export const menusInit = async ({ui}, err) => {
  try {
    const doc = await firestore().collection("establishments").doc(ui.user.establishment)
            .collection("blocks").doc("menu").get();
    if (!doc.exists) {
      await firestore().collection("establishments").doc(ui.user.establishment)
              .collection("blocks").doc("menu").set({
                type: "menu"
              });
    }
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
  }
};

//--------------------------  -------------------------------//

export const setMenuBatch = async ({ui, menus}, err) => {
  try {
    const batch = firestore().batch();
    Object.entries(menus).forEach(([uid, data]) => {
      const _data = {...data, created: true};
      batch.set(
        firestore()
          .collection("establishments").doc(ui.user.establishment)
          .collection("blocks").doc("menu")
          .collection("menu").doc(uid),
        _data,
      );
    });
    await batch.commit();
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
  }
};

//--------------------------  -------------------------------//

export const getMenusByPeriod = async ({ui, start, end}, err) => {
  try {
    const res = await firestore()
                        .collection("establishments")
                        .doc(ui.user.establishment)
                        .collection("blocks").doc("menu")
                        .collection("menu")
                        .where("day", ">=", start)
                        .where("day", "<=", end)
                        .get();
    let _data = [];
    res.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
    return _data;
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    return [];
  }
};

//--------------------------  -------------------------------//

export const getMenusByPeriod_rt = ({ui, start, end}, cb, err) => {
  const subscribe = firestore().collection("establishments").doc(ui.user.establishment)
    .collection("blocks").doc("menu")
    .collection("menu")
    .where("day", ">=", start)
    .where("day", "<=", end)
    .onSnapshot(snap => {
      const _data = snap.docs.map(doc => ({uid: doc.id, ...doc.data()}));
      cb(_data);
    }, err ?? (() => {}));
    return subscribe;
};