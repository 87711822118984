import PropTypes from "prop-types";
import React from "react";
import { FormGroup } from "reactstrap";
import {Input} from "lib/components";
import styles from "../styles/poem.contentType.module.scss";
import {
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import "../../../fonts";

const PoemComponent = (props) => {
  const {title, content, author} = props;
  const _content = (content && content.length > 0) ? (
    (content.length <= 200) ? content : content.substring(0, 200) + "..."
  ) : null;
  return (
    <div className={styles.poemContainer}>
      <h3>{title}</h3>
      <p>{_content ? `«${_content}»`: ""}</p>
      <p>Un poème proposé par {author}</p>
    </div>
  );

};

const PoemEditor = (props) => {
  const {onChange, field} = props;
  return (
    <>
      <h5>Saisir un poème</h5>
      <FormGroup>
        <Input value={field.title} onChange={e => onChange("title", e.target.value)} placeholder="Titre" label="Titre" />
      </FormGroup>
      <FormGroup>
        <Input type="textarea" cols={100} rows={10} value={field.content} onChange={e => onChange("content", e.target.value)} placeholder="Contenu" label="Contenu" />
      </FormGroup>
      <FormGroup>
        <Input value={field.author} onChange={e => onChange("author", e.target.value)} placeholder="Poème proposé par" label="Prénom Nom" />
      </FormGroup>
    </>
  );
};

const PoemPdf = (props) => {
  const {title, content, author} = props;
  const pdfStyle = StyleSheet.create({
    globalContainer: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#e7e6e6",
      padding: "10px",
      width: "100%",
      height: "100%",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      border: "2px solid white",
      width: "100%",
      height: "100%",
      padding: "20px",
    },
    pageType: {
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: "18px",
      color: "white",
      backgroundColor: "#e76d90",
      padding: "5px",
      position: "absolute",
      top: "-1%",
      left: "50%",
      transform: "translate(-42%, 0%)",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      marginTop: "30px",
    },
    title: {
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: "22px",
      color: "#1c2140",
      marginBottom: "10px",
    },
    text: {
      fontFamily: "Brandon",
      fontSize: "16px",
      color: "#1c2140",
    },
    author: {
      fontFamily: "Brandon",
      fontSize: "16px",
      color: "#1c2140",
    },

  });

  return (
    <View style={pdfStyle.globalContainer}>
      <View style={pdfStyle.container}>
        <View style={pdfStyle.content}>
          <Text style={pdfStyle.title}>{title}</Text>
          <Text style={pdfStyle.text}>{content}</Text>
        </View>
        <Text style={pdfStyle.author}>Un poème proposé par {author}</Text>
      </View>
      <Text style={pdfStyle.pageType}>LE POEME</Text>
    </View>
  );
};


PoemComponent.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  author: PropTypes.string
};
PoemEditor.propTypes = {
  onChange: PropTypes.any,
  field: PropTypes.any,
};
PoemPdf.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  author: PropTypes.string
};

export { PoemComponent, PoemEditor, PoemPdf };