import React from "react";
import useUI from "hooks/ui.hook";

import Basic_UserList from "./Basic/Pages/UserList";

import Heurus_UserList from "./Specific/Heurus/Pages/UserList";


const UserList = (props) => {
  const [ui] = useUI();

  if(ui?.groupement?.specific === "heurus") return <Heurus_UserList {...props} />;

  return <Basic_UserList {...props} />;
};

export {UserList};