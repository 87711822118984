import React from "react";
import useUI from "hooks/ui.hook";

import Basic_Animation from "./Basic/Pages/index";
import Basic_PlanningWeekExport from "./Basic/Pages/PlanningWeekExport";
import Basic_PlanningMonthExport from "./Basic/Pages/PlanningMonthExport";

const Animations = (props) => {
    return <Basic_Animation {...props} />;
};

const PlanningWeekExport = (props) => {
    return <Basic_PlanningWeekExport {...props} />;
};

const PlanningMonthExport = (props) => {
    return <Basic_PlanningMonthExport {...props} />;
};




export { Animations, PlanningWeekExport, PlanningMonthExport};