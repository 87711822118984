import React, {useState, useEffect, useCallback} from "react";
import {Container, Row, Col, Card, CardHeader, CardBody, CreatableSelect, CardFooter, Button} from "../../../../../../../lib/components";
import useCtx from "../../../Context/ctx";
import { updateUserById } from "services/ressources/user";
import { updateEstablishmentById } from "services/ressources/establishment";
import { toast } from "react-toastify";
import useUI from "hooks/ui.hook";

const Tags = (props) => {
  const {state, dispatch} = useCtx();
  const {user, establishment} = state;
  const [ui] = useUI();

  const [restoInfos, setRestoInfos] = useState([]);
  const [value, setValue] = useState([]);

  useEffect(() => {
    if (establishment?.restoInfos) {
      setRestoInfos(establishment.restoInfos.split(",").map(e => ({label: e, value: e})));
    }
  }, [establishment]);
  

  const onCreate = async (e) => {
    setRestoInfos(prev => [...prev, {label: e, value: e}]);
    updateEstablishmentById({
      id: ui.establishment.uid, 
      data: {
        restoInfos: [...restoInfos, {label: e, value: e}].map(e => e.value).join(",")
      }
    });
  };
  const handleChange = useCallback((key, value) => {
    console.log("KEY", key, "VALUE", value);
    dispatch({type: "setUser", user: {...user, [key]: value.map(e => e.value).join(",")}});
  }, [user]);


  return (
    <Container><Row><Col>
      <Card>
        <CardHeader><h5>Préférences restaurant</h5></CardHeader>
        <CardBody>
          <CreatableSelect
            labels="Tags"
            options={restoInfos}
            onChange={e => handleChange("restoInfos", e)}
            onCreate={onCreate}
            value={user.restoInfos ? user.restoInfos.split(",").map(e => ({label: e, value: e})) : ""}
            placeholder={"Haché, Végétarien, Sans gluten, ..."}

          />
        </CardBody>

      </Card>
    </Col></Row></Container>
  );
};

export default Tags;