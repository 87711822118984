import PropTypes from "prop-types";
import {
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import {Input} from "lib/components";
import { FormGroup } from "reactstrap";
import Person from "../assets/Person.png";

import "../../../fonts";

const QuoteComponent = (props) => {
  const {message} = props;

  return (
    <div style={{overflow: "hidden"}}>
      <p style={{fontWeight: "bold"}}>Le dicton du mois :</p>
      <p>«{message}»</p>
    </div>
  );
};

const QuoteEditor = (props) => {
  const {
    onChange,
    field
  } = props;

  field.message = field.message ?? "Il n'y a pas de dicton ce mois-ci.";
  return (
    <>
      <h5>Ajouter un Dicton à votre gazette</h5>
      <FormGroup>
        <Input type="textarea" cols={100} rows={10} value={field.message} onChange={e => onChange("message", e.target.value)} placeholder="Message" label="Contenu du texte" />
      </FormGroup>
    </>
  );
};

const QuotePdf = (props) => {
  const {message} = props; 

  const pdfStyle = StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#e7e6e6",
      width: "100%",
      // height: "100%",
      // margin: 10,
      padding: 10,
    },
    title: {
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: 16,
      color: "#1c2140"
    },
    quote: {
      fontFamily: "Brandon",
      fontSize: 14,
      color: "#1c2140",
      textAlign: "center"
    },
  });

  return (
    <View style={pdfStyle.container}>
      <Text style={pdfStyle.title}>LE DICTON DU MOIS</Text>
      <Text style={pdfStyle.quote}>« {message} »</Text>
    </View>
  );
};

QuoteComponent.propTypes = {
  message: PropTypes.any,
};
QuoteEditor.propTypes = {
  onChange: PropTypes.any,
  field: PropTypes.any,
};
QuotePdf.propTypes = {
  message: PropTypes.any,
};

export { QuoteEditor, QuoteComponent, QuotePdf };