import React from "react";
import PropTypes from "prop-types";

import styles from "../../assets/scss/layout/dashboard/sidebarImage.module.scss";

const SidebarImage = (props) => {
  const {img, fit} = props;

  return (
    <div className={`${styles.sidebarImage} ${fit === "cover" && styles.cover}`}>
      <img src={img} alt="Logo" />
    </div>
  );
};

SidebarImage.propTypes = {
  img: PropTypes.string,
  fit: PropTypes.oneOf(["partial", "cover"])
};

SidebarImage.defaultProps = {
  fit: "partial"
};

export default SidebarImage;