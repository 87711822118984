import { Edit3, Plus, Trash } from "react-feather";
import PropTypes from "prop-types";
import {
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";

import React, {useMemo} from "react";
import contentTypes from "../contentTypesHeurus";
import styles from "../styles/summary.module.scss";
import useGazette from "../../../context";
import useUI from "hooks/ui.hook";
import Roboto from "../../../../../../fonts/roboto/Roboto-Condensed.ttf";
import RobotoBold from "../../../../../../fonts/roboto/Roboto-BoldCondensed.ttf";
import Brandon from "../../../../../../fonts/brandon/BrandonGrotesque-Regular.ttf";
import BrandonBold from "../../../../../../fonts/brandon/BrandonGrotesque-Bold.ttf";
import Logo from "../assets/Logo.png";
import "../../../fonts";

Font.register({
  family: "Brandon",
  fontWeight: "normal",
  src: Brandon,
});
Font.register({
  family: "Brandon",
  format: "bold",
  src: BrandonBold,
});

Font.register({
  family: "Roboto",
  fontWeight: "normal",
  src: Roboto,
});
Font.register({
  family: "Roboto",
  format: "bold",
  src: RobotoBold,
});

const SummaryMiniature = (props) => {
  const { onClick } = props;

  return (
    <div onClick={onClick} className={styles.miniature}>
      Page de type &quot;Sommaire&quot;
    </div>
  );
};

const Summary = (props) => {
  const {
    page,
    onEditPage,
    onRemove,
    onAddContent,
    onRemoveContent,
    onEditContent,
  } = props;

  const [ctx] = useGazette();
  const [ui] = useUI();
  if(page.title === "") {
    page.title = "Sommaire";
    const defaultContents = {
      // "2": {
      //   contentType: "edito",
      //   format: "edito",
      //   message: "Edito du directeur / de la directrice",
      // }, 
    };

    Object.keys(defaultContents).forEach(field => {
      page[field] = JSON.stringify(defaultContents[field]);
    });
  }

  const summaryPages = useMemo(() => {
      return ctx.pages.filter((page, index) => {
        if(index === 0) return true;
        return page.title && ctx.pages[index - 1].title != page.title;
      });
    }, [ctx.pages.map(page => page.uid)]);
  return (
    <>
      <div className={styles.head}></div>
      <div className={styles.page}>
        <div className={styles.pageHeader}>
          <div className={styles.leftHeader}>
            <p className={styles.establishmentName}>
              {ui?.establishment?.name.toUpperCase()}
            </p>
            <p style={{color: "#ed7d31"}}>{ctx.editionDate ? moment(ctx.editionDate).format("MMMM YYYY") : moment().format("MMMM YYYY")}</p>
          </div>
          <div className={styles.rigthHeader}>
            <p style={{fontFamily: "Brandon", fontSize: 28, fontWeight: "bold", color: "#1c2140"}}>La gazette</p>
            <img src={Logo}/>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.contenu}>
            <div className={styles.summary}>
              <span className={styles.summaryTitle}>Au Sommaire ce mois-ci</span>
              <div className={styles.pageListContainer}>
                {summaryPages
                  .map((page, index) => (
                    <div key={page.uid} className={styles.pageListItem}>
                      <span className={styles.pageNumber}>{index + 1}</span>
                      <span className={styles.pageTitle}>{page.title}</span>
                    </div>
                  ))}
              </div>
            </div>
            {page["1"] &&
            contentTypes.find(
              (type) => type.slug === JSON.parse(page["1"]).contentType && type.slug === "imageHeurus"
            ) ? (
                <div className={`${styles.editoField} ${styles.illustration}`}>
                  <Trash
                    color="red"
                    size={40}
                    className={styles.trash}
                    onClick={() => onRemoveContent("1")}
                  />
                  <Edit3
                    color="blue"
                    size={40}
                    className={styles.edit}
                    onClick={() => onEditContent({ format: "summary", key: "1" })}
                  />
                  {contentTypes
                    .find((type) => type.slug === JSON.parse(page["1"]).contentType)
                    .component({ ...JSON.parse(page["1"]) })}
                </div>
              ) : (
                <div
                  className={`${styles.editoField} ${styles.add} ${styles.illustration}`}
                  onClick={() => onAddContent({ format: "summary", key: "1" })}
                >
                  <Plus size={120} />
                </div>
              )}
          </div>
          <div className={styles.footer}>
          {page["2"] &&
            contentTypes.find(
              (type) => type.slug === JSON.parse(page["2"]).contentType
            ) ? (
                <div className={`${styles.editoField}`}>
                  <Trash
                    color="red"
                    size={40}
                    className={styles.trash}
                    onClick={() => onRemoveContent("2")}
                  />
                  <Edit3
                    color="blue"
                    size={40}
                    className={styles.edit}
                    onClick={() => onEditContent({ format: "edito", key: "2" })}
                  />
                  {contentTypes
                    .find((type) => type.slug === JSON.parse(page["2"]).contentType)
                    .component({ ...JSON.parse(page["2"]) })}
                </div>
              ) : (
                <div
                  className={`${styles.editoField} ${styles.add}`}
                  onClick={() => onAddContent({ format: "edito", key: "2" })}
                >
                  <Plus size={120} />
                </div>
              )}
          </div>
        </div>
        <Trash
          color="red"
          size={40}
          className={styles.bottomTrashIcon}
          onClick={onRemove}
        />
      </div>
    </>
  );
};

const SummaryPdf = (props) => {
  const { page, ctx, index, ui } = props;
  const pdfStyle = StyleSheet.create({
    globalContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
      height: "100%",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      padding: "0% 1% 0% 1%",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginTop: "10px",
      marginBottom: "10px",
    },
    headerLeft: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    headerRight: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "flex-start",
      marginRight: "10px",
      marginTop: "-10px",
      width: "70%",
    },
    establishmentContainer: {
      backgroundColor: "#1c2140",
      width: "100%",
      padding: "30px",
    },
    establishment: {
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: "18px",
      color: "white",
    },
    headerDate: {
      color: "#ec871c",
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: "12px",
    },
    logoImage: {
      width: 150,
      height: 65,
    },
    headerTitle: {
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: "80px",
      color: "#1c2140",
    },
    contentContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    summary: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "30%",
      border: "1px solid #ec871c",
    },
    summaryTitle: {
      textAlign: "center",
      color: "#ec871c",
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: "16px",
    },
    pageList: {
      marginTop: "10px",
    },
    pageListItem: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "80%"
    },
    pageNumber: {
      color: "#ec871c",
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: "14px",
      marginRight: "10px",
    },
    pageTitle: {
      color: "#1c2140",
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: "14px",
    },
    illustrationContainer: {
      width: "65%",
      height: "250px",
      display: "flex",
    },
    decorator: {
      width: "95%",
      height: "90%",
      backgroundColor: "#ec871c",
      position: "absolute",
      bottom: 0,
      right: 0,
    },
    image: {
      marginRight: "10px",
      marginBottom: "10px",
    },
    footerContainer: {
      width: "100%",
      height: "30%",
    }
  });
  const summaryPages = ctx.pages.filter((page, index) => {
    if(index === 0) return true;
    return page.title && ctx.pages[index - 1].title != page.title;
  });
  return (
    <Page size="A4" orientation="portrait">
      <View style={pdfStyle.globalContainer}>
        <View style={pdfStyle.container}>
            <View style={pdfStyle.header}>
              <View style={pdfStyle.headerLeft}>
                <View style={pdfStyle.establishmentContainer}>
                  <Text style={pdfStyle.establishment}>{ui.establishment.name}</Text>
                </View>
                <Text style={pdfStyle.headerDate}>
                  {moment(ctx.createdAt).format("MMMM yyyy").toLocaleUpperCase()}
                </Text>
              </View>
              <View style={pdfStyle.headerRight}>
                <Text style={pdfStyle.headerTitle}>La gazette</Text>
                <Image
                  src={Logo}
                  style={pdfStyle.logoImage}
                />
              </View>
            </View>
            <View style={pdfStyle.contentContainer}>
              <View style={pdfStyle.summary}>
                <Text style={pdfStyle.summaryTitle}>AU SOMMAIRE CE MOIS-CI</Text>
                <View style={pdfStyle.pageList}>
                  {summaryPages
                  .slice(0, page["1"] &&  contentTypes.find((type) => type.slug === JSON.parse(page["1"]).contentType) ? 15 : 25)
                  .map((page, index) => (
                    <View key={page.uid} style={pdfStyle.pageListItem}>
                      <Text style={pdfStyle.pageNumber}>{parseInt(index) + 1}</Text>
                      <Text style={pdfStyle.pageTitle}>{page.title}</Text>
                    </View>
                  ))}
                </View>
              </View>
              <View style={pdfStyle.illustrationContainer}>
                <View style={pdfStyle.decorator}></View>
                <View style={pdfStyle.image}>
                  {page["1"] &&
                    contentTypes.find(
                      (type) => type.slug === JSON.parse(page["1"]).contentType && type.slug === "imageHeurus"
                    )
                      ? contentTypes
                        .find(
                          (type) => type.slug === JSON.parse(page["1"]).contentType
                        )
                        .pdf({settings: ctx.settings, ...JSON.parse(page["1"]) })
                      : null}
                </View>
              </View>
            </View>
        </View>
        <View style={pdfStyle.footerContainer}>
            {page["2"] &&
              contentTypes.find(
                (type) => type.slug === JSON.parse(page["2"]).contentType
              )
                ? contentTypes
                  .find(
                    (type) => type.slug === JSON.parse(page["2"]).contentType
                  )
                  .pdf({settings: ctx.settings, ...JSON.parse(page["2"]) })
                : null}
          </View>
      </View>
    </Page>
  );
};

Summary.propTypes = {
  page : PropTypes.any,
  onEditPage : PropTypes.any,
  onRemove : PropTypes.any,
  onAddContent : PropTypes.any,
  onRemoveContent : PropTypes.any,
  onEditContent : PropTypes.any,
};

SummaryMiniature.propTypes = {
  onClick : PropTypes.any,
};

SummaryPdf.propTypes = {
  page : PropTypes.any,
  ctx : PropTypes.any,
  index : PropTypes.any,
  ui : PropTypes.any,
};

export { Summary, SummaryMiniature, SummaryPdf };
