import PropTypes from "prop-types";
import {
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import {Input} from "lib/components";
import { FormGroup } from "reactstrap";
import Person from "../assets/Person.png";

import "../../../fonts";

const EditoComponent = (props) => {
  const {message} = props;

  return (
    <div>
      <h5>Edito de la directrice / du directeur :</h5>
      <p>«{message}»</p>
    </div>
  );
};

const EditoEditor = (props) => {
  const {
    onChange,
    field
  } = props;

  field.message = field.message ?? "Edito du directeur / de la directrice";
  return (
    <>
      <h5>Ajouter un edito à votre gazette</h5>
      <FormGroup>
        <Input type="textarea" cols={100} rows={10} value={field.message} onChange={e => onChange("message", e.target.value)} placeholder="Message" label="Contenu du texte" />
      </FormGroup>
    </>
  );
};

const EditoPdf = (props) => {
  const {message} = props; 

  const pdfStyle = StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#e76d90",
      width: "100%",
      height: "100%",
    },
    titleContainer: {
      width: "80%",
      display: "flex", 
      flexDirection: "row",
      alignItems: "center",
      marginTop: "5px"
    },
    editoContainer: {
      width: "80%",
    },
    img: {
      width: 35,
      height: 35,
      marginRight: "10px"
    },
    title: {
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: 16,
      color: "white"
    },
    edito: {
      fontFamily: "Brandon",
      fontWeight: "bold",
      fontSize: 14,
      color: "white"
    },
  });

  return (
    <View style={pdfStyle.container}>
      <View style={pdfStyle.titleContainer}>
        <Image src={Person} style={pdfStyle.img}/>
        <Text style={pdfStyle.title}>Edito de la directrice / du directeur:</Text>
      </View>
      <View style={pdfStyle.editoContainer}>
        <Text style={pdfStyle.edito}>« {message} »</Text>
      </View>
    </View>
  );
};

EditoComponent.propTypes = {
  message: PropTypes.any,
};
EditoEditor.propTypes = {
  onChange: PropTypes.any,
  field: PropTypes.any,
};
EditoPdf.propTypes = {
  message: PropTypes.any,
};

export { EditoEditor, EditoComponent, EditoPdf };