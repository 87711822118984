import {useState, useEffect} from "react";

const useFirebaseDocument = (path, defaultValue) => {
  const [data, setData] = useState(defaultValue ?? {});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = path.onSnapshot(snapshot => {
      let res = {uid: snapshot.id, ...snapshot.data()};
      setData(res);
      setLoading(false);
    }, (error) => {
      setError(error.message);
      console.error(error);
    });
    return unsubscribe;
  }, [path]);

  const update = async (data) => {
    try {
      await path.update({
        ...data,
        updatedAt : new Date()
      });
    } catch (e) {
      setError(e.message);
      console.error(e);
    }
  };
  const set = async (data) => {
    try {
      await path.set({
        ...data,
        updatedAt : new Date()
      });
    } catch (e) {
      setError(e.message);
      console.error(e);
    }
  };
  const remove = async () => {
    try {
      await path.delete();
    } catch (e) {
      setError(e.message);
      console.error(e);
    }
  };
  return {data, error, loading, update, set, remove};
};

export default useFirebaseDocument;