import React, {useState, useEffect} from "react";
import { Spinner } from "../../../lib/components";
import moment from "moment";
import useAnimationEvents from "../../../resources/animations";

import midiImage from "../../../assets/images/home/menuMidi.png";
import styles from "../../../assets/scss/components/homeWidgets/tomorrowActivities.module.scss";


const TodayMenu = () => {
  const [activities, setActivities] = useState([]);
  const {events} = useAnimationEvents();

  useEffect(() => {
    if (events === null) return;
    let tomorrow = moment().add(1, "day").format("YYYY-MM-DD");
    let data = events.filter(i => moment(i.start.toDate()).format("YYYY-MM-DD") === tomorrow);
    setActivities(data);
  }, [events]);

  return (
    <div className={styles.container}>
        <div className={styles.header}>
          <h4 style={{fontWeight:600, fontSize:18, textAlign: "center"}}>Les activités de demain</h4>
        </div>
        <div className={styles.body}>
          <div className={styles.left}>
            <img src={midiImage} />
            <h5>Activités de demain</h5>
        {activities === null ? 
          <Spinner />
          :
          activities.length === 0 ?
            // eslint-disable-next-line react/no-unescaped-entities
            <p>Aucune activité</p>
            :<div className={styles.activitiesContainer}>
              {activities.map(i => 
                <div key={i.uid} className={styles.activity}>
                  <span className={styles.start}>{moment(i.start.toDate()).format("HH:mm")}</span>
                  <span className={styles.title}>{i.title}</span>
                </div>
              )}
            </div>}
          </div>
          
        </div>
    </div>
  );

};

export default TodayMenu;
