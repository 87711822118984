
import React, { useState } from "react";
import { Container, Row, Col, Input, Card, CardHeader, CardBody, CardFooter, Button, FormGroup, Separator, Modal, ModalHeader, ModalBody, Icon } from "../../../lib/components";
// import styles from "./in.module.scss";

import styles from "./Scss/tableau.module.scss";
import firebase from "firebase";

import CenterOfNotif from "./Pages/centreNotif";

import useUI from "../../../hooks/ui.hook";
import { ModalFooter } from "reactstrap";
import { Bell } from "react-feather";
const firestore = firebase.firestore();
const NotificationCenter = () => {

  return (
    <Container style={{height:"calc(100Vh - 110px)"}}>
      <CenterOfNotif></CenterOfNotif>
    </Container>
  );
};

export default NotificationCenter;