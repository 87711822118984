import React, {useEffect,useState} from "react";
import {useServiceReservation} from "../../routerContext";
import { ButtonAddPrestataire, CategoriesCrudService, PrestataireCard } from "../../routerComponents";
import { Button, Card, Col, Input,Row,Select } from "lib/components";
import styles from "../../Basic/Scss/service.module.scss";

const ListePrestataire = (props) => {    
    const [ctx] = useServiceReservation();

  const [search, setSearch] = useState("");
  const [categories, setCategories] = useState([]); //tab des catégories
  const [category, setCategory] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  
  useEffect(() => {
     const categories = ctx.availableServices;
     setCategories(categories);
     },[category,ctx.availableServices]);

  useEffect(() => {
    const filtered = ctx.prestataires.filter((d) => {
      const matchesSearch = d.name.toLowerCase().includes(search.toLowerCase());
      const matchesCategory = category ? d.serviceRef === category.label : true;
      return matchesSearch && matchesCategory;
    });

    setFilteredData(filtered);
  }, [ctx.prestataires, search, category]);
 
  return (
    <div>
      <Card> 
        <>
        <div className={styles.rechercheContainer}>
        <div className={styles.recherche}>
          Recherche : <Input value={search} placeholder="Nom du prestataire..." onChange={(e) => setSearch(e.target.value)} />
        </div>
        <div >
          <Select
            placeholder="Catégorie..."
            clearable
            options={categories.map((category) => ({ label: category.name, value: category.uid }))}
            value={category}
            onChange={(e) => setCategory(e ?? null)}
          />
        </div>
      </div>
      <div style={{marginTop:"10px"}}></div>
      <CategoriesCrudService creationMode={true}/>
      <div className={styles.bodyContainer}>
      
        <ButtonAddPrestataire/>
        {filteredData.map((_data, index) => (
          <PrestataireCard data={_data} key={index}></PrestataireCard>
        ))}
      </div>
      </>
      </Card>
    </div>
  );
  
  
};

export default ListePrestataire;