import React, {createContext, useReducer, useEffect} from "react";
import PropTypes from "prop-types";
import firebase from "firebase";
import axios from "axios";
import {updateUserById} from "services/ressources/user";

const UIContext = createContext();

function UIReducer(state, action) {
  switch(action.type) {
  case "endLoad": 
    return {
      ...state,
      appLoaded: true
    };
  case "login":
    updateUserById({id: firebase.auth().currentUser.uid, data: {lastLogin: firebase.firestore.FieldValue.serverTimestamp()}});
    return {
      appLoaded: true,
      connected: true,
      user: {...firebase.auth().currentUser, ...action.user},
      establishment: action.establishment,
      groupement: action.groupement,
      region: action.region,
      refSenior: action.refSenior,
      backendMode: "api",
    };
  case "updateUser":
    return {
      ...state,
      user: {
        ...state.user,
        ...action.user,
      }
    };
  case "logout":
    axios.defaults.headers.common["Authorization"] = null;
    firebase.auth().signOut();
    return {
      user: null,
      establishment: null,
      connected: false,
      appLoaded: true
    };
  case "takeControl":
    axios.defaults.headers.common["Establishment"] = action.establishment.uid;
    return ({
      ...state,
      user: action.user,
      establishment: action.establishment,
      groupement: action.groupement,
    });
  case "updateEstablishment":
    return ({
      ...state,
      establishment: action.establishment,
    });
  case "updateEstablishmentKey":
    return ({
      ...state,
      establishment: {
        ...state.establishment,
        [action.key]: action.value,
      },
    });
  case "updateGroupement":
    return ({
      ...state,
      groupement: action.groupement
    });
  case "updateGroupementKey":
    return ({
      ...state,
      groupement: {
        ...state.groupement,
        [action.key]: action.value,
      },
    });
  case "setBackendMode":
    return ({
      ...state,
      backendMode: action.mode,
    });
  default:
    return {...state};
  }
}

const UIDefault = {
  user: null,
  establishment: null,
  groupement: null,
  connected: false,
  appLoaded: false,
  backendMode: null, // "firebase" or "api"
};

const Provider = ({children}) => {
  const [ui, dispatch] = useReducer(UIReducer, UIDefault);
  
  useEffect(() => {
    if(ui.user && ui.user.isDeleted) {
      dispatch({type: "logout"});
    }
  }, [ui.user]);

  return (
    <UIContext.Provider value={[ui, dispatch]}>
      {children}
    </UIContext.Provider>
  );
};

Provider.propTypes = {
  children : PropTypes.element,
};

export default UIContext;
export {Provider};