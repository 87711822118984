import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useServiceReservation } from "../../Reservation/routerContext";
import styles from "../Scss/prestataireCard.module.scss";
import moment from "moment";
import {useHistory} from "react-router-dom";
const PrestataireCard = (props) => {
  const {
    data,
    style,
    onClickResa
  } = props;

  const [computedAvailabilities, setComputedActivities] = useState([]);
  const history = useHistory();
  const [ctx,{updatePage,updateCurrentPrestataire,updateCurrentPrestataireId}] = useServiceReservation();
  const [ModalResa,setModalResa] =useState(false);
  const handleClick= ()=>{
    try {
        updateCurrentPrestataireId(data.uid);
        updateCurrentPrestataire(data.name,data.contact,data.description,data.personnel,data.img,data.serviceRef,data.prestations);
        updatePage(2);
      
    } catch (error) {
      console.error(error);
    }
  };

  const whichColor = ()=>{
    const service = ctx.availableServices.find(item => item.name === data.serviceRef);
    return service ? service.color : "#b9bdc7";
  };
  return (
    <div className={styles.cardContainer} style= {style} onClick={() =>handleClick()}>
      {data.img  ? 
        <div className={styles.imageContainer}>
          <img src={data.img} />
        </div> : null 
      }
      <div className={styles.cardBody} >
        <div className={styles.infos}>
          <div className={styles.name}>{data.name}</div>
          <div className={styles.description}>{data.description}</div>
          <div className={styles.dateContainer}>
            Contact:
              <div>
                <div className={styles.availabilityRow}>
                  <div className={styles.halfDay}>{data.contact}</div>
                </div>
              </div> 
          </div> 
        </div>
          <div className={styles.categories} style={{backgroundColor:whichColor()}}>
            {data.serviceRef ? <div className={styles.category}>{data.serviceRef}</div>: null}
          </div>
      </div>
    </div>
  );
};

PrestataireCard.propTypes = {
  data: PropTypes.object.isRequired,
  style: PropTypes.object,
  onClickResa:PropTypes.func,
};

export default PrestataireCard;