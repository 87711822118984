import React from "react";
import {SidebarLink, SidebarImage} from "../lib/components/dashboard";
import Logo from "../assets/images/jabiLogo/MAJOR_BLANC.png";

import {Home} from "react-feather";

const SuperAdminMenu = () => {
  return (
    <div style={{position: "relative", top: -20}}>
      <SidebarImage img={Logo}  />
      <SidebarLink to="/dashboard/one"        icon={Home}>Accueil</SidebarLink>
    </div>
  );
};

export default SuperAdminMenu;