import { ArrowLeft, ArrowRight, Printer, Columns } from "react-feather";
import { Button, Checkbox, Spinner } from "../../../../../lib/components";
import React, { useEffect, useState } from "react";

import { MenuCard, PrintDay, ModalReservationChange } from "../../router_components";
import { useMenu } from "../../router_context";

import Logo from "assets/images/jabiLogo/MAJOR_DETOUR.png";
import moment from "moment";
import styles from "../../../../../assets/scss/pages/menu.module.scss";
import { useHistory } from "react-router-dom";
import { usePrompt } from "lib/hooks/usePrompt";
import { useSidebar } from "lib/context/dashboard/sidebar.context";
import useUi from "hooks/ui.hook";
import { VideoTuto } from "lib/components/YoutubeModal";
import useFeature from "hooks/useFeature";
import {SpecificRouter} from "specificGroupement/SpecificRouter";
import { Info } from "react-feather";
import ReactTooltip from "react-tooltip";

// import menuCard from "assets/images/TutoVideo/menuCard.svg";


const cardColors = [
  "green",
  "purple",
  "red",
  "yellow",
  "purple",
  "red",
  "yellow",
];

const RenderRepas = () => {
  const [ui] = useUi();
  const [ctx, dispatch] = useMenu();
  const [clicked, stateClicked] = useState(null);

  const _handleClick = (repas) => {
    dispatch({ type: "setMeal", meal: repas });
    dispatch({ type: "changePage", page: 1 });
    // console.log(ctx.meal);
  };
  
  if (!ui || !ui.establishment || !ui.establishment.template)
    return (
      <div className={styles.noTemplate}>
        <img src={Logo} />
        <h1>Menu introuvable</h1>
        <p>
          {"Votre fonctionnalité d'édition de menu n'est pas encore activée"}
        </p>
        <p>
          {
            "Rapprochez vous de l'équipe Jabi afin de paramétrer votre menu personnalisé"
          }
        </p>
        <Button
          style={{ marginTop: 20 }}
          onClick={() => (window.location.href = "mailto:briac.perrin@jabi.fr")}
        >
          {"Prendre contact avec l'équipe Major"}
        </Button>
      </div>
    );

  return Object.keys(ui.establishment.template)
    .sort(
      (a, b) =>
        ui.establishment.template[a].heure - ui.establishment.template[b].heure
    )
    .map((_repas, _id) => (
      <div
        className={styles.card}
        key={_id}
        onClick={() => _handleClick(_repas)}
        style={
          ctx.meal === _repas
            ? {
                backgroundColor: "#52c5a5"
              }
            : { backgroundColor: "#EEE" }
        }
      >
        <span
          className={styles.mealTitle}
          style={ctx.meal === _repas ? { color: "#fff" } : { color: "#444" }}
        >
          {_repas}
        </span>
      </div>
    ));
};

const Menu = () => {
  const [ctx, dispatch] = useMenu();
  const [ui] = useUi();
  const history = useHistory();
  const [focused, setFocused] = useState(null);
  const [publication, setPublication] = useState(false);
  const [sidebar] = useSidebar();
  const isSavePublish = useFeature("savePublishMenus");

  // console.log(sidebar);

  const handlePublication = () => {
    ctx.save(!publication);
    setPublication(!publication);
  };

  useEffect(()=> {
    console.log(ctx.data);
    if( ctx.data && Object.values(ctx.data).length > 0){
      if(Object.values(ctx.data)[0].published === true){
        setPublication(true);
      }
      if(Object.values(ctx.data)[0].published === false || Object.values(ctx.data)[0].published === undefined){
        setPublication(false);
      }
    }
  },[ctx.data]);

  usePrompt(
    ctx.isSaved,
    "Les modifications que vous avez apportées au Menu ne sont pas enregistrées. Voulez vous quitter ?",
    true
  );

  const localConfirm = (callback) => {
    let reponse = true;
    if (!ctx.isSaved()) {
      reponse = confirm(
        "Les modifications que vous avez apportées au Menu ne sont pas enregistrées. Voulez vous quitter ?"
      );
    }

    if (reponse) {
      callback();
    }
  };


  const _export = () => {
    history.push("/dashboard/menu/export", {
      week: ctx.week,
      menuState: ctx.state,
      logo: ui.establishment.profileImage,
    });
  };

  if (ctx.week === null) return <Spinner />;
  if (ctx.state === null) return <Spinner />;
  if (ctx.loading) return <Spinner />;

  return (
    <div style={{width: "100%", minWidth: 1150}}>
      <div className={styles.pageControl}>
        <span data-tip data-for={"allergie"} style={{cursor: "pointer", display: "flex", alignItems: "center", gap: 5}}>
          <Info color="#300438" size={32} />
          Informations sur les allergies
        </span>
        <ReactTooltip id={"allergie"} place="bottom" effect="float" style={{zIndex:9000}}>
          <div style={{maxWidth: 800, fontSize: 16}}>
            <p>{"(1) Soja et produit à base de soja"}</p>
            <p>{"(2) Poisson et produits à base de poisson"}</p>
            <p>{"(3) Mollusques"}</p>
            <p>{"(4) Crustacés et produits à base de crustacés"}</p>
            <p>{"(5) Graines de sésames et produits à base de sésame"}</p>
            <p>{"(6) Céréales contenant du gluten : blé, seigle épeautre, kamut et les produits à base de ces céréales"}</p>
            <p>{"(7) Œuf et produits à base d'œuf"}</p>
            <p>{"(8) Lupin"}</p>
            <p>{"(9) Fruits à coque : amande, noisette, noix de cajou, noix de pécan, noix du brésil, pistache, noix de macadamia et produits à base de ces fruits"}</p>
            <p>{"(10) Lait et produit à base de lait"}</p>
            <p>{"(11) Arachide et produits à base d'arachide"}</p>
            <p>{"(12) Anhydride sulfureux et sulfites en concentration de plus de 10mg/kg ou mg/l exprimés en SO2"}</p>
            <p>{"(13) Moutarde et produit à base de moutarde"}</p>
            <p>{"(14) Céleri et produits à base de céleri"}</p>
          </div>
        </ReactTooltip>
        <Button
          color="primary"
          onClick={() =>
            localConfirm(() => {
              dispatch({ type: "changePage", page: 0 });
            })
          }
        >
          Retour
        </Button>
        {isSavePublish ? 
          <>
           <Button color="primary" onClick={() => ctx.save(publication)} disabled={ctx.isSaved()}>
            Enregistrer
          </Button>
          <Checkbox label="Publication" className={styles.checkbox} checked={publication} onChange={handlePublication}/>
          </>
        : 
          <>
          {ui.user.role === "restaurant" ? (
          <Button color="primary" onClick={() => ctx.save(publication)} disabled={ctx.isSaved()}>
            Enregistrer
          </Button>
         ) : (
          <Button color="primary" onClick={() => ctx.save(true)}>
            Publier
          </Button>
         )} 
          </>
        }
        
        <Button color="primary" onClick={() => localConfirm(_export)} style={{display: "flex", alignItems: "center", gap: 5}}>
          <Printer size={20} />
          Imprimer
        </Button>
      </div>
      
      <div className={styles.choseMenu}>
        <RenderRepas />
      </div>
      <VideoTuto url="https://youtu.be/Xo7PSK9K4lQ" /*preview={menuCard}*//>
      
      <div className={`${styles.container} ${sidebar ? styles.sidebarOpen : ""}`}>
        <div className={styles.contentContainer}>
          {ui.establishment.template &&
          ctx.meal &&
          ui.establishment.template[ctx.meal] &&
          ui.establishment.template[ctx.meal].menus
            ? Object.keys(ui.establishment.template[ctx.meal].menus)
                .sort(
                  (a, b) =>
                    ui.establishment.template[ctx.meal].menus[a].weight -
                    ui.establishment.template[ctx.meal].menus[b].weight
                )
                .map((menu, id) => {
                  return (
                    <div key={id}>
                      <div className={styles.planningHeader}>
                        <ArrowLeft
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            localConfirm(() =>
                              dispatch({ type: "previousWeek" })
                            )
                          }
                        />
                        <span>
                          {moment(ctx.week[0]).format("ll")} -{" "}
                          {moment(ctx.week[6]).format("ll")}
                        </span>
                        <ArrowRight
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            localConfirm(() => dispatch({ type: "nextWeek" }))
                          }
                        />
                        
                      </div>
                      {ui.establishment.template[ctx.meal].menus[menu].type ===
                      "daily" ? (
                        <div className={styles.planningDatesContainer}>
                          {ctx.week.map((day) => (
                            <div
                              className={styles.date}
                              key={moment(day).format("llll").split(" ")[0]}
                            >
                              <h6>
                                {moment(day).format("llll").split(" ")[0]}
                              </h6>
                              <h5>{moment(day).format("L").split("/")[0]}</h5>

                              <PrintDay data={{day, styles}} />
                            </div>
                          ))}
                        </div>
                      ) : null}
                      <h5 style={{ fontSize: 25 }}>
                        <span
                          style={{
                            textTransform: "uppercase",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {menu}
                        </span>
                      </h5>
                      
                      <div className={styles.planningBody}>
                        <div className={styles.menuSection}>
                          {ui.establishment.template[ctx.meal].menus[menu]
                            .type === "daily" ? (
                            ctx.week.map((day, id) => (
                              <div
                                className={`${styles.menuDay} ${
                                  focused === id ? styles.focused : ""
                                }`}
                                key={moment(day).format("llll").split(" ")[0]}
                              >
                                <MenuCard
                                  color={cardColors[id]}
                                  date={moment(day).format("YYYY-MM-DD")}
                                  origin={menu}
                                />
                              </div>
                            ))
                          ) : ui.establishment.template[ctx.meal].menus[menu]
                              .type === "weekly" ? (
                            <MenuCard
                              color={cardColors[0]}
                              date={moment(ctx.week[0]).format("YYYY-MM-DD")}
                              origin={menu}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })
            : null}
        </div>

        {/*<span key={id} className={`${styles.control} ${ctx.menu === menu ? styles.active : ""}`}
              onClick={() => localConfirm(() => dispatch({ type: "setMenu", menu: menu }))}>{menu}</span>*/}
      </div>
      <ModalReservationChange />
    </div>
  );
};

export default Menu;
