import firebase from "firebase";
import moment from "moment"; 

const firestore = firebase.firestore;


const updateMenu = (establishmentId, date, options, uid, action) => { // date = "2022-07-15" options = {"midi" : 0, "soir" : 2} action = ["update", "remove"]
  let _date = moment(date).startOf("day")._d;
  let docId = `${_date.getDate()}-${_date.getMonth() + 1}-${_date.getFullYear()}`;
  let map = {};

  if(action == "update" || action == "add"){
    map["reservation." + uid] = {
      midi : {
        entry : parseInt(options["midi"]["entry"]),
        dish : parseInt(options["midi"]["dish"]),
        cheese : parseInt(options["midi"]["cheese"]),
        dessert : parseInt(options["midi"]["dessert"])
      },
      soir : {
        entry : parseInt(options["soir"]["entry"]),
        dish : parseInt(options["soir"]["dish"]),
        cheese : parseInt(options["soir"]["cheese"]),
        dessert : parseInt(options["soir"]["dessert"])
      }
    }; 
  }
  else if(action == "remove"){
    map["reservation." + uid] = firestore.FieldValue.delete();
  }else{return;}

  firestore()
    .collection("establishments")
    .doc(establishmentId)
    .collection("blocks")
    .doc("menu")
    .collection("menu")
    .doc(docId)
    .update(map);
};

const updatePlanning = (establishmentId, eventId, uid, action) => { // action = ["add","remove"]

  const _history = {app: "major", action, date: firestore.Timestamp.now(), user: uid};

  if(action == "add"){
    firestore()
      .collection("establishments")
      .doc(establishmentId)
      .collection("blocks")
      .doc("planning")
      .collection("events")
      .doc(eventId)
      .update( {
        reservation: firestore.FieldValue.arrayUnion( uid ),
        history: firestore.FieldValue.arrayUnion(_history),
      });
  }
  if(action == "remove"){
    firestore()
      .collection("establishments")
      .doc(establishmentId)
      .collection("blocks")
      .doc("planning")
      .collection("events")
      .doc(eventId)
      .update( {
        reservation: firestore.FieldValue.arrayRemove( uid ),
        history: firestore.FieldValue.arrayUnion(_history),
      });
  }

};


const updateUser = (uid, map) => { // map : { "key" : "value"}

  firestore()
    .collection("users")
    .doc(uid)
    .update( map );
};



export {updateMenu, updatePlanning, updateUser};