import React, {useState, useEffect, useCallback} from "react";
import PropTypes from "prop-types";
import firebase from "firebase";
import useUI from "../../../hooks/ui.hook";
import {Plus, RotateCcw, RotateCw, ZoomIn, ZoomOut} from "react-feather";
import Cropper from "react-easy-crop";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Select} from "lib/components";
import styles from "../../assets/scss/component/imagePickerCrop.module.scss";
import {v4 as uuidv4} from "uuid";
import {Trash, Upload, Scissors} from "react-feather";
import useUUid from "lib/hooks/useUid";
import { toast } from "react-toastify";
import axios from "axios";
import { Buffer } from "buffer";
import { Document, Page,pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

const storage = firebase.storage;

const PdfPickerCrop = (props) => {
  const {
    value,
    onChange,
    ratio,
  } = props;

  const [ui] = useUI();
  const inputId = useUUid();
  const [step, setStep] = useState("upload");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewSize, setPreviewSize] = useState({width: 150, height: 150});
  const [loading, setLoading] = useState(false);
  const [ratioWarning, setRatioWarning] = useState(false);

  const [file, setFile] = useState(null);
  const [pdf,setPdf] = useState(null);
  const [localUrl, setLocalUrl] = useState(null);

  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [cropArea, setCropArea] = useState(null);

  const [numPages,setNumPages] = useState(null);
  const [pageNumber,setPageNumber] = useState(1);


  const _onCropComplete = useCallback((_, croppedAreaPixels) => setCropArea(croppedAreaPixels), []);

  useEffect(() => {
    //compute preview ratio
    if (ratio < 1) {//vertical
      setPreviewSize({
        height: 200,
        width: 200 * ratio
      });
    } else {//horizontal
      setPreviewSize({
        width: 200,
        height: 200 / ratio
      });
    }
  }, [ratio]);
  useEffect(() => {
    if (value === null) {setRatioWarning(false); return;}
    const img = new Image();
    img.addEventListener("load", function() {
      let _ratio = this.naturalWidth / this.naturalHeight;
      //check if ratio is near of 10%
      if (Math.abs(_ratio - ratio) > 0.1) {
        setRatioWarning(true);
      } else {
        setRatioWarning(false);
      }
    });
    img.src = value;
  }, [ratio, value]);
  
  useEffect(() => { 
    if (pdf) {
      loadChoosePage(pdf);
    }
  }, [pageNumber]);


  
  const _clear = () => {
    setCrop({x: 0, y: 0});
    setZoom(1);
    setIsModalOpen(false);
    setStep("upload");
    setLocalUrl(null);
    setFile(null);
    setPdf(null);
    setNumPages(1);
    setPageNumber(1);
    setLoading(false);
  };
  const _onRotateLeft = () => {
    let imageFile = file;
    var img = document.createElement("img");
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    img.onload = function () {
      canvas.width = img.height;
      canvas.height = img.width;

      var x = canvas.width / 2;
      var y = canvas.height / 2;
      var width = img.width;
      var height = img.height;

      ctx.translate(x, y);
      ctx.rotate(-Math.PI / 2);
      ctx.drawImage(img, -width / 2, -height / 2, width, height);
      ctx.rotate(Math.PI / 2);
      ctx.translate(-x, -y);

      let dataUrl = canvas.toDataURL(imageFile.type);
      setLocalUrl(dataUrl);
    };
    img.src = localUrl;
  };
  const _onRotateRight = () => {
    let imageFile = file;
    var img = document.createElement("img");
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    img.onload = function () {
      canvas.width = img.height;
      canvas.height = img.width;

      var x = canvas.width / 2;
      var y = canvas.height / 2;
      var width = img.width;
      var height = img.height;

      ctx.translate(x, y);
      ctx.rotate(Math.PI / 2);
      ctx.drawImage(img, -width / 2, -height / 2, width, height);
      ctx.rotate(-Math.PI / 2);
      ctx.translate(-x, -y);

      let dataUrl = canvas.toDataURL(imageFile.type);
      setLocalUrl(dataUrl);
    };
    img.src = localUrl;
  };
  const pdfChange = (e) => {
    const pdf = e.target.files[0];
    if (pdf) {
        setPdf(pdf);
        setPageNumber(1); 
        loadPdf(pdf);
    }
  };
  const loadPdf = async (pdfimport) => {
    try {
      const filepdfReader = new FileReader(); 
      filepdfReader.readAsArrayBuffer(pdfimport); 

      filepdfReader.onload = async (e) => {

        const buffer = e.target.result;
        const loadingTask = pdfjs.getDocument({ data: buffer });
        const pdf1 = await loadingTask.promise;
        setPdf(pdf1);
        await loadChoosePage(pdf1);
      };

    } catch (error) {
      console.error("Erreur lors du chargement du PDF:", error);
    }
  };
  const loadChoosePage= async (pdf) => {
    const page = await pdf.getPage(pageNumber);

    setNumPages(pdf.numPages);
    
    const viewport = page.getViewport({ scale: 1 });
    const canvas = document.createElement("canvas");
    const canvasContext = canvas.getContext("2d");
    canvas.width = viewport.width;
    canvas.height = viewport.height;

    const renderContext = {
      canvasContext,
      viewport,
    };

    const renderTask = page.render(renderContext);
    await renderTask.promise;

    const blob = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg"));
    const localUrl = URL.createObjectURL(blob);

    setLocalUrl(localUrl);
    setStep("crop");
    setFile(blob);
  };

  const pageChange = (e) => {
    const selectedPage = parseInt(e.target.value, 10);
    if (!isNaN(selectedPage) && selectedPage >= 1 && selectedPage <= numPages) {
      setPageNumber(selectedPage);
    }
  };
  
  const _submitCrop = () => {
    setLoading(true);
    let imageFile = file;
    var reader = new FileReader();
    reader.onload = function (e) {
      var img = document.createElement("img");
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      ctx.canvas.width = cropArea.width;
      ctx.canvas.height = cropArea.height;
      img.onload = function () {
        ctx.drawImage(img, cropArea.x, cropArea.y, cropArea.width, cropArea.height, 0, 0, cropArea.width, cropArea.height);
        let dataUrl = canvas.toDataURL(imageFile.type);
        
        //cropping done, now upload
        (async () => {
          try {
            let hash = uuidv4();
            await storage().ref(`/uploads/${ui.user.establishment}/${hash+e.name}`).putString(dataUrl, "data_url");
            let downloadUrl = await storage().ref(`/uploads/${ui.user.establishment}`).child(hash+e.name).getDownloadURL();
            onChange(downloadUrl);
            _clear();
          } catch (e) {
            console.error(e);
            toast.error(e.message);
            setLoading(false);
          }
        })();
      };
      img.src = localUrl;
    };
    reader.readAsDataURL(imageFile);
  };

  const cropCurrentImage = async () => {
    const blob = await fetch(value).then(r => r.blob());
    let _localUrl = URL.createObjectURL(blob);
    setIsModalOpen(true);
    setStep("crop");

    setLocalUrl(_localUrl);
    setFile(blob);
  };
  const pageOptions = Array.from({ length: numPages }, (_, index) => ({
    value: index + 1,
    label: `Page ${index + 1}`,
  }));

  return (
    <div className={styles.container} style={{width: previewSize.width}}>
      {value ? (
        <div className={styles.imageContainer} style={previewSize}>
          <img src={value} alt="image" className={styles.image} style={previewSize} />
          {ratioWarning ? 
            <Scissors color="black" onClick={cropCurrentImage} className={styles.scissors}/>
          : null}
          <Trash color="red" onClick={() => onChange(null)} 
            className={styles.trash} />
        </div>
      ) : (
        <div className={styles.upload} style={previewSize} onClick={() => setIsModalOpen(true)}>
          <Plus size={48} color="white" />
        </div>
      )}
      {ratioWarning && (
        // eslint-disable-next-line react/no-unescaped-entities
        <p style={{textAlign: "center", color: "orange", fontWeight: 600}}>Cliquez sur les ciseaux pour recadrer l'image et ainsi obtenir un format d'image idéal.</p>
      )}
      {/*<Button color="primary" onClick={() => setIsModalOpen(true)}>click here</Button>*/}
      <Modal isOpen={isModalOpen} toggle={_clear} size="lg">
        <ModalHeader><h4></h4></ModalHeader>
        {step === "upload" ? (
          <ModalBody className={styles.modalBody} style={{height: 400}}>
            <label className={styles.dropZone} htmlFor={inputId} style={{display: "flex", flexDirection: "column", gap: 10}}>
              <Upload size={80} color="#AAA" />
              <span style={{fontSize: 20, color: "#aaaaaa"}}>Cliquez ici pour ajouter un pdf</span>
              <input type="file" onChange={pdfChange} id={inputId}  accept=".pdf"/>
              
            </label>
          </ModalBody>
        ) : (
          <ModalBody className={styles.modalBody} style={{height: 400}}>
            

            <Cropper
              image={localUrl}
              aspect={ratio}
              crop={crop}
              zoom={zoom}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={_onCropComplete}
              maxZoom={5}
            />
          </ModalBody>
        )}
        <ModalFooter className={styles.modalFooter}>
          {step === "crop" ? 
            <>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div>
                  <label htmlFor="pageNumber">Choisir une page :</label>
                  <select style={{ marginBottom: "10px",marginLeft: "10px" }}
                    id="pageNumber"
                    value={pageNumber}
                    onChange={pageChange}>
                    {Array.from({ length: numPages }, (_, index) => (
                      <option key={index + 1} value={index + 1}>
                        Page {index + 1}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
              <ZoomOut style={{cursor: "pointer" }} onClick={() => setZoom(zoom > 1 ? zoom - 0.5 : zoom)} />
              <ZoomIn  style={{cursor: "pointer",marginLeft: "5px" }} onClick={() => setZoom(zoom < 4.5 ? zoom + 0.5 : zoom)} />
              <Button style={{marginLeft: "5px" }} color="primary" onClick={_submitCrop} loading={loading}>Valider</Button> 
              <RotateCw style={{cursor: "pointer",marginLeft: "5px"}} onClick={() => _onRotateRight()} />
              <RotateCcw style={{cursor: "pointer",marginLeft: "5px"}} onClick={() => _onRotateLeft()} /></div></div>
            </>
            : null}
        </ModalFooter>
      </Modal>
    </div>
  );
};

PdfPickerCrop.propTypes = {
  value : PropTypes.any,
  onChange : PropTypes.func,
  ratio : PropTypes.number,
};


export default PdfPickerCrop;
