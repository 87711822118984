import React from "react";

import Basic_useMenuReservation, {Provider as Basic_MenuReservationProvider} from "./Basic/Context/ContextReservationMenu";

import Ovelia_useMenuReservation, {Provider as Ovelia_MenuReservationProvider} from "./Specific/ovelia/Context/contextReservationMenu";
import Heurus_useMenuReservation, {Provider as Heurus_MenuReservationProvider} from "./Specific/heurus/Context/contextReservationMenu";
import useUI from "hooks/ui.hook";

const MenuReservationProvider = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "heurus") return <Heurus_MenuReservationProvider {...props} />;
    if(ui?.groupement?.specific === "ovelia") return <Ovelia_MenuReservationProvider {...props} />;
    return <Basic_MenuReservationProvider {...props} />;
};

const useMenuReservation = () => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "heurus") return Heurus_useMenuReservation();
    if(ui?.groupement?.specific === "ovelia") return Ovelia_useMenuReservation();
    return Basic_useMenuReservation();
};

export { useMenuReservation, MenuReservationProvider };