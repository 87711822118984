import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useServiceReservation } from "../../routerContext";
import styles from "../Scss/littleprestataireCard.module.scss";
import {useHistory} from "react-router-dom";

const LittlePrestataireCard = (props) => {
  const {
    data,
    style,
    onClickResa,
  } = props;

  const [ctx,] = useServiceReservation();

  const handleClick= ()=>{
    try {
        onClickResa();
    } catch (error) {
      console.error(error);
      // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur.
    }
  };

  const whichColor = ()=>{
    const service = ctx.availableServices.find(item => item.name === data.serviceRef);
    return service ? service.color : "";
  };
  return (
    <div className={styles.cardContainer} style= {style}onClick={() => {handleClick();}}
    >
      <div className={styles.cardBody}>
        <div className={styles.categories} style={{backgroundColor:whichColor()}}>
            {data.serviceRef ? <div className={styles.category}>{data.serviceRef}</div>: null}
          </div>
        <div className={styles.infos}>
          <div className={styles.name}>{data.name}</div>
        </div>
          
      </div>
    </div>
  );
};

LittlePrestataireCard.propTypes = {
  data: PropTypes.object.isRequired,
  style: PropTypes.object,
  onClickResa:PropTypes.func,
};

export default LittlePrestataireCard;