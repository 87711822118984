import React from "react";
import PropTypes from "prop-types";

import Card from "lib/components/Stats/Card";
import NumberDisplay from "lib/components/Stats/NumberDisplay";
import Chart from "react-apexcharts";
import Table from "lib/components/Stats/table";
import styles from "lib/assets/scss/component/stats/simpleStatComponent.module.scss";

import { ArrowUp, ArrowDown } from "react-feather";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { percent, mean, extractElementsFromObject, getFromPath} from "./operations";
import useStats from "lib/Stats/statsContext";

const SimpleStatComponent = ({path, title, icon, iconBackgroundColor, info }) => {
  const [ctx, dispatch, hasFeature] = useStats();

  if(ctx.targetType === "all" || ctx.targetType === "region"){

    let _number = getFromPath(ctx.data1, path);
    const array = extractElementsFromObject(ctx.data1.establishments, path);
    let _mean = mean(array);

    let data = [];

    let _list = ctx.data2.establishments;

    ctx.data1.establishments.forEach(_obj => {
      let etabLastPeriod = null;
      _list.forEach(_etab => {
        if(_etab.id === _obj.id)etabLastPeriod = _etab;
      });

      data.push({
        etab: _obj.name,
        value: getFromPath(_obj, path),
        // variation: _etabVariation,
      });
    });

    return (
      <div>
        <Card title={title} info={info} size="long">
          <NumberDisplay number={_number} mean={_mean} icon={icon} iconBackgroundColor={iconBackgroundColor}/*meanVariation={_meanVariation}*//>
          <div className={styles.separator}></div>
          <div className={styles.rightContainer} >
            <div className={styles.tropyContainer} >
                <FontAwesomeIcon icon={faTrophy} style={{fontSize: "85px", width: "85px", color:"rgba(244, 202, 47, 0.25)"}}/>
            </div>
            <div className={styles.bestContainer}>
                <div className={styles.bestHeaderText}> Top 3 </div>
                {data.sort((a,b)=> b.value - a.value).slice(0,3).map((e, indx)=>(
                    <div className={styles.establishment} key={indx}>
                        <div className={styles.rank}>{indx+1}</div>
                        <div className={styles.name}>{e.etab}</div>
                    </div>
                ))}
            </div>
          </div>
        </Card>
      </div>
    );
  }else{
    let _number = getFromPath(ctx.data1, path);
    let _variation = percent(_number,getFromPath(ctx.data2, path));

    return (
      <div>
        <Card title={title} info={info} size="md">
          <NumberDisplay number={_number} variation={_variation} icon={icon} iconBackgroundColor={iconBackgroundColor}/*mean={_mean} meanVariation={_meanVariation}*//>
        </Card>
      </div>
    );
  }
};

    const CellFunc = ({ cell }) => {
      return <div style={{ width: "100%", color: typeof(cell.value) === "number" ? cell.value >= 0 ? "green" : "red" : "black"}}>
          {typeof(cell.value) === "number" ? cell.value >= 0 ? <ArrowUp size={16} color="green"/> : <ArrowDown size={16} color="red"/> : null} {typeof(cell.value) === "number" ?cell.value >= 0 ? "+" : "" : null}{cell.value}{typeof(cell.value) === "number" ? "%" : null}
      </div>;
    };
    CellFunc.propTypes = {
      cell: PropTypes.object.isRequired,
    };

SimpleStatComponent.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.any,
  iconBackgroundColor: PropTypes.string,
  info: PropTypes.string,
};

export default SimpleStatComponent;
