import React from "react";
import PropTypes from "prop-types";
import useUi from "../hooks/ui.hook";
import GazetteEditHeurus from "pages/Major/Gazette/Specific/heurus";

const SpecificRouter = (props) => {
  const [ui] = useUi();
  const { path, data } = props;
  if(!ui?.groupement) return null;
  
  // FEATURE DE GROUPEMENT ICI  

  if(path === "gazette" && ui?.groupement?.specific === "heurus") return <GazetteEditHeurus />;

  return null;
};

// Utilisé pour des affichage conditionnel
const hasGroupementFeature = (path) => {
  const [ui] = useUi();
  if(path === "gazette" && ui?.groupement?.specific === "heurus") return true;

  return false;
};


SpecificRouter.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.any,
  // style : PropTypes.any,
  // children : PropTypes.element,
};

export {SpecificRouter, hasGroupementFeature};