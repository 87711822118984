import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  FormGroup,
  Button,
  Select,
  Container,
  DatePicker, TimePicker,
  Input
} from "lib/components";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import { useServiceManagement } from "../../routerContext";
import "react-tippy/dist/tippy.css";
import { toast } from "react-toastify";

const PlanningModal = (props) => {
  const {
    isOpen,
    eventOpen, //provient d'un clique d'un evenemtn en particulier
    preselectStart,
    preselectEnd,
    preselectDate,
    toggle,
  } = props;
  const [ctx, { addAvailabiliy,updateAvailabiliy,removeAvailabiliy }] = useServiceManagement();
  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [place, setPlace] = useState("");
  const [currentPrestataire, setCurrentPrestataire] = useState("");
  const [currentPrestataireID,setCurrentPrestataireId] =useState("");
  const [personnelList, setPersonnelList] = useState([]);
  const [completePersonnelList,setCompletePersonnelList] = useState([]);
  const [listPrestations,setListPrestation] = useState([]);
  const [listPrestationComplete,setListPrestationComplete]=useState([]);
  const [error, setError] = useState({});

  
  const updateListPersonnels = (prestataireChosen) => {
    const selectedPrestataire = ctx.prestataires.find((item) => item.name === prestataireChosen);
    if(selectedPrestataire){
      if(!eventOpen)setPersonnelList(selectedPrestataire.personnel || []);
      setCompletePersonnelList(selectedPrestataire.personnel || []);
    }else{
      setPersonnelList([]);
      setCompletePersonnelList([]);
    }
  };
  const updateListPrestations = (prestataireChosen) => {
    const selectedPrestataire = ctx.prestataires.find((item) => item.name === prestataireChosen);
    if(selectedPrestataire){
      const prestationsObj = selectedPrestataire.prestations || {};
      const prestasId = Object.keys(prestationsObj);
      const prestationsNames = prestasId.map((key) => prestationsObj[key].title);
      if(!eventOpen)setListPrestation(prestationsNames || []);
      setListPrestationComplete(prestationsNames || []);
    }else{
      setListPrestation([]);
      setListPrestationComplete([]);
    }
  };

  useEffect(() => {
    if (!eventOpen && isOpen) { // si t'as rien préselectionner et que c ouvert
      setCurrentPrestataire("");
      setError({});
      setCurrentPrestataireId("");
      setPersonnelList([]);
      setCompletePersonnelList([]);
      setListPrestation([]);
      setListPrestationComplete([]);
      setPlace("");
      setDate(new Date());
      if (preselectDate) {
        setDate(preselectDate);
      }
      if (preselectStart && preselectEnd) {
        let _start = moment(preselectStart).format("HH[h]mm")
          ;
        let _end = moment(preselectEnd).format("HH[h]mm")
        ;
        setStartTime(_start);
        setEndTime(_end);
      } else {
        setStartTime("");
        setEndTime("");
      }
      return;
    } else if (eventOpen && isOpen) {
      setError({});
      setCurrentPrestataireId(eventOpen.prestataireId);
      setPlace(eventOpen.place);
      setDate(eventOpen.start); 
      setCurrentPrestataire(ctx.prestataires.find((item) => item.uid === eventOpen.prestataireId).name);
      setPersonnelList(eventOpen.personnel ?? []);
      setListPrestation(eventOpen.prestationsAvailable);
      let eventStartHour = eventOpen.start.getHours();
      let eventStartMin = eventOpen.start.getMinutes();
      let eventEndHour = eventOpen.end.getHours();
      let eventEndMin = eventOpen.end.getMinutes();
      setStartTime(
       
       `${eventStartHour <= 9 ? "0" : ""}${eventStartHour}h${
          eventStartMin <= 9 ? "0" : ""
        }${eventStartMin}`);
      setEndTime(
         `${eventEndHour <= 9 ? "0" : ""}${eventEndHour}h${
          eventEndMin <= 9 ? "0" : ""
        }${eventEndMin}`
        );
    }
  }, [isOpen]);
 

  useEffect(() => {
    if (currentPrestataire) {
      updateListPersonnels(currentPrestataire);
      updateListPrestations(currentPrestataire);
      setCurrentPrestataireId(ctx.prestataires.find((item) => item.name === currentPrestataire).uid);
    }
  }, [currentPrestataire]);

  const _onSubmit =  ()=>{
    let _error = {};
    if (!date) { _error.date = "Ce champ est obligatoire"; }
    if (!startTime) { _error.heure = "Ce champ est obligatoire"; }
    if (!endTime) { _error.heure = "Ce champ est obligatoire"; }
    if (!currentPrestataire) { _error.prestataire = "Ce champ est obligatoire"; }
    try {
      setError(_error);
      if (Object.keys(_error).length > 0) {
        toast.warning("Inscription incomplète. N'a pas abouti");
        return;
      }
      let start = new Date(date.getTime());
      let end = new Date(date.getTime());
      start.setHours(startTime.split("h")[0]);
      start.setMinutes(startTime.split("h")[1]);
      start.setSeconds(0);
      end.setHours(endTime.split("h")[0]);
      end.setMinutes(endTime.split("h")[1]);
      end.setSeconds(0);
      if (start.getTime() >= end.getTime()) return toast.warning("L'heure de fin doit être supérieure à l'heure de début");
      if (!eventOpen) {
        addAvailabiliy(currentPrestataireID, start, end, personnelList, listPrestations, place);
        toggle();
      } else {
        updateAvailabiliy(eventOpen.uid, currentPrestataireID, start, end, personnelList, listPrestations, place);
        toggle();
      }

    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");

    }
  };

  const _onDelete =()=>{
    try {
      removeAvailabiliy(eventOpen.uid);
      toggle();
    } catch (e) {
      toggle();
    }
  };
  
  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle} >
      <ModalHeader>{eventOpen? "Modification de l'horaire":"Ajout d'un horaire"}</ModalHeader>
      <ModalBody >
        <Container>
          <FormGroup>
            <Select
              invalid={error.prestataire}
              label="Choix du prestataire"
              placeholder="Aucun"
              options={ctx.prestataires.map((presta) => ({ label: presta.name, value: presta.name }))}
              onChange={(e) => {setCurrentPrestataire(e.value ?? null);}}
              value={{label:currentPrestataire,value:currentPrestataire}}
            />
          </FormGroup>
          {completePersonnelList.length>0 ? <FormGroup>
            <label htmlFor="personnelList" style={{ marginRight: "10px" }}>
              Gérer le personnel présent: 
            </label>            
            <CreatableSelect 
              label="test"
              isMulti
              placeholder="Vous n'avez pas sélectionner de prestations"
              value={personnelList != [] ? personnelList.map(name=>({label:name,value:name})): []}
              options={completePersonnelList.map((person) => ({ label: person, value: person }))}
              onChange={(newValue, actionMeta) => {
                if (actionMeta.action === "remove-value" ) { 
                  const removedPerson = actionMeta.removedValue.value;
                  setPersonnelList((prev) => prev.filter((person) => person !== removedPerson));
                } 
                else if (actionMeta.action === "select-option") {
                  newValue.forEach(selectedOption => {
                    if (!personnelList.includes(selectedOption.value)) {
                      setPersonnelList(prev => [...prev, selectedOption.value]);
                    }
                  });
                }
              }}
            />
          </FormGroup>: null}
          {listPrestationComplete.length>0 ? <FormGroup>
          <label htmlFor="prestationsList" style={{ marginRight: "10px" }}>
              Gérer les prestations disponibles:
            </label>
            <CreatableSelect 
              label="test"
              isMulti
              placeholder="Aucune prestations n'est sélectionné"
              value={listPrestations != [] ? listPrestations.map(name=>({label:name,value:name})): []}
              options={listPrestationComplete.map((person) => ({ label: person, value: person }))}
              onChange={(newValue, actionMeta) => {
                if (actionMeta.action === "remove-value" ) { // si on retire une personne de la liste
                  const removedPerson = actionMeta.removedValue.value;
                  setListPrestation((prev) => prev.filter((person) => person !== removedPerson));
                } 
                else if (actionMeta.action === "select-option") {
                  newValue.forEach(selectedOption => {
                    if (!listPrestations.includes(selectedOption.value)) {
                      setListPrestation(prev => [...prev, selectedOption.value]);
                    }
                  });
                }
              }}
            />    
          </FormGroup>: null}
          <FormGroup>
            <Input
              theme="grey"
              label={"Lieu"}
              onChange={(e) => setPlace(e.target.value)}
              value={place}
            />
          </FormGroup>
          <FormGroup
            style={{
              display: "flex",
              justifyContent: "space-between",
              columnGap: 4,
            }}
          >
            <div style={{ flex: 1 }}>
              <DatePicker
                invalid={error.date}
                label={"Jour"}
                value={date}
                onChange={setDate}
                theme="grey"
              />
            </div>
            <div style={{ flex: 1 }}>
              <TimePicker
                invalid={error.heure}
                label={"Heure de début"}
                value={{label:startTime,value:startTime}}
                onChange={(e) => setStartTime(e ? e.label : null)}
                theme="grey"
              />
            </div>
            <div style={{ flex: 1 }}>
              <TimePicker
                invalid={error.heure}
                label={"Heure de fin"}
                value={{label:endTime,value:endTime}}
                onChange={(e) => setEndTime(e ? e.label : null)}
                theme="grey"
              />
            </div>
          </FormGroup>
          
        </Container>
      </ModalBody>
      
      <ModalFooter style={{ justifyContent: "center", gap: "5px" }}>
        <Button color='secondary' onClick={toggle}>Annuler</Button>
        {eventOpen ? <Button color='danger' onClick={_onDelete} >Supprimer</Button>:null}
        <Button color='primary' onClick={_onSubmit}>{eventOpen?"Modifier":"Valider"}</Button>
      </ModalFooter>
    </Modal>
  );

};

PlanningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  eventOpen: PropTypes.object,
  preselectStart: PropTypes.any,
  preselectEnd: PropTypes.any,
  preselectDate: PropTypes.any,
};

export default PlanningModal;