import React, { useState } from "react";
import useAteliers from "../../../../../hooks/useAteliers";
import PropTypes from "prop-types";
import { Select, FormGroup } from "../../../../../lib/components";

const AteliersSelector = (props) => {
  const { onTitleChange, onDescriptionChange, onFileChange } = props;

  const { ateliers } = useAteliers();

  const [selectorA, setSelectorA] = useState(null);
  const [selectorB, setSelectorB] = useState(null);
  const [selectorC, setSelectorC] = useState(null);
  const [selectorD, setSelectorD] = useState(null);

  const _getSelectorAOptions = () =>
    Object.keys(ateliers)
      .filter((i) => !ateliers[i].comingSoon)
      .map((i) => ({ value: JSON.stringify(ateliers[i]), label: i }));
  const _getSelectorBOptions = () => {
    if (!selectorA) return [];
    const _options = JSON.parse(selectorA.value);
    return Object.keys(_options)
      .filter((i) => !_options[i].comingSoon)
      .map((i) => ({ value: JSON.stringify(_options[i]), label: i }));
  };
  const _getSelectorCOptions = () => {
    if (!selectorB) return [];
    const _options = JSON.parse(selectorB.value);
    return Object.keys(_options)
      .filter((i) => !_options[i].comingSoon)
      .map((i) => ({ value: JSON.stringify(_options[i]), label: i }));
  };
  const _getSelectorDOptions = () => {
    if (!selectorC) return [];
    const _options = JSON.parse(selectorC.value);
    return Object.keys(_options)
      .filter((i) => !_options[i].comingSoon)
      .map((i) => ({ value: JSON.stringify(_options[i]), label: i }));
  };
  //--------------------------  -------------------------------//
  const _onSelectorAChange = (e) => {
    setSelectorA(e);
    setSelectorB(null);
    setSelectorC(null);
    setSelectorD(null);
  };
  const _onSelectorBChange = (e) => {
    setSelectorB(e);
    setSelectorC(null);
    setSelectorD(null);
  };
  const _onSelectorCChange = (e) => {
    setSelectorC(e);
    setSelectorD(null);
  };
  const _onSelectorDChange = (e) => {
    setSelectorD(e);
    const workshop = JSON.parse(e.value);
    let title = e.label.split("-");
    onTitleChange(title[title.length - 1]);
    onDescriptionChange("");
    onFileChange(
      Object.keys(workshop)
        .map((i) => workshop[i])
        .map((i) => i.link)
    );
  };

  return (
    <>
      <FormGroup>
        <Select
          theme="grey"
          label={"Atelier"}
          options={_getSelectorAOptions()}
          value={selectorA}
          onChange={_onSelectorAChange}
        />
      </FormGroup>
      {selectorA && (
        <FormGroup>
          <Select
            theme="grey"
            options={_getSelectorBOptions()}
            value={selectorB}
            disabled={!selectorA}
            onChange={_onSelectorBChange}
          />
        </FormGroup>
      )}
      {selectorB && (
        <FormGroup>
          <Select
            theme="grey"
            options={_getSelectorCOptions()}
            value={selectorC}
            disabled={!selectorB}
            onChange={_onSelectorCChange}
          />
        </FormGroup>
      )}
      {selectorC && (
        <FormGroup>
          <Select
            theme="grey"
            options={_getSelectorDOptions()}
            value={selectorD}
            disabled={!selectorC}
            onChange={_onSelectorDChange}
          ></Select>
        </FormGroup>
      )}
    </>
  );
};

AteliersSelector.propTypes = {
  onTitleChange : PropTypes.func.isRequired,
  onDescriptionChange : PropTypes.func.isRequired,
  onFileChange : PropTypes.func.isRequired,
};

export default AteliersSelector;
