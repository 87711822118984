import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody,ModalFooter, Row,Container} from "lib/components";
import { ChevronRight, ChevronLeft, User, Trash, Edit, Trash2 } from "react-feather";
import styles from "../Scss/tableau.module.scss";
import { useServiceReservation } from "../../routerContext";


const ToInformChangesModal = (props) => {
    const {isOpen,data,toggle,availabilityUid,isDone} = props;
    const [ctx,{removeReservation}] = useServiceReservation();

    const getPrestations=(resa)=>{
        const prestationsTitles = ctx.prestataires.flatMap(item => {
            return resa.prestationId.map(id => item.prestations[id]?.title).filter(Boolean);
        });
        let prestass =prestationsTitles.join(",\n");
        return prestass;
    };
    const getSenior=(resa)=>{
        for (const userId in ctx.users) {
           if (userId == resa.userId) {
               const user = ctx.users[userId];
               return user.surname+" "+user.name;
           }
       }
   };
   
   const _confirmModification =()=>{
    if(data){
        data.forEach((resa)=>{
            removeReservation(availabilityUid,resa.uid);
            isDone(true);
        });
        toggle();
    }
   };

    return(
        <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader>
       Information
      </ModalHeader>
        <ModalBody>
          <div>
            <span>Les modifications que vous allez effectuer sur cette disponibilité vont <span style={{fontWeight:"bold"}}>supprimer</span> les réservations des résidents pour les causes suivantes :</span>
            <br></br>
            <div style={{width: "100%", display: "flex", flexDirection: "row", gap: 20, marginTop: 10, padding: 8, borderRadius: 8,border: " solid 1px #8F8F8F"}}>
                <div style={{flex: 1, maxHeight: "40vh", minHeight: 200, display: "flex", flexDirection: "column", alignItems: "center", overflowY: "auto", gap: 8}}>
                {data ? data.map((resa, idx) => (
                    <Row key={idx} className={styles.rowContainer}  style={{width:"100%"}}>
                    <div className={styles.column}>
                    <Container  className={styles.rowContainer} style={{marginBottom:"10px"}}>
                        <div className={styles.column} >
                            <Row style={{ display: "flex", alignItems: "center" }}>
                                <div style={{fontWeight:"bold", display: "flex", flex: 2}}>{getSenior(resa)}</div>
                                <Container  className={styles.dayContainer} style={{ display: "flex", flex: 4, backgroundColor: "#E0E2E7" }}>
                                    <> 
                                        <div className={styles.timing} style={{color:resa.reasons && (resa.reasons.start || resa.reasons.end )?"red":"black",textDecoration: resa.reasons && (resa.reasons.start || resa.reasons.end ) ? "line-through" : "none"}}>{resa.start.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit", hour12: false })} - {resa.end.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit", hour12: false })}</div>
                                        <span className={styles.titlePresta} style={{color:resa.reasons.prestations?"red":"black",textDecoration: resa.reasons && resa.reasons.prestations ? "line-through" : "none"}} >{getPrestations(resa)}</span>
                                        <div className={styles.titlePresta} style={{color:resa.reasons.personnel?"red":"black",textDecoration: resa.reasons && resa.reasons.personnel ? "line-through" : "none"}}>{resa.personnel == "unknown"? "-" :resa.personnelByDefault}</div>
                                        
                                    </>
                                </Container>
                            </Row>
                            
                        </div>
                    </Container>
                    </div></Row>
                )): null}

                </div>
            </div>

          </div>
        </ModalBody>
      <ModalFooter>
        <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
          <div style={{width: "40%", display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
          <Button color="secondary" onClick={toggle}>{"Annuler"} </Button>  
          <Button color="primary" onClick={_confirmModification}>{"Continuer"}</Button>

          </div>
        </div>
      </ModalFooter>
    </Modal>
    );
};


ToInformChangesModal.propTypes = { 
    isOpen:PropTypes.bool,
    data:PropTypes.object,
    toggle:PropTypes.func,
    isDone: PropTypes.func,
    availabilityUid:PropTypes.string
};

export default ToInformChangesModal;