import React, {useState} from "react";
import { Button } from "lib/components";

export default function Tools() {

  const [renderCrash, setRenderCrash] = useState(false);

  const syncCrash = () => {
    throw new Error("Sync crash");
  };

  const asyncCrash = () => {
    setTimeout(() => {
      throw new Error("Async crash");
    }, 0);
  };

  return (
    <div>
      <h3>Tools</h3>
      <div style={{display: "flex", flexDirection: "column", gap: 10}}>
        <Button onClick={syncCrash}>Sync crash</Button>
        <Button onClick={asyncCrash}>Async crash</Button>
        <Button onClick={() => setRenderCrash(true)}>Render crash</Button>
        {/*renderCrash && <div>{undefined()}</div>*/}
      </div>
    </div>
  );
}
