import { Game, GameMiniature, GamePdf } from "./Game";
import { Health, HealthMiniature, HealthPdf } from "./Health";
import { ImportantAnimation, ImportantAnimationMiniature, ImportantAnimationPdf } from "./ImportantAnimation";
import { MonthAnimation, MonthAnimationMiniature, MonthAnimationPdf } from "./MonthAnimation";
import { Poem, PoemMiniature, PoemPdf } from "./Poem";
import { Recipe, RecipeMiniature, RecipePdf } from "./Recipe";
import {Summary, SummaryPdf, SummaryMiniature} from "./Summary";
import { LastPage, LastPageMiniature, LastPagePdf } from "./LastPage";
import pageTypes from "pages/Major/Gazette/pageTypes";

const _pageTypesHeurus = [
  {
    slug: "summary",
    name: "Sommaire",
    component: Summary,
    pdf: SummaryPdf,
    miniature: SummaryMiniature,
  },
  {
    slug: "monthAnimation",
    name: "Animations du mois",
    component: MonthAnimation,
    pdf: MonthAnimationPdf,
    miniature: MonthAnimationMiniature
  },
  {
    slug: "importantAnimation",
    name: "Animations importantes",
    component: ImportantAnimation,
    pdf: ImportantAnimationPdf,
    miniature: ImportantAnimationMiniature,
  },
  {
    slug: "poem",
    name: "Poème",
    component: Poem,
    pdf: PoemPdf,
    miniature: PoemMiniature,
  },
  {
    slug: "recipe",
    name: "Recette",
    component: Recipe,
    pdf: RecipePdf,
    miniature: RecipeMiniature,
  },
  {
    slug: "health",
    name: "Point Santé",
    component: Health,
    pdf: HealthPdf,
    miniature: HealthMiniature,
  }, 
  {
    slug: "game",
    name: "Jeux",
    component: Game,
    pdf: GamePdf,
    miniature: GameMiniature,
  },
  {
    slug: "lastpage",
    name: "Éphémérides",
    component: LastPage,
    pdf: LastPagePdf,
    miniature: LastPageMiniature
  }
];


const mergePageTypes = (allow = ["page-1-slot", "page-2-slot", "page-3-slot"]) => {
  let _pageTypes = _pageTypesHeurus;
  pageTypes.forEach(pt => {
    if(_pageTypes.filter(p => p.slug === pt.slug).length === 0 && allow.indexOf(pt.slug) !== -1) _pageTypes.push(pt);
  });
  return _pageTypes;
};

const pageTypesHeurus = mergePageTypes();



const getPageTypeFromSlug = (slug) => {
  return pageTypesHeurus.find(type => type.slug === slug);
};

export default pageTypesHeurus;
export { getPageTypeFromSlug };