import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import moment from "moment";

import Arial from "fonts/arial/ARIAL.TTF";
import ArialBold from "fonts/arial/ARIALBD.TTF";

import { sortDishes, sortMenus } from "pages/Major/Menu/helpers/operations";
import OveliaFondWE from "./assets/OveliaFondWE.png";
import OveliaFondSemaine from "./assets/OveliaFondSemaine.png";
import OveliaCocotte from "./assets/cocotte.png";
import Star from "./assets/star.png";

Font.register({
  family: "Arial",
  fontWeight: "normal",
  src: Arial,
});
Font.register({
  family: "Arial",
  format: "bold",
  src: ArialBold,
});

// pdf size : 595 * 842

const styles = StyleSheet.create({
  container: {
    position: "relative",
    width: "100%",
    height: "100%",
    padding: 0,

  },

  backgroundContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },

  imageBackground: {
    width: "100%",
    height: "100%",
  },

  reducedContainer: {
    position: "absolute",
    marginLeft: 42,
    marginTop: 40,
    width: 511,
    height: 762,
    backgroundColor: "#f8f6ea",
  },

  header: {
    width: "100%",
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },

  pageTitle: {
    textAlign: "center",
    fontFamily: "Arial",
    fontSize: 50,
    color: "#09526c",
  },

  pageTitleBis: {
    textAlign: "center",
    fontFamily: "Arial",
    fontSize: 42,
    color: "#09526c",
  },

  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  div: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 10,
  },

  divHorizontal: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },

  repasTitle: {
    textAlign: "center",
    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: 20,
    color: "#e2b900",
    textTransform: "uppercase",
    padding: 0,
  },

  categTitle: {
    textAlign: "center",
    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: 24,
    color: "#09526c",
    textTransform: "uppercase",
    marginTop: 10,
  },

  categTitleBis: {
    textAlign: "center",
    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: 17,
    color: "#09526c",
    textTransform: "uppercase",
    marginTop: 7,
  },

  categDayTitle: {
    textAlign: "center",
    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: 18,
    color: "#09526c",
    textTransform: "uppercase",
    marginTop: 2,
  },

  horizontalSeparator: {
    width: "25%",
    borderBottomColor: "#09526c",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    marginTop: 16,
  },

  verticalSeparator: {
    height: "70%",
    borderLeftColor: "#09526c",
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
  },

  horizontalSeparatorBis: {
    width: "60%",
    borderBottomWidth: 2,
    borderBottomStyle: "dotted",
    marginTop: 15,
    marginBottom: 5,
  },

  separatorStar: {
    height: 8,
    marginTop: 8,
  },

  bigSeparator: {
    width: 515,
    marginLeft: -2,
    borderBottomWidth: 3,
    borderBottomStyle: "solid",
    marginTop: 10,
    borderColor: "#09526c",
  },

  dish: {
    marginTop: 7,
    paddingHorizontal: 3,
    textAlign: "center"
  },

  dishBis: {
    fontSize: 15,
    marginTop: 7,
    paddingHorizontal: 3,
    textAlign: "center"
  },

  cocotte: {
    position: "absolute",
    width: 45,
    left: 5,
    top: -15,
  }

});

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}


export const OveliaWeekendPDF = ({ day, dataWeek, ui }) => {
  const template = ui.establishment.template;

  const _saturday = moment(day).weekday() === 5 ? day : moment(day).clone().add(-1, "days").format("YYYY-MM-DD");
  const _sunday = moment(day).weekday() === 6 ? day : moment(day).clone().add(1, "days").format("YYYY-MM-DD");

  const getDishes = useCallback((day, repas, category, menu) => {
    let list = [];

    if (dataWeek[day] && dataWeek[day][repas] && dataWeek[day][repas][category]) {
      sortDishes(Object.entries(dataWeek[day][repas][category])).forEach(([uid, element]) => {
        if (menu) {
          if (element.origin === menu) list.push(element.name);
        } else {
          list.push(element.name);
        }
      });
    }
    return list;
  }, [day, dataWeek]);


  const sortedRepas = Object.keys(template).sort((a, b) => template[a].heure - template[b].heure);
  const _dej = sortedRepas[0];
  const _din = sortedRepas[1];

  const _dejSortedMenus = sortMenus(template, _dej, Object.keys(template[_dej].menus));

  return (
      <Page size="A4" orientation="portrait">
        <View style={styles.container}>
          <View style={styles.backgroundContainer}>
            <Image src={OveliaFondWE} style={styles.imageBackground}></Image>
          </View>

          <View style={styles.reducedContainer}>
            <View style={styles.header}>
              <Text style={styles.pageTitleBis}>
                {capitalizeFirstLetter(moment(_saturday).format("dddd DD MMMM"))}
              </Text>
            </View>

            <View style={styles.content}>
              <View style={styles.div}>
                <Text style={styles.repasTitle}>
                  {_dej}
                </Text>
              </View>
              <View style={styles.div}>
                <Text style={styles.categTitleBis}>
                  Les entrées
                </Text>

                {getDishes(_saturday, _dej, template[_dej].categories[0]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dishBis}>
                    {_d}
                  </Text>
                ))}
              </View>

              <View style={styles.horizontalSeparator}></View>

              <View style={styles.div}>
                <Text style={styles.categTitleBis}>
                  Les plats
                </Text>

                {getDishes(_saturday, _dej, template[_dej].categories[1]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dishBis}>
                    {_d}{getDishes(_saturday, _dej, template[_dej].categories[2]).length > _indx ? ", " + getDishes(_saturday, _dej, template[_dej].categories[2])[_indx] : null}
                  </Text>
                ))}
              </View>

              <View style={styles.horizontalSeparator}></View>

              <View style={styles.div}>
                <Text style={styles.categTitleBis}>
                  Les desserts
                </Text>

                {getDishes(_saturday, _dej, template[_dej].categories[3]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dishBis}>
                    {_d}
                  </Text>
                ))}
              </View>


              <View style={[styles.horizontalSeparatorBis, { borderBottomColor: "#09526c" }]}></View>


              <View style={styles.div}>
                <Text style={styles.repasTitle}>
                  {_din}
                </Text>

                {getDishes(_saturday, _din, template[_din].categories[0]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dishBis}>
                    {_d}
                  </Text>
                ))}

                {getDishes(_saturday, _din, template[_din].categories[0]).length > 0 ?
                  <Image src={Star} style={styles.separatorStar} />
                  : null}

                {getDishes(_saturday, _din, template[_din].categories[1]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dishBis}>
                    {_d}
                  </Text>
                ))}

                {getDishes(_saturday, _din, template[_din].categories[1]).length > 0 && getDishes(_saturday, _din, template[_din].categories[2]).length > 0 ?
                  <Image src={Star} style={styles.separatorStar} />
                  : null}

                {getDishes(_saturday, _din, template[_din].categories[2]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dishBis}>
                    {_d}
                  </Text>
                ))}
              </View>

            </View>

            <View style={styles.bigSeparator}></View>

            <Text style={styles.pageTitleBis}>
                  {capitalizeFirstLetter(moment(_sunday).format("dddd DD MMMM"))}
              </Text>

            <View style={styles.divHorizontal}>

              <View style={[styles.div, { width: "48%", marginTop: 10, height: 200, position: "relative"}]}>
                <Image src={OveliaCocotte} style={styles.cocotte}/>

                <Text style={styles.repasTitle}>
                    {_dej}
                </Text>

                  
                {getDishes(_sunday, _dej, template[_dej].categories[0]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dishBis}>
                    {_d}
                  </Text>
                ))}

                {getDishes(_sunday, _dej, template[_dej].categories[0]).length > 0 ?
                  <Image src={Star} style={styles.separatorStar} />
                  : null}

                {getDishes(_sunday, _dej, template[_dej].categories[1]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dishBis}>
                    {_d}
                  </Text>
                ))}

                {getDishes(_sunday, _dej, template[_dej].categories[1]).length > 0 && getDishes(_sunday, _dej, template[_dej].categories[3]).length > 0 ?
                  <Image src={Star} style={styles.separatorStar} />
                  : null}

                {getDishes(_sunday, _dej, template[_dej].categories[3]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dishBis}>
                    {_d}
                  </Text>
                ))}

              </View>
              
              <View style={[styles.div, { width: "48%", marginTop: 10, height: 200 }]}>
                <Text style={styles.repasTitle}>
                    {_din}
                </Text>

                {getDishes(_sunday, _din, template[_din].categories[0]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dishBis}>
                    {_d}
                  </Text>
                ))}

                {getDishes(_sunday, _din, template[_din].categories[0]).length > 0 ?
                  <Image src={Star} style={styles.separatorStar} />
                  : null}

                {getDishes(_sunday, _din, template[_din].categories[1]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dishBis}>
                    {_d}
                  </Text>
                ))}

                {getDishes(_sunday, _din, template[_din].categories[1]).length > 0 && getDishes(_sunday, _din, template[_din].categories[2]).length > 0 ?
                  <Image src={Star} style={styles.separatorStar} />
                  : null}

                {getDishes(_sunday, _din, template[_din].categories[2]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dishBis}>
                    {_d}
                  </Text>
                ))}
              </View>
            </View>

          </View>




        </View>
      </Page>
  );

};

export const OveliaWeekPDF = ({ day, dataDay, ui }) => {
  const template = ui.establishment.template;

  const getDishes = useCallback((repas, category, menu) => {
    let list = [];

    if (dataDay[repas] && dataDay[repas][category]) {
      sortDishes(Object.entries(dataDay[repas][category])).forEach(([uid, element]) => {
        if (menu) {
          if (element.origin === menu) list.push(element.name);
        } else {
          list.push(element.name);
        }
      });
    }
    return list;
  }, [day, dataDay]);

  const sortedRepas = Object.keys(template).sort((a, b) => template[a].heure - template[b].heure);
  const _dej = sortedRepas[0];
  const _din = sortedRepas[1];

  const _dejSortedMenus = sortMenus(template, _dej, Object.keys(template[_dej].menus));

  return (
      <Page size="A4" orientation="portrait">
        <View style={styles.container}>
          <View style={styles.backgroundContainer}>
            <Image src={OveliaFondSemaine} style={styles.imageBackground}></Image>
          </View>

          <View style={styles.reducedContainer}>
            <View style={styles.header}>
              <Text style={styles.pageTitle}>
                {capitalizeFirstLetter(moment(day).format("dddd DD MMMM"))}
              </Text>
            </View>

            <View style={styles.content}>
              <View style={styles.div}>
                <Text style={styles.repasTitle}>
                  {_dej}
                </Text>
              </View>
              <View style={styles.div}>
                <Text style={styles.categTitle}>
                  Les entrées
                </Text>

                {getDishes(_dej, template[_dej].categories[0]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dish}>
                    {_d}
                  </Text>
                ))}
              </View>

              <View style={styles.horizontalSeparator}></View>

              <View style={styles.div}>
                <Text style={styles.categTitle}>
                  Les plats et garnitures
                </Text>

                <View style={styles.divHorizontal}>
                  <View style={[styles.div, { width: "45%", marginTop: 0 }]}>
                    {getDishes(_dej, template[_dej].categories[1], _dejSortedMenus[1]).map((_d, _indx) => (
                      <Text key={_indx} style={styles.dish}>
                        {_d}
                      </Text>
                    ))}
                  </View>
                  <View style={styles.verticalSeparator}></View>
                  <View style={[styles.div, { width: "45%", marginTop: 0 }]}>
                    {getDishes(_dej, template[_dej].categories[2], _dejSortedMenus[1]).map((_d, _indx) => (
                      <Text key={_indx} style={styles.dish}>
                        {_d}
                      </Text>
                    ))}
                  </View>
                </View>
              </View>

              <View style={styles.div}>
                <Text style={styles.categDayTitle}>
                  plat du jour
                </Text>

                {getDishes(_dej, template[_dej].categories[1], _dejSortedMenus[0]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dish}>
                    {_d}{getDishes(_dej, template[_dej].categories[2], _dejSortedMenus[0]).length > _indx ? ", " + getDishes(_dej, template[_dej].categories[2], _dejSortedMenus[0])[_indx] : null}
                  </Text>
                ))}

              </View>

              <View style={styles.horizontalSeparator}></View>

              <View style={styles.div}>
                <Text style={styles.categTitle}>
                  Les desserts
                </Text>

                {getDishes(_dej, template[_dej].categories[3]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dish}>
                    {_d}
                  </Text>
                ))}
              </View>


              <View style={[styles.horizontalSeparatorBis, { borderBottomColor: "#e2b900" }]}></View>


              <View style={styles.div}>
                <Text style={styles.repasTitle}>
                  {sortedRepas[1]}
                </Text>

                {getDishes(_din, template[_din].categories[0]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dish}>
                    {_d}
                  </Text>
                ))}

                {getDishes(_din, template[_din].categories[0]).length > 0 ?
                  <Image src={Star} style={styles.separatorStar} />
                  : null}

                {getDishes(_din, template[_din].categories[1]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dish}>
                    {_d}
                  </Text>
                ))}

                {getDishes(_din, template[_din].categories[1]).length > 0 && getDishes(_din, template[_din].categories[2]).length > 0 ?
                  <Image src={Star} style={styles.separatorStar} />
                  : null}

                {getDishes(_din, template[_din].categories[2]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dish}>
                    {_d}
                  </Text>
                ))}
              </View>
            </View>

          </View>




        </View>
      </Page>
  );
};

const OveliaDayPDF = (props) => {
  const { day, data, ui } = props;

  if (moment(day).weekday() < 5) {
    return (
      <Document>
        <OveliaWeekPDF day={day} dataDay={data[day]} ui={ui} />
      </Document>
      );
  } else {
    return (
      <Document>
        <OveliaWeekendPDF day={day} dataWeek={data} ui={ui} />
      </Document>
      );
  }
};

OveliaDayPDF.propTypes = {
  day: PropTypes.any.isRequired,
  data: PropTypes.object.isRequired,
  ui: PropTypes.any,
};

OveliaWeekendPDF.propTypes = {
  day: PropTypes.any.isRequired,
  dataWeek: PropTypes.object.isRequired,
  ui: PropTypes.any,
};

OveliaWeekPDF.propTypes = {
  day: PropTypes.any.isRequired,
  dataDay: PropTypes.object.isRequired,
  ui: PropTypes.any,
};

export default OveliaDayPDF;
