import React, { useEffect, useMemo, useState } from "react";
import { ChevronRight, ChevronLeft, User, Trash, Edit, Trash2 } from "react-feather";
import { Button, Row, Icon, Container, Card, Input, Select } from "lib/components";
import styles from "../Scss/tableau.module.scss";
import { useServiceReservation } from "../../routerContext";
import { CategoriesCrudService } from "../../routerComponents";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import { sendNotifToCentreNotif } from "services/ressources/notification";
import useUI from "hooks/ui.hook";


const TableauUneDispo = (props) => {

    const history = useHistory();
    const [ui] =useUI();
    const { onModifyOpen, availability } = props;
    const [ctx,{removeReservation}] = useServiceReservation();
    const [currentWeekStart, setCurrentWeekStart] = useState(new Date());
    const [searchSenior, setSearchSenior] = useState("");
    const [searchPersonnel, setSearchPersonnel] = useState("");
    const [prestataireName, setPrestataireName] = useState("");
    const [serviceRef,setServiceRef] = useState("");
    const [planning,setPlanning]= useState([]);
    const [eventsParsed, setEventsParsed] = useState([]);

    
    useEffect(()=>{
        if(availability?.reservations){
            const sortedPlanning = availability.reservations.sort((a, b) => {
                const dateA = new Date(a.start);
                const dateB = new Date(b.start);
                return dateA.getTime() - dateB.getTime(); // Trie par ordre croissant des horaires
            }).filter((item) => {
                const senior = getSenior(item);
                return senior?.toLowerCase().includes(searchSenior.toLowerCase()) && item.personnelByDefault.toLowerCase().includes(searchPersonnel.toLowerCase());
                
            });
            setPlanning(sortedPlanning);
        }
    },[availability,searchSenior,searchPersonnel]);

    const getSenior=(resa)=>{
         for (const userId in ctx.users) {
            if (userId == resa.userId) {
                const user = ctx.users[userId];
                return user.surname+" "+user.name;
            }
        }
    };

    const getPrestations=(resa)=>{
        //on récupère les noms correspondand aux prestationsId
        const prestationsTitles = ctx.prestataires.flatMap(item => {
            return resa.prestationId.map(id => item.prestations[id]?.title).filter(Boolean);
        });
        return prestationsTitles;
    };

    const prestataireInfo = useMemo(() => {
        if (ctx?.prestataires && availability?.prestataireId) {
            const presta = ctx.prestataires.find((resa) => resa.uid === availability.prestataireId);
            setPrestataireName(presta.name);
            setServiceRef(presta.serviceRef);
            return presta;
        }

        else return {};
    }, [availability, ctx.prestataires]);


    const _onDelete = (item) => {
        try {
            if (!window.confirm("Êtes-vous sur de vouloir supprimer cette réservation?")) return;
            console.log(item,availability);
            const prestations = getPrestations(item);
            sendNotifToCentreNotif({
                ui,
                data:{
                    type:"service",
                    action:"delete",
                    data:{
                        userId: item.userId,
                        date: item.start,
                        name: prestataireName,
                        end: item.end,                            
                        category: serviceRef,
                        prestation :prestations
                    }
                }
            });   
            removeReservation(availability.uid,item.uid);
        } catch (e) {
            toast.error("la suppression n'a pas abouti");
            console.error("Erreur lors de la suppression de la prestation:", e);
            throw e;
        }
    };

    
    return (
        <Card className={styles.card} >
            <Row style={{ justifyContent: "space-between", marginRight: "10px", marginBottom: "40px" }}>
                <div className={styles.rechercheContainer}>
                    <div className={styles.recherche}>
                        Recherche : 
                        <Input
                            placeholder="Nom du senior.."
                            value={searchSenior}
                            onChange={(e) => setSearchSenior(e.target.value)}
                        />
                         {availability.personnel.length>0?<Input
                            placeholder="Nom du personnel.."
                            value={searchPersonnel}
                            onChange={(e) => setSearchPersonnel(e.target.value)}
                        />:null}
                         <div className={styles.iconPoubelle}>
                        <Icon icon={Trash2} size={20} onClick={()=>{setSearchSenior("");setSearchPersonnel("");}}/> 
                    </div>
                    </div>

                </div>

            </Row>
          
            <div>  <Row className={styles.titreColonne} style={{marginLeft:"5px",marginRight:"20px"}}>
                <div style={{fontWeight:"bold",fontSize:"18px",flex:2}}>Horaire</div>
                <div  style={{fontWeight:"bold",fontSize:"18px",flex:3}}>Prestation(s)</div>
                <div  style={{fontWeight:"bold",fontSize:"18px",flex:3}}>Senior</div>
                <div  style={{fontWeight:"bold",fontSize:"18px",flex:3}}>Personnel</div>
                <div ></div>
            </Row>
                {availability?.reservations?.length > 0 ?
                    planning.map((resa, idx) => {
                        return (
                            <Row key={idx} className={styles.rowContainer}>
                            <div className={styles.column}>
                            <Container  className={styles.rowContainer}>
                                <div className={styles.column}>
                                    <Container  className={styles.dayContainer}>
                                        <>
                                            <div className={styles.timing}>{resa.start.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit", hour12: false })} - {resa.end.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit", hour12: false })}</div>
                                            <div className={styles.titlePresta}>{getPrestations(resa).join(",\n")}</div>
                                            <div className={styles.titlePresta}>{getSenior(resa) !==undefined ? getSenior(resa) : "Pas de user"}</div>
                                            <div className={styles.titlePresta}>{resa.personnel == "unknown"? "-" : resa.personnel=="Sans Préférence"?resa.personnel +" ("+resa.personnelByDefault+")":resa.personnel}</div>
                                            <Row className={styles.participantRow} style={{ flex: "1", display: "flex", justifyContent: "flex-end", marginRight: "auto", gap: "8px" }}>
                                                <Edit color="blue" size="20px" style={{ marginLeft: "50px", marginRight: "15px",cursor:"pointer" }}
                                                    onClick={() => {onModifyOpen(resa);}}
                                                />
                                                <Trash2 color="red" size="20px" style={{ marginRight: "15px" ,cursor:"pointer"}}
                                                    onClick={() => { _onDelete(resa);}}
                                                />
                                            </Row>


                                        </>
                                    </Container>
                                </div>
                            </Container>
                            </div></Row>);
                    })
                    :
                    <div className={styles.noPrestataire}>{"Pour le moment, il n'y a aucune réservation d'effectuer sur ce créneau"}</div>
                }
            </div>
        </Card>
    );
};
TableauUneDispo.propTypes = {
    onModifyOpen: PropTypes.func,
    availability: PropTypes.object.isRequired
};

export default TableauUneDispo;

