import React, { useEffect, useState } from "react";
import { useServiceReservation } from "../../routerContext";
import { ArrowLeft, Plus, Check, Edit, ChevronDown, UserPlus } from "react-feather";
import { Button, Row, Icon, Container, Card } from "lib/components";
import { TableauResa, DisponibilityModal, ReservationModal } from "../../routerComponents";

const Reservation = (props) => {
    const [ctx, { updatePage }] = useServiceReservation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalResaVisible,setIsModalResaVisible] = useState(false);
    const [currentWeekStart, setCurrentWeekStart] = useState(new Date(ctx.weekDays));
    const [selectedItem, setSelectedItem] = useState("");

    useEffect(() => {
        setCurrentWeekStart(new Date(ctx.weekStart));
    }, [ctx.weekStart]);
    const currentWeekStartDate = new Date(currentWeekStart);
    const nextWeekStartDate = new Date(currentWeekStartDate);
    nextWeekStartDate.setDate(nextWeekStartDate.getDate() + 6);
    // primary", "secondary", "success", "warning", "info", "danger", "primaryDark", "secondaryDark"
    return (
        <Container >
            <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", marginLeft: "auto", marginRight: " auto" }}>

                <h2>{"Semaine du "} <span style={{ color: "#DF7189" }}>{currentWeekStartDate.toLocaleDateString("fr-FR", { weekday: "short", day: "2-digit", month: "2-digit" })} </span> au <span style={{ color: "#DF7189" }}> {nextWeekStartDate.toLocaleDateString("fr-FR", { weekday: "short", day: "2-digit", month: "2-digit" })}</span></h2>
                <Row style={{ marginRight: "10px" }}>
                <Button color="secondary" style={{ width: "fit-content", display: "flex", gap: "5px", alignItems: "center", height: "50px",marginRight: "10px"  }} onClick={() => setIsModalVisible(true)} >
                    <Icon icon={Plus} color="light" size="30"   />
                    <div style={{fontWeight:"bold",fontSize:"17px"}}>Nouvelle disponibilité </div>
                </Button>
                    <Button style={{ width: "fit-content", display: "flex", alignItems: "center", gap: "5px", height: "50px", marginRight: "10px" }} onClick={() => setIsModalResaVisible(true)}  >
                        <Icon icon={UserPlus} color="light" size="30" />
                        <div style={{fontWeight:"bold",fontSize:"17px"}}>Réserver</div>
                    </Button>

                    <Button style={{ border: "solid 1px black", backgroundColor: "white", color: "black",  height: "50px",borderRadius: "5px", display: "flex", flexDirection: "flex-row", alignItems: "center" }} onClick={{}}>
                        <div>Exporter</div>
                        <ChevronDown />
                    </Button>
                </Row>

            </Row>
            <TableauResa
                onModifyOpen={(item) => { setSelectedItem(item); setIsModalVisible(true); }}

            />

            <DisponibilityModal
                isOpen={isModalVisible}
                eventOpen={selectedItem}
                toggle={() => {
                    if (selectedItem) setSelectedItem("");
                    setIsModalVisible(false);
                }}
            />
            <ReservationModal
                isOpen={isModalResaVisible}
                toggle={()=>{
                    setIsModalResaVisible(false);
                }}
            />
        </Container>
    );
};

export default Reservation;

