import axios from "axios";

const TEST_IN_PROD = false;

const MODE = 
  TEST_IN_PROD ? "PROD" :
  ["localhost", "major-dev-fdd82.web.app", "major-dev-fdd82.firebaseapp.com"].includes(window.location.hostname) ? "DEV" : 
  //["major-preprod-4ae67.web.app", "major-preprod-4ae67.firebaseapp.com", "preprod.app.jabi.fr"].includes(window.location.hostname) ? "PREPROD" : 
  "PROD";

axios.defaults.baseURL = 
  MODE === "DEV" ? "http://localhost:8080" :
  //MODE === "PREPROD" ? "https://api.preprod.jabi.fr" :
                          "https://api.jabi.fr";


axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.headers.common["Pragma"] = "no-cache";
axios.defaults.headers.common["Expires"] = "0";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.timeout = 5000;



export default MODE;