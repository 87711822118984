import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, Card, CardHeader, CardBody, Button, Spinner, Tag, Input } from "lib/components";
import firebase from "firebase";
import useGroupement from "hooks/useGroupement";
import { Edit, Trash, Plus } from "react-feather";
import roles from "enums/roles";
import { useHistory } from "react-router-dom";
import useUI from "hooks/ui.hook";
import { toast } from "react-toastify";
import AddModal from "./AddModal";
import useMicroService from "helpers/microService";
import useSearch from "hooks/useSearch";

const firestore = firebase.firestore;
const functions = firebase.functions;

const parameters = ["name", "surname"];

const Users = () => {
  const [ui] = useUI();
  const execMicroService = useMicroService();
  const history = useHistory();
  const {groupement, establishments, loading: groupementLoading } = useGroupement();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [userFilter, setUserFilter] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [value, setValue] = useState("");
  const { filtered } = useSearch(filteredUsers, value, parameters);


  const filterUsers = (user) => {
    if(  userFilter.length == 0)
      return true;
    else
      return userFilter.indexOf(user.role) != -1 ? true : false;
  };
  

  useEffect(()=>{
      setFilteredUsers(users.filter(filterUsers));
  },[users, userFilter]);


  useEffect(() => {
    (async () => {
      if (groupementLoading) return;
      let users = [];

      await Promise.all(
        establishments.map(async (establishment) => {
          try {
            const res = await firestore()
              .collection("users")
              .where("establishment", "==", establishment.uid)
              .get();
            res.forEach((doc) => {
              users.push({ ...doc.data(), uid: doc.id });
            });
          } catch (e) {
            console.error("error while fetch etab " + establishment.name, e);
          }
        })
      );
      try {
        const res2 = await firestore()
          .collection("users")
          .where("groupement", "==", groupement.uid)
          .where("role", "==", "regionAdmin")
          .get();

          res2.forEach((doc) => {
          users.push({ ...doc.data(), uid: doc.id });
        });
      } catch (e) {
        console.error("error while fetch groupement regionAdmin");
      }
      setUsers(users);
      setLoading(false);
    })();
  }, [establishments, groupementLoading, addModalOpen]);

  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  const onDelete = async (uid) => {
    if (
      !window.confirm(
        "Souhaitez vous vraiment supprimer cet utilisateur ? (cette action est irréversible)"
      )
    )
      return;

    try {
      let res = await execMicroService("deleteUser", { uid: uid });
      if (res.status === 200) {
        toast.success("Utilisateur supprimé");
        setAddModalOpen(null); // reload les utilisateurs
      } else {
        toast.error("Une erreur est survenue");
      }
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  if (loading) return <Spinner />;
  return (
    <>
      <h5 style={{ width: "100%" }}>Filtres : </h5>
      <RenderFilter userFilter={userFilter} setUserFilter={setUserFilter} groupement={groupement}/>
      <Card style={{ width: "100%" }}>
        <CardHeader style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{display: "flex", justifyContent: "space-between", gap: 60, alignItems: "center"}}>
              <h5 style={{marginBottom: 0, whiteSpace: "nowrap"}}>Liste des utilisateurs</h5>
              <div style={{width: "60%", display: "flex", flexDirection: "row", alignItems: "center", gap: 5}}>
                <h6 style={{marginBottom: 0, whiteSpace: "nowrap"}}>Recherche :</h6>
                <Input value={value} onChange={(e)=> setValue(e.target.value)}/>
              </div>
            </div>
          <Button color="primary" onClick={() => setAddModalOpen(true)}>
            <Plus />
          </Button>
        </CardHeader>
        <CardBody>
          <Table loading={loading}>
            <thead>
              <th>Nom</th>
              <th>Prénom</th>
              <th>Etablissement</th>
              <th>Role</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {filtered.map((user) => (
                <tr key={user.uid}>
                  <td>{user.name}</td>
                  <td>{user.surname}</td>
                  <td>{(establishments.find((e) => e.uid === user.establishment) ?? {}).name}</td>
                  <td>
                    <Tag
                      style={{
                        backgroundColor: (roles.find((r) => r.value === user.role) ?? {}).color,
                      }}
                    >
                      {(roles.find((r) => r.value === user.role) ?? {}).label ?? user.role}
                    </Tag>
                  </td>
                  <td>
                    {user.uid !== ui.user.uid ? (
                      <>
                        <Trash
                          size={22}
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => onDelete(user.uid)}
                        />
                        &nbsp;
                        <Edit
                          size={22}
                          style={{ cursor: "pointer" }}
                          onClick={() => history.push(`/dashboard/users/${user.uid}`)}
                        />
                      </>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <AddModal isOpen={addModalOpen} toggle={closeAddModal} />
    </>
  );
};


const RenderFilter = ({userFilter, setUserFilter, groupement}) => {

  const handleFilter = (value) => {
    let _userFilter = [...userFilter];
  
    if(value == "all"){
      _userFilter = [];
    }else{
      if(_userFilter.indexOf(value) == -1){
        _userFilter.push(value);
      }else{
        _userFilter.splice(_userFilter.indexOf(value), 1);
      }
    }
    setUserFilter(_userFilter);
  };
  let retour = [];
  retour.push(<div style={{backgroundColor: "#51075e", color: "#FFFFFF", padding: 6, borderRadius: 10, cursor: "pointer", opacity: userFilter.length > 0 ? 0.35 : 1}} onClick={() => handleFilter("all")}>Tous</div>);
  roles.forEach(element => {
    if(element.value != "superadmin" && element.value != "groupementAdmin"){
      if(element.value !== "regionAdmin" || (element.value === "regionAdmin" && groupement?.regions && groupement?.regions.length > 0)){
        retour.push(<div style={{backgroundColor: element.color, color: "#FFFFFF", padding: 6, borderRadius: 10, cursor: "pointer", opacity: userFilter.indexOf(element.value) == -1 ? 0.35 : 1}} onClick={() => handleFilter(element.value)}>{element.label}</div>);
      }
    }
  });

  return <div style={{display:"flex", flex: 1, flexWrap: "wrap",flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 10}}>
    {retour}
  </div>;
};

RenderFilter.propTypes = {
  userFilter: PropTypes.array,
  setUserFilter: PropTypes.func,
  groupement: PropTypes.object,
};

export default Users;
