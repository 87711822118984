import React from "react";
import PropTypes from "prop-types";
import Select from "./Select";

const options = [
  /*{value: "00h00", label: "00h00"},
  {value: "00h15", label: "00h15"},
  {value: "00h30", label: "00h30"},
  {value: "00h45", label: "00h45"},
  {value: "01h00", label: "01h00"},
  {value: "01h15", label: "01h15"},
  {value: "01h30", label: "01h30"},
  {value: "01h45", label: "01h45"},
  {value: "02h00", label: "02h00"},
  {value: "02h15", label: "02h15"},
  {value: "02h30", label: "02h30"},
  {value: "02h45", label: "02h45"},
  {value: "03h00", label: "03h00"},
  {value: "03h15", label: "03h15"},
  {value: "03h30", label: "03h30"},
  {value: "03h45", label: "03h45"},
  {value: "04h00", label: "04h00"},
  {value: "04h15", label: "04h15"},
  {value: "04h30", label: "04h30"},
  {value: "04h45", label: "04h45"},
  {value: "05h00", label: "05h00"},
  {value: "05h15", label: "05h15"},
  {value: "05h30", label: "05h30"},
  {value: "05h45", label: "05h45"},
  {value: "06h00", label: "06h00"},
  {value: "06h15", label: "06h15"},
  {value: "06h30", label: "06h30"},
  {value: "06h45", label: "06h45"},
  {value: "07h00", label: "07h00"},
  {value: "07h15", label: "07h15"},
  {value: "07h30", label: "07h30"},
  {value: "07h45", label: "07h45"},*/
  {value: "08h00", label: "08h00"},
  {value: "08h15", label: "08h15"},
  {value: "08h30", label: "08h30"},
  {value: "08h45", label: "08h45"},
  {value: "09h00", label: "09h00"},
  {value: "09h15", label: "09h15"},
  {value: "09h30", label: "09h30"},
  {value: "09h45", label: "09h45"},
  {value: "10h00", label: "10h00"},
  {value: "10h15", label: "10h15"},
  {value: "10h30", label: "10h30"},
  {value: "10h45", label: "10h45"},
  {value: "11h00", label: "11h00"},
  {value: "11h15", label: "11h15"},
  {value: "11h30", label: "11h30"},
  {value: "11h45", label: "11h45"},
  {value: "12h00", label: "12h00"},
  {value: "12h15", label: "12h15"},
  {value: "12h30", label: "12h30"},
  {value: "12h45", label: "12h45"},
  {value: "13h00", label: "13h00"},
  {value: "13h15", label: "13h15"},
  {value: "13h30", label: "13h30"},
  {value: "13h45", label: "13h45"},
  {value: "14h00", label: "14h00"},
  {value: "14h15", label: "14h15"},
  {value: "14h30", label: "14h30"},
  {value: "14h45", label: "14h45"},
  {value: "15h00", label: "15h00"},
  {value: "15h15", label: "15h15"},
  {value: "15h30", label: "15h30"},
  {value: "15h45", label: "15h45"},
  {value: "16h00", label: "16h00"},
  {value: "16h15", label: "16h15"},
  {value: "16h30", label: "16h30"},
  {value: "16h45", label: "16h45"},
  {value: "17h00", label: "17h00"},
  {value: "17h15", label: "17h15"},
  {value: "17h30", label: "17h30"},
  {value: "17h45", label: "17h45"},
  {value: "18h00", label: "18h00"},
  {value: "18h15", label: "18h15"},
  {value: "18h30", label: "18h30"},
  {value: "18h45", label: "18h45"},
  {value: "19h00", label: "19h00"},
  {value: "19h15", label: "19h15"},
  {value: "19h30", label: "19h30"},
  {value: "19h45", label: "19h45"},
  {value: "20h00", label: "20h00"},
  {value: "20h15", label: "20h15"},
  {value: "20h30", label: "20h30"},
  {value: "20h45", label: "20h45"},
  {value: "21h00", label: "21h00"},
  {value: "21h15", label: "21h15"},
  {value: "21h30", label: "21h30"},
  {value: "21h45", label: "21h45"},
  {value: "22h00", label: "22h00"},
  {value: "22h15", label: "22h15"},
  {value: "22h30", label: "22h30"},
  {value: "22h45", label: "22h45"},
  {value: "23h00", label: "23h00"},
  /*{value: "23h15", label: "23h15"},
  {value: "23h30", label: "23h30"},
  {value: "23h45", label: "23h45"},*/
];

const TimePicker = (props) => {
  const {
    label,
    value,
    onChange,
    error,
    theme,
    style,
    invalid,
  } = props;

  return (
    <div style={style ?? {}}>
      <Select 
        label={label}
        error={error}
        onChange={onChange}
        value={value}
        options={options}
        theme={theme}
      />
      {invalid && typeof(invalid) === "string" ?
        <span style={{color: "red"}}>{invalid}</span>
        : ""}
    </div>
  );
};

TimePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  error: PropTypes.any,
  theme: PropTypes.any,
  style: PropTypes.any,
  invalid: PropTypes.any,
};

export default TimePicker;