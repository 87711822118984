import React, { useState } from "react";
import PropTypes from "prop-types";
import { ChevronDown, ChevronRight } from "react-feather";
import {
  Row,Button
} from "lib/components";
import { Container } from "reactstrap";

const HoraireChooser = (props) => {
  const { date, availableTimes,
    selectedTiming,selectedDay,onSelectButton,end
   } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

if(availableTimes) {
  if(availableTimes.length == 0) return <div>{"Aucun horaire n'est disponible pour vos choix."}</div>;
  return (
    <div style={{ backgroundColor: "#D0D0D0", borderRadius: "5px",marginBottom:"10px" }}> 
      <div onClick={handleToggle} style={{ display: "flex", borderBottom: isOpen ? "1px solid #A0A0A0" : "" }} >
        <Row style={{ margin: "10px", display: "flex", width: "100%", justifyContent: "space-between" }}>
          <div>
            {date.toLocaleDateString("fr-FR", { weekday: "long", month: "long", day: "numeric" })+" "+date.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" })+" - "+end.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" })}
          </div>
          {isOpen ? <ChevronDown /> : <ChevronRight />}
        </Row>
      </div>
  
      {isOpen && (
        <div style={{ marginTop: "10px", marginBottom: "10px", overflow: "hidden" }}> 
          <Row style={{ marginLeft: "10px" }}> 
            {availableTimes.map((time, index) => (
              <Button key={index} 
              style={{ backgroundColor: selectedTiming != "" ? ((selectedTiming.getTime() == time.getTime() && (selectedDay.getTime()==date.getTime())) ? "pink" : "white"): "white", borderRadius: "5px", padding: "5px", marginRight: "20px",marginBottom:"10px" }}  
              onClick={() =>{onSelectButton(time,date);}}>
                <div style={{color:"black"}}>{ `${ String(time.getHours()).padStart(2, "0")}:${String(time.getMinutes()).padStart(2, "0")}`}</div>
              </Button>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
}else return <div>{"Aucun horaire n'est disponible pour vos choix."}</div>;
  


};
HoraireChooser.propTypes = {
  date: PropTypes.instanceOf(Date),
  availableTimes: PropTypes.array,
  selectedTiming: PropTypes.instanceOf(Date),
  selectedDay: PropTypes.instanceOf(Date),
  onSelectButton: PropTypes.func,
  end: PropTypes.instanceOf(Date)
};
export default HoraireChooser;
