import React from "react";
import PropTypes from "prop-types";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import icone from "./assets/icone.jpg";
import fork from "./assets/fork.png";

import moment from "moment";

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    padding: 0,
    backgroundColor: "#6EC829"
  },
  header: {
    flex: 1,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
  },
  headerLeft: {
    width: "35%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
  },
  icone: {
    width: "100%",
    objectFit: "contain",
  },
  headerRight: {
    width: "60%",
    paddingVertical: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#006E70",
    borderWidth: 4,
    borderRadius: 5,
    borderColor: "white"
  },
  menuText: {
    //fontFamily: "Arial",
    fontSize: 20,
    fontWeight: 700,
    color: "white"
  },
  weekText: {
    //fontFamily: "Arial",
    fontSize: 15,
    color: "white",
  },
  table:{
    flex: 9,
    marginTop: "2%",
    marginBottom: "4%",
    width: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 0,
  },
  line:{
    flex: 1,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 0,
  },
  forkContainer:{
    flex: 1,
    alignItems: "center",
    justifyContent: "space-around"
  },
  fork:{
    width: "80%",
    objectFit: "contain",
  },
  tableHeaderDiv: {
    flex: 2,
    height: "100%",
    justifyContent: "space-around",
    alignItems: "center"
  },
  tableHeaderDivText:{
    fontSize: 25,
    fontWeight: 700,
    color: "#006E70"
  },

  divDate:{
    flex: 1,
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderLeftWidth: 2,
    borderLeftColor: "white",
    borderRightWidth: 2,
    borderRightColor: "white",
    borderTopWidth : 0,
    borderBottomWidth: 0,
  },
  dateDay: {
    fontSize: 18,
    textAlign: "center",
    color: "#494838",
  },
  dateDetails: {
    fontSize: 16,
    textAlign: "center",
    color: "#006E70"
  },
  divContent: {
    flex: 2,
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRightWidth: 2,
    borderRightColor: "white",
    borderTopWidth : 0,
    borderBottomWidth: 0,
  },
  dishText: {
    fontSize: 14,
    textAlign: "center",
    color: "#494838",
  },
});

const Jasmins = (props) => {
  const { week, menuState, logo, ui } = props;
  const template = ui.establishment.template;

  const _repas = Object.keys(template).sort((a,b)=> template[a].heure - template[b].heure );
  const Dej = _repas[0];
  const Din = _repas[1];

  //console.log(ui.establishment.labels);
  function capFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <Document>
          <Page size="A4" orientation="portrait">
            <View style={styles.container}>

              <View style={styles.header}>
                <View style={styles.headerLeft}>
                  <Image src={icone} style={styles.icone}/>
                </View>
                <View style={styles.headerRight}>
                  <Text style={styles.menuText}> Menu </Text>
                  <Text style={styles.weekText}>
                   Du {moment(week[0]).format("LL")} au{" "} {moment(week[6]).format("LL")}
                  </Text>
                </View>
              </View>

              <View style={styles.table}>
                  <View style={styles.line}>
                      <View style={styles.forkContainer}>
                        <Image src={fork} style={styles.fork}/>
                      </View>
                      <View style={[styles.tableHeaderDiv, {backgroundColor: "white"}]}>
                        <Text style={styles.tableHeaderDivText}>
                          {Dej}
                        </Text>
                      </View>
                      <View style={[styles.tableHeaderDiv, {backgroundColor: "#CAC8BA"}]}>
                        <Text style={styles.tableHeaderDivText}>
                          {Din}
                        </Text>
                      </View>
                  </View>

                  {Object.entries(menuState).map(([date, data], index) => (
                    <View key={index} style={[styles.line, {backgroundColor: index%2 === 0 ? "#DCDBD2" : "#F0EFE8"}]}>
                      <View style={styles.divDate}>
                        <Text style={styles.dateDay}>
                          {capFirstLetter(moment(date).format("dddd"))}
                        </Text>
                        <Text style={styles.dateDetails}>
                          {moment(date).format("DD MMMM")}
                        </Text>
                      </View>

                      <View style={styles.divContent}>
                        {template[Dej].categories.map((_category, id) => (
                          <View key={id}>
                            {data[Dej] && data[Dej][_category] && Object.keys(data[Dej][_category]).length > 0 ? 
                                Object.entries(data[Dej][_category]).sort((a,b) => a[1].order - b[1].order).map(([_dishId, _dish]) => (
                                  <Text key={_dishId} style={styles.dishText}>
                                    {_dish.name}
                                  </Text>
                                ))
                            : null}
                          </View>
                        ))}
                      </View>


                      <View style={styles.divContent}>
                        {template[Din].categories.map((_category, id) => (
                          <View key={id}>
                            {data[Din] && data[Din][_category] && Object.keys(data[Din][_category]).length > 0? 
                              Object.entries(data[Din][_category]).sort((a,b) => a[1].order - b[1].order).map(([_dishId, _dish]) => (
                                <Text key={_dishId} style={styles.dishText}>
                                  {_dish.name}
                                </Text>
                              ))
                              : null}
                          </View>
                        ))}
                      </View>

                    </View>
                  ))}

              </View>
            </View>
          </Page>
    </Document>
  );
};


Jasmins.propTypes = {
  week: PropTypes.array.isRequired,
  menuState: PropTypes.object.isRequired,
  logo: PropTypes.string,
  ui: PropTypes.any,
};

export default Jasmins;
