import { Folder, PlusCircle, Slash } from "react-feather";
import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import firebase from "firebase";
import styles from "../../../../../assets/scss/components/labelSelector.module.scss";
import { useFirebaseDocumentOnce } from "lib/hooks/firebase";
import useUI from "hooks/ui.hook";
import deepClone from "helpers/deepClone";
import { getEstablishmentById } from "services/ressources/establishment";

const firestore = firebase.firestore;

const LabelSelector = (props) => {
  const { value, onChange, color } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [ui] = useUI();
  const [data, setData] = useState({});
  const [labels, setLabels] = useState([]);
  
  useEffect(() => {
    (async () => {
      setData(await getEstablishmentById({id: ui.user.establishment}));
    })();
  }, [ui.user.establishment]);
  useEffect(() => {
    if (data && data.labels) {
      setLabels(data.labels);
    } else {
      setLabels([]);
    }
  }, [data]);

  return (
    <div className={`${styles.container} ${styles[color]}`}>
      {value && labels[value]?.img ? (
        <img src={labels[value].img} className={styles.preview} onClick={() => setIsOpen(true)}/>
      ) : (
        <PlusCircle
          className={styles.icon}
          size={26}
          onClick={() => setIsOpen(true)}
        />
      )}
      {isOpen ? (
        <>
          <div
            className={styles.overlay}
            onClick={() => setIsOpen(false)}
          ></div>
          <div style={{ position: "relative" }}>
            <div className={styles.content}>
              <div
                  className={styles.label}
                  onClick={() => {
                    onChange(null);
                    setIsOpen(false);
                  }}
                >
                  <span>Aucun Label</span>
                  <Slash
                    size={40}
                    color={"red"}
                  />
                </div>
              {Object.keys(labels).map((label, index) => (
                <div
                  className={styles.label}
                  key={index}
                  onClick={() => {
                    onChange(label);
                    setIsOpen(false);
                  }}
                >
                  <span>{label}</span>
                  <img src={labels[label].img} alt={label} />
                </div>
              ))}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

LabelSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  color: PropTypes.string,
};

export default LabelSelector;
