import React, {useState, useMemo} from "react";
import useUI from "hooks/ui.hook";
import styles from "assets/scss/components/homeWidgets/todayBirthday.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBirthdayCake } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import firebase from "firebase";
import {useFirebaseCollection} from "lib/hooks/firebase";

const firestore = firebase.firestore;
const TodayBirthday = () => {
  const [ui] = useUI();
  const [usersRef] = useState(firestore().collection("users").where("establishment", "==", ui.user.establishment));
  const {data: users} = useFirebaseCollection(usersRef);
  const todayUsersBirthday = useMemo(() => {
    return users.filter(user => user.birthdate && moment(user.birthdate.toDate()).format("MM-DD") === moment().format("MM-DD"));
  }, [users]);
 
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h5 style={{fontWeight:600, fontSize:18, textAlign: "center"}}>Les anniversaires du jour</h5>
      </div>
      <div className={styles.body}>
        <FontAwesomeIcon className={styles.img} icon={faBirthdayCake} size={"6x"} color={"#f2994a"}/>
        <h5>{todayUsersBirthday.length} anniversaires aujourd&apos;hui</h5>
        <div className={styles.birthdaysContainer}>
              {todayUsersBirthday.map(user => 
                <div key={user.uid} className={styles.birthday}>
                  <span className={styles.user}>{`${user.surname} ${user.name}`}</span>
                </div>
              )}
          </div>
      </div>
    </div>);
};

export default TodayBirthday;