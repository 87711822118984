import React from "react";
import PropTypes from "prop-types";
const Text = ({
  children,
  tag       = "p",
  className = "",
  style,
  onClick,
}) => {

  return (
    <>
      {tag === "p" ?
        <p style={style} onClick={onClick} className={`${className ?? ""}`}>
          {children}
        </p>
        :
        <span style={style} onClick={onClick} className={`${className ?? ""}`}>
          {children}
        </span>
      }
    </>
  );
};

Text.propTypes = {
  children  : PropTypes.any,
  tag: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.any,
  onClick: PropTypes.func,
};

export default Text;