import React from "react";
import PropTypes from "prop-types";
import {Modal, ModalHeader, ModalBody} from "../../../lib/components";

const CognitiveModal = (props) => {
  const {
    isOpen,
    toggle,
    item
  } = props;

  if (item === null) return <></>;

  const data = item && item.data ? JSON.parse(item.data) : {};

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader>Données cognitives</ModalHeader>
      <ModalBody>
        <p>
          <span>game: {item.game}</span>
        </p>
        {Object.keys(data).map(k => (
          <p key={k}>
            <span>{k}: {typeof(data[k]) === "object" ? "" : 
              typeof(data[k]) === "boolean" && data[k] ? "true" :
                typeof(data[k]) === "boolean" && !data[k] ? "false" : data[k]}</span>
            {typeof(data[k]) === "string" ? data[k]
              : Object.keys(data[k]).map(k2 => (
                <p key={k2} style={{marginLeft: 15}}>
                  <span>{k2}: {data[k][k2]}</span>
                </p>
              ))}
          </p>
        ))}
      </ModalBody>
    </Modal>
  );
};

CognitiveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

export default CognitiveModal;